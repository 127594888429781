<div class="modal-wrap" [formGroup]="paymentsSettingForm">
  <div class="modal-wrap__header">
    <div class="title">
      <h2>{{ modalTitle }}</h2>
      <p *ngIf="!isCompletedPayments">정산 받으실 계좌 정보를 입력해 주세요.</p>
    </div>
  </div>
  <div class="modal-wrap__body">
    <div class="alert-wrap">
      <i class="icon icon-speaker"></i>
      <div>
        {{ noticeInfo }}
      </div>
    </div>
    <div class="ha-card ha-card-radius">
      <div class="ha-card-header">
        <div class="ha-card-title">{{ hospitalName }}</div>
      </div>
      <table class="ha-table ha-table-bordered">
        <tr>
          <td class="table-header-row" style="color: #000000">
            사업자등록번호
          </td>
          <td style="width: 270px">
            <ng-container *ngIf="!isCompletedPayments; else isModified">
              <app-radio formControlName="type" name="type" value="INDIVIDUAL"
                >개인사업자
              </app-radio>
              <app-radio formControlName="type" name="type" value="CORPORATE">
                법인사업자
              </app-radio>
            </ng-container>
            <ng-template #isModified>
              {{ PaymentsSettingTypeMapper[type] }}
            </ng-template>
          </td>
          <td>{{ businessNumber }}</td>
        </tr>
        <tr style="height: 102px">
          <td class="table-header-row">계좌정보</td>
          <td colspan="2">
            <div class="account-info-row">
              <div class="dropdown" placement="bottom" ngbDropdown>
                <button
                  class="ha-dropdown-toggle form-control"
                  ngbDropdownToggle
                >
                  {{ this.bankCodeFormControl.value ?? '은행 선택' }}
                </button>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <button
                    class="dropdown-item"
                    *ngFor="let bankCode of BANK_CODES"
                    (click)="handleBankSelector(bankCode)"
                  >
                    {{ bankCode }}
                  </button>
                </div>
              </div>
              <div style="width: 270px">
                <app-input-text
                  formControlName="holderName"
                  placeholder="예금주명 입력"
                  [maxLength]="30"
                ></app-input-text>
              </div>
            </div>
            <div style="display: flex">
              <div style="width: 420px">
                <app-input-text
                  formControlName="accountNumber"
                  placeholder="계좌번호 입력"
                  [hasError]="hasAccountError$ | async"
                  errorMessage="계좌번호가 유효하지 않습니다."
                  [maxLength]="30"
                >
                </app-input-text>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <ng-container *ngIf="!isCompletedPayments">
      <table class="ha-table ha-table-bordered ha-table-rounded">
        <tr>
          <td class="table-header-row">
            <span>동의 후 간편결제 사용을 누르시면,</span>
            <div class="date-select-wrap">
              <div class="picked-date" #datePickerRef>
                <span>{{ formattedDate }}</span>
              </div>
              <button
                type="button"
                bsDatepicker
                class="btn-date-picker"
                #datePickerBtnRef
                [bsConfig]="bsConfig"
                [bsValue]="activationDateFormControl.value"
                (bsValueChange)="handleActivationDateSelector($event)"
              >
                <span class="ha-btn icon-button">
                  <i class="icon icon-ic-calendar"></i>
                </span>
              </button>
            </div>
            <span>부터 똑닥 앱에서 진료비 간편결제가 가능합니다.</span>
          </td>
        </tr>
      </table>
      <table class="ha-table ha-table-bordered ha-table-rounded">
        <tr>
          <td class="table-header-row">
            <p>정보 위탁처리 동의</p>
            <p style="font-weight: 300; font-size: 14px">
              제3자 제공에 대한 동의를 거부할 권리가 있으며, 동의를 거부할 경우
              서비스를 이용하실 수 없습니다.
            </p>
            <a
              class="detail-link"
              href="https://ddocdoc.com/terms/hospital_privacy"
              target="blank"
              >자세히보기</a
            >
          </td>
        </tr>
      </table>
    </ng-container>
  </div>
  <div class="modal-wrap__footer" [ngClass]="{ init: !isCompletedPayments }">
    <button class="ha-btn ha-btn-secondary" (click)="handleCancel()">
      취소
    </button>
    <button
      class="ha-btn ha-btn-primary"
      [ngClass]="{
        'ha-btn-spec': !isCompletedPayments,
        'btn-dim': isNotAllowedTime
      }"
      type="submit"
      (click)="submitEvent.next()"
      [disabled]="this.paymentsSettingForm.invalid"
    >
      {{ submitBtnText }}
    </button>
  </div>
  <div [hidden]="hasAccountError$ | async" class="tosspay-error">
    {{ registerErrorKey$ | async | tossError }}
  </div>
</div>
