<div *ngIf="step === 1" class="modal-body step-1">
  <a href="https://landing.ddocdoc.co/evt/intro_popup_ct.html">자세히 보기></a>
  <img
    src="/assets/img/home/popup-image-register.png"
    alt="회원가입 유도팝업 이미지"
  />
  <div class="next-button-wrap">
    <button class="ha-btn ha-btn-primary btn-next" (click)="onNextStep(2)">
      병원 회원가입(추가인증)하고 우리병원 알리기
    </button>
  </div>
  <button class="btn-close" (click)="activeModal.dismiss()">
    <img src="/assets/img/btn-close.png" alt="닫기" />
  </button>
</div>

<div *ngIf="step === 2" class="modal-body">
  <app-account-create-terms
    (nextStep)="onTerms($event)"
  ></app-account-create-terms>
  <button class="btn-close" (click)="activeModal.dismiss()">
    <img src="/assets/img/btn-close.png" alt="닫기" />
  </button>
</div>

<div *ngIf="step === 3" class="modal-body">
  <app-account-create-forms
    (accountCreateSubmit)="onSubmit($event)"
  ></app-account-create-forms>
  <button class="btn-close" (click)="activeModal.dismiss()">
    <img src="/assets/img/btn-close.png" alt="닫기" />
  </button>
</div>

<div *ngIf="step === 4" class="modal-body step-4">
  <div class="create-complete">
    <div class="hospital-manager-logo">
      <i class="icon icon-ddocdoc-logo-hospital-manager"></i>
    </div>
    <div class="complete-wrap">
      <div>
        <i class="icon icon-complete"></i>
      </div>
      <div class="complete-text">
        똑닥 병원매니저 원장님 <b>인증이 신청되었습니다.</b>
      </div>
    </div>
    <div class="notice">
      ※ 정보 확인에 소요되는 시간은 <b>평일 기준 24시간 내외</b>입니다.
    </div>
  </div>
  <div class="next-button-wrap">
    <button class="ha-btn ha-btn-spec btn-next" (click)="activeModal.close()">
      확인
    </button>
  </div>
</div>
