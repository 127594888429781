import { createAction, props } from '@ngrx/store';
import {
  ExpenseDetailUiModel,
  ExpenseGroupUiModel,
  ExpenseParam,
  ExpenseTypeEnum,
  ExpenseUpdateReqParam,
} from '~models/expense.model';

export enum ExpenseActionType {
  EXPENSE_LIST_LOAD = '[Expense] Expense List Load',
  EXPENSE_LIST_LOAD_SUCCESS = '[Expense] Expense List Load Success',
  EXPENSE_LIST_ORDER_UPDATE = '[Expense] Expense List Order Update',
  EXPENSE_DETAIL_RESET = '[Expense] Expense Detail Reset',
  EXPENSE_DETAIL_LOAD = '[Expense] Expense Detail Load',
  EXPENSE_DETAIL_LOAD_SUCCESS = '[Expense] Expense Detail Load Success',
  EXPENSE_REGISTER = '[Expense] Expense Register Request',
  EXPENSE_REGISTER_SUCCESS = '[Expense] Expense Register Success',
  EXPENSE_UPDATE = '[Expense] Expense Update Request',
  EXPENSE_UPDATE_SUCCESS = '[Expense] Expense Update Success',
  EXPENSE_UPDATE_ACTIVE = '[Expense] Expense Update Active Value Request',
  EXPENSE_UPDATE_ACTIVE_SUCCESS = '[Expense] Expense Update Active Value Request Success',
  EXPENSE_DELETE = '[Expense] Expense Delete',
  EXPENSE_DELETE_SUCCESS = '[Expense] Expense Delete Success',
  REQ_FAIL = '[Expense] Request Fail',
}

export const ExpenseReqFail = createAction(
  ExpenseActionType.REQ_FAIL,
  props<{ payload: any }>()
);

export const ExpenseListLoad = createAction(
  ExpenseActionType.EXPENSE_LIST_LOAD
);

export const ExpenseListLoadSuccess = createAction(
  ExpenseActionType.EXPENSE_LIST_LOAD_SUCCESS,
  props<{ payload: { items: ExpenseGroupUiModel[]; totalCount: number } }>()
);

export const ExpenseListOrderUpdate = createAction(
  ExpenseActionType.EXPENSE_LIST_ORDER_UPDATE,
  props<{ payload: { type: ExpenseTypeEnum; items: string[] } }>()
);

export const ExpenseDetailLoad = createAction(
  ExpenseActionType.EXPENSE_DETAIL_LOAD,
  props<{ payload: string }>()
);

export const ExpenseDetailLoadSuccess = createAction(
  ExpenseActionType.EXPENSE_DETAIL_LOAD_SUCCESS,
  props<{ payload: ExpenseDetailUiModel }>()
);

export const ExpenseDetailReset = createAction(
  ExpenseActionType.EXPENSE_DETAIL_RESET
);

export const ExpenseRegister = createAction(
  ExpenseActionType.EXPENSE_REGISTER,
  props<{ payload: ExpenseParam }>()
);

export const ExpenseRegisterSuccess = createAction(
  ExpenseActionType.EXPENSE_REGISTER_SUCCESS
);

export const ExpenseUpdate = createAction(
  ExpenseActionType.EXPENSE_UPDATE,
  props<{ payload: ExpenseUpdateReqParam }>()
);

export const ExpenseUpdateActive = createAction(
  ExpenseActionType.EXPENSE_UPDATE_ACTIVE,
  props<{ payload: { id: string; active: boolean } }>()
);

export const ExpenseUpdateActiveSuccess = createAction(
  ExpenseActionType.EXPENSE_UPDATE_ACTIVE_SUCCESS,
  props<{ payload: { id: string; active: boolean } }>()
);

export const ExpenseUpdateSuccess = createAction(
  ExpenseActionType.EXPENSE_UPDATE_SUCCESS
);

export const ExpenseDelete = createAction(
  ExpenseActionType.EXPENSE_DELETE,
  props<{ payload: string }>()
);

export const ExpenseDeleteSuccess = createAction(
  ExpenseActionType.EXPENSE_DELETE_SUCCESS
);

export const ExpenseActions = {
  ExpenseListLoad,
  ExpenseListLoadSuccess,
  ExpenseListOrderUpdate,
  ExpenseReqFail,
  ExpenseDetailLoad,
  ExpenseDetailLoadSuccess,
  ExpenseDetailReset,
  ExpenseRegister,
  ExpenseRegisterSuccess,
  ExpenseUpdate,
  ExpenseUpdateSuccess,
  ExpenseUpdateActive,
  ExpenseUpdateActiveSuccess,
  ExpenseDelete,
  ExpenseDeleteSuccess,
};
