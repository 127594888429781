import { createAction, props } from '@ngrx/store';
import { TreatmentInfoUiModel } from '~models/treatment-room.model';

export const TreatmentInfoActionTypes = {
  TREATMENT_LIST_LOAD: '[TreatmentInfo] list load',
  TREATMENT_LIST_LOAD_SUCCESS: '[TreatmentInfo] list load Success',
  TREATMENT_LIST_CREATE: '[TreatmentInfo] list create',
  TREATMENT_LIST_CREATE_SUCCESS: '[TreatmentInfo] list create Success',
  TREATMENT_LIST_ADD: '[TreatmentInfo] list add',
  TREATMENT_LIST_DELETE: '[TreatmentInfo] list delete',
  TREATMENT_LIST_DELETE_SUCCESS: '[TreatmentInfo] list delete Success',
  TREATMENT_LIST_UPDATE: '[TreatmentInfo] list update',
  TREATMENT_LIST_UPDATE_SUCCESS: '[TreatmentInfo] list update Success',
  TREATMENT_CHANGE: '[TreatmentInfo] change',
  REQ_FAIL: '[TreatmentInfo] Req Fail',
} as const;

export const TreatmentReqFail = createAction(
  TreatmentInfoActionTypes.REQ_FAIL,
  props<any>()
);

export const TreatmentListLoad = createAction(
  TreatmentInfoActionTypes.TREATMENT_LIST_LOAD
);

export const TreatmentListLoadSuccess = createAction(
  TreatmentInfoActionTypes.TREATMENT_LIST_LOAD_SUCCESS,
  props<{ payload: TreatmentInfoUiModel[] }>()
);

export const TreatmentChange = createAction(
  TreatmentInfoActionTypes.TREATMENT_CHANGE,
  props<{ payload: TreatmentInfoUiModel; index: number }>()
);

export const TreatmentListUpdate = createAction(
  TreatmentInfoActionTypes.TREATMENT_LIST_UPDATE,
  props<{ payload: TreatmentInfoUiModel[] }>()
);

export const TreatmentListUpdateSuccess = createAction(
  TreatmentInfoActionTypes.TREATMENT_LIST_UPDATE_SUCCESS,
  props<{ payload: TreatmentInfoUiModel[] }>()
);

export const TreatmentInfoActions = {
  TreatmentReqFail,
  TreatmentListLoad,
  TreatmentListLoadSuccess,
  TreatmentChange,
  TreatmentListUpdate,
  TreatmentListUpdateSuccess,
};
