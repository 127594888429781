/**
 * 사전문진 답변 유형
 */
export enum QuestionUseOptionType {
  /**
   * YN만 사용
   */
  ONLY_YES_OR_NO = 'ONLY_YES_OR_NO',
  /**
   * yes를 눌렀을 경우 하위 상세답변 사용
   */
  INPUT_WHEN_CHOSE_YES = 'INPUT_WHEN_CHOSE_YES',
  /**
   * no를 선택했을 경우 하위 상세답변 사용
   */
  INPUT_WHEN_CHOSE_NO = 'INPUT_WHEN_CHOSE_NO',
  /**
   * 상세답변만 사용
   */
  ONLY_INPUT = 'ONLY_INPUT',
}

/**
 * 상세답변 형식
 */
export enum QuestionOptionType {
  /**
   * 주관식(입력형)
   */
  INPUT = 'INPUT',
  /**
   * 객관식(선택형)
   */
  SELECT = 'SELECT',
  /**
   * 객관식(중복선택형)
   */
  MULTI_SELECT = 'MULTI_SELECT',
}

/**
 * 사전문진 - 상세답변 주관식일 경우 키패드 형태
 */
export enum QuestionInputType {
  /**
   * 숫자만 입력
   */
  NUMBER = 'NUMBER',
  /**
   * 전부 가능
   */
  ALL = 'ALL',
}

export type QuestionnaireType =
  | 'RECEIPT'
  | 'RESERVATION'
  | 'KIOSK'
  | 'DEFAULT_NATIONAL_MEDICAL_EXMN'
  | string;

/**
 * 사전문진 요청 파라미터
 */
export interface QuestionDetailParams {
  /**
   * 진료실키
   */
  unitKey: string;
  /**
   * 사용 형태
   */
  type: QuestionnaireType;
}

/**
 * 사전문진 - 상세답변 목록 내용 (옵션)
 */
export interface QuestionDetailOptionItem {
  /**
   * 상세답변 형식
   */
  optionType: QuestionOptionType;
  /**
   * 상세답변 힌트 텍스트
   */
  hintText: string;
  /**
   * 상세답변 객관식 문항
   */
  selectText: string[];
  /**
   * 상세답변 주관식일 경우 키패드 형태
   */
  inputType: QuestionInputType;
  /**
   * 상세답변 값 단위
   */
  optionUnit: 'cm' | 'kg' | '℃' | 'cc' | 'mg' | string;
  /**
   * 키오스크 사전문진 여부
   */
  isKiosk?: boolean;
}

/**
 * 사전문진 - UI 에서 쓰이는 사전문진 문항
 */
export interface QuestionDetailItemModel {
  /**
   * 유저가 보는 문항 제목
   */
  userTitle: string;
  /**
   * EMR 표시 제목
   */
  emrTitle: string;
  /**
   * 문항 사용 여부
   */
  use: boolean;
  /**
   * 답변 유형
   */
  useOptionType: QuestionUseOptionType;
  /**
   * 상세 답변 개수
   */
  optionCount: number;
  /**
   * 상세답변 목록
   */
  options: QuestionDetailOptionItem[];
  /**
   * 기본 문항 여부
   */
  default?: boolean;
  /**
   * 건강 정보 추가 여부 (기본 문항 추가 시 존재)
   */
  healthInfoType?: string;
}

/**
 * 사전문진 조회 중 items 에 대응되는 목록 내용
 */
export interface QuestionDetailResItem extends QuestionDetailItemModel {
  /**
   * 사전문진 문항 ID
   */
  _id: string;
}

export interface KioskQuestionOptionUIModel extends QuestionDetailResItem {
  expanded: boolean;
  isDefault: boolean;
  isRequired: boolean;
  useChangeable: boolean;
  editable: boolean;
  isKiosk?: boolean;
}

export interface QuestionOptionRes {
  _id: string;
  hospitalId: string;
  unitKey: string;
  type: string;
  unitTitle: string;
  subject: string;
  items: QuestionDetailResItem[];
}

export interface QuestionDetailShareModel {
  /**
   * 진료실키
   */
  unitKey: string;
  /**
   * 접수/예약 여부
   */
  type: 'RECEIPT' | 'RESERVATION' | string;
  /**
   * 사전문진 사용여부
   */
  useMedicalQuestionnaire?: boolean;
}

/**
 * 사전문진 설정
 * https://boostbros.atlassian.net/wiki/spaces/BACKEND/pages/802816016/-
 */
export interface QuestionDetailUpdateParams extends QuestionDetailShareModel {
  /**
   * 사전문진 문항
   */
  items: QuestionDetailItemModel[];
  /**
   * 사전문항 키
   */
  questionnaireId?: string;
}

/**
 * 사전문진 조회 내용
 * https://boostbros.atlassian.net/wiki/spaces/BACKEND/pages/802750551/-
 */
export interface QuestionDetailRes extends QuestionDetailShareModel {
  /**
   * 사전문진 질문지 ID
   */
  _id: string;
  /**
   * 병원ID
   */
  hospitalId: string;
  /**
   * 사전문진 문항
   */
  items: QuestionDetailResItem[];
}

/**
 * 사전문진 - 문항에 대하여 UI 에서 쓰이는 모델.
 */
export interface QuestionDetailUiItemModel extends QuestionDetailItemModel {
  /**
   * 기본 문항 여부
   */
  isDefault: boolean; // Deprecated
  /**
   * 필수 문항 여부
   */
  isRequired: boolean; // Deprecated
  /**
   * 사용 변경 가능 여부
   */
  useChangeable: boolean; // Deprecated
  /**
   * 내용 편집 가능 여부
   */
  editable: boolean; // Deprecated
  /**
   * 펼쳐짐 여부
   */
  expanded: boolean;
}

/**
 * 사전문진 - 해당 페이지에서 쓰이는 UI 모델.
 */
export interface QuestionDetailUiModel extends QuestionDetailShareModel {
  /**
   * 사전문진 문항
   */
  items: QuestionDetailUiItemModel[];
}

export interface QuestionOptionCountChangeArgs {
  item: QuestionDetailUiItemModel;
  before: number;
  after: number;
}

/**
 * 이하의 모델 정의는 사전문진 고도화 이후의 모델입니다 *
 * 이하의 모델 정의는 사전문진 고도화 이후의 모델입니다 *
 */

/**
 * 사전문진표 모델
 *
 * https://boostbros.atlassian.net/wiki/spaces/BACKEND/pages/943685690/-
 * 해당 벡엔드 문서 'items 데이터' 참조
 */
export interface QuestionnaireRes {
  items: QuestionnaireResItem[];
  count: number;
  unitInfo: QuestionnaireUnitInfo;
}

export interface QuestionnaireUnitInfo {
  title: string;
  subject: string;
  useMedicalQuestionnaire: boolean;
}

export interface QuestionnaireResItem {
  /**
   * 사전문진표 ID
   */
  _id: string;
  /**
   * 병원 ID
   */
  hospitalId: string;
  /**
   * 진료실 키
   */
  unitKey: string;
  /**
   * 진료실 별 사전문진 질문지 순서
   */
  order: number;
  /**
   * 시작 허용 연령 (이상)
   */
  startAge: number;
  /**
   * 끝 허용 연령 (미만)
   */
  endAge: number;
  /**
   * 질문지 제목
   */
  title: string;
  /**
   * 허용 진료 항목 사용 여부 (미사용 시 전체 진료 항목, 사용시 symptiomCategoryId 입력)
   */
  useSymptomCategory: boolean;
  /**
   * 허용 진료 항목 아이디
   */
  symptomCategoryId?: string;
  /**
   * 허용 진료 항목 명 (전체 선택일경우 존재하지않음)
   */
  symptomName?: string;
  /**
   * 재접수 여부
   */
  reReception: boolean;
  /**
   * 재접수 판단 기간 (~이후)
   *
   * (TODO, 추가) 문서상 벡엔드 필드 명은 reReceiptReriod로 되어있으나 오타인거같아 reReceiptPeriod로 설정 했습니다. 추후 API 나오는거 보고 수정 필요합니다.
   */
  reReceptionPeriod?: number;
  /**
   * 처음 접수 여부
   */
  firstReception: boolean;
  /**
   * 접수/예약 여부
   */
  type: string;
  /**
   * 사전문진 사용여부
   */
  useMedicalQuestionnaire: boolean;
  /**
   * 사전문진 문항 리스트
   */
  items: QuestionDetailResItem[];
}

/**
 * 사전문진표 관리페이지 UI 모델
 */
export interface QuestionnaireUiModel {
  /**
   * 사전문진표 UI 리스트
   */
  questionnaires: QuestionnaireUiItemModel[];
  /**
   * 해당 진료실의 사전문진 관련 정보
   */
  unitInfo: QuestionnaireUnitInfo;
}

/**
 * 사전문진표 UI 모델
 */
export interface QuestionnaireUiItemModel extends QuestionnaireResItem {
  /**
   * 사전문진 문항 설정 상태 표시
   *
   * true 등록완료
   * false 미등록
   */
  isQuestionSet: boolean;
  /**
   * 사용중인 문항 개수
   */
  numberOfQuestionUse: number;
  /**
   * 대상 요약 텍스트 (예 : (진료항목 : 소아진료 / 연령범위 : 만 0세 이상 ~ 12세 미만 / 재접수 환자 : 3일 이내))
   */
  targetSummary: string;
}

/**
 * 사전문진 우선순위 리스트 조회 벡엔드 리스트 모델
 *
 * https://boostbros.atlassian.net/wiki/spaces/BACKEND/pages/943849513/-
 */
export interface QuestionnaireOrderRes {
  /**
   * 사전문진표 리스트 (순서 정렬 UI 표시만을 위한 데이터만 있습니다)
   */
  items: QuestionnaireOrderResItem[];
  /**
   * 사전문진표 개수
   */
  count: number;
}

/**
 * 사전문진 우선순위 리스트 조회 벡엔드 아이템 모델
 *
 * https://boostbros.atlassian.net/wiki/spaces/BACKEND/pages/943849513/-
 */
export interface QuestionnaireOrderResItem {
  /**
   * 사전문진표 고유 Id
   */
  questionnaireId: string;
  /**
   * 순서
   */
  order: number;
  /**
   * 사전문진표 타이틀
   */
  title: string;
}

export interface QuestionnaireTargetCreateReq {
  unitKey: string;
  type: string;
  startAge: number;
  endAge: number;
  title: string;
  useSymptomCategory: boolean;
  symptomCategoryId?: string;
  reReception: boolean;
  reReceptionPeriod?: number;
  firstReception: boolean;
}

/**
 * 사전문진 대상 벡엔드 모델
 *
 * https://boostbros.atlassian.net/wiki/spaces/BACKEND/pages/943751169/-
 */
export interface QuestionnaireTargetRes {
  /**
   * 진료실 키
   */
  unitKey: string;
  /**
   * 접수/예약 여부
   */
  type: string;
  /**
   * 사전문진 질문지 순서
   */
  order: number;
  /**
   * 시작 허용 연령
   */
  startAge: number;
  /**
   * 끝 허용 연령
   */
  endAge: number;
  /**
   * 질문지 제목
   */
  title: string;
  /**
   * 허용 진료 항목 사용 여부
   * 사용시 symptomCategoryId 입력
   * 미사용 시 전체 진료 항목 사용
   */
  useSymptomCategory: boolean;
  /**
   * 허용 진료 항목 아이디
   */
  symptomCategoryId?: string;
  /**
   * 재접수 여부
   */
  reReception: boolean;
  /**
   * 재접수 판단 기간 (이후)
   */
  reReceptionPeriod?: number;
  /**
   * 처음 접수 여부
   */
  firstReception: boolean;
}

/**
 * 사전문진표 업데이트 Request 모델
 */
export interface QuestionnaireTargetReq extends QuestionnaireTargetRes {
  /**
   * 사전문진표 고유 Id
   */
  questionnaireId?: string;
}

/**
 * 사전문진 대상 Ui 모델
 */
export interface QuestionnaireTargetUiModel extends QuestionnaireTargetRes {
  additionalOption: string;
}

export interface QuestionnaireTargetUiQueryModel {
  /**
   * 진료실 키
   */
  unitKey: string;
  /**
   * 접수/예약 여부
   */
  type: string;
  /**
   * 시작 허용 연령
   */
  startAge: number;
  /**
   * 끝 허용 연령
   */
  endAge: number;
  /**
   * 질문지 제목
   */
  title: string;
  /**
   * 허용 진료 항목 사용 여부
   * 사용시 symptomCategoryId 입력
   * 미사용 시 전체 진료 항목 사용
   */
  useSymptomCategory: boolean;
  /**
   * 허용 진료 항목 아이디
   */
  symptomCategoryId?: string;
  /**
   * 재접수 여부
   */
  reReception?: boolean;
  /**
   * 재접수 판단 기간 (이후)
   */
  reReceptionPeriod?: number;
  /**
   * 처음 접수 여부
   */
  firstReception?: boolean;
  /**
   * (UI전용) 사전문진 추가 옵션 선택 값
   */
  additionalOption: string;
}

export interface QuestionnaireOrderUpdateParams {
  items: QuestionnaireUiItemModel[];
  unitKey: string;
  type: string;
}

/**
 * 사전문진 문항 조회 파라미터
 */
export interface QuestionnaireDetailParams {
  /**
   * 진료실키
   */
  unitKey?: string;
  /**
   * 사용 형태
   */
  type?: QuestionnaireType;
  /**
   * 문항 Id
   */
  questionKey: string;
}

/**
 * 사전문진 진료실 Response 모델
 *
 * https://boostbros.atlassian.net/wiki/spaces/BACKEND/pages/960593921/-
 */
export interface QuestionnaireCareRoomRes {
  items: QuestionnaireCareRoomResItem[];
  totalCount: number;
}

/**
 * 사전문진 진료실 Response Item 모델
 *
 * https://boostbros.atlassian.net/wiki/spaces/BACKEND/pages/960593921/-
 */
export interface QuestionnaireCareRoomResItem {
  /**
   * 병원 아이디
   */
  hospitalId: string;
  /**
   * 진료 타입
   */
  type: string;
  /**
   * 진료실 이름
   */
  title: string;
  /**
   * 진료실 키
   */
  unitKey: string;
  /**
   * 대표 진료과목
   */
  subject: string;
  /**
   * 해당 진료실이 사용 가능한지 검증한 값
   *
   * 사용중 AVAILABLE
   * 사용불가
   *   - 사전문진 진료실 설정 값 없음 : REQUIRE_TREATMENT_OPTION
   *   - 사전문진 대상 없음 : REQUIRE_TARGET
   *   - 사전문진 문항 없음 : REQUIRE_ITEM
   */
  checkMedicalQuestionnaire: string;
  /**
   * 허용 진료 항목 아이디
   *
   * 접수 - RECEIPT
   * 예약 - RESERVATION
   */
  useMedicalQuestionnaire: string[];
}

export interface QuestionnaireCareRoomItemUiModel
  extends QuestionnaireCareRoomResItem {
  /**
   * 사전문진표 사용 여부
   *
   * 사용중/미사용
   */
  useQuestionnaire: boolean;
}

export interface QuestionnaireResModel extends QuestionOptionRes {
  title: string;
  unitKey: string;
}

export interface QuestionSubjectResItem {
  _id: string;
  name: string;
}

export interface QuestionSubjectUiItem {
  text: string;
  value: string;
}

export interface QuestionnaireListResModel {
  items: QuestionnaireResItem[];
}

/**
 * 진료실 별 진료항목 사용여부 (접수/예약 진료항목 설정에서 유효성 체크용으로 쓰임) Request
 */
export interface QuestionCheckUseSymptomReq extends QuestionDetailParams {
  symptomCategoryId: string;
}

/**
 * 진료실 별 진료항목 사용여부 (접수/예약 진료항목 설정에서 유효성 체크용으로 쓰임) Response
 */
export interface QuestionCheckUseSymptomRes {
  questionnaireAvailability: boolean;
  hospitalCrmAvailability: boolean;
}
