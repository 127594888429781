import { createSelector } from '@ngrx/store';
import { selectProductState } from '~reducers/products';
import { get as _get } from 'lodash';

export const selectPayments = createSelector(
  selectProductState,
  state => state.payments
);

export const getPaymentsSettingLoaded = createSelector(
  selectPayments,
  state => state.loaded
);

export const getPaymentsRegisterLoaded = createSelector(
  selectPayments,
  state => state.isLoadedRegister
);
export const getPaymentsUpdateSettingLoaded = createSelector(
  selectPayments,
  state => state.isLoadedUpdateSetting
);
export const getPaymentsUpdateActivationDateLoaded = createSelector(
  selectPayments,
  state => state.isLoadedUpdateActivationDate
);

export const getPaymentsActive = createSelector(selectPayments, state => {
  return _get(state, 'paymentSetting.active', false);
});

export const getPaymentsSetting = createSelector(
  selectPayments,
  state => state.paymentSetting
);

export const getPaymentsLogs = createSelector(
  selectPayments,
  state => state.logs
);

export const getPaymentsBankCodes = createSelector(
  selectPayments,
  state => state.bankCodes
);

export const getPaymentsRegisterErrorKey = createSelector(
  selectPayments,
  state => state.registerErrorKey
);

export const getPaymentsDefaultAvailableDate = createSelector(
  selectPayments,
  state => state.defaultAvailableDate
);
