import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

import { makeHyphenPhoneNumber } from '../utils/text-format';

@Directive({
  selector: '[appPhoneNumAutoHyphen]',
})
export class PhoneNumAutoHyphenDirective {
  constructor(public ngControl: NgControl) {}

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    const input = event;
    if (!input) {
      return;
    }

    const autoHypenPhoneNum = makeHyphenPhoneNumber(input);

    if (this.ngControl.control.value !== autoHypenPhoneNum) {
      this.ngControl.control.setValue(autoHypenPhoneNum); // 13자리일때 010-0000-0000 형식으로 저장되어야하는데 값이 안바뀌여서 강제로 setValue하도록 했습니다
    }
    // input.value = tmp;
  }
}
