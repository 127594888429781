<article [ngStyle]="articleStyle">
  <figure
    *ngIf="imageSrc"
    class="guide__background"
    [ngStyle]="imageInlineOptions"
  >
    <img src="{{ imageSrc }}" />
  </figure>
  <section class="guide__content">
    <ng-content></ng-content>
  </section>
</article>
