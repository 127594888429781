import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { set as _set } from 'lodash';
import { TokenProvider } from './token.provider';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private token: TokenProvider) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let header = {
      Authorization: `Bearer ${this.token.get()}`,
    };

    if (req.body instanceof FormData) {
      // header['Content-Type'] = 'multipart/form-data';
      // header['Content-Type'] = 'application/x-www-form-urlencoded';
    } else {
      header = _set(header, 'Content-Type', 'application/json');
    }

    req = req.clone({
      setHeaders: header,
    });
    return next.handle(req);
  }
}
