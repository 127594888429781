import { HashMap, SchedulerTags } from '~models/common.model';
import {
  BaseTimelineData,
  Holiday,
  HourItem,
  HourRange,
  Schedule,
  ScheduleItemGroup,
  SymptomUnit,
  TimelineScheduleResData,
  TimeRow,
} from '~models/reservation-timeline.model';
import { arrayToMap, makeNumberArray } from '~shared/service/util';
import { ResTimelineActions } from '~actions/products/res-timeline-schedule.actions';
import {
  addColorFromSymptomUnit,
  getColorNoList,
  removeAllColorFromSymptomUnit,
  removeColorFromSymptomUnit,
} from '../../services/res-timeline-schedule/res-timeline-schedule.color';
import {
  copyScheduleByDays,
  copyScheduleByWeeks,
  copyTimelineByDays,
  copyTimelineByWeeks,
} from '../../services/res-timeline-schedule/res-timeline-schedule.copy';
import {
  getCopyCompleteMessage,
  getTimelinePreviewMessage,
  MSG_TT_SCHEDULE_LOAD_SUCCESS,
} from '../../services/res-timeline-schedule/res-timeline-schedule.msg';
import {
  applySymptomUnitToSchedulesByHourRange,
  changeSchedules,
  modifySchedules,
  removeSchedules,
  removeSchedulesBySymptomUnit,
} from '../../services/res-timeline-schedule/res-timeline-schedule.schedules';
import {
  applySymptomUnitToTimelineByHourRange,
  applySymptomUnitToTimeRowsForPreview,
  changeTimelineInfo,
  clearTimelineInfo,
  initTimeRowsForPreview,
  modifyTimelineInfoListByState,
  removeTimelineInfo,
  removeTimelineInfoBySymptomUnit,
} from '../../services/res-timeline-schedule/res-timeline-schedule.timelineinfo';
import {
  chooseRightWeekNumber,
  hasHoliday,
} from '../../services/res-timeline-schedule/res-timeline-schedule.util';
import { createReducer, on } from '@ngrx/store';
import { cloneDeep as _cloneDeep } from 'lodash';

export interface ResTimelineScheduleState extends BaseTimelineData {
  week: number;
  userHourRange: HourRange;
  schedules: HashMap<Schedule>;
  hourTimes: HourItem[];
  counts: number[];
  timeUnitList: number[];
  colorList: number[];
  scheduleItemGroups: ScheduleItemGroup[];
  previewTimeRows: TimeRow[];
  loading: boolean;
  message: string;
  hasModified: boolean;
  previewMessage: string;
  existsMap: { [month: string]: boolean };
  prevExistsMap: { [month: string]: boolean };
  nextExistsMap: { [month: string]: boolean };
  prevHolidayMap: { [day: string]: Holiday };
  usedTags: SchedulerTags[];
  monthsTag: { [month: string]: SchedulerTags };
}

function getInitScheduleData(): TimelineScheduleResData {
  return {
    unitKey: '',
    monthKey: '',
    _id: '',
    hospital: '',
    preset: {
      symptomUnit: [],
    },
    schedules: [],
    version: 1,
    tags: SchedulerTags.V1,
  };
}

function getInitState(): ResTimelineScheduleState {
  const now = new Date();

  return {
    week: 1,
    year: now.getFullYear(),
    month: now.getMonth() + 1,
    selectedSymptomUnit: {} as SymptomUnit,
    scheduleData: getInitScheduleData(),
    symptomUnits: [],
    symptomItems: [],
    symptomMap: {},
    existsMap: {},
    prevExistsMap: {},
    nextExistsMap: {},
    prevHolidayMap: {},
    userHourRange: {
      start: '00:00',
      end: '00:00',
      weekdays: [],
    },
    timelineInfoList: [],
    schedules: {},
    hourRange: { start: '00:00', end: '00:00' },
    hourItems: [],
    timeUnit: 5,
    timeUnitHeader: [],
    hourTimes: [],
    colorList: getColorNoList(),
    counts: makeNumberArray(1, 5),
    timeUnitList: [5, 10, 15, 20, 30, 60],
    loading: false,
    message: '',
    openTimeMap: {},
    holidayMap: {},
    scheduleItemGroups: [],
    previewTimeRows: initTimeRowsForPreview(),
    hasModified: false,
    previewMessage: '',
    usedTags: null,
    monthsTag: null,
  };
}

let selectedSymptomUnit: SymptomUnit;

export const reducer = createReducer(
  getInitState(),
  on(
    ResTimelineActions.TimelineBaseLoad,
    ResTimelineActions.TimelineScheduleLoad,
    ResTimelineActions.TimelineScheduleUpdate,
    ResTimelineActions.TimelineExistsLoad,
    ResTimelineActions.TimelineScheduleCopyPrevMonthPrepare,
    (state, action) => ({
      ...state,
      loading: true,
      message: '',
    })
  ),
  on(ResTimelineActions.TimelineBaseLoadSuccess, (state, action) => ({
    ...state,
    ...action.payload,
    week: chooseRightWeekNumber(
      action.currWeek,
      state.week,
      action.payload.timelineInfoList.length
    ),
    colorList: removeAllColorFromSymptomUnit(
      getColorNoList(),
      action.payload.symptomUnits
    ),
    schedules: arrayToMap(action.payload.scheduleData.schedules, 'day'),
    // previewTimeRows: initTimeRowsForPreview(),
    previewTimeRows: applySymptomUnitToTimeRowsForPreview(
      action.payload.timeUnitHeader,
      action.payload.timeUnit,
      action.payload.hourRange,
      action.payload.selectedSymptomUnit
    ),
    previewMessage: '',
    loading: false,
    message: action.payload.scheduleData.warningMessage || '',
    hasModified: action.payload.scheduleData.hasModified || false,
  })),
  on(ResTimelineActions.TimelineScheduleLoadSuccess, (state, action) => ({
    ...state,
    ...action.payload,
    colorList: removeAllColorFromSymptomUnit(
      getColorNoList(),
      action.payload.symptomUnits
    ),
    // week: 1, // 자료를 불러 왔을 때는 무조건 1주차 부터 보여줌
    scheduleData: action.payload.scheduleData,
    schedules: arrayToMap(action.payload.scheduleData.schedules, 'day'),
    prevExistsMap: {},
    prevHolidayMap: {},
    nextExistsMap: {},
    loading: false,
    message: MSG_TT_SCHEDULE_LOAD_SUCCESS,
    hasModified: true,
  })),
  on(
    ResTimelineActions.TimelineScheduleCopyPrevMonthPrepare,
    (state, action) => ({ ...state, loading: true })
  ),
  on(
    ResTimelineActions.TimelineScheduleCopyPrevMonthPrepared,
    (state, action) => ({
      ...state,
      ...action.payload,
      loading: false,
    })
  ),
  on(
    ResTimelineActions.TimelineScheduleCopyPrevMonthWeeklySelected,
    (state, action) => ({ ...state, nextExistsMap: action.payload })
  ),
  on(ResTimelineActions.TimelineScheduleUpdateSuccess, (state, action) => ({
    ...state,
    loading: false,
    // message: action.background ? '' : MSG_TT_SCHEDULE_SAVE_SUCCESS,
    message: '',
    hasModified: false,
  })),
  on(ResTimelineActions.TimelineExistsLoadSuccess, (state, action) => ({
    ...state,
    loading: false,
    existsMap: action.payload,
    usedTags: action.usedTags,
    monthsTag: action.monthsTag,
  })),
  on(
    ResTimelineActions.TimelineBaseLoadFail,
    ResTimelineActions.TimelineScheduleLoadFail,
    ResTimelineActions.TimelineScheduleUpdateFail,
    ResTimelineActions.TimelineExistsLoadFail,
    ResTimelineActions.TimelineScheduleCopyPrevMonthPrepareFail,
    (state, action) => {
      return {
        ...state,
        loading: false,
        message: action.message,
      };
    }
  ),
  on(ResTimelineActions.TimelineSymptomUnitSelect, (state, action) => ({
    ...state,
    selectedSymptomUnit: action.payload,
  })),
  on(ResTimelineActions.TimelineSymptomUnitAdd, (state, action) => {
    const symptomUnits = [...state.symptomUnits, action.payload];
    // symptomUnits.push(action.payload);
    selectedSymptomUnit = action.dontSelected
      ? ({} as SymptomUnit)
      : action.payload;
    return {
      ...state,
      colorList: removeColorFromSymptomUnit(state.colorList, action.payload),
      previewTimeRows: applySymptomUnitToTimeRowsForPreview(
        state.timeUnitHeader,
        state.timeUnit,
        state.hourRange,
        selectedSymptomUnit
      ),
      previewMessage: getTimelinePreviewMessage(
        state.hourRange,
        selectedSymptomUnit
      ),
      selectedSymptomUnit,
      symptomUnits,
      hasModified: true,
    };
  }),
  on(ResTimelineActions.TimelineSymptomUnitRemove, (state, action) => {
    const newSymptomUnits = state.symptomUnits.filter(
      su => su.symptom !== action.payload.symptom
    );
    // symptomUnits = newSymptomUnits;

    if (
      selectedSymptomUnit &&
      action.payload &&
      selectedSymptomUnit.symptom === action.payload.symptom
    ) {
      selectedSymptomUnit = {} as SymptomUnit;
      // if (symptomUnits.length === 0) {
      // } else {
      //   selectedSymptomUnit = symptomUnits[0];
      // }
    }

    return {
      ...state,
      colorList: addColorFromSymptomUnit(state.colorList, action.payload),
      selectedSymptomUnit,
      symptomUnits: newSymptomUnits,
      schedules: removeSchedulesBySymptomUnit(state.schedules, action.payload),
      timelineInfoList: removeTimelineInfoBySymptomUnit(
        state.timelineInfoList,
        action.payload
      ),
      previewTimeRows: selectedSymptomUnit
        ? applySymptomUnitToTimeRowsForPreview(
            state.timeUnitHeader,
            state.timeUnit,
            state.hourRange,
            selectedSymptomUnit
          )
        : initTimeRowsForPreview(),
      previewMessage: getTimelinePreviewMessage(
        state.hourRange,
        selectedSymptomUnit
      ),
      hasModified: true,
    };
  }),
  on(ResTimelineActions.TimelineSymptomUnitChange, (state, action) => {
    const symptomUnitsMapped = state.symptomUnits.map(unit => {
      if (unit.symptom === action.payload.symptom) {
        selectedSymptomUnit = {
          ...action.payload,
          colorNo: unit.colorNo,
        };

        return selectedSymptomUnit;
      }

      return unit;
    });

    return {
      ...state,
      selectedSymptomUnit,
      symptomUnits: symptomUnitsMapped,
      hasModified: true,
    };
  }),
  on(ResTimelineActions.TimelineSymptomUnitApply, (state, action) => ({
    ...state,
    selectedSymptomUnit: action.payload.symptomUnit,
    timelineInfoList: applySymptomUnitToTimelineByHourRange(
      _cloneDeep(state),
      action.payload.symptomUnit
    ),
    schedules: applySymptomUnitToSchedulesByHourRange(
      _cloneDeep(state),
      action.payload.symptomUnit
    ),
    hasModified: true,
  })),
  on(ResTimelineActions.TimelineDateSelect, (state, action) => ({
    ...state,
    ...action.payload,
  })),
  on(ResTimelineActions.TimelineHourRangeApply, (state, action) => ({
    ...state,
    hourRange: action.payload,
  })),
  on(ResTimelineActions.TimelineWeekSelect, (state, action) => ({
    ...state,
    week: action.payload,
  })),
  on(ResTimelineActions.TimelineScheduleAdd, (state, action) => {
    const modifiedSchedules = modifySchedules(
      _cloneDeep(state),
      action.payload
    );
    return {
      ...state,
      timelineInfoList: modifyTimelineInfoListByState(
        _cloneDeep(state),
        action.payload
      ),
      schedules: modifiedSchedules,
      hasModified: true,
    };
  }),
  on(ResTimelineActions.TimelineScheduleRangeAdded, (state, action) => ({
    ...state,
    ...action.payload,
    hasModified: true,
  })),
  on(ResTimelineActions.TimelineScheduleRemove, (state, action) => ({
    ...state,
    timelineInfoList: removeTimelineInfo(_cloneDeep(state), action.payload),
    schedules: removeSchedules(_cloneDeep(state), action.payload),
    hasModified: true,
  })),
  on(ResTimelineActions.TimelineScheduleBatchRemoved, (state, action) => ({
    ...state,
    ...action.payload,
    hasModified: true,
  })),
  on(ResTimelineActions.TimelineScheduleChange, (state, action) => ({
    ...state,
    timelineInfoList: changeTimelineInfo(_cloneDeep(state), action.payload),
    schedules: changeSchedules(_cloneDeep(state), action.payload),
    hasModified: true,
  })),
  on(ResTimelineActions.TimelineScheduleClear, (state, action) => ({
    ...state,
    timelineInfoList: clearTimelineInfo(_cloneDeep(state).timelineInfoList),
    schedules: {},
    hasModified: true,
  })),
  on(ResTimelineActions.TimelineCalendarInit, (state, action) => ({
    ...state,
    scheduleItemGroups: [],
  })),
  on(ResTimelineActions.TimelineScheduleGroups, (state, action) => ({
    ...state,
    scheduleItemGroups: action.payload,
  })),
  on(ResTimelineActions.TimelineScheduleCopyDays, (state, action) => ({
    ...state,
    timelineInfoList: copyTimelineByDays(_cloneDeep(state), action.payload),
    schedules: copyScheduleByDays(_cloneDeep(state), action.payload),
    hasModified: true,
    message: getCopyCompleteMessage(),
  })),
  on(ResTimelineActions.TimelineScheduleCopyWeeks, (state, action) => ({
    ...state,
    timelineInfoList: copyTimelineByWeeks(_cloneDeep(state), action.payload),
    schedules: copyScheduleByWeeks(_cloneDeep(state), action.payload),
    hasModified: true,
    message: getCopyCompleteMessage(
      'week',
      hasHoliday(_cloneDeep(state), action.payload.dist)
    ),
  })),
  on(ResTimelineActions.TimelinePreviewApply, (state, action) => {
    const { hourRange, symptomUnit } = action.payload;
    const currentHourRange = hourRange ?? state.hourRange;
    const currentSymptomUnit = symptomUnit ?? state.selectedSymptomUnit;
    return {
      ...state,
      hourRange: currentHourRange,
      previewTimeRows: applySymptomUnitToTimeRowsForPreview(
        state.timeUnitHeader,
        state.timeUnit,
        currentHourRange,
        currentSymptomUnit
      ),
      previewMessage: getTimelinePreviewMessage(
        currentHourRange,
        currentSymptomUnit
      ),
    };
  }),
  on(ResTimelineActions.TimelineMessageClear, (state, action) => ({
    ...state,
    message: '',
  })),
  on(ResTimelineActions.TimelineResetExistsTags, (state, action) => ({
    ...state,
    existsMap: {},
  }))
);
