import * as moment from 'moment';
import { SchedulerDate } from '~models/reservation-schedule-v2.model';

export const enum ParsedTimeNameEum {
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  WEEK_OF_MONTH = 'WEEK_OF_MONTH',
  TOTAL_WEEK_OF_MONTH = 'TOTAL_WEEK_OF_MONTH',
}

export const timeParser = (
  date: SchedulerDate,
  name: keyof typeof ParsedTimeNameEum
) => {
  const cloneDate = moment(date).clone();

  if (name === ParsedTimeNameEum.YEAR) {
    return cloneDate.year();
  }

  if (name === ParsedTimeNameEum.MONTH) {
    return cloneDate.month() + 1;
  }

  /**
   * @description
   * 해당 달의 총 주차 수
   */
  const isJanuary = moment(date).month() + 1 === 1;
  const totalStartWeek = isJanuary
    ? 0
    : moment(date)
        .clone()
        .startOf('month')
        .isoWeek();

  const totalWeek =
    moment(date)
      .clone()
      .endOf('month')
      .isoWeek() -
    totalStartWeek +
    1;

  if (name === ParsedTimeNameEum.TOTAL_WEEK_OF_MONTH) {
    return totalWeek;
  }

  /**
   * @description
   * 해당 달의 해당 날짜가 몇 주차인지
   * 1월은 originWeek가 12월달로 책정되어 음수로 나오고, 1월의 총 주차에 따라 1주 차이가 있음
   */
  const originWeek =
    cloneDate.isoWeek() -
    moment(date)
      .clone()
      .startOf('month')
      .isoWeek() +
    1;
  const week =
    originWeek < 0 && totalWeek > 5
      ? originWeek + 52
      : originWeek < 0
      ? originWeek + 53
      : originWeek;

  if (name === ParsedTimeNameEum.WEEK_OF_MONTH) {
    return week;
  }
};

export const momentToStringParser = (date: SchedulerDate) => {
  return date.format('YYYY-MM-DD');
};
