import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * 기존 limited-input-text를 form 에서 쓰기 편하도록 rx 관련 핸들러를 제거한 input
 */
@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputTextComponent),
    },
  ],
})
export class InputTextComponent implements AfterViewInit, ControlValueAccessor {
  @ViewChild('inputWrap') inputWrap: ElementRef<HTMLDivElement>;
  @ViewChild('errorMessageSpan') errorMessageSpan: ElementRef<HTMLSpanElement>;

  @Input() value: string;
  @Input() placeholder = '입력해주세요';
  @Input() name: string;
  @Input() maxLength?: number | null = null;
  @Input() showTextLengthIndicator = false;
  @Input() disabled = false;
  @Input() hasError = false;
  @Input() errorMessage?: string;

  @Output() inputChange = new EventEmitter<string>();

  onChange: (text: string) => void;
  onTouched: () => void;

  constructor(private renderer2: Renderer2) {}

  ngAfterViewInit(): void {
    const inputWrapElem = this.inputWrap.nativeElement;
    const errorMessageSpanElem = this.errorMessageSpan.nativeElement;
    this.renderer2.setStyle(
      errorMessageSpanElem,
      'left',
      `${inputWrapElem.offsetWidth + 10}px`
    );
  }

  handleValueChange(changeValue: string) {
    this.onChange?.(changeValue);
    this.inputChange.emit(changeValue);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    this.value = value;
  }
}
