import { createAction, props } from '@ngrx/store';
import { PopupInfo } from '~models/popup.model';

export const PopupActionTypes = {
  POPUP_LOAD: '[POPUP] Popup Load',
  POPUP_LOAD_SUCCESS: '[POPUP] Popup Load Success',
  POPUP_LOAD_FAILURE: '[POPUP] Popup Load Failure',
} as const;

export const PopupLoad = createAction(PopupActionTypes.POPUP_LOAD);

export const PopupLoadSuccess = createAction(
  PopupActionTypes.POPUP_LOAD_SUCCESS,
  props<{ payload: { popupInfoItems: PopupInfo[] } }>()
);

export const PopupLoadFailure = createAction(
  PopupActionTypes.POPUP_LOAD_FAILURE
);

export const PopupActions = { PopupLoad, PopupLoadSuccess, PopupLoadFailure };
