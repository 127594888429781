import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RadioComponent),
    },
  ],
})
export class RadioComponent implements OnInit, ControlValueAccessor {
  @Input() name: string;
  @Input() value = '1';
  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Input() tooltip: any;
  @Input() tooltipPlacement = 'right';

  @Output() checkedChange = new EventEmitter<string>();

  onChange: (value: string) => void;
  onTouched: () => void;

  constructor() {}

  ngOnInit() {}

  handleChange(event: any) {
    const checked = event.target.checked;

    if (!checked) {
      return;
    }
    this.checkedChange.emit(this.value);
    this.onChange?.(this.value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    if (this.value === value) {
      this.checked = true;
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
