import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DualTimeRange } from '~models/common';

const INVALID_TEXT_FOR_WORK = '휴진';
const INVALID_TEXT_FOR_MEAL_TIME = '설정 안 함';

function toFormatted(time: Date) {
  try {
    if (!time) {
      return '';
    }

    return (
      (time.getHours() < 12 ? '오전 ' : '오후 ') + moment(time).format('hh:mm')
    );
  } catch (error) {
    return '';
  }
}

type TimeType = 'WORK' | 'MEAL';

@Pipe({
  name: 'dualTimeFormat',
})
export class DualTimeFormatPipe implements PipeTransform {
  transform(
    range: DualTimeRange,
    type: TimeType = 'WORK',
    hasSchedule: boolean = true
  ): string {
    const INVALID_TEXT = (() => {
      switch (type) {
        case 'MEAL':
          return INVALID_TEXT_FOR_MEAL_TIME;
        case 'WORK':
          return hasSchedule ? INVALID_TEXT_FOR_WORK : '설정 안 함';
      }
    })();
    try {
      if (!range.startTime || !range.endTime) {
        return INVALID_TEXT;
      }
      const start = toFormatted(range.startTime);
      const end = toFormatted(range.endTime);

      if (!start || !end) {
        return INVALID_TEXT;
      }

      return start + ' ~ ' + end;
    } catch (error) {
      return INVALID_TEXT;
    }
  }
}
