import { HospitalSettingActions } from '~actions/products/hospital-setting.actions';
import { OperatingTimeModel } from '~models/hospital.model';
import { errorMessage } from '~shared/service/util';
import { createReducer, on } from '@ngrx/store';

export interface OperatingState {
  loading: boolean;
  loaded: boolean;
  error: string | null;
  data?: OperatingTimeModel;
}

const initialState: OperatingState = {
  loaded: false,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(HospitalSettingActions.OperatingTimeLoad, (state, action) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(HospitalSettingActions.OperatingTimeLoadSuccess, (state, action) => ({
    data: action.payload.operating,
    loading: false,
    error: null,
    loaded: true,
  })),
  on(HospitalSettingActions.OperatingTimeLoadFail, (state, action) => ({
    ...state,
    loading: false,
    error: errorMessage(action.payload, 'opertime.red43'),
    loaded: false,
  })),
  on(HospitalSettingActions.OperatingTimeUpdate, (state, action) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(HospitalSettingActions.OperatingTimeUpdateSuccess, (state, action) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(HospitalSettingActions.OperatingTimeUpdateFail, (state, action) => ({
    ...state,
    loading: false,
    error: errorMessage(action.payload, 'opertime.red67'),
  }))
);

export const getLoaded = (state: OperatingState) => state.loaded;
export const getOperatingData = (state: OperatingState) => state.data;
export const getError = (state: OperatingState) => state.error;
export const getLoading = (state: OperatingState) => state.loading;
