import { StatusCanceledActions } from '~actions/products/status-canceled.action';
import {
  ReceptionCanceledResItem,
  StatusCanceledSearchUiQuery,
} from '~models/status-canceled.model';
import { createStatusCanceledSearchUiQuery } from '../../services/status/canceled.create';
import { createReducer, on } from '@ngrx/store';

export interface StatusCanceledState {
  query: StatusCanceledSearchUiQuery; // StatusPenaltySearchUiQuery;
  items: ReceptionCanceledResItem[]; // ReceptionPenaltyResItem[];
  totalCount: number;
  pending: boolean;
}

export function getInitState(): StatusCanceledState {
  return {
    query: createStatusCanceledSearchUiQuery(),
    items: [],
    totalCount: 0,
    pending: false,
  };
}

export const reducer = createReducer(
  getInitState(),
  on(StatusCanceledActions.StatusCanceledLoadList, (state, action) => ({
    ...state,
    query: action.payload,
    pending: true,
  })),
  on(StatusCanceledActions.StatusCanceledLoadListSuccess, (state, action) => ({
    ...state,
    items: action.payload.items,
    totalCount: action.payload.totalCount,
    pending: false,
  })),
  on(StatusCanceledActions.StatusCanceledFail, (state, action) => ({
    ...state,
    pending: false,
  }))
);
