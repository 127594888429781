import { Params } from '@angular/router';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';

import { State as AppState } from '../app.reducer';

export { RouterReducerState, routerReducer };

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

export const selectRouterState = (state: AppState) => state.router;

export const getRouteState = createSelector(
  selectRouterState,
  routerState => routerState.state
);

export const getRouteParams = createSelector(
  getRouteState,
  state => state.params
);
