import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-filter-panel',
  templateUrl: './search-filter-panel.component.html',
  styleUrls: ['./search-filter-panel.component.scss'],
})
export class SearchFilterPanelComponent implements OnInit {
  @Input() pending = false;
  @Input() overflowHidden = false;
  @Output() search = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}

  handleSubmit(event: any) {
    event.preventDefault();
    event.stopPropagation();

    this.search.emit();
  }
}
