import { Component, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-signin-youtube-trigger',
  templateUrl: './signin-youtube-trigger.component.html',
  styleUrls: ['./signin-youtube-trigger.component.scss'],
})
export class SigninYoutubeTriggerComponent implements OnInit, OnDestroy {
  isMobile = false;
  isMouseOver = false;
  isOpenYoutubeOverlay = false;
  youtubeOverlaySize = { width: 0, height: 0 };

  onDestroy = new Subject<void>();

  get iconClassName(): string {
    switch (true) {
      case this.isMobile && this.isMouseOver:
        return 'icon icon-circle5 active';
      case this.isMobile && !this.isMouseOver:
        return 'icon icon-circle5';
      case !this.isMobile && this.isMouseOver:
        return 'icon icon-circle4 active';
      case !this.isMobile && !this.isMouseOver:
        return 'icon icon-circle4';
    }
  }

  constructor(private elementRef: ElementRef) {}

  getYoutubeOverlaySize(): { width: number; height: number } {
    const width = window.innerWidth;

    if (width < 640) {
      return {
        width: width - 30,
        height: Math.floor((width - 30) * (9 / 16)),
      };
    }
    if (width < 1025) {
      return {
        width: 640,
        height: 360,
      };
    }
    return {
      width: 1000,
      height: 563,
    };
  }

  onToggleMouseOver(isMouseOver: boolean): void {
    this.isMouseOver = isMouseOver;
  }

  onToggleOpenYoutubeOverlay(isOpen: boolean): void {
    this.isOpenYoutubeOverlay = isOpen;
    this.elementRef.nativeElement.closest('body').style.overflow = isOpen
      ? 'hidden'
      : 'auto';
  }

  ngOnInit(): void {
    const innerWidth = window.innerWidth;

    this.isMobile = innerWidth < 768;
    this.youtubeOverlaySize = this.getYoutubeOverlaySize();

    fromEvent(window, 'resize')
      .pipe(debounceTime(100), takeUntil(this.onDestroy))
      .subscribe(event => {
        const { innerWidth } = event.target as Window;

        this.isMobile = innerWidth < 768;
        this.youtubeOverlaySize = this.getYoutubeOverlaySize();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
