import { ExpenseCategorySearchActions } from '~actions/products/expense-category-search.actions';
import { createReducer, on } from '@ngrx/store';

export interface ExpenseCategorySearchState {
  items: any[];
  pending: boolean;
}

const initialState: ExpenseCategorySearchState = {
  items: [],
  pending: false,
};

export const reducer = createReducer(
  initialState,
  on(ExpenseCategorySearchActions.ExpenseCategorySearchLoad, state => ({
    ...state,
    pending: true,
  })),
  on(
    ExpenseCategorySearchActions.ExpenseCategorySearchLoadSuccess,
    (state, action) => ({
      ...state,
      items: action.payload,
      pending: false,
    })
  )
);

export const getExpenseCategoryState = (state: ExpenseCategorySearchState) =>
  state;
