import {
  HospitalSettingModel,
  HospitalSettingUpdateModel,
  OperatingTimeModel,
} from '~models/hospital.model';
import { createAction, props } from '@ngrx/store';
import {
  HospitalServiceSettingReq,
  InspectionSetting,
} from '~models/hospital-setting.model';

export const HospitalSettingTypes = {
  OPERATING_TIME_LOAD: '[Operating] Time Load',
  OPERATING_TIME_LOAD_SUCCESS: '[Operating] Time Load success',
  OPERATING_TIME_LOAD_FAIL: '[Operating] Time Load fail',

  OPERATING_TIME_UPDATE: '[Operating] Time Update',
  OPERATING_TIME_UPDATE_SUCCESS: '[Operating] Time Update Success',
  OPERATING_TIME_UPDATE_FAIL: '[Operating] Time Update fail',

  HOSPITAL_SETTING_LOAD: '[HosSetting] Setting Load',
  HOSPITAL_SETTING_LOAD_SUCCESS: '[HosSetting] Setting Load Success',
  HOSPITAL_SETTING_LOAD_FAIL: '[HosSetting] Setting Load Fail',

  HOSPITAL_SETTING_UPDATE: '[HosSetting] Setting Update',
  HOSPITAL_SETTING_UPDATE_SUCCESS: '[HosSetting] Setting Update Success',
  HOSPITAL_SETTING_UPDATE_FAIL: '[HosSetting] Setting Update Fail',

  HOSPITAL_OPTION: '[HosSetting] Option Update',
  HOSPITAL_OPTION_UPDATE_SUCCESS: '[HosSetting] Option Update Complete',

  PEAKTIME_LIMIT_LOAD: '[PeaktimeLimit] PeaktimeLimitLoad',
  PEAKTIME_LIMIT_LOAD_SUCCESS: '[PeaktimeLimit] PeaktimeLimitLoad Success',
  PEAKTIME_LIMIT_LOAD_FAIL: '[PeaktimeLimit] PeaktimeLimitLoad Fail',

  UNIT_PEAKTIME_LIMIT_LOAD: '[UnitPeaktimeLimit] UnitPeaktimeLimitLoad',
  UNIT_PEAKTIME_LIMIT_LOAD_SUCCESS:
    '[UnitPeaktimeLimit] UnitPeaktimeLimitLoad Success',
  UNIT_PEAKTIME_LIMIT_LOAD_FAIL:
    '[UnitPeaktimeLimit] UnitPeaktimeLimitLoad Fail',

  EXAM_INFANTS_SETTING_UPDATE: '[ExamInfantsSetting] Setting Update',
  EXAM_INFANTS_SETTING_UPDATE_SUCCESS:
    '[ExamInfantsSetting] Setting Update Success',
  EXAM_INFANTS_SETTING_UPDATE_FAIL: '[ExamInfantsSetting] Setting Update Fail',

  RECEIPT_INSPECTION_SETTING_UPDATE:
    '[InspectionSetting] Receipt Setting Update',
  RESERVATION_INSPECTION_SETTING_UPDATE:
    '[InspectionSetting] Reservation Setting Update',
  RESERVATION_INSPECTION_SETTING_UPDATE_SUCCESS:
    '[InspectionSetting] Reservation Setting Update Success',
  INSPECTION_SETTING_UPDATE_SUCCESS:
    '[InspectionSetting] Setting Update Success',
  INSPECTION_SETTING_UPDATE_FAIL: '[InspectionSetting] Setting Update Fail',

  OVERLAP_OPTION: '[HosSetting] Overlap Update',
  OVERLAP_OPTION_UPDATE_SUCCESS: '[HosSetting] Overlap Update Complete',

  HOSPITAL_SERVICE_SETTING_UPDATE: '[HosSetting] Service Setting Update',
  HOSPITAL_SERVICE_SETTING_UPDATE_SUCCESS:
    '[HosSetting] Service Setting Update Success',
} as const;

// 진료시간 불러오기
export const OperatingTimeLoad = createAction(
  HospitalSettingTypes.OPERATING_TIME_LOAD
);

export const OperatingTimeLoadSuccess = createAction(
  HospitalSettingTypes.OPERATING_TIME_LOAD_SUCCESS,
  props<{ payload: { operating: OperatingTimeModel } }>()
);

export const OperatingTimeLoadFail = createAction(
  HospitalSettingTypes.OPERATING_TIME_LOAD_FAIL,
  props<{ payload: any }>()
);

// 진료시간 업데이트

export const OperatingTimeUpdate = createAction(
  HospitalSettingTypes.OPERATING_TIME_UPDATE,
  props<{ payload: { timeUpdate: OperatingTimeModel } }>()
);

export const OperatingTimeUpdateSuccess = createAction(
  HospitalSettingTypes.OPERATING_TIME_UPDATE_SUCCESS,
  props<{ payload: { data: OperatingTimeModel } }>()
);

export const OperatingTimeUpdateFail = createAction(
  HospitalSettingTypes.OPERATING_TIME_UPDATE_FAIL,
  props<{ payload: any }>()
);

// 병원 설정 불러오기

export const HosSettingLoad = createAction(
  HospitalSettingTypes.HOSPITAL_SETTING_LOAD
);

export const HosSettingLoadSuccess = createAction(
  HospitalSettingTypes.HOSPITAL_SETTING_LOAD_SUCCESS,
  props<{ payload: { setting: HospitalSettingModel } }>()
);

export const HosSettingLoadFail = createAction(
  HospitalSettingTypes.HOSPITAL_SETTING_LOAD_FAIL,
  props<{ payload: any }>()
);

// 병원 설정 업데이트

export const HosSettingUpdate = createAction(
  HospitalSettingTypes.HOSPITAL_SETTING_UPDATE,
  props<{ payload: { update: HospitalSettingUpdateModel } }>()
);

export const HosSettingUpdateSuccess = createAction(
  HospitalSettingTypes.HOSPITAL_SETTING_UPDATE_SUCCESS
);

export const HosSettingUpdateFail = createAction(
  HospitalSettingTypes.HOSPITAL_SETTING_UPDATE_FAIL,
  props<{ payload: any }>()
);

// 접수 옵션 업데이트

export const HosOptionUpdate = createAction(
  HospitalSettingTypes.HOSPITAL_OPTION,
  props<{ payload: { update: HospitalSettingUpdateModel; message?: string } }>()
);

export const HosOptionUpdateSuccess = createAction(
  HospitalSettingTypes.HOSPITAL_OPTION_UPDATE_SUCCESS,
  props<{ payload: { update: HospitalSettingUpdateModel; message?: string } }>()
);

// 중복 예약 업데이트

export const OverlapOption = createAction(
  HospitalSettingTypes.OVERLAP_OPTION,
  props<{ payload: { useFlag: HospitalSettingUpdateModel } }>()
);

export const OverlapOptionUpdateSuccess = createAction(
  HospitalSettingTypes.OVERLAP_OPTION_UPDATE_SUCCESS,
  props<{ payload: boolean }>()
);

// 진료실 피크타임 불러오기

export const PeaktimeLimitLoad = createAction(
  HospitalSettingTypes.PEAKTIME_LIMIT_LOAD
);

export const PeaktimeLimitLoadSuccess = createAction(
  HospitalSettingTypes.PEAKTIME_LIMIT_LOAD_SUCCESS,
  props<{ payload: { setting: HospitalSettingModel } }>()
);

export const PeaktimeLimitLoadFail = createAction(
  HospitalSettingTypes.PEAKTIME_LIMIT_LOAD_FAIL,
  props<{ payload: any }>()
);

// 진료실 피크타임 불러오기

export const UnitPeaktimeLimitLoad = createAction(
  HospitalSettingTypes.UNIT_PEAKTIME_LIMIT_LOAD
);

export const UnitPeaktimeLimitLoadSuccess = createAction(
  HospitalSettingTypes.UNIT_PEAKTIME_LIMIT_LOAD_SUCCESS,
  props<{ payload: { setting: HospitalSettingModel } }>()
);

export const UnitPeaktimeLimitLoadFail = createAction(
  HospitalSettingTypes.UNIT_PEAKTIME_LIMIT_LOAD_FAIL,
  props<{ payload: any }>()
);

// 영유아 검진 사용여부 업데이트
export const ExamInfantsSettingUpdate = createAction(
  HospitalSettingTypes.EXAM_INFANTS_SETTING_UPDATE,
  props<{ payload: { update: HospitalSettingUpdateModel } }>()
);

export const ExamInfantsSettingUpdateSuccess = createAction(
  HospitalSettingTypes.EXAM_INFANTS_SETTING_UPDATE_SUCCESS,
  props<{ payload: HospitalSettingUpdateModel }>()
);

export const ExamInfantsSettingUpdateFail = createAction(
  HospitalSettingTypes.EXAM_INFANTS_SETTING_UPDATE_FAIL,
  props<{ payload: any }>()
);

// 검사결과 연동
export const ReceiptInspectionSettingUpdate = createAction(
  HospitalSettingTypes.RECEIPT_INSPECTION_SETTING_UPDATE,
  props<{
    payload: {
      receipt: InspectionSetting;
    };
    hospitalId: string;
  }>()
);
export const ReservationInspectionSettingUpdate = createAction(
  HospitalSettingTypes.RESERVATION_INSPECTION_SETTING_UPDATE,
  props<{
    payload: {
      reservation: InspectionSetting;
    };
    onlyChangedSendDay: boolean;
    hospitalId: string;
    treatmentKey: string;
  }>()
);

export const ReservationInspectionSettingUpdateSuccess = createAction(
  HospitalSettingTypes.RESERVATION_INSPECTION_SETTING_UPDATE_SUCCESS,
  props<{
    isOn: boolean;
    treatmentKey: string;
    onlyChangedSendDay: boolean;
  }>()
);

export const InspectionSettingUpdateSuccess = createAction(
  HospitalSettingTypes.INSPECTION_SETTING_UPDATE_SUCCESS
);

export const InspectionSettingUpdateFail = createAction(
  HospitalSettingTypes.INSPECTION_SETTING_UPDATE_FAIL,
  props<{ payload: any }>()
);

export const HospitalServiceSettingUpdate = createAction(
  HospitalSettingTypes.HOSPITAL_SERVICE_SETTING_UPDATE,
  props<{ payload: HospitalServiceSettingReq }>()
);

export const HospitalServiceSettingUpdateSuccess = createAction(
  HospitalSettingTypes.HOSPITAL_SERVICE_SETTING_UPDATE_SUCCESS
);

export const HospitalSettingActions = {
  OperatingTimeLoad,
  OperatingTimeLoadSuccess,
  OperatingTimeLoadFail,
  OperatingTimeUpdate,
  OperatingTimeUpdateSuccess,
  OperatingTimeUpdateFail,
  HosSettingLoad,
  HosSettingLoadSuccess,
  HosSettingLoadFail,
  HosSettingUpdate,
  HosSettingUpdateSuccess,
  HosSettingUpdateFail,
  HosOptionUpdate,
  HosOptionUpdateSuccess,
  PeaktimeLimitLoad,
  PeaktimeLimitLoadSuccess,
  PeaktimeLimitLoadFail,
  UnitPeaktimeLimitLoad,
  UnitPeaktimeLimitLoadSuccess,
  UnitPeaktimeLimitLoadFail,
  ExamInfantsSettingUpdate,
  ExamInfantsSettingUpdateSuccess,
  ExamInfantsSettingUpdateFail,
  OverlapOption,
  OverlapOptionUpdateSuccess,
  ReceiptInspectionSettingUpdate,
  ReservationInspectionSettingUpdate,
  ReservationInspectionSettingUpdateSuccess,
  InspectionSettingUpdateSuccess,
  InspectionSettingUpdateFail,
  HospitalServiceSettingUpdate,
  HospitalServiceSettingUpdateSuccess,
};
