import { createAction, props } from '@ngrx/store';
import { HashMap, SchedulerTags } from '~models/common.model';
import { EachMonthTag } from '~models/reservation-schedule-v2.model';
import {
  BaseTimelineData,
  CellInfo,
  EnableTimeStream,
  Holiday,
  HourRange,
  LoadedTimelineData,
  Schedule,
  ScheduleExistsReqParams,
  ScheduleItemGroup,
  ScheduleWeekCopyPayload,
  SymptomUnit,
  TimelineInfo,
  TimelinePreviewPayload,
  TimelineScheduleReqData,
  TimelineScheduleReqParams,
  TimelineScheduleResData,
  YearMonth,
} from '~models/reservation-timeline.model';
import { SymptomCareRoomSelectParameter } from '~models/select-symptom.model';

export const ResTimelineActionTypes = {
  BASE_LOAD: '[TimelineSchedule] Base Load',
  BASE_LOAD_SUCCESS: '[TimelineSchedule] Base Load Success',
  BASE_LOAD_FAIL: '[TimelineSchedule] Base Load Fail',

  SCHEDULE_LOAD: '[TimelineSchedule] Schedule Load',
  SCHEDULE_LOAD_SUCCESS: '[TimelineSchedule] Schedule Load Success',
  SCHEDULE_LOAD_FAIL: '[TimelineSchedule] Schedule Load Fail',

  SCHEDULE_UPDATE: '[TimelineSchedule] Schedule Update',
  SCHEDULE_UPDATE_SUCCESS: '[TimelineSchedule] Schedule Update Success',
  SCHEDULE_UPDATE_FAIL: '[TimelineSchedule] Schedule Update Fail',

  DATE_SELECT: '[TimelineSchedule] Date Select',
  HOUR_RANGE_CHANGE: '[TimelineSchedule] Hour Range Select',
  HOUR_RANGE_APPLY: '[TimelineSchedule] Hour Range Apply',
  WEEK_SELECT: '[TimelineSchedule] Week Select',
  SYMPTOM_UNIT_SELECT: '[TimelineSchedule] Symptom Unit Select',
  SYMPTOM_UNIT_ADD: '[TimelineSchedule] Symptom Unit Add',
  SYMPTOM_UNIT_REMOVE: '[TimelineSchedule] Symptom Unit Remove',
  SYMPTOM_UNIT_CHANGE: '[TimelineSchedule] Symptom Unit Change',
  SYMPTOM_UNIT_APPLY: '[TimelineSchedule] Symptom Unit Apply',

  SCHEDULE_ADD: '[TimelineSchedule] Schedule Add',
  SCHEDULE_RANGE_ADD: '[TimelineSchedule] Schedule Range Add',
  SCHEDULE_RANGE_ADDED: '[TimelineSchedule] Schedule Range Added',
  SCHEDULE_REMOVE: '[TimelineSchedule] Schedule Remove',
  SCHEDULE_DAY_REMOVE: '[TimelineSchedule] Schedule Day Remove',
  SCHEDULE_WEEKLY_REMOVE: '[TimelineSchedule] Schedule Weekly Remove',
  SCHEDULE_BATCH_REMOVED: '[TimelineSchedule] Schedule Batch Removed',
  SCHEDULE_CHANGE: '[TimelineSchedule] Schedule Change',

  SCHEDULE_MODIFY: '[TimelineSchedule] Schedule Modify',
  SCHEDULE_MODIFIED: '[TimelineSchedule] Schedule Modified',
  SCHEDULE_CLEAR: '[TimelineSchedule] Schedule Clear',

  CALENDAR_INIT: '[TimelineSchedule] Calendar Init',
  CALENDAR_SELECT_DAYS: '[TimelineSchedule] Calendar Select Days',
  SCHEDULE_GROUPS: '[TimelineSchedule] Schedule Groups',

  COPY_PREV_MONTH_PREPARE: '[TimelineSchedule] Copy Prev Month Prepare',
  COPY_PREV_MONTH_PREPARED: '[TimelineSchedule] Copy Prev Month Prepared',
  COPY_PREV_MONTH_PREPARE_FAIL:
    '[TimelineSchedule] Copy Prev Month Prepare Fail',
  COPY_PREV_MONTH_BY_STORAGE: '[TimelineSchedule] Copy Prev Month by Storage',
  COPY_PREV_MONTH_WEEKLY_SELECT:
    '[TimelineSchedule] Copy Prev Month Weekly Select',
  COPY_PREV_MONTH_WEEKLY_DESELECT:
    '[TimelineSchedule] Copy Prev Month Weekly Deselect',
  COPY_PREV_MONTH_WEEKLY_SELECTED:
    '[TimelineSchedule] Copy Prev Month Weekly Selected',

  COPY_DAYS: '[TimelineSchedule] Copy Days',
  COPY_WEEKS: '[TimelineSchedule] Copy Weeks',

  PREVIEW_INIT: '[TimelineSchedule] Preview Init',
  PREVIEW_APPLY: '[TimelineSchedule] Preview Apply',
  CURRENT_MONTH_APPLY: '[TimelineSchedule] Current Month Apply',

  EXISTS_LOAD: '[TimelineSchedule] Exists Load',
  EXISTS_LOAD_SUCCESS: '[TimelineSchedule] Exists Load Success',
  EXISTS_LOAD_FAIL: '[TimelineSchedule] Exists Load Fail',

  MESSAGE_CLEAR: '[TimelineSchedule] Message Clear',

  TO_NEW_SCHEDULE: '[TimelineSchedule] To New Schedule',

  RESET_EXISTS_TAGS: '[TimelineSchedule] Reset Exists Tags',
} as const;

// 기반 데이터 불러오기
export const TimelineBaseLoad = createAction(
  ResTimelineActionTypes.BASE_LOAD,
  props<{
    payload: {
      paramSymptom: SymptomCareRoomSelectParameter;
      paramSchedule: TimelineScheduleReqParams;
      year: number;
      month: number;
      forceCurrWeek?: boolean;
    };
  }>()
);

export const TimelineBaseLoadSuccess = createAction(
  ResTimelineActionTypes.BASE_LOAD_SUCCESS,
  props<{ payload: BaseTimelineData; currWeek: number }>()
);

export const TimelineBaseLoadFail = createAction(
  ResTimelineActionTypes.BASE_LOAD_FAIL,
  props<any>()
);

// 저장된 스케줄 불러오기
export const TimelineScheduleLoad = createAction(
  ResTimelineActionTypes.SCHEDULE_LOAD,
  props<{
    payload: {
      parameter: TimelineScheduleReqParams;
      yearMonth: YearMonth;
    };
  }>()
);

export const TimelineScheduleLoadSuccess = createAction(
  ResTimelineActionTypes.SCHEDULE_LOAD_SUCCESS,
  props<{ payload: LoadedTimelineData }>()
);

export const TimelineScheduleLoadFail = createAction(
  ResTimelineActionTypes.SCHEDULE_LOAD_FAIL,
  props<any>()
);

// 스케줄 저장하기
export const TimelineScheduleUpdate = createAction(
  ResTimelineActionTypes.SCHEDULE_UPDATE,
  props<{
    payload: {
      data: TimelineScheduleReqData;
      background?: boolean;
    };
  }>()
);

export const TimelineScheduleUpdateSuccess = createAction(
  ResTimelineActionTypes.SCHEDULE_UPDATE_SUCCESS,
  props<{ payload: TimelineScheduleResData; background?: boolean }>()
);

export const TimelineScheduleUpdateFail = createAction(
  ResTimelineActionTypes.SCHEDULE_UPDATE_FAIL,
  props<any>()
);

// 증상별 스케줄 조작부
export const TimelineSymptomUnitSelect = createAction(
  ResTimelineActionTypes.SYMPTOM_UNIT_SELECT,
  props<{ payload: SymptomUnit }>()
);

export const TimelineSymptomUnitAdd = createAction(
  ResTimelineActionTypes.SYMPTOM_UNIT_ADD,
  props<{ payload: SymptomUnit; dontSelected?: boolean }>()
);

export const TimelineSymptomUnitRemove = createAction(
  ResTimelineActionTypes.SYMPTOM_UNIT_REMOVE,
  props<{ payload: SymptomUnit }>()
);

export const TimelineSymptomUnitChange = createAction(
  ResTimelineActionTypes.SYMPTOM_UNIT_CHANGE,
  props<{ payload: SymptomUnit }>()
);

export const TimelineSymptomUnitApply = createAction(
  ResTimelineActionTypes.SYMPTOM_UNIT_APPLY,
  props<{ payload: { symptomUnit: SymptomUnit; hourRange: HourRange } }>()
);

// 날짜 선택
export const TimelineDateSelect = createAction(
  ResTimelineActionTypes.DATE_SELECT,
  props<{ payload: YearMonth }>()
);

// 예약 가능 시간 간격 변경
export const TimelineHourRangeChange = createAction(
  ResTimelineActionTypes.HOUR_RANGE_CHANGE,
  props<{ payload: { hourRange: HourRange; unitKey: string } }>()
);

export const TimelineHourRangeApply = createAction(
  ResTimelineActionTypes.HOUR_RANGE_APPLY,
  props<{ payload: HourRange }>()
);

// 주간 선택
export const TimelineWeekSelect = createAction(
  ResTimelineActionTypes.WEEK_SELECT,
  props<{ payload: number }>()
);

// 타임라인 테이블 수정
export const TimelineScheduleAdd = createAction(
  ResTimelineActionTypes.SCHEDULE_ADD,
  props<{ payload: CellInfo }>()
);

export const TimelineScheduleRangeAdd = createAction(
  ResTimelineActionTypes.SCHEDULE_RANGE_ADD,
  props<{
    payload: {
      cellMap: HashMap<CellInfo[]>;
      symptomUnit: SymptomUnit;
    };
  }>()
);

export const TimelineScheduleRangeAdded = createAction(
  ResTimelineActionTypes.SCHEDULE_RANGE_ADDED,
  props<{
    payload: {
      selectedSymptomUnit: SymptomUnit;
      timelineInfoList: TimelineInfo[][];
      schedules: HashMap<Schedule>;
    };
  }>()
);

export const TimelineScheduleRemove = createAction(
  ResTimelineActionTypes.SCHEDULE_REMOVE,
  props<{ payload: CellInfo }>()
);

export const TimelineScheduleDayRemove = createAction(
  ResTimelineActionTypes.SCHEDULE_DAY_REMOVE,
  props<{ payload: number }>()
);

export const TimelineScheduleWeeklyRemove = createAction(
  ResTimelineActionTypes.SCHEDULE_WEEKLY_REMOVE,
  props<{ payload: number }>()
);

export const TimelineScheduleBatchRemoved = createAction(
  ResTimelineActionTypes.SCHEDULE_BATCH_REMOVED,
  props<{
    payload: {
      timelineInfoList: TimelineInfo[][];
      schedules: HashMap<Schedule>;
    };
  }>()
);

export const TimelineScheduleChange = createAction(
  ResTimelineActionTypes.SCHEDULE_CHANGE,
  props<{ payload: CellInfo }>()
);

export const TimelineScheduleModify = createAction(
  ResTimelineActionTypes.SCHEDULE_MODIFY,
  props<{ payload: { data: EnableTimeStream; remove?: boolean } }>()
);

export const TimelineScheduleModified = createAction(
  ResTimelineActionTypes.SCHEDULE_MODIFIED,
  props<{ payload: TimelineInfo[][] }>()
);

export const TimelineScheduleClear = createAction(
  ResTimelineActionTypes.SCHEDULE_CLEAR,
  props<{ payload: any }>()
);

// 캘린더 핸들링
export const TimelineCalendarInit = createAction(
  ResTimelineActionTypes.CALENDAR_INIT,
  props<{ payload: any }>()
);

export const TimelineCalendarSelectDays = createAction(
  ResTimelineActionTypes.CALENDAR_SELECT_DAYS,
  props<{
    payload: {
      mode: string;
      year: number;
      month: number;
      days: number[];
    };
  }>()
);

export const TimelineScheduleGroups = createAction(
  ResTimelineActionTypes.SCHEDULE_GROUPS,
  props<{ payload: ScheduleItemGroup[] }>()
);

// 일/주 복사
export const TimelineScheduleCopyDays = createAction(
  ResTimelineActionTypes.COPY_DAYS,
  props<{ payload: { src: number; dist: number[] } }>()
);

export const TimelineScheduleCopyWeeks = createAction(
  ResTimelineActionTypes.COPY_WEEKS,
  props<{ payload: ScheduleWeekCopyPayload }>()
);

export const TimelineScheduleCopyPrevMonthPrepare = createAction(
  ResTimelineActionTypes.COPY_PREV_MONTH_PREPARE,
  props<{
    payload: {
      unitKey: string;
      yearMonth: YearMonth;
    };
  }>()
);

export const TimelineScheduleCopyPrevMonthPrepared = createAction(
  ResTimelineActionTypes.COPY_PREV_MONTH_PREPARED,
  props<{
    payload: {
      prevExistsMap: { [month: string]: boolean };
      prevHolidayMap: { [day: string]: Holiday };
      nextExistsMap: { [month: string]: boolean };
    };
  }>()
);

export const TimelineScheduleCopyPrevMonthPrepareFail = createAction(
  ResTimelineActionTypes.COPY_PREV_MONTH_PREPARE_FAIL,
  props<any>()
);

export const TimelineScheduleCopyPrevMonthByStorage = createAction(
  ResTimelineActionTypes.COPY_PREV_MONTH_BY_STORAGE
);

export const TimelineScheduleCopyPrevMonthWeeklySelect = createAction(
  ResTimelineActionTypes.COPY_PREV_MONTH_WEEKLY_SELECT,
  props<{ payload: number }>()
);

export const TimelineScheduleCopyPrevMonthWeeklyDeselect = createAction(
  ResTimelineActionTypes.COPY_PREV_MONTH_WEEKLY_DESELECT
);

export const TimelineScheduleCopyPrevMonthWeeklySelected = createAction(
  ResTimelineActionTypes.COPY_PREV_MONTH_WEEKLY_SELECTED,
  props<{ payload: { [day: string]: boolean } }>()
);

// 미리보기 부분
export const TimelinePreviewInit = createAction(
  ResTimelineActionTypes.PREVIEW_INIT,
  props<{ payload: any }>()
);

export const TimelineCurrentMonthApply = createAction(
  ResTimelineActionTypes.CURRENT_MONTH_APPLY,
  props<{ payload: number }>()
);

export const TimelinePreviewApply = createAction(
  ResTimelineActionTypes.PREVIEW_APPLY,
  props<{ payload: TimelinePreviewPayload }>()
);

// 스케줄 존재 여부
export const TimelineExistsLoad = createAction(
  ResTimelineActionTypes.EXISTS_LOAD,
  props<{ params: ScheduleExistsReqParams }>()
);

export const TimelineExistsLoadSuccess = createAction(
  ResTimelineActionTypes.EXISTS_LOAD_SUCCESS,
  props<{
    payload: { [month: string]: boolean };
    usedTags: SchedulerTags[];
    monthsTag: { [month: string]: SchedulerTags };
  }>()
);

export const TimelineExistsLoadFail = createAction(
  ResTimelineActionTypes.EXISTS_LOAD_FAIL,
  props<any>()
);

// 메시지
export const TimelineMessageClear = createAction(
  ResTimelineActionTypes.MESSAGE_CLEAR,
  props<{ payload: any }>()
);

// v2 스케줄 이동
export const TimelineToNewSchedule = createAction(
  ResTimelineActionTypes.TO_NEW_SCHEDULE,
  props<{ unitKey: string; year: number; month: number }>()
);

// exists 태그 초기화
export const TimelineResetExistsTags = createAction(
  ResTimelineActionTypes.RESET_EXISTS_TAGS
);

export const ResTimelineActions = {
  TimelineBaseLoad,
  TimelineBaseLoadSuccess,
  TimelineBaseLoadFail,
  TimelineScheduleLoad,
  TimelineScheduleLoadSuccess,
  TimelineScheduleLoadFail,
  TimelineScheduleUpdate,
  TimelineScheduleUpdateSuccess,
  TimelineScheduleUpdateFail,
  TimelineSymptomUnitAdd,
  TimelineSymptomUnitApply,
  TimelineSymptomUnitChange,
  TimelineSymptomUnitRemove,
  TimelineSymptomUnitSelect,
  TimelineDateSelect,
  TimelineHourRangeChange,
  TimelineHourRangeApply,
  TimelineWeekSelect,
  TimelineScheduleAdd,
  TimelineScheduleRangeAdd,
  TimelineScheduleRangeAdded,
  TimelineScheduleRemove,
  TimelineScheduleDayRemove,
  TimelineScheduleWeeklyRemove,
  TimelineScheduleBatchRemoved,
  TimelineScheduleChange,
  TimelineScheduleModified,
  TimelineScheduleClear,
  TimelineCalendarInit,
  TimelineCalendarSelectDays,
  TimelineScheduleGroups,
  TimelineScheduleCopyDays,
  TimelineScheduleCopyWeeks,
  TimelineScheduleCopyPrevMonthPrepare,
  TimelineScheduleCopyPrevMonthPrepared,
  TimelineScheduleCopyPrevMonthPrepareFail,
  TimelineScheduleCopyPrevMonthWeeklySelect,
  TimelineScheduleCopyPrevMonthWeeklyDeselect,
  TimelineScheduleCopyPrevMonthWeeklySelected,
  TimelinePreviewInit,
  TimelinePreviewApply,
  TimelineCurrentMonthApply,
  TimelineExistsLoad,
  TimelineExistsLoadSuccess,
  TimelineExistsLoadFail,
  TimelineMessageClear,
  TimelineScheduleCopyPrevMonthByStorage,
  TimelineToNewSchedule,
  TimelineResetExistsTags,
};
