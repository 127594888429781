import { FindUserInfo } from '~models/auth.model';
import { createAction, props } from '@ngrx/store';

export enum FindIdActionTypes {
  FIND_ID = '[Auth] FindId',
  FIND_ID_COMPLETE = '[Auth] FindId Complete',
  FIND_ID_FAILURE = '[Auth] FindId Failure',
}

export const FindId = createAction(
  FindIdActionTypes.FIND_ID,
  props<{ payload: FindUserInfo }>()
);

export const FindIdComplete = createAction(
  FindIdActionTypes.FIND_ID_COMPLETE,
  props<{ payload: string }>()
);

export const FindIdFailure = createAction(
  FindIdActionTypes.FIND_ID_FAILURE,
  props<{ payload: any }>()
);

export const FindIdActions = {
  FindId,
  FindIdComplete,
  FindIdFailure,
};
