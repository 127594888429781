import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export const ServiceUserPanelDescriptionType = {
  NONE: 'NONE',
  ELEMENTS: 'ELEMENTS',
};

@Component({
  selector: 'app-service-use-panel',
  templateUrl: './service-use-panel.component.html',
  styleUrls: ['./service-use-panel.component.scss'],
})
export class ServiceUsePanelComponent implements OnInit {
  @Input() icon = 'icon-calendar';
  @Input() title = '';
  @Input() description = '';
  @Input() label = '';
  @Input() footerDescriptionType = ServiceUserPanelDescriptionType.NONE;
  @Input() footerDescription = '';
  @Input() use = false;

  @Output() useChange = new EventEmitter<boolean>();

  serviceUserPanelDescriptionType = ServiceUserPanelDescriptionType;

  constructor() {}

  ngOnInit(): void {}

  handleChange(use: boolean): void {
    this.useChange.emit(use);
  }

  get hasFooter(): boolean {
    return (
      this.footerDescriptionType === ServiceUserPanelDescriptionType.ELEMENTS ||
      (this.footerDescriptionType === ServiceUserPanelDescriptionType.NONE &&
        this.footerDescription.length > 0)
    );
  }
}
