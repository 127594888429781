import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectOption } from '~models/common';

@Component({
  selector: 'app-labeled-select-filter',
  templateUrl: './labeled-select-filter.component.html',
  styleUrls: ['./labeled-select-filter.component.scss'],
})
export class LabeledSelectFilterComponent implements OnInit {
  public _value = '';
  @Input() set value(value: string) {
    this._value = value;
    this.selectedText = this.findTextByValue(value);
  }

  get value() {
    return this._value;
  }

  private _options: SelectOption[] = [];
  @Input() set options(options: SelectOption[]) {
    this._options = options || [];

    if (!this.value && this._options.length > 0) {
      this.selectedText = this._options[0].text;
    } else if (this._options.length > 0) {
      this.selectedText = this.findTextByValue(this.value);
    }
  }

  get options() {
    return this._options;
  }

  @Input() label = '';

  @Output() selectChange = new EventEmitter<string>();

  selectedText = '';

  constructor() {}

  ngOnInit() {}

  findTextByValue(value: string) {
    const options = this._options;
    let foundText = '';

    if (!options || options.length === 0) {
      return foundText;
    }

    foundText = options[0].text;

    options.every(opt => {
      if (opt.value === value) {
        foundText = opt.text;
        return false;
      }
      return true;
    });

    return foundText;
  }

  handleChange(option: SelectOption) {
    this.selectedText = option.text;
    this._value = option.value;
    this.selectChange.emit(option.value);
  }
}
