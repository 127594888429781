<section class="happy-way">
  <div class="content-box dark-gray">
    <div class="triangle"></div>
    <div class="content-layout">
      <h2 class="content-layout--title">
        병원이 행복해지는
        <br />
        편리한 방법 8가지!
        <div id="scroll-event"></div>
      </h2>

      <div class="signin-carousel">
        <app-tab-list
          theme="signin-carousel--header"
          [items]="tabItems"
          (tabSelect)="changeCategory($event)"
        ></app-tab-list>

        <app-signin-carousel
          [currentTabIdx]="tabIdx"
          [items]="carouselItems[tabIdx]"
        ></app-signin-carousel>
      </div>

      <div class="signin-scroll-contents">
        <div class="sticky-box">
          <app-tab-list
            theme="signin-carousel--header"
            [items]="tabItems"
            (tabSelect)="changeMobileTabIdx($event)"
          ></app-tab-list>
        </div>

        <app-signin-scroll-contents
          [currentTabIdx]="tabIdx"
          [items]="carouselItems[tabIdx]"
        ></app-signin-scroll-contents>
      </div>
    </div>
  </div>
</section>
