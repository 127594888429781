import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-loading',
  template: `
    <ng-container *ngIf="show">
      <div class="loading-wrap">
        <div class="loading-container">
          <p>Loading..</p>
          <div class="loading">
            <div class="loading-shape"></div>
            <div class="loading-shape"></div>
            <div class="loading-shape"></div>
            <div class="loading-shape"></div>
            <div class="loading-shape"></div>
            <div class="loading-shape"></div>
            <div class="loading-shape"></div>
            <div class="loading-shape"></div>
            <div class="loading-shape"></div>
            <div class="loading-shape"></div>
            <div class="loading-shape"></div>
            <div class="loading-shape"></div>
          </div>
        </div>
      </div>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./app-loading.component.scss']
})
export class AdminLoadingComponent implements OnInit {
  @Input() show = false;

  constructor() {}

  ngOnInit() {}
}
