import { BehaviorSubject, from, Observable, zip } from 'rxjs';
import { Injectable } from '@angular/core';
import { concatMap, shareReplay, switchMap } from 'rxjs/operators';
import {
  KAKAO_API_KEY,
  KAKAO_MAP_API_URL,
  KAKAO_POSTCODE_API_URL,
  KAKAO_SCRIPT_URL,
  KAKAO_SHARE_URL,
} from '../../config';
import { loadOuterScript } from '../utils/lib-loader';

export interface FoundAddress {
  address: string;
  buildingName: string;
  zonecode: string;
  jibunAddress: string; // 지번주소
  sigungu: string; // 시/군/구 이름
  bname: string; // 동 이름
}

function findAddress() {
  return new Promise<FoundAddress>((resolve, reject) => {
    daum.postcode.load(() => {
      new daum.Postcode({
        oncomplete: data => {
          resolve({
            address: data.address,
            buildingName: data.buildingName,
            zonecode: data.zonecode,
            jibunAddress: data.jibunAddress,
            sigungu: data.sigungu,
            bname: data.bname,
          });
        },
        onclose: data => {
          resolve(null);
        },
      }).open();
    });
  });
}

@Injectable()
export class KakaoService {
  kakaoObs: Observable<any>;

  constructor() {
    const checkLoadedApi = () => typeof Kakao !== 'undefined';

    const prmKakaoPostCodeObs = from(loadOuterScript(KAKAO_POSTCODE_API_URL));
    const prmDaumObs = from(loadOuterScript(KAKAO_MAP_API_URL));
    const prmKakaoObs = from(loadOuterScript(KAKAO_SCRIPT_URL));

    const kakaoApiObs = zip(prmKakaoPostCodeObs, prmDaumObs, prmKakaoObs).pipe(
      concatMap(([isNewApiLoaded, isDaumApiLoaded, isKakaoApiLoaded]) => {
        if (
          isNewApiLoaded &&
          isDaumApiLoaded &&
          isKakaoApiLoaded &&
          checkLoadedApi()
        ) {
          Kakao.init(KAKAO_API_KEY);
        }
        return new BehaviorSubject(Kakao).asObservable();
      })
    );
    this.kakaoObs = kakaoApiObs.pipe(shareReplay(1));
  }

  shareKakao() {
    this.kakaoObs.subscribe(kakao => {
      kakao.Link.sendScrap({
        requestUrl: KAKAO_SHARE_URL,
      });
    });
  }

  findAddress(): Observable<FoundAddress> {
    return this.kakaoObs.pipe(switchMap(() => from(findAddress())));
  }
}
