import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformToTime',
})
export class TransformToTimePipe implements PipeTransform {
  transform(value: number): string {
    const min = Math.floor(value / 60);
    return `${min < 10 ? '0' + min : min}:${('0' + (value % 60)).slice(-2)}`;
  }
}
