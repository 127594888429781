<div class="footer">
  <div class="inner">
    <div class="footer-header">
      <div class="footerBi">
        <i class="icon icon-ddocdoc-bi-white"></i>
      </div>
      <div class="follow_us">
        <a target="blank" href="https://www.facebook.com/ddocdoctor/">
          <i class="icon icon-facebook"></i>
          <i class="icon icon-facebook-mobile"></i>
        </a>
        <a target="blank" href="https://www.instagram.com/ddocdoc_official/">
          <i class="icon icon-insta"></i>
          <i class="icon icon-insta-mobile"></i>
        </a>
        <a target="blank" href="http://blog.naver.com/bbros6">
          <i class="icon icon-nblog"></i>
          <i class="icon icon-nblog-mobile"></i>
        </a>
      </div>
    </div>

    <div class="info">
      <strong>주식회사 비브로스</strong>
      <p class="detail">
        서울특별시 영등포구 여의대로 108 31층 (파크원 타워2)
        <span class="separator">|</span> 대표이사: 고승윤, 김형석
        <span class="separator">|</span> 사업자번호: 114-87-13917
        <span class="separator">|</span>
        <span>대표전화: <a href="tel:1899-6826">1899-6826</a></span>
        <span class="separator">|</span>
        <a href="mailto:support@bbros.kr">이메일: support@bbros.kr</a><br />
      </p>

      <p class="detail_m">
        서울특별시 영등포구 여의대로 108 31층 (파크원 타워2)
        <span class="separator">|</span> 대표이사: 고승윤, 김형석
        <span class="separator">|</span> <span>사업자번호: 114-87-13917</span>
        <span class="separator">|</span>
        <span>대표전화: <a href="tel:1899-6826">1899-6826</a> </span>
        <span class="separator">|</span> 이메일:
        <a href="mailto:support@bbros.kr">support@bbros.kr</a><br />
      </p>

      <hr />

      <div class="copyright-box">
        <span>Copyright © B-bros Corp. All rights reserved. </span>
        <div class="terms">
          <a href="https://www.ddocdoc.com/terms/hospital_service">이용약관</a>
          <a href="https://www.ddocdoc.com/terms/hospital_privacy" class="last"
            >개인정보처리방침</a
          >
        </div>
      </div>

      <div class="copyright-box-mobile">
        <div class="terms">
          <a href="https://www.ddocdoc.com/terms/hospital_service">이용약관</a>
          <a href="https://www.ddocdoc.com/terms/hospital_privacy" class="last">
            개인정보처리방침
          </a>
        </div>
        <span>Copyright © B-bros Corp. All rights reserved. </span>
      </div>
    </div>
  </div>
</div>
