import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-tooltip',
  templateUrl: './icon-tooltip.component.html',
  styleUrls: ['./icon-tooltip.component.scss'],
})
export class IconTooltipComponent implements OnInit {
  @Input() tooltip: any;
  @Input() tooltipPlacement = 'bottom';
  @Input() iconName = 'question';
  @Input() tooltipClass = 'icon-label-tooltip';
  @Input() autoClose = true;

  constructor() {}

  ngOnInit() {}

  get iconClass() {
    return `icon-${this.iconName}-mark`;
  }
}
