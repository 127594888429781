import { CrmResAgreement } from '~models/crm-service.model';
import { CrmActions } from '~actions/products/crm-service';
import { createReducer, on } from '@ngrx/store';

export interface CrmServiceState {
  loading: boolean;
  crmData: CrmResAgreement | null;
  loaded: boolean;
  error: string | null;
  fileList: string[];
}

const initialState: CrmServiceState = {
  loading: false,
  error: null,
  crmData: null,
  loaded: false,
  fileList: [],
};

export const reducer = createReducer(
  initialState,
  on(CrmActions.CrmAgreementLoad, (state, action) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(CrmActions.CrmAgreementLoadSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
    crmData: action.payload,
    fileList: [],
  })),
  on(CrmActions.CrmAgreementLoadFail, (state, action) => ({
    ...state,
    loading: false,
    loaded: false,
    error: action.payload,
  })),
  on(CrmActions.CrmAgreementUpdate, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(CrmActions.CrmAgreementUpdateSuccess, (state, action) => ({
    ...state,
    loading: false,
  })),
  on(CrmActions.CrmAgreementUpdateFail, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  })),
  on(CrmActions.CrmUploadFiles, (state, action) => ({ ...state })),
  on(CrmActions.CrmUploadFilesSuccess, (state, action) => ({
    ...state,
    fileList: state.fileList.concat(action.payload.result.filePath),
  })),
  on(CrmActions.CrmUploadFilesFail, (state, action) => ({
    ...state,
    error: action.payload.error,
  })),
  on(CrmActions.CrmExceptFiles, (state, action) => ({
    ...state,
    fileList: state.fileList.filter((v, i) => {
      return i !== action.payload;
    }),
  })),
  on(CrmActions.CrmSMSRequest, (state, action) => ({ ...state })),
  on(CrmActions.CrmSMSRequestSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
  })),
  on(CrmActions.CrmSMSRequestFail, (state, action) => ({ ...state })),
  on(CrmActions.CrmClear, (state, action) => ({
    ...state,
    loading: false,
    error: null,
    crmData: null,
    loaded: false,
    fileList: [],
  }))
);

export const getLoading = (state: CrmServiceState) => state.loading;
export const getLoaded = (state: CrmServiceState) => state.loaded;
export const getAgreement = (state: CrmServiceState) => state.crmData;
export const getError = (state: CrmServiceState) => state.error;
export const getFileList = (state: CrmServiceState) => state.fileList;
