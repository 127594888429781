import { createSelector } from '@ngrx/store';
import { selectProductState } from '~reducers/products';
import { telemedicineItemAdapter } from '~reducers/products/telemedicine.reducer';

const { selectAll, selectEntities, selectIds, selectTotal } =
  telemedicineItemAdapter.getSelectors();

export const selectTelemedicine = createSelector(
  selectProductState,
  state => state.telemedicine
);

export const getTelemedicineSettingLoaded = createSelector(
  selectTelemedicine,
  state => state.loaded
);

export const getTelemedicineRequiredSetting = createSelector(
  selectTelemedicine,
  state => state.requiredSetting
);

export const getTelemedicineUse = createSelector(
  selectTelemedicine,
  state => state.useTelemedicine
);

export const getTelemedicineAllSettings = createSelector(
  selectTelemedicine,
  selectAll
);

export const getTelemedicineSettingsDict = createSelector(
  selectTelemedicine,
  selectEntities
);

export const getTelemedicineRoomsSettingsUnitKeys = createSelector(
  selectTelemedicine,
  selectIds
);

export const getTelemedicineRoomsSettingsTotal = createSelector(
  selectTelemedicine,
  selectTotal
);

export const getTelemedicineRoomSettingByUnitKey = (unitKey: string) =>
  createSelector(selectTelemedicine, selectEntities, (state, entities) => {
    return entities[unitKey];
  });
