import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SimpleModalService } from '~shared/service/simple.modal.service';
import { AuthService } from '~core/api/auth.service';

function initStatesChecked() {
  return [false, false, false, false];
}

@Component({
  selector: 'app-account-create-terms',
  templateUrl: './account-create-terms.component.html',
  styleUrls: ['./account-create-terms.component.scss'],
})
export class AccountCreateTermsComponent implements OnInit {
  viewTerms: any;

  statesChecked: boolean[] = initStatesChecked();
  statesCheckedAll = false;
  terms: {
    label: string;
    isNecessary: boolean;
    src: string;
    href: string;
  }[] = [
    {
      label: '서비스 이용약관',
      isNecessary: true,
      src: 'https://ddocdoc.com/terms/hospital_service',
      href: 'https://ddocdoc.com/terms/hospital_service',
    },
    {
      label: '개인정보 처리방침',
      isNecessary: true,
      src: 'https://ddocdoc.com/terms/hospital_privacy',
      href: 'https://ddocdoc.com/terms/hospital_privacy',
    },
    {
      label: '제3자 정보 제공 동의',
      isNecessary: true,
      src: 'https://ddocdoc.com/terms/hospital_third',
      href: 'https://ddocdoc.com/terms/hospital_third',
    },
    {
      label: '마케팅 활용 동의',
      isNecessary: false,
      src: 'https://ddocdoc.com/terms/hospital_marketing',
      href: 'https://ddocdoc.com/terms/hospital_marketing',
    },
  ];
  ynum: string;
  isActiveYnumBtn = false;
  isNext = false;
  @Output() nextStep = new EventEmitter<{
    ynum: string;
    marketingAgreement: boolean;
  }>();

  get isActiveNext() {
    return (
      this.isNext &&
      this.statesChecked[0] &&
      this.statesChecked[1] &&
      this.statesChecked[2]
    );
  }

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private p: SimpleModalService
  ) {}

  ngOnInit() {
    this.http
      .get('assets/terms/smsTerms.html', { responseType: 'text' })
      .subscribe(data => {
        this.viewTerms = data;
      });
  }

  onCheckedChange(event: any, index: number) {
    event.stopPropagation();
    const value = event.target.checked as boolean;
    this.statesChecked[index] = value;

    /**
     * 체크 박스가 전체 체크 되어있는지 여부를 가져오는 함수
     */
    const isCheckedAll = this.statesChecked.reduce((a, c) => {
      return a && c;
    }, true);

    this.statesCheckedAll = isCheckedAll;
  }

  onCheckChangeAll(event: any) {
    event.stopPropagation();
    const value = event.target.checked as boolean;
    this.statesChecked = this.statesChecked.map(i => (i = value));
  }

  onChangeYnum(event: any) {
    this.isActiveYnumBtn = event.length === 8;
    this.isNext = false;
  }

  onCheckYnum() {
    const ynum = this.ynum.replace('-', '');
    if (this.validateYnum(ynum)) {
      this.authService.checkYnum(ynum).subscribe(i => {
        if (i.isExist) {
          this.p.alert(
            '이미 가입된 요양기관 번호입니다. 아이디 찾기 후 로그인해주세요.'
          );
        } else {
          this.p.alert('가입 가능한 요양기관 번호입니다.');
          this.isNext = true;
        }
      });
    } else {
      this.p.alert('유효하지 않은 요양기관 번호입니다.');
    }
  }

  validateYnum(ynum: string): boolean {
    const validateynum = /^(\s|\d)+$/; // 요양기관번호는 숫자만 들어간다. 이를 확인하기위한 정규식
    const areaCode = [
      '11',
      '12',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '31',
      '32',
      '33',
      '34',
      '35',
      '36',
      '37',
      '38',
      '39',
      '41',
    ]; // 요양기관번호 지역별 코드

    /**
     * 요양기관번호는 8자리
     */
    if (ynum.length !== 8) {
      return false;
    }

    if (!validateynum.test(ynum)) {
      return false;
    }

    if (!areaCode.includes(ynum.slice(0, 2))) {
      return false;
    }

    return true;
  }

  onClickNext() {
    this.nextStep.emit({
      ynum: this.ynum,
      marketingAgreement: this.statesChecked[3],
    });
  }
}
