import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scheduleColor'
})
export class ResScheduleColorPipe implements PipeTransform {
  transform(color: string): string {
    if (!color) {
      return '';
    }

    return 'tt-bg' + color;
  }
}
