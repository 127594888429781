import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputNumberComponent),
    },
  ],
})
export class InputNumberComponent implements OnInit, ControlValueAccessor {
  @Input() value: number;
  @Input() placeholder = '입력해주세요';
  @Input() name: string;
  @Input() max: number;
  @Input() min: number;
  @Input() disabled = false;

  @Output() inputChange = new EventEmitter<number>();

  onChange: (value: number) => void;
  onTouched: () => void;

  constructor() {}

  ngOnInit() {}

  normalizeByMinMax(value: number) {
    if (value < this.min) {
      return this.min;
    }

    if (value > this.max) {
      return this.max;
    }

    return value;
  }

  handleValueChange(value: number) {
    this.value = this.normalizeByMinMax(value);
    this.inputChange.emit(this.normalizeByMinMax(value));
    this.onChange?.(this.normalizeByMinMax(value));
  }

  handleKeyUp(event: Event) {
    const target = event.target as HTMLInputElement;
    target.value = target.value.replace(/[^0-9]/gi, '');
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: number): void {
    this.value = this.normalizeByMinMax(value);
  }
}
