import { Subject } from 'rxjs/';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-reception-peaktime-modal',
  template: `
    <div class="modal-header ha-modal-header">
      <div class="modal-title-wrap">
        <ng-container>
          <h5 class="modal-title">알림</h5>
          <p class="modal-title-desc">
            설정된 피크타임을 복사하기 위한 요일을 선택해주세요.
          </p>
          <p style="color: #737373">
            ※ 다른 진료실의 피크타임은 복사할 수 없습니다.
          </p>
        </ng-container>
      </div>
    </div>

    <div class="modal-body">
      <div class="modal-box-item text-center justify-content-between">
        <div class="ha-box-wrap">
          <app-copy-tab-list
            class="careroom-tabs"
            theme="peaktime-faq"
            [items]="dayTabItems"
            [tab]="selectedIndex"
            [fixday]="fixday"
            (tabSelect)="onSelectDayTab($event)"
          >
          </app-copy-tab-list>
          <br />
          <div class="d-flex">
            <div class="d-flex modal-helper">
              <div class="selectBox"></div>
              <span>&nbsp;:&nbsp;선택된 날짜</span>
            </div>
            <div class="d-flex modal-helper">
              <div class="copyBox"></div>
              <span>&nbsp;:&nbsp;복사할 날짜</span>
            </div>
            <div class="d-flex modal-helper">
              <div class="defaultBox"></div>
              <span>&nbsp;:&nbsp;복사 가능한 날짜</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="ha-btn ha-btn-secondary"
        (click)="activeModal.close('cancel')"
      >
        취소
      </button>
      <button
        type="button"
        class="ha-btn ha-btn-primary"
        (click)="copySetting()"
        [disabled]="!selectedIndex"
      >
        확인
      </button>
    </div>
  `,
  styleUrls: ['./re-peaktime-copy.modal.component.scss'],
})
export class ReceptionPeaktimeModalComponent implements OnInit {
  // @Input() careroom: CareRoom;
  @Input() fixday: number;
  @Output() copyPeakTime = new EventEmitter<number>();
  dayTabItems: string[] = ['월', '화', '수', '목', '금', '토', '일', '공휴일'];

  selectedIndex: number;

  pauseData: {
    use: boolean;
    msg: string;
  };

  pause;
  stopMsg: string;

  settingSubject = new Subject<any>();

  optionalTitles: string[] = [];

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.pause = false;
    this.stopMsg = '';
  }

  copySetting() {
    this.activeModal.close({
      fixday: this.fixday,
      selectedIndex: this.selectedIndex,
    });
  }

  onSelectDayTab(selectedIndex: number) {
    this.selectedIndex = 0;
    if (this.fixday === selectedIndex) {
      return;
    }
    this.selectedIndex = selectedIndex;
  }
}
