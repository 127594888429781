import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Input,
  AfterViewInit,
} from '@angular/core';

export interface SelectOption {
  value: string | number;
  name: string | number;
  disabled?: boolean;
}

@Component({
  selector: 'app-listed-select',
  templateUrl: './listed-select.component.html',
  styleUrls: ['./listed-select.component.scss'],
})
export class ListedSelectComponent implements OnInit, AfterViewInit {
  @ViewChild('optionList') optionListRef?: ElementRef<HTMLUListElement>;
  @Input() value?: string | number;
  @Input() options?: SelectOption[];
  @Input() disabled = false;

  @Output() selectOptionChange = new EventEmitter<SelectOption>();

  constructor() {}

  ngOnInit() {}
  ngAfterViewInit() {
    this.handleScrollIntoSelected();
  }

  handleScrollIntoSelected() {
    const { optionListRef } = this;

    if (optionListRef) {
      const element = optionListRef.nativeElement;
      const activeElement = element.querySelector('li.active');
      if (activeElement) {
        activeElement.scrollIntoView({ block: 'nearest' });
      }
    }
  }

  handleChange(option: SelectOption) {
    // component disabled 처리
    if (this.disabled) {
      return;
    }

    if (!option.disabled) {
      this.value = option.value;
    }
    this.selectOptionChange.emit(option);
  }
}
