import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { dateToString } from '../../services/util';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { koLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-labeled-date-filter',
  templateUrl: './labeled-date-filter.component.html',
  styleUrls: ['./labeled-date-filter.component.scss'],
})
export class LabeledDateFilterComponent implements OnInit {
  @Input() label = '일자';
  @Input() placeholder = '2020/01/01';
  @Input() autocomplete = 'on';
  private _value = '';
  @Input() set value(value: string) {
    const _value = value || '';
    this._value = _value;
    this.displayValue = _value ? _value.replace(/-/gi, '/') : '';
  }

  get value() {
    return this._value;
  }

  @Input() minDate = null;
  @Input() maxDate = null;
  @Input() separator = '-';

  @Output() valueChange = new EventEmitter<string>();

  displayValue = '';

  constructor(private localeService: BsLocaleService) {}

  ngOnInit() {
    defineLocale('ko', koLocale);
    this.localeService.use('ko');
  }

  resetValue() {
    this.displayValue = null;
    this._value = null;
    this.handleBsValueChange(null);
  }

  handleBsValueChange(value: Date) {
    const sDate = dateToString(value, this.separator);
    this._value = sDate;
    this.valueChange.emit(sDate);
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Backspace') {
      this.resetValue();
      return;
    }
    event.preventDefault();
  }
}
