import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-icon-info',
  templateUrl: './icon-info.component.html',
  styleUrls: [`./icons.scss`]
})
export class IconInfoComponent {
  // @HostBinding()
  @Input() width: number;
  @Input() height: number;
}
