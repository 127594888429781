<label
  class="ha-form-control ha-form-checkbox"
  [ngClass]="{ disabled: isDisabled }"
>
  <input
    class="ha-form-control-input"
    type="checkbox"
    [formControl]="formControl"
  />
  <span class="ha-form-control-label label-text">
    <ng-content></ng-content>
  </span>
  <ng-container *ngFor="let badge of badges">
    <i [ngClass]="badge" class="icon"></i>
  </ng-container>
</label>
