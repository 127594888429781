<div class="wrap">
  <span class="step-header">
    Step.2<br />
    <b>원장님 인증하기</b>
  </span>
  <form [formGroup]="registerForm" novalidate autocomplete="off" (submit)="registerAccount($event)">
    <div class="form-group-wrap">
      <h4 class="form-group-title">기본 정보</h4>
      <div class="form-group">
        <!-- <label for="inputUserId">아이디</label> -->
        <input
          type="text"
          autocomplete="off"
          formControlName="userId"
          class="form-control"
          #inputId
          id="inputUserId"
          placeholder="아이디 (영문+숫자, 4~20자)"
        />
        <!-- <small class="form-text text-muted">영문, 숫자 4 ~ 12자리로 작성해주세요.</small> -->
        <small class="form-text text-danger" *ngIf="duplicatedId">이미 가입된 아이디 입니다.</small>
        <small
          class="form-text text-danger"
          *ngIf="registerForm.controls['userId'].invalid && registerForm.controls['userId'].touched"
        >
          4~20자의 영문, 숫자, 영문+숫자만 사용 가능합니다.</small
        >
      </div>
      <div class="form-group">
        <!-- <label for="inputPassword">비밀번호</label> -->
        <input
          type="password"
          autocomplete="new-password"
          formControlName="userPassword"
          class="form-control"
          id="inputPassword"
          placeholder="비밀번호 (영문+숫자+특수문자 1자 이상 포함, 8 ~ 16자)"
        />
        <!-- <small class="form-text text-muted"
          >영문, 숫자, 특수문자가 1자 이상 포함된 6 ~ 18자로 작성해주세요. <br />특수문자는 !@#$%^*+- 만
          허용됩니다.</small
        > -->
        <small
          class="form-text text-danger"
          *ngIf="registerForm.controls['userPassword'].invalid && registerForm.controls['userPassword'].touched"
        >
          8~16자의 영문, 숫자, 특수문자를 포함하여 입력해주세요.</small
        >
      </div>
      <div class="form-group">
        <!-- <label for="inputPasswordConfirm">비밀번호 확인</label> -->
        <input
          type="password"
          formControlName="resetPassword"
          autocomplete="new-password"
          class="form-control"
          id="inputPasswordConfirm"
          placeholder="비밀번호 확인"
        />
        <small
          class="form-text text-danger"
          *ngIf="registerForm.controls['resetPassword'].invalid && registerForm.controls['resetPassword'].touched"
          >비밀번호가 같지 않습니다.</small
        >
      </div>
    </div>

    <div class="form-group-wrap">
      <h4 class="form-group-title">대표(원장님) 정보</h4>
      <div class="form-group">
        <!-- <label for="inputPhone">휴대폰 번호</label> -->
        <input
          appCregnoAutoHyphen
          type="phone"
          formControlName="cRegNo"
          autocomplete="off"
          class="form-control"
          id="inputcRegNo"
          maxlength="12"
          placeholder="사업자 등록번호"
        />
        <!-- <small class="form-text text-muted">연락 가능한 휴대전화번호를 입력하셔야 합니다.</small> -->
        <small
          class="form-text text-danger"
          *ngIf="
            registerForm.controls['cRegNo'].invalid &&
            (registerForm.controls['cRegNo'].dirty || registerForm.controls['cRegNo'].touched)
          "
        >
          정확한 사업자 번호를 입력해주세요.</small
        >
      </div>
      <div class="form-group">
        <!-- <label for="inputUserName">담당자 이름</label> -->
        <input
          type="text"
          autocomplete="off"
          formControlName="userName"
          class="form-control"
          id="inputUserName"
          placeholder="대표자(원장님)"
        />
        <small
          class="form-text text-danger"
          *ngIf="
            registerForm.controls['userName'].invalid &&
            (registerForm.controls['userName'].dirty || registerForm.controls['userName'].touched)
          "
          >정확한 이름을 입력해주세요.</small
        >
      </div>
      <div class="form-group">
        <!-- <label for="inputPhone">휴대폰 번호</label> -->
        <div class="form-check-wrap">
          <input
            appPhoneNumAutoHyphen
            type="phone"
            formControlName="userPhone"
            autocomplete="off"
            class="form-control with-check-button"
            id="inputPhone"
            placeholder="대표자 휴대폰 번호"
          />

          <button
            *ngIf="!isPhoneCertificationSend"
            type="button"
            class="ha-btn sign-up-check"
            [disabled]="
              (registerForm.controls['userPhone'].invalid &&
                (registerForm.controls['userPhone'].dirty || registerForm.controls['userPhone'].touched)) ||
              !registerForm.controls['userPhone'].value
            "
            (click)="onCheckPhoneNum()"
          >
            인증번호 받기
          </button>
          <button
            *ngIf="isPhoneCertificationSend"
            type="button"
            class="ha-btn sign-up-check"
            (click)="onResetCheckPhoneNum()"
          >
            인증번호 재전송
          </button>
        </div>

        <!-- <small class="form-text text-muted">연락 가능한 휴대전화번호를 입력하셔야 합니다.</small> -->
        <small
          class="form-text text-danger"
          *ngIf="registerForm.controls['userPhone'].invalid && registerForm.controls['userPhone'].touched"
        >
          정확한 휴대폰 번호를 입력해주세요.</small
        >
      </div>
      <div *ngIf="isPhoneCertificationSend" class="form-group">
        <!-- <label for="inputPhone">휴대폰 번호</label> -->
        <div class="form-check-wrap">
          <input
            type="phone"
            formControlName="certNum"
            autocomplete="off"
            class="form-control with-check-button"
            id="inputCertNum"
            placeholder="인증번호"
          />

          <button
            type="button"
            class="ha-btn sign-up-check"
            [disabled]="!registerForm.controls['certNum'].value || !isPhoneCertificationSend"
            (click)="onCheckCertification()"
          >
            확인
          </button>
        </div>

        <small class="form-text text-danger" *ngIf="registerForm.controls['token'].invalid"
          >인증번호 확인이 되지 않았습니다.</small
        >
      </div>
      <div class="form-group">
        <!-- <label for="inputEmail">이메일</label> -->
        <input
          type="email"
          #inputEmail
          autocomplete="off"
          formControlName="userEmail"
          class="form-control"
          id="inputEmail"
          placeholder="대표자 이메일"
        />
        <!-- <small class="form-text text-muted">수신가능한 이메일 주소를 입력하셔야 합니다.</small> -->
        <small class="form-text text-danger" *ngIf="duplicatedEmail">이미 사용 중인 이메일입니다.</small>
        <small
          class="form-text text-danger"
          *ngIf="registerForm.controls['userEmail'].invalid && registerForm.controls['userEmail'].touched"
        >
          정확한 이메일 주소를 입력해주세요.</small
        >
      </div>
    </div>

    <!-- <div class="form-group form-check mt-5">
      <label class="form-check-label agree-form-control" for="exampleCheck1">
        <i class="icon icon-check-on" *ngIf="registerForm.controls['agree'].value === true"></i>
        <i class="icon icon-check-none" *ngIf="!!registerForm.controls['agree'].value === false"></i>
        <input
          style="visibility: hidden;"
          type="checkbox"
          formControlName="agree"
          class="form-check-input"
          id="exampleCheck1"
          checked
        />
        <a href="//www.ddocdoc.com/termsofservice" target="blank">서비스 이용약관</a>&nbsp;및&nbsp;
        <a href="//www.ddocdoc.com/privacypolicy" target="blank">개인정보 처리방침</a>에 동의합니다.</label
      >
    </div> -->

    <button class="ha-btn ha-btn-spec next" [class.invalid]="registerForm.invalid" (click)="registerAccount($event)">
      인증하기
    </button>
    <!-- <span class="text-muted d-inline ml-2">이미 계정을 생성하셨다면 <a routerLink="/signin">로그인</a> </span> -->
  </form>
</div>
