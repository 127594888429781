import { HourRange, SymptomUnit } from '~models/reservation-timeline.model';
import { hourRangeToTimeRange } from './res-timeline-schedule.util';
import { parseTemplate } from '~shared/utils/timeline.utils';

export const MSG_TT_SCHEDULE_LOAD_SUCCESS = '스케줄을 성공적으로 불러왔습니다.';
export const MSG_TT_SCHEDULE_SAVE_SUCCESS =
  '모바일 예약 스케줄이 성공적으로 저장되었습니다.';
export const MSG_TT_NO_SYMPTOM =
  '사용할 수 있는 진료항목이 없습니다.\n진료항목 설정페이지에서 스케줄에\n적용할 진료항목을 추가해주세요.';
export const MSG_TT_CONFIRM_PAST_MONTH =
  '지난 월의 예약 스케줄 설정내역은 수정할 수 없습니다.\n설정내역을 확인하시겠습니까?';
export const MSG_TT_RECEIPT_TIME_INVALID =
  // tslint:disable-next-line:quotemark
  '해당 진료실의 예약 시간 설정 메뉴에서\n최소 1개 이상의 요일을 예약 가능 상태로 변경하여 주시기 바랍니다.';

const MSG_TT_PREVIEW_HOUR_END_GTE_START =
  '종료 시간은 시작 시간보다\n커야합니다.';
const MSG_TT_PREVIEW_MIN_RANGE_TOO_NARROW =
  '시작과 종료 각 시간의 간격을\n선택한 진료의 소요시간인\n<strong>{timeUnit}분</strong> 이상으로 설정해주세요.';
const MSG_TT_PREVIEW_WEEKS_NO_SELECTED =
  '적용 할 요일을\n최소 1개 이상 선택 해주세요.';
const MSG_TT_PREVIEW_SYMPTOM_UNIT_NO_SELECTED =
  '적용할 진료항목을\n선택 해주세요.';

export function getTimelinePreviewMessage(
  hourRange: HourRange,
  unit: SymptomUnit
) {
  if (!unit) {
    return MSG_TT_PREVIEW_SYMPTOM_UNIT_NO_SELECTED;
  }

  const { startHour, startMin, endHour, endMin } = hourRangeToTimeRange(
    hourRange
  );

  const bCheckedWeeks = hourRange.weekdays?.reduce(
    (prev, curr) => prev || curr
  );

  if (!bCheckedWeeks) {
    return MSG_TT_PREVIEW_WEEKS_NO_SELECTED;
  }

  const isEndHourGteStar = hourRange.start < hourRange.end;

  // console.log('hourRange', hourRange);

  if (!isEndHourGteStar) {
    return MSG_TT_PREVIEW_HOUR_END_GTE_START;
  }

  const iMinRangeLimit = unit.timeUnit;
  const iTotalStartMin = startHour * 60 + startMin;
  const iTotalEndMin = endHour * 60 + endMin;
  const iMinRangeAbs = Math.abs(iTotalStartMin - iTotalEndMin);

  const isMinRangeTooNarrow =
    iMinRangeAbs === 0 || (iMinRangeAbs > 0 && iMinRangeAbs < iMinRangeLimit);

  if (isMinRangeTooNarrow) {
    return parseTemplate(MSG_TT_PREVIEW_MIN_RANGE_TOO_NARROW, {
      timeUnit: iMinRangeLimit,
    });
  }

  return '';
}

const MSG_TT_DAY_COPY_COMPLETE = '일 복사 후 붙여넣기가 완료되었습니다.';
const MSG_TT_WEEK_COPY_COMPLETE = '주 복사 후 붙여넣기가 완료되었습니다.';
const MSG_TT_WEEK_COPY_HOLIDAY_EXCLUDED =
  '주 복사가 완료되었습니다.\n※ 붙여넣기 하는 주차에 공휴일이 포함되어 있었으나 제외되었습니다.';

export function getCopyCompleteMessage(
  type: string = 'day',
  hasHoliday: boolean = false
) {
  if (type === 'week') {
    if (hasHoliday) {
      return MSG_TT_WEEK_COPY_HOLIDAY_EXCLUDED;
    } else {
      return MSG_TT_WEEK_COPY_COMPLETE;
    }
  }

  return MSG_TT_DAY_COPY_COMPLETE;
}

const MSG_TT_LOST_SYMPTOM =
  '기존 예약 스케줄에 사용되었던 진료항목 중 일부가 비활성화되어\n해당 예약 스케줄에서 제외되었습니다.';
const MSG_TT_LOST_SCHEDULE =
  '기존 예약 스케줄에 사용되었던 스케줄 중 일부가\n진료시간 조정에 의하여 해당 예약 스케줄에서 제외되었습니다.\n(해당 팝업을 다시 보지 않으려면 예약 스케줄 설정내역을 저장해주세요.)';
const MSG_TT_LOST_SYMPTOM_SCHEDULE =
  MSG_TT_LOST_SYMPTOM + '\n또한, ' + MSG_TT_LOST_SCHEDULE;

export function getLostScheduleDataMessage(
  hasFixedSymptom: boolean,
  hasFixedSchedule: boolean = false
) {
  if (hasFixedSchedule && hasFixedSymptom) {
    return MSG_TT_LOST_SYMPTOM_SCHEDULE;
  }
  if (hasFixedSchedule) {
    return MSG_TT_LOST_SCHEDULE;
  }
  if (hasFixedSymptom) {
    return MSG_TT_LOST_SYMPTOM;
  }

  return '';
}
