import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { TelemedicineActions } from '~actions/products/telemedicine.actions';
import {
  TelemedicineRequiredSettingFetchRes,
  TelemedicineRoomSetting,
} from '~models/telemedicine.model';

interface State {
  loaded: boolean;
  requiredSetting: TelemedicineRequiredSettingFetchRes;
  useTelemedicine: boolean;
}

export type TelemedicineEntityState = State &
  EntityState<TelemedicineRoomSetting>;

export const telemedicineItemAdapter =
  createEntityAdapter<TelemedicineRoomSetting>({
    selectId: roomSetting => roomSetting.unitKey,
  });

const initialState = telemedicineItemAdapter.getInitialState<State>({
  loaded: false,
  requiredSetting: {
    payment: {
      status: 'NOT_SET',
    },
    paper: {
      status: 'NOT_SET',
    },
  },
  useTelemedicine: false,
});

export const telemedicineReducer = createReducer(
  initialState,
  on(
    TelemedicineActions.TelemedicineRoomsSettingsFetchSuccess,
    (state, { res: { use, units = [] } }) => {
      return telemedicineItemAdapter.setAll(units, {
        ...state,
        loaded: true,
        useTelemedicine: use,
      });
    }
  ),
  on(
    TelemedicineActions.TelemedicineRequiredSettingSuccess,
    (state, { res }) => {
      return {
        ...state,
        requiredSetting: {
          ...state.requiredSetting,
          ...res,
        },
      };
    }
  ),
  on(
    TelemedicineActions.TelemedicineUpdateUseSuccess,
    (state, { useTelemedicine }) => {
      return {
        ...state,
        useTelemedicine,
      };
    }
  ),
  on(
    TelemedicineActions.TelemedicineUpdateBulkRoomSettingsSuccess,
    (state, { updates }) => {
      return telemedicineItemAdapter.updateMany(updates, state);
    }
  )
);
