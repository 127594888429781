import {
  CellInfo,
  Holiday,
  ScheduleCalendarCell,
  YearMonth,
} from '~models/reservation-timeline.model';
import { makeDayMatrix } from '~store/services/res-timeline-schedule/res-timeline-schedule.util';

export const WIZARD_TYPE = 'res-timeline';

export function getPageXY(evt) {
  let pageX: number = evt.pageX;
  let pageY: number = evt.pageY;

  if (pageX === undefined) {
    pageX =
      evt.clientX +
      document.body.scrollLeft +
      document.documentElement.scrollLeft;
    pageY =
      evt.clientY +
      document.body.scrollTop +
      document.documentElement.scrollTop;
  }

  return {
    pageX,
    pageY,
  };
}

function getDataAttribute(elem: HTMLElement, key: string): string {
  return elem.getAttribute('data-' + key) || '';
}

function getDataset(elem: HTMLElement): any {
  if (typeof elem.dataset !== 'undefined') {
    return elem.dataset;
  }
  const keys = ['row', 'col', 'count', 'reason'];
  const mRet = {};

  keys.forEach(key => {
    mRet[key] = getDataAttribute(elem, key);
  });

  return mRet;
}

function parseInteger(value: any) {
  return parseInt(value, 10);
}

export function convertDataFromEvent(evt, day: number = 0): CellInfo {
  const dataset = getDataset(evt.target);
  const {
    row: rowRaw,
    col: colRaw,
    count: cntRaw,
    reason: reasonRaw,
  } = dataset;
  const row = parseInteger(rowRaw);
  const col = parseInteger(colRaw);
  const count = parseInteger(cntRaw);
  const reason = parseInteger(reasonRaw);
  // const reason = TimelinePreventReason[parseInt(reasonRaw, 10)];
  const pos = getPageXY(evt);
  const { pageX, pageY } = pos;

  return {
    key: `_${day}_${row}_${col}`,
    day,
    row,
    col,
    count,
    pageX,
    pageY,
    reason,
    // reason: TimelinePreventReason[reason],
  };
}

export function toHourMinute(val: string) {
  if (val) {
    const aVal = val.split(':');
    let hour = parseInteger(aVal[0]);
    let minute = parseInteger(aVal[1]);

    if (isNaN(hour)) {
      hour = 0;
    }
    if (isNaN(minute)) {
      minute = 0;
    }

    return {
      hour,
      minute,
    };
  }
  return {
    hour: 0,
    minute: 0,
  };
}

export function padNumber(val: number) {
  return val > 9 ? val + '' : '0' + val;
}

export function toHourMinuteString(val: { hour: number; minute: number }) {
  let { hour } = val;
  const { minute } = val;

  // 새벽 0시는 24시로 바꿔 준다.
  if (hour === 0) {
    hour = 24;
  }

  const sHour = padNumber(hour);
  const sMinute = padNumber(minute);

  return sHour + ':' + sMinute;
}

export function makeScheduleCalendarCells(
  year: number,
  month: number,
  markedMap: { [day: string]: boolean },
  holidayMap: { [day: string]: Holiday }
) {
  if (year === 0 && month === 0) {
    return [];
  }

  const aaMatrix = makeDayMatrix(year, month);
  const aaCells: ScheduleCalendarCell[][] = [];
  const mMarkedMap: { [day: string]: boolean } = markedMap || {};
  const iMaxRowIndex = aaMatrix.length - 1;
  const mHoliday = holidayMap || {};
  let hasMaxDayFound = false;

  aaMatrix.forEach((row, rowIndex) => {
    const aCells: ScheduleCalendarCell[] = [];

    row.forEach((day, colIndex) => {
      if (iMaxRowIndex === rowIndex && !hasMaxDayFound) {
        if (day < 0) {
          hasMaxDayFound = true;
          aCells[colIndex - 1].tail = true;
        }
      }
      aCells.push({
        day,
        marked: !!mMarkedMap[day],
        copySelected: false,
        pasteSelected: false,
        head: (rowIndex > 0 && colIndex === 0) || day === 1,
        tail: colIndex === 6,
        holiday: !!mHoliday[day],
      });
    });

    aaCells.push(aCells);
  });

  return aaCells;
}

export function getPrevMonth(year: number, month: number): YearMonth {
  const date = new Date(year, month - 1, 0);

  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
  };
}

export function parseTemplate(tpl: string, data: any): string {
  if (!data) {
    return tpl;
  }

  let sRet = tpl;

  Object.keys(data).forEach(key => {
    sRet = sRet.replace(new RegExp(`\{${key}\}`, 'ig'), data[key]);
  });

  return sRet;
}

export function isPastMonth(yearMonth: YearMonth) {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;

  if (yearMonth.year < year) {
    return true;
  }
  if (yearMonth.year > year) {
    return false;
  }
  if (yearMonth.month < month) {
    return true;
  }

  return false;
}
