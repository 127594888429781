const STATUS_RESULT_NOTICE = 'STATUS_RESULT_NOTICE' as const;
import { createAction, props } from '@ngrx/store';
import {
  ResultNotifiedResModel,
  ResultNotifiedSearchUiQuery,
} from '~models/status-result-notified.model';

export const StatusResultNoticeActionTypes = {
  LOAD_LIST: `[${STATUS_RESULT_NOTICE}] Load List`,
  LOAD_LIST_SUCCESS: `[${STATUS_RESULT_NOTICE}] Load List Success`,
  DOWNLOAD_CSV_LIST: `[${STATUS_RESULT_NOTICE}] Download CSV List`,
  FAIL: `[${STATUS_RESULT_NOTICE}] Fail`,
} as const;

export const StatusResultNotifiedLoadList = createAction(
  StatusResultNoticeActionTypes.LOAD_LIST,
  props<{ payload: ResultNotifiedSearchUiQuery }>()
);

export const StatusResultNotifiedLoadListSuccess = createAction(
  StatusResultNoticeActionTypes.LOAD_LIST_SUCCESS,
  props<{ payload: ResultNotifiedResModel }>()
);

export const StatusResultNotifiedDownloadList = createAction(
  StatusResultNoticeActionTypes.DOWNLOAD_CSV_LIST,
  props<{ payload: ResultNotifiedSearchUiQuery }>()
);

export const StatusResultNotifiedFail = createAction(
  StatusResultNoticeActionTypes.FAIL,
  props<{ payload: string }>()
);

export const StatusResultNotifiedActions = {
  StatusResultNotifiedLoadList,
  StatusResultNotifiedLoadListSuccess,
  StatusResultNotifiedDownloadList,
  StatusResultNotifiedFail,
};
