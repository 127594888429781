import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FindPwActions } from '~actions/auth/find-pw.action';
import * as fromAuth from '~reducers/auth';
import { FindUserInfo } from '~models/auth.model';

@Component({
  selector: 'app-find-password-page',
  templateUrl: './find-password-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FindPasswordPageComponent {
  error$: Observable<string | null>;

  constructor(private store: Store<fromAuth.AuthInfoState>) {
    this.error$ = this.store.pipe(select(fromAuth.getFindPwError));
  }

  onSubmit(data: FindUserInfo): void {
    this.store.dispatch(FindPwActions.FindPw({ payload: data }));
  }
}
