import { AllStopCareRoom, PauseCareRoom } from '~models/hospital.model';
import { AllEmrCareRoomRes, CareRoom } from '~models/care-room.model';
import { createAction, props } from '@ngrx/store';

export const CareRoomActionTypes = {
  FETCH_CAREROOM: '[CareRoom] Fetch CareRoom',
  FETCH_CAREROOM_FAIL: '[CareRoom] Fetch CareRoom Fail',
  FETCH_CAREROOM_COMPLETE: '[CareRoom] Fetch CareRoom Complete',
  SET_AGREEMENT_INFO: '[CareRoom] Set Agreement Info',

  SELECT_CAREROOM: '[CareRoom] Select CareRoom',

  CAREROOM_UPDATE: '[CareRoom] CareRoom Update',
  CAREROOM_SCHEDULE_IMPORT: '[CareRoom] CareRoom Schedule Import',
  CAREROOM_UPDATE_COMPLETE: '[CareRoom] CareRoom Update Complete',
  CAREROOM_UPDATE_FAIL: '[CareRoom] CareRoom Update Fail',

  CAREROOM_ALL_STOP: '[CareRoom] CareRoom All Stop',
  CAREROOM_ALL_STOP_COMPLETE: '[CareRoom] CareRoom All Stop Success',
  CAREROOM_ALL_STOP_FAIL: '[CareRoom] CareRoom All Stop Fail',

  CAREROOM_MULTIPLE_UPDATE: '[CareRoom] CareRoom Multiple Update',
  CAREROOM_MULTIPLE_UPDATE_LAZY: '[CareRoom] CareRoom Multiple Update Lazy',
  CAREROOM_MULTIPLE_UPDATE_COMPLETE:
    '[CareRoom] CareRoom Multiple Update Suceess',
  CAREROOM_MULTIPLE_UPDATE_FAIL: '[CareRoom] CareRoom Multiple Update Fail',

  CAREROOM_STOP: '[CareRoom] CareRoom Stop',
  CAREROOM_STOP_COMPLETE: '[CareRoom] CareRoom Stop Complete',
  CAREROOM_STOP_FAIL: '[CareRoom] CareRoom Stop Fail',

  CAREROOM_ORDER_UPDATE: '[CareRoom] CareRoom Order Update',
  CAREROOM_ORDER_UPDATE_COMPLETE: '[CareRoom] CareRoom Order Update Success',
  CAREROOM_ORDER_UPDATE_FAIL: '[CareRoom] CareRoom Order Update fail',

  CAREROOM_AGREE_TELEMEDICINE: '[CareRoom] Telemedicine agree',
  CAREROOM_AGREE_TELEMEDICINE_SUCCESS: '[CareRoom] Telemedicine agree Success',

  CAREROOM_ACTIVE: '[CareRoom] CareRoom Active',
  CAREROOM_ACTIVE_SUCCESS: '[CareRoom] CareRoom Active Success',

  FETCH_ALL_EMR_CAREROOMS: '[CareRoom] Fetch All Emr CareRooms',
  FETCH_ALL_EMR_CAREROOMS_SUCCESS: '[CareRoom] Fetch All Emr CareRooms Success',

  EMR_CAREROOM_ACTIVE: '[CareRoom] Emr CareRoom Active',
  EMR_CAREROOM_ACTIVE_SUCCESS: '[CareRoom] Emr CareRoom Active Success',
} as const;

// 진료실 불러오기
export const FetchCareRoom = createAction(CareRoomActionTypes.FETCH_CAREROOM);

export const FetchCareRoomComplete = createAction(
  CareRoomActionTypes.FETCH_CAREROOM_COMPLETE,
  props<{ payload: { careRooms: CareRoom[] } }>()
);

export const FetchCareRoomFail = createAction(
  CareRoomActionTypes.FETCH_CAREROOM_FAIL,
  props<{ payload: any }>()
);

export const SetAgreementInfo = createAction(
  CareRoomActionTypes.SET_AGREEMENT_INFO,
  props<{ payload: { telemedicine: boolean } }>()
);

export const CareRoomUpdate = createAction(
  CareRoomActionTypes.CAREROOM_UPDATE,
  props<{
    payload: {
      id: string;
      changes: Partial<CareRoom>;
      isInitializing?: boolean;
      navigateCommands?: any[];
      completeMessage?: string;
    };
  }>()
);

export const CareRoomUpdateLazy = createAction(
  CareRoomActionTypes.CAREROOM_MULTIPLE_UPDATE_LAZY,
  props<{
    payload: {
      id: string;
      changes: Partial<CareRoom>;
    };
  }>()
);

export const CareRoomScheduleImport = createAction(
  CareRoomActionTypes.CAREROOM_SCHEDULE_IMPORT,
  props<{
    payload: {
      id: string;
      selectedCareRoomKey: string;
      scheduleType: string;
      changes: Partial<CareRoom>;
      isInitializing?: boolean;
    };
  }>()
);

export const CareRoomUpdateComplete = createAction(
  CareRoomActionTypes.CAREROOM_UPDATE_COMPLETE,
  props<{ navigateCommands?: any[]; completeMessage?: string }>()
);

export const CareRoomUpdateFail = createAction(
  CareRoomActionTypes.CAREROOM_UPDATE_FAIL,
  props<{ payload: any }>()
);

// 진료실 선택
export const CareRoomSelect = createAction(
  CareRoomActionTypes.SELECT_CAREROOM,
  props<{ payload: { key: any } }>()
);

// 진료실 전체 일시정지
export const CareRoomAllStop = createAction(
  CareRoomActionTypes.CAREROOM_ALL_STOP,
  props<{ payload: { update: AllStopCareRoom } }>()
);

export const CareRoomAllStopComplete = createAction(
  CareRoomActionTypes.CAREROOM_ALL_STOP_COMPLETE
);

export const CareRoomAllStopFail = createAction(
  CareRoomActionTypes.CAREROOM_ALL_STOP_FAIL,
  props<{ payload: any }>()
);

// 진료실 한번에 업데이트

export const CareRoomMultipleUpdate = createAction(
  CareRoomActionTypes.CAREROOM_MULTIPLE_UPDATE,
  props<{ payload: { update: Partial<CareRoom>[] } }>()
);

export const CareRoomMultipleUpdateComplete = createAction(
  CareRoomActionTypes.CAREROOM_MULTIPLE_UPDATE_COMPLETE
);

export const CareRoomMultipleUpdateFail = createAction(
  CareRoomActionTypes.CAREROOM_MULTIPLE_UPDATE_FAIL,
  props<{ payload: any }>()
);

// 진료실 일시정지

export const CareRoomStop = createAction(
  CareRoomActionTypes.CAREROOM_STOP,
  props<{
    payload: {
      unit: string;
      data: PauseCareRoom;
    };
  }>()
);

export const CareRoomStopComplete = createAction(
  CareRoomActionTypes.CAREROOM_STOP_COMPLETE,
  props<{
    payload: {
      unit: string;
      data: PauseCareRoom;
    };
  }>()
);

export const CareRoomStopFail = createAction(
  CareRoomActionTypes.CAREROOM_STOP_FAIL,
  props<{ payload: any }>()
);

// 진료실 순서 변경
export const CareRoomOrderUpdate = createAction(
  CareRoomActionTypes.CAREROOM_ORDER_UPDATE,
  props<{ payload: CareRoom[] }>()
);

export const CareRoomOrderUpdateComplete = createAction(
  CareRoomActionTypes.CAREROOM_ORDER_UPDATE_COMPLETE
);

export const CareRoomOrderUpdateFail = createAction(
  CareRoomActionTypes.CAREROOM_ORDER_UPDATE_FAIL,
  props<{ payload: any }>()
);

// agreement 변경
export const CareRoomAgreeTelemedicine = createAction(
  CareRoomActionTypes.CAREROOM_AGREE_TELEMEDICINE
);

export const CareRoomAgreeTelemedicineSuccess = createAction(
  CareRoomActionTypes.CAREROOM_AGREE_TELEMEDICINE_SUCCESS
);

export const CareRoomActive = createAction(
  CareRoomActionTypes.CAREROOM_ACTIVE,
  props<{ payload: { active: boolean; unitKey: string } }>()
);

export const CareRoomActiveSuccess = createAction(
  CareRoomActionTypes.CAREROOM_ACTIVE_SUCCESS
);

export const CareRoomEmrFetchAll = createAction(
  CareRoomActionTypes.FETCH_ALL_EMR_CAREROOMS,
  props<{ payload?: { active: boolean } }>()
);

export const CareRoomEmrFetchAllSuccess = createAction(
  CareRoomActionTypes.FETCH_ALL_EMR_CAREROOMS_SUCCESS,
  props<{ payload: AllEmrCareRoomRes }>()
);

export const CareRoomEmrActive = createAction(
  CareRoomActionTypes.EMR_CAREROOM_ACTIVE,
  props<{ payload: { active: boolean; unitKey: string } }>()
);

export const CareRoomEmrActiveSuccess = createAction(
  CareRoomActionTypes.EMR_CAREROOM_ACTIVE_SUCCESS,
  props<{ payload: { active: boolean; unitKey: string } }>()
);

export const CareRoomActions = {
  FetchCareRoom,
  FetchCareRoomComplete,
  FetchCareRoomFail,
  CareRoomUpdate,
  CareRoomUpdateLazy,
  CareRoomScheduleImport,
  CareRoomUpdateComplete,
  CareRoomUpdateFail,
  CareRoomSelect,
  CareRoomAllStop,
  CareRoomAllStopComplete,
  CareRoomAllStopFail,
  CareRoomMultipleUpdate,
  CareRoomMultipleUpdateComplete,
  CareRoomMultipleUpdateFail,
  CareRoomStop,
  CareRoomStopComplete,
  CareRoomStopFail,
  CareRoomOrderUpdate,
  CareRoomOrderUpdateComplete,
  CareRoomOrderUpdateFail,
  SetAgreementInfo,
  CareRoomAgreeTelemedicine,
  CareRoomAgreeTelemedicineSuccess,
  CareRoomActive,
  CareRoomActiveSuccess,
  CareRoomEmrFetchAll,
  CareRoomEmrFetchAllSuccess,
  CareRoomEmrActive,
  CareRoomEmrActiveSuccess,
};
