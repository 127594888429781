import {
  HospitalInfoCategoryModel,
  HospitalInfoUiModel,
} from '~models/hospital.model';

export function createHospitalInfoUiModel(): HospitalInfoUiModel {
  return {
    _id: '',
    title: '',
    address: '',
    roadAddress: '',
    position: {
      lat: 0,
      lng: 0,
    },
    telReal: '',
    mainDepartment: {} as HospitalInfoCategoryModel,
    useHospitalReview: true,
  };
}
