<div class="period-dropdown">
  <div ngbDropdown class="period-dropdown-value">
    <div>
      <button class="ha-dropdown-toggle form-control w-100" ngbDropdownToggle>
        {{ value }}
      </button>
    </div>
    <div ngbDropdownMenu class="w-100">
      <div class="scroll-wrap">
        <button
          class="dropdown-item"
          *ngFor="let period of dropdownOptions"
          (click)="handleChange(period)"
        >
          {{ period }}
        </button>
      </div>
    </div>
  </div>
  <p *ngIf="unit">{{ unit }}</p>
</div>
