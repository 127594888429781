<div class="scroll" [ngClass]="[theme]">
  <div class="tab-list">
    <button
      type="button"
      class="ha-btn tab-item"
      *ngFor="let item of items; index as idx"
      [ngClass]="{ active: tab === idx }"
      (click)="onTabSelect(idx)"
    >
      <span>{{ item }}</span>
    </button>
  </div>
</div>
