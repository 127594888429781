import { createAction, props } from '@ngrx/store';
import {
  ChangeKioskCertSetupPayload,
  KioskFetchResult,
  KioskPrintingPaperSetupModel,
  KioskUpdateData,
} from '~models/kiosk.model';

export enum KioskActionType {
  FETCH_KIOSK_DATA = '[Kiosk] Fetch Kiosk Data',
  FETCH_KIOSK_DATA_SUCCESS = '[Kiosk] Fetch Kiosk Data Success',
  FETCH_KIOSK_DATA_FAIL = '[Kiosk] Fetch Kiosk Data Fail',

  UPDATE_KIOSK_DATA = '[Kiosk] Update Kiosk Data',
  UPDATE_KIOSK_DATA_SUCCESS = '[Kiosk] Update Kiosk Data Success',
  UPDATE_KIOSK_DATA_FAIL = '[Kiosk] Update Kiosk Data Fail',

  CHANGE_KIOSK_PRINT_SETUP = '[Kiosk] Change Kiosk Print Setup',
  CHANGE_KIOSK_PRINT_SETUP_AFTER = '[Kiosk] Change Kiosk Print Setup After',
  CHANGE_KIOSK_USE_CERT_PRINT = '[Kiosk] Change Kiosk use cert print',
  CHANGE_KIOSK_CERT_SETUP = '[Kiosk] Change Kiosk Cert Setup',
  CANCEL_KIOSK_PRINT_SETUP = '[Kiosk] Cancel Kiosk Print Setup After',

  UPDATE_KIOSK_CERT_SETUP = '[Kiosk] Update Kiosk Cert Setup',
  UPDATE_KIOSK_CERT_SETUP_SUCCESS = '[Kiosk] Update Kiosk Cert Setup Success',
}

export const FetchKioskData = createAction(KioskActionType.FETCH_KIOSK_DATA);

export const FetchKioskDataSuccess = createAction(
  KioskActionType.FETCH_KIOSK_DATA_SUCCESS,
  props<{ payload: KioskFetchResult }>()
);

export const FetchKioskDataFail = createAction(
  KioskActionType.FETCH_KIOSK_DATA_FAIL,
  props<any>()
);

export const UpdateKioskData = createAction(
  KioskActionType.UPDATE_KIOSK_DATA,
  props<{ payload: Partial<KioskUpdateData> }>()
);

export const UpdateKioskDataSuccess = createAction(
  KioskActionType.UPDATE_KIOSK_DATA_SUCCESS
);

export const UpdateKioskDataFail = createAction(
  KioskActionType.UPDATE_KIOSK_DATA_FAIL,
  props<any>()
);

export const ChangeKioskPrintSetup = createAction(
  KioskActionType.CHANGE_KIOSK_PRINT_SETUP,
  props<{ payload: KioskPrintingPaperSetupModel }>()
);

export const ChangeKioskPrintSetupAfter = createAction(
  KioskActionType.CHANGE_KIOSK_PRINT_SETUP_AFTER,
  props<{ payload: KioskPrintingPaperSetupModel }>()
);

export const ChangeKioskUseCertPrinting = createAction(
  KioskActionType.CHANGE_KIOSK_USE_CERT_PRINT,
  props<{ payload: boolean }>()
);

export const ChangeKioskCertSetup = createAction(
  KioskActionType.CHANGE_KIOSK_CERT_SETUP,
  props<{ payload: ChangeKioskCertSetupPayload }>()
);

export const CancelKioskPrintSetup = createAction(
  KioskActionType.CANCEL_KIOSK_PRINT_SETUP
);

export const UpdateKioskCertSetup = createAction(
  KioskActionType.UPDATE_KIOSK_CERT_SETUP,
  props<{ payload: KioskUpdateData }>()
);

export const UpdateKioskCertSetupSuccess = createAction(
  KioskActionType.UPDATE_KIOSK_CERT_SETUP_SUCCESS
);

export const KioskActions = {
  FetchKioskData,
  FetchKioskDataSuccess,
  FetchKioskDataFail,
  UpdateKioskData,
  UpdateKioskDataSuccess,
  UpdateKioskDataFail,
  ChangeKioskPrintSetup,
  ChangeKioskPrintSetupAfter,
  ChangeKioskUseCertPrinting,
  ChangeKioskCertSetup,
  UpdateKioskCertSetup,
  UpdateKioskCertSetupSuccess,
};
