import { NoticeActions } from '~actions/products/notice.actions';
import { Notice, NoticeList } from '~models/notice.model';
import { createReducer, on } from '@ngrx/store';

export interface NoticeState {
  latestNotice: Notice[];
  noticeList: NoticeList;
  noticeDetail?: Notice;
  loading: boolean;
}

const initialNoticeListState: NoticeList = {
  topNotice: [],
  items: [],
  totalCount: 0,
  skip: 0,
  limit: 0,
};
const initialNoticeState: NoticeState = {
  latestNotice: [],
  noticeList: initialNoticeListState,
  loading: false,
};

export const reducer = createReducer(
  initialNoticeState,
  on(NoticeActions.NoticeLatestLoadSuccess, (state, action) => ({
    ...state,
    latestNotice: action.payload,
  })),
  on(NoticeActions.NoticeListLoadSuccess, (state, action) => ({
    ...state,
    noticeList: action.payload,
    loading: false,
  })),
  on(NoticeActions.NoticeListLoad, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(NoticeActions.NoticeDetailLoad, (state, action) => ({
    ...state,
    noticeDetail: {} as Notice,
    loading: true,
  })),
  on(NoticeActions.NoticeDetailLoadSuccess, (state, action) => ({
    ...state,
    noticeDetail: action.payload,
    loading: false,
  }))
);

export const getNoticeLatest = (state: NoticeState) => state.latestNotice;
export const getNoticeList = (state: NoticeState) => state.noticeList;
export const getNoticeDetail = (state: NoticeState) => state.noticeDetail;
export const getNoticeLoading = (state: NoticeState) => state.loading;
