import { createAction, props } from '@ngrx/store';
import { Notice, NoticeList, NoticeRequestParams } from '~models/notice.model';

export enum NoticeActionTypes {
  NOTICE_LATEST_LOAD = '[Notice] Latest Load',
  NOTICE_LATEST_LOAD_SUCCESS = '[Notice] Latest Load Success',
  NOTICE_LIST_LOAD = '[Notice] List Load',
  NOTICE_LIST_LOAD_SUCCESS = '[Notice] List Load Success',
  NOTICE_DETAIL_LOAD = '[Notice] Detail Load',
  NOTICE_DETAIL_LOAD_SUCCESS = '[Notice] Detail Load Success',
}

export const NoticeLatestLoad = createAction(
  NoticeActionTypes.NOTICE_LATEST_LOAD
);

export const NoticeLatestLoadSuccess = createAction(
  NoticeActionTypes.NOTICE_LATEST_LOAD_SUCCESS,
  props<{ payload: Notice[] }>()
);

export const NoticeListLoad = createAction(
  NoticeActionTypes.NOTICE_LIST_LOAD,
  props<{ payload: NoticeRequestParams }>()
);

export const NoticeListLoadSuccess = createAction(
  NoticeActionTypes.NOTICE_LIST_LOAD_SUCCESS,
  props<{ payload: NoticeList }>()
);

export const NoticeDetailLoad = createAction(
  NoticeActionTypes.NOTICE_DETAIL_LOAD,
  props<{ noticeId: string }>()
);

export const NoticeDetailLoadSuccess = createAction(
  NoticeActionTypes.NOTICE_DETAIL_LOAD_SUCCESS,
  props<{ payload: Notice }>()
);

export const NoticeActions = {
  NoticeLatestLoad,
  NoticeLatestLoadSuccess,
  NoticeListLoad,
  NoticeListLoadSuccess,
  NoticeDetailLoad,
  NoticeDetailLoadSuccess,
};
