import { createAction, props } from '@ngrx/store';
import {
  CrmSymptomCategoryItemModel,
  CrmSymptomItemModel,
  HospitalScheduleCreateModel,
  HospitalScheduleFormModel,
} from '~models/hospital-crm.model';

export enum CrmAddScheduleActionType {
  REQ_FAIL = '[CrmScheduleAdd] Req Fail',
  MAKE_SCHEDULE_DATA = '[CrmScheduleAdd] Make Schedule Data',
  MAKE_SCHEDULE_DATA_SUCCESS = '[CrmScheduleAdd] Make Schedule Data Success',
  SAVE_FORM_DATA = '[CrmScheduleAdd] Save Form Data',
  SAVE_SCHEDULE = '[CrmScheduleAdd] Save Schedule',
  SAVE_SCHEDULE_SUCCESS = '[CrmScheduleAdd] Save Schedule Success',
  CATEGORY_LOAD = '[CrmScheduleAdd] Category Load',
  CATEGORY_LOAD_SUCCESS = '[CrmScheduleAdd] Category Load Success',
  SYMPTOM_LOAD = '[CrmScheduleAdd] Schedule Load',
  SYMPTOM_LOAD_SUCCESS = '[CrmScheduleAdd] Schedule Load Success',
  RESET_SCHEDULE_DATA = '[CrmScheduleAdd] Reset Schedule Data',
}

export const CrmAddScheduleReqFail = createAction(
  CrmAddScheduleActionType.REQ_FAIL,
  props<{ message: any }>()
);

export const CrmMakeScheduleData = createAction(
  CrmAddScheduleActionType.MAKE_SCHEDULE_DATA,
  props<{ formData: HospitalScheduleFormModel }>()
);

export const CrmMakeScheduleDataSuccess = createAction(
  CrmAddScheduleActionType.MAKE_SCHEDULE_DATA_SUCCESS,
  props<{ data: HospitalScheduleCreateModel }>()
);

export const CrmSaveFormData = createAction(
  CrmAddScheduleActionType.SAVE_FORM_DATA,
  props<{ formData: HospitalScheduleFormModel }>()
);

export const CrmResetScheduleData = createAction(
  CrmAddScheduleActionType.RESET_SCHEDULE_DATA
);

export const CrmSaveSchedule = createAction(
  CrmAddScheduleActionType.SAVE_SCHEDULE
);

export const CrmSaveScheduleSuccess = createAction(
  CrmAddScheduleActionType.SAVE_SCHEDULE_SUCCESS
);

export const CrmCategoryLoad = createAction(
  CrmAddScheduleActionType.CATEGORY_LOAD
);

export const CrmCategoryLoadSuccess = createAction(
  CrmAddScheduleActionType.CATEGORY_LOAD_SUCCESS,
  props<{ categories: CrmSymptomCategoryItemModel[] }>()
);

export const CrmSymptomLoad = createAction(
  CrmAddScheduleActionType.SYMPTOM_LOAD,
  props<{ symptomCategoryId: string }>()
);

export const CrmSymptomLoadSuccess = createAction(
  CrmAddScheduleActionType.SYMPTOM_LOAD_SUCCESS,
  props<{ symptoms: CrmSymptomItemModel[] }>()
);

export const CrmAddScheduleActions = {
  CrmAddScheduleReqFail,
  CrmMakeScheduleData,
  CrmMakeScheduleDataSuccess,
  CrmSaveFormData,
  CrmResetScheduleData,
  CrmSaveSchedule,
  CrmSaveScheduleSuccess,
  CrmCategoryLoad,
  CrmCategoryLoadSuccess,
  CrmSymptomLoad,
  CrmSymptomLoadSuccess,
};
