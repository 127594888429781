import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[sliderItem]',
})
export class SliderItemDirective {
  private _isInit = false;
  get isInit() {
    return this._isInit;
  }

  constructor(private elem: ElementRef) {}

  initPosition(direction: string) {
    if (this._isInit) {
      return;
    }

    const elemCont = this.elem.nativeElement;

    elemCont.style.position = 'absolute';
    elemCont.style.display = 'block';

    if (direction === 'x') {
      elemCont.style.top = '0';
      elemCont.style.width = '33.333%';
      elemCont.style.height = '100%';
    } else if (direction === 'y') {
      elemCont.style.left = '0';
      elemCont.style.height = '33.333%';
      elemCont.style.width = '100%';
    } else {
      throw new Error('direction error:' + direction);
    }

    this._isInit = true;
  }
}
