import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { koLocale } from 'ngx-bootstrap/locale';
import { DateRange } from '~models/common.model';
import { dateToString } from '../../services/util';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker/bs-daterangepicker.config';

@Component({
  selector: 'app-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
})
export class DateRangeFilterComponent implements OnInit {
  @Input() placeholder = '기간 설정';
  @Input() placement: 'top' | 'bottom' | 'left' | 'right' = 'bottom';
  @Input() useModal = false;

  private _value: DateRange = { startDate: '', endDate: '' };
  @Input() set value(value: DateRange) {
    this._value = !value ? { startDate: '', endDate: '' } : value;

    if (!value || !value.startDate || !value.endDate) {
      this.bsValue = [null, null];

      return;
    }

    // if (
    //   value.startDate === this._value.startDate &&
    //   value.endDate === this._value.endDate
    // ) {
    //   return;
    // }

    this.bsValue = [
      value.startDate ? new Date(value.startDate) : null,
      value.endDate ? new Date(value.endDate) : null,
    ];
  }

  get value() {
    return this._value;
  }

  @Output() valueChange = new EventEmitter<DateRange>();

  bsValue: Date[] = [null, null];
  minDate = new Date('2019-08-01');
  maxDate = new Date();
  bsConfig: Partial<BsDaterangepickerConfig> = {
    showWeekNumbers: false,
    dateInputFormat: 'yyyy-mm-dd',
    containerClass: 'penalty-status-dateRangePicker',
  };

  constructor(public modal: NgbModal, private localeService: BsLocaleService) {}

  ngOnInit() {
    defineLocale('ko', koLocale);
    this.localeService.use('ko');
  }

  handleBsValueChange(values: Date[]) {
    const dateRange: DateRange = {
      startDate: dateToString(values[0]),
      endDate: dateToString(values[1]),
    };

    if (
      this._value.startDate === dateRange.startDate &&
      this._value.endDate === dateRange.endDate
    ) {
      return;
    }

    this._value = dateRange;
    this.valueChange.emit({ ...dateRange });
  }
}
