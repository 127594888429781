import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import { AccountInfo, AuthTypeEnum } from '~models/auth.model';
import {
  basicSideMenu,
  headerMenu,
  SIDE_MENU_NAMES,
} from '~models/layout.model';
import * as fromAuth from './auth.reducer';
import * as fromFindId from './find-id.reducer';
import * as fromFindPw from './find-pw.reducer';
import * as fromResetPw from './reset-pw.reducer';
import * as fromServiceVideo from './service-video.reducers';
import { getServiceVideoResponse } from './service-video.reducers';

export interface AuthInfoState {
  authInfo: fromAuth.AuthState;
  findId: fromFindId.FindIdState;
  findPw: fromFindPw.FindPwState;
  resetPw: fromResetPw.ResetPwState;
  serviceVideoState: fromServiceVideo.ServiceVideoState;
}

export const reducers: ActionReducerMap<AuthInfoState> = {
  authInfo: fromAuth.reducer,
  findId: fromFindId.reducer,
  findPw: fromFindPw.reducer,
  resetPw: fromResetPw.reducer,
  serviceVideoState: fromServiceVideo.reducer,
};

export const selectAuthState = createFeatureSelector<AuthInfoState>('auth');

export const getAuthInfoState = createSelector(
  selectAuthState,
  (state: AuthInfoState) => state.authInfo
);

// auth state
export const getAccountInfoState = createSelector(
  getAuthInfoState,
  fromAuth.getAccountInfo
);

export const getPhoneCertToken = createSelector(
  getAuthInfoState,
  fromAuth.getPhoneCertToken
);

export const getGuideType = createSelector(
  getAuthInfoState,
  fromAuth.getGuideType
);

export const getHospitalTitle = createSelector(
  getAccountInfoState,
  state => state?.hospital?.title
);

export const getAgentType = createSelector(
  getAccountInfoState,
  state => state?.agentType
);

export const getWaitingSetupId = createSelector(
  getAccountInfoState,
  state => state?.setupId
);

export const getAccountSuccessInfoState = createSelector(
  getAuthInfoState,
  fromAuth.getSuccessInfo
);

export const getAuthLoading = createSelector(
  getAuthInfoState,
  fromAuth.getAuthLoading
);

export const getLoggedIn = createSelector(
  getAuthInfoState,
  fromAuth.getLoggedIn
);

export const getError = createSelector(getAuthInfoState, fromAuth.getError);

export const getFindIdState = createSelector(
  selectAuthState,
  (state: AuthInfoState) => state.findId
);

export const getFindIdError = createSelector(
  getFindIdState,
  fromFindId.getError
);

export const getFindId = createSelector(getFindIdState, fromFindId.getUserId);

export const getFindIdLoading = createSelector(
  getFindIdState,
  fromFindId.getLoading
);

// findPw state

export const getFindPwState = createSelector(
  selectAuthState,
  (state: AuthInfoState) => state.findPw
);

export const getFindPwError = createSelector(
  getFindPwState,
  fromFindPw.getError
);

export const getFindPwToken = createSelector(
  getFindPwState,
  fromFindPw.getResetToken
);

// resetPw state

export const getResetPwState = createSelector(
  selectAuthState,
  (state: AuthInfoState) => state.resetPw
);

export const getResetPwError = createSelector(
  getResetPwState,
  fromResetPw.getError
);

export const getRestPwShowComplete = createSelector(
  getResetPwState,
  state => state.showComplete
);

/**
 * 메인 동영상
 */
export const getServiceVideoState = createSelector(
  selectAuthState,
  (state: AuthInfoState) => state.serviceVideoState
);

export const getServiceVideoList = createSelector(
  getServiceVideoState,
  fromServiceVideo.getServiceVideoResponse
);

export const getServiceVideoError = createSelector(
  getServiceVideoState,
  fromServiceVideo.getServiceVideoError
);

function hasHospitalCrmService(accountInfo: AccountInfo) {
  return accountInfo.useDdocdocService;
}

function hasEmrAgent({ agentType = '' }: AccountInfo, emrWhiteList: string[]) {
  if (!agentType) {
    return false;
  }

  return new Set(emrWhiteList).has(agentType);
}

function hasManagementAccount(accountInfo: AccountInfo) {
  return accountInfo.authType === AuthTypeEnum.DDOCDOC_ADMIN;
}

function hasKakaoService(accountInfo: AccountInfo) {
  const serviceType = accountInfo.hospital?.serviceType;
  if (serviceType) {
    const iLen = serviceType.length || 0;

    for (let i = 0; i < iLen; i++) {
      switch (serviceType[i]) {
        case 'WAITINGS':
        case 'RECEIPT':
          return true;
        default:
          return false;
      }
    }
    return false;
  } else {
    return false;
  }
}

// 카카오 프로필 사용 여부
// export const getUsekakao = createSelector(getAccountInfoState, hasKakaoService);

export const getSideMenus = createSelector(getAccountInfoState, accountInfo => {
  if (accountInfo?.hospital && accountInfo.hospital.serviceType) {
    const serviceTypesArr = accountInfo.hospital.serviceType;

    const modifiedBasicSideMenu = basicSideMenu.map(sideMenu => {
      if (sideMenu.type === 'unmanned') {
        const filteredMenuItems = (() => {
          const hasKioskServiceType = serviceTypesArr.indexOf('KIOSK') > -1;
          const hasTabletServiceType =
            serviceTypesArr.indexOf('SELF_DESK') > -1;
          const hasMobilePaymentServiceType =
            serviceTypesArr.indexOf('PAYMENT') > -1;
          return sideMenu.menuItems?.filter(item => {
            return (
              (item.link.indexOf('kiosk') > -1 && hasKioskServiceType) ||
              (item.link.indexOf('tablet') > -1 && hasTabletServiceType) ||
              (item.link.indexOf('payment') > -1 && hasMobilePaymentServiceType)
            );
          });
        })();
        return { ...sideMenu, menuItems: filteredMenuItems };
      }
      return sideMenu;
    });
    // .filter(sideMenu => sideMenu.menuItems.length > 0);

    return modifiedBasicSideMenu.filter(sideMenu => {
      // const ynum = !!accountInfo.hospital ? accountInfo.hospital.ynum : null;
      const waitManageException = sideMenu.type === 'wait-manage';
      const hasNoMenuItems = sideMenu.menuItems?.length === 0;
      const hasNoSideMenuNames = !SIDE_MENU_NAMES[sideMenu.type];
      const hasServiceTypes =
        serviceTypesArr.indexOf(SIDE_MENU_NAMES[sideMenu.type]) >= 0;

      if (sideMenu.type === 'kakao-plus') {
        return hasKakaoService(accountInfo);
      }

      // blockAgents에 agentType가 있을 경우
      const bloackedAgents = sideMenu.blockedAgents || [];
      if (sideMenu.blockedAgents && hasEmrAgent(accountInfo, bloackedAgents)) {
        return false;
      }

      // allowedAgents에 agentType가 없을 경우
      const allowedAgents = sideMenu.allowedAgents || [];
      if (sideMenu.allowedAgents && !hasEmrAgent(accountInfo, allowedAgents)) {
        return false;
      }

      if (sideMenu.isAdminOnly) {
        return !hasManagementAccount(accountInfo);
      }

      // 똑드민 전용 메뉴 & 똑드민에서 접속 상태가 아닐경우
      if (sideMenu.forManagement && !hasManagementAccount(accountInfo)) {
        return false;
      }

      // hospitalCrm 메뉴 & useDdocdoc 값이 false일 경우
      if (
        sideMenu.type === 'hospital-crm' &&
        !hasHospitalCrmService(accountInfo)
      ) {
        return false;
      }

      return (
        waitManageException ||
        hasNoSideMenuNames ||
        hasServiceTypes ||
        hasNoMenuItems
      );
    });
  } else {
    return [];
  }
});

export const getHeaderMenuItems = createSelector(
  getAccountInfoState,
  accountInfo => {
    if (accountInfo?.hospital) {
      return headerMenu.filter(menu => {
        if (menu.type === 'isCrmSmsTarget') {
          return !!accountInfo?.hospital?.isCrmSmsTarget;
        }
        if (menu.type === 'isConfirmationTarget') {
          return !!accountInfo?.hospital?.isConfirmationTarget;
        }
        return null;
      });
    }
    return null;
  }
);
