<input
  class="input-text"
  type="number"
  [max]="max"
  [min]="min"
  [name]="name"
  [placeholder]="placeholder"
  [disabled]="disabled"
  [(ngModel)]="value"
  (ngModelChange)="handleValueChange($event)"
  (keyup)="handleKeyUp($event)"
/>
