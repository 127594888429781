/* tslint:disable:no-bitwise */
import { Pipe, PipeTransform } from '@angular/core';
import { TimeCell, TimelinePreventReason as TReason } from '../../models/reservation-timeline.model';

// const CLASS_NAME_MAP = {
//   [Reason.MEALTIME]: 'tt-mealtime',
//   [Reason.DAYOFF]: 'tt-dayoff',
//   [Reason.HOLIDAY]: 'tt-holiday',
//   [Reason.TIMEOFF]: 'tt-timeoff',
// };
// const REASON_VALUES = [
//   Reason.MEALTIME,
//   Reason.DAYOFF,
//   Reason.HOLIDAY,
//   Reason.TIMEOFF,
// ];

@Pipe({
  name: 'cellColor',
  pure: false
})
export class ResCellColorPipe implements PipeTransform {
  getBgColor(cell: TimeCell) {
    if (!cell) {
      return '';
    }

    if (cell.color) {
      if (cell.text) {
        return 'tt-bg' + cell.color + ' first';
      }
      return 'tt-bg' + cell.color;
    }

    const reason = cell.reason;

    // if (!reason) {
    //   return '';
    // }

    // const classNames = [];

    // REASON_VALUES.forEach(value => {
    //   if (reason & value) {
    //     classNames.push(CLASS_NAME_MAP[value]);
    //   }
    // });

    // return classNames.join(' ');

    // if (reason & Reason.MEALTIME) {
    //   className += CLASS_NAME_MAP[Reason.MEALTIME];
    // }

    if (reason & TReason.LAUNCH) {
      if (reason & TReason.DAYOFF) {
        return 'tt-mealtime tt-lunch tt-dayoff';
      }
      return 'tt-mealtime tt-lunch';
    }

    if (reason & TReason.DINNER) {
      if (reason & TReason.DAYOFF) {
        return 'tt-mealtime tt-dinner tt-dayoff';
      }
      return 'tt-mealtime tt-dinner';
    }

    if (reason & TReason.HOLIDAY) {
      if (reason & TReason.MEALTIME) {
        return 'tt-holiday tt-mealtime';
      }
      if (reason & TReason.TIMEOFF) {
        return 'tt-holiday tt-timeoff';
      }
      return 'tt-holiday';
    }

    if (reason & TReason.TIMEOFF) {
      return 'tt-timeoff';
    }

    if (reason & TReason.DAYOFF) {
      return 'tt-dayoff';
    }

    return '';
  }
  transform(cell: TimeCell): string {
    const className = this.getBgColor(cell);

    if (cell.selected) {
      return className + ' tt-cell selected';
    }

    return className + ' tt-cell';
  }
}
