import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appCregnoAutoHyphen]',
})
export class CregnoAutoHyphenDirective {
  constructor(public ngControl: NgControl) {}

  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;

    const str = input.value.replace(/[^0-9]/g, '');
    let trimmed = str.replace(/\s+/g, '');

    if (trimmed.length > 12) {
      trimmed = trimmed.substr(0, 12);
    }

    trimmed = trimmed.replace(/-/g, '');

    const numbers = [];

    numbers.push(trimmed.substr(0, 3));
    if (trimmed.substr(3, 2) !== '') {
      numbers.push(trimmed.substr(3, 2));
    }
    if (trimmed.substr(5, 5) !== '') {
      numbers.push(trimmed.substr(5, 5));
    }

    if (input.value !== numbers.join('-')) {
      this.ngControl.control.setValue(numbers.join('-'));
    }
  }
}
