import { TabletSettingFetchModel } from '~models/tablet.model';
import { TabletManageActions } from '~actions/products/tablet-manage.actions';
import { createReducer, on } from '@ngrx/store';

export interface TabletManageState {
  loading: boolean;
  tabletSetting?: TabletSettingFetchModel;
  loaded: boolean;
  error: string | null;
}

const initialState: TabletManageState = {
  loading: false,
  error: null,
  loaded: false,
};

export const reducer = createReducer(
  initialState,
  on(TabletManageActions.TabletSettingDataLoad, (state, action) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(TabletManageActions.TabletSettingDataLoadSuccess, (state, action) => ({
    loading: false,
    loaded: true,
    error: null,
    tabletSetting: action.payload,
  })),
  on(TabletManageActions.TabletSettingDataLoadFail, (state, action) => ({
    ...state,
    loading: false,
    loaded: false,
    error: action.payload,
  })),
  on(TabletManageActions.TabletSettingDataUpdate, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(TabletManageActions.TabletSettingDataUpdateSuccess, (state, action) => ({
    ...state,
    loading: false,
  })),
  on(TabletManageActions.TabletSettingDataUpdateFail, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
);

export const getLoading = (state: TabletManageState) => state.loading;
export const getLoaded = (state: TabletManageState) => state.loaded;
export const getData = (state: TabletManageState) => state.tabletSetting;
export const getError = (state: TabletManageState) => state.error;
