<article [ngClass]="{ disabled: disabled }">
  <header *ngIf="title">
    <span>{{ title }}</span>
    <span *ngIf="itemCount > 0">({{ itemCount }})</span>
  </header>
  <ng-container [ngSwitch]="listState">
    <section *ngSwitchCase="listStateType.PENDING" class="section--center">
      <i class="fas fa-spinner"></i>
    </section>
    <section *ngSwitchCase="listStateType.LOADED">
      <app-listed-select
        [options]="options"
        [value]="value"
        (selectOptionChange)="handleChange($event)"
      >
      </app-listed-select>
    </section>
    <section *ngSwitchCase="listStateType.DISABLED" class="section--center">
      <ng-container *ngTemplateOutlet="disabledRef"></ng-container>
    </section>
    <section *ngSwitchDefault class="section--center">
      <ng-container *ngTemplateOutlet="emptyRef"></ng-container>
    </section>
  </ng-container>
  <ng-content></ng-content>
</article>
