import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AgencyModel } from '~models/auth.model';
import { SharedService } from '~core/api/shared.service';
import { sortByProperty } from '~shared/service/util';

@Component({
  selector: 'app-agency-type',
  templateUrl: './agency-type.component.html',
  styleUrls: ['./agency-type.component.scss'],
})
export class AgencyTypeComponent implements OnInit, OnChanges {
  @Input() placeHolder = '대리점 선택';

  @Input() set selectedAgency(selectedItem: AgencyModel) {
    this.selectedItem = selectedItem;
  }

  @Input() agent: string | undefined = undefined;

  @Output() agencyTypeInfo = new EventEmitter<string>();

  selectedItem: AgencyModel | null = null;

  agencyTypeList$: Observable<AgencyModel[]>;

  constructor(private sharedService: SharedService) {
    this.agencyTypeList$ = this.sharedService.fetchAgencies(this.agent).pipe(
      map(value => value.items),
      map(items => sortByProperty(items, 'agency')),
      map(this.sortByHeadquarters)
    );
  }

  ngOnInit(): void {
    this.setAgencyTypeList(this.agent);
  }

  ngOnChanges(): void {
    this.setAgencyTypeList(this.agent);
  }

  setAgencyTypeList(agent?: string): void {
    this.agencyTypeList$ = this.sharedService.fetchAgencies(agent).pipe(
      map(value => value.items),
      map(items => sortByProperty(items, 'agency')),
      map(this.sortByHeadquarters)
    );
  }

  sortByHeadquarters(items: AgencyModel[]): AgencyModel[] {
    const HEADQUARTERS = '본사';
    return items.sort((a, b) => {
      if (a.agency === HEADQUARTERS) {
        return -1;
      }
      return 0;
    });
  }

  onItemClick(item: AgencyModel): void {
    this.selectedItem = item;
    this.agencyTypeInfo.emit(item.agency);
  }

  get selectedTitle(): string {
    if (this.selectedItem && this.selectedItem.agency) {
      return this.selectedItem.agency;
    }

    return this.placeHolder;
  }
}
