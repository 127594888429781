<div class="youtubeTrigger">
  <div
    class="youtubeTrigger--wrapper"
    (mouseover)="onToggleMouseOver(true)"
    (mouseout)="onToggleMouseOver(false)"
    (click)="onToggleOpenYoutubeOverlay(true)"
  >
    <img
      src="assets/img/signin/videoThumbnail.png"
      class="youtubeTrigger__img--thumbnail"
      alt="의사가 설명하고 있는 사진"
    />
    <div class="youtubeTrigger__icon--play">
      <i [class]="iconClassName"></i>
    </div>
  </div>
</div>

<div
  *ngIf="isOpenYoutubeOverlay"
  class="youtubeTrigger__overlay"
  (click)="onToggleOpenYoutubeOverlay(false)"
>
  <div class="youtubeTrigger__overlay--body">
    <button
      class="youtubeTrigger__overlay--close"
      (click)="onToggleOpenYoutubeOverlay(false)"
    >
      <i class="icon icon-x-large"></i>
    </button>
    <iframe
      class="youtubeTrigger__overlay--iframe"
      src="https://www.youtube.com/embed/QKRtjukY73s?autoplay=1"
      allow="accelerometer; autoplay; encrypted-media; gyroscope;
    picture-in-picture"
      allowFullScreen
      [width]="youtubeOverlaySize.width"
      [height]="youtubeOverlaySize.height"
    ></iframe>
  </div>
</div>
