import { FindUserInfo } from '~models/auth.model';
import { createAction, props } from '@ngrx/store';

export enum FindPwActionTypes {
  FIND_PW = '[Auth] FindPw',
  FIND_PW_COMPLETE = '[Auth] FindPw Complete',
  FIND_PW_FAILURE = '[Auth] FindPw Failure',
}

export const FindPw = createAction(
  FindPwActionTypes.FIND_PW,
  props<{ payload: FindUserInfo }>()
);

export const FindPwComplete = createAction(
  FindPwActionTypes.FIND_PW_COMPLETE,
  props<{ payload: any }>()
);

export const FindPwFailure = createAction(
  FindPwActionTypes.FIND_PW_FAILURE,
  props<{ payload: any }>()
);

export const FindPwActions = {
  FindPw,
  FindPwComplete,
  FindPwFailure,
};
