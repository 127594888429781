import { createAction, props } from '@ngrx/store';
import {
  HospitalInfoReqModel,
  HospitalInfoUiModel,
} from '~models/hospital.model';

export enum HospitalInfoActionTypes {
  HOSPITAL_INFO_LOAD = '[HospitalInfo] Info Load',
  HOSPITAL_INFO_LOAD_SUCCESS = '[HospitalInfo] Info Load Success',
  HOSPITAL_INFO_CHANGE = '[HospitalInfo] Info Change',
  HOSPITAL_INFO_SAVE = '[HospitalInfo] Save',
  HOSPITAL_INFO_VALIDATE_SUCCESS = '[HospitalInfo] Validate Success',
  REQ_FAIL = '[HospitalInfo] Req Fail',
}

export const HospitalInfoReqFail = createAction(
  HospitalInfoActionTypes.REQ_FAIL,
  props<{ error: any }>()
);

export const HospitalInfoLoad = createAction(
  HospitalInfoActionTypes.HOSPITAL_INFO_LOAD
);

export const HospitalInfoLoadSuccess = createAction(
  HospitalInfoActionTypes.HOSPITAL_INFO_LOAD_SUCCESS,
  props<{ payload: any }>()
);

export const HospitalInfoChange = createAction(
  HospitalInfoActionTypes.HOSPITAL_INFO_CHANGE,
  props<{ payload: Partial<HospitalInfoUiModel> }>()
);

export const HospitalInfoSave = createAction(
  HospitalInfoActionTypes.HOSPITAL_INFO_SAVE,
  props<{ payload: Partial<HospitalInfoUiModel> }>()
);

export const HospitalInfoValidateSuccess = createAction(
  HospitalInfoActionTypes.HOSPITAL_INFO_VALIDATE_SUCCESS,
  props<{ payload: Partial<HospitalInfoReqModel> }>()
);

export const HospitalInfoActions = {
  HospitalInfoLoad,
  HospitalInfoLoadSuccess,
  HospitalInfoChange,
  HospitalInfoReqFail,
  HospitalInfoSave,
  HospitalInfoValidateSuccess,
};
