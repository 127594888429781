import { DoctorInfoCreateModel, DoctorInfoUiModel } from '~models/doctor.model';

export function createDoctorInfoCreateModel(): DoctorInfoCreateModel {
  return {
    active: true,
    hospital: '',
    name: '',
    gender: '',
    birthDate: '',
    briefHistory: [],
  };
}

export function createDoctorInfoUiModel(): DoctorInfoUiModel {
  return {
    _id: '',
    active: true,
    hospital: '',
    name: '',
    gender: '',
    birthDate: '',
    briefHistory: [],
  };
}
