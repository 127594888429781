import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fromEvent, of, pipe, Subscription } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { AuthService } from '~core/api/auth.service';
import { SimpleModalService } from '~shared/service/simple.modal.service';
import { EmrAccountCreateInfo } from '~models/auth.model';
import { ValidatorService } from '~shared/service/validator.service';

@Component({
  selector: 'app-account-create-forms',
  templateUrl: './account-create-forms.component.html',
  styleUrls: ['./account-create-forms.component.scss'],
})
export class AccountCreateFormsComponent implements OnInit {
  @Output() accountCreateSubmit = new EventEmitter<EmrAccountCreateInfo>();

  registerForm: FormGroup;

  validateId = '^[A-Za-z0-9]*$';
  validatePassword = '^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^*+-]).{8,16}$';
  validateEmail = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
  validatePhone = '^(01)([0-9]{7,9}|[0-9]{1,1}-[0-9]{3,4}-[0-9]{3,4})$';

  duplicatedId = false;
  duplicatedEmail = false;
  isPhoneCertificationSend = false;

  checkId$: Subscription = new Subscription();
  checkEmail$: Subscription = new Subscription();

  checkedFilter = pipe(
    map((data: any) => data.target.value),
    filter((data: string) => data.trim() !== ''),
    debounceTime(400),
    distinctUntilChanged()
  );

  @ViewChild('inputId', { static: true }) inputId: ElementRef<HTMLInputElement>;
  @ViewChild('inputEmail', { static: true }) inputEmail: ElementRef<
    HTMLInputElement
  >;

  matchingPassword(passwordKey: string, passwordConfirm: string) {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey];
      const passwordConfirmInput = group.controls[passwordConfirm];
      if (passwordInput.value !== passwordConfirmInput.value) {
        return passwordConfirmInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmInput.setErrors(null);
      }
    };
  }

  onCheckPhoneNum() {
    this.sendPhoneCertification();
  }

  onCheckCertification() {
    this.phoneCertificationCheck();
  }

  onResetCheckPhoneNum() {
    this.registerForm.get('token')?.setValue(null);
    this.registerForm.get('certNum')?.setValue(null);
    this.isPhoneCertificationSend = false;
    this.onCheckPhoneNum();
  }

  duplicateCheck() {
    this.checkId$ = fromEvent(this.inputId.nativeElement, 'keyup')
      .pipe(
        map((data: any) => data.target.value),
        // filter((data: string) => data.trim() !== '' && /^[A-Za-z0-9]*$/.test(data)),
        filter((data: string) => data.trim() !== ''),
        // debounceTime(400),
        distinctUntilChanged(),
        tap(() => (this.duplicatedId = false)),
        switchMap(data => this.authService.existId(data)),
        map((v: any) => (this.duplicatedId = v.isExist))
      )
      .subscribe((v: any) => {
        this.registerForm.get('duplicateId')?.setValue(!v);
      });

    this.checkEmail$ = fromEvent(this.inputEmail.nativeElement, 'keyup')
      .pipe(
        map((data: any) => data.target.value),
        filter((data: string) => data.trim() !== ''),
        // debounceTime(400),
        distinctUntilChanged(),
        filter((data: any) => this.validateEmail.test(data)),
        switchMap(data => this.authService.existEmail(data)),
        map((v: any) => (this.duplicatedEmail = v.isExist))
      )
      .subscribe((v: any) => {
        this.registerForm.get('duplicateEmail')?.setValue(!v);
      });
  }

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private p: SimpleModalService,
    private validatorService: ValidatorService
  ) {
    this.registerForm = this.fb.group(
      {
        userId: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(20),
            Validators.pattern(this.validateId),
          ]),
        ],
        userPassword: [
          null,
          Validators.compose([
            Validators.required,
            Validators.pattern(this.validatePassword),
          ]),
        ],
        resetPassword: [null, Validators.required],
        userName: [null, Validators.required],
        userPhone: [
          null,
          Validators.compose([
            Validators.required,
            Validators.maxLength(13),
            Validators.pattern(this.validatePhone),
          ]),
        ],
        certNum: [null, Validators.required],
        token: [null, Validators.required],
        cRegNo: [
          null,
          Validators.compose([
            Validators.required,
            this.validatorService.cRegNo(),
          ]),
        ],
        userEmail: [
          null,
          Validators.compose([
            Validators.required,
            Validators.pattern(this.validateEmail),
          ]),
        ],
        duplicateId: [false, Validators.requiredTrue],
        duplicateEmail: [false, Validators.requiredTrue],
      },
      { validator: this.matchingPassword('userPassword', 'resetPassword') }
    );
  }

  ngOnInit() {
    // this.registerForm.valueChanges.subscribe(data => {});

    // this.registerForm.statusChanges.subscribe(valid => console.log('valid : ', valid));

    this.duplicateCheck();
    window.scrollTo(0, 0);
  }

  registerAccount(event: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) {
    event.preventDefault();
    event.stopPropagation();
    if (this.registerForm.valid) {
      const value = this.registerForm.value;
      const registerReq = { ...value };
      this.accountCreateSubmit.emit(registerReq);
    }
  }

  sendPhoneCertification() {
    this.authService
      .phoneCertificationCheck(this.registerForm.get('userPhone')?.value)
      .pipe(
        map(result => result),
        catchError(err => of({ errMsg: err.error.error.message }))
      )
      .subscribe((payload: { errMsg?: string; userPhone?: string }) => {
        if (payload && payload.errMsg) {
          this.p.alert(payload.errMsg);
        } else {
          this.p.alert('인증번호를 전송했습니다.');
          this.isPhoneCertificationSend = true;
        }
      });
  }

  phoneCertificationCheck() {
    this.authService
      .phoneCertificationConfirm(
        this.registerForm.get('userPhone')?.value,
        this.registerForm.get('certNum')?.value
      )
      .pipe(
        map(result => ({ result: true, token: result.token })),
        catchError(err => of({ result: false, message: err.error.message }))
      )
      .subscribe(
        (result: { result: boolean; token?: string; message?: string }) => {
          if (result.result) {
            this.p.alert('인증에 성공하셨습니다.');
            this.registerForm.get('token')?.setValue(result.token);
          } else {
            this.p.alert('전화번호 인증에 실패하셨습니다. 다시 시도해주세요.');
          }
        }
      );
  }
}
