/* tslint:disable */
import { Injectable } from '@angular/core';
import { isUndefined as _isUndefined } from 'lodash';

import {
  ChannelTalkBootOption,
  ChannelTalkCallback,
} from '~shared/models/channeltalk.models';

@Injectable({
  providedIn: 'root',
})
export class ChannelService {
  constructor() {
    if (_isUndefined(window.ChannelIO)) {
      this.loadScript();
    }
  }

  loadScript() {
    try {
      const w = window;
      if (w.ChannelIO) {
        return (window.console.error || window.console.log || function () {})(
          'ChannelIO script included twice.'
        );
      }
      let ch = function () {
        // @ts-ignore
        ch.c(arguments);
      };
      // @ts-ignore
      ch.q = [];
      // @ts-ignore
      ch.c = function (args) {
        // @ts-ignore
        ch.q.push(args);
      };
      w.ChannelIO = ch;

      function l() {
        if (w.ChannelIOInitialized) {
          return;
        }
        w.ChannelIOInitialized = true;
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
        s.charset = 'UTF-8';
        const x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      }

      if (document.readyState === 'complete') {
        l();
      } else if (window.attachEvent) {
        window.attachEvent('onload', l);
      } else {
        window.addEventListener('DOMContentLoaded', l, false);
        window.addEventListener('load', l, false);
      }
    } catch (e) {
      throw new Error('채널톡 초기화 실패');
    }
  }

  boot(settings: ChannelTalkBootOption, callBack: ChannelTalkCallback) {
    window.ChannelIO('boot', settings, callBack);
  }

  shutdown() {
    window.ChannelIO('shutdown');
  }
}
