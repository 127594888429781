/* tslint:disable:no-bitwise */
import { OfficeWeeklyDetailData } from '~models/care-room.model';
import { HashMap } from '~models/common.model';
import {
  CellInfo,
  HourItem,
  HourRange,
  SectionCellInfo,
  SymptomUnit,
  TimeCell,
  TimelineInfo,
  TimelinePreventReason,
  TimeRow,
} from '~models/reservation-timeline.model';
import { makeNumberArray } from '~shared/service/util';
import { ResTimelineScheduleState } from '~reducers/products/res-timeline-schedule.reducer';
import {
  clearCell,
  clearTimeRows,
  convertTimeCellPreventInfoMap,
  getCell,
  getRows,
  isCellPositionInside,
  isCellPositionOutside,
} from './res-timeline-schedule.cell';
import {
  DEFAULT_TIME_UNIT,
  getRowRange,
  getWeekArray,
  hasPreventCellWithStartEnd,
  hourRangeToTimeRange,
  WEEK_NAME_LIST,
} from './res-timeline-schedule.util';

function clearOverlapCells(
  cells: TimeCell[],
  start: number,
  end: number,
  count: number,
  color: string
) {
  const aOvCells = cells
    .filter(
      (cell, index) =>
        start >= 0 && cell.start >= 0 && index >= start && index <= end
    )
    .map(cell => Object.assign({}, cell));

  let equals = false;
  let equalsEnd = false;

  if (aOvCells.length === 0) {
    return {
      cells,
      equals,
    };
  }

  const firstOvCell = aOvCells[0];
  equalsEnd =
    aOvCells.length > 0 &&
    firstOvCell.start === start &&
    firstOvCell.color === color;

  equals = equalsEnd && firstOvCell.end === end;

  equalsEnd = equalsEnd && cells.length <= end;

  if (equals) {
    // 같은 것이면 그 것만 지운다.
    if (firstOvCell.start >= 0 && firstOvCell.end >= 0) {
      const aRmPos = makeNumberArray(firstOvCell.start, firstOvCell.end);
      aRmPos.forEach(idx => (cells[idx] = clearCell(cells[idx])));
    }
  } else if (equalsEnd) {
    if (firstOvCell.start >= 0) {
      // 끝쪽만 같다고 판단 된다면 별도 프로세스
      const aRmPos = makeNumberArray(firstOvCell.start, cells.length - 1);
      aRmPos.forEach(idx => (cells[idx] = clearCell(cells[idx])));
    }
    equals = true;
  } else {
    // 같지 않다면 겹치는 모든 셀을 다 지운다.
    aOvCells.forEach(cell => {
      if (cell.start >= 0 && cell.end >= 0) {
        const aRmPos = makeNumberArray(cell.start, cell.end);
        aRmPos.forEach(idx => (cells[idx] = clearCell(cells[idx])));
      }
    });
  }
  return {
    cells,
    equals,
  };
}

function validStateForTimelineInfo(
  state: ResTimelineScheduleState,
  cellInfo: CellInfo
) {
  const { timelineInfoList, week } = state;
  const weekIndex = week - 1;
  const iLen = timelineInfoList.length;

  if (
    !timelineInfoList ||
    iLen < 1 ||
    weekIndex < 0 ||
    weekIndex >= iLen ||
    !cellInfo
  ) {
    return false;
  }

  return true;
}

export function toLinearCells(timeRows: TimeRow[]): TimeCell[] {
  return timeRows.reduce(
    (prevVal, currVal) => [...prevVal, ...currVal.cells],
    [] as TimeCell[]
  );
}

function toLinearIndex(cellInfo: CellInfo, cellQty: number = 12): number {
  return cellInfo.row * cellQty + cellInfo.col;
}

/**
 * 타임라인 테이블에서 사용자가 드래그로 여러 셀을 선택 했을 때 사용한다.
 * 선택된 여러셀을 현재 선택된 진료항목(SymptomUnit)과 연계하여 입력 및 변경 한다.
 * @param timelineInfo 주간별 타임라인 정보
 * @param cellInfoMap
 * @param symptomUnit 선택된 진료항목
 */
export function modifyWeeklyTimelineInfoListByCellInfoList(
  timelineInfo: TimelineInfo[],
  cellInfoMap: HashMap<CellInfo[]>,
  symptomUnit: SymptomUnit
) {
  const TIME_UNIT = DEFAULT_TIME_UNIT;
  const mTimeRows: HashMap<TimeRow[]> = {};
  const mLinearCells: HashMap<TimeCell[]> = {};
  const { count, colorNo, timeUnit: suTimeUnit } = symptomUnit;
  const cellCount = Math.floor(suTimeUnit / TIME_UNIT);

  timelineInfo.forEach(timeline => {
    mTimeRows[timeline.day] = timeline.timeRows;
    mLinearCells[timeline.day] = toLinearCells(timeline.timeRows);
  });

  Object.keys(cellInfoMap).forEach(key => {
    const linearCells = mLinearCells[key];
    const cellInfoList = cellInfoMap[key];

    cellInfoList.forEach(cellInfo => {
      const startIndex = toLinearIndex(cellInfo);
      let endIndex = startIndex + cellCount - 1;
      const linearLength = linearCells.length;

      let cell: TimeCell;

      if (cellInfo.count > 0) {
        cell = linearCells[startIndex];
        if (cell.start !== cell.end && cell.start && cell.start >= 0) {
          // if (endIndex >= linearLength) {
          //   endIndex = linearLength - 1;
          // }
          const min = cell.start;
          let max = cell.end;

          if (max) {
            if (max >= linearLength) {
              max = linearLength - 1;
            }
            for (let i = min; i <= max; i++) {
              cell = linearCells[i];
              cell.text = '';
              cell.count = 0;
              cell.color = '';
              cell.start = i;
              cell.end = i;
            }
          }
        } else {
          cell.text = '';
          cell.count = 0;
          cell.color = '';
          cell.start = startIndex;
          cell.end = startIndex;
        }
        return;
      }

      if (endIndex >= linearLength) {
        endIndex = linearLength - 1;
      }

      for (let i = startIndex; i <= endIndex; i++) {
        cell = linearCells[i];
        if (i === startIndex) {
          cell.text = count + '';
        }
        cell.count = count;
        cell.color = colorNo;
        cell.start = startIndex;
        cell.end = endIndex;
      }
    });
  });

  return timelineInfo;
}

/**
 * State 를 모두 이용 하여 특정 하나의 셀에 대하여 타임라인 내용을 수정한다.
 * @param state 수정에 필요한 참조 데이터들
 * @param cellInfo 실제 수정 할 위치 정보가 담긴 셀
 * @param useCountFromCellInfo 셀이 채워지는 길이를 셀 정보를 이용할지의 여부. true 면 셀 정보를 이용, false 면 증상선택 정보를 이용한다. (기본 false)
 */
export function modifyTimelineInfoListByState(
  state: ResTimelineScheduleState,
  cellInfo: CellInfo,
  useCountFromCellInfo = false
): TimelineInfo[][] {
  const {
    timelineInfoList,
    week,
    selectedSymptomUnit: symptomUnit,
    timeUnit,
  } = state;
  const weekIndex = week - 1;

  if (!validStateForTimelineInfo(state, cellInfo)) {
    return timelineInfoList;
  }

  try {
    const items = timelineInfoList[weekIndex];
    const { day } = cellInfo;
    const count = useCountFromCellInfo ? cellInfo.count : symptomUnit.count;
    const { colorNo, timeUnit: suTimeUnit } = symptomUnit;
    const cellCount = Math.floor(suTimeUnit / timeUnit);

    const timeRows = items.filter(item => item.day === day)[0].timeRows;
    const linearCells = toLinearCells(timeRows);
    const startIndex = toLinearIndex(cellInfo);
    let endIndex = startIndex + cellCount - 1;
    const linearLength = linearCells.length;

    const mClearInfo = clearOverlapCells(
      linearCells,
      startIndex,
      endIndex,
      count,
      colorNo
    );
    let cell: TimeCell;

    if (mClearInfo.equals) {
      return timelineInfoList;
    }

    if (endIndex >= linearLength) {
      endIndex = linearLength - 1;
    }

    for (let i = startIndex; i <= endIndex; i++) {
      cell = linearCells[i];
      if (i === startIndex) {
        cell.text = count + '';
      }
      cell.count = count;
      cell.color = colorNo;
      cell.start = startIndex;
      cell.end = endIndex;
    }
  } catch (error) {
    console.log(error);
    console.log(cellInfo);
  }

  return timelineInfoList;
}

export function changeTimelineInfo(
  state: ResTimelineScheduleState,
  cellInfo: CellInfo
): TimelineInfo[][] {
  const {
    timelineInfoList,
    week,
    // selectedSymptomUnit: symptomUnit,
    // timeUnit,
  } = state;
  const weekIndex = week - 1;
  const { day, row, col, count } = cellInfo;

  if (!validStateForTimelineInfo(state, cellInfo)) {
    return timelineInfoList;
  }

  // const { colorNo, timeUnit: suTimeUnit } = symptomUnit;
  // const cellCount = Math.floor(suTimeUnit / timeUnit);

  const items = timelineInfoList[weekIndex];
  const timeRows = items.filter(item => item.day === day)[0].timeRows;
  const linearCells = toLinearCells(timeRows);
  const startIndex = toLinearIndex(cellInfo);
  // const endIndex = startIndex + cellCount - 1;

  // const timeRow = timeRows[row];
  const cell = linearCells[startIndex];
  const { start, end } = cell;
  if (start && end) {
    if (start < 0) {
      return modifyTimelineInfoListByState(state, cellInfo, true);
    }

    for (let i = start; i <= end; i++) {
      if (i === start) {
        linearCells[i].text = count + '';
      }
      linearCells[i].count = count;
    }
  }
  return timelineInfoList;
}

export function removeTimelineInfoBySymptomUnit(
  // state: ResTimelineScheduleState,
  timelineInfoList: TimelineInfo[][],
  unit: SymptomUnit
) {
  // const timelineInfoList = state.timelineInfoList;
  const unitColor = unit.colorNo;

  // timelineInfoList.forEach(items => {
  //   items.forEach(item => {
  //     item.timeRows.forEach(rows => {
  //       rows.cells.forEach((cell, idx) => {
  //         try {
  //           if (cell.color === unitColor) {
  //             rows.cells[idx] = clearCell(cell);
  //           }
  //         } catch (error) {}
  //       });
  //     });
  //   });
  // });

  // return timelineInfoList;

  return timelineInfoList.map(items =>
    items.map(item => {
      const returnTimeRows = item.timeRows.map(row => {
        const returnCells = row.cells.map(cell => {
          if (cell.color === unitColor) {
            return getCell();
          }
          return cell;
        });

        return {
          ...row,
          cells: [...returnCells],
        };
      });

      return {
        ...item,
        timeRows: returnTimeRows,
      };
    })
  );
}

export function removeTimelineInfo(
  state: ResTimelineScheduleState,
  cellInfo: CellInfo
): TimelineInfo[][] {
  const { timelineInfoList, week, timeUnit } = state;
  const weekIndex = week - 1;

  if (!validStateForTimelineInfo(state, cellInfo)) {
    return timelineInfoList;
  }

  try {
    const items = timelineInfoList[weekIndex];
    const { day, col, row, count } = cellInfo;
    const timeRows = items.filter(item => item.day === day)[0].timeRows;
    const linearCells = toLinearCells(timeRows);
    // const timeRow = items.filter(item => item.day === day)[0].timeRows[row];
    // const cells = timeRow.cells;
    const cellCount = timeRows[0].cells.length;
    // const maxPos = cellCount + col;
    const start = row * cellCount + col;
    const end = start;

    // const limitCol = cells.length - 1;

    // if (end > limitCol) {
    //   end = limitCol;
    //   start = limitCol - cellCount + 1;
    //   col = start;
    // }

    clearOverlapCells(linearCells, start, end, count, '');
  } catch (error) {
    // console.log(error);
    // console.log(cellInfo);
  }

  return timelineInfoList;
}

function toGroupInfo(
  start: number,
  end: number,
  wholeEnd: number,
  cellCountAtSection: number
): SectionCellInfo[][] {
  const aRet: SectionCellInfo[][] = [];
  let iEnd = end;

  if (iEnd > wholeEnd) {
    iEnd = wholeEnd;
  }
  // console.log(start, end, wholeEnd, cellCountAtSection);

  for (let i = start; i <= iEnd; i += cellCountAtSection) {
    if (i < 0) {
      continue;
    }
    aRet.push(toSectionInfo(i, iEnd, cellCountAtSection));
  }

  const lastSection = aRet[aRet.length - 1];

  if (!lastSection) {
    return aRet;
  }

  if (lastSection.length < cellCountAtSection) {
    aRet.pop();
  }

  return aRet;
}

function toSectionInfo(
  start: number,
  end: number,
  cellCountAtSection: number
): SectionCellInfo[] {
  const mInfo: SectionCellInfo = {
    index: 0,
    start,
    end: start + cellCountAtSection - 1,
  };
  const aRet: SectionCellInfo[] = [];
  // const max = cellCountAtSection - (end % cellCountAtSection);

  for (let i = 0; i < cellCountAtSection; i++) {
    if (i > end) {
      break;
    }
    aRet.push({
      ...mInfo,
      index: start + i,
    });
  }

  return aRet;
}

function hasPreventCell(section: SectionCellInfo[], linearCells: TimeCell[]) {
  const len = section.length;
  const infoFirst = section[0];
  const infoLast = section[len - 1];

  return hasPreventCellWithStartEnd(infoFirst.start, infoLast.end, linearCells);
}

function applySectionsToLinearCells(
  groupInfo: SectionCellInfo[][],
  count: number,
  color: string,
  linearCells: TimeCell[],
  withClear: boolean = false
): TimeCell[] {
  groupInfo.forEach(section => {
    const iLen = section.length;
    let info: SectionCellInfo;
    let cell: TimeCell;

    if (hasPreventCell(section, linearCells)) {
      return;
    }

    // 때때로 전체 스케줄 범위를 벗어난 섹션이 넘어오는 경우가 있어 이에 대한 예외사항 추가
    if (section[iLen - 1].end >= linearCells.length) {
      return;
    }

    // console.log(section[0]);

    if (withClear) {
      clearOverlapCells(
        linearCells,
        section[0].start,
        section[0].end,
        count,
        color
      );
    }

    for (let idx = 0; idx < iLen; idx++) {
      info = section[idx];
      cell = linearCells[info.index];

      if (!info || !cell) {
        continue;
      }

      if (idx === 0) {
        cell.text = count + '';
      }

      // if (cell.text && idx > 0) {
      //   console.log(cell);
      // }

      cell.start = info.start;
      cell.end = info.end;
      cell.color = color;
      cell.count = count;
    }
  });

  return linearCells;
}

export function applySymptomUnitToTimelineByHourRange(
  state: ResTimelineScheduleState,
  unit: SymptomUnit
): TimelineInfo[][] {
  const {
    timelineInfoList: table,
    hourRange,
    week,
    hourItems,
    timeUnit,
    timeUnitHeader,
  } = state;
  const weekIndex = week - 1;
  const currTable = table[weekIndex];
  const aWeek = getWeekArray();
  const weekdays = hourRange.weekdays;
  const count = unit ? unit.count : 1;
  const color = unit ? unit.colorNo : '';
  const iSymptomTimeUnit = unit ? unit.timeUnit : timeUnit;

  const {
    startHour: iStartHour,
    startMin: iStartMin,
    endHour: iEndHour,
    endMin: iEndMin,
  } = hourRangeToTimeRange(hourRange);
  const columnCount = timeUnitHeader.length;

  const [iRowIdxStart, iRowIdxEnd] = getRowRange(
    hourItems,
    iStartHour,
    iEndHour
  );
  const iColStart = timeUnitHeader.indexOf(iStartMin);
  // const iColEnd = iColStart + Math.floor(iSymptomTimeUnit / timeUnit) - 1;
  const iColEnd = timeUnitHeader.indexOf(iEndMin);
  const iCellCountAtSection = Math.floor(iSymptomTimeUnit / timeUnit);

  // console.log('colStart', iColStart);

  const indexLinearStart = iRowIdxStart * columnCount + iColStart;
  const indexLinearMax = iRowIdxEnd * columnCount + iColEnd;
  const sectionCount = Math.floor(
    (indexLinearMax - indexLinearStart) / iCellCountAtSection
  );
  const indexLinearEnd =
    indexLinearStart + iCellCountAtSection * sectionCount - 1;

  // console.log(iStartHour, iStartMin, iEndHour, iEndMin, iRowIdxStart, iRowIdxEnd);
  // tslint:disable-next-line:max-line-length
  // console.log(indexLinearStart, indexLinearEnd, indexLinearMax, 'iCellCountAtSection', iCellCountAtSection, 'sectionCount', sectionCount);

  currTable.forEach(item => {
    const weekIdx = aWeek.indexOf(item.weekday);

    if ((weekdays && !weekdays[weekIdx]) || item.day < 1 || item.holiday) {
      return;
    }

    const linearCells = toLinearCells(item.timeRows);

    // clearOverlapCells(linearCells, indexLinearStart, indexLinearEnd, count, color);

    const aaGroupInfo = toGroupInfo(
      indexLinearStart,
      indexLinearEnd,
      indexLinearMax,
      iCellCountAtSection
    );

    try {
      applySectionsToLinearCells(aaGroupInfo, count, color, linearCells, true);
    } catch (error) {
      console.log(error);
      console.log(aaGroupInfo);
    }
  });
  return table;
}

export function initTimeRowsForPreview() {
  return getRows(3, 12);
}

export function applySymptomUnitToTimeRowsForPreview(
  timeUnitHeader: number[],
  timeUnit: number,
  hourRange: HourRange,
  unit: SymptomUnit
): TimeRow[] {
  const timeRows = initTimeRowsForPreview();
  // const aWeek = getWeekArray();
  // const weekdays = hourRange.weekdays;
  const count = unit ? unit.count : 1;
  const color = unit ? unit.colorNo : '';
  const iSymptomTimeUnit = unit ? unit.timeUnit : timeUnit;
  const {
    startHour,
    startMin: iStartMin,
    endHour,
    endMin: iEndMin,
  } = hourRangeToTimeRange(hourRange);

  let hourDiff = endHour - startHour;

  if (hourDiff > 3) {
    hourDiff = 3;
  }

  let iRowIdxStart = 0;
  let iRowIdxEnd = hourDiff;
  const iColStart = timeUnitHeader.indexOf(iStartMin);
  // const iColEnd = iColStart + Math.floor(iSymptomTimeUnit / timeUnit) - 1;
  const iColEnd = timeUnitHeader.indexOf(iEndMin);
  const iColCount = timeUnitHeader.length;

  // console.log(startHour, endHour);

  const iUsableMaxCellCount = iColCount * (hourDiff + 1);

  if (iRowIdxStart < 0) {
    iRowIdxStart = 0;
  }
  if (iRowIdxEnd < 0) {
    iRowIdxEnd = 2;
  }

  const iWholeStart = 0;
  const iWholeEnd = iUsableMaxCellCount;
  // const iWholeStart = (iRowIdxStart * iColCount) + iColStart;
  // let iWholeEnd = (iRowIdxEnd * iColCount) + iColEnd;
  const iCellCountAtSection = Math.floor(iSymptomTimeUnit / timeUnit);
  // const iSectionCountAtGroup = Math.floor(iColCount / iCellCountAtSection);
  // let iActSecCnt = 0;

  // if ((iColEnd <= iColStart)) {
  //   iColEnd += iColCount;
  // }

  // console.log('iWholeEnd', iWholeEnd);
  // console.log('iUsableMaxCellCount', iUsableMaxCellCount);

  // if (iUsableMaxCellCount < iWholeEnd) {
  //   iWholeEnd = iUsableMaxCellCount - 1;
  // }
  const columnCount = timeUnitHeader.length;

  const indexLinearStart = iRowIdxStart * columnCount + iColStart;
  const indexLinearMax = iRowIdxEnd * columnCount + iColEnd;
  const sectionCount = Math.floor(
    (indexLinearMax - indexLinearStart) / iCellCountAtSection
  );
  const indexLinearEnd =
    indexLinearStart + iCellCountAtSection * sectionCount - 1;

  // iActSecCnt = Math.floor((iColEnd - iColStart) / iCellCountAtSection);

  const linearCells = toLinearCells(timeRows);
  // let iGroupIdxStart = 0;
  // let iGroupIdxEnd = 0;
  let aaGroupInfo: SectionCellInfo[][];

  // console.log(indexLinearStart, indexLinearEnd, indexLinearMax, iCellCountAtSection);

  for (
    let iWholeIdx = iWholeStart;
    iWholeIdx < iWholeEnd;
    iWholeIdx += iColCount
  ) {
    // 그룹의 시작 위치f
    // iGroupIdxStart = iWholeIdx;
    // 그룹의 끝 위치
    // iGroupIdxEnd = iWholeIdx + (iActSecCnt * iCellCountAtSection) - 1;
    // 섹션을 채우기 전에 겹치는 부분은 미리 삭제
    // clearOverlapCells(linearCells, iGroupIdxStart, iGroupIdxEnd, count, color);
    // aaGroupInfo = toGroupInfo(iGroupIdxStart, iGroupIdxEnd, iWholeEnd, iCellCountAtSection);
    aaGroupInfo = toGroupInfo(
      indexLinearStart,
      indexLinearEnd,
      indexLinearMax,
      iCellCountAtSection
    );

    try {
      if (unit) {
        applySectionsToLinearCells(aaGroupInfo, count, color, linearCells);
      }
    } catch (error) {
      console.log(error);
      console.log(aaGroupInfo);
    }
  }

  return timeRows;
}

export function clearTimelineInfo(table: TimelineInfo[][]): TimelineInfo[][] {
  if (!table || table.length === 0) {
    return table;
  }

  table.forEach(timelineInfo => {
    if (!timelineInfo || timelineInfo.length === 0) {
      return;
    }

    timelineInfo.forEach(info => {
      clearTimeRows(info.timeRows);
    });
  });

  return table;
}

export function applyPreventInfoToTable(
  tableInfoList: TimelineInfo[][],
  hourItems: HourItem[],
  mOpenDataMap: { [key: string]: OfficeWeeklyDetailData },
  timeUnit: number
): TimelineInfo[][] {
  const mTimeCellPreventInfo = convertTimeCellPreventInfoMap(
    hourItems,
    mOpenDataMap,
    timeUnit
  );

  tableInfoList.forEach((tableInfo, ii) =>
    tableInfo.forEach((group, iii) => {
      const weekday = group.weekday;
      const weekName = WEEK_NAME_LIST[weekday];
      let info = mTimeCellPreventInfo[weekName];
      const infoHoliday = mTimeCellPreventInfo.holiday;
      const isHolidayOpen = infoHoliday && !infoHoliday.isDayOff;
      let code = 0;

      if (group.holiday) {
        info = infoHoliday;

        if (!isHolidayOpen) {
          // 휴일에 열 경우 이 내용을 우선적으로 적용 한다.
          code = TimelinePreventReason.HOLIDAY;
        }
      }

      try {
        if (!info) {
          console.log(
            'applyPreventInfoToTable',
            ii,
            iii,
            tableInfo,
            weekday,
            weekName,
            mTimeCellPreventInfo
          );
        }
      } catch (error) {}

      if (info.isDayOff) {
        code = code || TimelinePreventReason.DAYOFF;
        // code |= TimelinePreventReason.DAYOFF;
      }

      const linearCells = toLinearCells(group.timeRows);

      linearCells.forEach((cell, index) => {
        let cellCode = code;

        if (isCellPositionOutside(info, index)) {
          cellCode = cellCode || TimelinePreventReason.TIMEOFF;
          // if (index > (15 * 12) && ii === 0 && iii === 1) {
          //   console.log(info, index);
          // }
        }
        if (
          // (weekday === 6 && info.)
          info.hasLunchBreak &&
          info.lunch &&
          isCellPositionInside(info.lunch, index)
        ) {
          // if (ii === 0 && iii === 6) {
          //   console.log(weekName, info);
          // }
          cellCode = cellCode || TimelinePreventReason.LAUNCH;
        }
        if (
          info.hasDinnerBreak &&
          info.dinner &&
          isCellPositionInside(info.dinner, index)
        ) {
          cellCode = cellCode || TimelinePreventReason.DINNER;
        }

        // if (group.weekday === 1 &&  ii === 0) {
        //   console.log(info, cellCode);
        // }

        cell.reason = cellCode;
      });
    })
  );

  return tableInfoList;
}
