import { CareroomSymptomActions } from '~actions/products/careroom-symptom.actions';
import { SymptomCareRoomSelectResult } from '~models/select-symptom.model';
import { errorMessage } from '~shared/service/util';
import { createReducer, on } from '@ngrx/store';

export interface CareroomSymptomState {
  loading: boolean;
  loaded: boolean;
  selected: boolean;
  error: string | null;
  selectCareroom?: SymptomCareRoomSelectResult;
  careRoomsSymptom: SymptomCareRoomSelectResult[];
  CareRoomsSymptomWholeList?: SymptomCareRoomSelectResult[];
}

const initialState: CareroomSymptomState = {
  loaded: false,
  loading: false,
  error: null,
  selected: false,
  careRoomsSymptom: [],
};

export const reducer = createReducer(
  initialState,
  on(CareroomSymptomActions.CareRoomSymptomLoad, (state, action) => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
  })),
  on(CareroomSymptomActions.CareRoomSymptomLoadSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
    selected: true,
    selectCareroom: action.payload,
  })),
  on(CareroomSymptomActions.CareRoomSymptomLoadFail, (state, action) => ({
    ...state,
    loading: false,
    selected: false,
    error: errorMessage(action.payload, 'symptom.red53'),
  })),
  on(CareroomSymptomActions.CareRoomsSymptomListLoad, (state, action) => ({
    ...state,
    loading: true,
    loaded: false,
    error: null,
  })),
  on(
    CareroomSymptomActions.CareRoomsSymptomListLoadSuccess,
    (state, action) => ({
      ...state,
      loading: false,
      loaded: true,
      error: null,
      careRoomsSymptom: action.payload,
    })
  ),
  on(CareroomSymptomActions.CareRoomsSymptomListLoadFail, (state, action) => ({
    ...state,
    loading: false,
    loaded: false,
    error: action.payload,
  })),
  on(CareroomSymptomActions.CareRoomSymptomSave, (state, action) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(CareroomSymptomActions.CareRoomSymptomSaveSuccess, (state, action) => ({
    ...state,
    loading: false,
  })),
  on(CareroomSymptomActions.CareRoomSymptomSaveFail, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  })),
  on(CareroomSymptomActions.CareRoomsSymptomWholeListLoad, (state, action) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(
    CareroomSymptomActions.CareRoomsSymptomWholeListLoadSuccess,
    (state, action) => ({
      ...state,
      loading: false,
      CareRoomsSymptomWholeList: action.payload,
    })
  ),
  on(
    CareroomSymptomActions.CareRoomsSymptomWholeListLoadFail,
    (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    })
  )
);

export const getLoaded = (state: CareroomSymptomState) => state.loaded;
export const getLoading = (state: CareroomSymptomState) => state.loading;
export const getError = (state: CareroomSymptomState) => state.error;
export const getSelected = (state: CareroomSymptomState) => state.selected;

export const getSelectedCareRoomSymptom = (state: CareroomSymptomState) =>
  state.selectCareroom;
export const getCareRoomsSymptomList = (state: CareroomSymptomState) =>
  state.careRoomsSymptom;
export const getCareRoomsSymptomWholeList = (state: CareroomSymptomState) =>
  state.CareRoomsSymptomWholeList;
