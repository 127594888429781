import { createReducer, on } from '@ngrx/store';
import { HealthScreeningActions } from '~actions/products/health-screening.actions';
import { HealthScreeningItemsModel } from '~models/health-screening.model';
import { ImageUrlInfo } from '~models/common.model';
import { HealthScreeningQuestionnaireActions } from '~actions/products/health-screening-questionnaire.action';

export interface HealthScreeningState {
  settingId: string;
  useHealthScreening: boolean;
  /**
   * 검진 항목 설정
   */
  examinationItemList: HealthScreeningItemsModel[];
  examinationCategories: string[];
  /**
   * 문진표 병원 설정
   */
  questionnaireSendPeriod: number;
  lifestyleItemList: HealthScreeningItemsModel[];
  lifestyleCategories: string[];
  /**
   * 검진 예약 설정
   */
  reservationPeriod: number;
  reservationStartsInDays: number;
  reservationPersonPerDay: number;
  reservationAvailableDays: string[];
  reservationDisabledDates: string[];
  /**
   * 검진 결과지 설정 (병원 정보)
   */
  hospitalTitle: string;
  hospitalLogo?: ImageUrlInfo | {};
  /**
   * 추가 문진 사용 여부
   */
  useAdditionalQuestionnaire: boolean;
}

const initialState: HealthScreeningState = {
  settingId: '',
  useHealthScreening: false,
  examinationItemList: [],
  examinationCategories: [],
  questionnaireSendPeriod: 1,
  lifestyleItemList: [],
  lifestyleCategories: [],
  reservationPeriod: 1,
  reservationStartsInDays: 1,
  reservationPersonPerDay: 1,
  reservationAvailableDays: [],
  reservationDisabledDates: [],
  hospitalTitle: '',
  hospitalLogo: {},
  useAdditionalQuestionnaire: false,
};

export const healthScreeningReducer = createReducer(
  initialState,
  on(
    HealthScreeningActions.HealthScreeningUseChangeSuccess,
    (state, action) => {
      const { useHealthScreening } = action.payload;
      return {
        ...state,
        useHealthScreening,
      };
    }
  ),
  on(HealthScreeningActions.PostHealthScreeningInfoSuccess, state => {
    return { ...state, useHealthScreening: true };
  }),
  on(
    HealthScreeningActions.FetchHealthScreeningInfoSuccess,
    (state, { healthScreeningInfo }) => {
      const {
        active,
        _id,
        questionnaireAutoSendDate,
        examinationCategories,
        lifeStyleCategories,
        reservationPeriod,
        reservationStartsInDays,
        reservationAvailableDays,
        numberOfReservationPerDay,
        unavailableDays,
        title,
        image,
        useAdditionalQuestionnaire,
      } = healthScreeningInfo;
      return {
        ...state,
        settingId: _id,
        useHealthScreening: active,
        examinationCategories,
        lifestyleCategories: lifeStyleCategories,
        questionnaireSendPeriod: questionnaireAutoSendDate,
        reservationPeriod,
        reservationStartsInDays,
        reservationPersonPerDay: numberOfReservationPerDay,
        reservationAvailableDays,
        reservationDisabledDates: unavailableDays,
        hospitalTitle: title,
        hospitalLogo: image ?? {},
        useAdditionalQuestionnaire,
      };
    }
  ),
  on(
    HealthScreeningActions.FetchExaminationItemsSuccess,
    (state, { examinationItems }) => {
      return {
        ...state,
        examinationItemList: examinationItems,
      };
    }
  ),
  on(
    HealthScreeningActions.FetchLifeStyleItemsSuccess,
    (state, { lifestyleItems }) => {
      return {
        ...state,
        lifestyleItemList: lifestyleItems,
      };
    }
  ),
  on(
    HealthScreeningActions.HealthScreeningUploadHospitalLogoSuccess,
    (state, { logo }) => {
      return {
        ...state,
        hospitalLogo: logo,
      };
    }
  ),
  on(HealthScreeningActions.HealthScreeningDeleteHospitalLogoSuccess, state => {
    return {
      ...state,
      hospitalLogo: {},
    };
  }),
  on(
    HealthScreeningQuestionnaireActions.UpdateUseSuccess,
    (state, { useAdditionalQuestionnaire }) => {
      return {
        ...state,
        useAdditionalQuestionnaire,
      };
    }
  )
);
