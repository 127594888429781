import { errorMessage } from '~shared/service/util';
import { createReducer, on } from '@ngrx/store';
import { FindIdActions } from '~actions/auth/find-id.action';

export interface FindPwState {
  resetToken: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: FindPwState = {
  resetToken: null,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(FindIdActions.FindId, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(FindIdActions.FindIdComplete, (state, action) => ({
    resetToken: action.payload,
    loading: false,
    error: null,
  })),
  on(FindIdActions.FindIdFailure, (state, action) => ({
    resetToken: null,
    loading: false,
    error: errorMessage(action.payload, 'findpw.red37'),
  }))
);

export const getError = (state: FindPwState) => state.error;
export const getResetToken = (state: FindPwState) => state.resetToken;
