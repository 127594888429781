import { createAction, props } from '@ngrx/store';
import {
  SymptomCareRoomSaveBody,
  SymptomCareRoomSaveParameter,
  SymptomCareRoomSelectListQuery,
  SymptomCareRoomSelectParameter,
  SymptomCareRoomSelectResult,
} from '~models/select-symptom.model';

export enum CareroomSymptomActionTypes {
  CAREROOM_SYMPTOM_LOAD = '[Symptom] Careroom Symptom Load',
  CAREROOM_SYMPTOM_LOAD_SUCCESS = '[Symptom] Careroom Symptom Load Success',
  CAREROOM_SYMPTOM_LOAD_FAIL = '[Symptom] Careroom Symptom Load fail',

  CAREROOMS_SYMPTOM_LIST_LOAD = '[Symptom] Carerooms Symptom List Load',
  CAREROOMS_SYMPTOM_LIST_LOAD_SUCCESS = '[Symptom] Carerooms Symptom List Load Success',
  CAREROOMS_SYMPTOM_LIST_LOAD_FAIL = '[Symptom] Carerooms Symptom List Load fail',

  CAREROOM_SYMPTOM_SAVE = '[Symptom] Careroom Symptom Save',
  CAREROOM_SYMPTOM_SAVE_SUCCESS = '[Symptom] Careroom Symptom Save Success',
  CAREROOM_SYMPTOM_SAVE_FAIL = '[Symptom] Careroom Symptom Save fail',

  CAREROOM_SYMPTOM_WHOLE_LIST_LOAD = '[Symptom] Carerooms Symptom Whole List Load',
  CAREROOM_SYMPTOM_WHOLE_LIST_LOAD_SUCCESS = '[Symptom] Carerooms Symptom Whole List Load Success',
  CAREROOM_SYMPTOM_WHOLE_LIST_LOAD_FAIL = '[Symptom] Carerooms Symptom Whole List Load Fail',
}

// 진료실 별 증상 선택 조회
export const CareRoomSymptomLoad = createAction(
  CareroomSymptomActionTypes.CAREROOM_SYMPTOM_LOAD,
  props<{ parameter: SymptomCareRoomSelectParameter }>()
);

export const CareRoomSymptomLoadSuccess = createAction(
  CareroomSymptomActionTypes.CAREROOM_SYMPTOM_LOAD_SUCCESS,
  props<{ payload: SymptomCareRoomSelectResult }>()
);

export const CareRoomSymptomLoadFail = createAction(
  CareroomSymptomActionTypes.CAREROOM_SYMPTOM_LOAD_FAIL,
  props<{ payload: any }>()
);

// 진료실 증상 선택 목록
export const CareRoomsSymptomListLoad = createAction(
  CareroomSymptomActionTypes.CAREROOMS_SYMPTOM_LIST_LOAD,
  props<{ payload: { query: SymptomCareRoomSelectListQuery } }>()
);

export const CareRoomsSymptomListLoadSuccess = createAction(
  CareroomSymptomActionTypes.CAREROOMS_SYMPTOM_LIST_LOAD_SUCCESS,
  props<{ payload: SymptomCareRoomSelectResult[] }>()
);

export const CareRoomsSymptomListLoadFail = createAction(
  CareroomSymptomActionTypes.CAREROOMS_SYMPTOM_LIST_LOAD_FAIL,
  props<{ payload: any }>()
);

// 진료실 증상선택 저장

export const CareRoomSymptomSave = createAction(
  CareroomSymptomActionTypes.CAREROOM_SYMPTOM_SAVE,
  props<{
    payload: {
      key: SymptomCareRoomSaveParameter;
      body: SymptomCareRoomSaveBody;
      redirectRouteUrl?: string;
    };
  }>()
);

export const CareRoomSymptomSaveSuccess = createAction(
  CareroomSymptomActionTypes.CAREROOM_SYMPTOM_SAVE_SUCCESS,
  props<{ redirectRouteUrl?: string }>()
);

export const CareRoomSymptomSaveFail = createAction(
  CareroomSymptomActionTypes.CAREROOM_SYMPTOM_SAVE_FAIL,
  props<{ payload: any }>()
);

export const CareRoomsSymptomWholeListLoad = createAction(
  CareroomSymptomActionTypes.CAREROOM_SYMPTOM_WHOLE_LIST_LOAD,
  props<{ payload: { queries: SymptomCareRoomSelectListQuery[] } }>()
);
export const CareRoomsSymptomWholeListLoadSuccess = createAction(
  CareroomSymptomActionTypes.CAREROOM_SYMPTOM_WHOLE_LIST_LOAD_SUCCESS,
  props<{ payload: SymptomCareRoomSelectResult[] }>()
);
export const CareRoomsSymptomWholeListLoadFail = createAction(
  CareroomSymptomActionTypes.CAREROOM_SYMPTOM_WHOLE_LIST_LOAD_FAIL,
  props<{ payload: any }>()
);

export const CareroomSymptomActions = {
  CareRoomSymptomLoad,
  CareRoomSymptomLoadSuccess,
  CareRoomSymptomLoadFail,
  CareRoomsSymptomListLoad,
  CareRoomsSymptomListLoadSuccess,
  CareRoomsSymptomListLoadFail,
  CareRoomSymptomSave,
  CareRoomSymptomSaveSuccess,
  CareRoomSymptomSaveFail,
  CareRoomsSymptomWholeListLoad,
  CareRoomsSymptomWholeListLoadSuccess,
  CareRoomsSymptomWholeListLoadFail,
};
