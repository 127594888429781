import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
} from 'rxjs/operators';
import { StorageService } from '~shared/service/storage.service';
import { AgencyModel, EmrTypeListItems } from '~models/auth.model';
import {
  SymptomAddBody,
  SymptomDeleteParemeter,
  SymptomSearchQuery,
  SymptomSearchResult,
} from '~models/select-symptom.model';
import { Api } from './../http-api';
import { HttpRes } from '~models/common.model';

@Injectable()
export class SharedService {
  constructor(private api: Api, private storage: StorageService) {}

  searchHospitalsByTitle(title$: Observable<string>) {
    return title$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((title: string) => this.fetchHospitalList(title))
    );
  }

  searchSymptomByName(symptom$: Observable<SymptomSearchQuery>) {
    return symptom$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      filter(data => data.name !== ''),
      switchMap((symptom: SymptomSearchQuery) => {
        return this.searchSymptoms(symptom);
      })
    );
  }

  /**
   * 병원 이름으로 병원 목록 불러오기
   * @param {string} title
   * @param {number} skip
   * @param {number} limit
   * @memberOf SharedService
   */
  fetchHospitalList(title: string, skip: number = 0, limit: number = 0) {
    const url = 'api/v1/hospital';
    const params = {
      skip,
      limit: 110,
      title: title !== '' ? title : undefined,
    };
    return this.api.get(url, params);
  }

  /**
   * 증상 검색
   * @param {SymptomSearchQuery} data
   * @memberOf SelectSymptomService
   */
  searchSymptoms(data: SymptomSearchQuery): Observable<SymptomSearchResult> {
    const url = 'api/v1/reception-symptom/symptom/search';
    return this.api.get(url, data);
  }

  /**
   * 증상 등록
   * @param {SymptomAddBody} data
   * @memberOf SelectSymptomService
   */
  addSymptom(data: SymptomAddBody): Observable<any> {
    const url = 'api/v1/reception-symptom/symptom';
    return this.api.post(url, data);
  }

  /**
   * 증상 삭제
   * @param {SymptomDeleteParemeter} data
   * @memberOf SelectSymptomService
   */
  deleteSymptom(data: SymptomDeleteParemeter): Observable<any> {
    const url = `api/v1/reception-symptom/symptom/${data.symptomId}`;
    return this.api.delete(url);
  }

  /**
   * EMR 및 차트 프로그램 선택 목록 가져오기
   */
  fetchEmrList(): Observable<EmrTypeListItems> {
    const url = 'api/v1/common/agent-types';
    return this.api.get(url);

    // const items = [
    //   { id: 'UBCARE', name: '유비케어 - 의사랑' },
    //   { id: 'BIT', name: '비트컴퓨터 - U차트' },
    //   { id: 'BITHIB', name: '비트 컴퓨터 병원' },
    //   { id: 'NEO', name: '네오소프트뱅크 - 메디차트, 센스, 이플러스' },
    //   { id: 'POINT', name: '포인트닉스 - 닉스펜, 닉스SQL' },
    //   { id: 'DASOM', name: '다솜정보 - 신통, 차트매니저' },
    //   { id: 'CHUN', name: '전능아이티 - 아담스NC' },
    //   { id: 'MTS', name: '엠디소프트 - MTS' },
    //   { id: 'BYUNGCOM', name: '병원과컴퓨터 - 차트프로' },
    //   { id: 'MEDICAL', name: '메디칼소프트 - 히포크라테스' },
    //   { id: 'FORDOCTOR', name: '포닥터 - 매직차트' },
    //   { id: 'KUMWOO', name: '금우뱅킹시스템 - 파워차트' },
    //   { id: 'SEJUNMIT', name: '서전엠아이티 - 클리닉2000' },
    //   { id: 'CLICKSOFT', name: '클릭소프트 - new click' },
    //   { id: 'TENSOFT', name: '텐소프트 - 아이차트' },
    //   { id: 'MEDISES', name: '대일전산 - 메디세스' },
    //   { id: 'ETC', name: '기타' }
    // ];

    // return of({
    //   items,
    //   totalCount: items.length
    // } as EmrTypeListItems);
  }

  getContent(path: string) {
    return new Observable<string>(sub => {
      const url = `/assets/${path}`;
      const xhr = new XMLHttpRequest();

      xhr.open('GET', url);
      xhr.addEventListener('load', () => {
        const status = xhr.status;

        if (xhr.readyState === 4) {
          if (status >= 200 && status < 400) {
            sub.next(xhr.responseText);
            sub.complete();
          }
        }
      });
      xhr.addEventListener('error', () => {
        sub.error(false);
        sub.complete();
      });

      xhr.send();
    });
  }

  /**
   * 진료과목 목록을 가져 온다.
   */
  fetchSubjectList() {
    const url = 'api/v1/common/hospital-categories';

    return this.api.get(url);
  }

  /**
   * geocode api
   */
  geocode(query: string) {
    const url = 'api/v1/geocode';

    return this.api.get(url, { query });
  }

  /**
   * 병원 조회
   * https://boostbros.atlassian.net/wiki/spaces/BACKEND/pages/1086521433/API+-
   * @param hospitalId 병원 고유 Id
   */
  fetchHospitalInfo(hospitalId: string) {
    const url = `api/v1/hospital/${hospitalId}`;
    return this.api.get(url);
  }

  /**
   * EMR 대리점 리스트
   * https://boostbros.atlassian.net/wiki/spaces/BACKEND/pages/1228931112/-+EMR
   * @param agentType 차트사 타입
   */
  fetchAgencies(agentType?: string): Observable<HttpRes<AgencyModel>> {
    const url = `api/v1/common/emr-agencies`;
    const query = {} as { agentType?: string };
    if (agentType) {
      query.agentType = agentType;
    }

    return this.api.get<HttpRes<AgencyModel>>(url, query);
  }
}
