import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
  val = 0;
  innerValue = 0;
  _min = null;
  _max = null;
  _step = 1;
  _loop = false;

  @Input() set value(val: number) {
    this.val = val;
    this.innerValue = val;

    // console.log('spinner.set value', val);
  }

  @Input() set min(min: number) {
    if (!isFinite(min)) {
      return;
    }
    this._min = min;
  }

  @Input() set max(max: number) {
    if (!isFinite(max)) {
      return;
    }
    this._max = max;
  }

  @Input() set step(step: number) {
    if (!step || step < 0) {
      return;
    }
    this._step = parseInt(step + '', 10);
  }

  @Input() set loop(loop: boolean) {
    this._loop = !!loop;
  }

  @Input() disabled = false;

  @Output() spinnerEventChange = new EventEmitter<number>();

  valid(raw: number) {
    const min = this._min;
    const max = this._max;
    const loop = this._loop;
    let val = raw;

    if (min !== null && val < min) {
      if (loop) {
        val = max;
      } else {
        val = min;
      }
    }
    if (max !== null && val > max) {
      if (loop) {
        val = min;
      } else {
        val = max;
      }
    }

    return val;
  }

  onClick(event: any) {
    const elem = event.target;

    try {
      if (elem.select) {
        elem.select();
      } else {
        elem.setSelectionrange(0, elem.value.length);
      }
    } catch (error) {}
  }

  onChange(event: any, allowEmpty: boolean = true) {
    const elem = event.target as HTMLInputElement;
    const raw = elem.value as string;
    let val = raw ? parseInt(raw, 10) : 0;

    if (allowEmpty && raw === '') {
      return;
    }

    if (isNaN(val)) {
      val = this._min || 0;
    } else {
      val = this.valid(val);
      this.spinnerEventChange.emit(val);
    }

    this.val = val;
    this.innerValue = val;

    try {
      elem.value = val + '';
    } catch (error) {}
  }

  onBlur(event) {
    event.stopPropagation();
    this.onChange(event, false);
  }

  onInc(event) {
    const min = this._min;
    const max = this._max;
    const loop = this._loop;
    const step = this._step;
    let val = this.innerValue + step;

    event.stopPropagation();

    if (max !== null && val > max) {
      if (loop) {
        val = min;
      } else {
        val = max;
      }
    }

    // console.log('spinner inc', this.val, val);

    this.val = val;
    this.innerValue = val;
    this.spinnerEventChange.emit(val);
  }

  onDec(event) {
    const min = this._min;
    const max = this._max;
    const loop = this._loop;
    const step = this._step;
    let val = this.innerValue - step;

    event.stopPropagation();

    if (min !== null && val < min) {
      if (loop) {
        val = max;
      } else {
        val = min;
      }
    }

    // console.log('spinner dec', this.val, val);

    this.val = val;
    this.innerValue = val;
    this.spinnerEventChange.emit(val);
  }
}
