import { OfficeWeeklyDetailData } from './care-room.model';
import { DateRange, HashMap, SchedulerTags } from './common.model';

/**
 * 타임라인 스케줄에서 스케줄 작성 시 각 셀에 작성 방지가 걸린 이유에 대해 정의된 열거형 타입.
 */
export enum TimelinePreventReason {
  /**
   * 해당사항 없음
   */
  NONE = 0,
  /**
   * 휴일
   */
  HOLIDAY = 1,
  /**
   * 식사시간
   */
  MEALTIME = 0x60,
  /**
   * 점심식사
   */
  LAUNCH = 0x20,
  /**
   * 저녁식사
   */
  DINNER = 0x40,
  /**
   * 운영시간이 아님
   */
  TIMEOFF = 0x100,
  /**
   * 운영날이 아님
   */
  DAYOFF = 0x200,
  /**
   * 데이터가 아예 없음
   */
  NODATA = 0xf000,
}

/**
 * 드래그 선택 시 사용되는 4분면 정보.
 */
export enum MoveQuadrantType {
  /**
   * 움직이지 않는 상태 이거나, 움직이지 않아서 커서가 시작셀 내에 있을 때
   */
  NONE,
  /**
   * 좌측상단 (top-left)
   */
  TOP_LEFT,
  /**
   * 우측상단 (top-right)
   */
  TOP_RIGHT,
  /**
   * 좌측하단 (bottom-left)
   */
  BOTTOM_LEFT,
  /**
   * 우측하단 (bottom-right)
   */
  BOTTOM_RIGHT,
}

/**
 * 증상 선택 아이템.
 * symptomCategory 내용을 현재 병원의 증상선택 활성화 내용에 맞는 것들을 적절히 필터링 하여 사용 하게 된다.
 * UI 에서 쓰인다.
 */
export interface SymptomItem {
  _id?: string;
  name: string;
}

/**
 * 시간 범위.
 * 예약 가능 시간 간격 Ui 및 타임라인 테이블에서 사용 된다.
 * 각 필드의 포멧: HH:mm
 */
export interface HourRange {
  start: string;
  end: string;
  weekdays?: boolean[];
}

export interface ScheduleHourRange extends HourRange {
  lunch?: HourRange;
  dinner?: HourRange;
  isDayOff?: boolean;
}

/**
 * 증상 선택 단위.
 * 스케줄 설정 시 그 각 증상별 설정 내용에 대하여 데이터로 추상화 한 것이다.
 * 서버에서 쓰인다.
 */
export interface SymptomUnit {
  timeUnit: number;
  symptom: string | null;
  count: number;
  colorNo: string;
}

/**
 * 시간 범위를 지정하는 모델.
 * 시작 시간과 끝 시간으로 분류 된다.
 */
export interface TimeRange {
  startTime: string;
  endTime: string;
}

/**
 * 셀의 인덱스 정보를 가짐.
 */
export interface CellIndex {
  row: number;
  col: number;
}

/**
 * 셀 인덱스를 범위별로 가지는 모델.
 */
export interface CellIndexRange {
  start: CellIndex;
  end: CellIndex;
}

/**
 * 타임라인 테이블의 각 셀에 입력 방지 (TimelinePreventReason)를 넣을 때
 * 그 기준이되는 시간적 범위를 테이블의 행/열 정보로 바꾸어 쓸 때 사용.
 */
export interface TimeCellPreventInfo extends CellIndexRange {
  isDayOff: boolean;
  hasDinnerBreak: boolean;
  hasLunchBreak: boolean;
  dinner?: CellIndexRange;
  lunch?: CellIndexRange;
}

/**
 * 공휴일 기본 정보.
 */
export interface Holiday {
  date: string;
  title: string;
  type: string;
}

/**
 * 공휴일 정보를 백엔드 API로 가져왔을 때 구성되는 기본 자료 구조.
 */
export interface HolidaysResData extends DateRange {
  holidays: Holiday[];
  totalCount: number;
}

/**
 * 타임라인 스케줄에 기록되는 모델.
 * 테이블 형태의 UI에 기록되는 자료를 추상화 한 것이다.
 */
export interface EnableTime extends TimeRange {
  symptom: string | null;
  count: number;
}

/**
 * 타임라인 스케줄의 각 날짜별 자료를 추상화 한 것이다.
 */
export interface Schedule {
  day: number;
  isDayOff?: boolean;
  enableTime: EnableTime[];
}

/**
 * 사용자가 타임라인 스케줄 표에서 액션이 이뤄질 때 사용되는 셀 위치 모델.
 */
export interface CellInfo {
  key: string;
  day?: number;
  row: number;
  col: number;
  /* 예약 가능 인원 */
  count: number;
  pageX: number;
  pageY: number;
  reason: number;
  color?: string;
}

/**
 * 타임라인 스케줄의 각 셀에 대한 데이터.
 */
export interface TimeCell {
  text: string;
  color: string;
  count: number | null;
  start: number | null;
  end: number | null;
  reason: number;
  selected?: boolean;
}

/**
 * 타임라인 스케줄의 각 행에 대한 데이터.
 */
export interface TimeRow {
  cells: TimeCell[];
}

export interface SectionCellInfo {
  index: number;
  start: number;
  end: number;
}

/**
 * 타임라인 스케줄 내용이 변경 되었을 때 전달되는 인자 데이터.
 */
export interface TimelineDayColumnEventArgs {
  data: Schedule;
  added: EnableTime[];
  removed: EnableTime[];
  modified: EnableTime[];
}

/**
 * 타임라인 스케줄이 변경 되었을 때 전달되는 인수 데이터.
 */
export interface TimelineChangeEventArgs extends CellInfo {
  count: number;
}

/**
 * 연/월 자료만을 전달하는 모델.
 */
export interface YearMonth {
  year: number;
  month: number;
}

/**
 * 백엔드에 타임라인 스케줄 현황을 검색할 때 쓰이는 파라메터 모델.
 */
export interface TimelineScheduleReqParams {
  unitKey: string;
  monthKey: string;
}

/**
 * 예약 시간 (Open Time) 설정 시 사용되는 파라메터 모델.
 */
export interface OpenTimeUpdateParams {
  unitKey: string;
  data: HashMap<OfficeWeeklyDetailData>;
}

/**
 * 백엔드에 생성/업데이트 시 요청하는 데이터.
 */
export interface TimelineScheduleReqData extends TimelineScheduleReqParams {
  preset: {
    symptomUnit: SymptomUnit[];
  };
  schedules: Schedule[];
  tags: SchedulerTags.V1;
}

/**
 * 백엔드에서 응답받는 타임라인 스케줄 데이터.
 */
export interface TimelineScheduleResData
  extends Omit<TimelineScheduleReqData, 'tags'> {
  _id?: string;
  hospital: string;
  version?: number;
  warningMessage?: string;
  /**
   * 시간 조정이나 진료항목 변경으로 인하여 스케줄이 변경되었을 때 true 로 들어온다.
   */
  hasModified?: boolean;
  resave?: boolean;
  /**
   * 최초생성여부
   */
  isFirstCreate?: boolean;
  /**
   * 예약 스케줄러 버전
   */
  tags: SchedulerTags;
}

/**
 * 타임라인 테이블 컴포넌트에 전달되는 렌더링용 데이터.
 */
export interface TimelineInfo {
  day: number;
  weekday: number;
  holiday?: Holiday;
  isDayOff?: boolean;
  timeRows: TimeRow[];
  // timeUnit: number;
}

/**
 * 타임라인 테이블의 RowHead 컴퍼넌트에서 쓰이는 데이터.
 */
export interface HourItem {
  hour: string;
  isPm: boolean;
  raw: number;
}

/**
 * 스케줄을 임의의 날짜로 불러 왔을 때 리듀서에 전달 되는 데이터 모음.
 */
export interface LoadedTimelineData {
  selectedSymptomUnit: SymptomUnit;
  symptomUnits: SymptomUnit[];
  symptomItems: SymptomItem[];
  symptomMap: { [id: string]: SymptomItem };
  scheduleData: TimelineScheduleResData;
  timelineInfoList: TimelineInfo[][];
}

/**
 * 페이지 내용을 초기에 불러올 때 전달되는 자료 모음.
 */
export interface BaseTimelineData extends LoadedTimelineData, YearMonth {
  scheduleData: TimelineScheduleResData;
  hourRange: HourRange;
  hourItems: HourItem[];
  timeUnit: number;
  timeUnitHeader: number[];
  openTimeMap: { [key: string]: OfficeWeeklyDetailData };
  holidayMap: { [day: string]: Holiday };
}

/**
 * 타임라인 관련 이펙트에서 스케줄 수정 시 Rx로 전달되는 스트림 데이터.
 */
export interface CellInfoStream {
  cellInfo?: CellInfo;
  hourItems: HourItem[];
  symptomUnit: SymptomUnit;
  timeUnit: number;
  timelineInfoList: TimelineInfo[][];
  week: number;
}

/**
 * CellInfo 에서 변환되어 스토어에서 임시로 이용하게 되는 스트림 데이터.
 */
export interface EnableTimeStream {
  cellInfo: CellInfo;
  enableTime: EnableTime;
  symptomUnit: SymptomUnit;
  timeUnit: number;
  timelineInfoList: TimelineInfo[][];
  week: number;
}

export interface TimelineInfoModifyStream extends EnableTimeStream {
  remove: boolean;
}

/**
 * 시작과 종료 및 그에 따른 시/분 으로 나눠진 데이터.
 */
export interface HourMinRange {
  startHour: number;
  startMin: number;
  endHour: number;
  endMin: number;
}

export interface ScheduleCalendarCell {
  day: number;
  marked: boolean;
  copySelected: boolean;
  pasteSelected: boolean;
  holiday?: boolean;
  head?: boolean;
  tail?: boolean;
}

/**
 * 스케줄 목록을 UI 에서 보일 때 쓰인다.
 */
export interface ScheduleItem extends EnableTime, SymptomUnit, SymptomItem {}

/**
 * 스케줄 목록을 UI 에서 보일 때 쓰인다.
 * 날짜별로 그룹화를 담당 한다.
 */
export interface ScheduleItemGroup extends YearMonth {
  day: number;
  items: ScheduleItem[];
}

/**
 * 증상 선택에 관련된 내용을 모아 놓은 '증상 정보' 모델.
 */
export interface SymptomInfo extends SymptomUnit, SymptomItem {}

/**
 * 스케줄 미리보기 적용 시 쓰이는 페이로드 모델
 */
export interface TimelinePreviewPayload {
  symptomUnit?: SymptomUnit;
  hourRange?: HourRange;
}

/**
 * 스케줄 존재 여부를 담고 있는 모델.
 */
export interface ScheduleExistsItem {
  monthKey: string;
  available: boolean;
  tags: SchedulerTags;
}

/**
 * 서버에 스케줄 존재 여부를 요청할 때 사용하는 파라매터.
 */
export interface ScheduleExistsReqParams {
  unitKey: string;
  monthKeyStart: string;
  monthKeyEnd: string;
}

/**
 * 스케줄 존재 여부를 확인할 수 있는 자료.
 */
export interface ScheduleExistsResData {
  items: ScheduleExistsItem[];
  totalCount: number;
  tags?: SchedulerTags[];
}

/**
 * 스케줄 주복사를 할 때 전달되는 페이로드 모델.
 */
export interface ScheduleWeekCopyPayload {
  src: number[];
  dist: number[][];
  allowHoliday?: boolean;
}
