import {
  ResultNotifiedItem,
  ResultNotifiedResModel,
  ResultNotifiedSearchUiQuery,
} from '~models/status-result-notified.model';

export function createStatusResultNotifiedSearchUiQuery(): ResultNotifiedSearchUiQuery {
  return {
    status: '',
    patientName: '',
    birthDate: '',
    phone: '',
    dateType: '',
    occurredDate: {
      startDate: '',
      endDate: '',
    },
    sort: '-INSPECTION_DATE',
    skip: 0,
    limit: 10,
  };
}

export function createResultNotifiedInfo(): ResultNotifiedResModel {
  return {
    totalCount: 0,
    limit: 10,
    skip: 0,
    items: [],
  };
}

export function createResultNotifiedItem(): ResultNotifiedItem {
  return {
    _id: '',
    patientName: '',
    patientGender: 'M',
    patientBirthDate: '',
    patientPhone: '',
    inspectionDate: '',
    receivedDate: '',
    alimtalkSendDate: '',
    alimtalkSendResult: '',
  };
}
