import {
  Component,
  OnInit,
  Input,
  ContentChild,
  TemplateRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { SelectOption } from '../listed-select/listed-select.component';

export enum ListStateEnum {
  PENDING,
  LOADED,
  EMPTY,
  DISABLED,
}

@Component({
  selector: 'app-label-listed-select',
  templateUrl: './label-listed-select.component.html',
  styleUrls: ['./label-listed-select.component.scss'],
})
export class LabelListedSelectComponent implements OnInit {
  private _title = '';
  @Input() set title(title: string) {
    this._title = title;
  }

  get title() {
    return this._title;
  }
  @Input() value?: string | number;
  @Input() isPending = false;
  @Input() options: SelectOption[] = [];
  @Input() disabled = false;

  @Output() selectOptionChange = new EventEmitter<SelectOption>();

  @ContentChild('disabled') disabledRef: TemplateRef<any> | undefined;
  @ContentChild('empty') emptyRef: TemplateRef<any> | undefined;

  listStateType = ListStateEnum;

  get listState() {
    if (this.disabled) {
      return this.listStateType.DISABLED;
    }
    if (this.isPending) {
      return this.listStateType.PENDING;
    }
    if (this.options.length > 0) {
      return this.listStateType.LOADED;
    }

    return this.listStateType.EMPTY;
  }

  get itemCount() {
    return this.options.length;
  }

  constructor() {}
  ngOnInit() {}

  handleChange(event: SelectOption) {
    this.selectOptionChange.emit(event);
  }
}
