import { createAction, props } from '@ngrx/store';
import { Agency, VoluntaryCreateQuery } from '~models/voluntary';

export const VoluntaryActionTypes = {
  VOLUNTARY_CREATE: '[VOLUNTARY] Voluntary Create',
  VOLUNTARY_CREATE_SUCCESS: '[VOLUNTARY] Voluntary Create Success',
  VOLUNTARY_CREATE_FAIL: '[VOLUNTARY] Voluntary Create fail',
  VOLUNTARY_AGENCY_LOAD: '[VOLUNTARY] Voluntary Agency Load',
  VOLUNTARY_AGENCY_LOAD_SUCCESS: '[VOLUNTARY] Voluntary Agency Load Success',
} as const;

// 자발적 신청 작성

export const VoluntaryCreate = createAction(
  VoluntaryActionTypes.VOLUNTARY_CREATE,
  props<{ payload: { data: VoluntaryCreateQuery } }>()
);

export const VoluntaryCreateSuccess = createAction(
  VoluntaryActionTypes.VOLUNTARY_CREATE_SUCCESS
);

export const VoluntaryCreateFail = createAction(
  VoluntaryActionTypes.VOLUNTARY_CREATE_FAIL,
  props<{ payload: any }>()
);

export const VoluntaryAgencyLoad = createAction(
  VoluntaryActionTypes.VOLUNTARY_AGENCY_LOAD,
  props<{ payload: { emrType: string } }>()
);

export const VoluntaryAgencyLoadSuccess = createAction(
  VoluntaryActionTypes.VOLUNTARY_AGENCY_LOAD_SUCCESS,
  props<{ payload: { agencies: Agency[] } }>()
);

export const VoluntaryActions = {
  VoluntaryCreate,
  VoluntaryCreateSuccess,
  VoluntaryCreateFail,
  VoluntaryAgencyLoad,
  VoluntaryAgencyLoadSuccess,
};
