import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthLoginInfo } from '~models/auth.model';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs/';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthActions } from '~actions/auth/auth.action';
import * as fromAuth from '~reducers/auth';
import { SigninModalComponent } from '../../components/signin-modal/signin-modal.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-sign-in-page',
  templateUrl: './signin-page.component.html',
})
export class SignInPageComponent implements OnInit, OnDestroy {
  loading$: Observable<boolean>;
  error$: Observable<any>;
  loggedIn$: Subscription;
  queryParams$: Subscription | undefined;

  @ViewChild('signinModal', { static: false })
  signinModal?: SigninModalComponent;

  constructor(
    private store: Store<fromAuth.AuthInfoState>,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.loading$ = this.store.pipe(select(fromAuth.getAuthLoading));
    this.error$ = this.store.pipe(
      select(fromAuth.getError),
      map(msg => {
        if (msg && !this.signinModal?.isShow) {
          alert(msg);
        }

        return msg;
      })
    );
    this.loggedIn$ = this.store
      .pipe(select(fromAuth.getLoggedIn))
      .subscribe(loggedIn => {
        if (loggedIn) {
          this.router.navigate(['/home']);
        }
      });
  }

  ngOnInit(): void {
    this.queryParams$ = this.route.queryParams.subscribe(_ => {
      this.goHomeByToken(this.getUrlVars());
    });
  }

  ngOnDestroy(): void {
    try {
      this.queryParams$?.unsubscribe();
      this.loggedIn$.unsubscribe();
    } catch (error) {}
  }

  goHomeByToken(params: Params): void {
    const sEmrToken = params.emr_authtoken;
    const sAdminToken = params.ddocdoc_admin_authtoken;
    const guideTypeParam = params.guide_type;

    const mPayload: AuthLoginInfo = {};
    let hasToken = false;

    if (sEmrToken) {
      mPayload.emrAuthToken = sEmrToken;
      hasToken = true;
    } else if (sAdminToken) {
      mPayload.ddocdocAdminAuthToken = sAdminToken;
      hasToken = true;
    }

    if (hasToken) {
      this.store.dispatch(
        AuthActions.Login({
          payload: {
            authLoginInfo: mPayload,
            guideType: guideTypeParam,
          },
        })
      );
    } else if (params.login === 'go') {
      this.signinModal?.open();
    }
  }

  getUrlVars(): any {
    const vars = {};
    let hash;
    const hashes = window.location.href
      .slice(window.location.href.indexOf('?') + 1)
      .split('&');
    for (let i = 0; i < hashes.length; i++) {
      hash = hashes[i].split('=');
      // @ts-ignore
      vars[hash[0]] = hash[1];
    }
    return vars;
  }

  login(data: AuthLoginInfo): void {
    this.store.dispatch(
      AuthActions.Login({
        payload: {
          authLoginInfo: data,
        },
      })
    );
  }
}
