<div class="page-bg"></div>
<div class="wrap">
  <a class="find-header mb-5" routerLink="/">
    <img
      class="mb-4"
      src="/assets/img/find-id-password.png"
      width="100"
      height="37"
    />
    <hr />
  </a>
  <div class="text-center">
    <div class="title-section"><span>비밀번호 변경 완료</span></div>
    <img
      src="/assets/img/reset-password-complete.png"
      width="80"
      height="80"
      alt="변경 완료"
    />
  </div>
  <a
    routerLink="/signin"
    [queryParams]="{ login: 'go' }"
    class="btn btn-lg submit-btn"
  >
    로그인
  </a>
</div>
