import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  KioskQuestionOptionUIModel,
  QuestionnaireResItem,
} from '~models/question.model';
import { KioskQuestionActions } from '~actions/products/kiosk-question.actions';
import {
  createKioskQuestionUIModel,
  getKioskQuestionOptionItem,
  parseKioskQuestionOptionUIModel,
} from '~store/services/question.effect.services';
import { head as _head, values as _values } from 'lodash';

interface State {
  loaded: boolean;
  _id: string;
  hospitalId: string;
  unitKey: string;
  type: string;
  unitTitle: string;
  subject: string;
  allQuestionnaireOptions: QuestionnaireResItem[];
}

export type KioskQuestionOptionState = State &
  EntityState<KioskQuestionOptionUIModel>;

export const kioskQuestionOptionAdapter: EntityAdapter<KioskQuestionOptionUIModel> = createEntityAdapter<
  KioskQuestionOptionUIModel
>({
  selectId: questionOption => questionOption._id,
});

const initialState = kioskQuestionOptionAdapter.getInitialState<State>({
  loaded: false,
  _id: '',
  subject: '',
  type: 'KIOSK',
  unitKey: '',
  unitTitle: '',
  hospitalId: '',
  allQuestionnaireOptions: [],
});

export const kioskQuestionOptionReducer = createReducer(
  initialState,
  on(
    KioskQuestionActions.KioskQuestionOptionsSuccess,
    (state, { payload: { items, ...rest } }) => {
      return kioskQuestionOptionAdapter.setAll(
        items.map(item => parseKioskQuestionOptionUIModel(item)),
        {
          ...state,
          loaded: true,
          ...rest,
        }
      );
    }
  ),
  on(
    KioskQuestionActions.KioskQuestionOptionChange,
    (state, { id, changes }) => {
      return kioskQuestionOptionAdapter.updateOne(
        { id, changes },
        { ...state }
      );
    }
  ),
  on(KioskQuestionActions.KioskQuestionOptionAdd, state => {
    const length = state.ids.length;
    return kioskQuestionOptionAdapter.addOne(
      {
        ...createKioskQuestionUIModel(length),
      },
      { ...state }
    );
  }),
  on(KioskQuestionActions.KioskQuestionOptionRemoveSuccess, (state, { id }) => {
    return kioskQuestionOptionAdapter.removeOne(id, { ...state });
  }),
  on(
    KioskQuestionActions.KioskQuestionOptionItemCountChange,
    (state, { id, optionCount }) => {
      const { options } = state.entities[id];
      const optionItems = (() => {
        const head = _head(options);
        if (optionCount === 1) {
          return [head];
        }
        return [...options, getKioskQuestionOptionItem()];
      })();
      return kioskQuestionOptionAdapter.updateOne(
        {
          id,
          changes: {
            optionCount,
            options: optionItems,
          },
        },
        { ...state }
      );
    }
  ),
  on(KioskQuestionActions.KioskQuestionImportOptions, (state, { options }) => {
    const importedOptions = [
      ..._values(state.entities).filter(item => item.default === true),
      ...options.map(option => parseKioskQuestionOptionUIModel(option)),
    ];

    return kioskQuestionOptionAdapter.setAll(importedOptions, state);
  }),
  on(
    KioskQuestionActions.KioskQuestionAllOptionListSuccess,
    (state, { payload }) => {
      return { ...state, allQuestionnaireOptions: payload };
    }
  )
);
