export interface PaymentsAccount {
  bank: string;
  accountNumber: string;
  holderName: string;
}

export interface PaymentsRegisterReq {
  type: PaymentsSettingType;
  hospitalId: string;
  account: PaymentsAccount;
  activationDate: string;
}

export type PaymentsRegisterRes = PaymentsSetting;

export type PaymentsSettingType = 'CORPORATE' | 'INDIVIDUAL';
export const PaymentsSettingTypeMapper = {
  CORPORATE: '법인사업자',
  INDIVIDUAL: '개인사업자',
} as const;

export type PaymentsSettingStatus = 'FAILURE' | 'SUCCESS';

export interface PaymentsSetting {
  subMallId: string;
  type: PaymentsSettingType;
  companyName: string;
  representativeName: string;
  businessNumber: string;
  account: PaymentsAccount;
  _id: string;
  hospitalId: string;
  status: PaymentsSettingStatus;
  activationDate: string;
  reason?: string;
  createdAt: string;
  updatedAt: string;
  active: boolean;
}

export interface PaymentsSettingUpdateReq {
  account: PaymentsAccount;
}

export type PaymentsSettingUpdateRes = PaymentsSetting;

export interface PaymentsUseUpdateReq {
  use: boolean;
}

export interface PaymentsActivationDateUpdateReq {
  activationDate: string;
}

export interface PaymentsLog {
  createdAt: string;
  payoutDateRange: {
    startDate: string;
    endDate: string;
  };
  payoutCycle: 'DAILY' | 'MONTHLY';
  payoutAmount: number;
}

export interface PaymentsLogFetchReq {
  startDate?: string;
  endDate?: string;
  skip: number;
}

export interface PaymentsLogFetchRes {
  items: PaymentsLog[];
  count: number;
  totalCount: number;
  skip: number;
  limit: number;
}

export interface PaymentsCSVFetchReq {
  startDate?: string;
  endDate?: string;
}

export interface PaymentsFilterValues {
  bankCode: string[];
}

export const enum TOSS_SUB_MALL_ERROR_CODES {
  ALREADY_EXIST_SUBMALL = 'ALREADY_EXIST_SUBMALL',
  INVALID_BUSINESS_NUMBER = 'INVALID_BUSINESS_NUMBER',
  INVALID_ACCOUNT_NUMBER = 'INVALID_ACCOUNT_NUMBER',
  INVALID_BANK = 'INVALID_BANK',
  ACCOUNT_OWNER_CHECK_FAILED = 'ACCOUNT_OWNER_CHECK_FAILED',
  INVALID_REQUEST = 'INVALID_REQUEST',
  NOT_ALLOWED_TIME_UPDATE_PAYMENT_SETTING = 'NOT_ALLOWED_TIME_UPDATE_PAYMENT_SETTING',
}

export const TOSS_SUB_MALL_ERROR_MESSAGE_MAPPER = {
  ALREADY_EXIST_SUBMALL: '이미 존재하는 서브몰입니다.',
  INVALID_BUSINESS_NUMBER: '사업자 등록번호가 잘못되었습니다.',
  INVALID_ACCOUNT_NUMBER: '계좌번호가 올바르지 않습니다.',
  INVALID_BANK: '유효하지 않은 은행입니다.',
  ACCOUNT_OWNER_CHECK_FAILED:
    '계좌 소유주를 가져오는데 실패했습니다. 은행코드, 계좌번호를 확인해주세요.',
  INVALID_REQUEST: '잘못된 요청입니다.',
  NOT_ALLOWED_TIME_UPDATE_PAYMENT_SETTING:
    '오후 11시~오전 2시에는 계좌 수정이 불가합니다! 잠시 후 다시 시도해주세요.',
} as const;

export type TossErrorKey = keyof typeof TOSS_SUB_MALL_ERROR_MESSAGE_MAPPER;

export const LIST_LIMIT = 10;
