import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs/';
import { take } from 'rxjs/operators';
import { Omit } from '~models/common.model';

const DEFAULT_TITLE = '알림';

export interface CommonModalParams {
  /**
   * 모달 모드
   * type: alert, confirm, retreat
   */
  mode?: CommonModalModeType;
  title?: string;
  /**
   * 모달 내용
   */
  desc?: string;
  elseWhere?: RetreatElseWhere;
  /**
   * 우측 상단 닫기 버튼 노출 여부
   * 기본 true
   */
  showClose?: boolean;
  /**
   * (alert 전용) alert 모드 일때, 버튼 텍스트
   */
  checkText?: string; // alert 전용
  /**
   * (confirm 전용) confirm 모드 일때, 중립 버튼 텍스트
   */
  positiveText?: string; // confirm 전용
  /**
   * (confirm 전용) confirm 모드 일때, 긍정 버튼 텍스트
   */
  negativeText?: string; // confirm 전용
}

export type CommonAlertModalParams = Omit<
  CommonModalParams,
  'positiveText' | 'negativeText'
>;
export type CommonConfirmModalParams = Omit<CommonModalParams, 'checkText'>;

export type CommonModalModeType = 'alert' | 'confirm' | 'retreat';

export interface CommonModalModel extends CommonModalParams {
  open: boolean;
}

export interface RetreatElseWhere {
  text: string;
  route: string;
}

function getInitData(): CommonModalModel {
  return {
    mode: 'alert',
    title: DEFAULT_TITLE,
    desc: '',
    open: false,
    showClose: true,
    checkText: null,
    positiveText: null,
    negativeText: null,
  };
}

@Injectable()
export class CommonModalService {
  private _modal = new BehaviorSubject<CommonModalModel>(getInitData());
  private _result = new Subject<boolean>();

  get modal() {
    return this._modal.asObservable();
  }

  get result() {
    return this._result.asObservable();
  }

  constructor(private location: Location, private router: Router) {}

  open(params: CommonModalParams) {
    const args = { ...params, open: true } as CommonModalModel;

    this._modal.next(args);

    return this._result.pipe(take(1));
  }

  close(val: boolean, elseWhere?: RetreatElseWhere) {
    this._result.next(val);
    this._modal.next(getInitData());

    if (elseWhere) {
      if (val) {
        this.router.navigate([elseWhere.route]);
      } else {
        this.location.back();
      }
      return;
    }
  }

  alert(desc: string, options: Partial<CommonAlertModalParams> = {}) {
    return this.open({ mode: 'alert', desc, ...options });
  }

  confirm(desc: string, options: Partial<CommonConfirmModalParams> = {}) {
    return this.open({ mode: 'confirm', desc, ...options });
  }

  retreat(desc: string, elseWhere?: RetreatElseWhere) {
    return this.open({ mode: 'retreat', desc, elseWhere });
  }
}
