import { DoctorInfoUiModel } from '~models/doctor.model';
import { DoctorInfoActions } from '~actions/products/doctor-info.actions';
import { createDoctorInfoUiModel } from '../../services/doctor-info/doctor-info.create';
import { createReducer, on } from '@ngrx/store';

export interface DoctorInfoState {
  doctorIds: string[];
  doctorsMap: { [id: string]: DoctorInfoUiModel };
  doctorDetail: DoctorInfoUiModel;
  skip: number;
  totalCount: number;
}

const initialState: DoctorInfoState = {
  doctorIds: [],
  doctorsMap: {},
  doctorDetail: createDoctorInfoUiModel(),
  skip: 0,
  totalCount: 0,
};

export const reducer = createReducer(
  initialState,
  on(DoctorInfoActions.DoctorListLoadSuccess, (state, action) => ({
    ...state,
    doctorIds: action.payload.doctorIds,
    doctorsMap: { ...state.doctorsMap, ...action.payload.doctorMap },
    skip: action.payload.skip,
    totalCount: action.payload.totalCount,
  })),
  on(DoctorInfoActions.DoctorUpdateActiveSuccess, (state, action) => ({
    ...state,
    doctorsMap: {
      ...state.doctorsMap,
      [action.doctorInfo._id]: action.doctorInfo,
    },
  })),
  on(DoctorInfoActions.DoctorInfoLoadSuccess, (state, action) => ({
    ...state,
    doctorDetail: { ...action.payload },
  }))
);

// export function reducer(
//   state: DoctorInfoState = initialState,
//   action: DoctorInfoActions
// ): ActionReducer<DoctorInfoState, DoctorInfoActions> {
//   switch (action.type) {
//     case DoctorInfoActionTypes.DOCTOR_LIST_LOAD_SUCC:
//       return {
//         ...state,
//         doctorIds: action.payload.doctorIds,
//         doctorsMap: { ...state.doctorsMap, ...action.payload.doctorMap },
//         skip: action.payload.skip,
//         totalCount: action.payload.totalCount,
//       };
//     case DoctorInfoActionTypes.DOCTOR_UPDATE_ACTIVE_SUCC:
//       return {
//         ...state,
//         doctorsMap: {
//           ...state.doctorsMap,
//           [action.doctorInfo._id]: action.doctorInfo,
//         },
//       };
//     case DoctorInfoActionTypes.DOCTOR_INFO_LOAD_SUCC:
//       return { ...state, doctorDetail: { ...action.payload } };
//     default:
//       return state;
//   }
// }
