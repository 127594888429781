import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-find-password',
  templateUrl: './findPassword.component.html',
  styleUrls: ['./findPassword.component.scss'],
})
export class FindPasswordComponent {
  @Input() error: string | null = null;

  @Output() submitted = new EventEmitter<any>();
  findPwForm: FormGroup;

  validateEmail =
    '^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$';
  validateynum = '[0-9]+$';
  validateId = '[A-Za-z0-9]*';

  constructor(private fb: FormBuilder) {
    this.findPwForm = this.fb.group({
      // TODO: 테스트 데이터. 서버 반영 시 제거 할 것
      // name: ['손준현', Validators.compose([Validators.required])],
      // email: ['theson@bbros.kr', Validators.compose([Validators.required, Validators.pattern(this.validateEmail)])],
      // ynum: ['ys040203', Validators.compose([Validators.required])],
      // hospital: ['585b2e94617ae8020c647565', Validators.required]
      name: [null, Validators.compose([Validators.required])],
      email: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(this.validateEmail),
        ]),
      ],
      ynum: [null, Validators.compose([Validators.required])],
      hospital: [null, Validators.required],
    });
  }

  getHospitalInfo(item: any): void {
    this.findPwForm.controls.hospital.setValue(item._id);
  }

  onSubmit(e: any): void {
    e.preventDefault();
    if (this.findPwForm.valid) {
      // console.log('this.findPwForm.value', this.findPwForm.value);
      this.submitted.emit(this.findPwForm.value);
    }
  }
}
