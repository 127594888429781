import { createAction, props } from '@ngrx/store';
import {
  WaitingsAgreedRes,
  WaitingsSetupRes,
  WaitingsSetupUiModel,
  WaitingsUseChangeModel,
  WaitingsUseSimpleChangeModel,
  WaitNoticeUiModel,
  WaitSettingsUiModel,
} from '~models/waitings.model';
import { WaitingsState } from '~reducers/products/waitings.reducer';

export const WaitingsActionType = {
  LOAD: '[Waitings] Load',
  LOAD_SUCCESS: '[Waitings] Load Success',
  SAVE: '[Waitings] Save',
  SAVE_VALID: '[Waitings] Save Valid',
  SAVE_SUCCESS: '[Waitings] Save Success',
  USE_CHANGE: '[Waitings] Use Change',
  USE_SIMPLE_CHANGE: '[Waitings] Use Simple Change',
  USE_ALIMTALK_CHANGE: '[Waitings] Use Alimtalk Change',
  USE_ALIMTALK_CHANGE_SUCCESS: '[Waitings] Use Alimtalk Change Success',
  USE_CHANGE_SUCCESS: '[Waitings] Use Change Success',
  NOTICE_CHANGE: '[Waitings] Notice Change',
  SETTINGS_CHANGE: '[Waitings] Settings Change',
  CHANGED: '[Waitings] Changed',
  FAIL: '[Waitings] Fail',

  LOAD_AGREED: '[Waitings] Load Agreed',
  LOAD_AGREED_SUCCESS: '[Waitings] Load Agreed Success',
} as const;

/**
 * 액션: 대기현황 설정 내용 불러오기
 */
// export class WaitingsLoad implements Action {
//   readonly type = WaitingsActionType.LOAD;

//   constructor(
//     /**
//      * 설정 ID (setupId)
//      */
//     public payload: string
//   ) {}
// }

export const WaitingsLoad = createAction(
  WaitingsActionType.LOAD,
  props<{ payload: string }>()
);

/**
 * 액션: 대기현황 설정 내용 불러오기 성공
 */
// export class WaitingsLoadSucc implements Action {
//   readonly type = WaitingsActionType.LOAD_SUCC;

//   constructor(
//     public payload: WaitingsSetupUiModel & {
//       response: WaitingsSetupRes;
//     }
//   ) {}
// }

export const WaitingsLoadSuccess = createAction(
  WaitingsActionType.LOAD_SUCCESS,
  props<{ payload: WaitingsSetupUiModel & { response: WaitingsSetupRes } }>()
);

/**
 * 액션: 대기현황 설정 저장
 */
// export class WaitingsSave implements Action {
//   readonly type = WaitingsActionType.SAVE;

//   constructor(
//     public payload: {
//       state: WaitingsState;
//       setupId: string;
//     }
//   ) {}
// }

export const WaitingsSave = createAction(
  WaitingsActionType.SAVE,
  props<{ payload: { state: WaitingsState; setupId: string } }>()
);

/**
 * 액션: 대기현황 설정 유효성 검증 통과
 */
// export class WaitingsSaveValid implements Action {
//   readonly type = WaitingsActionType.SAVE_VALID;

//   constructor(
//     public payload: {
//       state: WaitingsState;
//       setupId: string;
//     }
//   ) {}
// }

export const WaitingsSaveValid = createAction(
  WaitingsActionType.SAVE_VALID,
  props<{ payload: { state: WaitingsState; setupId: string } }>()
);

/**
 * 액션: 대기현황 설정 저장 성공
 */
// export class WaitingsSaveSucc implements Action {
//   readonly type = WaitingsActionType.SAVE_SUCC;
// }

export const WaitingsSaveSuccess = createAction(
  WaitingsActionType.SAVE_SUCCESS
);

/**
 * 액션: 대기현황 사용 여부 변경
 */
// export class WaitingsUseChange implements Action {
//   readonly type = WaitingsActionType.USE_CHANGE;

//   constructor(public payload: boolean) {}
// }

export const WaitingsUseChange = createAction(
  WaitingsActionType.USE_CHANGE,
  props<{ payload: WaitingsUseChangeModel }>()
);

/**
 * 모달 없이 대기 현황판 사용 여부 변경
 */
export const WaitingsUseSimpleChange = createAction(
  WaitingsActionType.USE_SIMPLE_CHANGE,
  props<{ payload: WaitingsUseSimpleChangeModel }>()
);

export const WaitingsAlimtalkChange = createAction(
  WaitingsActionType.USE_ALIMTALK_CHANGE,
  props<{ payload: boolean }>()
);

/**
 * 액션: 대기현황 공지사항 데이터 변경
 */
// export class WaitingsNoticeChange implements Action {
//   readonly type = WaitingsActionType.NOTICE_CHANGE;

//   constructor(public payload: WaitNoticeUiModel) {}
// }

export const WaitingsNoticeChange = createAction(
  WaitingsActionType.NOTICE_CHANGE,
  props<{ payload: WaitNoticeUiModel }>()
);

/**
 * 액션: 대기현황 기타 설정 데이터 변경
 */
// export class WaitingsSettingsChange implements Action {
//   readonly type = WaitingsActionType.SETTINGS_CHANGE;

//   constructor(public payload: WaitSettingsUiModel) {}
// }

export const WaitingsSettingsChange = createAction(
  WaitingsActionType.SETTINGS_CHANGE,
  props<{ payload: WaitSettingsUiModel }>()
);

/**
 * 액션: 대기현황 설정 내용 변경됨
 */
// export class WaitingsChanged implements Action {
//   readonly type = WaitingsActionType.CHANGED;

//   constructor(public payload: WaitingsSetupUiModel) {}
// }

export const WaitingsChanged = createAction(
  WaitingsActionType.CHANGED,
  props<{ payload: WaitingsSetupUiModel }>()
);

/**
 * 액션: 대기현황 관련 행위 실패
 */
// export class WaitingsFail extends ErrorAction {
//   readonly type = WaitingsActionType.FAIL;

//   constructor(public payload: any) {
//     super(payload);
//   }
// }

export const WaitingsFail = createAction(
  WaitingsActionType.FAIL,
  props<{
    payload: any;
  }>()
);

export const WaitingsUseChangeSuccess = createAction(
  WaitingsActionType.USE_CHANGE_SUCCESS,
  props<{ payload: WaitingsUseChangeModel }>()
);

export const WaitingsLoadAgreed = createAction(
  WaitingsActionType.LOAD_AGREED,
  props<{ payload: string }>()
);

export const WaitingsLoadAgreedSuccess = createAction(
  WaitingsActionType.LOAD_AGREED_SUCCESS,
  props<{ payload: WaitingsAgreedRes }>()
);

export const WaitingsAlimtalkChangeSuccess = createAction(
  WaitingsActionType.USE_ALIMTALK_CHANGE_SUCCESS,
  props<{ payload: { message?: string } }>()
);

export const WaitingsActions = {
  WaitingsLoad,
  WaitingsLoadSuccess,
  WaitingsSave,
  WaitingsSaveValid,
  WaitingsSaveSuccess,
  WaitingsUseChange,
  WaitingsUseSimpleChange,
  WaitingsAlimtalkChange,
  WaitingsAlimtalkChangeSuccess,
  WaitingsUseChangeSuccess,
  WaitingsNoticeChange,
  WaitingsSettingsChange,
  WaitingsChanged,
  WaitingsFail,
  WaitingsLoadAgreed,
  WaitingsLoadAgreedSuccess,
};
