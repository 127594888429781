import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signin-ddocdoc-set',
  templateUrl: './signin-ddocdoc-set.component.html',
  styleUrls: ['./signin-ddocdoc-set.component.scss'],
})
export class SigninDdocdocSetComponent implements OnInit {
  ngOnInit(): void {}
}
