import {
  SymptomCareRoomSelectParameter,
  SymptomCategoryItem,
  SymptomCategoryListQuery,
  SymptomListItem,
  SymptomListQuery,
  SymptomWholeListResult,
} from '~models/select-symptom.model';
import { createAction, props } from '@ngrx/store';

export const SelectSymptomSettingTypes = {
  SYMPTOM_CATEGORY_LOAD: '[Symptom] Symptom Category Load',
  SYMPTOM_CATEGORY_LOAD_SUCCESS: '[Symptom] Symptom Category Load Success',
  SYMPTOM_CATEGORY_LOAD_FAIL: '[Symptom] Symptom Category Load fail',

  SYMPTOM_LIST_LOAD: '[Symptom] Symptom List Load',
  SYMPTOM_LIST_LOAD_SUCCESS: '[Symptom] Symptom List Load Success',
  SYMPTOM_LIST_LOAD_FAIL: '[Symptom] Symptom List Load Fail',

  SYMPTOM_WHOLE_LIST: '[Symptom] Symptom Whole List',
  SYMPTOM_WHOLE_LIST_SUCCESS: '[Symptom] Symptom Whole List Success',
  SYMPTOM_WHOLE_LIST_FAIL: '[Symptom] Symptom Whole List Fail',
} as const;

// 증상 카테고리 목록
export const SelectSymptomCategoryLoad = createAction(
  SelectSymptomSettingTypes.SYMPTOM_CATEGORY_LOAD,
  props<{ payload: { query: SymptomCategoryListQuery } }>()
);

export const SelectSymptomCategorySuccess = createAction(
  SelectSymptomSettingTypes.SYMPTOM_CATEGORY_LOAD_SUCCESS,
  props<{ payload: SymptomCategoryItem[] }>()
);

export const SelectSymptomCategoryFail = createAction(
  SelectSymptomSettingTypes.SYMPTOM_CATEGORY_LOAD_FAIL,
  props<{ payload: any }>()
);

// 증상 목록
export const SelectSymptomListLoad = createAction(
  SelectSymptomSettingTypes.SYMPTOM_LIST_LOAD,
  props<{ payload: { query: SymptomListQuery } }>()
);

export const SelectSymptomListLoadSuccess = createAction(
  SelectSymptomSettingTypes.SYMPTOM_LIST_LOAD_SUCCESS,
  props<{ payload: SymptomListItem[] }>()
);

export const SelectSymptomListLoadFail = createAction(
  SelectSymptomSettingTypes.SYMPTOM_LIST_LOAD_FAIL,
  props<{ payload: any }>()
);

/**
 * 특정 진료실 (CareRoom) 에 대한 진료항목 목록 및 증상 분류 내용 다 가져오기
 */
export const SymptomWholeList = createAction(
  SelectSymptomSettingTypes.SYMPTOM_WHOLE_LIST,
  props<{ payload: SymptomCareRoomSelectParameter }>()
);

export const SymptomWholeListSuccess = createAction(
  SelectSymptomSettingTypes.SYMPTOM_WHOLE_LIST_SUCCESS,
  props<{ payload: SymptomWholeListResult }>()
);

export const SymptomWholeListFail = createAction(
  SelectSymptomSettingTypes.SYMPTOM_WHOLE_LIST_FAIL,
  props<{ payload: any }>()
);

export const SelectSymptomActions = {
  SelectSymptomCategoryLoad,
  SelectSymptomCategorySuccess,
  SelectSymptomCategoryFail,
  SelectSymptomListLoad,
  SelectSymptomListLoadSuccess,
  SelectSymptomListLoadFail,
  SymptomWholeList,
  SymptomWholeListSuccess,
  SymptomWholeListFail,
};
