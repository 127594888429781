export const calcMaxPage = (totalCount, limit) => Math.floor(totalCount / limit) + (totalCount % limit > 0 ? 1 : 0);

export const parseProps = ({ limit, totalCount, page }) => {
  const maxPage = calcMaxPage(totalCount, limit);

  let skip = (page - 1) * limit;
  let iPage = page;
  let disabledPrev = true;
  let disabledNext = false;

  if (skip < 0) {
    skip = 0;
    iPage = 1;
  }

  if (skip + limit > totalCount) {
    iPage = maxPage;
    skip = iPage * limit - limit;
  }

  disabledPrev = skip === 0;

  if (skip + limit >= totalCount) {
    disabledNext = true;
  }

  return {
    page: iPage,
    skip,
    maxPage,
    disabledPrev,
    disabledNext
  };
};

export const handlerOnPrev = (limit, maxPage, pageNum) => {
  let page = pageNum;
  let skip = 0;
  let disabledPrev = false;
  let disabledNext = false;

  page -= 1;
  skip = (page - 1) * limit;

  if (page <= 1) {
    page = 1;
    skip = 0;
  }

  if (page === 1) {
    disabledPrev = true;
  }

  if (page === maxPage) {
    disabledNext = true;
  }

  return {
    skip,
    page,
    maxPage,
    disabledPrev,
    disabledNext
  };
};

export const handlerOnNext = (limit, maxPage, pageNum) => {
  let page = pageNum;
  let skip = 0;
  let disabledPrev = false;
  let disabledNext = false;

  page += 1;
  skip = (page - 1) * limit;

  if (page >= maxPage) {
    page = maxPage;
    skip = maxPage * limit - limit;
  }

  if (page === 1) {
    disabledPrev = true;
  }

  if (page === maxPage) {
    disabledNext = true;
  }

  return {
    skip,
    page,
    maxPage,
    disabledPrev,
    disabledNext
  };
};

export const handlerBtn = (limit, maxPage, pageNum) => {
  let page = pageNum;
  let skip = 0;
  let disabledPrev = false;
  let disabledNext = false;

  skip = (page - 1) * limit;

  if (page >= maxPage) {
    page = maxPage;
    skip = maxPage * limit - limit;
  }

  if (page === 1) {
    disabledPrev = true;
  } else {
    disabledPrev = false;
  }

  if (page === maxPage) {
    disabledNext = true;
  } else {
    disabledNext = false;
  }

  return {
    skip,
    page,
    maxPage,
    disabledPrev,
    disabledNext
  };
};

export const createPages = ({ page, navCount, maxPage }) => {
  const iPage = page;
  const iMaxPage = maxPage;
  const iNavCount = navCount;
  let iStartPage = Math.floor((iPage - 1) / iNavCount) * iNavCount + 1;
  let iEndPage = iStartPage + (iNavCount - 1);
  const aPaging = [];

  if (iMaxPage > iNavCount && iStartPage > iMaxPage) {
    iStartPage -= iNavCount;
  }

  if (iEndPage > iMaxPage) {
    iEndPage = iMaxPage;
  }

  for (let i = iStartPage; i <= iEndPage; i += 1) {
    aPaging.push(i);
  }

  return aPaging;
};
