import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signin-benefit',
  templateUrl: './signin-benefit.component.html',
  styleUrls: ['./signin-benefit.component.scss'],
})
export class SigninBenefitComponent implements OnInit {
  ngOnInit(): void {}
}
