import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, map, filter, tap, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-limited-input-text',
  templateUrl: './limited-input-text.component.html',
  styleUrls: ['./limited-input-text.component.scss']
})
export class LimitedInputTextComponent implements OnInit, OnDestroy {
  @Input() value: string;
  @Input() placeholder = '입력해주세요';
  @Input() name: string;
  @Input() maxLength = 20;
  @Output() inputChange = new EventEmitter<string>();

  sbjInputChange = new Subject<string>();

  constructor() {
    this.sbjInputChange
      .pipe(
        debounceTime(1000),
        map(value => this.cutValueByMaxLength(value)),
        distinctUntilChanged()
      )
      .subscribe(value => this.inputChange.emit(value));
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.sbjInputChange.complete();
    this.sbjInputChange = null;
  }
  cutValueByMaxLength(value: string) {
    if (value.length > this.maxLength) {
      this.value = value.substr(0, this.maxLength);

      return this.value;
    }
    return value;
  }

  handleValueChange(value: string) {
    this.sbjInputChange.next(value);
  }
}
