<ul #optionList class="select-item-list">
  <li
    class="select-item"
    *ngFor="let option of options"
    [ngClass]="{
      disabled: disabled || option.disabled,
      active: option.value === value
    }"
    (click)="handleChange(option)"
  >
    <span>{{ option.name }}</span>
  </li>
</ul>
