<div ngbDropdown class="">
  <button
    type="button"
    class="form-control emr-selector"
    ngbDropdownToggle
    [ngClass]="{
      placeholder: selectedTitle === placeHolder
    }"
  >
    {{ selectedTitle }}
  </button>
  <div ngbDropdownMenu>
    <button
      type="button"
      class="dropdown-item"
      *ngFor="let item of agentTypeList"
      (click)="onItemClick(item)"
    >
      {{ item.agentTypeName }}
    </button>
  </div>
</div>
