<label class="wrap">
  <ng-container *ngIf="!labelOnly">
    <input
      type="checkbox"
      class="chkbox"
      value="1"
      [checked]="checked"
      [disabled]="disabled"
      (change)="handleChange($event)"
    />
    <span class="icon-box"> <i class="icon icon-check-sm"></i> </span>
  </ng-container>
  <span class="chk-label"> <ng-content></ng-content> </span>
</label>
