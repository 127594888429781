<div class="labeled-select-filter">
  <div class="label">{{ label }}</div>
  <div ngbDropdown class="select-filter">
    <button type="button" class="form-control dropdown-button" ngbDropdownToggle>{{ selectedText }}</button>
    <div ngbDropdownMenu>
      <button type="button" class="dropdown-item" *ngFor="let option of options" (click)="handleChange(option)">
        {{ option.text }}
      </button>
    </div>
  </div>
</div>
