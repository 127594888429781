import { Component, OnInit } from '@angular/core';
import { carouselItems } from '../signin-carousel/signin-carousel.constants';

@Component({
  selector: 'app-signin-happy-way',
  templateUrl: './signin-happy-way.component.html',
  styleUrls: ['./signin-happy-way.component.scss'],
})
export class SigninHappyWayComponent implements OnInit {
  tabIdx = 0;
  carouselItems = carouselItems;
  tabItems = ['환자 진료 전', '환자 진료 후', '병원 경영관리'];

  ngOnInit(): void {}

  changeCategory(tabIdx: number): void {
    this.tabIdx = tabIdx;
  }

  changeMobileTabIdx(tabIdx: number): void {
    this.tabIdx = tabIdx;
    document
      .getElementById('scroll-event')
      .scrollIntoView({ behavior: 'smooth' });
  }
}
