import {
  PeaktimeLimitResItem,
  PeaktimeLimitResQuery,
  UnitPeaktimeLimitConfirmResQuery,
  UnitPeaktimeLimitResItem,
  UnitPeaktimeLimitResQuery,
} from '~models/hospital.model';
import { createAction, props } from '@ngrx/store';

export enum PeakTimeLimitTypes {
  PEAKTIME_LIMIT_LOAD = '[PEAKTIME] PeaktimeLimitLoad',
  PEAKTIME_LIMIT_LOAD_SUCCESS = '[PEAKTIME] PeaktimeLimitLoad Success',
  PEAKTIME_LIMIT_LOAD_FAIL = '[PEAKTIME] PeaktimeLimitLoad Fail',

  UNIT_PEAKTIME_LIMIT_LOAD = '[PEAKTIME] UnitPeaktimeLimitLoad',
  UNIT_PEAKTIME_LIMIT_LOAD_SUCCESS = '[PEAKTIME] UnitPeaktimeLimitLoad Success',
  UNIT_PEAKTIME_LIMIT_LOAD_FAIL = '[PEAKTIME] UnitPeaktimeLimitLoad Fail',

  PEAKTIME_LIMIT_UPDATE = '[PEAKTIME] PeaktimeLimitLoad Update',
  PEAKTIME_LIMIT_UPDATE_SUCCESS = '[PEAKTIME] PeaktimeLimitLoad Update Success',
  PEAKTIME_LIMIT_UPDATE_FAIL = '[PEAKTIME] PeaktimeLimitLoad Update Fail',

  UNIT_PEAKTIME_LIMIT_UPDATE = '[PEAKTIME] UnitPeaktimeLimitLoad Update',
  UNIT_PEAKTIME_LIMIT_UPDATE_SUCCESS = '[PEAKTIME] UnitPeaktimeLimitLoad Update Success',
  UNIT_PEAKTIME_LIMIT_UPDATE_FAIL = '[PEAKTIME] UnitPeaktimeLimitLoad Update Fail',

  UNIT_PEAKTIME_LIMIT_SAVE = '[PEAKTIME] UnitPeaktimeLimitLoad Save',
}

// 진료실 피크타임 불러오기

export const PeaktimeLimitLoad = createAction(
  PeakTimeLimitTypes.PEAKTIME_LIMIT_LOAD
);

export const PeaktimeLimitLoadSuccess = createAction(
  PeakTimeLimitTypes.PEAKTIME_LIMIT_LOAD_SUCCESS,
  props<{ payload: PeaktimeLimitResItem[] }>()
);

export const PeaktimeLimitLoadFail = createAction(
  PeakTimeLimitTypes.PEAKTIME_LIMIT_LOAD_FAIL,
  props<{ payload: any }>()
);

// 진료실별 피크타임 불러오기

export const UnitPeaktimeLimitLoad = createAction(
  PeakTimeLimitTypes.UNIT_PEAKTIME_LIMIT_LOAD,
  props<{ payload: string }>()
);

export const UnitPeaktimeLimitLoadSuccess = createAction(
  PeakTimeLimitTypes.UNIT_PEAKTIME_LIMIT_LOAD_SUCCESS,
  props<{ payload: UnitPeaktimeLimitResItem }>()
);

export const UnitPeaktimeLimitLoadFail = createAction(
  PeakTimeLimitTypes.UNIT_PEAKTIME_LIMIT_LOAD_FAIL,
  props<{ payload: any }>()
);

// 피크타임 등록
export const PeaktimeLimitUpdate = createAction(
  PeakTimeLimitTypes.PEAKTIME_LIMIT_UPDATE,
  props<{ payload: PeaktimeLimitResQuery }>()
);

export const PeaktimeLimitUpdateSuccess = createAction(
  PeakTimeLimitTypes.PEAKTIME_LIMIT_UPDATE_SUCCESS,
  props<{ payload: PeaktimeLimitResQuery }>()
);

export const PeaktimeLimitUpdateFail = createAction(
  PeakTimeLimitTypes.PEAKTIME_LIMIT_UPDATE_FAIL,
  props<{ payload: any }>()
);

// 진료실별 피크타임 등록
export const UnitPeaktimeLimitUpdate = createAction(
  PeakTimeLimitTypes.UNIT_PEAKTIME_LIMIT_UPDATE,
  props<{ payload: UnitPeaktimeLimitConfirmResQuery }>()
);

export const UnitPeaktimeLimitUpdateSuccess = createAction(
  PeakTimeLimitTypes.UNIT_PEAKTIME_LIMIT_UPDATE_SUCCESS,
  props<{ payload: UnitPeaktimeLimitResQuery }>()
);

export const UnitPeaktimeLimitUpdateFail = createAction(
  PeakTimeLimitTypes.UNIT_PEAKTIME_LIMIT_UPDATE_FAIL,
  props<{ payload: any }>()
);

export const UnitPeaktimeLimitSave = createAction(
  PeakTimeLimitTypes.UNIT_PEAKTIME_LIMIT_SAVE,
  props<{ payload: UnitPeaktimeLimitResItem }>()
);

export const PeaktimeLimitActions = {
  PeaktimeLimitLoad,
  PeaktimeLimitLoadSuccess,
  PeaktimeLimitLoadFail,
  UnitPeaktimeLimitLoad,
  UnitPeaktimeLimitLoadSuccess,
  UnitPeaktimeLimitLoadFail,
  PeaktimeLimitUpdate,
  PeaktimeLimitUpdateSuccess,
  PeaktimeLimitUpdateFail,
  UnitPeaktimeLimitUpdate,
  UnitPeaktimeLimitUpdateSuccess,
  UnitPeaktimeLimitUpdateFail,
  UnitPeaktimeLimitSave,
};
