import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-keyword-filter',
  templateUrl: './keyword-filter.component.html',
  styleUrls: ['./keyword-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeywordFilterComponent implements OnInit, OnDestroy {
  @Input() placeholder = '검색어를 입력하세요';
  @Input() value = '';
  @Input() id = '';
  // public _value = '';
  // @Input() set value(value: string) {
  //   this._value = value;
  // }
  // get value() {
  //   return this._value;
  // }
  @Output() valueChange = new EventEmitter<string>();
  @Output() debouncedChange = new EventEmitter<string>();

  private sbjChange = new Subject<string>();
  private change$: Subscription;

  constructor() {}

  ngOnInit() {
    this.change$ = this.sbjChange
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
        // tap(value => this._value = value),
      )
      .subscribe(value => this.debouncedChange.emit(value));
  }

  ngOnDestroy() {
    this.change$.unsubscribe();
    this.sbjChange.complete();
  }

  handleChange(value: string) {
    this.valueChange.emit(value);
    this.sbjChange.next(value);
  }
}
