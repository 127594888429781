import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ToggleSwitchComponent),
    },
  ],
})
export class ToggleSwitchComponent implements OnInit, ControlValueAccessor {
  @Input() value = false;
  @Input() on = 'ON';
  @Input() off = 'OFF';
  @Input() disabled = false;
  @Input() debounceTime = 0;

  @Output() toggleChange = new EventEmitter<boolean>();

  timer: number;
  onChanged: (value: boolean) => void;
  onTouched: () => void;

  ngOnInit(): void {}

  onChange(event) {
    event.stopPropagation();
    const checked = event.target.checked;
    this.value = checked;

    if (this.debounceTime) {
      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(
        () => this.toggleChange.emit(checked),
        this.debounceTime
      );
      return;
    }

    this.toggleChange.emit(checked);
    this.onChanged?.(checked);
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
}
