import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-calendar',
  templateUrl: './icon-calendar.component.html',
  styleUrls: [`./icons.scss`]
})
export class IconCalendarComponent {
  @Input() width: number;
  @Input() height: number;
}
