import { HashMap, HttpRes } from './common.model';
import { DropdownModel } from './common';

export enum ChildPhysicalConditions {
  NOT_USE = '사용안함',
  USE_OPTIONAL = '선택',
}

export enum ScheduleKeyEnum {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday',
  lunch = 'lunch',
  dinner = 'dinner',
  holiday = 'holiday',
}

export type ScheduleKeyTypes = keyof typeof ScheduleKeyEnum;

export const WEEKLY_DATA_KEYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
  'holiday',
];

export const WEEKDAY_NAMES = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const WEEKDAY_NAME_MAP = {
  holiday: '공휴일',
  sunday: '일요일',
  saturday: '토요일',
  friday: '금요일',
  thursday: '목요일',
  wednesday: '수요일',
  tuesday: '화요일',
  monday: '월요일',
  dinner: '저녁시간',
  lunch: '점심시간',
};

export interface CareRoomResponse extends HttpRes<CareRoom> {
  agreement: {
    telemedicine: boolean;
  };
}

export interface CareRoom {
  active: boolean; // 진료실 활성화 여부
  key: string; // 진료실 식별 키
  title: string; // 진료실 표시내용
  subject: string; // 대표 진료과목
  memo: string; // 접수 비고
  reservationMemo: string; // 예약 비고
  treatWaiting: number; // 현재 진료 대기자 수
  reserveCountPerHalfHour: number; // 30분당 시간예약 가능 인원
  useReceipt: boolean; // 접수서비스 사용여부
  useReservation: boolean; // 예약 서비스 사용여부
  useKiosk: boolean; // 키오스크 사용여부
  useSelfDesk: boolean; // 셀프 데스크 사용여부
  usePayment: boolean; // 똑닥 간편결제 서비스 사용여부
  useUntact: boolean; // 비대면 결제 서비스 사용여부
  usePhysicalConditions: string; // 아이 상태 입력 사용여부
  useMedicalQuestionnaire: string[];
  useAvgWaitTime?: boolean; // 예상 진료시간 사용여부
  useAllAvgWaitTime?: boolean; // 모든 진료실 예상 진료시간 사용여부
  receiptPause: boolean; // 접수 일시 정지 사용여부
  receiptPauseEndDate?: string; // 접수 일시정지 종료 시간
  receiptPauseMessage: string; // 접수 일시정지 메시지
  receiptPauseOnDate?: string; // 접수 일시정지 시작 시간
  kioskPause: boolean; // 키오스크 일시 정지 사용여부
  kioskPauseMessage: string; // 키오스크 일시정지 메시지
  tabletPause: boolean; // 태블릿 일지 정지 사용여부
  tabletPauseMessage: string; // 태블릿 일시 정지 메시지
  avgWaitTime?: number; // 예상 진료시간(단위: 분)
  weeklyReceiptSchedule: HashMap<OfficeWeeklyDetailData>; // 주간 접수 가능 시간데이터
  weeklyReservationSchedule: HashMap<OfficeWeeklyDetailData>; // 주간 예약 가능 시간데이터
  weeklyTabletSchedule: HashMap<OfficeWeeklyDetailData>; // 무인접수 태블릿 가능 시간데이터
  weeklyKioskSchedule: HashMap<OfficeWeeklyDetailData>; // 무인접수 키오스크 가능 시간데이터
  reservationEnv: ReservationEnvData;
  useCareTag?: boolean; // 키오스크 진료항목 사용여부
  paymentEnv?: {
    receipt: boolean;
    reservation: boolean;
  };
  paperEnv?: {
    receipt: boolean;
    reservation: boolean;
  };
  telemedicineEnv?: TelemedicineEnv;
}

export enum TelemedicineVisitTypeEnum {
  'PHONE' = 'PHONE',
  'VIDEO' = 'VIDEO',
}

export type TelemedicineVisitType = keyof typeof TelemedicineVisitTypeEnum;

export enum TelemedicinePayTypeEnum {
  'TRANSFER' = 'TRANSFER',
  'DDOCDOC' = 'DDOCDOC',
  // ONSITE : 현재 백엔드에서 쓰는 '현장 결제'용 상태 값
  'ONSITE' = 'ONSITE',
}

export type TelemedicinePayType = keyof typeof TelemedicinePayTypeEnum;

export enum TelemedicineBanksEnum {
  '경남은행' = '경남은행',
  '광주은행' = '광주은행',
  '국민은행' = '국민은행',
  '기업은행' = '기업은행',
  '농협' = '농협',
  '대구은행' = '대구은행',
  '부산은행' = '부산은행',
  '산업은행' = '산업은행',
  '상호저축은행' = '상호저축은행',
  '새마을금고' = '새마을금고',
  '수협' = '수협',
  '신한은행' = '신한은행',
  '신용협동조합' = '신용협동조합',
  '시티은행' = '시티은행',
  '외환은행' = '외환은행',
  '우리은행' = '우리은행',
  '우체국' = '우체국',
  '전북은행' = '전북은행',
  '제주은행' = '제주은행',
  '하나은행' = '하나은행',
  'SC제일은행' = 'SC제일은행',
  '도이치은행' = '도이치은행',
  '미쓰비시' = '미쓰비시',
  '아메리카' = '아메리카',
  'HSBC' = 'HSBC',
  '카카오뱅크' = '카카오뱅크',
  '케이뱅크' = '케이뱅크',
  // '' = '',
}

export type TelemedicineBankType = keyof typeof TelemedicineBanksEnum;

export interface TelemedicineEnv {
  receipt: boolean;
  reservation: boolean;
  visitType?: TelemedicineVisitType[];
  payType?: TelemedicinePayType[];
  phone?: string;
  account?: TelemedicineBankAccount;
}

export interface TelemedicineBankAccount {
  bank: TelemedicineBankType;
  number: string;
  holder: string;
}

export interface OfficeWeeklyData {
  holiday: OfficeWeeklyDetailData;
  sunday: OfficeWeeklyDetailData;
  saturday: OfficeWeeklyDetailData;
  friday: OfficeWeeklyDetailData;
  thursday: OfficeWeeklyDetailData;
  wednesday: OfficeWeeklyDetailData;
  tuesday: OfficeWeeklyDetailData;
  monday: OfficeWeeklyDetailData;
}

export interface OfficeWeeklyDetailData {
  isDayOff: boolean;
  isOfflineOff?: boolean;
  hasDinnerBreak: boolean;
  hasLunchBreak: boolean;
  dinner: {
    endTime: string;
    startTime: string;
  };
  lunch: {
    endTime: string;
    startTime: string;
  };
  endTime: string;
  startTime: string;
}

export interface ReservationEnvData {
  useTodayBooking: {
    use: boolean;
    afterMinutes: number;
  };
  currentTimeUnit: number;
  reserveIntervalCount: number;
  maxTerm: number;
  autoCancel: {
    use: boolean;
    delayTime: number;
  };
  avgHoursTreatment: number;
  scheduleOpenTime: string;
}

export interface PeaktimeItem {
  key: string;
  startTime: string;
  endTime: string;
  limit: number;
  settingId: string;
}

export interface UnitPeaktimeItem {
  holiday: UnitPeaktimeDetailData[];
  sunday: UnitPeaktimeDetailData[];
  saturday: UnitPeaktimeDetailData[];
  friday: UnitPeaktimeDetailData[];
  thursday: UnitPeaktimeDetailData[];
  wednesday: UnitPeaktimeDetailData[];
  tuesday: UnitPeaktimeDetailData[];
  monday: UnitPeaktimeDetailData[];
}

export interface UnitPeaktimeDetailData {
  endTime: string;
  limit: number;
  startTime: string;
}

export interface SettingItemModel {
  name: string;
  checked: boolean;
  value: number | string;
  isEditLocked?: boolean;
}

export interface ScheduleSettingItemChangedEventArgs {
  key: string;
  item: OfficeWeeklyDetailData;
}

export interface SettingItemComponentField {
  label: string;
  inputLabelHead: string;
  inputLabelTail: string;
  useCheckbox: boolean;
  name: string;
}

export interface CareRoomScheduleItem {
  enabled: boolean;
  code?: string;
  name: string;
  startTime: Date;
  endTime: Date;
}

export interface CareRoomScheduleData {
  unitKey?: string;
  normal: CareRoomScheduleItem[];
  holiday: CareRoomScheduleItem[];
  mealTime: CareRoomScheduleItem[];
}

export interface CareRoomScheduleForHome {
  use: boolean;
  selectItems: DropdownModel[];
  schedules: HashMap<CareRoomScheduleData>;
}

export interface CareRoomTeleMedicineData {
  receipt: boolean;
  reservation: boolean;
}

export interface AllEmrCareRoomRes {
  _id: string;
  __v: number;
  active: boolean;
  createdAt: string;
  hospital: string;
  units: CareRoom[];
  updatedAt: string;
}
