import { makeNumberArray } from '~shared/service/util';
import { SymptomUnit } from '~models/reservation-timeline.model';

function sortNumber(a: number, b: number): number {
  if (a < b) {
    return -1;
  }

  if (a > b) {
    return 1;
  }

  return 0;
}

export function getColorNoList() {
  return makeNumberArray(1, 12);
}

export function addColorFromSymptomUnit(
  arr: number[],
  unit: SymptomUnit
): number[] {
  const iColorNo = unit ? parseInt(unit.colorNo, 10) : 0;

  if (!iColorNo || arr.indexOf(iColorNo) >= 0) {
    return arr;
  }

  return [...arr, iColorNo].sort(sortNumber);
}

export function removeColorFromSymptomUnit(arr: number[], unit: SymptomUnit) {
  const iColorNo = unit ? parseInt(unit.colorNo, 10) : 0;

  if (!iColorNo || arr.indexOf(iColorNo) < 0) {
    return arr;
  }

  return arr.filter(num => num !== iColorNo);
}

export function removeAllColorFromSymptomUnit(
  arr: number[],
  unit: SymptomUnit[]
) {
  if (unit.length === 0) {
    return arr;
  }

  const mNums: { [num: string]: boolean } = {};

  unit.forEach(u => {
    if (!u || !u.colorNo) {
      return;
    }

    mNums[u.colorNo] = true;
  });
  // .filter(u => u && u.colorNo)
  // .map(u => parseInt(u.colorNo, 10));
  // const aNew = arr.concat(aColorNo);

  // return uniqueArray(aNew).sort(sortNumber);

  return arr.filter(num => !mNums[num]);
}
