import { createAction, props } from '@ngrx/store';
import {
  ExcludePushUserListReqParam,
  ExcludePushUserRes,
  UpdateExcludePushUserReqParams,
} from '~models/hospital-crm.model';

export enum CrmExcludePushUserActionType {
  REQ_FAIL = '[CrmExcludePushUser] Req Fail',
  MODAL_LIST_LOAD = '[CrmExcludePushUser] Modal ExcludePushUser Load',
  MODAL_LIST_LOAD_SUCCESS = '[CrmExcludePushUser] Modal ExcludePushUser Load Success',
  MAIN_LIST_LOAD = '[CrmExcludePushUser] ExcludePushUser Load',
  MAIN_LIST_LOAD_SUCCESS = '[CrmExcludePushUser] ExcludePushUser Load Success',
  UPDATE_REQ = '[CrmExcludePushUser] ExcludePushUser Update',
  UPDATE_REQ_SUCCESS = '[CrmExcludePushUser] ExcludePushUser Update Success',
  DELETE_REQ = '[CrmExcludePushUser] ExcludePushUser Delete',
  DELETE_REQ_SUCCESS = '[CrmExcludePushUser] ExcludePushUser Delete Success',
}

export const CrmExcludePushUserReqFail = createAction(
  CrmExcludePushUserActionType.REQ_FAIL,
  props<any>()
);

// used in Modal --
export const CrmModalExcludePushUserLoad = createAction(
  CrmExcludePushUserActionType.MODAL_LIST_LOAD,
  props<{ payload: ExcludePushUserListReqParam }>()
);

export const CrmModalExcludePushUserLoadSuccess = createAction(
  CrmExcludePushUserActionType.MODAL_LIST_LOAD_SUCCESS,
  props<{ payload: ExcludePushUserRes }>()
);

export const CrmExcludePushUserUpdate = createAction(
  CrmExcludePushUserActionType.UPDATE_REQ,
  props<{
    payload: {
      user: UpdateExcludePushUserReqParams;
      searchParams?: ExcludePushUserListReqParam;
    };
  }>()
);

export const CrmExcludePushUserUpdateSuccess = createAction(
  CrmExcludePushUserActionType.UPDATE_REQ_SUCCESS,
  props<{ payload: ExcludePushUserListReqParam }>()
);

// -- used in Modal

// used in main --
export const CrmExcludePushUserLoad = createAction(
  CrmExcludePushUserActionType.MAIN_LIST_LOAD,
  props<{ payload: ExcludePushUserListReqParam }>()
);

export const CrmExcludePushUserLoadSuccess = createAction(
  CrmExcludePushUserActionType.MAIN_LIST_LOAD_SUCCESS,
  props<{ payload: ExcludePushUserRes }>()
);

export const CrmExcludePushUserDelete = createAction(
  CrmExcludePushUserActionType.DELETE_REQ,
  props<{
    payload: { user: string; searchParams?: ExcludePushUserListReqParam };
  }>()
);

export const CrmExcludePushUserDeleteSuccess = createAction(
  CrmExcludePushUserActionType.DELETE_REQ_SUCCESS,
  props<{ payload: ExcludePushUserListReqParam }>()
);

// -- used in main

export const CrmExcludePushUserActions = {
  CrmExcludePushUserReqFail,
  CrmModalExcludePushUserLoad,
  CrmModalExcludePushUserLoadSuccess,
  CrmExcludePushUserLoad,
  CrmExcludePushUserLoadSuccess,
  CrmExcludePushUserDelete,
  CrmExcludePushUserDeleteSuccess,
  CrmExcludePushUserUpdate,
  CrmExcludePushUserUpdateSuccess,
};
