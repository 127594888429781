import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

function ckCRegNo(value: string): boolean {
  const cRegNo = (value + '').match(/\d{1}/g);

  // 넘어온 값의 정수만 추츨하여 문자열의 배열로 만들고 10자리 숫자인지 확인합니다.
  if (!cRegNo || cRegNo.length !== 10) {
    return false;
  }
  // 합 / 체크키
  let sum = 0;
  const key = [1, 3, 7, 1, 3, 7, 1, 3, 5];
  // 0 ~ 8 까지 9개의 숫자를 체크키와 곱하여 합에더합니다.
  for (let i = 0; i < 9; i++) {
    sum += key[i] * Number(cRegNo[i]);
  }
  // 각 8번배열의 값을 곱한 후 10으로 나누고 내림하여 기존 합에 더합니다.
  // 다시 10의 나머지를 구한후 그 값을 10에서 빼면 이것이 검증번호 이며 기존 검증번호와 비교하면됩니다.

  const checkNum =
    (10 - ((sum + Math.floor((key[8] * Number(cRegNo[8])) / 10)) % 10)) % 10;

  return checkNum === Number(cRegNo[9]);
}

@Injectable()
export class ValidatorService {
  constructor() {}

  cRegNo(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value == null) {
        return null;
      }

      return ckCRegNo(control.value) ? null : { validCRegNo: false };
      // return control.value && control.value.length === 12 ? null : { validCRegNo: false };
    };
  }
}
