<div class="ha-card ha-card-radius">
  <div class="ha-card-header">
    <h4 class="ha-card-title" [innerHTML]="title"></h4>
  </div>
  <div class="ha-card-body table-group">
    <table class="ha-table ha-table-bordered">
      <tr *ngFor="let key of dayGroup">
        <th [ngClass]="dayClass(key)">{{ key | dayTransform }}</th>
        <td>
          <template
            *ngIf="
              itemGroup[key]?.startTime || itemGroup[key]?.isDayOff;
              then open;
              else close
            "
          ></template>
          <ng-template #open>
            {{ itemGroup[key].startTime | apmFormat }} ~
            <span>{{ itemGroup[key].endTime | apmFormat }}</span>
            <span class="ha-badge ha-badge-soft-blue" *ngIf="false"></span>
          </ng-template>
          <ng-template #close>
            <span class="close-span">{{ offLabel(key) }}</span>
          </ng-template>
        </td>
      </tr>
    </table>
  </div>
</div>
