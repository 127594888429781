import { createAction, props } from '@ngrx/store';

export enum CancelMessageTypes {
  CANCEL_MSG_LOAD = '[CancelMsg] Msg Load',
  CANCEL_MSG_LOAD_SUCCESS = '[CancelMsg] Msg Load Success',
  CANCEL_MSG_LOAD_FAIL = '[CancelMsg] Msg Load Fail',

  CANCEL_MSG_UPDATE = '[CancelMsg] Msg Update',
  CANCEL_MSG_UPDATE_SUCCESS = '[CancelMsg] Msg Update Success',
  CANCEL_MSG_UPDATE_FAIL = '[CancelMsg] Msg Update Fail',
}

// 취소 메시지 불러오기
export const CancelMsgLoad = createAction(
  CancelMessageTypes.CANCEL_MSG_LOAD,
  props<{ payload: { type: string } }>()
);

export const CancelMsgLoadSuccess = createAction(
  CancelMessageTypes.CANCEL_MSG_LOAD_SUCCESS,
  props<{ payload: { msg: string[] } }>()
);

export const CancelMsgLoadFail = createAction(
  CancelMessageTypes.CANCEL_MSG_LOAD_FAIL,
  props<{ payload: any }>()
);

// 취소 메시지 업데이트
export const CancelMsgUpdate = createAction(
  CancelMessageTypes.CANCEL_MSG_UPDATE,
  props<{ payload: { type: string; update: string[] } }>()
);

export const CancelMsgUpdateSuccess = createAction(
  CancelMessageTypes.CANCEL_MSG_UPDATE_SUCCESS,
  props<{ payload: string[] }>()
);

export const CancelMsgUpdateFail = createAction(
  CancelMessageTypes.CANCEL_MSG_UPDATE_FAIL,
  props<{ payload: any }>()
);

export const CancelMsgActions = {
  CancelMsgLoad,
  CancelMsgLoadSuccess,
  CancelMsgLoadFail,
  CancelMsgUpdate,
  CancelMsgUpdateSuccess,
  CancelMsgUpdateFail,
};
