import { FindUserInfo } from '~models/auth.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-find-id',
  templateUrl: './findId.component.html',
  styleUrls: ['./findId.component.scss'],
})
export class FindIdComponent {
  @Input() error: string | null = null;
  @Output() submitted = new EventEmitter<FindUserInfo>();

  findIdForm: FormGroup;

  validateEmail =
    '^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$';
  validateynum = '[0-9]+$';

  constructor(private fb: FormBuilder, private location: Location) {
    this.findIdForm = this.fb.group({
      name: [null, Validators.required],
      email: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(this.validateEmail),
        ]),
      ],
      ynum: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(this.validateynum),
        ]),
      ],
      hospital: [null, Validators.required],
    });
  }

  back(): void {
    this.location.back();
  }

  getHospitalInfo(item: any): void {
    this.findIdForm.controls.hospital.setValue(item._id);
  }

  findId(): void {
    if (this.findIdForm.valid) {
      this.submitted.emit(this.findIdForm.value);
    }
  }
}
