import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CareRoom } from '~models/care-room.model';
import {
  CareRoomActions,
  CareRoomScheduleImport,
} from '~actions/products/care-room.actions';
import { errorMessage } from '~shared/service/util';
import { createReducer, on } from '@ngrx/store';
import { isNil as _isNil } from 'lodash';

export interface CareRoomState extends EntityState<CareRoom> {
  selectedKey: string | null;
  loading: boolean;
  error: string | null;
  loaded: boolean;
  agreement: {
    telemedicine: boolean;
  };
  isAllPaused: boolean;
}

export const careRoomAdapter: EntityAdapter<CareRoom> =
  createEntityAdapter<CareRoom>({
    selectId: (careRoom: CareRoom) => careRoom.key,
  });

const initialState: CareRoomState = careRoomAdapter.getInitialState({
  selectedKey: null,
  loading: false,
  error: null,
  loaded: false,
  agreement: {
    telemedicine: false,
  },
  isAllPaused: false,
});

export const reducer = createReducer(
  initialState,
  on(CareRoomActions.FetchCareRoom, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(CareRoomActions.FetchCareRoomComplete, (state, action) => {
    const { careRooms } = action.payload;
    const keyEncodedCareRooms = careRooms.map(careRoom => {
      return {
        ...careRoom,
        key: encodeURIComponent(careRoom.key),
      };
    });

    return careRoomAdapter.setAll(keyEncodedCareRooms, {
      ...state,
      selectedKey: state.selectedKey,
      loading: false,
      loaded: true,
      error: null,
    });
  }),
  on(CareRoomActions.SetAgreementInfo, (state, action) => ({
    ...state,
    agreement: action.payload,
  })),
  on(CareRoomActions.FetchCareRoomFail, (state, action) => ({
    ...state,
    loading: false,
    error: errorMessage(action.payload, 'care-room.red51'),
  })),
  on(
    CareRoomActions.CareRoomUpdate,
    CareRoomScheduleImport,
    (state, action) => {
      return careRoomAdapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload.changes,
        },
        {
          ...state,
          selectedKey: state.selectedKey,
          loading: true,
          error: null,
        }
      );
    }
  ),
  on(CareRoomActions.CareRoomUpdateLazy, (state, action) => {
    return careRoomAdapter.updateOne(
      {
        id: action.payload.id,
        changes: action.payload.changes,
      },
      {
        ...state,
        selectedKey: state.selectedKey,
        loading: false,
        error: null,
      }
    );
  }),
  on(CareRoomActions.CareRoomActive, (state, action) => {
    const {
      payload: { active, unitKey },
    } = action;
    return careRoomAdapter.updateOne(
      {
        id: unitKey,
        changes: { active },
      },
      { ...state }
    );
  }),
  on(CareRoomActions.CareRoomUpdateComplete, state => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(CareRoomActions.CareRoomStopComplete, (state, action) => {
    const { receiptPause, receiptPauseEndDate, receiptPauseMessage } =
      action.payload.data;
    return careRoomAdapter.updateOne(
      {
        id: action.payload.unit,
        changes: {
          ...(!_isNil(receiptPause) && { receiptPause }),
          ...(!_isNil(receiptPauseEndDate) && { receiptPauseEndDate }),
          receiptPauseMessage,
        },
      },
      {
        ...state,
      }
    );
  }),
  on(CareRoomActions.CareRoomUpdateFail, (state, action) => ({
    ...state,
    loading: false,
    error: errorMessage(action.payload, 'care-room.red65'),
  })),
  on(CareRoomActions.CareRoomAllStop, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(CareRoomActions.CareRoomAllStopComplete, state => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(CareRoomActions.CareRoomAllStopFail, (state, action) => ({
    ...state,
    loading: false,
    error: errorMessage(action.payload, 'care-room.red87'),
  })),
  on(CareRoomActions.CareRoomSelect, (state, action) => ({
    ...state,
    selectedKey: action.payload.key,
    loading: true,
    error: null,
  })),
  on(CareRoomActions.CareRoomMultipleUpdate, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(CareRoomActions.CareRoomMultipleUpdateComplete, state => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(CareRoomActions.CareRoomMultipleUpdateFail, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.error.error,
  })),
  on(CareRoomActions.CareRoomAgreeTelemedicine, state => ({
    ...state,
  })),
  on(CareRoomActions.CareRoomAgreeTelemedicineSuccess, state => ({
    ...state,
    agreement: {
      ...state.agreement,
      telemedicine: true,
    },
  }))
);

export const getCareRoomsError = (state: CareRoomState) => state.error;
export const getSelectedKey = (state: CareRoomState) => state.selectedKey;
export const getCareRoomLoaded = (state: CareRoomState) => state.loaded;
export const getCareRoomLoading = (state: CareRoomState) => state.loading;
export const getCareRoomAgreementInfo = (state: CareRoomState) =>
  state.agreement;
