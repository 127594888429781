import { FindIdActions } from '~actions/auth/find-id.action';
import { errorMessage } from '~shared/service/util';
import { createReducer, on } from '@ngrx/store';

export interface FindIdState {
  userId: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: FindIdState = {
  userId: null,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(FindIdActions.FindId, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(FindIdActions.FindIdComplete, (state, action) => ({
    userId: action.payload,
    loading: false,
    error: null,
  })),
  on(FindIdActions.FindIdFailure, (state, action) => ({
    userId: null,
    loading: false,
    error: errorMessage(action.payload, 'findid.red39'),
  }))
);

export const getError = (state: FindIdState) => state.error;
export const getUserId = (state: FindIdState) => state.userId;
export const getLoading = (state: FindIdState) => state.loading;
