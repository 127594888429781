import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import {
  routerReducer,
  RouterReducerState,
  RouterStateUrl,
} from './core/router.reducer';

export interface State {
  router: RouterReducerState<RouterStateUrl>;
}

export const reducers: ActionReducerMap<State> = {
  router: routerReducer,
};

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [debug];
