import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoadingModule } from 'ngx-loading';
import { SharedModule } from '~shared/shared.module';
import { EmrTypeComponent } from './emtType/emrType.component';
import { FindIdComponent } from './findId/findId.component';
import { FindPasswordComponent } from './findPassword/findPassword.component';
import { ResetPasswordCompleteComponent } from './reset-password-complete/reset-password-complete.component';
import { ResetPasswordComponent } from './resetPassword/resetPassword.component';
import { SignUpComponent } from './signup/signup.component';
import { CategoryTypeComponent } from './category-type/category-type.component';
import { AgencyTypeComponent } from './agency-type/agency-type.component';
import { TransformToTimePipe } from '../pipes/transform-to-time.pipe';

const Components = [
  FindIdComponent,
  FindPasswordComponent,
  ResetPasswordComponent,
  SignUpComponent,
  EmrTypeComponent,
  CategoryTypeComponent,
  ResetPasswordCompleteComponent,
  AgencyTypeComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingModule,
    SharedModule,
    RouterModule,
  ],
  declarations: [...Components, TransformToTimePipe],
  exports: [...Components],
})
export class AuthComponentsModule {}
