import { createReducer, on } from '@ngrx/store';
import type { Moment } from 'moment';
import * as moment from 'moment';
import { PaymentsActions } from '~actions/products/payments.actions';
import type {
  PaymentsLogFetchRes,
  PaymentsSetting,
  TossErrorKey,
} from '~models/payments.model';

export interface PaymentsState {
  loaded: boolean;
  isLoadedRegister: boolean;
  isLoadedUpdateSetting: boolean;
  isLoadedUpdateActivationDate: boolean;
  paymentSetting?: PaymentsSetting;
  bankCodes: string[];
  logs: PaymentsLogFetchRes;
  registerErrorKey?: TossErrorKey;
  defaultAvailableDate: Moment;
}

const initialState: PaymentsState = {
  loaded: false,
  isLoadedRegister: false,
  isLoadedUpdateSetting: false,
  isLoadedUpdateActivationDate: false,
  bankCodes: [],
  logs: {
    count: 0,
    items: [],
    limit: 10,
    skip: 0,
    totalCount: 0,
  },
  defaultAvailableDate: moment().isAfter(moment('2023-05-17'))
    ? moment().startOf('day')
    : moment('2023-05-17'),
};

export const paymentsReducer = createReducer(
  initialState,
  on(
    PaymentsActions.PaymentsErrorKeyReset,
    PaymentsActions.PaymentsSettingFetch,
    PaymentsActions.PaymentsLogsFetch,
    state => {
      return {
        ...state,
        loaded: false,
        isLoadedRegister: false,
        isLoadedUpdateSetting: false,
        isLoadedUpdateActivationDate: false,
        registerErrorKey: undefined,
      };
    }
  ),
  on(PaymentsActions.PaymentsRegister, state => {
    return {
      ...state,
      loaded: false,
      isLoadedRegister: false,
    };
  }),
  on(
    PaymentsActions.PaymentsRegisterSuccess,
    (state, { res: paymentSetting }) => {
      return {
        ...state,
        paymentSetting,
        loaded: true,
        isLoadedRegister: true,
        registerErrorKey: undefined,
      };
    }
  ),
  on(PaymentsActions.PaymentsRegisterFail, (state, { errorKey }) => {
    return {
      ...state,
      loaded: true,
      isLoadedRegister: true,
      registerErrorKey: errorKey,
    };
  }),
  on(
    PaymentsActions.PaymentsSettingFetchSuccess,
    (state, { res: paymentSetting }) => {
      return {
        ...state,
        loaded: true,
        paymentSetting: paymentSetting ?? undefined,
      };
    }
  ),
  on(PaymentsActions.PaymentsSettingUpdate, state => {
    return {
      ...state,
      loaded: false,
      isLoadedUpdateSetting: false,
    };
  }),
  on(
    PaymentsActions.PaymentsSettingUpdateSuccess,
    (state, { res: paymentSetting }) => {
      return {
        ...state,
        paymentSetting,
        loaded: true,
        isLoadedUpdateSetting: true,
        registerErrorKey: undefined,
      };
    }
  ),
  on(PaymentsActions.PaymentsSettingUpdateFail, (state, { errorKey }) => {
    return {
      ...state,
      loaded: true,
      isLoadedUpdateSetting: true,
      registerErrorKey: errorKey,
    };
  }),
  on(PaymentsActions.PaymentsUseUpdateSuccess, (state, { updater }) => {
    return {
      ...state,
      paymentSetting: {
        ...state.paymentSetting,
        active: updater.use,
      },
      loaded: true,
    };
  }),
  on(PaymentsActions.PaymentsActivationDateUpdate, state => {
    return {
      ...state,
      loaded: false,
      isLoadedUpdateActivationDate: false,
    };
  }),
  on(
    PaymentsActions.PaymentsActivationDateUpdateSuccess,
    (state, { updater: { activationDate } }) => {
      return {
        ...state,
        paymentSetting: {
          ...state.paymentSetting,
          activationDate,
        },
        loaded: true,
        isLoadedUpdateActivationDate: true,
        registerErrorKey: undefined,
      };
    }
  ),
  on(
    PaymentsActions.PaymentsActivationDateUpdateFail,
    (state, { errorKey }) => {
      return {
        ...state,
        loaded: true,
        isLoadedUpdateActivationDate: true,
        registerErrorKey: errorKey,
      };
    }
  ),
  on(PaymentsActions.PaymentsLogsFetchSuccess, (state, { res: logs }) => {
    return {
      ...state,
      loaded: true,
      logs,
    };
  }),
  on(PaymentsActions.PaymentsFilterValuesFetchSuccess, (state, { res }) => {
    return {
      ...state,
      loaded: true,
      bankCodes: res.bankCode,
    };
  })
);
