import { createReducer, on } from '@ngrx/store';
import {
  HolidaysInfo,
  OperateTime,
  PasteMonthInfo,
  ReservationScheduleV2ResModel,
  Schedule,
  SymptomCategoryInfo,
  SymptomUnit,
} from '~models/reservation-schedule-v2.model';
import { ReservationScheduleCopyActions } from '~actions/products/reservation-schedule-copy-v2.actions';
import { keyBy as _keyBy } from 'lodash';

export interface ReservationScheduleCopyState {
  id: string;
  loading: boolean;
  message: string;
  error: string;
  symptomListData: SymptomUnit[];
  editorSchedules: Schedule[];
  operateTime?: OperateTime;
  holidaysInfo: HolidaysInfo;
  categoryInfo: SymptomCategoryInfo;
  // year: number;
  // month: number;
  // tags: SchedulerTags;
  pasteMonthInfo: PasteMonthInfo;
  multiCopyTargetSchedules: ReservationScheduleV2ResModel[];
  multiHolidayInfos: HolidaysInfo;
  multiPasteMonthInfos: PasteMonthInfo[];
}

const initialState: ReservationScheduleCopyState = {
  id: '',
  loading: false,
  message: '',
  error: null,
  symptomListData: [],
  editorSchedules: [],
  holidaysInfo: null,
  categoryInfo: {},
  // year: moment().year(),
  // month: moment().month() + 1,
  // tags: null,
  pasteMonthInfo: null,
  multiCopyTargetSchedules: [],
  multiHolidayInfos: null,
  multiPasteMonthInfos: [],
};

export const reducer = createReducer(
  initialState,
  on(
    ReservationScheduleCopyActions.FetchOperateTime,
    ReservationScheduleCopyActions.FetchHolidays,
    // ReservationScheduleCopyActions.UpdateTime,
    ReservationScheduleCopyActions.UpdateMultipleSchedules,
    ReservationScheduleCopyActions.FetchExistsScheduleMonth,
    state => ({
      ...state,
      loading: true,
      message: '',
    })
  ),
  on(ReservationScheduleCopyActions.FetchReservationSchedule, state => ({
    ...state,
    loading: true,
    message: '',
    editorSchedules: [],
  })),
  on(ReservationScheduleCopyActions.FetchCategoryList, state => ({
    ...state,
    loading: true,
    message: '',
    symptomListData: [],
  })),
  on(
    ReservationScheduleCopyActions.ReservationScheduleFail,
    (state, action) => ({
      ...state,
      loading: false,
      message: action.message,
    })
  ),
  on(
    ReservationScheduleCopyActions.FetchReservationScheduleSuccess,
    (state, action) => ({
      ...state,
      loading: false,
      message: '',
      id: action.payload._id,
      version: action.payload.version,
      symptomListData: action.payload.preset.symptomUnit,
      editorSchedules: action.payload.editorSchedules,
      // tags: action.payload.tags,
    })
  ),
  on(
    ReservationScheduleCopyActions.FetchMultiReservationScheduleSuccess,
    (state, action) => {
      return {
        ...state,
        multiCopyTargetSchedules: action.payload,
      };
    }
  ),
  on(
    ReservationScheduleCopyActions.FetchCategoryListSuccess,
    (state, action) => ({
      ...state,
      categoryInfo: _keyBy(action.payload.items, '_id'),
    })
  ),
  on(
    ReservationScheduleCopyActions.FetchOperateTimeSuccess,
    (state, action) => ({
      ...state,
      loading: false,
      operateTime: action.payload.operateTime,
    })
  ),
  on(ReservationScheduleCopyActions.FetchHolidaysSuccess, (state, action) => ({
    ...state,
    loading: false,
    holidaysInfo: action.payload,
  })),
  on(
    ReservationScheduleCopyActions.FetchMultiHolidaysSuccess,
    (state, action) => ({
      ...state,
      loading: false,
      multiHolidayInfos: action.payload,
    })
  ),
  // on(ReservationScheduleCopyActions.UpdateTimeSuccess, (state, action) => ({
  //   ...state,
  //   loading: false,
  //   year: action.payload.year,
  //   month: action.payload.month,
  // }))
  on(
    ReservationScheduleCopyActions.FetchExistsScheduleMonthSuccess,
    (state, action) => ({
      ...state,
      loading: false,
      pasteMonthInfo: action.pasteMonthInfo,
    })
  ),
  on(
    ReservationScheduleCopyActions.FetchMultiExistsScheduleMonthSuccess,
    (state, action) => {
      return {
        ...state,
        loading: false,
        multiPasteMonthInfos: action.pasteMonthInfos,
      };
    }
  )
);
