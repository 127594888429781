import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { AuthService } from '~core/api/auth.service';
import { SimpleModalService } from '~shared/service/simple.modal.service';
import { CommonModalService } from '~root/app/shared-new/services/common.modal.service';
import { Subject, throwError } from 'rxjs';

function initStatesChecked(): boolean[] {
  return [false, false, false, false];
}

interface TermsProps {
  label: string;
  isNecessary: boolean;
  src: string;
  href: string;
}

export interface SignupTermValues {
  ynum: string;
  marketingAgreement: boolean;
  // isGovUntactAgreed: boolean;
}

@Component({
  selector: 'app-signup-terms',
  templateUrl: './signup-terms.component.html',
  styleUrls: ['./signup-terms.component.scss'],
})
export class SignupTermsComponent implements OnInit, OnDestroy {
  @Output() nextStep = new EventEmitter<SignupTermValues>();

  // isGovUntactAgreed = false;

  statesChecked: boolean[] = initStatesChecked();
  statesCheckedAll = false;
  terms: TermsProps[] = [
    {
      label: '서비스 이용약관',
      isNecessary: true,
      src: 'https://ddocdoc.com/terms/hospital_service',
      href: 'https://ddocdoc.com/terms/hospital_service',
    },
    {
      label: '개인정보 처리방침',
      isNecessary: true,
      src: 'https://ddocdoc.com/terms/hospital_privacy',
      href: 'https://ddocdoc.com/terms/hospital_privacy',
    },
    {
      label: '제3자 정보 제공 동의',
      isNecessary: true,
      src: 'https://ddocdoc.com/terms/hospital_third',
      href: 'https://ddocdoc.com/terms/hospital_third',
    },
    {
      label: '마케팅 활용 동의',
      isNecessary: false,
      src: 'https://ddocdoc.com/terms/hospital_marketing',
      href: 'https://ddocdoc.com/terms/hospital_marketing',
    },
  ];
  ynum = '';
  isActiveYnumBtn = false;
  isNext = false;

  /** Life cycle */
  onDestroy = new Subject<void>();

  get isActiveNext(): boolean {
    const isAgreed =
      this.isNext &&
      this.statesChecked[0] &&
      this.statesChecked[1] &&
      this.statesChecked[2];

    return isAgreed;
  }

  constructor(
    private authService: AuthService,
    private simpleModalService: SimpleModalService,
    private route: ActivatedRoute,
    private commonModalService: CommonModalService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.checkYnumInQuery();
  }

  checkYnumInQuery(): void {
    this.route.queryParamMap
      .pipe(
        map(paramMap => paramMap.get('ynum')),
        take(1)
      )
      .subscribe(ynum => {
        if (ynum) {
          // history.replaceState({}, null, location.pathname);
          this.ynum = ynum;
          this.onChangeYnum(this.ynum);
        }
      });
  }

  onCheckedChange(event: any, index: number): void {
    event.stopPropagation();
    this.statesChecked[index] = event.target.checked as boolean;

    this.statesCheckedAll = this.statesChecked.reduce((a, c) => {
      return a && c;
    }, true);
  }

  onCheckChangeAll(event: any): void {
    event.stopPropagation();
    const value = event.target.checked as boolean;
    this.statesChecked = this.statesChecked.map(i => (i = value));
  }

  onChangeYnum(event: any): void {
    this.isActiveYnumBtn = event.length === 8;
    this.isNext = false;
  }

  onCheckYnum(): void {
    const ynum = this.ynum.replace('-', '');
    if (this.validateYnum(ynum)) {
      this.authService
        .checkYnum(ynum)
        .pipe(
          switchMap(i => {
            if (i.isExist) {
              return this.commonModalService.confirm(
                '<b>이미 가입된 요양기관 번호입니다.</b></br>아이디 찾기 후 로그인해주세요.',
                {
                  negativeText: '닫기',
                  positiveText: '아이디찾기',
                }
              );
            }
            return throwError('가입 가능한 요양기관 번호입니다.');
          }),
          takeUntil(this.onDestroy)
        )
        .subscribe(
          result => {
            if (result) {
              this.router.navigate(['/find-id']);
            }
          },
          error => {
            this.simpleModalService.alert(error);
            this.isNext = true;
          }
        );
    } else {
      this.simpleModalService.alert('유효하지 않은 요양기관 번호입니다.');
    }
  }

  validateYnum(ynum: string): boolean {
    const validateynum = /^(\s|\d)+$/; // 요양기관번호는 숫자만 들어간다. 이를 확인하기위한 정규식
    const areaCode = [
      '11',
      '12',
      '13',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '31',
      '32',
      '33',
      '34',
      '35',
      '36',
      '37',
      '38',
      '39',
      '41',
    ]; // 요양기관번호 지역별 코드

    /**
     * 요양기관번호는 8자리
     */
    if (ynum.length !== 8) {
      return false;
    }

    if (!validateynum.test(ynum)) {
      return false;
    }

    return areaCode.includes(ynum.slice(0, 2));
  }

  onClickNext(): void {
    this.nextStep.emit({
      ynum: this.ynum,
      marketingAgreement: this.statesChecked[3],
      // isGovUntactAgreed: this.isGovUntactAgreed,
    });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
