<div ngbDropdown class="">
  <button
    type="button"
    class="form-control category-selector"
    ngbDropdownToggle
    [ngClass]="{
      placeholder: selectedTitle === placeHolder
    }"
  >
    {{ selectedTitle }}
  </button>
  <div class="dropdown-menu" ngbDropdownMenu>
    <button
      type="button"
      class="dropdown-item"
      *ngFor="let item of categoryTypeList$ | async"
      (click)="onItemClick(item)"
    >
      {{ item.name }}
    </button>
  </div>
</div>
