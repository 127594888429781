import { Injectable } from '@angular/core';
import { merge, Observable, Subject, timer } from 'rxjs';
import { map } from 'rxjs/operators';

declare var Cookies: any;

const TOKEN_KEY = 'ddocdoc_token';

@Injectable()
export class TokenProvider {
  private token: string | null;
  private sbjExist = new Subject<boolean>();

  set(val: string) {
    Cookies.set(TOKEN_KEY, val, { expires: 86400 });
    this.token = val;
    this.sbjExist.next(!!val);
  }

  setByHours(val: string, hours: number) {
    Cookies.set(TOKEN_KEY, val, {
      expires: new Date(new Date().getTime() + 60 * 60 * 1000 * hours),
    });
    this.token = val;
    this.sbjExist.next(!!val);
  }

  get() {
    return this.token;
  }

  exist(): Observable<boolean> {
    if (this.token) {
      return new Observable(sub => {
        sub.next(true);
        sub.complete();
      });
    }
    return merge(timer(1000), this.sbjExist).pipe(
      map<any, any>(([timing, isExistSbj]) => {
        if (!isExistSbj) {
          throw new Error('token getting timeout');
        }

        return isExistSbj;
      })
    );
  }

  clear() {
    Cookies.remove(TOKEN_KEY);
    this.token = null;
    // this.sbjExist.next(false);
  }

  constructor() {
    this.token = Cookies.get(TOKEN_KEY);
  }
}
