export interface AuthLoginInfo {
  emrAuthToken?: string;
  ddocdocAdminAuthToken?: string;
  userId?: string;
  userPassword?: string;
}

export interface LoginSuccessInfo {
  isEmrAuth: boolean;
  authType?: AuthTypeEnum;
  userId: string;
  userName: string;
  hospitalToken: string;
  partnerGroup?: {
    _id: string;
    name: string;
    active: boolean;
    hospitals: Array<string>;
  };
}

export enum ServiceTypeEnum {
  RECEIPT, // 접수
  RESERVATION, // 예약
  WAITINGS, // 대기현황판
  SELF_DESK, // 무인접수
  EXAMINATION_INFANTS, // 영유아검진
  KIOSK, // 키오스크
  PAYMENT, // 모바일결제
  TELEMEDICINE, // 전화상담진료(원격진료)
}

export type ServiceTypeKeys = keyof typeof ServiceTypeEnum;

export const serviceTypeMapper = {
  RECEIPT: 'RECEIPT',
  RESERVATION: 'RESERVATION',
  SELF_DESK: 'SELF_DESK',
  KIOSK: 'KIOSK',
  PAYMENT: 'PAYMENT',
  TELEMEDICINE: 'TELEMEDICINE',
};

/**
 * 법정동 코드에 의한 지역 정보
 */
export interface Region {
  updatedAt: string;
  createdAt: string;
  x?: number;
  y?: number;
  /**
   * 리명
   */
  region4Depth?: string;
  /**
   * 읍면동명
   */
  region3Depth?: string;
  /**
   * 시군구명
   */
  region2Depth?: string;
  /**
   * 시도명
   */
  region1Depth?: string;
  /**
   * 법정동 코드 앞 5자리(시도+시군구)
   */
  bCodePrefix?: string;
  /**
   * 법정동 주소
   */
  address?: string;
  /**
   * 법정동 코드 주소
   */
  bCode?: string;
}

export interface HospitalInfo {
  _id: string;
  title: string;
  agentType?: string;
  agentTypeName?: string;
  serviceType: string[];
  ynum?: string;
  bCode: string;
  region?: Region;
  category?: string;
  categoryName?: string;
  /**
   * 영업교육 확인서 노출 대상
   */
  isConfirmationTarget?: boolean;
  /**
   * 문자 서비스 노출 대상
   */
  isCrmSmsTarget?: boolean;
  /**
   * 키오스크 정산 관리 버튼 사용 여부
   * VAN사가 KSNET 인 승인된 계약서 존재
   */
  useKioskSettlement?: boolean;
  /**
   * 병원 긴급 질병 대응(2020.1월 기준 신종 코로나) 사용 여부
   */
  useEmergency?: boolean;
  reservationVersion: string;
}

/**
 * @desc 계정정보
 * @docs https://boostbros.atlassian.net/wiki/spaces/BACKEND/pages/718176286/-
 * @docs https://boostlab-docs.s3.ap-northeast-2.amazonaws.com/internal/charmander/index.html#operation/%EB%82%B4%20%EC%A0%95%EB%B3%B4%20%EC%A1%B0%ED%9A%8C
 */
export interface AccountInfo {
  _id: string;
  hasAccount: boolean;
  /**
   * 병원어드민 계정 승인 상태
   *
   * 똑닥어드민 및 직접로그인인 경우 CONFIRM
   *
   * 그 외에는 상태 검사
   * 신청 이력 없음: NOT_SIGNED
   * 승인 계정 있음: CONFIRM
   * 그 외: APPLY
   */
  accountState: 'CONFIRM' | 'NOT_SIGNED' | 'APPLY';
  hasAgent: boolean;
  useDdocdocService: boolean;
  agentType: string;
  agentTypeName?: string;
  setupId?: string;
  hospital: HospitalInfo;
  /**
   * Deprecated
   */
  // role: string;
  approvalStatus: string;
  active: boolean;
  accessedAt: string;
  updatedAt: string;
  createdAt: string;
  isEmrAuth: boolean;
  authType: string;
  /**
   * 사업자 등록번호
   */
  cRegNo?: string;
  thirdPartyAgreementDate?: string;
  /**
   * 2021 정부 화상진료 참여 병원 여부
   */
  isGovUntactAgreed?: boolean;
  /**
   * ubcare의 경우 kiosk 메뉴 노출 여부를 serviceType으로 판단하기 어려워 생긴 임시 field
   */
  showKioskMenu: boolean;
  /**
   * 고객의 소리 메뉴 노출 여부
   */
  showReviewMenu: boolean;
  /**
   * 계정 전화번호
   */
  telReal?: string;
  userPhone?: string;
  userName?: string;
}

export interface ProfileInfo {
  hospitalName: string;
  userId: string;
  cRegNo: string;
  userName: string;
  userPhone: string;
  userEmail: string;
  marketingAgreement: boolean;
  isAgreed: boolean;
}

/**
 * 계정 생성 모델
 * https://boostbros.atlassian.net/wiki/spaces/BACKEND/pages/717127748/-+v2
 */
export interface CreateInfo {
  /**
   * 아이디
   */
  userId?: string;
  /**
   * 비밀번호
   */
  userPassword?: string;
  /**
   * 사용자 이름
   */
  userName?: string;
  /**
   * 사용지 휴대폰번호
   */
  userPhone?: string;
  /**
   * 사용자 이메일
   */
  userEmail?: string;
  /**
   * 병원 고유 ID
   */
  hospital?: string;
  /**
   * 요양기관번호
   */
  ynum?: string;
  /**
   * EMR 차트사 타입
   */
  agentType?: string;
  /**
   * EMR 차트사 명
   */
  agentTypeName?: string;
  /**
   * 대리점 명
   */
  agency?: string;
  /**
   * 대리점 담당자명
   */
  agencyManager?: string;
  /**
   * 대표 진료과목
   */
  mainCategory?: any;
  /**
   * 병원 주소
   * road: 도로명
   * detail: 상세주소
   */
  address?: {
    /**
     * 도로명 주소
     */
    road?: string;
    /**
     * 상세 주소
     */
    detail?: string;
  };
  /**
   * 병원전화번호
   */
  telReal?: string;
  /**
   * 사업자등록번호
   */
  cRegNo?: string;
  /**
   * 문자인증 후 받은 토큰
   */
  token?: string;
  /**
   * 마케팅 활용 동의 여부
   */
  marketingAgreement?: boolean;
  /**
   * 암호화된 요양기관번호 (Url Query String 전용)
   */
  ykiho?: string;
  /**
   * DEPRECATED
   * emr 타입 (agentType으로 대체됨)
   */
  emrType?: string; // deprecated
  /**
   * DEPRECATED
   * emr 기타 (agentType, agentName으로 대체됨)
   */
  emrEtc?: string; // deprecated
  /**
   * 2021 정부 화상진료 지원사업 참여 여부
   */
  isGovUntactAgreed?: boolean;
  /**
   * GC녹십자웰빙을 통한 가입 여부
   */
  isGCWellbeing?: boolean;
  /**
   * GC녹집사웰빙을 통한 가입일 경우 담당자 정보
   */
}

export interface CreateInfoFormModel extends CreateInfo {
  /**
   * (UI 전용 모델) 도로명주소
   */
  addressRoad?: string;
  /**
   * (UI 전용 모델) 상세주소
   */
  addressDetail?: string;
}

export interface FindUserInfo {
  name: string;
  email: string;
  ynum: string;
  hospital: string;
}

export interface ResetPwInfo {
  passwordResetToken: string;
  password: string;
}

export interface UpdateDataInfo {
  certNum?: string;
  confirmPassword?: string;
  marketingAgreement?: boolean;
  resetPassword?: string;
  token?: string;
  userPassword?: string;
  userPhone?: string;
}

export interface AccountUpdateInfo {
  password?: string;
  newPassword?: string;
  userPhone?: string;
  marketingAgreement?: boolean;
  token: string;
}

export interface HospitalListItems {
  items: [
    {
      _id: string;
      title: string;
      address: string;
    }
  ];
  totalCount: number;
}

export interface SignUpHospitalInfo {
  _id: string;
  title: string;
  address: string;
}

export interface SignupHospitalList {
  items: SignUpHospitalInfo[];
  limit: number;
  skip: number;
  totalCount: number;
}

export interface EmrTypeListItems {
  items: {
    id: string;
    name: string;
  }[];
  totalCount: number;
}

export interface AgentTypeListItem {
  agentType: string;
  agentTypeName: string;
}

export interface EmrAccountCreateInfo {
  userId: string;
  userPassword: string;
  userName: string;
  userPhone: string;
  userEmail: string;
  ynum: string;
  cRegNo: string;
  token: string;
  marketingAgreement?: boolean;
}

/**
 * 대리점 모델
 */
export interface AgencyModel {
  /**
   * EMR 차트사 타입
   */
  emr: string;
  /**
   * 대리점명
   */
  agency: string;
}

export enum AuthTypeEnum {
  DDOCDOC_ADMIN = 'DDOCDOC_ADMIN',
  EMR = 'EMR',
  HOSPITAL_ADMIN = 'HOSPITAL_ADMIN',
}

/**
 * GC 웰빙으로 회원가입
 */
