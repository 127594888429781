import { createAction, props } from '@ngrx/store';
import {
  QuestionDetailOptionItem,
  QuestionDetailParams,
  QuestionDetailUiItemModel,
  QuestionDetailUiModel,
  QuestionnaireCareRoomItemUiModel,
  QuestionnaireDetailParams,
  QuestionnaireOrderUpdateParams,
  QuestionnaireResItem,
  QuestionnaireTargetReq,
  QuestionnaireTargetRes,
  QuestionnaireTargetUiModel,
  QuestionnaireUiModel,
  QuestionOptionCountChangeArgs,
  QuestionSubjectUiItem,
} from '~models/question.model';

export const QuestionActionType = {
  USE_CHANGE: '[Question] Use Change',
  CHANGE: '[Question] Change',
  ADD: '[Question] Add',
  REMOVE: '[Question] Remove',
  OPTION_COUNT_CHANGE: '[Question] Option Count Change',
  OPTION_ITEM_CHANGE: '[Question] Option Item Change',
  ITEMS_MODIFIED: '[Question] Items Modified',
  REQ_FAIL: '[Question] Req Fail',
  QUESTIONNAIRES_LOAD: '[Question] Questionnaires Load',
  QUESTIONNAIRES_LOAD_SUCCESS: '[Question] Questionnaires Load Success',
  QUESTIONNAIRE_DELETE: '[Question] Questionnaire DELETE',
  QUESTIONNAIRE_DELETE_SUCCESS: '[Question] Questionnaire DELETE Success',
  QUESTIONNAIRE_TARGET_CHANGE: '[Question] Questionnaire Target Change',
  TARGET_UPDATE: '[Question] TARGET_UPDATE',
  QUESTIONNAIRE_CREATE: '[Question] Questionnaire create',
  RESET_TARGET: '[Question] Reset Target',
  LOAD_TARGET: '[Question] Load Target',
  LOAD_TARGET_SUCCESS: '[Question] Load Target Success',
  CREATE_TARGET: '[Question] Create Target',
  CREATE_TARGET_VALIDATE_SUCCESS: '[Question] Create Target Validate Success',
  UPDATE_TARGET: '[Question] Update Target',
  UPDATE_TARGET_VALIDATE_SUCCESS: '[Question] Update Target Validate Success',
  QUESTION_LOAD: '[Question] Question Load',
  QUESTION_LOAD_SUCCESS: '[Question] Question Load Success',
  CAREROOM_LOAD: '[Question] Careroom load',
  CAREROOM_LOAD_SUCCESS: '[Question] Careroom load Success',
  CHANGE_ORDER: '[Question] Change Order',
  CHANGE_ORDER_SUCCESS: '[Question] Change Order Success',
  QUESTION_SAVE: '[Question] Save',
  QUESTION_SAVE_SUCCESS: '[Question] Save Success',
  QUESTION_VALIDATE_SUCCESS: '[Question] Validate Success',
  FETCH_QUESTIONNAIRES: '[Question] Fetch Questionnaires',
  FETCH_QUESTIONNAIRES_SUCCESS: '[Question] Fetch Questionnaires Success',
  FETCH_CAREROOM_SUBJECT: '[Question] Fetch CareRoom Subject',
  FETCH_CAREROOM_SUBJECT_SUCCESS: '[Question] Fetch CareRoom Subject Success',
  QUESTIONNAIRE_OVERWRITE: '[Question] Questionnaire Overwrite',
} as const;

export const QuestionUseChange = createAction(
  QuestionActionType.USE_CHANGE,
  props<{
    payload: {
      use: boolean;
      type: 'RECEIPT' | 'RESERVATION';
      unitKey: string;
    };
  }>()
);

export const QuestionItemChange = createAction(
  QuestionActionType.CHANGE,
  props<{ payload: { item: QuestionDetailUiItemModel; index: number } }>()
);

export const QuestionItemAdd = createAction(QuestionActionType.ADD);

export const QuestionItemRemove = createAction(
  QuestionActionType.REMOVE,
  props<{ payload: number }>()
);

export const QuestionOptionCountChange = createAction(
  QuestionActionType.OPTION_COUNT_CHANGE,
  props<{ payload: QuestionOptionCountChangeArgs & { index: number } }>()
);

export const QuestionOptionItemChange = createAction(
  QuestionActionType.OPTION_ITEM_CHANGE,
  props<{
    payload: {
      data: { item: QuestionDetailUiItemModel; index: number };
      option: { item: QuestionDetailOptionItem; index: number };
    };
  }>()
);

export const QuestionItemsModified = createAction(
  QuestionActionType.ITEMS_MODIFIED,
  props<{ payload: QuestionDetailUiItemModel[] }>()
);

export const QuestionReqFail = createAction(
  QuestionActionType.REQ_FAIL,
  props<{ message: string }>()
);

export const QuestionnairesLoad = createAction(
  QuestionActionType.QUESTIONNAIRES_LOAD,
  props<{ payload: QuestionDetailParams }>()
);

export const QuestionnairesLoadSuccess = createAction(
  QuestionActionType.QUESTIONNAIRES_LOAD_SUCCESS,
  props<{ payload: QuestionnaireUiModel }>()
);

export const QuestionnaireDelete = createAction(
  QuestionActionType.QUESTIONNAIRE_DELETE,
  props<{ payload: string }>()
);

export const QuestionnaireDeleteSuccess = createAction(
  QuestionActionType.QUESTIONNAIRE_DELETE_SUCCESS
);

export const QuestionnaireTargetChange = createAction(
  QuestionActionType.QUESTIONNAIRE_TARGET_CHANGE,
  props<{ payload: QuestionnaireTargetUiModel }>()
);

/**
 * 사전문진표 타겟 ui 업데이트
 */
export const QuestionTargetUpdate = createAction(
  QuestionActionType.TARGET_UPDATE,
  props<{ payload: QuestionnaireTargetReq }>()
);

export const QuestionnaireCreate = createAction(
  QuestionActionType.QUESTIONNAIRE_CREATE,
  props<{ payload: QuestionnaireTargetRes }>()
);

export const QuestionResetTarget = createAction(
  QuestionActionType.RESET_TARGET
);

export const QuestionLoadTarget = createAction(
  QuestionActionType.LOAD_TARGET,
  props<{ questionnaireId: string }>()
);

export const QuestionLoadTargetSuccess = createAction(
  QuestionActionType.LOAD_TARGET_SUCCESS,
  props<{ payload: QuestionnaireTargetUiModel }>()
);

export const QuestionCreateTarget = createAction(
  QuestionActionType.CREATE_TARGET,
  props<{ payload: QuestionnaireTargetReq }>()
);

export const QuestionCreateTargetValidateSuccess = createAction(
  QuestionActionType.CREATE_TARGET_VALIDATE_SUCCESS,
  props<{ payload: QuestionnaireTargetReq }>()
);

/**
 * 사전문진표 타겟 업데이트 api 액션
 */
export const QuestionUpdateTarget = createAction(
  QuestionActionType.UPDATE_TARGET,
  props<{ payload: QuestionnaireTargetReq }>()
);

export const QuestionUpdateTargetValidateSuccess = createAction(
  QuestionActionType.UPDATE_TARGET_VALIDATE_SUCCESS,
  props<{ payload: QuestionnaireTargetReq }>()
);

export const QuestionnaireQuestionLoad = createAction(
  QuestionActionType.QUESTION_LOAD,
  props<{ payload: QuestionnaireDetailParams }>()
);

export const QuestionnaireQuestionLoadSuccess = createAction(
  QuestionActionType.QUESTION_LOAD_SUCCESS,
  props<{ payload: QuestionDetailUiModel }>()
);

export const QuestionCareRoomLoad = createAction(
  QuestionActionType.CAREROOM_LOAD,
  props<{ payload: string }>()
);

export const QuestionCareRoomLoadSuccess = createAction(
  QuestionActionType.CAREROOM_LOAD_SUCCESS,
  props<{ payload: QuestionnaireCareRoomItemUiModel[] }>()
);

export const QuestionChangeOrder = createAction(
  QuestionActionType.CHANGE_ORDER,
  props<{ payload: QuestionnaireOrderUpdateParams }>()
);

export const QuestionChangeOrderSuccess = createAction(
  QuestionActionType.CHANGE_ORDER_SUCCESS
);

export const QuestionSave = createAction(
  QuestionActionType.QUESTION_SAVE,
  props<{ payload: QuestionDetailUiModel }>()
);

export const QuestionSaveSuccess = createAction(
  QuestionActionType.QUESTION_SAVE_SUCCESS
);

export const QuestionValidateSuccess = createAction(
  QuestionActionType.QUESTION_VALIDATE_SUCCESS,
  props<{ payload: QuestionDetailUiModel }>()
);

/**
 * 사전문진표 문항 설정/ 다른 사전문진표 문항 불러오기 를 위한 해당 진료타입의 사전문진표를 불러오는 액션
 */
export const FetchQuestionnaires = createAction(
  QuestionActionType.FETCH_QUESTIONNAIRES,
  props<{ payload: string }>()
);

export const FetchQuestionnairesSuccess = createAction(
  QuestionActionType.FETCH_QUESTIONNAIRES_SUCCESS,
  props<{ payload: QuestionnaireResItem[] }>()
);

export const FetchCareRoomSubject = createAction(
  QuestionActionType.FETCH_CAREROOM_SUBJECT,
  props<{ payload: QuestionDetailParams }>()
);

export const FetchCareRoomSubjectSuccess = createAction(
  QuestionActionType.FETCH_CAREROOM_SUBJECT_SUCCESS,
  props<{ payload: QuestionSubjectUiItem[] }>()
);

export const QuestionnaireOverWrite = createAction(
  QuestionActionType.QUESTIONNAIRE_OVERWRITE,
  props<{ payload: QuestionDetailUiItemModel[] }>()
);

export const QuestionActions = {
  QuestionUseChange,
  QuestionItemChange,
  QuestionItemAdd,
  QuestionItemRemove,
  QuestionOptionCountChange,
  QuestionOptionItemChange,
  QuestionItemsModified,
  QuestionReqFail,
  QuestionnairesLoad,
  QuestionnairesLoadSuccess,
  QuestionnaireDelete,
  QuestionnaireDeleteSuccess,
  QuestionnaireTargetChange,
  QuestionTargetUpdate,
  QuestionnaireCreate,
  QuestionResetTarget,
  QuestionLoadTarget,
  QuestionCreateTarget,
  QuestionCreateTargetValidateSuccess,
  QuestionUpdateTarget,
  QuestionUpdateTargetValidateSuccess,
  QuestionLoadTargetSuccess,
  QuestionnaireQuestionLoad,
  QuestionnaireQuestionLoadSuccess,
  QuestionCareRoomLoad,
  QuestionCareRoomLoadSuccess,
  QuestionChangeOrder,
  QuestionChangeOrderSuccess,
  QuestionSave,
  QuestionSaveSuccess,
  QuestionValidateSuccess,
  FetchQuestionnaires,
  FetchQuestionnairesSuccess,
  FetchCareRoomSubject,
  FetchCareRoomSubjectSuccess,
  QuestionnaireOverWrite,
};
