import { Component, Input } from '@angular/core';

export interface GuideItem {
  type?: 'description' | 'caution';
  text: string;
  subItems?: Omit<GuideItem, 'subItems'>[];
}

/**
 * @name PageGuideComponent
 * @description child element 추가시 li 내 앞뒤로 공백이 붙는 경우가 있습니다. 배포 전에 확인해주세요.
 */
@Component({
  selector: 'app-page-guide',
  templateUrl: './page-guide.component.html',
  styleUrls: ['./page-guide.component.scss'],
})
export class PageGuideComponent {
  @Input() imgSrc: string;
  @Input() title: string;
  @Input() items: GuideItem[] = [];

  isSubItem(item): boolean {
    return Array.isArray(item);
  }

  constructor() {}
}
