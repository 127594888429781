import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
  fromEvent,
  noop,
  Observable,
  of,
  pipe,
  Subscription,
  timer,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  scan,
  switchMap,
  take,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { AuthService } from '~core/api/auth.service';
import {
  AgentTypeListItem,
  CreateInfo,
  CreateInfoFormModel,
  SignUpHospitalInfo,
} from '~models/auth.model';
import { FoundAddress, KakaoService } from '~shared/service/kakao.service';
import { SimpleModalService } from '~shared/service/simple.modal.service';
import { ValidatorService } from '~shared/service/validator.service';
import { SharedService } from '~core/api/shared.service';
import { HospitalInfoResModel } from '~models/hospital.model';
import {
  makeHypenCRegNo,
  makeHyphenPhoneNumber,
} from '~shared/utils/text-format';
import { omit as _omit, isNil as _isNil, isEmpty as _isEmpty } from 'lodash';

@Component({
  selector: 'app-sign-up',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignUpComponent implements OnInit {
  @ViewChild('inputId', { static: true }) inputId?: ElementRef;
  @ViewChild('inputEmail', { static: true }) inputEmail?: ElementRef;

  @Output() submitted = new EventEmitter<CreateInfo>();

  KIMES_TEMPORARY = false;

  registerForm: FormGroup;

  validateId = '^[A-Za-z0-9]*$';
  validatePassword = '^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^*+-]).{8,16}$';
  validateEmail =
    /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(]?)$/;
  validatePhone = '^(01)([0-9]{7,9}|[0-9]{1,1}-[0-9]{3,4}-[0-9]{3,4})$';
  validHospital = false;

  isDuplicatedId = false;
  isDuplicatedEmail = false;
  isPhoneCertificationSend = false;
  isShowAgencyInput = false;

  hasClickedMainCategory = false;
  hasClickedAgentType = false;

  checkId$?: Subscription;
  checkEmail$?: Subscription;

  resendSMSTime = 3 * 60; // 3분
  resendSMSCountDown$: Observable<number>;

  checkedFilter = pipe(
    map((data: any) => data.target.value),
    filter((data: string) => data.trim() !== ''),
    debounceTime(400),
    distinctUntilChanged()
  );

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private kakao: KakaoService,
    private route: ActivatedRoute,
    private simpleModalService: SimpleModalService,
    private validatorService: ValidatorService,
    private sharedService: SharedService
  ) {
    this.registerForm = this.fb.group(
      {
        userId: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(20),
            Validators.pattern(this.validateId),
          ]),
        ],
        userPassword: [
          null,
          Validators.compose([
            Validators.required,
            Validators.pattern(this.validatePassword),
          ]),
        ],
        resetPassword: [null, Validators.required],
        hospital: [null, Validators.required],
        hospitalTitle: [null, Validators.required],
        // ynum: [null, Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(100)])],
        agentType: [null, Validators.required],
        agentTypeName: [null],
        agency: [null],
        agencyManager: [null],
        mainCategory: [null, Validators.required],
        addressRoad: [null, Validators.required],
        addressDetail: [null, Validators.required],
        telReal: [null, Validators.compose([Validators.required])],
        // emrType: [null, Validators.required],
        userName: [null, Validators.required],
        userPhone: [
          null,
          Validators.compose([
            Validators.required,
            Validators.pattern(this.validatePhone),
          ]),
        ],
        certNum: [null, Validators.required],
        token: [null, Validators.required],
        cRegNo: [
          null,
          Validators.compose([
            Validators.required,
            this.validatorService.cRegNo(),
          ]),
        ],
        userEmail: [
          null,
          Validators.compose([
            Validators.required,
            Validators.pattern(this.validateEmail),
          ]),
        ],
        // agree: [null, Validators.requiredTrue],
        duplicateId: [false, Validators.requiredTrue],
        duplicateEmail: [false, Validators.requiredTrue],
        ykiho: [null],
      },
      { validator: this.matchingPassword('userPassword', 'resetPassword') }
    );
  }

  ngOnInit(): void {
    // Kimes 이벤트 기간 임시
    this.route.queryParamMap
      .pipe(
        map(params => params.has('kimes')),
        filter(isKimes => isKimes)
      )
      .subscribe(isKimes => {
        if (isKimes) {
          this.KIMES_TEMPORARY = true;
          this.registerForm.addControl(
            'isKimes',
            new FormControl('', Validators.requiredTrue)
          );
          this.registerForm.addControl(
            'isKimesExtra',
            new FormControl('', Validators.requiredTrue)
          );
        }
      });

    this.duplicateCheck();
    this.checkQueryParamMap();
    window.scrollTo(0, 0);
  }

  matchingPassword(
    passwordKey: string,
    passwordConfirm: string
  ): (formGroup: FormGroup) => void {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey];
      const passwordConfirmInput = group.controls[passwordConfirm];
      if (passwordInput.value !== passwordConfirmInput.value) {
        return passwordConfirmInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmInput.setErrors(null);
      }
    };
  }

  handleHospitalValid(isValid: boolean): void {
    this.validHospital = isValid;
  }

  onChangeHospitalValue(value: string): void {
    this.registerForm.controls.hospitalTitle.setValue(value);
  }

  get isHospitalValueEmpty(): boolean {
    return (
      !this.registerForm.controls.hospital.invalid &&
      this.registerForm.controls.hospitalTitle.invalid
    );
  }

  get isHospitalInvalid(): boolean {
    return (
      !this.registerForm.controls.hospital.invalid &&
      !this.registerForm.controls.hospitalTitle.invalid &&
      !this.validHospital
    );
  }

  getHospitalInfo(item: SignUpHospitalInfo): void {
    const hospitalId = item._id;
    this.registerForm.controls.hospital.setValue(hospitalId);
    this.registerForm.controls.hospitalTitle.setValue(item.title);
    this.setHospitalDetailInfo(hospitalId);
  }

  setHospitalDetailInfo(hospitalId: string): void {
    this.sharedService
      .fetchHospitalInfo(hospitalId)
      .subscribe((result: HospitalInfoResModel) => {
        const hospitalInfo: Partial<CreateInfoFormModel> = {};
        if (result.telReal) {
          hospitalInfo.telReal = result.telReal;
        }

        if (result.address) {
          hospitalInfo.addressRoad = result.address;
        }

        if (result.mainDepartment) {
          hospitalInfo.mainCategory = result.mainDepartment;
        }

        this.registerForm.setValue({
          ...this.registerForm.value,
          ...hospitalInfo,
        });
      }, noop);
  }

  getEmrTypeInfo(item: AgentTypeListItem): void {
    this.registerForm.controls.agentType.setValue(item.agentType);
    this.resetEmrTypeName(item);
    this.resetAgency();
    this.checkEmrHasAgency(item.agentType);
  }

  getEmrTypeNameInfo(item: AgentTypeListItem): void {
    this.registerForm.controls.agentTypeName.setValue(item.agentTypeName);
  }

  checkEmrHasAgency(agentType: string): void {
    // const agentListHasAgency = ['UBCARE', 'BIT', 'NEO', 'POINT', 'DASOM'];
    // this.isShowAgencyInput = agentListHasAgency.includes(agentType);

    this.sharedService
      .fetchAgencies(agentType)
      .pipe(map(res => res.items.length > 0))
      .subscribe(
        isShowAgencyInput => {
          this.isShowAgencyInput = isShowAgencyInput;
        },
        () => {
          this.isShowAgencyInput = false;
        }
      );
  }

  resetAgency(): void {
    this.registerForm.controls.agency.setValue(null);
    this.registerForm.controls.agencyManager.setValue(null);
  }

  resetEmrTypeName(item: any): void {
    if (item.agentType !== 'ETC') {
      this.registerForm.controls.agentTypeName.setValue(item.agentTypeName);
    } else {
      this.registerForm.controls.agentTypeName.setValue(null);
    }
  }

  getCategoryTypeInfo(item: any): void {
    this.registerForm.controls.mainCategory.setValue(item);
  }

  getAgencyTypeInfo(agencyName: string): void {
    this.registerForm.controls.agency.setValue(agencyName);
  }

  onCheckPhoneNum(): void {
    this.sendPhoneCertification();
  }

  onCheckCertification(): void {
    this.phoneCertificationCheck();
  }

  onClickMainCategory(): void {
    this.hasClickedMainCategory = true;
  }

  onClickAgentType(): void {
    this.hasClickedAgentType = true;
  }

  onResetCheckPhoneNum(): void {
    this.registerForm.controls.token.setValue(null);
    this.registerForm.controls.certNum.setValue(null);
    this.isPhoneCertificationSend = false;
    this.resendSMSCountDown$ = of(0);
    this.onCheckPhoneNum();
  }

  getAddressRoad(): void {
    this.kakao.findAddress().subscribe((data: FoundAddress) => {
      if (data) {
        const extraAddr = data.buildingName
          ? ' (' + data.buildingName + ')'
          : '';
        const address = data.address + extraAddr;
        this.registerForm.controls.addressRoad.setValue(address);
      }
    });
  }

  duplicateCheck(): void {
    this.checkId$ = fromEvent(this.inputId?.nativeElement, 'keyup')
      .pipe(
        map((data: any) => data.target.value),
        // filter((data: string) => data.trim() !== '' && /^[A-Za-z0-9]*$/.test(data)),
        filter((data: string) => data.trim() !== ''),
        // debounceTime(400),
        distinctUntilChanged(),
        tap(() => (this.isDuplicatedId = false)),
        switchMap(data => this.authService.existId(data)),
        map((v: any) => (this.isDuplicatedId = v.isExist))
      )
      .subscribe((v: any) => {
        this.registerForm.controls.duplicateId.setValue(!v);
      });

    this.checkEmail$ = fromEvent(this.inputEmail?.nativeElement, 'keyup')
      .pipe(
        map((data: any) => data.target.value),
        filter((data: string) => data.trim() !== ''),
        // debounceTime(400),
        distinctUntilChanged(),
        tap(() => (this.isDuplicatedEmail = false)),
        filter((data: any) => this.validateEmail.test(data)),
        switchMap(data => this.authService.existEmail(data)),
        map((v: any) => (this.isDuplicatedEmail = v.isExist))
      )
      .subscribe((v: any) => {
        this.registerForm.controls.duplicateEmail.setValue(!v);
      });
  }

  checkQueryParamMap(): void {
    this.route.queryParamMap.pipe(take(1)).subscribe(paramMap => {
      history.replaceState({}, '', location.pathname); // Query Params를 없앤다.

      if (paramMap.keys.length > 0) {
        this.setFormQueryParamMap(paramMap);
      }
    });
  }

  setFormQueryParamMap(paramMap: ParamMap): void {
    const formUpdateParams = {} as Partial<CreateInfoFormModel>;

    if (paramMap.has('userEmail')) {
      formUpdateParams.userEmail = paramMap.get('userEmail') as string;
    }

    if (paramMap.has('userPhone')) {
      formUpdateParams.userPhone = makeHyphenPhoneNumber(
        paramMap.get('usePhone') as string
      );
    }

    if (paramMap.has('userName')) {
      formUpdateParams.userName = paramMap.get('useName') as string;
    }

    if (paramMap.has('cRegNo')) {
      formUpdateParams.cRegNo = makeHypenCRegNo(
        paramMap.get('cRegNo') as string
      );
    }

    if (paramMap.has('agentType')) {
      const agentType = paramMap.get('agentType') as string;
      formUpdateParams.agentType = agentType;
      this.checkEmrHasAgency(agentType);
    }

    if (paramMap.has('agentTypeName')) {
      formUpdateParams.agentTypeName = paramMap.get('agentTypeName') as string;
    }

    if (paramMap.has('agency') && paramMap.has('agentType')) {
      formUpdateParams.agency = paramMap.get('agency') as string;
    }

    if (paramMap.has('agencyManager')) {
      formUpdateParams.agencyManager = paramMap.get('agencyManager') as string;
    }

    if (paramMap.has('ykiho')) {
      formUpdateParams.ykiho = paramMap.get('ykiho') as string;
    }

    this.registerForm.patchValue({ ...formUpdateParams });

    this.dispatchFormChangeEvent(formUpdateParams);
  }

  /**
   * 사용자가 직접 input필드에 텍스트를 입력하는 것이 아닌 코드에서 직접 값을 바꿀경우,
   * input 값이 바뀌고 사후처리를 해야하는 필드 (ex 전화번호 하이픈 값처리, 이메일 중복체크)를 위해 이벤트를 dispatch하는 function
   */
  dispatchFormChangeEvent(
    formUpdateParams: Partial<CreateInfoFormModel>
  ): void {
    this.registerForm.controls.cRegNo.markAsTouched(); // 사업자등록번호 유효성 체크, 하이픈 을 넣기위한 처리

    if (formUpdateParams.userEmail) {
      // @ts-ignore
      this.inputEmail?.nativeElement.value = formUpdateParams.userEmail;
      if (typeof Event === 'function') {
        // 모던 브라우저
        this.inputEmail?.nativeElement.dispatchEvent(new Event('keyup'));
      } else {
        // ie 의경우 Event 생성자가 없어서 따로 처리해줘야한다.
        if (this.inputEmail?.nativeElement.dispatchEvent) {
          // ie11 의 경우 dispatchEvent 메서드는 있으나 Event 생성자가 없기때문에 document.createEvent를 사용한다.
          const event = document.createEvent('TextEvent');
          event.initEvent('keyup', true, true);
          this.inputEmail.nativeElement.dispatchEvent(event);
        } else {
          // ie11 이하 버전을 위한 처리
          this.inputEmail?.nativeElement.fireEvent('keyup');
        }
      }
    }
  }

  registerAccount(): void {
    if (this.registerForm.valid) {
      const value = _omit(this.registerForm.value, 'hospitalTitle');
      const registerReq: CreateInfo = {
        ...value,
        address: { road: value.addressRoad, detail: value.addressDetail },
      };
      this.submitted.emit(registerReq);
    }
  }

  sendPhoneCertification(): void {
    this.authService
      .phoneCertificationCheck(this.registerForm.controls.userPhone.value)
      .pipe(
        map(result => result),
        catchError(err => of({ errMsg: err.error.error.message }))
      )
      .subscribe((payload: { errMsg?: string; userPhone?: string }) => {
        if (payload && payload.errMsg) {
          this.simpleModalService.alert(payload.errMsg);
        } else {
          this.simpleModalService.alert('인증번호를 전송했습니다.');
          this.isPhoneCertificationSend = true;
          this.resendSMSCountDown$ = timer(0, 1000).pipe(
            scan(timer => --timer, this.resendSMSTime + 1),
            takeWhile(leftTime => leftTime >= 0)
          );
        }
      });
  }

  phoneCertificationCheck(): void {
    this.authService
      .phoneCertificationConfirm(
        this.registerForm.controls.userPhone.value,
        this.registerForm.controls.certNum.value
      )
      .pipe(
        map(result => ({ result: true, token: result.token })),
        catchError(({ error }) => {
          return of({ result: false, message: error.error.message });
        })
      )
      .subscribe(
        (result: { result: boolean; token?: string; message?: string }) => {
          if (result.result) {
            this.simpleModalService.alert('인증에 성공하셨습니다.');
            this.registerForm.controls.token.setValue(result.token);
          } else {
            if (result.message === '인증번호가 일치하지 않습니다.') {
              this.simpleModalService.alert(
                '전화번호 인증에 실패하셨습니다. 다시 시도해주세요.'
              );
            } else {
              this.simpleModalService.alert(
                `인증시간이 초과되었습니다.<br/>인증번호를 다시 요청해주세요.`
              );
            }
          }
        }
      );
  }

  get phoneCertificatedButtonText(): string {
    return this.registerForm.controls.token.valid ? '완료' : '확인';
  }

  get isETCAgent(): boolean {
    return this.registerForm.controls.agentType.value === 'ETC';
  }

  get isETCAgentNameEmpty(): boolean {
    return (
      this.isETCAgent &&
      (_isNil(this.registerForm.controls.agentTypeName.value) ||
        _isEmpty(this.registerForm.controls.agentTypeName.value))
    );
  }

  get isInvalidRegister(): boolean {
    if (this.isETCAgent) {
      return this.isETCAgentNameEmpty || this.registerForm.invalid;
    }
    return this.registerForm.invalid;
  }
}
