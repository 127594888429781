import { createAction, props } from '@ngrx/store';
import {
  ExpenseCategoryRegister,
  ExpenseCategoryRequest,
  ExpenseCategoryUiModel,
} from '~root/app/models/expense-category.model';

export enum ExpenseCategoryActionType {
  EXPENSE_SUB_CATEGORY_LOAD = '[Expense Category] Sub Category Load',
  EXPENSE_SUB_CATEGORY_LOAD_SUCCESS = '[Expense Category] Sub Category Load Success',
  EXPENSE_CATEGORY_LOAD = '[Expense Category] Category Load',
  EXPENSE_CATEGORY_LOAD_SUCCESS = '[Expense Category] Category Load Success',
  REQ_FAIL = '[Expense Category] Request Fail',
  ADD_NEW_EXPENSE = '[Expense Category] Add New Expense',
  ADD_NEW_EXPENSE_SUCCESS = '[Expense Category] Add New Expense Success',
  ADD_NEW_EXPENSE_FAIL = '[Expense Category] Add New Expense FAIL',
}

export const ExpenseCategoryReqFail = createAction(
  ExpenseCategoryActionType.REQ_FAIL,
  props<{ payload: any }>()
);

export const ExpenseCategoryLoad = createAction(
  ExpenseCategoryActionType.EXPENSE_CATEGORY_LOAD,
  props<{ payload: ExpenseCategoryRequest }>()
);

export const ExpenseCategoryLoadSuccess = createAction(
  ExpenseCategoryActionType.EXPENSE_CATEGORY_LOAD_SUCCESS,
  props<{ payload: ExpenseCategoryUiModel }>()
);

export const AddNewExpense = createAction(
  ExpenseCategoryActionType.ADD_NEW_EXPENSE,
  props<{ payload: ExpenseCategoryRegister }>()
);

export const AddNewExpenseSuccess = createAction(
  ExpenseCategoryActionType.ADD_NEW_EXPENSE_SUCCESS
);

export const AddNewExpenseFail = createAction(
  ExpenseCategoryActionType.ADD_NEW_EXPENSE_FAIL,
  props<{ payload: any }>()
);

export const ExpenseCategoryActions = {
  ExpenseCategoryLoad,
  ExpenseCategoryLoadSuccess,
  ExpenseCategoryReqFail,
  AddNewExpense,
  AddNewExpenseSuccess,
  AddNewExpenseFail,
};
