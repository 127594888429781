import { createAction, props } from '@ngrx/store';
import { HashMap } from '~models/common.model';
import { OfficeWeeklyDetailData } from '~models/care-room.model';
import { OpenTimeUpdateParams } from '~models/reservation-timeline.model';
import { ScheduleSettingBatchModel } from '~models/reservation-schedule.model';
import { HospitalSettingTypes } from './hospital-setting.actions';
import { OperatingTimeModel } from '~models/hospital.model';

export type ScheduleType = 'RECEPTION' | 'KIOSK' | 'TABLET';

export enum RecScheduleActionTypes {
  LOAD = '[Rec Schedule] Load',
  LOAD_SUCCESS = '[Rec Schedule] Success',
  LOAD_FAIL = '[Rec Schedule] Fail',
  READ = '[Rec Schedule] Read',
  READ_SUCCESS = '[Rec Schedule] Read Success',
  READ_FAIL = '[Rec Schedule] Read Fail',
  UPDATE = '[Rec Schedule] Update',
  UPDATE_SUCCESS = '[Rec Schedule] Update Success',
  UPDATE_FAIL = '[Rec Schedule] Update Fail',
  OPERATINGTIME_LOAD = '[Rec Schedule] Time Load',

  EDIT = '[Rec Schedule] Edit',
  // APPLY = '[Res Schedule] Apply',

  BATCH_EDIT = '[Rec Schedule] Batch Edit',
  BATCH_APPLY = '[Rec Schedule] Batch Apply',
}

export const RecScheduleLoad = createAction(
  RecScheduleActionTypes.LOAD,
  props<{ params: { unitKey: string }; successMessage?: string }>()
);

export const RecScheduleLoadSuccess = createAction(
  RecScheduleActionTypes.LOAD_SUCCESS,
  props<{ payload: HashMap<OfficeWeeklyDetailData> }>()
);

export const RecScheduleLoadFail = createAction(
  RecScheduleActionTypes.LOAD_FAIL,
  props<{ error: any }>()
);

export const RecScheduleRead = createAction(
  RecScheduleActionTypes.READ,
  props<{ params: { unitKey: string; type: ScheduleType } }>()
);

export const RecScheduleReadSuccess = createAction(
  RecScheduleActionTypes.READ_SUCCESS,
  props<{ payload: HashMap<OfficeWeeklyDetailData>; message: string }>()
);

export const RecScheduleReadFail = createAction(
  RecScheduleActionTypes.READ_FAIL,
  props<{ error: any }>()
);

export const RecScheduleUpdate = createAction(
  RecScheduleActionTypes.UPDATE,
  props<{ params: OpenTimeUpdateParams }>()
);

export const RecScheduleUpdateSuccess = createAction(
  RecScheduleActionTypes.UPDATE_SUCCESS,
  props<{ message: string }>()
);

export const RecScheduleUpdateFail = createAction(
  RecScheduleActionTypes.UPDATE_FAIL,
  props<{ error: any }>()
);

export const RecScheduleEdit = createAction(
  RecScheduleActionTypes.EDIT,
  props<{ payload: HashMap<OfficeWeeklyDetailData> }>()
);

// export const ResScheduleApply = createAction(
//   RecScheduleActionTypes.APPLY;

//   constructor(public payload: HashMap<OfficeWeeklyDetailData>) {}
// }

export const RecScheduleBatchEdit = createAction(
  RecScheduleActionTypes.BATCH_EDIT,
  props<{ payload: ScheduleSettingBatchModel }>()
);

export const RecScheduleBatchApply = createAction(
  RecScheduleActionTypes.BATCH_APPLY,
  props<{ payload: HashMap<OfficeWeeklyDetailData> }>()
);

export const RecOperatingTimeLoad = createAction(
  HospitalSettingTypes.OPERATING_TIME_LOAD
);

export const RecOperatingTimeLoadSuccess = createAction(
  HospitalSettingTypes.OPERATING_TIME_LOAD_SUCCESS,
  props<{ payload: { operating: OperatingTimeModel } }>()
);

export const RecScheduleActions = {
  RecScheduleLoad,
  RecScheduleLoadSuccess,
  RecScheduleLoadFail,
  RecScheduleRead,
  RecScheduleReadSuccess,
  RecScheduleReadFail,
  RecScheduleUpdate,
  RecScheduleUpdateSuccess,
  RecScheduleUpdateFail,
  RecScheduleEdit,
  RecScheduleBatchEdit,
  RecScheduleBatchApply,
  RecOperatingTimeLoad,
  RecOperatingTimeLoadSuccess,
};
