<div class="input-wrap" #inputWrap>
  <input
    *ngIf="!showTextLengthIndicator"
    class="input-text"
    type="text"
    [name]="name"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    (ngModelChange)="handleValueChange($event)"
    [maxlength]="maxLength"
    [ngClass]="{ 'has-error': hasError }"
  />
  <span [hidden]="hasError === false" #errorMessageSpan class="error-message">{{
    errorMessage
  }}</span>
</div>

<div class="input-text-wrap" *ngIf="showTextLengthIndicator">
  <input
    type="text"
    [name]="name"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    (ngModelChange)="handleValueChange($event)"
    [maxlength]="maxLength"
  />
  <span class="length-indicator"
    >({{ value && value.length ? value.length : 0 }}/{{ maxLength }})</span
  >
</div>
