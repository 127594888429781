import {
  WaitingsSetupDeprecatedModel,
  WaitingsSetupRes,
  WaitingsSetupUiModel,
  WaitingsSetupUpdateParams,
  WaitNoticeModel,
  WaitNoticeUiModel,
  WaitSettingsUiModel,
} from '~models/waitings.model';

export function getInitWaitNoticeUiModel(): WaitNoticeUiModel {
  return {
    useNotice: false,
    notice: '',
    useTime: false,
    startTime: '',
    endTime: '',
  };
}

export function getInitWaitSettingsUiModel(): WaitSettingsUiModel {
  return {
    expectWaitActive: false,
    expectWaitType: 'auto',
    expectWaitAutoValue: 0,
    expectWaitManualValue: 0,
    firstNotifyActive: false,
    firstNotifyLimit: 0,
    increase: false,
  };
}

function getUseTime(notice: WaitNoticeModel): boolean {
  try {
    const { startTime, endTime } = notice;

    return (
      !!startTime &&
      !!endTime &&
      startTime !== endTime &&
      startTime.length === 5 &&
      endTime.length === 5
    );
  } catch (error) {
    return false;
  }
}

function getBoolean(value: any): boolean {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }
  if (typeof value === 'string') {
    return value === 'true';
  }
  return value === true;
}

function getNumber(value: any): number {
  return Number(value);
}

function convertToWaitNoticeUiModel(res: WaitingsSetupRes): WaitNoticeUiModel {
  const notice = res.notice;

  if (!notice) {
    return getInitWaitNoticeUiModel();
  }

  return {
    useNotice: getBoolean(notice.active),
    notice: notice.text || '',
    useTime: getUseTime(notice),
    startTime: notice.startTime || '',
    endTime: notice.endTime || '',
  };
}

function convertToWaitSettingsUiModel(
  res: WaitingsSetupRes
): WaitSettingsUiModel {
  const { waitingsTime, firstNotify, increase } = res;
  const mRet = getInitWaitSettingsUiModel();

  if (waitingsTime) {
    mRet.expectWaitActive = getBoolean(waitingsTime.active);
    mRet.expectWaitAutoValue = getNumber(waitingsTime.autoTime);
    mRet.expectWaitManualValue = getNumber(waitingsTime.manualTime);
    mRet.expectWaitType = waitingsTime.autoYn === 'Y' ? 'auto' : 'manual';
  }
  if (firstNotify) {
    mRet.firstNotifyActive = getBoolean(firstNotify.active);
    mRet.firstNotifyLimit = getNumber(firstNotify.limit);
  }
  mRet.increase = increase;

  return mRet;
}

export function convertToWaitingsSetupUiModel(
  res: WaitingsSetupRes
): WaitingsSetupUiModel {
  return {
    notice: convertToWaitNoticeUiModel(res),
    settings: convertToWaitSettingsUiModel(res),
    waitingsUse: res.waitingsUse,
    receptionGuideUse: res.receptionGuideUse,
  };
}

function getDeprecatedData(
  response: WaitingsSetupRes
): WaitingsSetupDeprecatedModel {
  return {
    callMessage: { ...response.callMessage },
    useSMS: response.useSMS,
    useSumHold: response.useSumHold,
    receptionGuideTarget: { ...response.receptionGuideTarget },
    excludeSend: { ...response.excludeSend },
    stopReason: { ...response.stopReason },
  };
}

function toInteger(val: number): number {
  return Math.floor(Number(val || 0));
}

export function convertToWaitingsSetupUpdateParams(
  state: WaitingsSetupUiModel & { response: WaitingsSetupRes }
): WaitingsSetupUpdateParams {
  const { notice, settings, waitingsUse, receptionGuideUse, response } = state;
  const expectWaitActive = settings.expectWaitActive;
  const useNoticeTime = notice.useTime;
  let startTime: string | null = notice.startTime;
  let endTime: string | null = notice.endTime;

  if (!useNoticeTime) {
    startTime = null;
    endTime = null;
  }

  return {
    ...getDeprecatedData(response),
    increase: settings.increase,
    waitingsTime: {
      active: expectWaitActive,
      autoYn: settings.expectWaitType === 'auto' ? 'Y' : 'N',
      autoTime: settings.expectWaitAutoValue + '',
      manualTime: toInteger(settings.expectWaitManualValue) + '',
    },
    notice: {
      active: notice.useNotice,
      text: notice.notice,
      startTime,
      endTime,
    },
    firstNotify: {
      active: settings.firstNotifyActive,
      limit: toInteger(settings.firstNotifyLimit),
    },
    waitingsUse,
    receptionGuideUse,
    requestType: 'HOSPITAL_ADMIN',
  };
}

function isInteger(val: number): boolean {
  return (
    typeof val !== 'undefined' &&
    val !== null &&
    isFinite(val) &&
    Math.floor(val) === val
  );
}

function validateNotice(notice: WaitNoticeUiModel): void {
  const { useTime: useNoticeTime, startTime, endTime } = notice;
  let msg = '';

  if (notice.useNotice) {
    if (!notice.notice) {
      msg = '공지사항 내용은 필수 입니다.';
    } else if (notice.notice.length > 200) {
      msg = '공지사항은 최대 200자 입니다.';
    } else if (useNoticeTime && startTime === endTime) {
      msg = '공지사항 노출시간의 시작/종료 시간은 서로 같을 수 없습니다.';
    } else if (useNoticeTime && startTime > endTime) {
      msg = '공지사항 노출시간의 시작시간이 종료 시간보다 클 수 없습니다.';
    }
  }

  if (msg) {
    throw new Error(msg);
  }
}

function validateSettings(settings: WaitSettingsUiModel): void {
  let msg = '';

  if (settings.expectWaitActive && settings.expectWaitType === 'manual') {
    if (!isInteger(settings.expectWaitManualValue)) {
      msg = '예상 대기시간 표시: 수동입력은 소수점 이하 입력이 불가합니다.';
    }
  }
  if (settings.firstNotifyActive) {
    if (!isInteger(settings.firstNotifyLimit)) {
      msg = '1차 접수내역 알림: 인원수는 소수점 이하 입력이 불가합니다.';
    }
  }

  if (msg) {
    throw new Error(msg);
  }
}

export function validateWaitingsSetup(
  state: WaitingsSetupUiModel
): WaitingsSetupUiModel {
  const { notice, settings } = state;
  validateNotice(notice);
  validateSettings(settings);

  return state;
}
