import {
  PenaltySummaryModel,
  ReceptionPenaltyResDetail,
  ReceptionPenaltyShareModel,
  StatusPenaltySearchUiQuery,
} from '~models/status-penalty.model';

export function createStatusPenaltySearchUiQuery(): StatusPenaltySearchUiQuery {
  return {
    serviceType: '',
    keywordType: 'userName',
    keyword: '',
    occurredDate: {
      startDate: '',
      endDate: '',
    },
    birthDate: '',
    phone: '',
    skip: 0,
    limit: 10,
  };
}

export function createReceptionPenaltyShareModel(): ReceptionPenaltyShareModel {
  return {
    _id: '',
    penalty: '',
    receptionId: '',
    hospital: '',
    userId: '',
    familyMemeberId: '',
    type: '',
    state: '',
    active: false,
    createdAt: '',
    updatedAt: '',
    parentName: '',
    parentPhone: '',
    userName: '',
    userPhone: '',
    birthDate: '',
    gender: '',
  };
}

export function createPenaltySummaryModel(): PenaltySummaryModel {
  return {
    cancelCount: 0,
    penaltyActive: false,
    startPenaltyDate: '',
    dismissPenaltyDate: '',
  };
}

export function createStatusPenaltyResDetail(): ReceptionPenaltyResDetail {
  return {
    ...createReceptionPenaltyShareModel(),
    email: '',
    parentBirthDate: '',
    receiptPenalty: createPenaltySummaryModel(),
    reservationPenalty: createPenaltySummaryModel(),
    penaltyHistories: [],
  };
}
