<div class="carousel-body" [ngClass]="items.order">
  <div *ngIf="items.mainTitle !== '병원 경영관리'" class="sub-header">
    <button
      *ngFor="let content of items.contents; let idx = index"
      class="sub-header--item"
      [ngClass]="{ active: currentSlideIdx === idx }"
      (click)="contentSelect(idx)"
    >
      {{ content.title }}
    </button>
  </div>

  <ngb-carousel
    class="carousel-body--content"
    #carousel
    (slide)="handleSlidEvent($event)"
  >
    <ng-template
      ngbSlide
      *ngFor="let content of items.contents; let idx = index"
      id="{{ idx }}"
    >
      <div class="img-box" [ngClass]="items.order">
        <img [src]="content.imgUrl" [alt]="content.explain" />
      </div>

      <hr *ngIf="items.order === 'last'" />

      <h4 class="carousel-body--content_title">
        {{ content.title }}
        <small *ngIf="content?.subTitle">{{ content.subTitle }}</small>
      </h4>
      <p class="carousel-body--content_explain">{{ content.explain }}</p>
    </ng-template>
  </ngb-carousel>
</div>
