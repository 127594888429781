<div style="position:relative">
  <input
    type="text"
    class="form-control-input"
    id="inputHospital"
    placeholder="가입 병원 선택"
    [(ngModel)]="hosTitle"
    (keyup)="handleKeyUp($event)"
    autocomplete="off"
  />

  <small class="form-text text-danger" *ngIf="!isSelect"
    >가입 병원을 검색하신 후 선택해주세요.</small
  >

  <div
    *ngIf="hasItems && items.length === 0"
    class="list-group"
    style="position:absolute;width:100%;left:0;top:72px;"
  >
    <a class="list-group-item list-group-item-action">
      <p class="m-0">검색 결과가 없습니다.</p>
    </a>
  </div>

  <div
    *ngIf="hasItems && items.length !== 0"
    class="list-group"
    style="position:absolute;width:100%;left:0;top:72px;max-height:366px;overflow-x:hidden;overflow-y:scroll;z-index:2;"
  >
    <div
      class="list-group-item list-group-item-action"
      *ngFor="let item of items"
      style="cursor:pointer;position:relative;"
      (click)="selectedHospital(item)"
    >
      <p class="m-0">{{ item.title }}</p>
      <small class="text-muted">{{ item.address }}</small>
    </div>
  </div>
</div>
