import {
  ReceptionPenaltyResDetail,
  ReceptionPenaltyResItem,
  StatusPenaltySearchUiQuery,
} from '~models/status-penalty.model';
import { StatusPenaltyActions } from '~actions/products/status-penalty.actions';
import {
  createStatusPenaltyResDetail,
  createStatusPenaltySearchUiQuery,
} from '../../services/status/penalty.create';
import { createReducer, on } from '@ngrx/store';

export interface StatusPenaltyState {
  query: StatusPenaltySearchUiQuery;
  items: ReceptionPenaltyResItem[];
  detail: ReceptionPenaltyResDetail;
  totalCount: number;
  pending: boolean;
}

export function getInitState(): StatusPenaltyState {
  return {
    query: createStatusPenaltySearchUiQuery(),
    items: [],
    detail: createStatusPenaltyResDetail(),
    totalCount: 0,
    pending: false,
  };
}

export const reducer = createReducer(
  getInitState(),
  on(StatusPenaltyActions.StatusPenaltyLoadList, (state, action) => ({
    ...state,
    query: action.payload,
    pending: true,
  })),
  on(
    StatusPenaltyActions.StatusPenaltyLoadDetail,
    StatusPenaltyActions.StatusPenaltyRemovePenalty,
    state => ({
      ...state,
      pending: true,
    })
  ),
  on(StatusPenaltyActions.StatusPenaltyLoadListSuccess, (state, action) => ({
    ...state,
    items: action.payload.items,
    totalCount: action.payload.totalCount,
    pending: false,
  })),
  on(StatusPenaltyActions.StatusPenaltyLoadDetailSuccess, (state, action) => ({
    ...state,
    detail: action.payload,
    pending: false,
  })),
  on(
    StatusPenaltyActions.StatusPenaltyRemovePenaltySuccess,
    StatusPenaltyActions.StatusPenaltyFail,
    state => ({
      ...state,
      pending: false,
    })
  )
);
