import { CrmDefaultActions } from '~actions/products/hospital-crm-default-schedule.actions';
import { HospitalScheduleUiModel } from '~models/hospital-crm.model';
import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';

export interface HospitalCrmDefaultScheduleState
  extends EntityState<HospitalScheduleUiModel> {
  pending: boolean;
  isActiveAll: boolean;
}

export const HospitalCrmDefaultScheduleAdapter = createEntityAdapter<
  HospitalScheduleUiModel
>({
  selectId: model => model._id,
});

export const initialState = HospitalCrmDefaultScheduleAdapter.getInitialState({
  pending: false,
  isActiveAll: false,
});

export const reducer = createReducer(
  initialState,
  on(
    CrmDefaultActions.CrmDefaultLoad,
    CrmDefaultActions.CrmSetActivateSchedule,
    state => ({
      ...state,
      pending: true,
      isActiveAll: false,
    })
  ),
  on(CrmDefaultActions.CrmDefaultLoadSuccess, (state, action) =>
    HospitalCrmDefaultScheduleAdapter.setAll(action.payload.items, {
      ...state,
      pending: false,
    })
  ),
  on(CrmDefaultActions.CrmSetActivateScheduleSuccess, (state, action) =>
    HospitalCrmDefaultScheduleAdapter.setAll(action.payload.items, {
      ...state,
      pending: false,
      isActiveAll: true,
    })
  ),
  on(
    CrmDefaultActions.CrmDefaultLoadFail,
    CrmDefaultActions.CrmSetActivateScheduleFail,
    state => ({
      ...state,
      pending: false,
      isActiveAll: false,
    })
  ),
  on(CrmDefaultActions.CrmDefaultUpdateSchedule, (state, { schedule }) => {
    return HospitalCrmDefaultScheduleAdapter.updateOne(
      {
        id: schedule._id,
        changes: { ...schedule, isEdited: true },
      },
      state
    );
  }),
  on(CrmDefaultActions.CrmDefaultSaveScheduleStart, (state, { _id }) => {
    return HospitalCrmDefaultScheduleAdapter.updateOne(
      {
        id: _id,
        changes: { isUpdate: true },
      },
      state
    );
  }),
  on(CrmDefaultActions.CrmDefaultSaveScheduleSuccess, (state, { _id }) => {
    return HospitalCrmDefaultScheduleAdapter.updateOne(
      {
        id: _id,
        changes: { isUpdate: false, isEdited: false },
      },
      state
    );
  }),
  on(CrmDefaultActions.CrmDefaultSaveScheduleFail, (state, { _id }) => {
    return HospitalCrmDefaultScheduleAdapter.updateOne(
      {
        id: _id,
        changes: { isUpdate: false },
      },
      state
    );
  })
);
