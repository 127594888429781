import { AccountInfo, LoginSuccessInfo } from '~models/auth.model';
import { AuthActions } from '~actions/auth/auth.action';
import { errorMessage } from '~shared/service/util';
import { Action, createReducer, on } from '@ngrx/store';

export interface AuthState {
  loading: boolean;
  loggedIn: boolean;
  error: string | null;
  successInfo: LoginSuccessInfo | null;
  accountInfo: AccountInfo | null;
  phoneCertToken?: string;
  guideType?: string;
}

const initialState: AuthState = {
  loading: false,
  loggedIn: false,
  error: null,
  successInfo: null,
  accountInfo: null,
};

export const reducer = createReducer<AuthState, Action>(
  initialState,
  on(AuthActions.Login, (state, action) => {
    const { guideType } = action.payload;
    return {
      ...state,
      guideType,
      loading: true,
      error: null,
    };
  }),
  on(AuthActions.LoginSuccess, (state, action) => ({
    ...state,
    loading: true,
    successInfo: action.payload,
    error: null,
  })),
  on(AuthActions.LoginComplete, state => ({
    ...state,
    loading: false,
    loggedIn: true,
    error: null,
  })),
  on(AuthActions.LoginFailure, (state, action) => ({
    ...state,
    loading: false,
    loggedIn: false,
    error: errorMessage(action.payload, 'auth.red54'),
  })),
  on(AuthActions.Logout, state => ({
    ...state,
    loading: false,
    loggedIn: false,
    error: null,
  })),
  on(AuthActions.AccountCreate, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(AuthActions.AccountCreateComplete, state => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(AuthActions.AccountCreateFailure, (state, action) => ({
    ...state,
    loading: false,
    error: errorMessage(action.payload, 'auth.red87'),
  })),
  on(AuthActions.AccountUpdate, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(AuthActions.AccountUpdateComplete, (state, action) => ({
    ...state,
    accountInfo: action.payload,
    loading: false,
    error: null,
  })),
  on(AuthActions.AccountUpdateFailure, (state, action) => ({
    ...state,
    loading: false,
    error: errorMessage(action.payload, 'auth.red109'),
  })),
  on(AuthActions.AccountFetch, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(AuthActions.AccountFetchComplete, (state, action) => ({
    ...state,
    accountInfo: action.payload,
    loggedIn: true,
    loading: false,
    error: null,
  })),
  on(AuthActions.AccountFetchFailure, state => ({ ...state, loading: false })),
  on(AuthActions.ChangeHospital, (state, action) => ({
    ...state,
    accountInfo: action.payload,
  })),
  on(AuthActions.ChangeHospitalSuccess, (state, action) => ({
    ...state,
    accountInfo: action.payload,
  })),
  on(AuthActions.PhoneCertificationCheck, state => ({
    ...state,
    loading: true,
  })),
  on(AuthActions.PhoneCertificationConfirmComplete, (state, action) => ({
    ...state,
    loading: true,
    phoneCertToken: action.payload.token,
  }))
);

export const getLoggedIn = (state: AuthState) => state.loggedIn;
export const getSuccessInfo = (state: AuthState) => state.successInfo;
export const getAccountInfo = (state: AuthState) => state.accountInfo;
export const getAuthLoading = (state: AuthState) => state.loading;
export const getError = (state: AuthState) => state.error;
export const getPhoneCertToken = (state: AuthState) => state.phoneCertToken;
export const getGuideType = (state: AuthState) => state.guideType;
