<div class="wrap">
  <label
    for="chk_weekday_{{ instKey }}_{{ idx }}"
    class="check-item"
    *ngFor="let num of nums; let idx = index"
  >
    <input
      type="checkbox"
      id="chk_weekday_{{ instKey }}_{{ idx }}"
      [checked]="weekdaysChecked[idx]"
      (change)="onCheckedChange($event, idx)"
    />
    <i class="far fa-square" *ngIf="!weekdaysChecked[idx]"></i>
    <i class="far fa-check-square" *ngIf="weekdaysChecked[idx]"></i>
    <span>{{ num | weekName }}</span>
  </label>
</div>
