import { FindUserInfo } from '~models/auth.model';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import * as fromAuth from '~reducers/auth';
import { FindIdActions } from '~actions/auth/find-id.action';

@Component({
  selector: 'app-find-id-page',
  templateUrl: './find-id-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FindIdPageComponent {
  error$: Observable<string | null>;

  constructor(private store: Store<fromAuth.AuthInfoState>) {
    this.error$ = this.store.pipe(select(fromAuth.getFindIdError));
  }

  findId(data: FindUserInfo): void {
    this.store.dispatch(FindIdActions.FindId({ payload: data }));
  }
}
