<div class="ha-pagination clearfix" [ngClass]="themeClass">
  <button type="button" class="btn-page btn-prev" [ngClass]="{ disabled: this.prevDisable }" (click)="prev()">
    <i class="fas fa-angle-left"></i><span>&nbsp; 이전</span>
  </button>
  <div class="nav-list">
    <button
      type="button"
      class="btn-page"
      *ngFor="let num of pages"
      [ngClass]="{ active: page === num }"
      (click)="onPageClick(num)"
    >
      {{ num }}
    </button>
  </div>
  <button type="button" class="btn-page btn-next" [ngClass]="{ disabled: this.nextDisable }" (click)="next()">
    <span>다음 &nbsp;</span><i class="fas fa-angle-right"></i>
  </button>
</div>
