import { createReducer, on } from '@ngrx/store';
import {
  DayOfWeekMap,
  OccupiedTimetableRange,
} from '~models/reservation-schedule-v2.model';
import { ReservationScheduleActions } from '~actions/products/reservation-schedule-v2.actions';

export interface ReservationTimetableScheduleState {
  toggledItemPopupKey: string;
  occupiedTimetableRange: DayOfWeekMap<OccupiedTimetableRange[]>;
  dragAndResizeEventItemKey: string;
}

const initialState: ReservationTimetableScheduleState = {
  toggledItemPopupKey: null,
  occupiedTimetableRange: {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  },
  dragAndResizeEventItemKey: null,
};

export const reducer = createReducer(
  initialState,
  on(ReservationScheduleActions.TimetableItemPopupToggle, (state, action) => ({
    ...state,
    toggledItemPopupKey: action.key,
  })),
  on(
    ReservationScheduleActions.CalculateOccupiedTimetableRange,
    (state, { type, ...rest }) => ({
      ...state,
      occupiedTimetableRange: { ...rest },
    })
  ),
  on(ReservationScheduleActions.SetDragAndResizeEventItem, (state, { key }) => {
    return {
      ...state,
      dragAndResizeEventItemKey: key,
    };
  })
);
