<div class="container">
  <div class="guide-wrap">
    <div class="guide-content align-items-center">
      <div class="img-wrap">
        <img src="/assets/img/page-guide/{{ imgSrc }}" alt="" />
      </div>
      <div class="text-wrap">
        <h5 class="ha-guide-head">{{ title }}</h5>
        <ol class="ha-guide-list guide-list__ol" *ngIf="items.length > 0">
          <ng-container *ngFor="let item of items">
            <li>
              <span [innerHTML]="item.text | sanitizeHtml"></span>
              <ul *ngIf="item.subItems">
                <li
                  [attr.data-guide-type]="subItem.type"
                  *ngFor="let subItem of item.subItems"
                  [innerHTML]="subItem.text | sanitizeHtml"
                ></li>
              </ul>
            </li>
          </ng-container>
        </ol>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
