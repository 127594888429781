import { isNil as _isNil } from 'lodash';
import { checkUrl } from '~shared/service/util';
import {
  HospitalInfoReqModel,
  HospitalInfoResModel,
  HospitalInfoUiModel,
  HospitalMedicalEquipmentInfo,
} from '~models/hospital.model';
import {
  AuthTypeEnum,
  HospitalInfo,
  ServiceTypeEnum,
  serviceTypeMapper,
  ServiceTypeKeys,
} from '~models/auth.model';
import { USE_UNTACT_AGENT_SET } from '~models/layout.model';

export function toHospitalInfoUi(
  hospitalInfoRes: HospitalInfoResModel
): HospitalInfoUiModel {
  const hospitalImages = !hospitalInfoRes.images ? [] : hospitalInfoRes.images;

  return {
    ...hospitalInfoRes,
    ...hospitalInfoRes.parking,
    ...hospitalInfoRes.facility,
    images: hospitalImages,
    scheduleImage: hospitalInfoRes.scheduleImage ?? null,
  };
}

export function toHospitalInfoReq(
  hospitalInfoUiChanges: Partial<HospitalInfoUiModel>,
  hospitalInfoUi: HospitalInfoUiModel
): Partial<HospitalInfoReqModel> {
  const hospitalInfo = {
    ...hospitalInfoUiChanges,
    isAvailable: undefined,
    parkingSpaces: undefined,
    isFree: undefined,
    notice: undefined,
    intensiveCareBeds: undefined,
    generalBeds: undefined,
    seniorBeds: undefined,
    neonatalIntensiveCareBeds: undefined,
    deliveryRooms: undefined,
    operationgRooms: undefined,
    emergencyRoomBeds: undefined,
    physicalTherapyRooms: undefined,
  };

  const params: Partial<HospitalInfoReqModel> = {
    parking: {
      isAvailable: hospitalInfoUi.isAvailable,
      parkingSpaces: hospitalInfoUi.parkingSpaces,
      isFree: hospitalInfoUi.isFree,
      notice: hospitalInfoUi.notice,
    },
    facility: {
      intensiveCareBeds: hospitalInfoUi.intensiveCareBeds,
      generalBeds: hospitalInfoUi.generalBeds,
      seniorBeds: hospitalInfoUi.seniorBeds,
      neonatalIntensiveCareBeds: hospitalInfoUi.neonatalIntensiveCareBeds,
      deliveryRooms: hospitalInfoUi.deliveryRooms,
      operationgRooms: hospitalInfoUi.operationgRooms,
      emergencyRoomBeds: hospitalInfoUi.emergencyRoomBeds,
      physicalTherapyRooms: hospitalInfoUi.physicalTherapyRooms,
    },
    ...hospitalInfo,
    careTags: undefined,
    categories: undefined,
    mainDepartment: undefined,
  };

  /**
   * parking
   */
  let isParkingChanges = false;

  if (!_isNil(hospitalInfoUiChanges.isAvailable)) {
    isParkingChanges = true;
    params.parking = {
      ...params.parking,
      isAvailable: hospitalInfoUiChanges.isAvailable,
    };
  }

  if (!_isNil(hospitalInfoUiChanges.parkingSpaces)) {
    isParkingChanges = true;
    params.parking = {
      ...params.parking,
      parkingSpaces: hospitalInfoUiChanges.parkingSpaces,
    };
  }

  if (!_isNil(hospitalInfoUiChanges.isFree)) {
    isParkingChanges = true;
    params.parking = {
      ...params.parking,
      isFree: hospitalInfoUiChanges.isFree,
    };
  }

  if (!_isNil(hospitalInfoUiChanges.notice)) {
    isParkingChanges = true;
    params.parking = {
      ...params.parking,
      notice: hospitalInfoUiChanges.notice,
    };
  }

  if (!isParkingChanges) {
    params.parking = undefined;
  }

  /**
   * facility
   */
  const isFacilityChanges = false;

  // if (!_isNil(hospitalInfoUi.intensiveCareBeds)) {
  //   isFacilityChanges = true;
  //   params.facility = { ...params.facility, intensiveCareBeds: hospitalInfoUi.intensiveCareBeds };
  // }

  // if (!_isNil(hospitalInfoUi.generalBeds)) {
  //   isFacilityChanges = true;
  //   params.facility = { ...params.facility, generalBeds: hospitalInfoUi.generalBeds };
  // }

  // if (!_isNil(hospitalInfoUi.seniorBeds)) {
  //   isFacilityChanges = true;
  //   params.facility = { ...params.facility, seniorBeds: hospitalInfoUi.seniorBeds };
  // }

  // if (!_isNil(hospitalInfoUi.neonatalIntensiveCareBeds)) {
  //   isFacilityChanges = true;
  //   params.facility = { ...params.facility, neonatalIntensiveCareBeds: hospitalInfoUi.neonatalIntensiveCareBeds };
  // }

  // if (!_isNil(hospitalInfoUi.deliveryRooms)) {
  //   isFacilityChanges = true;
  //   params.facility = { ...params.facility, deliveryRooms: hospitalInfoUi.deliveryRooms };
  // }

  // if (!_isNil(hospitalInfoUi.operationgRooms)) {
  //   isFacilityChanges = true;
  //   params.facility = { ...params.facility, operationgRooms: hospitalInfoUi.operationgRooms };
  // }

  // if (!_isNil(hospitalInfoUi.emergencyRoomBeds)) {
  //   isFacilityChanges = true;
  //   params.facility = { ...params.facility, emergencyRoomBeds: hospitalInfoUi.emergencyRoomBeds };
  // }

  // if (!_isNil(hospitalInfoUi.physicalTherapyRooms)) {
  //   isFacilityChanges = true;
  //   params.facility = { ...params.facility, physicalTherapyRooms: hospitalInfoUi.physicalTherapyRooms };
  // }

  if (!isFacilityChanges) {
    params.facility = undefined;
  }

  /**
   * others
   */
  if (hospitalInfoUiChanges.mainDepartment) {
    params.mainDepartment = hospitalInfoUiChanges.mainDepartment._id;
  }

  if (hospitalInfoUiChanges.roadAddress) {
    params.roadAddress =
      hospitalInfoUiChanges.roadAddress +
      ' ' +
      hospitalInfoUiChanges.addressDetail;
  }

  if (hospitalInfoUiChanges.categories) {
    params.categories = hospitalInfoUiChanges.categories.map(i => i._id);
  }

  if (hospitalInfoUiChanges.careTags) {
    params.careTags = hospitalInfoUiChanges.careTags.map(i => i._id);
  }

  return params;
}

export function validateHospitalInfoRequireValueIsEmpty(
  hospitalInfoReq: Partial<HospitalInfoReqModel>
) {
  if (!hospitalInfoReq.mainDepartment) {
    throw new Error('대표 진료과목을 설정해주세요.');
  }

  if (hospitalInfoReq.categories && hospitalInfoReq.categories.length < 1) {
    throw new Error('진료과목을 하나 이상 설정해주세요.');
  }

  if (!hospitalInfoReq.telReal) {
    throw new Error('연결 전화번호를 설정해주세요.');
  }

  if (!hospitalInfoReq.introduction) {
    throw new Error('키워드 홍보 문구를 작성해주세요.');
  }

  if (!hospitalInfoReq.roadAddress) {
    throw new Error('도로명 주소를 설정해주세요.');
  }
}

export function validateHospitalInfo(
  hospitalInfoReq: Partial<HospitalInfoReqModel>
): Partial<HospitalInfoReqModel> {
  if (hospitalInfoReq.note && hospitalInfoReq.note.length > 200) {
    throw new Error('병원설명은 200자 이내로 설정해주세요.');
  }

  const facility = hospitalInfoReq.facility;

  if (facility) {
    if (facility.deliveryRooms && facility.deliveryRooms > 999) {
      throw new Error('분만실 병상수는 999개를 넘을 수 없습니다.');
    }

    if (facility.emergencyRoomBeds && facility.emergencyRoomBeds > 999) {
      throw new Error('응급실 병상수는 999개를 넘을 수 없습니다.');
    }

    if (facility.generalBeds && facility.generalBeds > 999) {
      throw new Error('일반 입원실 일반 병상수는 999개를 넘을 수 없습니다.');
    }

    if (facility.intensiveCareBeds && facility.intensiveCareBeds > 999) {
      throw new Error('중환자실 병상수는 999개를 넘을 수 없습니다.');
    }

    if (
      facility.neonatalIntensiveCareBeds &&
      facility.neonatalIntensiveCareBeds > 999
    ) {
      throw new Error('신생아 중환자실 병상수는 999개를 넘을 수 없습니다.');
    }

    if (facility.operationgRooms && facility.operationgRooms > 999) {
      throw new Error('수술실 병상수는 999개를 넘을 수 없습니다.');
    }

    if (facility.physicalTherapyRooms && facility.physicalTherapyRooms > 999) {
      throw new Error('물리치료실 병상수는 999개를 넘을 수 없습니다.');
    }

    if (facility.seniorBeds && facility.seniorBeds > 999) {
      throw new Error('일반 입원실 상급 병상수는 999개를 넘을 수 없습니다.');
    }
  }

  if (hospitalInfoReq.homepage) {
    if (!checkUrl(hospitalInfoReq.homepage)) {
      throw new Error(
        '홈페이지 url은 http:// 혹은 https:// 로 시작하여야 합니다.'
      );
    }
  }

  return hospitalInfoReq;
}

export function normalizeNumberInput(
  value: number | null = 0,
  options?: { min?: number; max?: number }
): number {
  if (!value) {
    if (options?.min) {
      return options.min;
    }
    return 0;
  } else if (options) {
    if (options.min && value < options.min) {
      return options.min;
    } else if (options.max && value > options.max) {
      return options.max;
    }
  }

  return value;
}

export function normalizeHospitalInfoUi(
  hospitalInfoUi: Partial<HospitalInfoUiModel>
): Partial<HospitalInfoUiModel> {
  const result = { ...hospitalInfoUi };

  result.intensiveCareBeds = normalizeNumberInput(result.intensiveCareBeds, {
    min: 0,
    max: 999,
  });
  result.generalBeds = normalizeNumberInput(result.generalBeds, {
    min: 0,
    max: 999,
  });
  result.seniorBeds = normalizeNumberInput(result.seniorBeds, {
    min: 0,
    max: 999,
  });
  result.neonatalIntensiveCareBeds = normalizeNumberInput(
    result.neonatalIntensiveCareBeds,
    { min: 0, max: 999 }
  );
  result.deliveryRooms = normalizeNumberInput(result.deliveryRooms, {
    min: 0,
    max: 999,
  });
  result.operationgRooms = normalizeNumberInput(result.operationgRooms, {
    min: 0,
    max: 999,
  });
  result.emergencyRoomBeds = normalizeNumberInput(result.emergencyRoomBeds, {
    min: 0,
    max: 999,
  });
  result.physicalTherapyRooms = normalizeNumberInput(
    result.physicalTherapyRooms,
    { min: 0, max: 999 }
  );
  result.parkingSpaces = normalizeNumberInput(result.parkingSpaces, {
    min: 0,
    max: 9999,
  });

  return result;
}

export function toHospitalMedicalEquipmentModel(
  equipment: any
): HospitalMedicalEquipmentInfo {
  return {
    categoryCode: equipment.mainCategoryCode,
    categoryName: equipment.mainCategoryName,
    medicalEquipmentCategory: equipment._id,
  };
}

export const toSortedServiceTypeHospitalInfo = (
  hospitalInfo: HospitalInfo,
  authType: string
): HospitalInfo => {
  /**
   * 백엔드에서 타입을 보내주는 경우.
   * 1. 전화상담진료 진료항목이 있는 진료실이 하나라도 있을 경우
   * 2. 전화상담진료 켜진 진료실이 하나라도 있을 경우
   *
   * 진료실 설정에의해 결정 되는 상황이라 서버에서 안넘겨주더라도 조건 체크해서 값 집어넣었습니다.
   */
  const { agentType, serviceType } = hospitalInfo;

  const isUseTelemedicineAgent =
    USE_UNTACT_AGENT_SET.has(agentType) ||
    authType === AuthTypeEnum.DDOCDOC_ADMIN;
  const serviceTypeSet = new Set(serviceType);

  const { TELEMEDICINE } = serviceTypeMapper;

  if (isUseTelemedicineAgent) {
    serviceTypeSet.add(TELEMEDICINE);
  } else {
    serviceTypeSet.delete(TELEMEDICINE);
  }

  return {
    ...hospitalInfo,
    serviceType: Array.from(serviceTypeSet).sort((a, b) => {
      const leftKey = a as ServiceTypeKeys;
      const rightKey = b as ServiceTypeKeys;
      const left = ServiceTypeEnum[leftKey];
      const right = ServiceTypeEnum[rightKey];
      return left - right;
    }),
  };
};
