<div>
  <div class="img-wrap">
    <img [src]="imgUrl" [alt]="imgDesc" />
  </div>
  <div class="btn-wrap">
    <button
      *ngIf="cancelable"
      class="ha-btn ha-btn-secondary dismiss ha-btn-middle"
      (click)="cancel()"
    >
      {{ cancelText }}
    </button>
    <button
      class="ha-btn dismiss"
      [ngClass]="[themeClass, sizeClass]"
      (click)="confirm()"
    >
      {{ okText }}
    </button>
  </div>
</div>
