import { KioskSustainInfo } from '~models/kiosk-sustain.model';
import { KioskSustainActions } from '~actions/products/kiosk-sustain.actions';
import { createReducer, on } from '@ngrx/store';

export interface KioskSustainState {
  loading: boolean;
  loaded: boolean;
  data: KioskSustainInfo[];
  error?: any;
}

const initialStat: KioskSustainState = {
  loading: false,
  loaded: false,
  data: [],
};

export const kioskSustainReducer = createReducer(
  initialStat,
  on(KioskSustainActions.FetchKioskSustainInfos, state => ({
    ...state,
    loading: true,
  })),
  on(KioskSustainActions.FetchKioskSustainInfosSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    data: action.payload,
  })),
  on(KioskSustainActions.FetchKioskSustainInfosFail, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    error: action.error,
  }))
);
