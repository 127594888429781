import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

function toBlob(canvas, callback, type) {
  const dataURL = canvas.toDataURL(type).split(',')[1];

  setTimeout(() => {
    const binStr = atob(dataURL);
    const len = binStr.length;
    const arr = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }

    callback(new Blob([arr], { type: type || 'image/png' }));
  }, 1000);
}

const blobToFile = (theBlob: Blob, fileName: string, type: string): File => {
  try {
    return new File([theBlob], fileName, { type, lastModified: Date.now() });
  } catch (error) {}
  const b: any = theBlob;
  // A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;
  // b.type = type;

  // Cast to a File() type
  return theBlob as File;
};

/**
 * ImageTools.es6
 * 출처: https://gist.github.com/dcollien/312bce1270a5f511bf4a
 */

function isNotUndefined(val) {
  return typeof val !== 'undefined';
}

const hasBlobConstructor =
  isNotUndefined(Blob) &&
  (() => {
    try {
      return Boolean(new Blob());
    } catch (e) {
      return false;
    }
  })();

const hasArrayBufferViewSupport =
  hasBlobConstructor &&
  isNotUndefined(Uint8Array) &&
  (() => {
    try {
      return new Blob([new Uint8Array(100)]).size === 100;
    } catch (e) {
      return false;
    }
  })();

const hasToBlobSupport = isNotUndefined(HTMLCanvasElement)
  ? !!HTMLCanvasElement.prototype.toBlob
  : false;

const hasBlobSupport =
  hasToBlobSupport ||
  (isNotUndefined(Uint8Array) &&
    isNotUndefined(ArrayBuffer) &&
    isNotUndefined(atob));

const hasReaderSupport = isNotUndefined(FileReader) || isNotUndefined(URL);

function getCanvas(): HTMLCanvasElement {
  // const CANVAS_ID = '_imageServiceCanvas';
  // let canvas = document.getElementById(CANVAS_ID);

  // if (!canvas) {
  //   canvas = document.createElement('canvas');
  //   canvas.id = CANVAS_ID;
  //   document.body.appendChild(canvas);
  // }

  const canvas = document.createElement('canvas');

  return canvas as HTMLCanvasElement;
}

class ImageTools {
  static resize(
    file: File,
    maxDimensions: { width: number; height: number },
    type: string = file.type,
    callback: (b: Blob, result: boolean) => void
  ) {
    // if (typeof maxDimensions === 'function') {
    //     callback = maxDimensions;
    //     maxDimensions = {
    //         width: 640,
    //         height: 480
    //     };
    // }

    // const maxWidth  = maxDimensions.width;
    // const maxHeight = maxDimensions.height;

    if (!ImageTools.isSupported() || !file.type.match(/image.*/)) {
      // console.log('not supported');
      callback(file, false);
      return false;
    }

    if (file.type.match(/image\/gif/)) {
      // Not attempting, could be an animated gif
      callback(file, false);
      // TODO: use https://github.com/antimatter15/whammy to convert gif to webm
      return false;
    }

    const image = document.createElement('img');

    image.width = maxDimensions.width;
    image.height = maxDimensions.height;

    image.onload = imgEvt => {
      const width = maxDimensions.width;
      const height = maxDimensions.height;
      // let isTooLarge = false;

      // if (width >= height && width > maxDimensions.width) {
      //     // width is the largest dimension, and it's too big.
      //     height *= maxDimensions.width / width;
      //     width = maxDimensions.width;
      //     isTooLarge = true;
      // } else if (height > maxDimensions.height) {
      //     // either width wasn't over-size or height is the largest dimension
      //     // and the height is over-size
      //     width *= maxDimensions.height / height;
      //     height = maxDimensions.height;
      //     isTooLarge = true;
      // }

      //   if (!isTooLarge) {
      //       // early exit; no need to resize
      //       console.log('too large?');
      //       callback(file, false);
      //       return;
      //   }

      const canvas = getCanvas();
      canvas.width = width;
      canvas.height = height;

      //   console.log('canvas elem created', canvas);

      const ctx = canvas.getContext('2d');
      ctx.drawImage(image, 0, 0, width, height);

      // console.log('hasToBlobSupport', hasToBlobSupport);

      // canvas.toBlob((blob) => {
      //     callback(blob, true);
      // }, type);

      if (hasToBlobSupport) {
        canvas.toBlob(blob => {
          callback(blob, true);
        }, type);
      } else {
        toBlob(
          canvas,
          (b: Blob) => {
            callback(b, true);
          },
          type
        );
        // const blob = ImageTools._toBlob(canvas, file.type);
        // callback(blob, true);
      }
    };
    ImageTools._loadImage(image, file);

    return true;
  }

  // static _toBlob(canvas, type) {
  //     const dataURI = canvas.toDataURL(type);
  //     const dataURIParts = dataURI.split(',');
  //     let byteString;
  //     if (dataURIParts[0].indexOf('base64') >= 0) {
  //         // Convert base64 to raw binary data held in a string:
  //         byteString = atob(dataURIParts[1]);
  //     } else {
  //         // Convert base64/URLEncoded data component to raw binary data:
  //         byteString = decodeURIComponent(dataURIParts[1]);
  //     }
  //     const arrayBuffer = new ArrayBuffer(byteString.length);
  //     const intArray = new Uint8Array(arrayBuffer);

  //     for (let i = 0; i < byteString.length; i += 1) {
  //         intArray[i] = byteString.charCodeAt(i);
  //     }

  //     const mimeString = dataURIParts[0].split(':')[1].split(';')[0];
  //     let blob = null;

  //     if (hasBlobConstructor) {
  //         blob = new Blob(
  //             [hasArrayBufferViewSupport ? intArray : arrayBuffer],
  //             {type: mimeString}
  //         );
  //     } else {
  //       throw new Error('cannot found Blob Constructor');
  //         // const bb = new BlobBuilder();
  //         // bb.append(arrayBuffer);
  //         // blob = bb.getBlob(mimeString);
  //     }

  //     return blob;
  // }

  static _loadImage(image, file, callback?) {
    if (isNotUndefined(URL)) {
      image.src = URL.createObjectURL(file);

      if (callback) {
        callback();
      }
    } else {
      const reader = new FileReader();
      reader.onload = evt => {
        image.src = (evt.target as FileReader).result;
        if (callback) {
          callback();
        }
      };
      reader.readAsDataURL(file);
    }
  }

  static isSupported() {
    const bRet =
      isNotUndefined(HTMLCanvasElement) && hasBlobSupport && hasReaderSupport;

    //   console.log('isSupported', bRet);

    return bRet;
  }
}

@Injectable()
export class ImageService {
  resizeJpg(file: File, width: number, height: number) {
    return new Observable<File>(sub => {
      const type = 'image/jpeg';

      ImageTools.resize(file, { width, height }, type, (b, result) => {
        if (!result) {
          sub.error(new Error('image resizing failre.'));
        } else {
          sub.next(blobToFile(b, file.name.replace(/\.(png)$/, '.jpg'), type));
        }
        sub.complete();
      });
    });
  }

  toDataUrl(file: File) {
    return new Observable<string>(sub => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        sub.next(reader.result as string);
        sub.complete();
      };
      reader.onerror = () => {
        sub.error(new Error('data url convert failre.'));
        sub.complete();
      };
    });
  }
}
