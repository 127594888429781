import { createAction, props } from '@ngrx/store';
import {
  PaymentsActivationDateUpdateReq,
  PaymentsCSVFetchReq,
  PaymentsFilterValues,
  PaymentsLogFetchReq,
  PaymentsLogFetchRes,
  PaymentsRegisterReq,
  PaymentsRegisterRes,
  PaymentsSetting,
  PaymentsSettingUpdateReq,
  PaymentsSettingUpdateRes,
  PaymentsUseUpdateReq,
} from '~models/payments.model';

export const enum PaymentsActionTypes {
  ERROR_KEY_RESET = '[Payments] ERROR KEY RESET',
  REQUEST_FAIL = '[Payments] REQUEST FAIL',
  REGISTER_PAYMENTS = '[Payments] REGISTER PAYMENTS',
  REGISTER_PAYMENTS_SUCCESS = '[Payments] REGISTER PAYMENTS SUCCESS',
  REGISTER_PAYMENTS_FAIL = '[Payments] REGISTER PAYMENTS FAIL',
  FETCH_PAYMENTS_SETTING = '[Payments] FETCH PAYMENTS SETTING',
  FETCH_PAYMENTS_SETTING_SUCCESS = '[Payments] FETCH PAYMENTS SETTING SUCCESS',
  UPDATE_PAYMENTS_SETTING = '[Payments] UPDATE PAYMENTS SETTING',
  UPDATE_PAYMENTS_SETTING_SUCCESS = '[Payments] UPDATE PAYMENTS SETTING SUCCESS',
  UPDATE_PAYMENTS_SETTING_FAIL = '[Payments] UPDATE PAYMENTS SETTING FAIL',
  UPDATE_PAYMENTS_USE = '[Payments] UPDATE PAYMENTS USE',
  UPDATE_PAYMENTS_USE_SUCCESS = '[Payments] UPDATE PAYMENTS USE SUCCESS',
  UPDATE_PAYMENTS_ACTIVATION_DATE = '[Payments] UPDATE PAYMENTS ACTIVATION DATE',
  UPDATE_PAYMENTS_ACTIVATION_DATE_SUCCESS = '[Payments] UPDATE PAYMENTS ACTIVATION DATE SUCCESS',
  UPDATE_PAYMENTS_ACTIVATION_DATE_FAIL = '[Payments] UPDATE PAYMENTS ACTIVATION DATE FAIL',
  FETCH_PAYMENTS_LOG = '[Payments] FETCH PAYMENTS LOG',
  FETCH_PAYMENTS_LOG_SUCCESS = '[Payments] FETCH PAYMENTS LOG SUCCESS',
  REQUEST_PAYMENTS_CSV = '[Payments] REQUEST PAYMENTS CSV',
  REQUEST_PAYMENTS_CSV_SUCCESS = '[Payments] REQUEST PAYMENTS CSV SUCCESS',
  FETCH_PAYMENTS_FILTER_VALUES = '[Payments] FETCH PAYMENTS FILTER VALUES',
  FETCH_PAYMENTS_FILTER_VALUES_SUCCESS = '[Payments] FETCH PAYMENTS FILTER VALUES SUCCESS',
}

export const PaymentsErrorKeyReset = createAction(
  PaymentsActionTypes.ERROR_KEY_RESET
);

export const PaymentsRequestFail = createAction(
  PaymentsActionTypes.REQUEST_FAIL,
  props<{ error: any }>()
);

export const PaymentsRegister = createAction(
  PaymentsActionTypes.REGISTER_PAYMENTS,
  props<{ req: PaymentsRegisterReq }>()
);

export const PaymentsRegisterSuccess = createAction(
  PaymentsActionTypes.REGISTER_PAYMENTS_SUCCESS,
  props<{ res: PaymentsRegisterRes }>()
);

export const PaymentsRegisterFail = createAction(
  PaymentsActionTypes.REGISTER_PAYMENTS_FAIL,
  props<{ errorKey: any }>()
);

export const PaymentsSettingFetch = createAction(
  PaymentsActionTypes.FETCH_PAYMENTS_SETTING
);

export const PaymentsSettingFetchSuccess = createAction(
  PaymentsActionTypes.FETCH_PAYMENTS_SETTING_SUCCESS,
  props<{ res: PaymentsSetting }>()
);

export const PaymentsSettingUpdate = createAction(
  PaymentsActionTypes.UPDATE_PAYMENTS_SETTING,
  props<{ req: PaymentsSettingUpdateReq }>()
);

export const PaymentsSettingUpdateSuccess = createAction(
  PaymentsActionTypes.UPDATE_PAYMENTS_SETTING_SUCCESS,
  props<{ res: PaymentsSettingUpdateRes }>()
);

export const PaymentsSettingUpdateFail = createAction(
  PaymentsActionTypes.UPDATE_PAYMENTS_SETTING_FAIL,
  props<{ errorKey: any }>()
);

export const PaymentsUseUpdate = createAction(
  PaymentsActionTypes.UPDATE_PAYMENTS_USE,
  props<{ req: PaymentsUseUpdateReq }>()
);

export const PaymentsUseUpdateSuccess = createAction(
  PaymentsActionTypes.UPDATE_PAYMENTS_USE_SUCCESS,
  props<{ updater: PaymentsUseUpdateReq }>()
);

export const PaymentsActivationDateUpdate = createAction(
  PaymentsActionTypes.UPDATE_PAYMENTS_ACTIVATION_DATE,
  props<{ req: PaymentsActivationDateUpdateReq }>()
);

export const PaymentsActivationDateUpdateSuccess = createAction(
  PaymentsActionTypes.UPDATE_PAYMENTS_ACTIVATION_DATE_SUCCESS,
  props<{ updater: PaymentsActivationDateUpdateReq }>()
);

export const PaymentsActivationDateUpdateFail = createAction(
  PaymentsActionTypes.UPDATE_PAYMENTS_ACTIVATION_DATE_FAIL,
  props<{ errorKey: any }>()
);

export const PaymentsLogsFetch = createAction(
  PaymentsActionTypes.FETCH_PAYMENTS_LOG,
  props<{ req: PaymentsLogFetchReq }>()
);

export const PaymentsLogsFetchSuccess = createAction(
  PaymentsActionTypes.FETCH_PAYMENTS_LOG_SUCCESS,
  props<{ res: PaymentsLogFetchRes }>()
);

export const PaymentsCSVRequest = createAction(
  PaymentsActionTypes.REQUEST_PAYMENTS_CSV,
  props<{ req: PaymentsCSVFetchReq }>()
);

export const PaymentsCSVRequestSuccess = createAction(
  PaymentsActionTypes.REQUEST_PAYMENTS_CSV_SUCCESS
);

export const PaymentsFilterValuesFetch = createAction(
  PaymentsActionTypes.FETCH_PAYMENTS_FILTER_VALUES
);

export const PaymentsFilterValuesFetchSuccess = createAction(
  PaymentsActionTypes.FETCH_PAYMENTS_FILTER_VALUES_SUCCESS,
  props<{ res: PaymentsFilterValues }>()
);

export const PaymentsActions = {
  PaymentsErrorKeyReset,
  PaymentsRequestFail,
  PaymentsRegister,
  PaymentsRegisterSuccess,
  PaymentsRegisterFail,
  PaymentsSettingFetch,
  PaymentsSettingFetchSuccess,
  PaymentsSettingUpdate,
  PaymentsSettingUpdateSuccess,
  PaymentsSettingUpdateFail,
  PaymentsUseUpdate,
  PaymentsUseUpdateSuccess,
  PaymentsActivationDateUpdate,
  PaymentsActivationDateUpdateSuccess,
  PaymentsActivationDateUpdateFail,
  PaymentsLogsFetch,
  PaymentsLogsFetchSuccess,
  PaymentsCSVRequest,
  PaymentsCSVRequestSuccess,
  PaymentsFilterValuesFetch,
  PaymentsFilterValuesFetchSuccess,
};
