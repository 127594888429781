import { KioskFetchResult, KioskPrintingSetupModel } from '~models/kiosk.model';
import { KioskActions } from '~actions/products/kiosk-manage.actions';
import { createReducer, on } from '@ngrx/store';

export interface KioskManageState {
  loading: boolean;
  loaded: boolean;
  data: KioskFetchResult;
  /**
   * 급지대 타입.
   * - 1: 1단
   * - 2: 2단
   */
  feederType: number;
}

const initialState: KioskManageState = {
  loading: false,
  loaded: false,
  data: {} as KioskFetchResult,
  feederType: 2,
};

export const reducer = createReducer(
  initialState,
  on(KioskActions.FetchKioskData, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(KioskActions.FetchKioskDataSuccess, (state, action) => ({
    ...state,
    loaded: true,
    loading: false,
    data: action.payload,
  })),
  on(KioskActions.UpdateKioskData, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(KioskActions.UpdateKioskDataSuccess, (state, action) => ({
    ...state,
    loading: false,
  })),
  on(KioskActions.ChangeKioskUseCertPrinting, (state, action) => {
    const { certificationPrintingSetup } = state.data;
    const {
      receiptPayment,
      receiptDetail,
      patientPrescription,
    } = certificationPrintingSetup;
    const isSetup = Object.values(certificationPrintingSetup).reduce(
      (result, item: KioskPrintingSetupModel) => {
        return result ? result : item.usePrinting;
      },
      false
    );
    return {
      ...state,
      data: {
        ...state.data,
        useCertificationPrinting: action.payload,
        ...(!isSetup && {
          certificationPrintingSetup: {
            ...certificationPrintingSetup,
            receiptPayment: { ...receiptPayment, usePrinting: true },
            receiptDetail: { ...receiptDetail, usePrinting: true },
            patientPrescription: {
              ...patientPrescription,
              usePrinting: true,
            },
          },
        }),
      },
    };
  }),
  on(KioskActions.ChangeKioskCertSetup, (state, action) => ({
    ...state,
    data: {
      ...state.data,
      certificationPrintingSetup: {
        ...state.data.certificationPrintingSetup,
        [action.payload.key]: action.payload.setup,
      },
    },
  }))
);

export const getLoaded = (state: KioskManageState) => state.loaded;
export const getLoading = (state: KioskManageState) => state.loading;
export const getKioskData = (state: KioskManageState) => state.data;
