import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import {
  RetreatElseWhere,
  SimpleModalService,
} from '../../service/simple.modal.service';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert.modal.component.html',
  styleUrls: ['./alert.modal.component.scss'],
})
export class AlertModalComponent {
  @ViewChild('btnAccept', { static: true }) btnAccept: ElementRef;
  @ViewChild('btnRetreat', { static: true }) btnRetreat: ElementRef;

  @Input() open: boolean;

  mode: string;
  title: string;
  desc: string;
  okText: string;
  cancelText: string;
  elseWhere: RetreatElseWhere;
  elseWhereText = '';
  hasElseWhereText = false;
  isOverlayPassTrue: boolean;

  constructor(public service: SimpleModalService) {
    service.modal.subscribe(
      ({
        mode,
        title,
        desc,
        okText,
        cancelText,
        open,
        elseWhere,
        isOverlayPassTrue,
      }) => {
        this.mode = mode;
        this.title = title;
        this.desc = desc;
        this.open = open;
        this.okText = okText || '확인';
        this.cancelText = cancelText || '취소';
        this.hasElseWhereText = !!(elseWhere && elseWhere.text);
        this.elseWhereText = elseWhere ? elseWhere.text : '';
        this.elseWhere = elseWhere || null;
        this.isOverlayPassTrue = isOverlayPassTrue;

        if (open) {
          this.getFocusElem().then(elem => elem.focus());
        }
      }
    );
  }

  isOpen(): boolean {
    return this.open === true;
  }

  isConfirmMode(): boolean {
    return this.mode === 'confirm';
  }

  isRetreatMode(): boolean {
    return this.mode === 'retreat';
  }

  getFocusElem(): Promise<HTMLButtonElement> {
    return new Promise((resolve, reject) => {
      let id = 'btnAccept';

      if (this.isRetreatMode()) {
        id = 'btnRetreat';
      }

      const elem = document.getElementById(id);

      setTimeout(() => {
        if (!elem) {
          this.getFocusElem().then(elm => resolve(elm));

          return;
        }

        resolve(elem as HTMLButtonElement);
      }, 150);
    });
  }

  close(event, val: boolean): void {
    event.stopPropagation();

    this.service.close(val, this.elseWhere);
  }

  clickOverlay(event): void {
    event.stopPropagation();

    this.service.close(this.isOverlayPassTrue, this.elseWhere);
  }
}
