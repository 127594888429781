import { Subject } from 'rxjs';

// (function() {
//   const d = document;

//   function init() {
//     // Links
//     const anchor1Link  = d.getElementById('anchor1Link');
//     const anchor2Link  = d.getElementById('anchor2Link');
//     const anchor3Link  = d.getElementById('anchor3Link');
//     const anchor4Link  = d.getElementById('anchor4Link');
//     const anchor1Link2 = d.getElementById('anchor1Link2');
//     const anchor2Link2 = d.getElementById('anchor2Link2');
//     const anchor3Link2 = d.getElementById('anchor3Link2');
//     const anchor4Link2 = d.getElementById('anchor4Link2');
//     // Anchors
//     const anchor1      = d.getElementById('anchor1');
//     const anchor2      = d.getElementById('anchor2');
//     const anchor3      = d.getElementById('anchor3');
//     const anchor4      = d.getElementById('anchor4');

//     anchor1Link.addEventListener('click', (e) => { scrollTo(anchor1, e); }, false);
//     anchor1Link2.addEventListener('click', (e) => { scrollTo(anchor1, e); }, false);
//     anchor2Link.addEventListener('click', (e) => { scrollTo(anchor2, e); }, false);
//     anchor2Link2.addEventListener('click', (e) => { scrollTo(anchor2, e); }, false);
//     // anchor2Link.onclick = function() { scrollToSimple(document.documentElement, 500, 3000); }
//     // anchor2Link2.onclick = function() { scrollToSimple(document.documentElement, 0, 3000); }
//     anchor3Link.addEventListener('click', (e) => { scrollTo(anchor3, e); }, false);
//     anchor3Link2.addEventListener('click', (e) => { scrollTo(anchor3, e); }, false);
//     anchor4Link.addEventListener('click', (e) => { scrollTo(anchor4.offsetTop, e); }, false);
//     anchor4Link2.addEventListener('click', (e) => { scrollTo(anchor4.offsetTop, e); }, false);

//     console.log(anchor2); // DEBUG
//     // console.log('anchor1: '+scrollTopValue(anchor1)+' / '+offsetTopValue(anchor1)); //DEBUG
//     // console.log('anchor2: '+scrollTopValue(anchor2)+' / '+offsetTopValue(anchor2)); //DEBUG
//     // console.log('anchor3: '+scrollTopValue(anchor3)+' / '+offsetTopValue(anchor3)); //DEBUG
//     // console.log('anchor4: '+scrollTopValue(anchor4)+' / '+offsetTopValue(anchor4)); //DEBUG
//     // d.addEventListener('scroll', (e) => { console.log(e) }, false); //DEBUG

//     // console.log('App loaded. Have fun!');
//   }

//   // function scrollTopValue(domElement) { //DEBUG
//   //   return 'scrollTopValue:', domElement.scrollTop;
//   // }
//   // function offsetTopValue(domElement) { //DEBUG
//   //   return 'offsetTopValue:', domElement.offsetTop;
//   // }
//   /*function scrollToSimple(element, to, duration) { //FIXME finish this
// 		if (duration < 0) return;
// 		var difference = to - element.offsetTop;
// 		var perTick = difference / duration * 10;
// 		console.log('perTick', perTick); //DEBUG

// 		setTimeout(function() {
// 			console.log('element.scrollTop:', element.scrollTop); //DEBUG
// 			element.scrollTop += perTick;
// 			console.log('element.scrollTop:', element.scrollTop); //DEBUG
// 			scrollTo(element, to, duration - 10);
// 		}, 10);
// 	}*/

//   // cf. https://gist.github.com/james2doyle/5694700
//   // requestAnimationFrame for Smart Animating https://goo.gl/sx5sts

//   init();
// })();

const requestAnimFrame = (() => {
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    // window.mozRequestAnimationFrame ||
    // tslint:disable-next-line: only-arrow-functions
    function(callback) {
      window.setTimeout(callback, 1000 / 60);
    }
  );
})();

export function animationScrollTo(toId: string, duration = 1500) {
  const toElem = document.getElementById(toId);
  const to = toElem && toElem.offsetTop;

  // console.log('to', to);

  // because it's so fucking difficult to detect the scrolling element, just move them all
  function move(amount: number) {
    // document.scrollingElement.scrollTop = amount; //FIXME Test that
    document.documentElement.scrollTop = amount;
    // document.body.parentNode.scrollTop = amount;
    document.body.scrollTop = amount;
  }

  function position() {
    return window.scrollY;
  }

  const start = position();
  const change = to && to - start;
  const increment = 20;
  let currentTime = 0;
  const obs = new Subject<number>();
  // console.log('start:', start); // DEBUG
  // console.log('to:', to); // DEBUG
  // console.log('change:', change); // DEBUG
  // console.log('duration', duration);

  const animateScroll = () => {
    // increment the time
    currentTime += Math.abs(increment);
    const val = easeInOutQuad(currentTime, start, change, duration);

    // console.log('move val', val, start, change, raw);

    // move the document.body
    move(val + 1);
    // do the animation unless its over
    if (currentTime < duration) {
      requestAnimFrame(animateScroll);
    } else {
      obs.next(val);
      obs.complete();
    }
  };

  animateScroll();

  return obs;
}

// -------------------- Unimportant js functions --------------------
// easing functions https://goo.gl/5HLl8
// t = current time
// b = start value
// c = change in value
// d = duration
function easeInOutQuad(t: number, b: number, c: number | null, d: number) {
  t /= d / 2;
  if (t < 1) {
    return (c / 2) * t * t + b;
  }
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
}

function easeInCubic(t: number, b: number, c: number, d: number) {
  const tc = (t /= d) * t * t;
  return b + c * tc;
}

function inOutQuintic(t: number, b: number, c: number, d: number) {
  const ts = (t /= d) * t;
  const tc = ts * t;
  return b + c * (6 * tc * ts + -15 * ts * ts + 10 * tc);
}

function isDomElement(obj: any) {
  return obj instanceof Element;
}

function isMouseEvent(obj: any) {
  return obj instanceof MouseEvent;
}

function findScrollingElement(element) {
  // FIXME Test this too
  do {
    if (
      element.clientHeight < element.scrollHeight ||
      element.clientWidth < element.scrollWidth
    ) {
      return element;
    }
  } while (element === element.parentNode);
}
