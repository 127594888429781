import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { includes as _includes } from 'lodash';

const NOTION_LINK_PAGE_KEY_MAP = {
  HOSPITAL_INFO_NOTICE: '347e02ac45514b4ea318b8e9bc47a5cc',
  HOSPITAL_INFO_SETUP: '9833837a41bc4d7c914b8ba77fca950d',

  PATIENT_STATUS_PENALTY_STATUS: '73c352c9bf934e4fb972bbe66b2b416e',
  PATIENT_STATUS_CHILD_SCREENING: '8c0235ee408b42c384e494b6119c2b21',
  PATIENT_STATUS_CANCEL_STATUS: '463dbdbb032044eebd8cb4a4def40aad',

  DDOCDOC_SERVICE_MOBILE_SCHEDULE_RECEIPT: '02b5fe98915045fbb90c7305dd13c420',
  DDOCDOC_SERVICE_MOBILE_SCHEDULE_RESERVATION:
    '3838df72bbe642d38842519e9d1598ef',
  DDOCDOC_SERVICE_MOBILE_SCHEDULE_UNTACT: '02b5fe98915045fbb90c7305dd13c420',
  DDOCDOC_SERVICE_MOBILE_PAYMENT: 'cba4689b0b9c40078a17606ebf7706e9',
  DDOCDOC_SERVICE_MOBILE_PAPER: 'd037671d0495473ea98808e1153c3907',
  DDOCDOC_SERVICE_MOBILE_WAITING: '6db8951daab843a7bb48564ec9c5fdd9',
  DDOCDOC_SERVICE_KIOSK: '3d106544953541149346cd0c0f8037f2',
  DDOCDOC_SERVICE_TABLET: 'b301c94a969b4b5395672e66549e75ce',
} as const;

@Component({
  selector: 'app-user-guide-button',
  templateUrl: './user-guide-button.component.html',
  styleUrls: ['./user-guide-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserGuideButtonComponent implements OnInit {
  constructor(private router: Router) {}

  get link() {
    const notionPageKey = (() => {
      if (_includes(this.router.url, '/hospital-info/notices')) {
        return NOTION_LINK_PAGE_KEY_MAP.HOSPITAL_INFO_NOTICE;
      }
      if (_includes(this.router.url, '/hospital-info/setup')) {
        return NOTION_LINK_PAGE_KEY_MAP.HOSPITAL_INFO_SETUP;
      }
      if (_includes(this.router.url, '/patient-status/penalty-status')) {
        return NOTION_LINK_PAGE_KEY_MAP.PATIENT_STATUS_PENALTY_STATUS;
      }
      if (_includes(this.router.url, '/patient-status/child-screening')) {
        return NOTION_LINK_PAGE_KEY_MAP.PATIENT_STATUS_CHILD_SCREENING;
      }
      if (_includes(this.router.url, '/patient-status/cancel-status')) {
        return NOTION_LINK_PAGE_KEY_MAP.PATIENT_STATUS_CANCEL_STATUS;
      }
      if (
        _includes(this.router.url, '/ddocdoc-service/mobile-schedule/receipt')
      ) {
        return NOTION_LINK_PAGE_KEY_MAP.DDOCDOC_SERVICE_MOBILE_SCHEDULE_RECEIPT;
      }
      if (
        _includes(
          this.router.url,
          '/ddocdoc-service/mobile-schedule/reservation'
        )
      ) {
        return NOTION_LINK_PAGE_KEY_MAP.DDOCDOC_SERVICE_MOBILE_SCHEDULE_RESERVATION;
      }
      if (
        _includes(this.router.url, '/ddocdoc-service/mobile-schedule/untact')
      ) {
        return NOTION_LINK_PAGE_KEY_MAP.DDOCDOC_SERVICE_MOBILE_SCHEDULE_UNTACT;
      }
      if (_includes(this.router.url, '/ddocdoc-service/mobile-payment')) {
        return NOTION_LINK_PAGE_KEY_MAP.DDOCDOC_SERVICE_MOBILE_PAYMENT;
      }
      if (_includes(this.router.url, '/ddocdoc-service/mobile-paper')) {
        return NOTION_LINK_PAGE_KEY_MAP.DDOCDOC_SERVICE_MOBILE_PAPER;
      }
      if (_includes(this.router.url, '/ddocdoc-service/mobile-waiting')) {
        return NOTION_LINK_PAGE_KEY_MAP.DDOCDOC_SERVICE_MOBILE_WAITING;
      }
      if (_includes(this.router.url, '/ddocdoc-service/kiosk')) {
        return NOTION_LINK_PAGE_KEY_MAP.DDOCDOC_SERVICE_KIOSK;
      }
      if (_includes(this.router.url, '/ddocdoc-service/tablet')) {
        return NOTION_LINK_PAGE_KEY_MAP.DDOCDOC_SERVICE_TABLET;
      }
    })();
    return `https://boostbrothers.notion.site/${notionPageKey}`;
  }

  ngOnInit(): void {}

  handleClick() {
    window.open(this.link, '', '_blank');
  }
}
