import {
  AccountInfo,
  AccountUpdateInfo,
  AuthLoginInfo,
  CreateInfo,
  LoginSuccessInfo,
} from '~models/auth.model';
import { createAction, props } from '@ngrx/store';

export const AuthActionTypes = {
  LOGIN: '[Auth] Login',
  LOGIN_SUCCESS: '[Auth] Login Success',
  LOGIN_COMPLETE: '[Auth] Login Complete',
  LOGIN_FAILURE: '[Auth] Login Failure',
  LOGIN_REDIRECT: '[Auth] Login Redirect',
  LOGOUT: '[AUTH] Logout',
  ACCOUNT_CREATE: '[Auth] Account Create',
  ACCOUNT_CREATE_COMPLETE: '[Auth] Account Create Complete',
  ACCOUNT_CREATE_FAILURE: '[Auth] Account Create Failure',
  ACCOUNT_UPDATE: '[Auth] Account Update',
  ACCOUNT_UPDATE_COMPLETE: '[Auth] Account Update Complete',
  ACCOUNT_UPDATE_FAILURE: '[Auth] Account Update Failure',
  ACCOUNT_FETCH: '[Auth] Account Fetch',
  ACCOUNT_FETCH_COMPLETE: '[Auth] Account Fetch Complete',
  ACCOUNT_FETCH_FAILURE: '[Auth] Account Fetch Failure',
  ACCOUNT_EXPIRED: '[Auth] Account Expired',
  PARTNER_HOSPITAL_CHANGE: '[Auth] Partner Hospital Change',
  PARTNER_HOSPITAL_CHANGE_COMPLETE: '[Auth] Partner Hospital Change Complete',
  PARTNER_HOSPITAL_CHANGE_FAIL: '[Auth] Partner Hospital Change Fail',
  PHONE_CERTIFICATION_CHECK: '[Auth] Phone Certification Check',
  PHONE_CERTIFICATION_CONFIRM: '[Auth] Phone Certification Confirm',
  PHONE_CERTIFICATION_CONFIRM_COMPLETE:
    '[Auth] Phone Certification Confirm Complete',
  PHONE_CERTIFICATION_CONFIRM_FAIL: '[Auth] Phone Certification Confirm Fail',
} as const;

// 로그인
export const Login = createAction(
  AuthActionTypes.LOGIN,
  props<{
    payload: {
      authLoginInfo: AuthLoginInfo;
      guideType?: string;
    };
  }>()
);

export const LoginSuccess = createAction(
  AuthActionTypes.LOGIN_SUCCESS,
  props<{ payload: LoginSuccessInfo }>()
);

export const LoginComplete = createAction(AuthActionTypes.LOGIN_COMPLETE);

export const LoginFailure = createAction(
  AuthActionTypes.LOGIN_FAILURE,
  props<{ payload: any }>()
);

export const LoginRedirect = createAction(AuthActionTypes.LOGIN_REDIRECT);

export const Logout = createAction(AuthActionTypes.LOGOUT);

// 계정 생성
export const AccountCreate = createAction(
  AuthActionTypes.ACCOUNT_CREATE,
  props<{ payload: CreateInfo }>()
);

export const AccountCreateComplete = createAction(
  AuthActionTypes.ACCOUNT_CREATE_COMPLETE
);

export const AccountCreateFailure = createAction(
  AuthActionTypes.ACCOUNT_CREATE_FAILURE,
  props<{ payload: any }>()
);

// 계정 업데이트
export const AccountUpdate = createAction(
  AuthActionTypes.ACCOUNT_UPDATE,
  props<{ payload: AccountUpdateInfo }>()
);

export const AccountUpdateComplete = createAction(
  AuthActionTypes.ACCOUNT_UPDATE_COMPLETE,
  props<{ payload: AccountInfo }>()
);

export const AccountUpdateFailure = createAction(
  AuthActionTypes.ACCOUNT_UPDATE_FAILURE,
  props<{ payload: any }>()
);

// 계정 정보 조회
export const AccountFetch = createAction(
  AuthActionTypes.ACCOUNT_FETCH,
  props<{ payload?: LoginSuccessInfo }>()
);

export const AccountFetchComplete = createAction(
  AuthActionTypes.ACCOUNT_FETCH_COMPLETE,
  props<{ payload: AccountInfo }>()
);

export const AccountFetchFailure = createAction(
  AuthActionTypes.ACCOUNT_FETCH_FAILURE,
  props<{ payload: any }>()
);

export const AccountExpired = createAction(
  AuthActionTypes.ACCOUNT_EXPIRED,
  props<{ payload?: any }>()
);

// 파트너 병원 Change
export const ChangeHospital = createAction(
  AuthActionTypes.PARTNER_HOSPITAL_CHANGE,
  props<{ payload: AccountInfo }>()
);

export const ChangeHospitalSuccess = createAction(
  AuthActionTypes.PARTNER_HOSPITAL_CHANGE_COMPLETE,
  props<{ payload: AccountInfo }>()
);

export const ChangeHospitalFail = createAction(
  AuthActionTypes.PARTNER_HOSPITAL_CHANGE_FAIL,
  props<{ payload: any }>()
);

export const PhoneCertificationCheck = createAction(
  AuthActionTypes.PHONE_CERTIFICATION_CHECK,
  props<{ payload: { userPhone: string } }>()
);

export const PhoneCertificationConfirm = createAction(
  AuthActionTypes.PHONE_CERTIFICATION_CONFIRM,
  props<{ payload: { userPhone: string; certNum: string } }>()
);

export const PhoneCertificationConfirmComplete = createAction(
  AuthActionTypes.PHONE_CERTIFICATION_CONFIRM,
  props<{ payload: { token: string } }>()
);

export const AuthActions = {
  Login,
  LoginSuccess,
  LoginComplete,
  LoginFailure,
  AccountCreate,
  AccountCreateComplete,
  AccountCreateFailure,
  AccountUpdate,
  AccountUpdateComplete,
  AccountUpdateFailure,
  AccountFetch,
  AccountFetchComplete,
  AccountFetchFailure,
  AccountExpired,
  LoginRedirect,
  Logout,
  ChangeHospital,
  ChangeHospitalSuccess,
  ChangeHospitalFail,
  PhoneCertificationCheck,
  PhoneCertificationConfirm,
  PhoneCertificationConfirmComplete,
};
