import {
  Faq,
  FaqQueryKeyword,
  FaqQueryParams,
  ALL_KEYWORD_TYPE,
  KEYWORD_OPTIONS,
} from '~models/faq.model';
import { HttpRes } from '~models/common.model';
import { stringify } from 'qs';
import * as moment from 'moment';

export function toFixedFAQ(response: HttpRes<Faq>): HttpRes<Faq> {
  return {
    ...response,
    items: response.items.map(item => ({
      ...item,
      _id: `fixed_${item._id}`,
      topNoti: true,
      createdAt: moment(item.createdAt).format('YYYY.MM.DD'),
    })),
  };
}

export function toFAQ(response: HttpRes<Faq>): HttpRes<Faq> {
  return {
    ...response,
    items: response.items.map(item => ({
      ...item,
      topNoti: false,
      createdAt: moment(item.createdAt).format('YYYY.MM.DD'),
    })),
  };
}

export function toFaqSearchParams({
  searchType = KEYWORD_OPTIONS[0].value,
  keyword = '',
}) {
  return {
    ...(searchType === ALL_KEYWORD_TYPE
      ? {
          content: keyword,
          title: keyword,
        }
      : {
          [searchType]: keyword,
        }),
  };
}

const createOrQuery = (
  emrTypes: { [key: string]: string }[] = [],
  keywords: FaqQueryKeyword = {}
) => {
  const orQuery = emrTypes.reduce((acc, emrType) => {
    return [
      ...acc,
      ...(Object.entries(keywords).length > 0
        ? Object.entries(keywords).reduce((keywordAcc, [key, value]) => {
            return value
              ? [
                  ...keywordAcc,
                  {
                    [`${key}_contains`]: value,
                    ...emrType,
                  },
                ]
              : [...keywordAcc];
          }, [] as any[])
        : [emrType]),
    ];
  }, [] as any[]);

  // 전체를 돌고 값이 비었다면 emrType리턴
  if (orQuery.length === 0) {
    return emrTypes;
  }

  return orQuery;
};

interface FaqQuery extends FaqQueryParams {
  agentType?: string;
}

export const toFaqQuery = ({
  agentType = '',
  category = '',
  limit = -1,
  skip = 0,
  keywords = {},
  isFixed = false,
}: FaqQuery): string => {
  return stringify({
    _where: {
      _or: createOrQuery([{ ['emrTypes.type_eq']: agentType }], keywords),
    },
    _limit: limit,
    _start: skip,
    isFixed,
    _sort: 'created_at',
    ...(category && { category }),
  });
};
