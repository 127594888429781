import { Component, Input, OnInit } from '@angular/core';
import { OperatingTimeModel } from '~models/hospital.model';
import { some as _some, values as _values, isEmpty as _isEmpty } from 'lodash';

@Component({
  selector: 'app-card-time-table',
  templateUrl: './card-time-table.component.html',
  styleUrls: ['./card-time-table.component.scss'],
})
export class CardTimeTableComponent implements OnInit {
  @Input() itemGroup: OperatingTimeModel['schedule'];
  @Input() dayGroup: string[] = [];
  @Input() title = '';

  get hasSchedule() {
    return _some(_values(this.itemGroup), item => !_isEmpty(item.startTime));
  }

  ngOnInit(): void {}

  dayClass(dateKey: string) {
    switch (dateKey) {
      case 'saturday':
        return 'text-day-blue';
      case 'sunday':
        return 'text-day-red';
      case 'holiday':
        return 'text-day-red';
    }
  }

  offLabel(dateKey): string {
    switch (dateKey) {
      case 'lunch':
      case 'dinner':
        return '설정 안 함';
      default:
        return this.hasSchedule ? '휴진' : '설정 안 함';
    }
  }
}
