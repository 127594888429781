import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { DropdownModel } from '~models/common';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnDestroy {
  @Input() showSelected = true;
  @Input() items: DropdownModel[];
  @Input() placeholder: string;
  @Input() size: string;
  @Input() scrollable = false;

  @Input() set value(val: any) {
    const items = this.items;
    const placeholder = this.placeholder || '';

    if (!items || items.length === 0) {
      this.selectedText = placeholder;
      this.isPlaceholder = true;

      return;
    }

    const len = items.length;

    for (let i = 0; i < len; i++) {
      if (items[i].value === val) {
        this.selectedText = items[i].text;
        this.isPlaceholder = false;
        return;
      }
    }

    this.selectedText = placeholder;
    this.isPlaceholder = true;
  }

  @Input() width: string;
  @Input() menuDir: string;
  @Input() placement: string;
  @Input() textAlign: string;
  @Input() disabled: boolean;

  @Output() dropDownSelect = new EventEmitter<any>();

  @ViewChild('dropdownBtn', { static: true }) dropdownBtn: any;
  @ViewChild('dropdownList', { static: true }) dropdownList: ElementRef;

  selectedText: string;
  isShow = false;
  isPlaceholder = true;
  badge = '';
  globalEvent: any;

  constructor() {
    this.selectedText = this.placeholder || '';
    // this._value = '';
    this.globalEvent = event => {
      this.onClickGlobal(event);
    };
    window.addEventListener('click', this.globalEvent);
  }

  get widthClass() {
    return !!this.width ? 'manual-width' : '';
  }

  get dirClass() {
    return 'dir-' + (this.menuDir === 'h' ? 'h' : 'v');
  }

  get placementClass() {
    return this.placement || '';
  }

  get activeClass() {
    return this.isShow ? 'active' : '';
  }

  get sizeClass() {
    if (this.size === 'sm') {
      return 'ha-btn-sm';
    }

    return '';
  }

  get placeholderClass() {
    if (this.isPlaceholder) {
      return 'is-placeholder';
    }

    return '';
  }

  get textAlignClass() {
    return `text-${this.textAlign || 'center'}`;
  }

  get styles() {
    if (this.width) {
      return {
        'min-width': this.width + 'px',
      };
    }

    return {};
  }

  get scrollableClass() {
    return this.scrollable ? 'scrollable' : '';
  }

  get isHorizonMode() {
    return this.menuDir === 'h';
  }

  ngOnDestroy(): void {
    window.removeEventListener('click', this.globalEvent);
  }

  hasIncludedElement(target: HTMLElement, tryNum: number = 0) {
    const elemBtn = this.dropdownBtn.nativeElement as HTMLElement;

    if (tryNum > 5) {
      return false;
    }

    if (elemBtn === target) {
      return true;
    }

    if (target.parentElement) {
      return this.hasIncludedElement(target.parentElement, tryNum + 1);
    }

    return false;
  }

  onClickGlobal(event) {
    if (event.target === this.dropdownBtn.nativeElement) {
      return;
    }

    if (this.hasIncludedElement(event.target)) {
      return;
    }

    if (!this.isShow) {
      return;
    }

    this.isShow = false;
  }

  onClickDropdown() {
    const elemDropdownList = this.dropdownList.nativeElement;
    const elemDropdownBtn = this.dropdownBtn.nativeElement;
    this.isShow = !this.isShow;

    if (this.isShow) {
      try {
        if (!this.isHorizonMode) {
          elemDropdownList.style.width = elemDropdownBtn.offsetWidth + 'px';
        } else if (this.placement === 'top') {
          const rect = elemDropdownList.children[0].getBoundingClientRect();
          elemDropdownList.style.marginTop =
            -1 * (elemDropdownBtn.offsetHeight + rect.height) + 'px';
        }
        elemDropdownList.style.left = elemDropdownBtn.offsetLeft + 'px';
      } catch (error) {
        elemDropdownList.parentElement.style.position = 'relative';
      }
    }
  }

  onClickItem(item: DropdownModel) {
    this.selectedText = item.text;

    if (item.badge) {
      this.badge = item.badge;
    } else {
      this.badge = '';
    }

    this.dropDownSelect.emit(item.value);
  }
}
