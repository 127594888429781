import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectOption } from '~models/common';

@Component({
  selector: 'app-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectFilterComponent implements OnInit {
  @Input() set selectedButtonValue(selectedButtonValue: string) {
    this.filteredValue = selectedButtonValue;
    this.selectedText = this.findTextByValue(selectedButtonValue);
  }

  get selectedButtonValue() {
    return this.filteredValue;
  }

  @Input() set selectedButtonOptions(selectedButtonOptions: SelectOption[]) {
    this._selectedButtonOptions = selectedButtonOptions || [];

    if (!this.selectedButtonValue && this._selectedButtonOptions.length > 0) {
      this.selectedText = this._selectedButtonOptions[0].text;
    } else if (this._selectedButtonOptions.length > 0) {
      this.selectedText = this.findTextByValue(this.selectedButtonValue);
    }
  }

  get selectedButtonOptions() {
    return this._selectedButtonOptions;
  }

  public filteredValue = '';

  private _selectedButtonOptions: SelectOption[] = [];

  @Output() selectChange = new EventEmitter<string>();

  selectedText = '';

  constructor() {}

  ngOnInit() {}

  findTextByValue(selectedButtonValue: string) {
    const selectedButtonOptions = this._selectedButtonOptions;
    let foundText = '';

    if (!selectedButtonOptions || selectedButtonOptions.length === 0) {
      return foundText;
    }

    foundText = selectedButtonOptions[0].text;

    selectedButtonOptions.every(option => {
      if (option.value === selectedButtonValue) {
        foundText = option.text;
        return false;
      }
      return true;
    });

    return foundText;
  }

  handleChange(selectedButtonOption: SelectOption) {
    this.selectedText = selectedButtonOption.text;
    this.filteredValue = selectedButtonOption.value;
    this.selectChange.emit(selectedButtonOption.value);
  }
}
