import { environment } from '../environments/environment';

export const KAKAO_POSTCODE_API_URL =
  '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';
export const KAKAO_MAP_API_URL =
  '//ssl.daumcdn.net/dmaps/map_js_init/postcode.v2.js';
export const KAKAO_SCRIPT_URL = '//developers.kakao.com/sdk/js/kakao.min.js';
export const KAKAO_API_KEY = environment.production
  ? 'c045985321e9653595581fd8ded8cf10'
  : 'df32549e88e206f4efae9e700bc811ca';
export const KAKAO_PLUS_FRIEND_ID = '_Yxfjlj';
export const FACEBOOK_SHARE_URL = `https://hospital.ddocdoc.com/apply?utm_source=share_facebook&utm_medium=share`;
export const KAKAO_SHARE_URL = `https://hospital.ddocdoc.com/apply?utm_source=share_kakaotalk&utm_medium=share`;
export const VOLUNTARY_URL_FOR_SHARE = 'https://hospital.ddocdoc.com/apply';
export const HOSPITAL_ADMIN_PATH = 'hospital.ddocdoc.com';

export type UTM_SOURCE_TYPE = 'ddocdocweb' | 'ddocdoc_aos' | 'ddocdoc_ios' | '';
export type PLATFORM_TYPE = 'web' | 'ios' | 'android';

// 상위 30 병원아이디
export const TOP_30_HOSPITAL_IDS = [
  '57171f12e207df2709f14d70',
  '57171f12e207df2709f14f15',
  '57171f13e207df2709f1de66',
  '57171f13e207df2709f1d5d0',
  '606e6c540245810011404fce',
  '5889a2c64f75c42810a83e40',
  '57171f13e207df2709f1d946',
  '5a83a8dbeb2e3025cc7e963e',
  '5ed4ab03c9cecac803996054',
  '5e4f81f8ed68c1001ac3a661',
  '60190bc765be9d001993da81',
  '57171f13e207df2709f1d96d',
  '593e9103674c844aab60b2c6',
  '59ce9f2a1abc903859f26ae2',
  '58b3d54a3ea8d6071208ad54',
  '57171f13e207df2709f1d821',
  '634fab18ecb9ed00130002d5',
  '6400489d82db9feb2d628ae4',
  '5e15433211c0e8001838aecf',
  '5da6cc8d5a42f500114e4f0b',
  '57171f11e207df2709f12b45',
  '5cb5659b74bb1c00106b1693',
  '5f7bd0e1b8d3840018567dd0',
  '6372e6337753cb001b644621',
  '5ca327cbe3f21600175d627b',
  '638d371185b8fb001b277067',
  '61fc7aad7978ac001898c918',
  '5abb3b5f9fa07e230beaa9a0',
  '631540d63f0f5c00195aca13',
  '57171f12e207df2709f1c066',
];
