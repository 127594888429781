import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export enum InvalidType {
  'LINE' = 'LINE',
  'LENGTH' = 'LENGTH',
}

export interface LimitedTextAreaChangeEvent {
  value: string;
  isValid: boolean;
  error: Set<keyof typeof InvalidType>;
}

@Component({
  selector: 'app-limited-textarea',
  templateUrl: './limited-textarea.component.html',
  styleUrls: ['./limited-textarea.component.scss'],
})
export class LimitedTextareaComponent implements OnInit {
  private _rows = 3;

  @Input() set rows(val: number) {
    this._rows = val;
  }
  get rows(): number {
    return this._rows;
  }

  private _maxlength = 100;
  @Input() set maxlength(val: number) {
    this._maxlength = val;
  }
  get maxlength(): number {
    return this._maxlength;
  }

  private _maxlinebreak = 10;
  @Input() set maxlinebreak(val: number) {
    this._maxlinebreak = val;
  }
  get maxlinebreak(): number {
    return this._maxlinebreak;
  }

  private _maxWidth = '100%';
  @Input() set maxWidth(val: string) {
    this._maxWidth = val;
  }
  get maxWidth(): string {
    return this._maxWidth;
  }

  private _initialValue = '';
  @Input() set initialValue(text: string) {
    this._initialValue = text;
  }

  private _value = '';
  @Input() set value(text: string) {
    this._value = text;
  }
  get value(): string {
    return this._value;
  }

  private _errorSet: Set<keyof typeof InvalidType> = new Set();
  get errorSet(): Set<keyof typeof InvalidType> {
    return this._errorSet;
  }

  @Input() placeholder = '내용을 입력하세요';
  @Input() disabled = false;
  @Input() useBrowserMaxlength = false;
  @Input() useMaxLineBreakError = true;
  @Input() useMaxLengthError = true;

  errorType = InvalidType;

  // other getters
  get length() {
    return (this.value || '').length;
  }
  get hasError() {
    return this.errorSet.size > 0;
  }

  @Output() valueChange = new EventEmitter<LimitedTextAreaChangeEvent>();
  @Output() focusoutBlur = new EventEmitter<LimitedTextAreaChangeEvent>();

  ngOnInit(): void {
    if (!this.value) {
      this.value = this._initialValue || '';

      this.checkInvalid(this.value);
    }
  }

  checkInvalid(value = ''): boolean {
    let result = true;

    if (this.useMaxLengthError && value.length > this.maxlength) {
      result = false;
      this.errorSet.add(InvalidType.LENGTH);
    } else {
      this.errorSet.delete(InvalidType.LENGTH);
    }

    if (this.useMaxLineBreakError && value.split('\n').length > this.maxlinebreak) {
      result = false;
      this.errorSet.add(InvalidType.LINE);
    } else {
      this.errorSet.delete(InvalidType.LINE);
    }

    return result;
  }

  handleInput(event: Event) {
    const { value } = event.target as HTMLInputElement | HTMLTextAreaElement;
    this.checkInvalid(value);
    event.preventDefault();
  }

  handleKeyUp(event: Event) {
    const { value } = event.target as HTMLInputElement | HTMLTextAreaElement;
    this.setNextValue(value);
    this.valueChange.emit({
      value: this.value,
      isValid: this.hasError,
      error: this.errorSet,
    });
    event.preventDefault();
  }

  handlePaste(event: ClipboardEvent) {
    const clipboardValue = event.clipboardData.getData('Text');
    const { value: targetValue } = event.target as
      | HTMLInputElement
      | HTMLTextAreaElement;
    this.setNextValue(targetValue + clipboardValue);

    this.valueChange.emit({
      value: this.value,
      isValid: this.hasError,
      error: this.errorSet,
    });
    event.preventDefault();
  }

  handleBlur(event: Event) {
    this.focusoutBlur.emit({
      value: this.value,
      isValid: this.hasError,
      error: this.errorSet,
    });
    event.preventDefault();
    event.stopPropagation();
  }

  setNextValue(text: string): void {
    // input event가 지원 안되는 브라우저를 위해 체크
    this.checkInvalid(text);

    if (!this.useBrowserMaxlength) {
      this.value = text;
      return;
    }

    let nextValue = text;

    if (this.errorSet.has(this.errorType.LINE)) {
      nextValue = nextValue
        .split('\n')
        .slice(0, this._maxlinebreak)
        .join('\n');
    }
    if (this.errorSet.has(this.errorType.LENGTH)) {
      nextValue = nextValue.substr(0, this.maxlength);
    }

    // validation에 맞게 포맷 변경하기 때문에 에러 필요 없음.
    this.errorSet.clear();

    this.value = nextValue;
  }
}
