<div
  class="ha-input-textarea"
  [ngClass]="{
    'border-danger': hasError,
    disabled: disabled,
    invalid: hasError,
    rows1: rows <= 1
  }"
  [ngStyle]="{'max-width': maxWidth}"
>
  <div class="input-wrap">
    <textarea
      *ngIf="rows > 1"
      [rows]="rows"
      [disabled]="disabled"
      [placeholder]="placeholder"
      [maxLength]="useBrowserMaxlength ? maxlength : 524288"
      [(ngModel)]="value"
      (input)="handleInput($event)"
      (keyup)="handleKeyUp($event)"
      (blur)="handleBlur($event)"
    ></textarea>
    <input
      *ngIf="rows <= 1"
      type="text"
      [disabled]="disabled"
      [placeholder]="placeholder"
      [maxLength]="useBrowserMaxlength ? maxlength : 524288"
      [(ngModel)]="value"
      (input)="handleInput($event)"
      (keyup)="handleKeyUp($event)"
      (blur)="handleBlur($event)"
    />
    <div class="input-state" [ngClass]="{ 'text-danger': hasError }">
      ({{ length }}/{{ maxlength }})
    </div>
  </div>
</div>

<div *ngIf="hasError" class="text-right text-danger">
  <div *ngIf="errorSet.has(errorType.LINE)">
    <strong>최대 {{ maxlinebreak }}줄</strong>을 넘길 수 없습니다
  </div>
  <div *ngIf="errorSet.has(errorType.LENGTH)">
    입력 글자 수를 초과하였습니다. 최대 입력 글자 수는
    <strong> {{ maxlength }}자(띄어쓰기 포함)</strong>입니다.
  </div>
</div>
