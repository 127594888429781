import { createAction, props } from '@ngrx/store';
import {
  HolidaysInfo,
  OperateTime,
  PasteMonthInfo,
  ReservationScheduleMultiQuery,
  ReservationScheduleQuery,
  ReservationScheduleV2CopyReqModel,
  ReservationScheduleV2ResModel,
  ScheduleExistsReqModel,
  SymptomCategoryResModel,
} from '~models/reservation-schedule-v2.model';
import { DateRange } from '~models/common.model';
import { SchedulerPageParams } from '~root/app/modules/admin/pages/ddocdoc-service/mobile-schedule/scheduler-v2/containers/scheduler-page/scheduler-page.component';

const RESERVATION_SCHEDULE = 'Reservation Schedule Copy v2' as const;

export const ReservationScheduleCopyTypes = {
  FETCH_RESERVATION_SCHEDULE: `[${RESERVATION_SCHEDULE}] Fetch Reservation Schedule`,
  FETCH_RESERVATION_SCHEDULE_SUCCESS: `[${RESERVATION_SCHEDULE}] Fetch Reservation Schedule Success`,

  FETCH_MULTI_RESERVATION_SCHEDULE: `[${RESERVATION_SCHEDULE}] Fetch Multi Reservation Schedule`,
  FETCH_MULTI_RESERVATION_SCHEDULE_SUCCESS: `[${RESERVATION_SCHEDULE}] Fetch Multi Reservation Schedule Success`,

  UPDATE_MULTIPLE_SCHEDULE: `[${RESERVATION_SCHEDULE}] Update Multiple Schedule`,
  UPDATE_MULTIPLE_SCHEDULE_SUCCESS: `[${RESERVATION_SCHEDULE}] Update Multiple Schedule Success`,

  FETCH_SYMPTOM_CATEGORY_LIST: `[${RESERVATION_SCHEDULE}] Fetch Symptom Category List`,
  FETCH_SYMPTOM_CATEGORY_LIST_SUCCESS: `[${RESERVATION_SCHEDULE}] Fetch Symptom Category List Success`,

  FETCH_OPERATETIME: `[${RESERVATION_SCHEDULE}] Fetch Operate Time`,
  FETCH_OPERATETIME_SUCCESS: `[${RESERVATION_SCHEDULE}] Fetch Operate Time Success`,

  FETCH_HOLIDAYS: `[${RESERVATION_SCHEDULE}] Fetch Holidays`,
  FETCH_HOLIDAYS_SUCCESS: `[${RESERVATION_SCHEDULE}] Fetch Holidays Success`,

  FETCH_MULTI_HOLIDAYS: `[${RESERVATION_SCHEDULE}] Fetch Multi Holidays`,
  FETCH_MULTI_HOLIDAYS_SUCCESS: `[${RESERVATION_SCHEDULE}] Fetch Multi Holidays Success`,

  FETCH_EXISTS_SCHEDULE_MONTH: `[${RESERVATION_SCHEDULE}] Fetch Exists Schedule Month`,
  FETCH_EXISTS_SCHEDULE_MONTH_SUCCESS: `[${RESERVATION_SCHEDULE}] Fetch Exists Schedule Month Success`,

  FETCH_MULTI_EXISTS_SCHEDULE_MONTH: `[${RESERVATION_SCHEDULE}] Fetch Multi Exists Schedule Month`,
  FETCH_MULTI_EXISTS_SCHEDULE_MONTH_SUCCESS: `[${RESERVATION_SCHEDULE}] Fetch Multi Exists Schedule Month Success`,

  // UPDATE_TIME: `[${RESERVATION_SCHEDULE}] Update Time`,
  // UPDATE_TIME_SUCCESS: `[${RESERVATION_SCHEDULE}] Update Time Success`,

  RESERVATION_SCHEDULE_FAIL: `[${RESERVATION_SCHEDULE}] Reservation Schedule Fail`,
} as const;

/**
 * 예약 3.0 스케줄 조회
 */
export const FetchReservationSchedule = createAction(
  ReservationScheduleCopyTypes.FETCH_RESERVATION_SCHEDULE,
  props<{ payload: ReservationScheduleQuery }>()
);

export const FetchReservationScheduleSuccess = createAction(
  ReservationScheduleCopyTypes.FETCH_RESERVATION_SCHEDULE_SUCCESS,
  props<{ payload: ReservationScheduleV2ResModel }>()
);

/**
 * 예약 3.0 스케줄 조회 멀티
 */
export const FetchMultiReservationSchedule = createAction(
  ReservationScheduleCopyTypes.FETCH_MULTI_RESERVATION_SCHEDULE,
  props<{ payload: ReservationScheduleMultiQuery }>()
);

export const FetchMultiReservationScheduleSuccess = createAction(
  ReservationScheduleCopyTypes.FETCH_MULTI_RESERVATION_SCHEDULE_SUCCESS,
  props<{ payload: ReservationScheduleV2ResModel[] }>()
);

/**
 * 예약 3.0 스케줄 복사/붙여넣기 (하나 이상)
 */
export const UpdateMultipleSchedules = createAction(
  ReservationScheduleCopyTypes.UPDATE_MULTIPLE_SCHEDULE,
  props<{
    payload: ReservationScheduleV2CopyReqModel[];
    // isDayCopy: boolean;
    // hasHoliday: boolean;
    alertDesc: string;
  }>()
);
export const UpdateMultipleSchedulesSuccess = createAction(
  ReservationScheduleCopyTypes.UPDATE_MULTIPLE_SCHEDULE_SUCCESS,
  props<{
    // isDayCopy: boolean;
    // hasHoliday: boolean;
    route: SchedulerPageParams;
    alertDesc: string;
  }>()
);

/**
 * 증상 카테고리 목록 조회
 */
export const FetchCategoryList = createAction(
  ReservationScheduleCopyTypes.FETCH_SYMPTOM_CATEGORY_LIST
);

export const FetchCategoryListSuccess = createAction(
  ReservationScheduleCopyTypes.FETCH_SYMPTOM_CATEGORY_LIST_SUCCESS,
  props<{ payload: SymptomCategoryResModel }>()
);

/**
 * 진료실 영업시간 조회
 */
export const FetchOperateTime = createAction(
  ReservationScheduleCopyTypes.FETCH_OPERATETIME,
  props<{ params: { unitKey: string } }>()
);

export const FetchOperateTimeSuccess = createAction(
  ReservationScheduleCopyTypes.FETCH_OPERATETIME_SUCCESS,
  props<{
    payload: { operateTime: OperateTime };
  }>()
);

/**
 * 공휴일 조회
 */
export const FetchHolidays = createAction(
  ReservationScheduleCopyTypes.FETCH_HOLIDAYS,
  props<{ params: DateRange }>()
);

export const FetchHolidaysSuccess = createAction(
  ReservationScheduleCopyTypes.FETCH_HOLIDAYS,
  props<{ payload: HolidaysInfo }>()
);

/**
 * 공휴일 조회 멀티
 */
export const FetchMultiHolidays = createAction(
  ReservationScheduleCopyTypes.FETCH_MULTI_HOLIDAYS,
  props<{ params: DateRange }>()
);

export const FetchMultiHolidaysSuccess = createAction(
  ReservationScheduleCopyTypes.FETCH_MULTI_HOLIDAYS_SUCCESS,
  props<{ payload: HolidaysInfo }>()
);

/**
 * 스케줄 존재 여부 조회
 */
export const FetchExistsScheduleMonth = createAction(
  ReservationScheduleCopyTypes.FETCH_EXISTS_SCHEDULE_MONTH,
  props<{
    params: ScheduleExistsReqModel;
  }>()
);

export const FetchExistsScheduleMonthSuccess = createAction(
  ReservationScheduleCopyTypes.FETCH_EXISTS_SCHEDULE_MONTH_SUCCESS,
  props<{
    pasteMonthInfo: PasteMonthInfo;
  }>()
);

/**
 * 스케줄 존재 여부 조회 Multi
 */
export const FetchMultiExistsScheduleMonth = createAction(
  ReservationScheduleCopyTypes.FETCH_MULTI_EXISTS_SCHEDULE_MONTH,
  props<{
    params: ScheduleExistsReqModel;
  }>()
);

export const FetchMultiExistsScheduleMonthSuccess = createAction(
  ReservationScheduleCopyTypes.FETCH_MULTI_EXISTS_SCHEDULE_MONTH_SUCCESS,
  props<{
    pasteMonthInfos: PasteMonthInfo[];
  }>()
);

/**
 * 스케줄 복사 모달 날짜 변경
 */
// export const UpdateTime = createAction(
//   ReservationScheduleCopyTypes.UPDATE_TIME,
//   props<{
//     payload: SchedulerDate;
//   }>()
// );

// export const UpdateTimeSuccess = createAction(
//   ReservationScheduleCopyTypes.UPDATE_TIME_SUCCESS,
//   props<{
//     payload: {
//       year: number;
//       month: number;
//     };
//   }>()
// );

/**
 * 예약 스케줄러 관련 에러 액션
 */
export const ReservationScheduleFail = createAction(
  ReservationScheduleCopyTypes.RESERVATION_SCHEDULE_FAIL,
  props<{ message: string }>()
);

export const ReservationScheduleCopyActions = {
  FetchReservationSchedule,
  FetchReservationScheduleSuccess,

  FetchMultiReservationSchedule,
  FetchMultiReservationScheduleSuccess,

  UpdateMultipleSchedules,
  UpdateMultipleSchedulesSuccess,

  FetchCategoryList,
  FetchCategoryListSuccess,

  FetchOperateTime,
  FetchOperateTimeSuccess,

  FetchHolidays,
  FetchHolidaysSuccess,

  FetchMultiHolidays,
  FetchMultiHolidaysSuccess,

  FetchExistsScheduleMonth,
  FetchExistsScheduleMonthSuccess,

  FetchMultiExistsScheduleMonth,
  FetchMultiExistsScheduleMonthSuccess,

  // UpdateTime,
  // UpdateTimeSuccess,

  ReservationScheduleFail,
};
