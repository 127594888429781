import { ExpenseCategoryActions } from '~actions/products/expense-category.actions';
import { ExpenseCategoryUiItemModel } from '~models/expense-category.model';
import { createReducer, on } from '@ngrx/store';

export interface ExpenseCategoryState {
  items: ExpenseCategoryUiItemModel[][];
  pendings: boolean[];
}

const initialState: ExpenseCategoryState = {
  items: [[], [], []],
  pendings: [false, false, false],
};

export const reducer = createReducer(
  initialState,
  on(ExpenseCategoryActions.ExpenseCategoryLoad, (state, action) => {
    const { depth } = action.payload;
    const { pendings, items } = state;

    const nextItems = [...items];
    for (let i = depth - 1; i < items.length; i++) {
      nextItems[i] = [];
    }

    const nextPendings = [...pendings];
    nextPendings[depth - 1] = true;
    return {
      ...state,
      items: nextItems,
      pendings: nextPendings,
    };
  }),
  on(ExpenseCategoryActions.ExpenseCategoryLoadSuccess, (state, action) => {
    const { depth, items: results } = action.payload;
    const { items, pendings } = state;

    const nextItems = [...items];
    nextItems[depth - 1] = results;

    const nextPendings = [...pendings];
    nextPendings[depth - 1] = false;
    return {
      ...state,
      items: nextItems,
      pendings: nextPendings,
    };
  })
);

export const getExpenseCategoryState = (state: ExpenseCategoryState) => state;
