import {
  HospitalCrmScheduleStateEnum,
  HospitalScheduleUiModel,
} from '~models/hospital-crm.model';
import { HashMap } from '~models/common.model';
import { CrmScheduleActions } from '~actions/products/hospital-crm-schedule.actions';
import { createReducer, on } from '@ngrx/store';

export interface HospitalCrmScheduleState {
  scheduleMap: HashMap<HospitalScheduleUiModel>;
  scheduleIds: string[];
  pending: boolean;
  skip: number;
  totalCount: number;
  showPageGuide: boolean;
  showTab: boolean;
  state: keyof typeof HospitalCrmScheduleStateEnum;
}

const initState: HospitalCrmScheduleState = {
  scheduleMap: {},
  scheduleIds: [],
  pending: false,
  skip: 0,
  totalCount: 0,
  showPageGuide: true,
  showTab: false,
  state: 'RUNNING',
};

export const reducer = createReducer(
  initState,
  on(CrmScheduleActions.CrmScheduleInitSuccess, (state, action) => ({
    ...state,
    scheduleMap: { ...state.scheduleMap, ...action.payload.items },
    scheduleIds: [...Object.values(action.payload.items).map(i => i._id)],
    skip: action.payload.skip || 0,
    totalCount: action.payload.totalCount,
    pending: false,
    showPageGuide: action.payload.showPageGuide,
    showTab: action.payload.showTab,
    state: action.payload.state,
  })),
  on(CrmScheduleActions.CrmScheduleLoadSuccess, (state, action) => ({
    ...state,
    scheduleMap: { ...state.scheduleMap, ...action.payload.items },
    scheduleIds: [...Object.values(action.payload.items).map(i => i._id)],
    skip: action.payload.skip || 0,
    totalCount: action.payload.totalCount,
    pending: false,
  })),
  on(CrmScheduleActions.CrmScheduleInit, (state, action) => ({
    ...state,
    pending: true,
  })),
  on(CrmScheduleActions.CrmScheduleLoad, (state, action) => ({
    ...state,
    pending: true,
    state: action.payload.state || 'RUNNING',
  })),
  on(CrmScheduleActions.CrmScheduleLoadFail, (state, action) => ({
    ...state,
    pending: false,
  })),
  on(CrmScheduleActions.CrmUpdateSchedule, (state, action) => ({
    ...state,
    scheduleMap: {
      ...state.scheduleMap,
      [action.schedule._id]: { ...action.schedule, isEdited: true },
    },
  })),
  on(CrmScheduleActions.CrmEditScheduleStart, (state, action) => ({
    ...state,
    scheduleMap: {
      ...state.scheduleMap,
      [action._id]: { ...state.scheduleMap[action._id], isUpdate: true },
    },
  })),
  on(CrmScheduleActions.CrmEditScheduleSuccess, (state, action) => ({
    ...state,
    scheduleMap: {
      ...state.scheduleMap,
      [action._id]: {
        ...state.scheduleMap[action._id],
        isUpdate: false,
        isEdited: false,
      },
    },
  })),
  on(CrmScheduleActions.CrmEditScheduleFail, (state, action) => ({
    ...state,
    scheduleMap: {
      ...state.scheduleMap,
      [action._id]: { ...state.scheduleMap[action._id], isUpdate: false },
    },
  }))
);
