import { CrmMessageTemplateRes } from '~models/hospital-crm.model';
import { CrmMessageTemplateActions } from '~actions/products/hospital-crm-message-template.actions';
import { createReducer, on } from '@ngrx/store';

/**
 * 푸시 발송 대상 유저 Store
 * @name CrmPushTargetUserState
 * @author Hong-ki Min
 */
export interface CrmMessageTemplateState extends CrmMessageTemplateRes {
  pending: boolean;
}

const initState: CrmMessageTemplateState = {
  items: [],
  totalCount: 0,
  pending: false,
};

export const reducer = createReducer(
  initState,
  on(CrmMessageTemplateActions.CrmMessageTemplateLoad, (state, action) => ({
    ...state,
    pending: true,
  })),
  on(
    CrmMessageTemplateActions.CrmMessageTemplateLoadSuccess,
    (state, action) => ({
      ...state,
      ...action.payload,
      pending: false,
    })
  ),
  on(CrmMessageTemplateActions.CrmMessageTemplateReqFail, (state, action) => ({
    ...state,
    pending: false,
  }))
);
