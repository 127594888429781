import { VoluntaryActions } from '~actions/products/voluntary.actions';
import { Agency } from '~models/voluntary';
import { createReducer, on } from '@ngrx/store';

export interface VoluntaryState {
  loading: boolean;
  loaded: boolean;
  success: boolean;
  error: any;
  agencies: Agency[];
}

const initialState: VoluntaryState = {
  loaded: false,
  loading: false,
  success: false,
  error: null,
  agencies: [],
};

export const reducer = createReducer(
  initialState,
  on(VoluntaryActions.VoluntaryCreate, (state, action) => ({
    ...state,
    loaded: false,
    loading: true,
    success: false,
    error: null,
  })),
  on(VoluntaryActions.VoluntaryCreateSuccess, (state, action) => ({
    ...state,
    loaded: true,
    loading: false,
    success: true,
    error: null,
  })),
  on(VoluntaryActions.VoluntaryCreateFail, (state, action) => ({
    ...state,
    loading: false,
    success: false,
    error: action.payload,
  })),
  on(VoluntaryActions.VoluntaryAgencyLoad, (state, action) => ({ ...state })),
  on(VoluntaryActions.VoluntaryAgencyLoadSuccess, (state, action) => ({
    ...state,
    agencies: action.payload.agencies,
  }))
);

export const getLoading = (state: VoluntaryState) => state.loading;
export const getLoaded = (state: VoluntaryState) => state.loaded;
export const getError = (state: VoluntaryState) => state.error;
export const getAgencies = (state: VoluntaryState) => state.agencies;
export const getSent = (state: VoluntaryState) => ({
  loading: state.loading,
  error: state.error,
  success: state.success,
});
