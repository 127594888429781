import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
@Pipe({
  name: 'emphasisColorText'
})
export class EmphasisColorTextPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, regex, color, flag = 'gi'): any {
    return this.sanitizer.bypassSecurityTrustHtml(this.replace(value, regex, color, flag));
  }

  replace(str, regex, color, flag) {
    return str.replace(
      new RegExp(`(${regex})`, flag),
      `<em style="
          font-style: inherit;
          font-weight: inherit;
          color: ${color};"
      >$1</em>`
    );
  }
}
