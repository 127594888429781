import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  NgbCarousel,
  NgbCarouselConfig,
  NgbSlideEvent,
} from '@ng-bootstrap/ng-bootstrap';
import { CarouselItem } from '~models/signin-carousel.model';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-signin-carousel',
  templateUrl: './signin-carousel.component.html',
  styleUrls: ['./signin-carousel.component.scss'],
  providers: [NgbCarouselConfig],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SigninCarouselComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('carousel', {
    static: true,
  })
  carousel: NgbCarousel;

  @Input() items: CarouselItem;
  currentSlideIdx = 0;

  @Input() set currentTabIdx(_: number) {
    this.currentSlideIdx = 0;
    this.currentTabIdxChange$.next();
  }

  currentTabIdxChange$ = new Subject<void>();

  carouselSubscription: Subscription;

  constructor(config: NgbCarouselConfig) {
    config.interval = 10000;
    config.showNavigationArrows = false;
    config.showNavigationIndicators = false;
    config.pauseOnHover = true;
  }

  ngOnInit(): void {}

  handleSlidEvent(event: NgbSlideEvent): void {
    const { current } = event;
    this.currentSlideIdx = Number(current);
  }

  contentSelect(slideId: number): void {
    this.carousel.select(slideId.toString());
  }

  ngAfterViewInit(): void {
    this.carouselSubscription = this.currentTabIdxChange$.subscribe(() => {
      this.carousel.select('0');
    });
  }

  ngOnDestroy(): void {
    this.carouselSubscription.unsubscribe();
  }
}
