import { createReducer, on } from '@ngrx/store';
import { WaitingsSetupRes, WaitingsSetupUiModel } from '~models/waitings.model';
import { WaitingsActions } from '~actions/products/waitings.actions';
import {
  getInitWaitNoticeUiModel,
  getInitWaitSettingsUiModel,
} from '../../services/waitings.services';

export interface WaitingsState extends WaitingsSetupUiModel {
  pending: boolean;
  response: WaitingsSetupRes;
  receptionGuideUse: boolean;
  receptionGuideAgreedDate: string;
}

export function getInitState(): WaitingsState {
  return {
    pending: false,
    notice: getInitWaitNoticeUiModel(),
    settings: getInitWaitSettingsUiModel(),
    waitingsUse: false,
    receptionGuideUse: false,
    response: {} as WaitingsSetupRes,
    receptionGuideAgreedDate: '',
  };
}

const initState = getInitState();

export const reducer = createReducer(
  initState,
  on(
    WaitingsActions.WaitingsLoad,
    WaitingsActions.WaitingsSave,
    WaitingsActions.WaitingsLoadAgreed,
    state => ({
      ...state,
      pending: true,
    })
  ),
  on(
    WaitingsActions.WaitingsLoadSuccess,
    WaitingsActions.WaitingsUseChangeSuccess,
    (state, action) => ({
      ...state,
      ...action.payload,
      pending: false,
    })
  ),
  on(WaitingsActions.WaitingsLoadAgreedSuccess, (state, action) => ({
    ...state,
    ...action.payload,
    pending: false,
  })),
  on(WaitingsActions.WaitingsSaveSuccess, state => ({
    ...state,
    pending: false,
  })),
  on(WaitingsActions.WaitingsChanged, (state, action) => ({
    ...state,
    ...action.payload,
  }))
);
