import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs/';
import { take } from 'rxjs/operators';
import { ResetPwActions } from '~actions/auth/reset-pw.action';
import * as fromAuth from '~reducers/auth';

@Component({
  selector: 'app-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordPageComponent implements OnInit {
  error$: Observable<string | null>;
  showComplete$: Observable<boolean>;
  token?: string;

  constructor(
    private store: Store<fromAuth.AuthInfoState>,
    private route: ActivatedRoute
  ) {
    this.error$ = this.store.pipe(select(fromAuth.getResetPwError));
    this.showComplete$ = this.store.pipe(
      select(fromAuth.getRestPwShowComplete)
    );
    this.route.params
      .pipe(take(1))
      .subscribe(params => (this.token = params.token));
  }

  ngOnInit(): void {
    this.store.dispatch(ResetPwActions.ResetPwInit());
  }

  onSubmit(password: string): void {
    this.store.dispatch(
      ResetPwActions.ResetPw({
        payload: {
          password,
          passwordResetToken: this.token ?? '',
        },
      })
    );
  }
}
