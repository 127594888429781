import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SimpleModalService } from '../../service/simple.modal.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

export interface ContainerFooterOption {
  cancelBtn: boolean;
  disabled?: boolean;
  disabledMsg?: string;
}

@Component({
  selector: 'app-container-footer',
  templateUrl: './container-footer.component.html',
  styleUrls: ['./container-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContainerFooterComponent implements OnInit, AfterViewInit {
  @ViewChild('popover', { static: true }) popover: NgbPopover;

  @Input() showConfirm = true;
  @Input() confirmText;
  @Input() cancelText = '취소';
  @Input() cancel?: string;
  @Input() options: ContainerFooterOption = {
    cancelBtn: true,
    disabled: false,
  };
  @Input() showPopover = false;

  @Output() confirm = new EventEmitter<void>();

  constructor(private simpleModalService: SimpleModalService) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    if (this.showPopover) {
      this.popover.open();
    }
  }

  onSubmit() {
    if (this.options.disabled) {
      if (!!this.options.disabledMsg) {
        this.simpleModalService.alert(this.options.disabledMsg);
      }
    } else {
      this.confirm.emit();
    }
  }

  get cancelBtn() {
    return this.cancel || '../';
  }
}
