import { createAction, props } from '@ngrx/store';
import {
  TabletSettingFetchModel,
  TabletSettingUpdateModel,
} from '~models/tablet.model';

export enum TabletManageActionTypes {
  TABLET_SETTING_DATA_LOAD = '[Tablet] Tablet Setting Data Load',
  TABLET_SETTING_DATA_LOAD_SUCCESS = '[Tablet] Tablet Setting Data Load Success',
  TABLET_SETTING_DATA_LOAD_FAIL = '[Tablet] Tablet Setting Data Load Fail',

  TABLET_SETTING_DATA_UPDATE = '[Tablet] Tablet Setting Data Update',
  TABLET_SETTING_DATA_UPDATE_SUCCESS = '[Tablet] Tablet Setting Data Update Success',
  TABLET_SETTING_DATA_UPDATE_FAIL = '[Tablet] Tablet Setting Data Update Fail',
}

// 무인접수 기본 설정 조회
export const TabletSettingDataLoad = createAction(
  TabletManageActionTypes.TABLET_SETTING_DATA_LOAD
);

export const TabletSettingDataLoadSuccess = createAction(
  TabletManageActionTypes.TABLET_SETTING_DATA_LOAD_SUCCESS,
  props<{ payload: TabletSettingFetchModel }>()
);

export const TabletSettingDataLoadFail = createAction(
  TabletManageActionTypes.TABLET_SETTING_DATA_LOAD_FAIL,
  props<{ payload: any }>()
);

// 무인접수 기본 설정 업데이트
export const TabletSettingDataUpdate = createAction(
  TabletManageActionTypes.TABLET_SETTING_DATA_UPDATE,
  props<{ payload: TabletSettingUpdateModel }>()
);

export const TabletSettingDataUpdateSuccess = createAction(
  TabletManageActionTypes.TABLET_SETTING_DATA_UPDATE_SUCCESS
);

export const TabletSettingDataUpdateFail = createAction(
  TabletManageActionTypes.TABLET_SETTING_DATA_UPDATE_FAIL,
  props<{ payload: any }>()
);

export const TabletManageActions = {
  TabletSettingDataLoad,
  TabletSettingDataLoadSuccess,
  TabletSettingDataLoadFail,
  TabletSettingDataUpdate,
  TabletSettingDataUpdateSuccess,
  TabletSettingDataUpdateFail,
};
