import { Category, Faq, TOTAL_CATEGORY } from '~models/faq.model';
import { createReducer, on } from '@ngrx/store';
import { FaqActions } from '~actions/products/faq.actions';

export interface FaqState {
  categories: Category[];
  faqs: Faq[];
  fixedFaqs: Faq[];
  pending: {
    categories: boolean;
    faqs: boolean;
    fixedFaqs: boolean;
  };
  totalCount: number;
  limit?: number;
  skip?: number;
}

const initialFaqState: FaqState = {
  faqs: [],
  fixedFaqs: [],
  categories: [TOTAL_CATEGORY],
  pending: {
    categories: false,
    faqs: false,
    fixedFaqs: false,
  },
  totalCount: 0,
};

export const reducer = createReducer(
  initialFaqState,
  on(FaqActions.FixedFaqListLoad, (state, action) => ({
    ...state,
    pending: {
      ...state.pending,
      fixedFaqs: true,
    },
  })),
  on(FaqActions.FaqCategoriesLoad, (state, action) => ({
    ...state,
    pending: {
      ...state.pending,
      categories: true,
    },
  })),
  on(FaqActions.FaqListLoad, (state, action) => ({
    ...state,
    pending: {
      ...state.pending,
      faqs: true,
    },
  })),
  on(FaqActions.FaqCategoriesLoadSuccess, (state, action) => {
    const categories = action.payload;
    return {
      ...state,
      categories: categories
        ? [TOTAL_CATEGORY, ...categories]
        : [TOTAL_CATEGORY],
      pending: {
        ...state.pending,
        categories: false,
      },
    };
  }),
  on(FaqActions.FixedFaqListLoadSuccess, (state, action) => {
    return {
      ...state,
      fixedFaqs: action.payload.items,
      pending: {
        ...state.pending,
        fixedFaqs: false,
      },
    };
  }),
  on(FaqActions.FaqListLoadSuccess, (state, action) => {
    return {
      ...state,
      faqs: action.payload.items,
      totalCount: action.payload.totalCount,
      skip: action.payload.skip,
      ...(action.payload.limit && { limit: action.payload.limit }),
      pending: {
        ...state.pending,
        faqs: false,
      },
    };
  }),
  on(FaqActions.FaqReqFail, (state, action) => {
    return {
      ...state,
      pending: {
        faqs: false,
        fixedFaqs: false,
        categories: false,
      },
    };
  })
);

export const getFaqs = (state: FaqState) => state.faqs;
