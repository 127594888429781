import {
  AfterContentChecked,
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { fromEvent, interval, merge, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map, throttleTime } from 'rxjs/operators';
import {
  filteredIndexSign,
  initMove,
  move,
  positioningByIndex,
  positioningByStartEnd,
  positioningForDual,
} from './content-slider.service';
import { SliderItemDirective } from '../../directives/slider-item.directive';

export interface ContentSliderEventArgs {
  beforeIndex: number;
  index: number;
}

@Component({
  selector: 'app-content-slider',
  templateUrl: './content-slider.component.html',
  styleUrls: ['./content-slider.component.scss'],
})
export class ContentSliderComponent
  implements
    OnInit,
    OnChanges,
    AfterContentInit,
    AfterContentChecked,
    OnDestroy {
  private contents: HTMLElement[];
  private _size: number;
  private sbjGoToProcess = new Subject<number>();
  private resize$: Subscription;
  private autoPlay$: Subscription;
  private moving = false;
  private mouseOver = false;

  private _movingStateTime = null;
  private _throttle = 100;
  private _autoPlay = true;
  private _autoPlayInterval = 10000;
  private _curr = 0;
  private _direction = 'x';
  private _forceCheck = false;

  before = -1;
  structure: number[];
  length = 0;
  viewCount = 1;

  get curr() {
    return this._curr;
  }

  @Input() set throttle(val: number) {
    this._throttle = val;
  }

  @Input() set autoPlay(val: boolean) {
    this._autoPlay = val;
  }

  @Input() set autoPlayInterval(val: number) {
    this._autoPlayInterval = val;
  }

  @Input() set direction(val: string) {
    this._direction = val;
    this._forceCheck = val === 'y';
  }

  get direction() {
    return this._direction;
  }

  @Input() set forceCheck(val: boolean) {
    this._forceCheck = val;
  }

  @Output() slideBeforeChange = new EventEmitter<ContentSliderEventArgs>();
  @Output() slideChange = new EventEmitter<ContentSliderEventArgs>();
  @Output() slideLeft = new EventEmitter<ContentSliderEventArgs>();
  @Output() slideRight = new EventEmitter<ContentSliderEventArgs>();

  @ViewChild('listWrap', { static: true }) listWrap: ElementRef<HTMLDivElement>;
  @ContentChildren(SliderItemDirective)
  children!: QueryList<SliderItemDirective>;

  constructor(private elRef: ElementRef) {}

  ngOnInit() {
    this.sbjGoToProcess
      .pipe(
        filter(() => !this.moving),
        filter(index => this.curr !== index),
        throttleTime(this._throttle)
      )
      .subscribe(index => this.goToProcess(index));

    const elem = this.listWrap.nativeElement;

    this.resize$ = merge(
      fromEvent(window, 'resize'),
      fromEvent(elem, 'visibilitychange')
    )
      .pipe(debounceTime(200))
      .subscribe(() => {
        this.initSlider();
      });
  }

  ngOnChanges() {
    if (!this._autoPlay) {
      this.stopAutoPlay();

      return;
    }
  }

  onMouseEnter() {
    this.mouseOver = true;
  }

  onMouseLeave() {
    this.mouseOver = false;
    if (this._autoPlay) {
      this.startAutoPlay();
    }
  }

  ngAfterContentInit() {
    this.initSlider();
  }

  getChildLength() {
    try {
      return this.children.length;
    } catch (error) {
      return 0;
    }
  }

  ngAfterContentChecked() {
    // 본 후킹은 forceCheck 기능을 사용할 때만 쓰인다.
    if (!this._forceCheck || this.moving) {
      return;
    }

    const childLength = this.getChildLength();
    const firstChild = this.children.first;

    if (
      (this.length === 0 && childLength === 0) ||
      this.length === childLength
    ) {
      if (!firstChild || (firstChild && firstChild.isInit)) {
        return;
      }
    }

    this.initSlider();
  }

  ngOnDestroy() {
    if (this.resize$) {
      this.resize$.unsubscribe();
    }
  }

  getParentOffsetSize() {
    const elem = this.elRef.nativeElement as HTMLElement;

    if (this.direction === 'x') {
      return elem.offsetWidth;
    }
    return elem.offsetHeight;
  }

  initSlider(retry: number = 0) {
    const elem = this.listWrap.nativeElement;
    const aElem = elem.children as HTMLCollection;
    const iLen = aElem.length;
    const size = this.getParentOffsetSize();

    // 내부 컨텐츠가 하나도 없다면 모든 내용을 초기화 하고 끝낸다.
    if (iLen === 0) {
      this.contents = [];
      this.length = 0;
      this.structure = [];
      this._size = 0;

      return;
    }

    if (!this.children.first.isInit) {
      this.children.forEach(item => {
        item.initPosition(this.direction);
      });
    }

    if (size === 0) {
      // console.log('retry', retry, width);
      if (retry > 2) {
        // throw new Error('Initialization failed because the sub-content size(width/height) could not be verified.');
        return;
      }
      setTimeout(() => {
        this.initSlider(retry + 1);
      }, 150);

      return;
    }

    this.contents = Array.prototype.map.call(
      aElem,
      (elemCont: HTMLElement) => elemCont,
      aElem
    );
    this.length = iLen;
    this.structure = positioningByIndex(this.direction, 0, this.contents, size);
    // styleTransform(elem, `translateX(${-1 * width}px)`);
    if (iLen > 1) {
      initMove(this.direction, elem, size);
      this._size = size;
      this._goTo(0);

      if (this._autoPlay) {
        this.startAutoPlay();
      }
    }
  }

  startAutoPlay() {
    if (this.autoPlay$) {
      this.stopAutoPlay();
    }
    this.autoPlay$ = interval(this._autoPlayInterval)
      .pipe(
        filter(() => !this.mouseOver),
        map(() => this.curr + 1)
      )
      .subscribe(index => this._goTo(index));
  }

  stopAutoPlay() {
    if (this.autoPlay$) {
      this.autoPlay$.unsubscribe();
      this.autoPlay$ = null;
    }
  }

  checkEdge(sign: number) {
    const maxIdx = this.contents.length - 1;
    const curr = this.curr;

    return (curr === 0 && sign > 0) || (curr === maxIdx && sign < 0);
  }

  goToProcess(index: number) {
    const curr = this.curr;
    const size = this._size;
    const { index: idx, sign } = filteredIndexSign(this.length, curr, index);
    const args: ContentSliderEventArgs = {
      beforeIndex: curr,
      index: idx,
    };

    if (this.length === 0) {
      return;
    }

    if (this._movingStateTime) {
      clearTimeout(this._movingStateTime);
    }

    this.moving = true;

    this._movingStateTime = setTimeout(() => {
      this.moving = false;
    }, 1000);

    if (this.length === 2) {
      // console.log('sign', sign, 'idx', idx, 'curr', curr);
      this.structure = positioningForDual(
        this.direction,
        sign,
        curr,
        idx,
        this.contents,
        size
      );

      setTimeout(() => {
        this._moveProcess(sign, size, args);
      }, 100);

      return;
    }

    // console.log('try checkEdge');

    if (!this.checkEdge(sign) && Math.abs(idx - curr) > 1) {
      this.structure = positioningByStartEnd(
        this.direction,
        curr,
        idx,
        this.contents,
        size
      );

      setTimeout(() => {
        this._moveProcess(sign, size, args);
      }, 100);

      return;
    }

    // console.log('try _moveProcess');

    this._moveProcess(sign, size, args);
  }

  private _moveProcess(
    sign: number,
    size: number,
    args: ContentSliderEventArgs
  ) {
    const { beforeIndex, index } = args;
    this.slideBeforeChange.emit(args);

    move(this.direction, this.listWrap.nativeElement, sign * size).subscribe(
      () => {
        this.structure = positioningByIndex(
          this.direction,
          index,
          this.contents,
          this._size
        );
        if (this._movingStateTime) {
          clearTimeout(this._movingStateTime);
        }
        this.moving = false;

        this._emitSlideChange(sign, args);
      }
    );

    this.before = beforeIndex;
    this._curr = index;
  }

  private _emitSlideChange(sign: number, args: ContentSliderEventArgs) {
    if (sign < 0) {
      this.slideLeft.emit(args);
    } else {
      this.slideRight.emit(args);
    }
    this.slideChange.emit(args);
  }

  private _goTo(index: number) {
    this.sbjGoToProcess.next(index);
  }

  goTo(index: number) {
    if (this._autoPlay) {
      this.startAutoPlay();
    }
    this._goTo(index);
  }

  prev() {
    this.goTo(this.curr - 1);
  }

  next() {
    this.goTo(this.curr + 1);
  }
}
