export interface HospitalSetting {
  useReceipt: boolean;
  useReservation: boolean;
  useReceiptSchedule: boolean;
  useSelfDesk: boolean;
  useKiosk: boolean;
  mobileEnvironment: MobileEnvironment;
  useExaminationInfants: boolean;
}

export interface MobileEnvironment {
  receipt: Receipt;
  reservation: Reservation;
  kiosk: Kiosk;
  examinationInfants: ExaminationInfants;
}

export interface ExaminationInfants {
  use: boolean;
  hideCanceled: boolean;
  useNewbornExam: boolean;
}

export interface Kiosk {
  use: boolean;
}

export interface Receipt {
  lastReceive: LastReceive;
  autoCancel: AutoCancel;
  disableArriveTime: boolean;
  use: boolean;
  useSchedule: boolean;
  waitCountOption: string;
}

export interface AutoCancel {
  use: boolean;
  delayTime: number;
}

export interface LastReceive {
  receipt: number;
  lunch: number;
  dinner: number;
}

export interface Reservation {
  usePenalty: boolean;
  useTodayBooking: boolean;
  availableAfterMinutes: number;
  maxTerm: number;
  timeUnit: number;
  useCRM: boolean;
  version: string;
  todayBookingOnTime: string;
  allowDuplicates: boolean;
}

export interface HospitalServiceSettingReq {
  useReceipt?: boolean;
  useReservation?: boolean;
  useSelfDesk?: boolean;
  useKiosk?: boolean;
}

/**
 * 검사결과 연동 모델
 */
export interface InspectionSetting {
  use: boolean;
  alimtalkSendDay?: AlimTalkSendEnum;
  /* Default value = ResultSendEnum.AFTER_RESULT_ARRIVED(false) */
  isSendPaper?: boolean;
}

export enum AlimTalkSendEnum {
  THE_DAY = 0,
  AFTER_ONE_DAY = 1,
  AFTER_TWO_DAYS = 2,
}

export enum ResultSendEnum {
  AFTER_CONSULTANT = 'AFTER_CONSULTANT',
  AFTER_RESULT_ARRIVED = 'AFTER_RESULT_ARRIVED',
}

/**
 * 검사결과 연동 Request 모델
 */
export interface InspectionSettingReqModel {
  receipt?: InspectionSetting;
  reservation?: InspectionSetting;
}
