import { AllStopCareRoom } from '~models/hospital.model';
import { Subject } from 'rxjs/';
import { CareRoom } from '~models/care-room.model';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-reception-pause-form-modal',
  templateUrl: './re-pause.modal.component.html',
})
export class ReceptionPauseFormModalComponent implements OnInit {
  @Input() careroom: CareRoom;
  @Input() type: 'KIOSK' | 'RECEPTION' = 'RECEPTION';

  pauseData: {
    use: boolean;
    msg: string;
  };

  pause;
  stopMsg: string;

  settingSubject = new Subject<any>();

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.pause = false;
    this.stopMsg = '';
  }

  get careRoomPause() {
    if (this.type === 'KIOSK') {
      return this.careroom.kioskPause;
    }
    return this.careroom.receiptPause;
  }

  get careRoomPauseMessage() {
    if (this.type === 'KIOSK') {
      return this.careroom.kioskPauseMessage;
    }
    return this.careroom.receiptPauseMessage;
  }

  get descriptionMessage() {
    if (this.type === 'KIOSK') {
      return '일시정지 시 사유를 입력하면 키오스크 사용자에게 표시됩니다.';
    }
    return '일시정지 시 사유를 입력하면 똑닥 앱 사용자에게 표시됩니다.';
  }

  get placeholderMsg() {
    if (this.pause || (!!this.careroom && this.careRoomPause)) {
      return '일시정지 사유 메시지를 입력해주세요.';
    } else {
      return '일시정지 기능을 사용하시면 작성하실 수 있습니다.';
    }
  }

  acceptSetting() {
    const data: AllStopCareRoom = {};
    const { careroom: careRoom, pause, stopMsg } = this;

    if (this.type === 'KIOSK') {
      data.kioskPause = careRoom ? careRoom.kioskPause : pause;
      data.kioskPauseMessage = careRoom ? careRoom.kioskPauseMessage : stopMsg;
    } else {
      data.receiptPause = careRoom ? careRoom.receiptPause : pause;
      data.receiptPauseMessage = careRoom
        ? careRoom.receiptPauseMessage
        : stopMsg;
    }

    this.settingSubject.next(data);
  }
}
