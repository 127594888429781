import { createReducer, on } from '@ngrx/store';

import { KioskMenuActiveInfo } from '~models/kiosk-menu-active.model';
import { KioskMenuActiveActions } from '~actions/products/kiosk-menu-active.actions';

export interface KioskMenuActiveState {
  loaded: boolean;
  kioskMenuActiveInfo?: KioskMenuActiveInfo;
}

const initialState: KioskMenuActiveState = {
  loaded: false,
};

export const kioskMenuActiveReducer = createReducer(
  initialState,
  on(KioskMenuActiveActions.KioskMenuActive, state => ({
    ...state,
    loaded: false,
  })),
  on(KioskMenuActiveActions.KioskMenuActiveSuccess, (state, action) => ({
    ...state,
    loaded: true,
    kioskMenuActiveInfo: action.res,
  })),
  on(KioskMenuActiveActions.KioskMenuActiveReqFail, state => ({
    ...state,
    loaded: false,
  }))
);
