import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subscription } from 'rxjs';

export interface LabelCheckbox {
  checked: boolean;
  name: string;
  id: string;
}

@Component({
  selector: 'app-label-checkbox',
  templateUrl: './label-checkbox.component.html',
  styleUrls: ['./label-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LabelCheckboxComponent),
      multi: true,
    },
  ],
})
export class LabelCheckboxComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() checked = false;
  @Input() badges = [];
  @Input() isDisabled = false;

  formControl: FormControl;
  subscription: Subscription;

  onChange: (_: boolean) => void;
  onTouched: () => void;

  constructor() {
    const { checked } = this;
    this.formControl = new FormControl(checked);
  }
  ngOnInit(): void {
    this.subscription = this.formControl.valueChanges.subscribe(checked => {
      return this.onChange(checked);
    });
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  writeValue(value: boolean): void {
    this.formControl.setValue(value, { emitEvent: false });
  }
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    isDisabled
      ? this.formControl.disable({ emitEvent: false })
      : this.formControl.enable({ emitEvent: false });
  }
}
