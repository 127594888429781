import { Injectable } from '@angular/core';

const isServer = typeof window === 'undefined';
const isAvailable = typeof localStorage !== 'undefined';
const KEY_LOCAL = 'local';
const KEY_SESSION = 'session';
const KEY_CACHE = 'cache';

export enum StorageType {
  local,
  session,
  cache,
}

export interface SimpleStorage {
  /**
   * 값을 스토리지에 설정 한다.
   * @param {string} key 키 이름
   * @param {any} val 설정할 값
   */
  set(key: string, value: any): void;

  /**
   * 스토리지에서 값을 가져온다.
   * SSR 일 경우 항상 null을 반환 한다.
   * @param {string} key
   * @returns {any} key 에 대응되는 값. 없으면 null을 반환. 내부 오류로 값을 가져오지 못할 경우 (예: private mode) 역시 null을 반환 한다.
   */
  get(key: string): any;

  /**
   * 스토리지의 특정 값을 제거 한다.
   * @param {string} key
   */
  remove(key: string): void;
}

export interface TypeStorage<T> {
  key: string;

  set(value: T): void;

  get(): T;

  remove(): void;
}

/**
 * 특정 값을 문자열 형태로 바꾼다.
 * 객체일 경우 문자열 형태로 직렬화 한다.
 * @param {any} value
 */
const valueToString = value => {
  if (typeof value === 'string') {
    return value;
  }

  return JSON.stringify(value);
};

/**
 * 문자열 값을 확인하여 문자열, 혹은 역직렬화 하여 객체 형태로 바꿔준다.
 * @param {string} value
 */
const stringToValue = value => {
  let firstLetter = '';
  // const regexNum = /^\d+$/;

  if (value) {
    firstLetter = value[0];

    if (firstLetter === '{' || firstLetter === '[') {
      try {
        return JSON.parse(value);
      } catch (error) {
        return value;
      }
      // } else if (value === 'true' || value === 'false') {
      //   return value === 'true';
      // } else if (regexNum.test(value)) {
      //   return parseInt(value);
    }
  }

  return value;
};

const memoryStorage = {
  get keys(): string[] {
    return Object.keys(this.store);
  },
  get length() {
    return this.keys.length;
  },
  key(index: number): string {
    return this.keys[index];
  },
  store: {},
  setItem(key, value) {
    this.store[key] = value;
  },
  getItem(key): string {
    return this.store[key];
  },
  removeItem(key): void {
    delete this.store[key];
  },
  clear(): void {
    this.store = {};
  },
} as Storage;

const getStorageByType = type => {
  if (isServer) {
    return {
      key: (index: number) => '',
      length: 0,
      setItem() {
        return null;
      },
      getItem() {
        return null;
      },
      removeItem() {
        return null;
      },
      clear(): void {},
    } as Storage;
  }

  if (!isAvailable || type === KEY_CACHE) {
    return memoryStorage;
  }

  if (type === KEY_LOCAL) {
    return localStorage;
  }

  if (type === KEY_SESSION) {
    return sessionStorage;
  }

  return null;
};

const getStorage = type => {
  const storeApi = getStorageByType(type);

  return {
    /**
     * 값을 스토리지에 설정 한다.
     * @param {string} key 키 이름
     * @param {any} val 설정할 값
     */
    set(key: string, value: any) {
      storeApi.setItem(key, valueToString(value));
    },
    /**
     * 스토리지에서 값을 가져온다.
     * SSR 일 경우 항상 null을 반환 한다.
     * @param {string} key
     * @returns {any} key 에 대응되는 값. 없으면 null을 반환. 내부 오류로 값을 가져오지 못할 경우 (예: private mode) 역시 null을 반환 한다.
     */
    get(key: string): any {
      return stringToValue(storeApi.getItem(key));
    },
    /**
     * 스토리지의 특정 값을 제거 한다.
     * @param {string} key
     */
    remove(key) {
      storeApi.removeItem(key);
    },
  };
};

/**
 * 웹브라우저의 localStorage 를 이용하여 값을 설정 하거나 가져 온다.
 * SSR을 고려하여 localStorage 가용 여부를 확인하는 기능이 적용 되어 있다.
 */
export const local = getStorage(KEY_LOCAL);
/**
 * 웹브라우저의 sessionStorage 를 이용하여 값을 설정 하거나 가져 온다.
 * SSR을 고려하여 sessionStorage 가용 여부를 확인하는 기능이 적용 되어 있다.
 */
export const session = getStorage(KEY_SESSION);
/**
 * 웹브라우저의 메모리를 이용하여 값을 설정 하거나 가져 온다.
 * SSR 이라면 항상 null을 반환 할 것이다.
 */
export const cache = getStorage(KEY_CACHE);

export default {
  local,
  session,
  cache,
};

function typeStorageFactory<T>(
  storage: SimpleStorage,
  key: string
): TypeStorage<T> {
  return {
    key,
    set(value: T) {
      storage.set(this.key, value);
    },
    get(): T {
      return storage.get(this.key);
    },
    remove() {
      storage.remove(this.key);
    },
  };
}

function getStorageByStorageType(type: StorageType): SimpleStorage {
  switch (type) {
    case StorageType.local:
      return local;
    case StorageType.session:
      return session;
    default:
      return cache;
  }
}

/**
 * 간단하게 스토리지를 이용할 수 있는 서비스 객체를 가져올 수 있다.
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  /**
   * 이용할 형태.
   * local, session, cache 3가지가 있다.
   * 기본 local.
   * @param type {SimpleStorage | 'local' | 'session' | 'cache'} 받아올 형태.
   */
  get(
    type: StorageType | 'local' | 'session' | 'cache' = StorageType.local
  ): SimpleStorage {
    let vType: StorageType;

    if (typeof type === 'string') {
      vType = StorageType[type];
    } else {
      vType = type;
    }

    return getStorageByStorageType(vType);
  }

  /**
   * 특정 키만을 이용하여 단일 타입 스토리지를 구성할 때 쓰인다.
   * 로컬 스토리지를 사용 한다.
   * @param key 스토리지에 이용 될 키.
   */
  local<T>(key: string): TypeStorage<T> {
    return typeStorageFactory<T>(local, key);
  }

  /**
   * 특정 키만을 이용하여 단일 타입 스토리지를 구성할 때 쓰인다.
   * 세션 스토리지를 사용 한다.
   * @param key 스토리지에 이용 될 키.
   */
  session<T>(key: string): TypeStorage<T> {
    return typeStorageFactory<T>(session, key);
  }

  /**
   * 특정 키만을 이용하여 단일 타입 스토리지를 구성할 때 쓰인다.
   * 메모리 스토리지를 사용 한다.
   * @param key 스토리지에 이용 될 키.
   */
  cache<T>(key: string): TypeStorage<T> {
    return typeStorageFactory<T>(cache, key);
  }
}
