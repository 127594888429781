import { createAction, props } from '@ngrx/store';
import { Category, Faq } from '~models/faq.model';
import { HttpRes } from '~models/common.model';

export enum FaqActionTypes {
  REQ_FAIL = '[FAQ] Request Fail',
  FAQ_CATEGORIES_LOAD = '[FAQ] Faq Categories Load',
  FAQ_CATEGORIES_LOAD_SUCCESS = '[FAQ] Faq Categories Load Success',
  FAQ_LIST_LOAD = '[FAQ] Faq List Load',
  FAQ_LIST_LOAD_SUCCESS = '[FAQ] Faq List Load Success',
  FAQ_FIXED_LIST_LOAD = '[FAQ] Faq Fixed List Load',
  FAQ_FIXED_LIST_LOAD_SUCCESS = '[FAQ] Faq Fixed List Load Success',
}

export const FaqReqFail = createAction(
  FaqActionTypes.REQ_FAIL,
  props<{ payload: any }>()
);

export const FaqCategoriesLoad = createAction(
  FaqActionTypes.FAQ_CATEGORIES_LOAD,
  props<{
    payload?: {
      limit?: number;
      skip?: number;
    };
  }>()
);

export const FaqCategoriesLoadSuccess = createAction(
  FaqActionTypes.FAQ_CATEGORIES_LOAD_SUCCESS,
  props<{
    payload?: Category[];
    faqParams?: {
      limit?: number;
      skip?: number;
    };
  }>()
);

export const FaqListLoad = createAction(
  FaqActionTypes.FAQ_LIST_LOAD,
  props<{
    payload: {
      category?: string;
      limit?: number;
      skip?: number;
      isFixed?: boolean;
      keywords?: {
        content?: string;
        title?: string;
      };
    };
  }>()
);

export const FaqListLoadSuccess = createAction(
  FaqActionTypes.FAQ_LIST_LOAD_SUCCESS,
  props<{ payload: HttpRes<Faq> }>()
);

export const FixedFaqListLoad = createAction(
  FaqActionTypes.FAQ_FIXED_LIST_LOAD,
  props<{
    payload: {
      category?: string;
      limit?: number;
      isFixed?: boolean;
      keywords?: {
        content?: string;
        title?: string;
      };
    };
  }>()
);

export const FixedFaqListLoadSuccess = createAction(
  FaqActionTypes.FAQ_FIXED_LIST_LOAD_SUCCESS,
  props<{ payload: HttpRes<Faq> }>()
);

export const FaqActions = {
  FaqReqFail,
  FaqCategoriesLoad,
  FaqCategoriesLoadSuccess,
  FaqListLoad,
  FaqListLoadSuccess,
  FixedFaqListLoad,
  FixedFaqListLoadSuccess,
};
