import { CarouselItem } from '~models/signin-carousel.model';

export const carouselItems: CarouselItem[] = [
  {
    mainTitle: '환자 진료 전',
    order: 'first',
    contents: [
      {
        title: '똑닥 검색',
        imgUrl: 'assets/img/signin/carousel/search.png',
        scrollImgUrl: 'assets/img/signin/scroll_mobile/search.png',
        explain:
          '똑닥 검색은 우리 병원 인근 환자에게 병원이 직접 설정한 키워드로 홍보합니다.',
      },
      {
        title: '똑닥 접수/예약',
        imgUrl: 'assets/img/signin/carousel/reservation.png',
        scrollImgUrl: 'assets/img/signin/scroll_mobile/reservation.png',
        explain:
          '똑닥 접수/예약은 전국 유일·최초로 전자차트 프로그램과 연동되어 프로그램 추가 사용 없이 손쉽게 접수/예약이 가능합니다. ',
      },
      {
        title: '똑닥 사전문진/영유아검진',
        imgUrl: 'assets/img/signin/carousel/paperweight.png',
        scrollImgUrl: 'assets/img/signin/scroll_mobile/paperweight.png',
        explain:
          '똑닥 사전문진을 통해 반복해서 물어보는 질문을 줄여보세요 진료의 시작이 달라집니다.\n똑닥 영유아검진은 환자 스스로 모바일 영유아 검진표를 작성해 병원에서 일일이 안내, 관리하는 서류 업무가 줄어듭니다. ',
      },
      {
        title: '똑닥 대기현황',
        imgUrl: 'assets/img/signin/carousel/waiting.png',
        scrollImgUrl: 'assets/img/signin/scroll_mobile/waiting.png',
        explain:
          '환자가 외부에 있어도 똑닥 대기현황 알림 서비스만 있으면 병원의 호출없이 환자가 알아서 방문합니다.',
      },
    ],
  },
  {
    mainTitle: '환자 진료 후',
    order: 'second',
    contents: [
      {
        title: '똑닥 간편결제/서류발급',
        imgUrl: 'assets/img/signin/carousel/pay.png',
        scrollImgUrl: 'assets/img/signin/scroll_mobile/pay.png',
        explain:
          '똑닥 간편결제는 병원 최초 간편결제 서비스로 환자의 결제 편의는 높여주고, 병원의 수납업무는 줄여줍니다.\n똑닥 모바일 서류발급은 실손보험 청구용 서류를 모바일로 발급하여 불필요한 서류발급 업무를 간소화합니다.',
      },
      {
        title: '검사결과 알림',
        imgUrl: 'assets/img/signin/carousel/notice.png',
        scrollImgUrl: 'assets/img/signin/scroll_mobile/notice.png',
        explain:
          '검사결과 알림은 검사결과 도착안내 전화를 하지 않아도 똑닥이 자동으로 환자에게 알려주면 환자가 알아서 방문합니다. ',
      },
      {
        title: '똑닥 App CRM',
        imgUrl: 'assets/img/signin/carousel/crm.png',
        scrollImgUrl: 'assets/img/signin/scroll_mobile/crm.png',
        explain:
          '똑닥 App CRM으로 의원이 전하고픈 메시지를 환자에게 무료로 전하세요.',
      },
    ],
  },
  {
    mainTitle: '병원 경영관리',
    order: 'last',
    contents: [
      {
        title: '알파앤',
        subTitle: '의사랑 EMR 한정 이용가능합니다.',
        imgUrl: 'assets/img/signin/carousel/alphaen.png',
        scrollImgUrl: 'assets/img/signin/scroll_mobile/alphaen.png',
        explain:
          '알파앤은 빅데이터를 기반으로 컨설팅 회사도 알려줄 수 없는 우리 병원과 지역 최신 의료정보를 비교 분석하여 병원 경영의 방향성을 제시합니다.',
      },
    ],
  },
];
