<div class="text-input-wrap">
  <label for=""> {{ label }} </label>
  <input
    #input
    class="form-control text-input"
    [type]="type"
    [placeholder]="placeholder"
    [minlength]="minLength"
    [maxlength]="maxLength"
    [(ngModel)]="value"
    (ngModelChange)="handleChange($event)"
  />
</div>
