import { ServiceVideoActions } from '~actions/auth/service-video.actions';
import { ServiceVideoModel } from '~models/service-video.model';
import { HttpErrorRes, HttpRes } from '~models/common.model';
import { createReducer, on } from '@ngrx/store';

export interface ServiceVideoState {
  response?: HttpRes<ServiceVideoModel>;
  error?: HttpErrorRes;
}

const initialState: ServiceVideoState = {
  response: undefined,
  error: undefined,
};

export const reducer = createReducer(
  initialState,
  on(ServiceVideoActions.ServiceVideoLoadSuccess, (state, action) => ({
    ...state,
    response: action.payload,
  })),
  on(ServiceVideoActions.ServiceVideoLoadFail, (state, action) => ({
    ...state,
    error: action.payload,
  }))
);

export const getServiceVideoResponse = (state: ServiceVideoState) =>
  state.response;
export const getServiceVideoError = (state: ServiceVideoState) => state.error;
