<div class="modal-header ha-modal-header">
  <div class="modal-title-wrap">
    <ng-container *ngIf="careroom; else setTitleAll">
      <p>
        {{ careroom?.title }}
        <small class="text-muted">{{ careroom?.subject }}</small>
      </p>
      <h5 class="modal-title">일시정지 설정</h5>
    </ng-container>
    <ng-template #setTitleAll>
      <h5 class="modal-title">모든 진료실 일괄적용</h5>
    </ng-template>
    <p class="modal-title-desc">
      {{ descriptionMessage }}
    </p>
  </div>
</div>

<div class="modal-body">
  <ng-container *ngIf="careroom; else setCareroomAll">
    <!-- box -->
    <div class="ha-box-wrap">
      <div class="ha-box-item breakpoint-sm">
        <div class="title-area"><p>일시정지 기능</p></div>
        <div class="setting-area">
          <div
            *ngIf="type === 'RECEPTION'"
            class="ha-btn-group-toggle"
            ngbRadioGroup
            name="radioBasic"
            [(ngModel)]="careroom.receiptPause"
          >
            <label ngbButtonLabel>
              <input ngbButton type="radio" [value]="true" /> ON
            </label>
            <label ngbButtonLabel>
              <input ngbButton type="radio" [value]="false" /> OFF
            </label>
          </div>
          <div
            *ngIf="type === 'KIOSK'"
            class="ha-btn-group-toggle"
            ngbRadioGroup
            name="radioBasic"
            [(ngModel)]="careroom.kioskPause"
          >
            <label ngbButtonLabel>
              <input ngbButton type="radio" [value]="true" /> ON
            </label>
            <label ngbButtonLabel>
              <input ngbButton type="radio" [value]="false" /> OFF
            </label>
          </div>
        </div>
      </div>
    </div>
    <!--// box -->

    <!-- box -->
    <div class="ha-box-wrap">
      <div class="ha-box-item breakpoint-sm">
        <div class="title-area"><p>일시정지 사유</p></div>
        <div class="setting-area">
          <textarea
            *ngIf="type === 'RECEPTION'"
            [placeholder]="placeholderMsg"
            class="form-control ha-form-control"
            [disabled]="!careroom.receiptPause"
            [(ngModel)]="careroom.receiptPauseMessage"
          >
          </textarea>
          <textarea
            *ngIf="type === 'KIOSK'"
            [placeholder]="placeholderMsg"
            [maxLength]="100"
            class="form-control ha-form-control"
            [disabled]="!careroom.kioskPause"
            [(ngModel)]="careroom.kioskPauseMessage"
          >
          </textarea>
        </div>
      </div>
    </div>
    <!--// box -->
  </ng-container>

  <ng-template #setCareroomAll>
    <!-- box -->
    <div class="ha-box-wrap">
      <div class="ha-box-item breakpoint-sm">
        <div class="title-area"><p>일시정지 기능</p></div>
        <div class="setting-area">
          <div
            class="ha-btn-group-toggle"
            ngbRadioGroup
            name="radioBasic"
            [(ngModel)]="pause"
          >
            <label ngbButtonLabel>
              <input ngbButton type="radio" [value]="true" /> ON
            </label>
            <label ngbButtonLabel>
              <input ngbButton type="radio" [value]="false" /> OFF
            </label>
          </div>
        </div>
      </div>
    </div>
    <!--// box -->

    <!-- box -->
    <div class="ha-box-wrap">
      <div class="ha-box-item breakpoint-sm">
        <div class="title-area"><p>일시정지 사유</p></div>
        <div class="setting-area">
          <textarea
            class="form-control ha-form-control"
            [placeholder]="placeholderMsg"
            [disabled]="!pause"
            [(ngModel)]="stopMsg"
          >
          </textarea>
        </div>
      </div>
    </div>
    <!--// box -->
  </ng-template>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="ha-btn ha-btn-secondary"
    (click)="activeModal.close('cancel')"
  >
    닫기
  </button>
  <button type="button" class="ha-btn ha-btn-primary" (click)="acceptSetting()">
    설정 적용
  </button>
</div>
