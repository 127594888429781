import { Observable, Subject } from 'rxjs';
import { share, take } from 'rxjs/operators';

export class ControlEvent<A> {
  private subject: Subject<A>;

  constructor(terminateObservable: Observable<void>) {
    this.subject = new Subject<A>();

    terminateObservable.pipe(take(1)).subscribe(() => {
      this.subject.complete();
    });
  }

  next(a?: A) {
    this.subject.next(a);
  }

  get asObservable(): Observable<A> {
    return this.subject.asObservable().pipe(share());
  }
}
