import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';

import { FindPwActions } from '~actions/auth/find-pw.action';

import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { AuthService } from '~core/api/auth.service';

@Injectable()
export class FindPwEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router
  ) {}

  findPw$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FindPwActions.FindPw),
      map(action => action.payload),
      exhaustMap(info =>
        this.authService.findPw(info).pipe(
          map(reToken => {
            return FindPwActions.FindPwComplete({ payload: reToken });
          }),
          catchError(error =>
            of(FindPwActions.FindPwFailure({ payload: error }))
          )
        )
      )
    )
  );

  findPwComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FindPwActions.FindPwComplete),
        map(action => action.payload),
        tap(token => {
          this.router.navigate(['reset-pw/' + token.passwordResetToken]);
        })
      ),
    {
      dispatch: false,
    }
  );
}
