import { OfficeWeeklyDetailData } from '~models/care-room.model';
import { HashMap } from '~models/common.model';
import { RecScheduleActions } from '~actions/products/rec-schedule.actions';
import { createReducer, on } from '@ngrx/store';

export interface RecScheduleState {
  loading: boolean;
  message: string;
  schedules: HashMap<OfficeWeeklyDetailData>;
}

const initialState: RecScheduleState = {
  loading: false,
  message: '',
  schedules: {},
};

export const reducer = createReducer(
  initialState,
  on(
    RecScheduleActions.RecScheduleLoad,
    RecScheduleActions.RecScheduleRead,
    RecScheduleActions.RecScheduleUpdate,
    (state, action) => ({
      ...state,
      loading: true,
      message: '',
    })
  ),
  on(
    RecScheduleActions.RecScheduleLoadFail,
    RecScheduleActions.RecScheduleReadFail,
    RecScheduleActions.RecScheduleUpdateFail,
    (state, action) => ({
      ...state,
      loading: false,
      message: action.error.message,
    })
  ),
  on(
    RecScheduleActions.RecScheduleLoadSuccess,
    RecScheduleActions.RecScheduleReadSuccess,
    RecScheduleActions.RecScheduleBatchApply,
    RecScheduleActions.RecScheduleEdit,
    (state, action) => ({
      ...state,
      schedules: action.payload,
      loading: false,
      message: '',
    })
  ),
  on(RecScheduleActions.RecScheduleUpdateSuccess, (state, action) => ({
    ...state,
    loading: false,
    message: '',
  }))
);
