import { createReducer, on } from '@ngrx/store';
import {
  SymptomUnit,
  Schedule,
  SymptomCategoryInfo,
  OperateTime,
  HolidaysInfo,
  HasScheduleMonthInfo,
  EachMonthTag,
  SaveActionTypeEnum,
} from '~models/reservation-schedule-v2.model';
import { ReservationScheduleActions } from '~actions/products/reservation-schedule-v2.actions';
import { keyBy as _keyBy } from 'lodash';
import * as moment from 'moment';
import { momentToStringParser } from '../../services/reservation-scheduler-v2/scheduler-time-parser';
import { SchedulerTags } from '~models/common.model';

export interface ReservationScheduleState {
  id: string;
  version: number;
  loading: boolean;
  message: string;
  error: string;
  symptomListData: SymptomUnit[];
  editorSchedules: Schedule[];
  operateTime?: OperateTime;
  holidaysInfo: HolidaysInfo;
  categoryInfo: SymptomCategoryInfo;
  currentDate: string;
  year: number;
  month: number;
  firstDayOfWeek: string;
  week: number;
  totalWeek: number;
  hasScheduleMonth: HasScheduleMonthInfo;
  tags: SchedulerTags;
  usedTags: SchedulerTags[];
  monthsTags: EachMonthTag;
  isFromV1: boolean;
  hasSaved: boolean;
  hasModified: boolean;
  saveActionType: SaveActionTypeEnum;
}

const initialState: ReservationScheduleState = {
  id: '',
  version: 0,
  loading: false,
  message: '',
  error: null,
  symptomListData: [],
  editorSchedules: [],
  holidaysInfo: null,
  categoryInfo: {},
  currentDate: momentToStringParser(moment()),
  year: moment().year(),
  month: moment().month() + 1,
  firstDayOfWeek: null,
  week: null,
  totalWeek: null,
  hasSaved: false,
  hasScheduleMonth: null,
  tags: null,
  usedTags: null,
  monthsTags: null,
  isFromV1: false,
  hasModified: false,
  saveActionType: null,
};

export const reducer = createReducer(
  initialState,
  on(
    ReservationScheduleActions.FetchReservationSchedule,
    ReservationScheduleActions.FetchCategoryList,
    ReservationScheduleActions.FetchOperateTime,
    ReservationScheduleActions.FetchHolidays,
    ReservationScheduleActions.UpdateTime,
    ReservationScheduleActions.FetchExistsScheduleMonth,
    state => ({
      ...state,
      loading: true,
      message: '',
      usedTags: null,
    })
  ),
  on(ReservationScheduleActions.UpdateReservationSchedule, state => ({
    ...state,
    loading: true,
    message: '',
    hasSaved: false,
    hasModified: false,
    saveActionType: null,
  })),
  on(ReservationScheduleActions.ReservationScheduleFail, (state, action) => ({
    ...state,
    loading: false,
    message: action.message,
  })),
  on(
    ReservationScheduleActions.FetchReservationScheduleSuccess,
    (state, { payload }) => {
      const { _id, version, preset, tags, editorSchedules } = payload;
      return {
        ...state,
        loading: false,
        message: '',
        id: _id,
        version,
        symptomListData: preset.symptomUnit,
        editorSchedules,
        tags,
      };
    }
  ),
  on(ReservationScheduleActions.FetchCategoryListSuccess, (state, action) => ({
    ...state,
    categoryInfo: _keyBy(action.payload.items, '_id'),
  })),
  on(ReservationScheduleActions.FetchOperateTimeSuccess, (state, action) => ({
    ...state,
    loading: false,
    operateTime: action.payload.operateTime,
  })),
  on(ReservationScheduleActions.FetchHolidaysSuccess, (state, action) => ({
    ...state,
    loading: false,
    holidaysInfo: action.payload,
  })),
  on(ReservationScheduleActions.UpdateTimeSuccess, (state, action) => ({
    ...state,
    loading: false,
    currentDate: action.payload.currentDate,
    year: action.payload.year,
    month: action.payload.month,
    firstDayOfWeek: action.payload.firstDayOfWeek,
    week: action.payload.week,
    totalWeek: action.payload.totalWeek,
  })),
  on(
    ReservationScheduleActions.UpdateReservationScheduleSuccess,
    (state, { editorSchedules }) => ({
      ...state,
      hasSaved: true,
      editorSchedules,
    })
  ),
  on(
    ReservationScheduleActions.FetchExistsScheduleMonthSuccess,
    (state, action) => ({
      ...state,
      hasScheduleMonth: action.payload,
      usedTags: action.usedTags,
      monthsTags: action.monthsTag,
    })
  ),
  on(ReservationScheduleActions.UpdateMonthsTagSuccess, (state, action) => ({
    ...state,
    isFromV1: action.isFromV1,
  })),
  on(ReservationScheduleActions.SetHasModified, (state, action) => ({
    ...state,
    hasModified: action.payload,
    hasSaved: !action.payload,
  })),
  on(ReservationScheduleActions.SetSaveActionType, (state, action) => ({
    ...state,
    saveActionType: action.payload,
  }))
);
