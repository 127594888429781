import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentFormat',
})
export class MomentFormatPipe implements PipeTransform {
  transform(time: any, format: string): any {
    return !!time ? moment(time).format(format) : time;
  }
}
