import { createAction, props } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';

export const RouterActionTypes = {
  GO: '[Router] GO',
  BACK: '[Router] BACK',
  FORWARD: '[Router] FORWARD',
} as const;

export const Go = createAction(
  RouterActionTypes.GO,
  props<{
    payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    };
  }>()
);

export const Back = createAction(RouterActionTypes.BACK);

export const Forward = createAction(RouterActionTypes.FORWARD);

export const RouterActions = { Go, Back, Forward };
