import { createAction, props } from '@ngrx/store';
import { Banner } from '~models/common.model';
import { ServiceUnsetInfoType } from '~core/api/guides.service';

export enum HomeActionType {
  BANNER = '[Home] Banner',
  BANNER_SUCCESS = '[Home] Banner Success',
  BANNER_FAIL = '[Home] Banner Fail',
  EMERGENCY_UPDATE = '[Home] Emergency Update',
  EMERGENCY_UPDATE_SUCCESS = '[Home] Emergency Update Success',
  EMERGENCY_UPDATE_FAIL = '[Home] Emergency Update Fail',
  SERVICE_GUIDE = '[Home] Service Guide',
  SERVICE_GUIDE_SUCCESS = '[Home] Service Guide Success',
}

export const HomeBanner = createAction(HomeActionType.BANNER);

export const HomeBannerSuccess = createAction(
  HomeActionType.BANNER_SUCCESS,
  props<{ payload: Banner[] }>()
);

export const HomeBannerFail = createAction(
  HomeActionType.BANNER_FAIL,
  props<{ payload: any }>()
);

export const HomeEmergencyUpdate = createAction(
  HomeActionType.EMERGENCY_UPDATE,
  props<{ payload: boolean }>()
);

export const HomeEmergencyUpdateSuccess = createAction(
  HomeActionType.EMERGENCY_UPDATE_SUCCESS,
  props<{ payload: boolean }>()
);

export const HomeEmergencyUpdateFail = createAction(
  HomeActionType.EMERGENCY_UPDATE_FAIL,
  props<{ payload: any }>()
);

export const HomeServiceGuide = createAction(HomeActionType.SERVICE_GUIDE);

export const HomeServiceGuideSuccess = createAction(
  HomeActionType.SERVICE_GUIDE_SUCCESS,
  props<{ payload: ServiceUnsetInfoType }>()
);

export const HomeActions = {
  HomeBanner,
  HomeBannerSuccess,
  HomeBannerFail,
  HomeEmergencyUpdate,
  HomeEmergencyUpdateSuccess,
  HomeEmergencyUpdateFail,
  HomeServiceGuide,
  HomeServiceGuideSuccess,
};
