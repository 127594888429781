import { createAction, props } from '@ngrx/store';
import {
  ReceptionCanceledResItem,
  StatusCanceledSearchUiQuery,
} from '~models/status-canceled.model';
import { ListDataRes } from '~models/common';

export const StatusCanceledActionTypes = {
  LOAD_LIST: 'STATUS_CANCELED - LOAD_LIST',
  LOAD_LIST_SUCCESS: 'STATUS_CANCELED - LOAD_LIST_SUCCESS',
  DOWNLOAD_LIST: 'STATUS_CANCELED - DOWNLOAD_LIST',
  FAIL: 'STATUS_CANCELED - FAIL',
} as const;

export const StatusCanceledLoadList = createAction(
  StatusCanceledActionTypes.LOAD_LIST,
  props<{ payload: StatusCanceledSearchUiQuery }>()
);

export const StatusCanceledLoadListSuccess = createAction(
  StatusCanceledActionTypes.LOAD_LIST_SUCCESS,
  props<{ payload: ListDataRes<ReceptionCanceledResItem> }>()
);

export const StatusCanceledDownloadList = createAction(
  StatusCanceledActionTypes.DOWNLOAD_LIST,
  props<{ payload: StatusCanceledSearchUiQuery }>()
);

export const StatusCanceledFail = createAction(
  StatusCanceledActionTypes.FAIL,
  props<any>()
);

export const StatusCanceledActions = {
  StatusCanceledLoadList,
  StatusCanceledLoadListSuccess,
  StatusCanceledDownloadList,
  StatusCanceledFail,
};
