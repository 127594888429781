import { ExpenseActions } from '~actions/products/expense.actions';
import {
  ExpenseDetailUiModel,
  ExpenseGroupUiModel,
} from '~models/expense.model';
import { createReducer, on } from '@ngrx/store';

export interface ExpenseState {
  items: ExpenseGroupUiModel[];
  totalCount: number;
  selectedItem: null | ExpenseDetailUiModel;
  pending: boolean;
}

const initialState: ExpenseState = {
  items: [],
  totalCount: 0,
  selectedItem: null,
  pending: false,
};

export const reducer = createReducer(
  initialState,
  on(ExpenseActions.ExpenseDetailLoad, (state, action) => ({
    ...state,
    pending: true,
    selectedItem: null,
  })),
  on(ExpenseActions.ExpenseDetailLoadSuccess, (state, action) => ({
    ...state,
    pending: false,
    selectedItem: action.payload,
  })),
  on(ExpenseActions.ExpenseDetailReset, (state, action) => ({
    ...state,
    pending: false,
    selectedItem: null,
  })),
  on(ExpenseActions.ExpenseListLoadSuccess, (state, action) => ({
    ...state,
    pending: false,
    totalCount: action.payload.totalCount,
    items: action.payload.items,
  })),
  on(
    ExpenseActions.ExpenseListLoad,
    ExpenseActions.ExpenseUpdate,
    ExpenseActions.ExpenseRegister,
    (state, action) => ({ ...state, pending: true })
  ),
  on(
    ExpenseActions.ExpenseUpdateSuccess,
    ExpenseActions.ExpenseRegisterSuccess,
    ExpenseActions.ExpenseReqFail,
    (state, action) => ({ ...state, pending: false })
  ),
  on(ExpenseActions.ExpenseUpdateActiveSuccess, (state, action) => {
    const { id: targetId, active } = action.payload;
    let itemIndex = -1;
    const groupIndex = state.items.findIndex(({ items }) => {
      const index = items.findIndex(({ id }) => targetId === id);
      if (index > -1) {
        itemIndex = index;
      }
      return index > -1;
    });
    const nextItems = state.items[groupIndex].items;
    nextItems[itemIndex] = {
      ...nextItems[itemIndex],
      active,
    };
    const nextGroupItem = [...state.items];
    nextGroupItem[groupIndex] = {
      ...state.items[groupIndex],
      items: [...nextItems],
    };
    return {
      ...state,
      items: nextGroupItem,
    };
  })
);
