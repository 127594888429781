import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { ServiceVideoService } from '~core/api/service-video.service';
import { HttpRes } from '~models/common.model';
import { ServiceVideoModel } from '~models/service-video.model';
import { ServiceVideoActions } from '~actions/auth/service-video.actions';

@Injectable()
export class ServiceVideoEffects {
  constructor(
    private actions$: Actions,
    private serviceVideo: ServiceVideoService
  ) {}

  getServiceVideo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ServiceVideoActions.ServiceVideoLoad),
      exhaustMap(action => {
        return this.serviceVideo.fetchServiceVideo(action.payload).pipe(
          map((res: HttpRes<ServiceVideoModel>) => {
            return ServiceVideoActions.ServiceVideoLoadSuccess({
              payload: res,
            });
          }),
          catchError((error: HttpErrorResponse) =>
            of(ServiceVideoActions.ServiceVideoLoadFail({ payload: error }))
          )
        );
      })
    )
  );
}
