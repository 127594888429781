<div class="page-bg"></div>
<div class="wrap">
  <a class="find-header mb-5" routerLink="/">
    <img
      class="mb-4"
      src="/assets/img/find-id-password.png"
      width="100"
      height="37"
    />
    <hr />
  </a>
  <div class="title-section"><span>비밀번호 재설정</span></div>
  <form [formGroup]="resetPasswordForm" (submit)="onSubmit($event)">
    <span class="form-title">변경할 비밀번호</span>
    <div class="form-group">
      <input
        type="password"
        formControlName="password"
        autocomplete="new-password"
        class="form-control-input"
        id="inputPassword"
        placeholder="비밀번호 (영문+숫자+특수문자 1자 이상 포함, 6 ~ 18자)"
      />
      <ng-container *ngIf="password.invalid">
        <small *ngIf="password.errors.pattern" class="form-text text-danger">
          비밀번호 조건이 맞지 않습니다.
        </small>
      </ng-container>
    </div>
    <div class="form-group mb-5" style="margin-bottom: 53px;">
      <input
        type="password"
        formControlName="resetPassword"
        autocomplete="new-password"
        class="form-control-input"
        id="inputResetPassword"
        placeholder="비밀번호 확인"
      />
    </div>
    <button
      [ngClass]="{ 'btn-disabled': resetPasswordForm.invalid }"
      type="submit"
      [disabled]="resetPasswordForm.invalid"
      class="btn btn-lg submit-btn"
    >
      비밀번호 변경
    </button>
    <p style="margin-top: 16px; color: red;">{{ error }}</p>
  </form>
</div>
