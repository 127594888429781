import { Pipe, PipeTransform } from '@angular/core';
import { WEEKDAY_NAME_MAP } from '~models/care-room.model';

@Pipe({
  name: 'dayTransform',
})
export class DayTransForm implements PipeTransform {
  transform(day: string) {
    return WEEKDAY_NAME_MAP[day];
  }
}
