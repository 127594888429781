/* CRM Service */
// export type EMR_TYPE_STRING =
//   | 'BIT'
//   | 'UBCARE'
//   | 'POINT'
//   | 'POINTNIX'
//   | 'DASOM'
//   | 'NEO'
//   | 'BRAIN'
//   | 'FORDOCTOR'
//   | '';

// export namespace EMR_TYPE {
//   export const NONE = '';
//   export const BIT = 'BIT';
//   export const UBCARE = 'UBCARE';
//   export const POINT = 'POINT';
//   export const DASOM = 'DASOM';
//   export const NEO = 'NEO';
//   export const BRAIN = 'BRAIN';
//   export const POINTNIX = 'POINTNIX';
//   export const FORDOCTOR = 'FORDOCTOR';
//   export const ALL = [
//     NONE,
//     BIT,
//     UBCARE,
//     POINT,
//     POINTNIX,
//     DASOM,
//     NEO,
//     BRAIN,
//     FORDOCTOR,
//   ];
// }

export enum EmrTypeEnum {
  UBCLOUD = 'UBCLOUD', // "유비케어 - 클라우드(정식명칭모름)",
  UBCARE = 'UBCARE', // "유비케어 - 의사랑",
  BIT = 'BIT', // "비트컴퓨터 - U차트",
  BITHIB = 'BITHIB', // "비트컴퓨터 - bitnixHIB",
  BITCLOUD = 'BITCLOUD', // "비트클라우드", MEMO: BITCLOUD -> "비트플러스" 대체 데이터로 사용.
  NEO = 'NEO', // "네오소프트뱅크 - 이플러스",
  POINT = 'POINT', // "포인트닉스 - 닉스펜, 닉스SQL",
  DASOM = 'DASOM', // "다솜정보 - 신통, 차트매니저",
  BYUNGCOM = 'BYUNGCOM', // "병원과컴퓨터 - 차트프로",
  MEDICAL = 'MEDICAL', // "메디칼소프트 - 히포크라테스",
  FORDOCTOR = 'FORDOCTOR', // "포닥터 - 매직차트",
  BRAIN = 'BRAIN', // "브레인헬스케어 - 닥터스, 닥터브레인(핵톤프로젝트)",
  ONITMEDI = 'ONITMEDI', // "온아이티메디컴",
  CLICKSOFT = 'CLICKSOFT', // "클릭소프트",
  CWIT = 'CWIT', // 중외정보기술,
  ETC = 'ETC', // "기타"
}

export type USING_SERVICE_TYPE =
  | 'RECEIPT'
  | 'RESERVATION'
  | 'RECEPTION'
  | 'EXAMINATION_INFANTS';
export type SMS_TYPE = 'sms | lms | mms';

export interface CrmResAgreement {
  // crm동의여부
  isAgreed: boolean;
  receiptAgreed: boolean;
  reservationAgreed: boolean;
  hospitalTitle: string;
  representative: string;
  phone?: string;
  address: string;
}

export interface CrmReqAgreement {
  // crm마케팅 동의
  address?: string; // 주소
  representative?: string; // 대표자 성명 수정
  phone?: string; // 전화번호
}

export interface CrmReqMessage {
  senderPhone?: string; // 발신자 전화번호 (숫자만)
  isReservation?: boolean; // 예약 발송 여부
  reservationDate?: Date; // 예약 발송할 시간
  receiversFile: string; // 수신대상자 엑셀파일 업로드
  usingService: USING_SERVICE_TYPE; // 사용중인 서비스
  messageType: SMS_TYPE; // 문자 발송 타입
  messageHeader: string; // 메시지 내용
  messageBody: string; // 메시지 내용
  messageFooter: string; // 메시지 내용
  comment?: string; // 참고사항
}

export interface CrmFileLists {
  result: {
    filePath: string;
  };
}
