import { Pipe, PipeTransform } from '@angular/core';
import { ChildScreeningResItem } from '../../models/child-screening.model';

const MAP_STATE = {
  RESERVATION_CONFIRM: '예약 확정',
  RESERVATION_RETRACT: '사용자 철회',
  RESERVATION_CANCEL: '병원 취소', // 병원 취소 및 자동 취소가 섞여 있음.
  RESERVATION_AUTO_CANCEL: '자동 취소',
  RESERVATION_RECEIPT: '가접수',
  RECEIVE: '내원',
  COMPLETE: '완료',
  PENDING: '진료 보류'
};

@Pipe({
  name: 'resStateName'
})
export class ResStateNamePipe implements PipeTransform {
  transform(item: ChildScreeningResItem): string {
    const state = item.state;
    const ret = MAP_STATE[state];

    if (!ret) {
      return '알 수 없음';
    }

    if (state === 'RESERVATION_CANCEL') {
      if (item.isAutoCancel) {
        return MAP_STATE.RESERVATION_AUTO_CANCEL;
      }
    }

    return ret;
  }
}
