import { Subject } from 'rxjs/';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-reception-option-modal',
  template: `
    <div class="modal-header ha-modal-header">
      <div class="modal-title-wrap">
        <h5 class="modal-title">접수 옵션 설정</h5>
        <p class="modal-title-desc">
          도착예상시간을 사용할 경우 접수 자동취소를 사용할 수 없습니다.
        </p>
      </div>
    </div>
    <div class="modal-body">
      <!-- box -->
      <div class="ha-box-wrap">
        <div class="ha-box-item breakpoint-sm">
          <div class="title-area"><span>도착예상시간</span></div>
          <div class="setting-area">
            <label class="ha-form-control ha-form-checkbox">
              <input
                class="ha-form-control-input"
                type="checkbox"
                [ngModel]="!instantData.disableArrive"
                (ngModelChange)="changeDisable()"
              />
              <span
                class="ha-form-control-label"
                [ngClass]="{ 'text-disabled': instantData.disableArrive }"
                >{{ useUnuse(!instantData.disableArrive) }}</span
              >
            </label>
          </div>
        </div>
      </div>
      <!-- box -->

      <!-- box -->
      <div class="ha-box-wrap">
        <div class="ha-box-item2 breakpoint-sm autoCancelItem">
          <div class="d-flex">
            <div class="title-area"><span>접수 자동취소</span></div>
            <div class="setting-area">
              <label class="ha-form-control ha-form-checkbox">
                <input
                  class="ha-form-control-input"
                  type="checkbox"
                  [disabled]="!instantData.disableArrive"
                  [ngModel]="instantData.autoCancel"
                  (ngModelChange)="changeAutoCancel()"
                />
                <span class="ha-form-control-label">
                  {{ useUnuse(instantData.autoCancel) }}</span
                >
              </label>

              <div
                class="form-inline mt-2"
                *ngIf="instantData.autoCancel && instantData.disableArrive"
              >
                <span>취소지연시간 입력</span>
                <input
                  type="number"
                  class="form-control"
                  [(ngModel)]="delayTimeMinutes"
                />
                <label style="color:#b8b8b8">분</label>
              </div>
            </div>
          </div>
          <div class="notice">
            ※ 대기 순번이 6번째 이상 환자부터 자동취소되는 분 단위를 설정합니다.
          </div>
        </div>
      </div>
      <!-- box -->
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="ha-btn ha-btn-secondary"
        (click)="activeModal.close('cancel')"
      >
        닫기
      </button>
      <button
        type="button"
        class="ha-btn ha-btn-primary"
        (click)="updateOptions()"
      >
        설정 적용
      </button>
    </div>
  `,
  styleUrls: ['./re-option-modal.component.scss'],
})
export class ReceptionOptionModalComponent {
  instantData = {
    disableArrive: false,
    autoCancel: false,
    delayTime: 0,
  };

  optionDataSubject = new Subject<any>();

  delayTimeMinutes = 0;

  useUnuse(type) {
    return type ? '사용' : '미사용';
  }

  changeDisable() {
    this.instantData.disableArrive = !this.instantData.disableArrive;
    this.instantData.autoCancel = false;
  }
  changeAutoCancel() {
    this.instantData.autoCancel = !this.instantData.autoCancel;
  }

  updateOptions() {
    const data = { ...this.instantData };

    data.delayTime = this.delayTimeMinutes * 60;
    this.optionDataSubject.next(data);
  }

  constructor(public activeModal: NgbActiveModal) {}
}
