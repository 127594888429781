import { createAction, props } from '@ngrx/store';
import {
  ChildScreeningCsvQuery,
  ChildScreeningResQuery,
  ChildScreeningResResult,
  ExmnInfnPdfSingleDownloadParams,
} from '~models/child-screening.model';

export enum ChildActionType {
  CHILD_LIST_LOAD = '[CHILD] Infant List Load',
  CHILD_LIST_LOAD_SUCCESS = '[CHILD] Infant List Load Success',
  CHILD_LIST_LOAD_FAIL = '[CHILD] Infant List Load Fail',
  CHILD_SINGLE_PDF_DOWNLOAD = '[CHILD] Infant Single PDF Download',
  CHILD_SINGLE_PDF_DOWNLOAD_PENDING = '[CHILD] Infant Single PDF Download Pending',
  CHILD_PDF_BULK_DOWNLOAD = '[CHILD] Infant PDF Bulk Download',
  CHILD_CSV_DOWNLOAD = '[CHILD] Infant CSV Download',
}

// 영유아 검진 예약 불러오기
export const ChildInfantListLoad = createAction(
  ChildActionType.CHILD_LIST_LOAD,
  props<{ payload: { query: ChildScreeningResQuery } }>()
);

export const ChildInfantListSuccess = createAction(
  ChildActionType.CHILD_LIST_LOAD_SUCCESS,
  props<{ payload: ChildScreeningResResult }>()
);

export const ChildInfantListFail = createAction(
  ChildActionType.CHILD_LIST_LOAD_FAIL,
  props<{ payload: any }>()
);

export const ChildInfantSinglePdfDownload = createAction(
  ChildActionType.CHILD_SINGLE_PDF_DOWNLOAD,
  props<{ payload: ExmnInfnPdfSingleDownloadParams }>()
);

export const ChildInfantSinglePdfDownloadPending = createAction(
  ChildActionType.CHILD_SINGLE_PDF_DOWNLOAD_PENDING,
  props<{ payload: boolean }>()
);

export const ChildInfantPdfBulkDownload = createAction(
  ChildActionType.CHILD_PDF_BULK_DOWNLOAD,
  props<{ payload: string }>()
);

export const ChildInfantCsvDownload = createAction(
  ChildActionType.CHILD_CSV_DOWNLOAD,
  props<{ payload: ChildScreeningCsvQuery }>()
);

export const ChildActions = {
  ChildInfantListLoad,
  ChildInfantListSuccess,
  ChildInfantListFail,
  ChildInfantSinglePdfDownload,
  ChildInfantSinglePdfDownloadPending,
  ChildInfantPdfBulkDownload,
  ChildInfantCsvDownload,
};
