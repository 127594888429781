import {
  SymptomCategoryItem,
  SymptomListItem,
} from '~models/select-symptom.model';
import { SelectSymptomActions } from '~actions/products/select-symptom.actions';
import { createReducer, on } from '@ngrx/store';
import { get as _get, keyBy as _keyBy } from 'lodash';

export interface SelectSymptomState {
  loading: boolean;
  categoryData: SymptomCategoryItem[];
  symptomListData: SymptomListItem[];
  error: string | null;
}

const initialState: SelectSymptomState = {
  loading: false,
  error: null,
  categoryData: [],
  symptomListData: [],
};

export const reducer = createReducer(
  initialState,
  on(SelectSymptomActions.SelectSymptomCategoryLoad, (state, action) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SelectSymptomActions.SelectSymptomCategorySuccess, (state, action) => ({
    ...state,
    loading: false,
    error: null,
    categoryData: action.payload,
  })),
  on(SelectSymptomActions.SelectSymptomCategoryFail, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  })),
  on(SelectSymptomActions.SelectSymptomListLoad, (state, action) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(SelectSymptomActions.SelectSymptomListLoadSuccess, (state, action) => ({
    ...state,
    loading: false,
    error: null,
    symptomListData: action.payload,
  })),
  on(SelectSymptomActions.SelectSymptomListLoadFail, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
);

export const getLoading = (state: SelectSymptomState) => state.loading;
export const getCategoryData = (state: SelectSymptomState) => {
  const categoryList = state.categoryData;
  const dict = _keyBy(categoryList, 'name');
  const normalCategoryItem = _get(
    dict,
    '일반진료',
    undefined
  ) as SymptomCategoryItem;
  if (normalCategoryItem) {
    const normalFilteredCategoryItems = categoryList.filter(
      category => category.name !== '일반진료'
    );
    return [normalCategoryItem, ...normalFilteredCategoryItems];
  }
  return categoryList;
};
export const getError = (state: SelectSymptomState) => state.error;

export const getSymptomListData = (state: SelectSymptomState) =>
  state.symptomListData;
