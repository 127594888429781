import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';

import { AuthService } from '~core/api/auth.service';

import { ResetPwActions } from '~actions/auth/reset-pw.action';

import { catchError, exhaustMap, map } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class ResetPwEffects {
  constructor(private actions$: Actions, private authService: AuthService) {}

  resetPw$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResetPwActions.ResetPw),
      map(action => action.payload),
      exhaustMap(info =>
        this.authService.resetPw(info).pipe(
          map(() => ResetPwActions.ResetPwComplete()),
          catchError(error =>
            of(ResetPwActions.ResetPwFailure({ payload: error }))
          )
        )
      )
    )
  );
}
