import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { on } from '@ngrx/store';
import {
  QuestionnaireResItem,
  QuestionnaireUnitInfo,
  QuestionSubjectResItem,
} from '~models/question.model';
import { createReducer } from '@ngrx/store';
import { KioskQuestionActions } from '~actions/products/kiosk-question.actions';

interface State {
  loaded: boolean;
  unitInfo: QuestionnaireUnitInfo;
  symptoms: QuestionSubjectResItem[];
}

export type KioskQuestionState = State & EntityState<QuestionnaireResItem>;

export const kioskQuestionItemAdapter: EntityAdapter<QuestionnaireResItem> = createEntityAdapter<
  QuestionnaireResItem
>({
  selectId: questionnaireItem => questionnaireItem._id,
});

const initialState = kioskQuestionItemAdapter.getInitialState<State>({
  loaded: false,
  unitInfo: {
    title: '',
    subject: '',
    useMedicalQuestionnaire: false,
  },
  symptoms: [],
});

export const kioskQuestionReducer = createReducer(
  initialState,
  on(KioskQuestionActions.KioskQuestionRequestFail, state => {
    return { ...state, loaded: false };
  }),
  on(
    KioskQuestionActions.KioskQuestionListSuccess,
    (state, { payload: { items, ...rest } }) => {
      return kioskQuestionItemAdapter.setAll(items, {
        ...state,
        loaded: true,
        ...rest,
      });
    }
  ),
  on(
    KioskQuestionActions.KioskQuestionToggleSuccess,
    (state, { payload: { useQuestion } }) => {
      return {
        ...state,
        unitInfo: {
          ...state.unitInfo,
          useMedicalQuestionnaire: useQuestion,
        },
      };
    }
  ),
  on(
    KioskQuestionActions.KioskQuestionSymptomListSuccess,
    (state, { payload }) => {
      return {
        ...state,
        symptoms: payload,
      };
    }
  ),
  on(
    KioskQuestionActions.KioskQuestionDeleteSuccess,
    (state, { payload: { questionKey } }) => {
      return kioskQuestionItemAdapter.removeOne(questionKey, {
        ...state,
      });
    }
  ),
  on(
    KioskQuestionActions.KioskQuestionUpdateSuccess,
    (state, { payload: { questionnaireId, ...rest } }) => {
      return kioskQuestionItemAdapter.updateOne(
        {
          id: questionnaireId,
          changes: { ...rest },
        },
        { ...state }
      );
    }
  )
);
