import { Action, createAction, props } from '@ngrx/store';
import { ErrorAction } from '../common/error.actions';
import {
  DoctorInfoCreateModel,
  DoctorInfoReqModel,
  DoctorInfoUiModel,
  DoctorListPayloadModel,
} from '~models/doctor.model';

export enum DoctorInfoActionTypes {
  DOCTOR_LIST_LOAD = '[DoctorInfo] Doctor List Load',
  DOCTOR_LIST_LOAD_SUCCESS = '[DoctorInfo] Doctor List Load Succ',
  DOCTOR_INFO_LOAD = '[DoctorInfo] Doctor Info Load',
  DOCTOR_INFO_LOAD_SUCCESS = '[DoctorInfo] Doctor Info Load Succ',
  DOCTOR_DELETE = '[DoctorInfo] Doctor Delete',
  DOCTOR_UPDATE_ACTIVE = '[DoctorInfo] Doctor Update Active',
  DOCTOR_UPDATE_ACTIVE_SUCCESS = '[DoctorInfo] Doctor Update Active Succ',
  DOCTOR_UPDATE = '[DoctorInfo] Doctor Update',
  DOCTOR_UPDATE_VALIDATE_SUCCESS = '[DoctorInfo] Doctor Update Validate Succ',
  DOCTOR_CREATE = '[DoctorInfo] Doctor Create',
  DOCTOR_CREATE_VALIDATE_SUCCESS = '[DoctorInfo] Doctor Create Validate Succ',
  REQ_FAIL = '[DoctorInfo] Req Fail',
}

export class DoctorInfoReqFail extends ErrorAction {
  readonly type = DoctorInfoActionTypes.REQ_FAIL;

  constructor(error: any) {
    super(error);
  }
}

// export class DoctorListLoad implements Action {
//   readonly type = DoctorInfoActionTypes.DOCTOR_LIST_LOAD;
//
//   constructor(public skip = 0) {}
// }

export const DoctorListLoad = createAction(
  DoctorInfoActionTypes.DOCTOR_LIST_LOAD,
  props<{ skip: number }>()
);

// export class DoctorListLoadSucc implements Action {
//   readonly type = DoctorInfoActionTypes.DOCTOR_LIST_LOAD_SUCC;
//
//   constructor(public payload: DoctorListPayloadModel) {}
// }

export const DoctorListLoadSuccess = createAction(
  DoctorInfoActionTypes.DOCTOR_LIST_LOAD_SUCCESS,
  props<{ payload: DoctorListPayloadModel }>()
);

// export class DoctorInfoLoad implements Action {
//   readonly type = DoctorInfoActionTypes.DOCTOR_INFO_LOAD;
//
//   constructor(public doctorId: string) {}
// }

export const DoctorInfoLoad = createAction(
  DoctorInfoActionTypes.DOCTOR_INFO_LOAD,
  props<{ doctorId: string }>()
);

// export class DoctorInfoLoadSucc implements Action {
//   readonly type = DoctorInfoActionTypes.DOCTOR_INFO_LOAD_SUCC;
//
//   constructor(public payload: DoctorInfoUiModel) {}
// }

export const DoctorInfoLoadSuccess = createAction(
  DoctorInfoActionTypes.DOCTOR_INFO_LOAD_SUCCESS,
  props<{ payload: DoctorInfoUiModel }>()
);

// export class DoctorDelete implements Action {
//   readonly type = DoctorInfoActionTypes.DOCTOR_DELETE;
//
//   constructor(public doctorId: string) {}
// }

export const DoctorDelete = createAction(
  DoctorInfoActionTypes.DOCTOR_DELETE,
  props<{ doctorId: string }>()
);

// export class DoctorUpdateActive implements Action {
//   readonly type = DoctorInfoActionTypes.DOCTOR_UPDATE_ACTIVE;
//
//   constructor(public doctorId: string, public active: boolean) {
//   }
// }

export const DoctorUpdateActive = createAction(
  DoctorInfoActionTypes.DOCTOR_UPDATE_ACTIVE,
  props<{ doctorId: string; active: boolean }>()
);

// export class DoctorUpdateActiveSucc implements Action {
//   readonly type = DoctorInfoActionTypes.DOCTOR_UPDATE_ACTIVE_SUCC;
//
//   constructor(public doctorInfo: DoctorInfoUiModel) {
//   }
// }

export const DoctorUpdateActiveSuccess = createAction(
  DoctorInfoActionTypes.DOCTOR_UPDATE_ACTIVE_SUCCESS,
  props<{ doctorInfo: DoctorInfoUiModel }>()
);

// export class DoctorUpdate implements Action {
//   readonly type = DoctorInfoActionTypes.DOCTOR_UPDATE;
//
//   constructor(public payload: DoctorInfoUiModel) {}
// }

export const DoctorUpdate = createAction(
  DoctorInfoActionTypes.DOCTOR_UPDATE,
  props<{ payload: DoctorInfoUiModel }>()
);

// export class DoctorUpdateValidateSucc implements Action {
//   readonly type = DoctorInfoActionTypes.DOCTOR_UPDATE_VALIDATE_SUCC;
//
//   constructor(public payload: DoctorInfoReqModel) {}
// }

export const DoctorUpdateValidateSuccess = createAction(
  DoctorInfoActionTypes.DOCTOR_UPDATE_VALIDATE_SUCCESS,
  props<{ payload: DoctorInfoReqModel }>()
);

// export class DoctorCreate implements Action {
//   readonly type = DoctorInfoActionTypes.DOCTOR_CREATE;
//
//   constructor(public payload: DoctorInfoCreateModel) {}
// }

export const DoctorCreate = createAction(
  DoctorInfoActionTypes.DOCTOR_CREATE,
  props<{ payload: DoctorInfoCreateModel }>()
);

// export class DoctorCreateValidateSucc implements Action {
//   readonly type = DoctorInfoActionTypes.DOCTOR_CREATE_VALIDATE_SUCC;
//
//   constructor(public payload: DoctorInfoCreateModel) {}
// }

export const DoctorCreateValidateSuccess = createAction(
  DoctorInfoActionTypes.DOCTOR_CREATE_VALIDATE_SUCCESS,
  props<{ payload: DoctorInfoCreateModel }>()
);

// export type DoctorInfoActions =
//   | DoctorInfoReqFail
//   | DoctorListLoad
//   | DoctorListLoadSucc
//   | DoctorInfoLoad
//   | DoctorInfoLoadSucc
//   | DoctorDelete
//   | DoctorUpdateActive
//   | DoctorUpdateActiveSucc
//   | DoctorUpdate
//   | DoctorUpdateValidateSucc
//   | DoctorCreate
//   | DoctorCreateValidateSucc;

export const DoctorInfoActions = {
  DoctorListLoad,
  DoctorListLoadSuccess,
  DoctorInfoLoad,
  DoctorInfoLoadSuccess,
  DoctorDelete,
  DoctorUpdateActive,
  DoctorUpdateActiveSuccess,
  DoctorUpdate,
  DoctorUpdateValidateSuccess,
  DoctorCreate,
  DoctorCreateValidateSuccess,
};
