import {
  QuestionDetailUiModel,
  QuestionnaireCareRoomItemUiModel,
  QuestionnaireResItem,
  QuestionnaireTargetUiModel,
  QuestionnaireUiItemModel,
  QuestionnaireUnitInfo,
  QuestionSubjectUiItem,
} from '~models/question.model';
import { QuestionActions } from '~actions/products/question.actions';
import {
  createQuestionnaireUnitInfo,
  createTargetUiModel,
} from '../../services/question/question.create';
import { createReducer, on } from '@ngrx/store';

export interface QuestionState extends QuestionDetailUiModel {
  pending: boolean;
  questionnaires: QuestionnaireUiItemModel[];
  questionnaireTarget: QuestionnaireTargetUiModel;
  careRooms: QuestionnaireCareRoomItemUiModel[];
  unitInfo: QuestionnaireUnitInfo;
  subject: QuestionSubjectUiItem[];
  questionnaireList: QuestionnaireResItem[];
}

export function getInitState(): QuestionState {
  return {
    unitKey: '',
    type: '',
    useMedicalQuestionnaire: false,
    items: [],
    questionnaires: [],
    questionnaireTarget: createTargetUiModel(),
    careRooms: [],
    pending: false,
    unitInfo: createQuestionnaireUnitInfo(),
    subject: [],
    questionnaireList: [],
  };
}

export const reducer = createReducer(
  getInitState(),
  on(QuestionActions.QuestionReqFail, (state, action) => ({
    ...state,
    pending: false,
  })),
  on(QuestionActions.QuestionUseChange, (state, action) => ({ ...state })),
  on(QuestionActions.QuestionItemsModified, (state, action) => ({
    ...state,
    items: action.payload,
  })),
  on(QuestionActions.QuestionnairesLoad, (state, action) => ({
    ...state,
    ...action.payload,
    pending: true,
  })),
  on(QuestionActions.QuestionnairesLoadSuccess, (state, action) => ({
    ...state,
    questionnaires: action.payload.questionnaires,
    unitInfo: action.payload.unitInfo,
    useMedicalQuestionnaire: action.payload.unitInfo.useMedicalQuestionnaire,
    pending: false,
  })),
  on(QuestionActions.QuestionnaireTargetChange, (state, action) => ({
    ...state,
    questionnaireTarget: action.payload,
  })),
  on(QuestionActions.QuestionResetTarget, (state, action) => ({
    ...state,
    questionnaireTarget: createTargetUiModel(),
  })),
  on(QuestionActions.QuestionLoadTargetSuccess, (state, action) => ({
    ...state,
    questionnaireTarget: action.payload,
  })),
  on(QuestionActions.QuestionnaireQuestionLoadSuccess, (state, action) => ({
    ...state,
    items: action.payload.items,
  })),
  on(QuestionActions.QuestionCareRoomLoadSuccess, (state, action) => ({
    ...state,
    careRooms: action.payload,
  })),
  on(QuestionActions.FetchQuestionnairesSuccess, (state, action) => ({
    ...state,
    questionnaireList: action.payload,
  })),
  on(QuestionActions.FetchCareRoomSubjectSuccess, (state, action) => ({
    ...state,
    subject: action.payload,
  })),
  on(QuestionActions.QuestionnaireOverWrite, (state, action) => ({
    ...state,
    items: action.payload,
  }))
);
