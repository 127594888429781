import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
} from '@angular/core';
import { Observable } from 'rxjs/';
import {
  handlerOnPrev,
  handlerOnNext,
  handlerBtn,
  calcMaxPage,
  createPages,
} from './pagination.service';

export interface PaginationEventArgs {
  skip: number;
  page: number;
  maxPage: number;
  limit?: number;
  disabledPrev: boolean;
  disabledNext: boolean;
}

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() asyncParam?: boolean;

  private _page = 1;
  @Input() set page(val: number) {
    this._page = val;
  }
  get page(): number {
    return this._page;
  }

  private _limit = 10;
  @Input() set limit(val: number) {
    this._limit = val;
  }
  get limit(): number {
    return this._limit;
  }

  private _navCount = 5;
  @Input() set navCount(val: number) {
    this._navCount = val;
  }
  get navCount(): number {
    return this._navCount;
  }

  private _totalCount = 1;
  @Input() set totalCount(val: number) {
    this._totalCount = val;
  }
  get totalCount(): number {
    return this._totalCount;
  }

  private _prevDisable: boolean;
  get prevDisable(): boolean {
    return this._prevDisable;
  }

  private _nextDisable: boolean;
  get nextDisable(): boolean {
    return this._nextDisable;
  }

  private _theme: string;
  @Input() set theme(val: string) {
    this._theme = val;
  }
  get theme(): string {
    return this._theme;
  }

  @Input() placement = 'left';

  private maxPage = 1;
  private skip = 0;

  get themeClass() {
    const theme = this._theme;

    if (theme) {
      return theme + ' p-' + this.placement;
    }

    return 'p-' + this.placement;
  }

  pages: number[] = [];

  @Output() paginationChange = new EventEmitter<PaginationEventArgs>();

  ngOnInit(): void {
    this.makeNav();

    if (!!this.asyncParam) {
      const info = handlerBtn(this.limit, this.maxPage, this._page);
      this.setState(info);
    }
  }

  ngOnChanges() {
    this.makeNav();
  }

  makeNav() {
    const { skip, totalCount, limit, navCount } = this;
    const maxPage = calcMaxPage(totalCount, limit);
    const page = Math.floor(skip / limit) + 1;
    // const iPrevPage = ((page - 1) < 1) ? 1 : (page - 1);
    // const iNextPage = ((page + 1) >= maxPage) ? maxPage : (page + 1);
    // // const iNextPage = (((page) + navCount) > maxPage) ? maxPage : ((page) + navCount);
    // // const iPrevSkip = ((iPrevPage - 1) * limit);
    // // const iNextSkip = ((iNextPage - 1) * limit);
    const pageStructure = createPages({
      page,
      navCount,
      maxPage,
    });

    this.pages = pageStructure;
    this.maxPage = maxPage;
  }

  setState(info: PaginationEventArgs) {
    this._page = info.page;
    this._prevDisable = info.disabledPrev;
    this._nextDisable = info.disabledNext;
    this.skip = Math.floor((info.page - 1) * this.limit);
    // this.makeNav();
  }

  handlerOnPrevNext(isPrev: boolean) {
    const handler = isPrev ? handlerOnPrev : handlerOnNext;
    const { limit, maxPage, page } = this;
    const info = handler(limit, maxPage, page);
    const PAGE = page;

    if (info.page === PAGE) {
      return;
    }

    this.paginationChange.emit({
      ...info,
      limit,
      maxPage,
    });

    this.setState(info);
  }

  prev() {
    this.handlerOnPrevNext(true);
  }
  next() {
    this.handlerOnPrevNext(false);
  }

  onPageClick(num: number) {
    if (this.page === num) {
      return;
    }
    this._page = num;
    this.skip = Math.floor((num - 1) * this.limit);

    const info = handlerBtn(this.limit, this.maxPage, this._page);

    this.paginationChange.emit({
      ...info,
      page: num,
      skip: this.skip,
      maxPage: this.maxPage,
      limit: this.limit,
    });
    this.setState(info);
  }
}
