import { basicSideMenu, SideMenuItem } from '~models/layout.model';
import { isNil as _isNil, tail as _tail } from 'lodash';

const mMenu: { [path: string]: SideMenuItem } = basicSideMenu.reduce(
  (prevSideMenu, currSideMenu) => {
    const sideMenuItems = currSideMenu.menuItems;
    if (!_isNil(sideMenuItems)) {
      const reducedSideMenuItems = sideMenuItems.reduce(
        (prevSideMenuItem, currSideMenuItem) => {
          return {
            ...prevSideMenuItem,
            [currSideMenuItem.link]: {
              ...currSideMenuItem,
              parentTitle: currSideMenu.typeName,
              noChild: currSideMenu.noChild,
            },
          };
        },
        {}
      );
      return {
        ...prevSideMenu,
        ...reducedSideMenuItems,
      };
    }
    return {};
  },
  {}
);

function getPathKey(path: string) {
  if (!path) {
    return '';
  }

  const aPath = path.split('/');

  return `/${_tail(aPath)
    .slice(0, 3)
    .join('/')}`;
}

export function getPageInfo(path: string) {
  return mMenu[getPathKey(path)];
}
