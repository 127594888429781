import { ResScheduleActions } from '~actions/products/res-schedule.actions';
import { HashMap } from '~models/common.model';
import { OfficeWeeklyDetailData } from '~models/care-room.model';
import { createReducer, on } from '@ngrx/store';

export interface ResScheduleState {
  loading: boolean;
  message: string;
  schedules: HashMap<OfficeWeeklyDetailData>;
}

const initialState: ResScheduleState = {
  loading: false,
  message: '',
  schedules: {},
};

export const reducer = createReducer(
  initialState,
  on(
    ResScheduleActions.ResScheduleLoad,
    ResScheduleActions.ResScheduleRead,
    ResScheduleActions.ResScheduleUpdate,
    (state, action) => ({
      ...state,
      loading: true,
      message: '',
    })
  ),
  on(
    ResScheduleActions.ResScheduleLoadFail,
    ResScheduleActions.ResScheduleReadFail,
    ResScheduleActions.ResScheduleUpdateFail,
    (state, action) => ({
      ...state,
      loading: false,
      message: action.message,
    })
  ),
  on(
    ResScheduleActions.ResScheduleLoadSuccess,
    ResScheduleActions.ResScheduleReadSuccess,
    ResScheduleActions.ResScheduleBatchApply,
    ResScheduleActions.ResScheduleEdit,
    (state, action) => ({
      ...state,
      schedules: action.payload,
      loading: false,
      message: '',
    })
  ),
  on(ResScheduleActions.ResScheduleUpdateSuccess, (state, action) => ({
    ...state,
    loading: false,
    message: '',
  }))
);
