<div class="wrap">
  <button type="button" class="ha-btn spinner-btn" (click)="onInc($event)">
    <i class="ha-icon-angle-up"></i>
  </button>
  <input
    type="text"
    class="input-number"
    [value]="val"
    [disabled]="disabled"
    (change)="$event.stopPropagation()"
    (blur)="onBlur($event)"
    (click)="onClick($event)"
  />
  <button type="button" class="ha-btn spinner-btn" (click)="onDec($event)">
    <i class="ha-icon-angle-down"></i>
  </button>
</div>
