import { isEmpty as _isEmpty } from 'lodash';

import { environment } from '~root/environments/environment';
import { Pagination } from '~models/common.model';
import { AccountInfo } from '~models/auth.model';

/**
 * pagination 객체로 변환
 * @param {number} totalCount
 * @param {number} skip
 * @param {number} limit
 * @returns {Pagination}
 */
export const toPagination = ({
  totalCount = 0,
  skip = 0,
  limit = 1,
}): Pagination => ({
  page: Math.floor(skip / limit) + 1,
  skip,
  limit,
  totalCount,
});

const phoneRegExp = new RegExp(/(\d{3})(\d{4})(\d{4})/);
export const phoneRegExpWithDash = new RegExp(/^(\d{2,3}-|)\d{3,4}-\d{4}$/);
const telRegExp = new RegExp(/(\d{3})(\d{3})(\d{4})/);
const seoulTelRegExp = new RegExp(/(\d{2})(\d{3})(\d{4})/);
const digit8TelRegExp = new RegExp(/(\d{4})(\d{4})/);

/**
 * phone 포맷으로 변환
 * @param {string} phone
 * @returns {string}
 */
export const toPhoneFormat = (phone: string = '', isOnlyNumber = false) => {
  const parsedPhoneNumber = phone.replace(/[^0-9]/gi, '');

  if (isOnlyNumber) {
    return parsedPhoneNumber;
  }

  if (parsedPhoneNumber.length === 11) {
    return parsedPhoneNumber.replace(phoneRegExp, '$1-$2-$3');
  }
  if (parsedPhoneNumber.length === 8) {
    return parsedPhoneNumber.replace(digit8TelRegExp, '$1-$2');
  }
  if (parsedPhoneNumber.indexOf('02') === 0) {
    return parsedPhoneNumber.replace(seoulTelRegExp, '$1-$2-$3');
  }
  return parsedPhoneNumber.replace(telRegExp, '$1-$2-$3');
};

export const parseInputValue = (event: any) => {
  if (event.target) {
    if (event.target.value) {
      return event.target.value;
    }
    if (event.target.checked) {
      return event.target.checked;
    }
  }
  return event;
};

/*
 * 똑닥 에이전트 Version 체크.
 * 5.5.0.1(21년 10월 기준) 이상이면 true, 미만이면 false return.
 *
 * @param {string} version
 * @returns {boolean}
 * */
export const checkVersionHigher = (version: string) => {
  const result = version.localeCompare(
    environment.ddocdoc_agent_version,
    undefined,
    {
      numeric: true,
      sensitivity: 'base',
    }
  );
  return result >= 0;
};

/**
 * 병원 지역명 반환
 *
 * @desc 채널톡 병원명 표기에 사용
 * @desc 서울특별시만 "구" 반환, 그 외 "시" or "군" 반환
 * @example "서울특별시 영등포구" -> "영등포", "경기도 수원시" -> "수원", "전라북도 완주군" -> "완주"
 * @param {Hospital?.Region} region
 */
export const getHospitalRegionName = (
  region?: AccountInfo['hospital']['region']
): string => {
  if (_isEmpty(region)) {
    return '';
  }

  const [region2Depth] = region?.region2Depth.split(' ') ?? '';

  if (region?.region1Depth === '서울특별시') {
    return region2Depth.replace('구', '') ?? '';
  } else if (/(광역시|특별자치시)$/.test(region?.region1Depth)) {
    return region?.region1Depth.replace(/(광역시|특별자치시)$/, '') ?? '';
  } else {
    return region2Depth.replace(/([시군])$/, '') ?? '';
  }
};
