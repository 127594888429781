import { createSelector } from '@ngrx/store';
import {
  CareRoom,
  CareRoomScheduleData,
  CareRoomScheduleForHome,
  CareRoomScheduleItem,
  OfficeWeeklyDetailData,
} from '~models/care-room.model';
import { DropdownModel } from '~models/common';
import { HashMap } from '~models/common.model';
import { OperatingInnerTime, OperatingTimeModel } from '~models/hospital.model';
import {
  getAllEntityCareRoom,
  ProductState,
  selectProductState,
} from '~reducers/products';
import { HomeState } from '~reducers/products/home.reducer';

const getHomeState = createSelector(
  selectProductState,
  (state: ProductState) => {
    return state.homeState;
  }
);

export const getBanners = createSelector(
  getHomeState,
  (state: HomeState) => state.banners
);

const SCHEDULE_DATA_NAME_MAP: { [key: string]: string } = {
  monday: '월요일',
  tuesday: '화요일',
  wednesday: '수요일',
  thursday: '목요일',
  friday: '금요일',
  saturday: '토요일',
  sunday: '일요일',
  holiday: '공휴일',
  lunch: '점심',
  dinner: '저녁',
};

function getScheduleDataKey(type: string): string[] {
  if (type === 'normal') {
    return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];
  }
  if (type === 'holiday') {
    return ['saturday', 'sunday', 'holiday'];
  }
  return ['lunch', 'dinner'];
}

function timeToDate(time: string): Date | null {
  if (!time) {
    return null;
  }
  return new Date('1970-01-01T' + time);
}

function parseScheduleDataForOT(
  args: { [key: string]: OperatingInnerTime },
  type: string
): CareRoomScheduleItem[] {
  return getScheduleDataKey(type).map(key => {
    return {
      code: key,
      enabled: true,
      name: SCHEDULE_DATA_NAME_MAP[key],
      startTime: timeToDate(args[key].startTime),
      endTime: timeToDate(args[key].endTime),
    } as CareRoomScheduleItem;
  });
}

function operatingTimeToScheduleData(
  args: OperatingTimeModel
): CareRoomScheduleData {
  return {
    unitKey: 'ot',
    normal: parseScheduleDataForOT(args.schedule, 'normal'),
    holiday: parseScheduleDataForOT(args.schedule, 'holiday'),
    mealTime: parseScheduleDataForOT(args.schedule, 'mealTime'),
  };
}

export const getOperatingTimeForHome = createSelector(
  selectProductState,
  (state: ProductState): CareRoomScheduleForHome => {
    const data: CareRoomScheduleData = state.operatingTime.data
      ? operatingTimeToScheduleData(state.operatingTime.data)
      : {
          unitKey: '',
          normal: [],
          holiday: [],
          mealTime: [],
        };

    return {
      use: true,
      selectItems: [],
      schedules: { ot: data },
    };
  }
);

function parseMealTimeDataForOW(
  args: { [key: string]: OfficeWeeklyDetailData },
  type: keyof OfficeWeeklyDetailData
): CareRoomScheduleItem {
  const weekName = 'monday';
  const item = args[weekName][type] as OfficeWeeklyDetailData;
  let enabled = true;

  if (type === 'lunch') {
    enabled = item.hasLunchBreak;
  } else if (type === 'dinner') {
    enabled = item.hasDinnerBreak;
  }

  return {
    code: type,
    enabled,
    name: SCHEDULE_DATA_NAME_MAP[type],
    startTime: timeToDate(item.startTime),
    endTime: timeToDate(item.endTime),
  } as CareRoomScheduleItem;
}

function parseScheduleDataForOW(
  args: { [key: string]: OfficeWeeklyDetailData },
  type: string
): CareRoomScheduleItem[] {
  if (type === 'mealTime') {
    return [
      parseMealTimeDataForOW(args, 'lunch'),
      parseMealTimeDataForOW(args, 'dinner'),
    ];
  }
  return getScheduleDataKey(type).map(key => {
    return {
      code: key,
      enabled: !args[key].isDayOff,
      name: SCHEDULE_DATA_NAME_MAP[key],
      startTime: timeToDate(args[key].startTime),
      endTime: timeToDate(args[key].endTime),
    } as CareRoomScheduleItem;
  });
}

function careRoomToScheduleData(args: CareRoom): CareRoomScheduleData {
  const mData = {
    ...args.weeklyReceiptSchedule,
  };
  return {
    unitKey: args.key,
    normal: parseScheduleDataForOW(mData, 'normal'),
    holiday: parseScheduleDataForOW(mData, 'holiday'),
    mealTime: parseScheduleDataForOW(mData, 'mealTime'),
  };
}

export const getCareRoomScheduleForHome = createSelector(
  getAllEntityCareRoom,
  (rooms: CareRoom[]): CareRoomScheduleForHome => {
    const selectItems: DropdownModel[] = [];
    const schedules: HashMap<CareRoomScheduleData> = {};

    rooms.forEach(item => {
      selectItems.push({
        text: item.title,
        value: item.key,
      });
      if (item.useReceipt) {
        schedules[item.key] = careRoomToScheduleData(item);
      }
    });

    try {
    } catch (error) {}

    return {
      use: true,
      selectItems,
      schedules,
    };
  }
);

export const getAccountInfo = createSelector(
  getHomeState,
  (state: HomeState) => state.accountInfo
);

export const getUnsetServiceType = createSelector(
  getHomeState,
  state => state.unsetService
);

export const getUseGlobalReceipt = createSelector(
  getAccountInfo,
  ({ hospital }) => {
    const serviceTypeSet = new Set(hospital.serviceType);
    return hospital && serviceTypeSet.has('RECEIPT');
  }
);

export const getUseGlobalReservation = createSelector(
  getAccountInfo,
  ({ hospital }) => {
    const serviceTypeSet = new Set(hospital.serviceType);
    return hospital && serviceTypeSet.has('RESERVATION');
  }
);

export const getUseGlobalWaitings = createSelector(getAccountInfo, info => {
  return info.hospital
    ? info.hospital.serviceType.indexOf('WAITINGS') >= 0
    : false;
});

export const getUseEmergency = createSelector(getAccountInfo, info => {
  return info.hospital ? info.hospital.useEmergency : false;
});

export const getUserAgentType = createSelector(
  getAccountInfo,
  info => info.agentType
);
