import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BannerFaqJson, Category, Faq } from '~models/faq.model';
import { AuthService } from './auth.service';
import { environment } from '~root/environments/environment';
import { HttpRes } from '~models/common.model';

const FAQ_HOST = environment.faq_url;
const API = {
  categories: `${FAQ_HOST}/categories`,
  faqs: `${FAQ_HOST}/faqs`,
  bannerFaqs: `/assets/faq/banner.json`,
};

@Injectable()
export class FaqService {
  constructor(private http: HttpClient, private auth: AuthService) {}

  fetchCategories(): Observable<Category[]> {
    return this.http.get<Category[]>(API.categories);
  }

  /**
   * @todo 나중에 qs없이 params로 처리 할 수 없을 경우 주석 삭제
   * @param queryString qs 로 파싱한 queryString
   */
  fetchFaqs(queryString = ''): Observable<HttpRes<Faq>> {
    return this.http.get<HttpRes<Faq>>(
      `${API.faqs}${queryString ? `?${queryString}` : ''}`,
      {
        // params: {
        //    ['emrTypes.type']: agentType,
        //   ...(category && { category }),
        //   _where,
        //   _limit: String(limit),
        //   _start: String(skip)
        // }
      }
    );
  }

  fetchBannerFaqs(): Observable<BannerFaqJson> {
    return this.http.get(API.bannerFaqs) as Observable<BannerFaqJson>;
  }
}
