import * as moment from 'moment';
import { Moment } from 'moment';

export function dateToString(
  date: Date | Moment,
  separator: string = '-'
): string {
  if (!date) {
    return '';
  }
  return moment(date).format(`YYYY${separator}MM${separator}DD`);
}
