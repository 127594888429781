import {
  PdfArchivesListParams,
  PdfArchivesResItem,
} from '~models/pdf-archives.model';
import { PdfArchivesActions } from '~actions/products/pdf-archives.actions';
import { createReducer, on } from '@ngrx/store';

export interface PdfArchivesState {
  params: PdfArchivesListParams;
  items: PdfArchivesResItem[];
  totalCount: number;
  pending: boolean;
  downloadPending: boolean;
}

function getInitState(): PdfArchivesState {
  return {
    params: {
      type: 'EXAMINATION_INFANT',
      skip: 0,
      limit: 5,
    },
    items: [],
    totalCount: 0,
    pending: false,
    downloadPending: false,
  };
}

const initState = getInitState();

export const reducer = createReducer(
  initState,
  on(PdfArchivesActions.PdfArchivesListLoad, (state, action) => ({
    ...state,
    params: { ...action.payload },
  })),
  on(PdfArchivesActions.PdfArchivesListSuccess, (state, action) => ({
    ...state,
    items: action.payload.items,
    totalCount: action.payload.totalCount,
    pending: false,
    downloadPending: false,
  })),
  on(PdfArchivesActions.PdfArchivesDownload, (state, action) => ({
    ...state,
    downloadPending: true,
  })),
  on(
    PdfArchivesActions.PdfArchivesPendingStart,
    PdfArchivesActions.PdfArchivesPendingEnd,
    (state, action) => ({
      ...state,
      pending: action.payload,
      downloadPending: action.payload,
    })
  )
);
