import { PeaktimeLimitActions } from '~actions/products/peaktime-limit.actions';
import {
  PeaktimeLimitResItem,
  UnitPeaktimeLimitResItem,
} from '~models/hospital.model';
import { errorMessage } from '~shared/service/util';
import { createReducer, on } from '@ngrx/store';

export interface PeaktimeLimitState {
  loading: boolean;
  loaded: boolean;
  error: string | null;
  peaktimeData?: PeaktimeLimitResItem[];
  UnitPeaktime?: UnitPeaktimeLimitResItem;
  // unitData?: UnitPeaktimeLimitResItem;
}

const initialState: PeaktimeLimitState = {
  loaded: false,
  loading: false,
  error: null,
  // unitData: null
};

export const reducer = createReducer(
  initialState,
  on(PeaktimeLimitActions.PeaktimeLimitLoad, (state, action) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(PeaktimeLimitActions.PeaktimeLimitLoadSuccess, (state, action) => ({
    loading: false,
    loaded: true,
    error: null,
    peaktimeData: action.payload,
  })),
  on(PeaktimeLimitActions.PeaktimeLimitLoadFail, (state, action) => ({
    ...state,
    loading: false,
    error: errorMessage(action.payload, 'peak.red45'),
    loaded: false,
  })),
  on(PeaktimeLimitActions.UnitPeaktimeLimitLoad, (state, action) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(PeaktimeLimitActions.UnitPeaktimeLimitLoadSuccess, (state, action) => {
    return {
      loading: false,
      loaded: true,
      error: null,
      UnitPeaktime: action.payload,
    };
  }),
  on(PeaktimeLimitActions.UnitPeaktimeLimitLoadFail, (state, action) => ({
    ...state,
    loading: false,
    error: errorMessage(action.payload, 'peak.red45'),
    loaded: false,
  })),
  on(PeaktimeLimitActions.PeaktimeLimitUpdate, (state, action) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(PeaktimeLimitActions.PeaktimeLimitUpdateSuccess, (state, action) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(PeaktimeLimitActions.PeaktimeLimitUpdateFail, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  })),
  on(PeaktimeLimitActions.UnitPeaktimeLimitUpdate, (state, action) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(PeaktimeLimitActions.UnitPeaktimeLimitUpdateSuccess, (state, action) => ({
    ...state,
    loading: false,
    error: null,
  })),
  on(PeaktimeLimitActions.UnitPeaktimeLimitUpdateFail, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  })),
  on(PeaktimeLimitActions.UnitPeaktimeLimitSave, (state, action) => ({
    ...state,
    loading: false,
    error: null,
  }))
);

export const getLoaded = (state: PeaktimeLimitState) => state.loaded;
export const getPeaktime = (state: PeaktimeLimitState) => state.peaktimeData;
export const getUnitPeaktime = (state: PeaktimeLimitState) =>
  state.UnitPeaktime;
export const getError = (state: PeaktimeLimitState) => state.error;
export const getLoading = (state: PeaktimeLimitState) => state.loading;
