/* tslint:disable:no-bitwise */
import { OfficeWeeklyDetailData } from '~models/care-room.model';
import { HashMap } from '~models/common.model';
import {
  Holiday,
  HourItem,
  HourMinRange,
  HourRange,
  Schedule,
  SymptomItem,
  TimeCell,
  TimelinePreventReason,
  YearMonth,
} from '~models/reservation-timeline.model';
import { SymptomCategoryItem } from '~models/select-symptom.model';
import { makeNumberArray } from '~shared/service/util';
import { ResTimelineScheduleState } from '~reducers/products/res-timeline-schedule.reducer';

export const DEFAULT_TIME_UNIT = 5;

const NO_WEEKS = ['dinner', 'lunch'];

export const WEEK_NAME_LIST = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

function getPrevMonthLastDay(year: number, month: number): number {
  const datePrevMonth = new Date(year, month - 1, 0);

  return datePrevMonth.getDate();
}

/**
 * 연월 정보를 받아서 주(week)별로 스케줄 표에 쓰이는 일자 매트릭스 자료를 만든다.
 * @param year 연
 * @param month 월
 */
export function makeDayMatrix(year: number, month: number): number[][] {
  const dateFirst = new Date(year, month - 1, 1);
  const startWeekday = dateFirst.getDay();
  const dateLast = new Date(year, month, 0);
  const endDay = dateLast.getDate();
  const aWeeks = getWeekArray();
  const idxWeekday = aWeeks.indexOf(startWeekday);
  const iPrevMonthLastDay = getPrevMonthLastDay(year, month);
  const iPrevMonthDate = iPrevMonthLastDay - startWeekday + 1;
  const aRet = [];
  let aSub: number[];
  let iDay = 0;
  let iNextMonthDate = 1;

  for (let row = 0; row < 6; row++) {
    aSub = [];

    for (let col = 0; col < 7; col++) {
      if (row === 0 && col < idxWeekday) {
        aSub.push(-1 * (iPrevMonthDate + col));
      } else if (iDay >= endDay) {
        if (col === 0) {
          return aRet;
        }
        aSub.push(-1 * iNextMonthDate++);
      } else {
        aSub.push(++iDay);
      }
    }

    aRet.push(aSub);
  }

  return aRet;
}

export function getWeekArray() {
  return [0, 1, 2, 3, 4, 5, 6];
}

const filterInnerTime = (mSchedule: {
  [key: string]: OfficeWeeklyDetailData;
}) => {
  let start = '24:00';
  let end = '00:00';
  let weekdays = getDefWeekdays();

  return {
    do(week: string) {
      const innerTime = mSchedule[week];
      // const {
      //   startTime,
      //   endTime,
      // } = innerTime;

      if (!innerTime.isDayOff) {
        if (start > innerTime.startTime) {
          start = innerTime.startTime;
        }
        if (end < innerTime.endTime) {
          end = innerTime.endTime;
        }

        weekdays = applyToWeekdays(weekdays, week, innerTime);
      }
    },
    getResult(): HourRange {
      return {
        start,
        end,
        weekdays,
      };
    },
  };
};

function toHourRange(data: {
  [key: string]: OfficeWeeklyDetailData;
}): HourRange {
  const weeks = Object.keys(data).filter(key => NO_WEEKS.indexOf(key) < 0);

  const process = filterInnerTime(data);

  weeks.forEach(process.do);

  // 시간 간격을 임의로 조정하여 테스트 시 사용
  // return {
  //   start: '12:00',
  //   end: '24:00',
  // };

  return process.getResult();
}

export function toHourRangeForReserveTime(data: {
  [key: string]: OfficeWeeklyDetailData;
}): HourRange {
  const hourRange = toHourRange(data);
  const { start, end } = hourRange;
  const aStart = start.split(':');
  const aEnd = end.split(':');

  hourRange.start = aStart + ':00';
  hourRange.end = aEnd + ':00';
  // hourRange.start = aStart + ':10';
  // hourRange.end = aEnd + ':40';

  return hourRange;
}

export function toOpenTimeDataMap(data: HashMap<OfficeWeeklyDetailData>) {
  return { ...data };
}

export function createHourItems(hourRange: HourRange): HourItem[] {
  if (!hourRange) {
    return [];
  }
  const aStart = hourRange.start.split(':');
  const aEnd = hourRange.end.split(':');
  const iStartHour = parseInt(aStart[0], 10);
  const iEndHour = parseInt(aEnd[0], 10);
  const aNum = makeNumberArray(iStartHour, iEndHour);
  const aRet = aNum.map(num => hourToAmPmHour(num));

  return aRet;
}

export function hourToAmPmHour(num: number): HourItem {
  // const AM = 'AM';
  // const PM = 'PM';
  let sHour = '';
  let isPm = false;

  sHour = num + '시';

  if (num > 0 && num < 10) {
    sHour = `0${num}시`;
  } else if (num === 12) {
    isPm = true;
  } else if (num > 12 && num < 24) {
    isPm = true;
  } else if (num < 24) {
  } else {
    sHour = '';
  }

  return {
    hour: sHour,
    isPm,
    raw: num,
  };
}

export function getDefWeekdays() {
  return [false, false, false, false, false, false, false];
}

function applyToWeekdays(
  weekdays: boolean[],
  weekName: string,
  info: OfficeWeeklyDetailData
): boolean[] {
  const index = WEEK_NAME_LIST.indexOf(weekName);

  weekdays[index] = !!(info && info.startTime && info.endTime);

  return weekdays;
}

// export function toScheduleHourRanges(
//   schedule: { [key: string]: OperatingInnerTime }
// ): HourRange[] {
//   const mResult = reduceToHourRange(schedule) as HourRange;
//   const aRet = [];

//   for (let i = 0; i < 7; i++) {
//     aRet.push(mResult);
//   }

//   return aRet;
// }

export function formatHourMinutes(hour: number, min: number): string {
  const sHour = hour < 10 ? '0' + hour : hour;
  const sMin = min < 10 ? '0' + min : min;

  return sHour + ':' + sMin;
}

export function toHolidayMap(holidays: Holiday[]) {
  const mRet: { [day: string]: Holiday } = {};

  if (!holidays) {
    return mRet;
  }

  const regexYearMonth = /^\d{4}\-\d{2}\-0?/;

  holidays.forEach(item => {
    let sDate = item.date;

    if (!sDate) {
      return;
    }

    sDate = sDate.replace(regexYearMonth, '');

    mRet[sDate] = item;
  });

  return mRet;
}

export function hourRangeToTimeRange(hourRange: HourRange): HourMinRange {
  const aStart = hourRange.start.split(':');
  const aEnd = hourRange.end.split(':');
  const iStartHour = parseInt(aStart[0], 10);
  const iStartMin = parseInt(aStart[1], 10);
  const iEndHour = parseInt(aEnd[0], 10);
  const iEndMin = parseInt(aEnd[1], 10);

  return {
    startHour: iStartHour,
    startMin: iStartMin,
    endHour: iEndHour,
    endMin: iEndMin,
  };
}

export const ALL_SYMPTOM_AS_IS = '모두 가능';
export const ALL_SYMPTOM_TO_BE = '모든진료항목';

const mapForCombineToSymptomItem = (
  symptom: SymptomCategoryItem
): SymptomItem => {
  let name = symptom.name;

  if (symptom.name === ALL_SYMPTOM_AS_IS) {
    name = ALL_SYMPTOM_TO_BE;
  }
  return {
    _id: symptom._id,
    name,
  } as SymptomItem;
};

const sortForCombineToSymptomItem = (a: SymptomItem, b: SymptomItem) => {
  if (a.name === ALL_SYMPTOM_TO_BE) {
    return -1;
  }

  if (a.name > b.name) {
    return 1;
  }
  if (a.name < b.name) {
    return -1;
  }
  return 0;
};

/**
 * 증상선택 카테고리 id 들과 사용 가능한 증상선택 자료 정보를 바탕으로
 * 실제 사용되는 증상선택 id와 name 으로 이뤄진 객체 목록을 조합한다.
 * @param category 실제 사용되는 증상선택 카테고리 id 목록
 * @param symptoms 사용 가능한 증상선택 자료 목록
 */
export function combineToSymptomItem(
  category: string[],
  symptoms: SymptomCategoryItem[]
): SymptomItem[] {
  if (!category || category.length === 0) {
    throw new Error();
  }

  try {
    const aRet = symptoms
      .filter(symptom => category.indexOf(symptom._id) >= 0)
      .map(mapForCombineToSymptomItem)
      .sort(sortForCombineToSymptomItem);

    return aRet;
  } catch (error) {
    throw new Error();
  }
}

export function makeMonthKey(year: number, month: number) {
  const sMonth = month < 10 ? `0${month}` : `${month}`;
  return `${year}-${sMonth}`;
}

export function yearMonthToMonthKey(yearMonth: YearMonth) {
  return makeMonthKey(yearMonth.year, yearMonth.month);
}

// export function getCurrYearMonth() {
//   const now = new Date();
//   const year = now.getFullYear();
//   const month = now.getMonth() + 1;
//   const fmt = makeMonthKey(year, month);

//   return {
//     year,
//     month,
//     fmt,
//   };
// }

/**
 * 현재 날짜 기준, 주차 값을 구한다.
 */
export function getCurrWeek() {
  return _getWeekNumber(new Date());
}

/**
 * 특정 날짜 기준, 주차 값을 구한다,
 * @param year 연
 * @param month 월
 * @param day 일
 */
export function getWeekNumber(year: number, month: number, day: number = 1) {
  return _getWeekNumber(new Date(year, month - 1, day));
}

/**
 * 특정 날짜를 넣고, 이 날짜가 현재 년월에 해당된다면
 * 현재 날짜를 이용하여 주차 값을 내보낸다.
 * 그렇지 않다면 항상 1을 내보낸다.
 * @param year 연
 * @param month 월
 */
export function calcWeekNumberForNow(year: number, month: number) {
  const now = new Date();

  if (now.getFullYear() === year && now.getMonth() === month - 1) {
    return _getWeekNumber(now);
  }

  return 1;
}

/**
 * 제시된 날짜가 해당 월의 몇번째 주간인지 계산한다.
 */
function _getWeekNumber(date: Date) {
  const singleDate = date.getDate();
  const matrix = makeDayMatrix(date.getFullYear(), date.getMonth() + 1);
  let iRet = 1;

  matrix.every((weekNums, idx) => {
    const last = weekNums[6];

    if (singleDate > last) {
      return true;
    }

    iRet = idx + 1;

    return false;
  });

  return iRet;
  // const day = date.getDate();
  // const weekIdx = date.getDay();
  // const amount = day < weekIdx ? 1 : 0;

  // return Math.floor((day + (6 - weekIdx)) / 7) + amount;
}

export function findHourItemIndex(hourItems: HourItem[], hour: number) {
  const rawHours = hourItems.map(({ raw = 0 }) => raw);
  return rawHours.indexOf(hour);
}

export function getRowRange(
  hourItems: HourItem[],
  startHour = 0,
  endHour = 0
): [number, number] {
  const raws = hourItems.map(({ raw }) => raw);
  const start = findHourItemIndex(hourItems, startHour);
  const end = findHourItemIndex(hourItems, endHour);
  return [
    start < 0 ? Math.max(...raws) : start,
    end < 0 ? Math.min(...raws) : end,
  ];
}

export function pad(num: number | string): string {
  const s = num + '';

  if (s.length === 1) {
    return '0' + s;
  }

  return s;
}

export function hasHoliday(
  state: ResTimelineScheduleState,
  weekdays: number[][]
) {
  const { holidayMap } = state;

  if (!holidayMap || !weekdays) {
    return false;
  }

  const iRowLen = weekdays.length;
  let day = 0;

  for (let iRow = 0; iRow < iRowLen; iRow++) {
    for (let iCol = 0; iCol < 7; iCol++) {
      day = weekdays[iRow][iCol];

      if (!!holidayMap[day]) {
        return true;
      }
    }
  }

  return false;
}

export function hasPreventCellWithStartEnd(
  start: number,
  end: number,
  linearCells: TimeCell[],
  allowHoliday: boolean = false
) {
  const cellFirst = linearCells[start];
  const cellLast = linearCells[end];
  const DAYOFF = TimelinePreventReason.DAYOFF;
  const HOLIDAY = TimelinePreventReason.HOLIDAY;

  try {
    // tslint:disable-next-line: no-bitwise
    let reasonFirst = (cellFirst.reason | DAYOFF) ^ DAYOFF;
    // tslint:disable-next-line: no-bitwise
    let reasonLast = (cellLast.reason | DAYOFF) ^ DAYOFF;

    if (allowHoliday) {
      // tslint:disable-next-line: no-bitwise
      reasonFirst = (reasonFirst | HOLIDAY) ^ HOLIDAY;
      // tslint:disable-next-line: no-bitwise
      reasonLast = (reasonLast | HOLIDAY) ^ HOLIDAY;
    }

    return reasonFirst > 0 || reasonLast > 0;
  } catch (error) {
    return false;
  }
}

export function findWeekName(year: number, month: number, day: number) {
  const date = new Date(year, month - 1, day);
  return WEEK_NAME_LIST[date.getDay()];
}

export function chooseRightWeekNumber(
  targetWeek: number,
  defWeek: number = 1,
  maxWeek: number = 5
): number {
  if (targetWeek > 0) {
    return targetWeek;
  }

  if (defWeek > maxWeek) {
    return maxWeek;
  }

  return defWeek;
}

export const schedulesToMap = (schedules: HashMap<Schedule> | Schedule[]) => {
  if (Array.isArray(schedules)) {
    const markedMap: HashMap<boolean> = {};

    schedules.forEach(schedule => {
      markedMap[schedule.day] = schedule.enableTime.length > 0;
    });

    return markedMap;
  }
  return Object.keys(schedules).reduce((acc, key) => {
    try {
      acc[key] = schedules[key].enableTime.length > 0;
    } catch (error) {
      //
    }

    return acc;
  }, {} as HashMap<boolean>);
};

// export function tableToSchedules(table: TimelineInfo[][]) {
//   const schedules: Schedule[] = [];

//   table.forEach(info => {
//     info.forEach(data => {
//       data.
//     })
//   })
// }
