import { createAction, props } from '@ngrx/store';
import { HospitalReviewModels } from '~models/hospital-review.model';

export const HospitalReviewTypes = {
  HOSPITAL_STATISTICS_LOAD: '[Hospital Review] Hospital Statistics Load',
  HOSPITAL_STATISTICS_LOAD_SUCCESS:
    '[Hospital Review] Hospital Statistics Load Success',
  HOSPITAL_STATISTICS_LOAD_FAIL:
    '[Hospital Review] Hospital Statistics Load Fail',

  HOSPITAL_COMMENT_LIST_LOAD: '[Hospital Review] Hospital Comment List Load',
  HOSPITAL_COMMENT_LIST_LOAD_SUCCESS:
    '[Hospital Review] Hospital Comment List Load Success',
  HOSPITAL_COMMENT_LIST_LOAD_FAIL:
    '[Hospital Review] Hospital Comment List Load Fail',
} as const;

// 병원별 리뷰 통계
export const HospitalStatisticsLoad = createAction(
  HospitalReviewTypes.HOSPITAL_STATISTICS_LOAD
);
export const HospitalStatisticsSuccess = createAction(
  HospitalReviewTypes.HOSPITAL_STATISTICS_LOAD_SUCCESS,
  props<{ payload: HospitalReviewModels.StatisticsUiModel }>()
);
export const HospitalStatisticsFail = createAction(
  HospitalReviewTypes.HOSPITAL_STATISTICS_LOAD_FAIL,
  props<{ message: string }>()
);

// 병원별 리뷰 의견
export const HospitalCommentListLoad = createAction(
  HospitalReviewTypes.HOSPITAL_COMMENT_LIST_LOAD,
  props<{ query?: HospitalReviewModels.CommentsReqModel }>()
);
export const HospitalCommentListSuccess = createAction(
  HospitalReviewTypes.HOSPITAL_COMMENT_LIST_LOAD_SUCCESS,
  props<{ payload: HospitalReviewModels.CommentListUiModel }>()
);
export const HospitalCommentListFail = createAction(
  HospitalReviewTypes.HOSPITAL_COMMENT_LIST_LOAD_FAIL,
  props<{ message: string }>()
);

export const HospitalReviewActions = {
  HospitalStatisticsLoad,
  HospitalStatisticsSuccess,
  HospitalStatisticsFail,

  HospitalCommentListLoad,
  HospitalCommentListSuccess,
  HospitalCommentListFail,
};
