import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import { CareRoom } from '~models/care-room.model';
import * as fromCancelMsg from './cancel-msg.reducer';
import * as fromCareRoom from './care-room.reducer';
import * as fromEmrCareRoom from './emr-careroom.reducer';
import * as fromChild from './child.reducer';
import * as fromCrm from './crm-service';
import * as fromFaq from './faq.reducers';
import * as fromHome from './home.reducer';
import * as fromHospitalSetting from './hos-setting.reducer';
import * as fromHospitalNotice from './hospital-notice.reducers';
import * as fromKioskManage from './kiosk-manage.reducer';
import * as fromNotice from './notice.reducers';
import * as fromOperating from './operating-time.reducer';
import * as fromPdfArchives from './pdf-archives.reducer';
import * as fromPeaktime from './peaktime-limit.reducer';
import * as fromPopup from './popup.reducers';
import * as fromQuestion from './question.reducer';
import * as fromRecSchedule from './rec-schedule.reducer';
import * as fromResSchedule from './res-schedule.reducer';
import * as fromResTimeline from './res-timeline-schedule.reducer';
import * as fromSelectSymptom from './select-symptom.reducer';
import * as fromStatusCanceled from './status-canceled.reducer';
import * as fromStatusPenalty from './status-penalty.reducer';
import * as fromStatusResultNotified from './status-result-notified.reducer';
import * as fromCareroomSymptom from './careroom-symptom.reducer';
import * as fromTableManage from './tablet-manage.reducer';
import * as fromVoluntary from './voluntary.reducer';
import * as fromWaitings from './waitings.reducer';
import * as fromWizard from './wizard.reducer';
import * as fromHospitalInfo from './hospital-info.reducer';
import * as fromDoctorInfo from './doctor-info.reducer';
import * as fromTreatmentInfo from './treatment-info.reducer';
import * as fromCrmDefaultSchedule from './hospital-crm-default-schedule.reducer';
import * as fromCrmSchedule from './hospital-crm-schedule.reducer';
import * as fromCrmStatistics from './hospital-crm-statistics.reducer';
import * as fromCrmExcludePushUser from './hospital-crm-exclude-push-user.reducer';
import * as fromCrmPushTargetUser from './hospital-crm-push-target-user.reducer';
import * as fromCrmMessageTemplate from './hospital-crm-message-template.reducer';
import * as fromCrmAddSchedule from './hospital-crm-schedule-add.reducer';
import * as fromPayment from './payment.reducer';
import * as fromExpenseCategory from './expense-category.reducer';
import * as fromExpenseCategorySearch from './expense-category-search.reducer';
import * as fromExpense from './expense.reducer';
import * as fromShortUrl from './short-url.reducer';

import { emrCareRoomAdapter } from './emr-careroom.reducer';
import {
  kioskQuestionReducer,
  KioskQuestionState,
} from './kiosk-question.reducer';
import {
  kioskQuestionOptionReducer,
  KioskQuestionOptionState,
} from './kiosk-question-option.reducer';
import * as fromReservationSchedule from './reservation-schedule-v2.reducer';
import * as fromReservationCopySchedule from './reservation-schedule-copy-v2.reducer';
import * as fromReservationTimetableSchedule from './reservation-schedule-timetable-v2.reducer';
import {
  KioskMainConfigState,
  kioskMainConfigReducer,
} from './kiosk-main-config.reducer';
import {
  mobilePaperReducer,
  MobilePaperState,
} from '~reducers/products/mobile-paper.reducer';

import {
  HealthScreeningState,
  healthScreeningReducer,
} from '~reducers/products/health-screening.reducer';
import {
  healthScreeningQuestionnaireReducer,
  HealthScreeningQuestionnaireState,
} from '~reducers/products/health-screening-questionnaire.reducer';
import {
  hospitalReviewReducer,
  HospitalReviewState,
} from '~reducers/products/hospital-review.reducer';
import {
  kioskMenuActiveReducer,
  KioskMenuActiveState,
} from '~reducers/products/kiosk-menu-active.reducer';
import {
  KioskSustainState,
  kioskSustainReducer,
} from '~reducers/products/kiosk-sustain.reducer';
import {
  TelemedicineEntityState,
  telemedicineReducer,
} from '~reducers/products/telemedicine.reducer';
import {
  paymentsReducer,
  PaymentsState,
} from '~reducers/products/payments.reducer';

export interface ProductState {
  careRoom: fromCareRoom.CareRoomState;
  emrCareRoom: fromEmrCareRoom.EmrCareRoomState;
  operatingTime: fromOperating.OperatingState;
  hospitalSetting: fromHospitalSetting.HosSettingState;
  cancelMsg: fromCancelMsg.CancelMsgState;
  waitings: fromWaitings.WaitingsState;
  careroomSymptom: fromCareroomSymptom.CareroomSymptomState;
  selectSymptom: fromSelectSymptom.SelectSymptomState;
  recSchedule: fromRecSchedule.RecScheduleState;
  resSchedule: fromResSchedule.ResScheduleState;
  tabletManage: fromTableManage.TabletManageState;
  child: fromChild.ChildScreeningState;
  resTimeline: fromResTimeline.ResTimelineScheduleState;
  peaktime: fromPeaktime.PeaktimeLimitState;
  voluntary: fromVoluntary.VoluntaryState;
  popupState: fromPopup.PopupState;
  noticeState: fromNotice.NoticeState;
  faqState: fromFaq.FaqState;
  wizardState: fromWizard.WizardState;
  hospitalNoticeState: fromHospitalNotice.HospitalNoticeState;
  homeState: fromHome.HomeState;
  kioskState: fromKioskManage.KioskManageState;
  crmState: fromCrm.CrmServiceState;
  pdfArchives: fromPdfArchives.PdfArchivesState;
  question: fromQuestion.QuestionState;
  statusPenalty: fromStatusPenalty.StatusPenaltyState;
  statusCanceled: fromStatusCanceled.StatusCanceledState;
  statusResultNotified: fromStatusResultNotified.StatusResultNotifiedState;
  hospitalInfo: fromHospitalInfo.HospitalInfoState;
  doctorInfo: fromDoctorInfo.DoctorInfoState;
  treatmentInfo: fromTreatmentInfo.TreatmentInfoState;
  crmDefaultSchedule: fromCrmDefaultSchedule.HospitalCrmDefaultScheduleState;
  crmSchedule: fromCrmSchedule.HospitalCrmScheduleState;
  crmStatistics: fromCrmStatistics.CrmStatisticsState;
  crmExcludePushUser: fromCrmExcludePushUser.CrmExcludePushUserState;
  crmPushTargetUser: fromCrmPushTargetUser.CrmPushTargetUserState;
  crmMessageTemplate: fromCrmMessageTemplate.CrmMessageTemplateState;
  crmAddSchedule: fromCrmAddSchedule.HospitalCrmScheduleAddState;
  paymentInfo: fromPayment.PaymentState;
  expenseCategory: fromExpenseCategory.ExpenseCategoryState;
  expenseCategorySearch: fromExpenseCategorySearch.ExpenseCategorySearchState;
  expense: fromExpense.ExpenseState;
  reservationSchedule: fromReservationSchedule.ReservationScheduleState;
  reservationCopySchedule: fromReservationCopySchedule.ReservationScheduleCopyState;
  reservationTimetableSchedule: fromReservationTimetableSchedule.ReservationTimetableScheduleState;
  shortUrl: fromShortUrl.ShortUrlState;
  kioskQuestion: KioskQuestionState;
  kioskQuestionOption: KioskQuestionOptionState;
  kioskMainConfig: KioskMainConfigState;
  mobilePaper: MobilePaperState;
  healthScreening: HealthScreeningState;
  healthScreeningQuestionnaire: HealthScreeningQuestionnaireState;
  hospitalReview: HospitalReviewState;
  kioskMenuActive: KioskMenuActiveState;
  kioskSustain: KioskSustainState;
  telemedicine: TelemedicineEntityState;
  payments: PaymentsState;
}

export const reducers: ActionReducerMap<ProductState> = {
  cancelMsg: fromCancelMsg.reducer,
  careRoom: fromCareRoom.reducer,
  emrCareRoom: fromEmrCareRoom.reducer,
  child: fromChild.reducer,
  crmState: fromCrm.reducer,
  doctorInfo: fromDoctorInfo.reducer,
  expense: fromExpense.reducer,
  expenseCategory: fromExpenseCategory.reducer,
  expenseCategorySearch: fromExpenseCategorySearch.reducer,
  faqState: fromFaq.reducer,
  homeState: fromHome.reducer,
  hospitalSetting: fromHospitalSetting.reducer,
  crmDefaultSchedule: fromCrmDefaultSchedule.reducer,
  crmExcludePushUser: fromCrmExcludePushUser.reducer,
  crmMessageTemplate: fromCrmMessageTemplate.reducer,
  crmPushTargetUser: fromCrmPushTargetUser.reducer,
  crmSchedule: fromCrmSchedule.reducer,
  crmAddSchedule: fromCrmAddSchedule.reducer,
  crmStatistics: fromCrmStatistics.reducer,
  hospitalInfo: fromHospitalInfo.reducer,
  hospitalNoticeState: fromHospitalNotice.reducer,
  kioskState: fromKioskManage.reducer,
  noticeState: fromNotice.reducer,
  operatingTime: fromOperating.reducer,
  paymentInfo: fromPayment.reducer,
  pdfArchives: fromPdfArchives.reducer,
  peaktime: fromPeaktime.reducer,
  popupState: fromPopup.reducer,
  question: fromQuestion.reducer,
  recSchedule: fromRecSchedule.reducer,
  resSchedule: fromResSchedule.reducer,
  resTimeline: fromResTimeline.reducer,
  selectSymptom: fromSelectSymptom.reducer,
  statusCanceled: fromStatusCanceled.reducer,
  statusPenalty: fromStatusPenalty.reducer,
  statusResultNotified: fromStatusResultNotified.reducer,
  careroomSymptom: fromCareroomSymptom.reducer,
  treatmentInfo: fromTreatmentInfo.reducer,
  tabletManage: fromTableManage.reducer,
  voluntary: fromVoluntary.reducer,
  waitings: fromWaitings.reducer,
  wizardState: fromWizard.reducer,
  reservationSchedule: fromReservationSchedule.reducer,
  reservationCopySchedule: fromReservationCopySchedule.reducer,
  reservationTimetableSchedule: fromReservationTimetableSchedule.reducer,
  shortUrl: fromShortUrl.reducer,
  kioskQuestion: kioskQuestionReducer,
  kioskQuestionOption: kioskQuestionOptionReducer,
  kioskMainConfig: kioskMainConfigReducer,
  mobilePaper: mobilePaperReducer,
  healthScreening: healthScreeningReducer,
  healthScreeningQuestionnaire: healthScreeningQuestionnaireReducer,
  hospitalReview: hospitalReviewReducer,
  kioskMenuActive: kioskMenuActiveReducer,
  kioskSustain: kioskSustainReducer,
  telemedicine: telemedicineReducer,
  payments: paymentsReducer,
};

// select reception state

export const selectProductState =
  createFeatureSelector<ProductState>('product');

/**
 * 진료실 설정 Selector Command
 */

const {
  selectAll: selectAllEmrCareRooms,
  selectTotal: selectEmrCareRoomsTotal,
} = emrCareRoomAdapter.getSelectors();

const getEmrCareRoom = createSelector(
  selectProductState,
  state => state.emrCareRoom
);

export const getEmrCareRoomLoading = createSelector(
  getEmrCareRoom,
  state => state.loading
);

export const getEmrCareRoomLoadingUnitKey = createSelector(
  getEmrCareRoom,
  state => state.loadingUnitKey
);

export const getAllEmrCareRooms = createSelector(
  getEmrCareRoom,
  selectAllEmrCareRooms
);

export const getEmrCareRoomsTotal = createSelector(
  getEmrCareRoom,
  selectEmrCareRoomsTotal
);

// 진료실 설정 state select
export const getCareRoomEntitiesState = createSelector(
  selectProductState,
  (state: ProductState) => state.careRoom
);

// 진료실 설정 선택한 진료실 키 select
export const getSelectedCareRoomKey = createSelector(
  getCareRoomEntitiesState,
  fromCareRoom.getSelectedKey
);

// adapter selector
export const {
  selectAll: getAllEntityCareRoom,
  selectEntities: getCareRoomEntities,
  selectTotal: getEntityCareRoomTotal,
} = fromCareRoom.careRoomAdapter.getSelectors(getCareRoomEntitiesState);

// 진료실 설정 선택한 진료실
export const getSelectedCareRoom = createSelector(
  getCareRoomEntities,
  getSelectedCareRoomKey,
  (entities, selectedKey) => {
    return selectedKey && entities[selectedKey];
  }
);

export const getCareRoomReceiptReservationSafe = createSelector(
  getAllEntityCareRoom,
  (rooms: CareRoom[]) => {
    try {
      // 접수 혹은 예약을 하나라도 쓰고 있는 진료실이 2개 이상인지 확인 한다.
      // 2개 미만일 경우엔 안전(safe) 하지 않은 것이다.
      return rooms
        .filter(room => room.useReceipt || room.useReservation)
        .map(room => room.key);
    } catch (error) {
      // 진료실 목록 정보로 필터링 할 때 오류가 난 것이라면 진료실 목록 자체가 없다는 뜻으로 간주 한다.
      // 따라서 안전(safe) 하지 않다.
      return [];
    }
  }
);

export const getIsAgreedTelemedicine = createSelector(
  getCareRoomEntitiesState,
  ({ agreement: { telemedicine } }) => telemedicine
);

// Kiosk 사용중인 진료실
export const getCareRoomsForUsingKiosk = createSelector(
  getAllEntityCareRoom,
  (careRooms: CareRoom[]) => {
    return careRooms.filter(careRoom => careRoom.useKiosk);
  }
);

// 무인접수 사용중인 진료실
export const getCareRoomsForUsingSelfDesk = createSelector(
  getAllEntityCareRoom,
  (careRooms: CareRoom[]) => {
    return careRooms.filter(careRoom => careRoom.useSelfDesk);
  }
);

// export const getSelectedCareRoom = createSelector(
//   getCareRoomEntities,
//   getRouteParams,
//   (entities, params) => {
//     return params && entities[params.id];
//   }
// );

// 진료실 로드 완료 유무
export const getLoadedCareRoom = createSelector(
  getCareRoomEntitiesState,
  fromCareRoom.getCareRoomLoaded
);

// 진료실 에러 메시지
export const getCareRoomError = createSelector(
  getCareRoomEntitiesState,
  fromCareRoom.getCareRoomsError
);

export const getCareRoomLoading = createSelector(
  getCareRoomEntitiesState,
  fromCareRoom.getCareRoomLoading
);

export const getCareRoomAgreementInfo = createSelector(
  getCareRoomEntitiesState,
  fromCareRoom.getCareRoomAgreementInfo
);

/**
 * 진료시간 설정 Selector Command
 */

// 진료시간 설정 state select

export const selectOperatingTimeState = createSelector(
  selectProductState,
  (state: ProductState) => state.operatingTime
);

export const getOperatingTimeData = createSelector(
  selectOperatingTimeState,
  fromOperating.getOperatingData
);

export const getOperatingTimeError = createSelector(
  selectOperatingTimeState,
  fromOperating.getError
);

export const getOperatingTimeLoaded = createSelector(
  selectOperatingTimeState,
  fromOperating.getLoaded
);

export const getOperatingTimeLoading = createSelector(
  selectOperatingTimeState,
  fromOperating.getLoading
);

/**
 * 병원 설정 Selector Command
 */

export const selectHosSettingState = createSelector(
  selectProductState,
  (state: ProductState) => state.hospitalSetting
);

export const getHosSettingData = createSelector(
  selectHosSettingState,
  fromHospitalSetting.getHosSetting
);

export const getHosSettingLoaded = createSelector(
  selectHosSettingState,
  fromHospitalSetting.getLoaded
);

export const getHosSettingError = createSelector(
  selectHosSettingState,
  fromHospitalSetting.getError
);

export const getHosSettingLoading = createSelector(
  selectHosSettingState,
  fromHospitalSetting.getLoading
);

export const getUseReceipt = createSelector(
  selectHosSettingState,
  fromHospitalSetting.getUseReceipt
);

export const getUseReservation = createSelector(
  selectHosSettingState,
  fromHospitalSetting.getUseReservation
);

export const getUseExaminationInfants = createSelector(
  selectHosSettingState,
  fromHospitalSetting.getUseExaminationInfants
);

/**
 * 취소 메시지 Selector Command
 */

export const selectCancelMsgState = createSelector(
  selectProductState,
  (state: ProductState) => state.cancelMsg
);

export const getCancelMsgError = createSelector(
  selectCancelMsgState,
  fromCancelMsg.getCancelMsgError
);

export const getCancelMsgData = createSelector(
  selectCancelMsgState,
  fromCancelMsg.getCancelMsgData
);

export const getCancelMsgLength = createSelector(
  selectCancelMsgState,
  fromCancelMsg.getCancelMsgLength
);

export const getCancelMsgLoaded = createSelector(
  selectCancelMsgState,
  fromCancelMsg.getCancelMsgLoaded
);

/**
 * 대기현황판 설정 Selector Command
 */

// export const selectWaitTimeState = createSelector(
//   selectProductState,
//   (state: ProductState) => state.waitTimeSetting
// );

// export const getWaitTimeSettingError = createSelector(
//   selectWaitTimeState,
//   fromWaitTimeSetting.getError
// );

// export const getWaitTimeSettingData = createSelector(
//   selectWaitTimeState,
//   fromWaitTimeSetting.getWaitSetting
// );

// export const getWaitTimeSettingLoading = createSelector(
//   selectWaitTimeState,
//   fromWaitTimeSetting.getLoading
// );

// export const getWaitTimeSettingLoaded = createSelector(
//   selectWaitTimeState,
//   fromWaitTimeSetting.getLoaded
// );

/**
 * 선택 증상 설정 Selector Command
 */
export const symptomState = createSelector(
  selectProductState,
  (state: ProductState) => state.careroomSymptom
);

export const getSelectedCareRoomSymptomData = createSelector(
  symptomState,
  fromCareroomSymptom.getSelectedCareRoomSymptom
);

export const getCareRoomsSymptomData = createSelector(
  symptomState,
  fromCareroomSymptom.getCareRoomsSymptomList
);

export const getCareRoomsSymptomWholeList = createSelector(
  symptomState,
  fromCareroomSymptom.getCareRoomsSymptomWholeList
);

export const getSymptomLoading = createSelector(
  symptomState,
  fromCareroomSymptom.getLoading
);

export const getSymptomLoaded = createSelector(
  symptomState,
  fromCareroomSymptom.getLoaded
);

export const getSymptomError = createSelector(
  symptomState,
  fromCareroomSymptom.getError
);

/**
 * 선택 증상 목록
 */

export const selectSymptomState = createSelector(
  selectProductState,
  (state: ProductState) => state.selectSymptom
);

export const getSymptomCategoryList = createSelector(
  selectSymptomState,
  fromSelectSymptom.getCategoryData
);

export const getSelectSymptomLoading = createSelector(
  selectSymptomState,
  fromSelectSymptom.getLoading
);

export const getSelectSymptomError = createSelector(
  selectSymptomState,
  fromSelectSymptom.getError
);

export const getSymptomListData = createSelector(
  selectSymptomState,
  fromSelectSymptom.getSymptomListData
);

/**
 * 무인접수 태블릿 설정
 */

export const getUnmannedTabletState = createSelector(
  selectProductState,
  (state: ProductState) => state.tabletManage
);
export const getUnmannedTabletLoading = createSelector(
  getUnmannedTabletState,
  fromTableManage.getLoading
);
export const getUnmannedTabletLoaded = createSelector(
  getUnmannedTabletState,
  fromTableManage.getLoaded
);
export const getUnmannedTabletSettingData = createSelector(
  getUnmannedTabletState,
  fromTableManage.getData
);
export const getUnmannedTabletSettingDataError = createSelector(
  getUnmannedTabletState,
  fromTableManage.getError
);

/**
 * 무인접수 키오스크 설정
 */
export const getKisokManageState = createSelector(
  selectProductState,
  (state: ProductState) => state.kioskState
);

export const getKioskManageLoading = createSelector(
  getKisokManageState,
  fromKioskManage.getLoading
);

export const getKioskManageLoaded = createSelector(
  getKisokManageState,
  fromKioskManage.getLoaded
);

export const getKioskManageData = createSelector(
  getKisokManageState,
  fromKioskManage.getKioskData
);

export const getKioskCertificationPrintingSetup = createSelector(
  getKioskManageData,
  state => {
    try {
      return state.certificationPrintingSetup;
    } catch (error) {
      return null;
    }
  }
);

export const getKioskUseCertificationPrinting = createSelector(
  getKisokManageState,
  state => {
    try {
      return state.data.useCertificationPrinting;
    } catch (error) {
      return false;
    }
  }
);

export const getKioskFeederType = createSelector(
  getKisokManageState,
  state => state.feederType
);

export const getKioskCertPrintingDisabled = createSelector(
  getKioskUseCertificationPrinting,
  use => !use
);

export const getKioskCertSetupShown = createSelector(
  getKisokManageState,
  state => {
    try {
      return state.data.useCertificationPrinting && state.feederType === 2;
    } catch (error) {
      return true;
    }
  }
);

/**
 * 영유아 검진
 */
export const getChildState = createSelector(
  selectProductState,
  (state: ProductState) => state.child
);
export const getChildLoading = createSelector(
  getChildState,
  fromChild.getLoading
);
export const getChildLoaded = createSelector(
  getChildState,
  fromChild.getLoaded
);
export const getChildData = createSelector(getChildState, fromChild.getData);
export const getChildError = createSelector(getChildState, fromChild.getError);
export const getChildDownloadPending = createSelector(
  getChildState,
  fromChild.getDownloadPending
);

/**
 * 피크타임 제한
 */
export const getPeaktimeState = createSelector(
  selectProductState,
  (state: ProductState) => state.peaktime
);
export const getPeaktimeLoading = createSelector(
  getPeaktimeState,
  fromPeaktime.getLoading
);
export const getPeaktimeLoaded = createSelector(
  getPeaktimeState,
  fromPeaktime.getLoaded
);
export const getPeaktimeData = createSelector(
  getPeaktimeState,
  fromPeaktime.getPeaktime
);
export const getUnitPeaktime = createSelector(
  getPeaktimeState,
  fromPeaktime.getUnitPeaktime
);
export const getPeaktimeError = createSelector(
  getPeaktimeState,
  fromPeaktime.getError
);

/**
 * 자발적 신청경로
 */
export const getVoluntaryState = createSelector(
  selectProductState,
  (state: ProductState) => state.voluntary
);
export const getVoluntaryLoaded = createSelector(
  getVoluntaryState,
  fromVoluntary.getLoaded
);
export const getVoluntaryError = createSelector(
  getVoluntaryState,
  fromVoluntary.getError
);

export const getAgencies = createSelector(
  getVoluntaryState,
  fromVoluntary.getAgencies
);

export const getSended = createSelector(
  getVoluntaryState,
  fromVoluntary.getSent
);

/**
 * 메인 팝업
 */
export const getPopupState = createSelector(
  selectProductState,
  (state: ProductState) => state.popupState
);
export const getPopupInfoItems = createSelector(
  getPopupState,
  fromPopup.getPopupInfoItems
);

/**
 * 공지사항
 */
export const getNoticeState = createSelector(
  selectProductState,
  (state: ProductState) => state.noticeState
);
export const getNoticeLatest = createSelector(
  getNoticeState,
  fromNotice.getNoticeLatest
  // (state: noticeReducers.NoticeState) => {
  //   try {
  //     return state.noticeList.items.slice(0, 3);
  //   } catch (error) {
  //     return [];
  //   }
  // }
);
export const getNoticeList = createSelector(
  getNoticeState,
  fromNotice.getNoticeList
);
export const getNoticeDetail = createSelector(
  getNoticeState,
  fromNotice.getNoticeDetail
);
export const getNoticeLoading = createSelector(
  getNoticeState,
  fromNotice.getNoticeLoading
);

/**
 * FAQ
 */
export const getFaqState = createSelector(
  selectProductState,
  (state: ProductState) => state.faqState
);

/**
 * 병원 공지사항
 */
export const getHospitalNoticeState = createSelector(
  selectProductState,
  (state: ProductState) => state.hospitalNoticeState
);

export const getHospitalNoticeList = createSelector(
  getHospitalNoticeState,
  fromHospitalNotice.getHospitalNoticeList
);

export const getHospitalNoticeDetail = createSelector(
  getHospitalNoticeState,
  fromHospitalNotice.getHospitalNoticeDetail
);

export const getHospitalNoticeIsExists = createSelector(
  getHospitalNoticeDetail,
  detail => !!detail
);

export const getHospitalNoticeSearchParams = createSelector(
  getHospitalNoticeState,
  fromHospitalNotice.getHospitalNoticeSearchParams
);

/**
 * CRM 서비스 설정
 */
export const getCrmServiceState = createSelector(
  selectProductState,
  (state: ProductState) => state.crmState
);
export const getCrmStateLoading = createSelector(
  getCrmServiceState,
  fromCrm.getLoading
);
export const getCrmStateLoaded = createSelector(
  getCrmServiceState,
  fromCrm.getLoaded
);
export const getCrmAgreementData = createSelector(
  getCrmServiceState,
  fromCrm.getAgreement
);
export const getCrmFileListData = createSelector(
  getCrmServiceState,
  fromCrm.getFileList
);

// 결제 활성화 상태 확인
export const getPaymentActivationState = createSelector(
  selectProductState,
  (state: ProductState) => {
    return state.paymentInfo;
  }
);

// Expense Category
export const getExpenseCategoryState = createSelector(
  selectProductState,
  (state: ProductState) => {
    return state.expenseCategory;
  }
);

// expense category search
export const getExpenseCategorySearchState = createSelector(
  selectProductState,
  (state: ProductState) => {
    return state.expenseCategorySearch;
  }
);

export const getExpenseState = createSelector(
  selectProductState,
  (state: ProductState) => {
    return state.expense;
  }
);
