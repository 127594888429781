import { createAction, props } from '@ngrx/store';
import { WizardGotoArgs, WizardModel } from '~models/wizard.model';

export const WizardActionType = {
  INTRO_END: '[Wizard] Intro End',
  LIST_LOAD: '[Wizard] List Load',
  LIST_LOAD_SUCCESS: '[Wizard] List Load Success',
  LIST_LOAD_FAIL: '[Wizard] List Load Fail',

  // USE_CHECKING: '[Wizard] Use Checking',
  // USE_CHECKED: '[Wizard] Use Checked',
  SET_USE: '[Wizard] Set Use',
  SET_USE_APPLY: '[Wizard] Set Use Apply',
  // USE_OFF: '[Wizard[ Use Off',

  NEXT: '[Wizard] Next',
  PREV: '[Wizard] Prev',
  GOTO: '[Wizard] Goto',
  GOTO_APPLY: '[Wizard] Goto Apply',
  FINISH: '[Wizard] Finish',
} as const;

// export class WizardIntroEnd implements Action {
//   readonly type = WizardActionType.INTRO_END;

//   constructor(public params: { type: string }) {}
// }

export const WizardIntroEnd = createAction(
  WizardActionType.INTRO_END,
  props<{ params: { type: string } }>()
);

// export class WizardListLoad implements Action {
//   readonly type = WizardActionType.LIST_LOAD;

//   constructor(public params: { type: string; forceUse?: boolean }) {}
// }

export const WizardListLoad = createAction(
  WizardActionType.LIST_LOAD,
  props<{ params: { type: string; forceUse?: boolean } }>()
);

// export class WizardListLoadSuccess implements Action {
//   readonly type = WizardActionType.LIST_LOAD_SUCCESS;

//   constructor(
//     public payload: {
//       type: string;
//       list: WizardModel[];
//     }
//   ) {}
// }

export const WizardListLoadSuccess = createAction(
  WizardActionType.LIST_LOAD_SUCCESS,
  props<{ payload: { type: string; list: WizardModel[] } }>()
);

// export class WizardListLoadFail extends ErrorAction {
//   readonly type = WizardActionType.LIST_LOAD_FAIL;

//   constructor(err) {
//     super(err);
//   }
// }

export const WizardListLoadFail = createAction(
  WizardActionType.LIST_LOAD_FAIL,
  props<{ message: any }>()
);

// export class WizardSetUse implements Action {
//   readonly type = WizardActionType.SET_USE;

//   constructor(public payload: { type: string; use: boolean }) {}
// }

export const WizardSetUse = createAction(
  WizardActionType.SET_USE,
  props<{ payload: { type: string; use: boolean } }>()
);

// export class WizardSetUseApply implements Action {
//   readonly type = WizardActionType.SET_USE_APPLY;

//   constructor(public payload: { type: string; use: boolean }) {}
// }

export const WizardSetUseApply = createAction(
  WizardActionType.SET_USE_APPLY,
  props<{ payload: { type: string; use: boolean } }>()
);

// export class WizardNext implements Action {
//   readonly type = WizardActionType.NEXT;

//   constructor(public payload?: any) {}
// }

export const WizardNext = createAction(
  WizardActionType.NEXT,
  props<{ payload?: any }>()
);

// export class WizardPrev implements Action {
//   readonly type = WizardActionType.PREV;

//   constructor(public payload?: any) {}
// }

export const WizardPrev = createAction(
  WizardActionType.PREV,
  props<{ payload: any }>()
);

// export class WizardGoto implements Action {
//   readonly type = WizardActionType.GOTO;

//   constructor(public payload: WizardGotoArgs) {}
// }

export const WizardGoto = createAction(
  WizardActionType.GOTO,
  props<{ payload: WizardGotoArgs }>()
);

// export class WizardGotoApply implements Action {
//   readonly type = WizardActionType.GOTO_APPLY;

//   constructor(public index: number) {}
// }

export const WizardGotoApply = createAction(
  WizardActionType.GOTO_APPLY,
  props<{ index?: number }>()
);

// export class WizardFinish implements Action {
//   readonly type = WizardActionType.FINISH;

//   constructor(public payload?: any) {}
// }

export const WizardFinish = createAction(
  WizardActionType.FINISH,
  props<{
    payload: any;
  }>()
);

// export type WizardActions =
//   | WizardIntroEnd
//   | WizardListLoad
//   | WizardListLoadSuccess
//   | WizardListLoadFail
//   | WizardSetUse
//   | WizardSetUseApply
//   | WizardNext
//   | WizardPrev
//   | WizardGoto
//   | WizardGotoApply
//   | WizardFinish;

export const WizardActions = {
  WizardIntroEnd,
  WizardListLoad,
  WizardListLoadSuccess,
  WizardListLoadFail,
  WizardSetUse,
  WizardSetUseApply,
  WizardNext,
  WizardPrev,
  WizardGoto,
  WizardGotoApply,
  WizardFinish,
};
