import { createAction, props } from '@ngrx/store';
import {
  ServiceVideoModel,
  ServiceVideoQueryParams,
} from '~models/service-video.model';
import { HttpRes } from '~models/common.model';
import { HttpErrorResponse } from '@angular/common/http';

export const ServiceVideoActionTypes = {
  SERVICE_VIDEO_LOAD: '[SERVICE_VIDEO] SERVICE VIDEO LOAD',
  SERVICE_VIDEO_LOAD_SUCCESS: '[SERVICE_VIDEO] SERVICE VIDEO LOAD SUCCESS',
  SERVICE_VIDEO_LOAD_FAIL: '[SERVICE_VIDEO] SERVICE VIDEO LOAD FAIL',
} as const;

export const ServiceVideoLoad = createAction(
  ServiceVideoActionTypes.SERVICE_VIDEO_LOAD,
  props<{ payload?: ServiceVideoQueryParams }>()
);

export const ServiceVideoLoadSuccess = createAction(
  ServiceVideoActionTypes.SERVICE_VIDEO_LOAD_SUCCESS,
  props<{ payload: HttpRes<ServiceVideoModel> }>()
);

export const ServiceVideoLoadFail = createAction(
  ServiceVideoActionTypes.SERVICE_VIDEO_LOAD_FAIL,
  props<{ payload: HttpErrorResponse }>()
);

export const ServiceVideoActions = {
  ServiceVideoLoad,
  ServiceVideoLoadSuccess,
  ServiceVideoLoadFail,
};
