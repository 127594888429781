import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { RetreatElseWhere, CommonModalService } from '../../services/common.modal.service';

const DEFAULT_POSITIVE_TEXT = '확인';
const DEFAULT_NEGATIVE_TEXT = '취소';
const DEFAULT_CHECK_TEXT = '닫기';

@Component({
  selector: 'app-common-modal',
  templateUrl: './common.modal.component.html',
  styleUrls: ['./common.modal.component.scss']
})
export class CommonModalComponent {
  mode: string;
  desc: string;
  elseWhere: RetreatElseWhere;
  elseWhereText = '';
  hasElseWhereText = false;
  checkText = DEFAULT_CHECK_TEXT;
  positiveText = DEFAULT_POSITIVE_TEXT;
  negativeText = DEFAULT_NEGATIVE_TEXT;
  showClose = true;

  @Input() open: boolean;

  @ViewChild('btnCommonAccept') btnAccept: ElementRef;
  @ViewChild('btnCommonRetreat') btnRetreat: ElementRef;

  constructor(public service: CommonModalService) {
    service.modal.subscribe(params => {
      const { mode, desc, open, elseWhere, showClose = true } = params;
      this.mode = mode;
      this.desc = desc;
      this.open = open;
      this.hasElseWhereText = !!(elseWhere && elseWhere.text);
      this.elseWhereText = elseWhere ? elseWhere.text : '';
      this.elseWhere = elseWhere || null;
      this.showClose = showClose;

      if (mode === 'alert') {
        const { checkText = DEFAULT_CHECK_TEXT } = params;
        this.checkText = checkText;
      }

      if (mode === 'confirm') {
        const { positiveText = DEFAULT_POSITIVE_TEXT, negativeText = DEFAULT_NEGATIVE_TEXT } = params;
        this.positiveText = positiveText;
        this.negativeText = negativeText;
      }

      if (open) {
        this.getFocusElem().then(elem => elem.focus());
      }
    });
  }

  isOpen() {
    return this.open === true;
  }

  isConfirmMode() {
    return this.mode === 'confirm';
  }

  isRetreatMode() {
    return this.mode === 'retreat';
  }

  getFocusElem(): Promise<HTMLButtonElement> {
    return new Promise((resolve, reject) => {
      let id = 'btnCommonAccept';

      if (this.isRetreatMode()) {
        id = 'btnCommonRetreat';
      }

      const elem = document.getElementById(id);

      setTimeout(() => {
        if (!elem) {
          this.getFocusElem().then(elm => resolve(elm));

          return;
        }

        resolve(elem as HTMLButtonElement);
      }, 150);
    });
  }

  close(event, val: boolean) {
    event.stopPropagation();

    this.service.close(val, this.elseWhere);
  }

  clickOverlay(event) {
    event.stopPropagation();
    let val = true;

    if (this.isConfirmMode() || this.isRetreatMode()) {
      val = false;
    }

    this.service.close(val, this.elseWhere);
  }
}
