import { CrmExcludePushUserActions } from '~actions/products/hospital-crm-exclude-push-user.actions';
import { ExcludePushUserRes } from '~models/hospital-crm.model';
import { createReducer, on } from '@ngrx/store';

/**
 * 푸시 발송 제외 유저 State
 * @name CrmExcludePushUserState
 * @author Hong-ki Min
 */
export interface CrmExcludePushUserState extends ExcludePushUserRes {
  // 서버와 통신 여부
  pending: boolean;
  // 모달 검색 결과( forCreate 제외 )
  forCreate: Omit<CrmExcludePushUserState, 'forCreate'>;
}

const initState: CrmExcludePushUserState = {
  items: [],
  totalCount: 0,
  pending: false,
  forCreate: {
    items: [],
    totalCount: 0,
    pending: false,
  },
};

export const reducer = createReducer(
  initState,
  on(CrmExcludePushUserActions.CrmExcludePushUserLoad, (state, action) => ({
    ...state,
    forCreate: initState.forCreate,
    pending: true,
  })),
  on(
    CrmExcludePushUserActions.CrmExcludePushUserLoadSuccess,
    (state, action) => ({
      ...state,
      ...action.payload,
      pending: false,
    })
  ),
  on(CrmExcludePushUserActions.CrmModalExcludePushUserLoad, state => ({
    ...state,
    forCreate: { ...state.forCreate, pending: true },
  })),
  on(
    CrmExcludePushUserActions.CrmModalExcludePushUserLoadSuccess,
    (state, action) => {
      return {
        ...state,
        forCreate: {
          ...action.payload,
          pending: false,
        },
      };
    }
  ),
  on(CrmExcludePushUserActions.CrmExcludePushUserReqFail, state => ({
    ...state,
    forCreate: { ...state.forCreate, pending: false },
    pending: false,
  }))
);
