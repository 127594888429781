import { Observable } from 'rxjs/';
import { delay, map } from 'rxjs/operators';

export function filteredIndexSign(len: number, curr: number, index: number) {
  const maxIdx = len - 1;
  let idx = index;
  let sign = 0;

  if (idx < 0) {
    idx = maxIdx;
    sign = 1;
  } else if (idx > maxIdx) {
    idx = 0;
    sign = -1;
  } else {
    sign = Math.sign(curr - index);
  }

  return {
    index: idx,
    sign,
  };
}

export function makeStructure(length: number, index: number): number[] {
  const iLen = length;
  const maxIndex = iLen - 1;
  const aRet = [-1, -1, -1];

  if (!iLen) {
    return [];
  }

  if (index > maxIndex || index === 0) {
    aRet[0] = iLen - 1;
    aRet[1] = 0;
    aRet[2] = 1;

    return aRet;
  }

  if (index < 0 || index === maxIndex) {
    aRet[0] = maxIndex - 1;
    aRet[1] = maxIndex;
    aRet[2] = 0;

    return aRet;
  }

  aRet[0] = index - 1;
  aRet[1] = index;
  aRet[2] = index + 1;

  return aRet;
}

export function makeStructureByStartEnd(
  length: number,
  start: number,
  end: number
) {
  const maxIdx = length - 1;
  // 왼쪽으로 이동 한다
  if (start > end) {
    return [end, start, -1];
  }

  return [-1, start, end];
}

export function makeStructureForDual(sign: number, start: number, end: number) {
  // 왼쪽 <-- 오른쪽 이동
  if (sign < 0) {
    return [-1, start, end];
  }
  // 왼족 --> 오른쪽 이동
  return [end, start, -1];
}

export function positioning(
  direction: string,
  conts: HTMLElement[],
  width: number,
  structure: number[]
) {
  const stylePos = direction === 'x' ? 'left' : 'top';
  conts
    .filter((e, idx) => structure.indexOf(idx) < 0)
    .forEach(cont => {
      cont.style.visibility = 'hidden';
    });

  structure.forEach((seq, index) => {
    if (seq < 0) {
      return;
    }
    const cont = conts[seq];

    // console.log('seq', seq);

    cont.style.visibility = 'visible';
    cont.style[stylePos] = width * index + 'px';
  });
}

export function positioningByIndex(
  direction: string,
  index: number,
  conts: HTMLElement[],
  width: number
) {
  const length = conts ? conts.length : 0;

  if (length === 0) {
    return [];
  }
  if (length === 1) {
    return [0];
  }

  const structure = makeStructure(length, index);

  // console.log('structure', structure);

  positioning(direction, conts, width, structure);

  return structure;
}

export function positioningForDual(
  direction: string,
  sign: number,
  start: number,
  end: number,
  conts: HTMLElement[],
  width: number
) {
  const structure = makeStructureForDual(sign, start, end);

  positioning(direction, conts, width, structure);

  return structure;
}

export function positioningByStartEnd(
  direction: string,
  start: number,
  end: number,
  conts: HTMLElement[],
  width: number
) {
  const structure = makeStructureByStartEnd(conts.length, start, end);

  // console.log('structure', start > end, structure);

  positioning(direction, conts, width, structure);

  return structure;
}

export function styleTransform(elem: HTMLElement, value: string) {
  const isOnlyWebkit = elem.style.transform === undefined;

  if (isOnlyWebkit) {
    elem.style.webkitTransform = value;
  } else {
    elem.style.transform = value;
  }
}

export function styleTransition(elem: HTMLElement, value: string) {
  const isOnlyWebkit = elem.style.transition === undefined;

  if (isOnlyWebkit) {
    elem.style.webkitTransition = value;
  } else {
    elem.style.transition = value;
  }
}

function getTranslateByDirection(direction: string) {
  if (direction === 'x') {
    return 'translateX';
  }
  return 'translateY';
}

export function initMove(direction: string, elem: HTMLElement, width: number) {
  styleTransition(elem, 'none');
  styleTransform(
    elem,
    `${getTranslateByDirection(direction)}(${-1 * width}px)`
  );
}

export function move(direction: string, elem: HTMLElement, distance: number) {
  const iDistance = Math.abs(distance);
  const moveDistance = distance - iDistance;
  const transformValue = `${getTranslateByDirection(
    direction
  )}(${moveDistance}px)`;
  const isOnlyWebkit = elem.style.transform === undefined;
  let eventName = 'transitionend';

  styleTransition(elem, '');
  styleTransform(elem, transformValue);

  if (isOnlyWebkit) {
    eventName = 'webkitTransitionEnd';
  }

  return new Observable(sub => {
    const fn = event => {
      elem.removeEventListener(eventName, fn);

      sub.next(event);
      sub.complete();
    };
    elem.addEventListener(eventName, fn);
  }).pipe(
    delay(100),
    map(event => {
      initMove(direction, elem, iDistance);

      return event;
    })
  );
}
