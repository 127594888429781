<div class="keyword-filter">
  <input
    id="{{ id }}"
    type="text"
    class="form-control keyword-input"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    (ngModelChange)="handleChange(value)"
  />
  <i class="icon icon-search-black"></i>
</div>
