import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncateCharactersPipe implements PipeTransform {
  transform(value: string, limit = 25, completeWords = false, ellipsis = '…', lastCharacter = 0) {
    let returnstr = '';

    if (completeWords) {
      const limitword = value.substr(0, 13).lastIndexOf(' ');
      if (limitword > 0) {
        limit = limitword;
      }
      returnstr = value.substring(0, limit) + ellipsis;
    } else {
      returnstr = value.substring(0, limit) + ellipsis;
    }
    if (lastCharacter) {
      returnstr = value.substring(0, limit) + ellipsis + value.substr(lastCharacter);
    }

    if (limit + Math.abs(lastCharacter) > value.length) {
      returnstr = value;
    }
    return value.length > limit ? returnstr : value;
  }
}
