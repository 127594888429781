import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { on } from '@ngrx/store';
import { createReducer } from '@ngrx/store';
import { HealthScreeningQuestionnaireActions } from '~actions/products/health-screening-questionnaire.action';
import { HealthScreeningQuestionnaireModels } from '~models/health-screening-questionnaire.model';

interface State {
  loaded: boolean;
  error?: any;
}

export type HealthScreeningQuestionnaireState = State &
  EntityState<HealthScreeningQuestionnaireModels.Target>;

export const healthScreeningQuestionnaireTargetAdapter = createEntityAdapter<
  HealthScreeningQuestionnaireModels.Target
>({
  selectId: item => item._id,
  sortComparer: item => item.order,
});

const initialState = healthScreeningQuestionnaireTargetAdapter.getInitialState<
  State
>({
  loaded: false,
});

export const healthScreeningQuestionnaireReducer = createReducer(
  initialState,
  on(HealthScreeningQuestionnaireActions.RequestFail, (state, { error }) => {
    return {
      ...state,
      loaded: false,
      error,
    };
  }),
  on(
    HealthScreeningQuestionnaireActions.FetchTargetsSuccess,
    (state, { res: { items } }) => {
      return healthScreeningQuestionnaireTargetAdapter.setAll(items, {
        ...state,
        loaded: true,
      });
    }
  ),
  on(
    HealthScreeningQuestionnaireActions.DeleteTargetSuccess,
    (state, { questionnaireId }) => {
      return healthScreeningQuestionnaireTargetAdapter.removeOne(
        questionnaireId,
        state
      );
    }
  )
);
