/**
 * 전화번호 Hypen 추가
 * @param value 전화번호
 */
export function makeHyphenPhoneNumber(value: string): string {
  if (!value) {
    return '';
  }

  const str = value.replace(/[^0-9]/g, '');

  let trimmed = str.replace(/\s+/g, '');

  if (trimmed.length > 11) {
    trimmed = trimmed.substr(0, 11);
  }

  let tmp = '';

  tmp = trimmed
    .replace(/[^0-9]/g, '')
    .replace(
      /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
      '$1-$2-$3'
    )
    .replace('--', '-');

  return tmp;
}

/**
 * 요양기관번호 Hypen추가
 * @param value 요양기관번호
 */
export function makeHypenYnum(value: string): string {
  if (!value) {
    return '';
  }

  let trimmed = value.replace(/\s+/g, '');

  if (trimmed.length > 9) {
    trimmed = trimmed.substr(0, 9);
  }

  trimmed = trimmed.replace(/-/g, '');

  const numbers = [];

  numbers.push(trimmed.substr(0, 4));
  if (trimmed.substr(4, 4) !== '') {
    numbers.push(trimmed.substr(4, 4));
  }

  return numbers.join('-');
}

/**
 * 사업자등록번호 Hypen 추가
 * @param value 사업자등록번호
 */
export function makeHypenCRegNo(value: string): string {
  if (!value) {
    return '';
  }

  const str = value.replace(/[^0-9]/g, '');
  let trimmed = str.replace(/\s+/g, '');

  if (trimmed.length > 12) {
    trimmed = trimmed.substr(0, 12);
  }

  trimmed = trimmed.replace(/-/g, '');

  const numbers = [];

  numbers.push(trimmed.substr(0, 3));
  if (trimmed.substr(3, 2) !== '') {
    numbers.push(trimmed.substr(3, 2));
  }
  if (trimmed.substr(5, 5) !== '') {
    numbers.push(trimmed.substr(5, 5));
  }

  return numbers.join('-');
}

/**
 * comma 추가
 * @param value
 */
export function makeCommaNumber(value: string): string {
  if (!value) {
    return '';
  }

  const str = value.replace(/[^0-9]/g, '');

  return str.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * 안쪽의 - 한개만 남기기
 * @param value
 */
export function makeOnlySingleHypenNum(value: string): string {
  if (!value) {
    return '';
  }

  return value.replace(/[^\d-]/gi, '').replace(/-{2,}/gi, '-');
}
