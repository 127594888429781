<label [ngClass]="{disabled}">
  <input
    type="radio"
    [disabled]="disabled"
    [name]="name"
    [value]="value"
    [checked]="checked"
    (change)="handleChange($event)"
  />
  <i class="circle"></i>
  <span class="content-label"> <ng-content></ng-content> </span>
  <i
    *ngIf="tooltip"
    class="icon icon-question-mark tooltip-icon"
    [ngbTooltip]="tooltip"
    tooltipClass="label-tooltip"
    [placement]="tooltipPlacement"
  ></i>
</label>
