import { createAction, props } from '@ngrx/store';
import {
  CrmStatisticsUiModel,
  StatisticsReqParam,
} from '~models/hospital-crm.model';

export enum CrmStatisticsActionType {
  REQ_FAIL = '[CrmStatistics] Req Fail',
  DEFAULT_LOAD = '[CrmStatistics] Default Statistics Load',
  DEFAULT_LOAD_SUCCESS = '[CrmStatistics] Default Statistics Load Success',
}

export const CrmStatisticsReqFail = createAction(
  CrmStatisticsActionType.REQ_FAIL,
  props<any>()
);

export const CrmStatisticsLoad = createAction(
  CrmStatisticsActionType.DEFAULT_LOAD,
  props<{ payload: StatisticsReqParam }>()
);

export const CrmStatisticsLoadSuccess = createAction(
  CrmStatisticsActionType.DEFAULT_LOAD_SUCCESS,
  props<{ payload: CrmStatisticsUiModel }>()
);

export const CrmStatisticsActions = {
  CrmStatisticsReqFail,
  CrmStatisticsLoad,
  CrmStatisticsLoadSuccess,
};
