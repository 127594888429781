import { Component, OnInit, Input } from '@angular/core';

interface CardGuideImageInlineOptions {
  height?: string;
  width?: string;

  top?: string | number;
  left?: string | number;
  right?: string | number;
  bottom?: string | number;
}

@Component({
  selector: 'app-card-guide',
  templateUrl: './card-guide.component.html',
  styleUrls: ['./card-guide.component.scss'],
})
export class CardGuideComponent implements OnInit {
  @Input() articleStyle = {};
  @Input() imageSrc = '';
  @Input() imageInlineOptions: CardGuideImageInlineOptions;

  constructor() {}

  ngOnInit() {}
}
