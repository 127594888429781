import { Pipe, PipeTransform } from '@angular/core';
import {
  TOSS_SUB_MALL_ERROR_MESSAGE_MAPPER,
  TossErrorKey,
} from '~models/payments.model';

@Pipe({
  name: 'tossError',
})
export class TossErrorPipe implements PipeTransform {
  transform(value: TossErrorKey): string {
    return TOSS_SUB_MALL_ERROR_MESSAGE_MAPPER[value];
  }
}
