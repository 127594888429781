import {
  CellIndex,
  CellIndexRange,
  HourItem,
  TimeCell,
  TimeCellPreventInfo,
  TimelinePreventReason,
  TimeRange,
  TimeRow,
} from '~models/reservation-timeline.model';
import { OfficeWeeklyDetailData } from '~models/care-room.model';
import { makeNumberArray } from '~shared/service/util';

export function clearCell(cell: TimeCell): TimeCell {
  try {
    cell.start = -1;
    cell.end = -1;
    cell.count = 0;
    // cell.reason = TimelinePreventReason.NONE;
    cell.text = '';
    cell.color = '';
  } catch (error) {}

  return cell;
}

export function getCell(): TimeCell {
  return {
    text: '',
    color: '',
    count: 0,
    start: -1,
    end: -1,
    reason: TimelinePreventReason.NONE,
  };
}

function getCells(count: number): TimeCell[] {
  const cell = getCell();
  const aRet = [];

  for (let i = 0; i < count; i += 1) {
    aRet.push({ ...cell });
  }

  return aRet;
}

export function clearTimeRows(rows: TimeRow[]) {
  rows.forEach(row => row.cells.forEach(clearCell));

  return rows;
}

export function getRows(rowCount: number, colCount: number) {
  const aRet: TimeRow[] = [];

  for (let row = 0; row < rowCount; row += 1) {
    aRet.push({
      cells: getCells(colCount),
    });
  }

  return aRet;
}

function toCellIndex(
  hourList: number[],
  minList: number[],
  timeStr: string,
  timeUnit: number = 5
): CellIndex {
  const aStart = timeStr.split(':');
  const iHour = parseInt(aStart[0], 10);
  let iMinute = parseInt(aStart[1], 10);

  iMinute = iMinute - (iMinute % timeUnit);

  const row = hourList.indexOf(iHour);
  const col = minList.indexOf(iMinute);

  // if (col < 0) {
  //   console.log(iHour, iMinute);
  // }

  return {
    row,
    col,
  };
}

function toCellIndexRange(
  hourList: number[],
  minList: number[],
  data: TimeRange
): CellIndexRange | undefined {
  try {
    return {
      start: toCellIndex(hourList, minList, data.startTime),
      end: toCellIndex(hourList, minList, data.endTime),
    };
  } catch (error) {
    return undefined;
  }
}

// hourItems: HourItem[],
// toTimeCellPreventInfo

function toTimeCellPreventInfo(
  hourList: number[],
  minList: number[],
  data: OfficeWeeklyDetailData
): TimeCellPreventInfo {
  return {
    isDayOff: data.isDayOff,
    hasDinnerBreak: data.hasDinnerBreak,
    // hasLunchBreak: true,
    hasLunchBreak: data.hasLunchBreak,
    dinner: toCellIndexRange(hourList, minList, data.dinner),
    lunch: toCellIndexRange(hourList, minList, data.lunch),
    end: toCellIndex(hourList, minList, data.endTime),
    start: toCellIndex(hourList, minList, data.startTime),
  };
}

function hourItemsToHourList(hourItems: HourItem[]) {
  const hourList: number[] = [];

  hourItems.forEach(item => {
    hourList.push(item.raw);
  });

  return hourList;
}

export function convertTimeCellPreventInfoMap(
  hourItems: HourItem[],
  data: { [key: string]: OfficeWeeklyDetailData },
  timeUnit: number = 5
) {
  const hourList = hourItemsToHourList(hourItems);
  const minList = makeNumberArray(0, 55, timeUnit);
  const mRet: { [key: string]: TimeCellPreventInfo } = {};

  Object.keys(data).forEach(key => {
    mRet[key] = toTimeCellPreventInfo(hourList, minList, data[key]);
  });

  return mRet;
}

export function isCellPositionInside(info: CellIndexRange, index: number) {
  try {
    if (!info) {
      return false;
    }

    const start = info.start.row * 12 + info.start.col;
    const end = info.end.row * 12 + info.end.col;

    return index >= start && index < end;
  } catch (error) {
    return false;
  }
}

export function isCellPositionOutside(info: CellIndexRange, index: number) {
  const start = info.start.row * 12 + info.start.col;
  const end = info.end.row * 12 + info.end.col;

  return index < start || index >= end;
}
