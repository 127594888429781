import { createAction, props } from '@ngrx/store';
import {
  ReceptionPenaltyResDetail,
  ReceptionPenaltyResItem,
  StatusPenaltySearchUiQuery,
} from '~models/status-penalty.model';
import { ListDataRes } from '~models/common';

export const StatusPenaltyActionTypes = {
  LOAD_LIST: 'STATUS_PENALTY - LOAD_LIST',
  LOAD_LIST_SUCCESS: 'STATUS_PENALTY - LOAD_LIST_SUCCESS',
  DOWNLOAD_LIST: 'STATUS_PENALTY - DOWNLOAD_LIST',
  LOAD_DETAIL: 'STATUS_PENALTY - LOAD_DETAIL',
  LOAD_DETAIL_SUCCESS: 'STATUS_PENALTY - LOAD_DETAIL_SUCCESS',
  REMOVE_PENALTY: 'STATUS_PENALTY - REMOVE_PENALTY',
  REMOVE_PENALTY_SUCCESS: 'STATUS_PENALTY - REMOVE_PENALTY_SUCCESS',
  FAIL: 'STATUS_PENALTY - FAIL',
} as const;

export const StatusPenaltyLoadList = createAction(
  StatusPenaltyActionTypes.LOAD_LIST,
  props<{ payload: StatusPenaltySearchUiQuery }>()
);

export const StatusPenaltyLoadListSuccess = createAction(
  StatusPenaltyActionTypes.LOAD_LIST_SUCCESS,
  props<{ payload: ListDataRes<ReceptionPenaltyResItem> }>()
);

export const StatusPenaltyDownloadList = createAction(
  StatusPenaltyActionTypes.DOWNLOAD_LIST,
  props<{ payload: StatusPenaltySearchUiQuery }>()
);

export const StatusPenaltyLoadDetail = createAction(
  StatusPenaltyActionTypes.LOAD_DETAIL,
  props<{ payload: string }>()
);

export const StatusPenaltyLoadDetailSuccess = createAction(
  StatusPenaltyActionTypes.LOAD_DETAIL_SUCCESS,
  props<{ payload: ReceptionPenaltyResDetail }>()
);

export const StatusPenaltyRemovePenalty = createAction(
  StatusPenaltyActionTypes.REMOVE_PENALTY,
  props<{ payload: string }>()
);

export const StatusPenaltyRemovePenaltySuccess = createAction(
  StatusPenaltyActionTypes.REMOVE_PENALTY_SUCCESS,
  props<{ payload: ReceptionPenaltyResItem[] }>()
);

export const StatusPenaltyFail = createAction(
  StatusPenaltyActionTypes.FAIL,
  props<any>()
);

export const StatusPenaltyActions = {
  StatusPenaltyLoadList,
  StatusPenaltyLoadListSuccess,
  StatusPenaltyDownloadList,
  StatusPenaltyLoadDetail,
  StatusPenaltyLoadDetailSuccess,
  StatusPenaltyRemovePenalty,
  StatusPenaltyRemovePenaltySuccess,
  StatusPenaltyFail,
};
