<div class="ha-card ha-card-radius">
  <div class="ha-card-body inner">
    <div class="inner__left">
      <app-circle-icon [icon]="icon"></app-circle-icon>
      <div class="text-wrap">
        <div class="text-wrap__title">{{ title }}</div>
        <span class="text-wrap__desc">{{ description }}</span>
      </div>
    </div>
    <div class="inner__right">
      <button
        type="button"
        class="ha-btn ha-btn-gray-secondary"
        (click)="handleChange(true)"
      >
        {{ label }}
      </button>
    </div>
  </div>
  <div class="ha-card-body footer" *ngIf="hasFooter">
    <ng-container [ngSwitch]="footerDescriptionType">
      <div *ngSwitchCase="serviceUserPanelDescriptionType.ELEMENTS">
        <ng-content></ng-content>
      </div>
      <span *ngSwitchCase="serviceUserPanelDescriptionType.NONE">{{
        footerDescription
      }}</span>
    </ng-container>
  </div>
</div>
