import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { FaqActions } from '~actions/products/faq.actions';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { FaqService } from '~core/api/faq.service';
import { select, Store } from '@ngrx/store';
import { AuthState } from '~reducers/auth/auth.reducer';
import { getUserAgentType } from '~selectors/home.selectors';
import { of } from 'rxjs';
import { toFAQ, toFaqQuery, toFixedFAQ } from '../../services/faq/faq.parser';

@Injectable()
export class FaqEffects {
  constructor(
    private actions$: Actions,
    private faqService: FaqService,
    private authStore: Store<AuthState>
  ) {}

  fetchCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FaqActions.FaqCategoriesLoad),
      exhaustMap(() =>
        this.faqService.fetchCategories().pipe(
          map(categories =>
            FaqActions.FaqCategoriesLoadSuccess({ payload: categories })
          ),
          catchError(error => of(FaqActions.FaqReqFail({ payload: error })))
        )
      )
    )
  );

  fetchFaqList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FaqActions.FaqListLoad),
      concatLatestFrom(() => this.authStore.pipe(select(getUserAgentType))),
      exhaustMap(([{ payload: params }, agentType]) => {
        const queryString = toFaqQuery({ ...params, agentType });
        return this.faqService
          .fetchFaqs(queryString)
          .pipe(
            map(faqRes =>
              FaqActions.FaqListLoadSuccess({ payload: toFAQ(faqRes) })
            )
          );
      }),
      catchError(error => of(FaqActions.FaqReqFail({ payload: error })))
    )
  );

  fetchFixedFaqList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FaqActions.FixedFaqListLoad),
      concatLatestFrom(() => this.authStore.pipe(select(getUserAgentType))),
      exhaustMap(([{ payload }, agentType]) => {
        const queryString = toFaqQuery({ ...payload, agentType, skip: 0 });
        return this.faqService.fetchFaqs(queryString).pipe(
          map(faqRes =>
            FaqActions.FixedFaqListLoadSuccess({
              payload: toFixedFAQ(faqRes),
            })
          )
        );
      }),
      catchError(error => of(FaqActions.FaqReqFail({ payload: error })))
    )
  );
}
