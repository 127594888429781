<div
  class="ha-dropdown"
  [ngStyle]="styles"
  [ngClass]="[widthClass, dirClass, placementClass]"
>
  <button
    type="button"
    class="ha-btn ha-dropdown-btn"
    #dropdownBtn
    [disabled]="disabled"
    [ngClass]="[sizeClass, placeholderClass, textAlignClass]"
    (click)="onClickDropdown()"
  >
    <span
      [innerHTML]="(showSelected ? selectedText : placeholder) | boldText"
    ></span>
    <span *ngIf="!!badge" class="badge badge-primary">{{ badge }}</span>
  </button>
  <div
    #dropdownList
    class="ha-dropdown-list dd-list"
    [ngClass]="{ active: isShow }"
  >
    <div [ngClass]="scrollableClass">
      <button
        type="button"
        class="ha-btn ha-dropdown-item"
        *ngFor="let item of items"
        (click)="onClickItem(item)"
      >
        {{ item.text }}
        <span *ngIf="!!item.badge" class="badge badge-primary">{{
          item.badge
        }}</span>
      </button>
    </div>
  </div>
</div>
