import { Component, Input, OnInit } from '@angular/core';
import { CarouselItem } from '~models/signin-carousel.model';

@Component({
  selector: 'app-signin-scroll-contents',
  templateUrl: './signin-scroll-contents.component.html',
  styleUrls: ['./signin-scroll-contents.component.scss'],
})
export class SigninScrollContentsComponent implements OnInit {
  @Input() items: CarouselItem;

  @Input() currentTabIdx: number;

  ngOnInit(): void {}
}
