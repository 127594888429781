import { Injectable } from '@angular/core';
import { HttpRes } from '~models/common.model';
import {
  ServiceVideoModel,
  ServiceVideoQueryParams,
} from '~models/service-video.model';
import { Observable } from 'rxjs/';
import { environment } from '~root/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { get as _get } from 'lodash';
import * as moment from 'moment';

const API_URL = environment.voluntary_url;

@Injectable({
  providedIn: 'root',
})
export class ServiceVideoService {
  constructor(private http: HttpClient) {}

  /**
   * 서비스동영상 조회
   * @param params
   */
  fetchServiceVideo(
    params?: ServiceVideoQueryParams
  ): Observable<HttpRes<ServiceVideoModel>> {
    const URL = 'v2/service-introduction-video';

    const httpParams = (() => {
      let initialHttpParams = new HttpParams({
        fromObject: {
          _timestamp: moment()
            .valueOf()
            .toString(),
        },
      });
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const value = _get(params, key, undefined);
          initialHttpParams = initialHttpParams.set(key, value);
        }
      }
      return initialHttpParams;
    })();

    return this.http.get<HttpRes<ServiceVideoModel>>(`${API_URL}/${URL}`, {
      params: httpParams,
    });
  }
}
