import { createAction, props } from '@ngrx/store';
import {
  KioskQuestionOptionUIModel,
  QuestionDetailResItem,
  QuestionDetailUpdateParams,
  QuestionnaireDetailParams,
  QuestionnaireOrderUpdateParams,
  QuestionnaireRes,
  QuestionnaireResItem,
  QuestionnaireTargetCreateReq,
  QuestionnaireTargetReq,
  QuestionOptionRes,
  QuestionSubjectResItem,
} from '~models/question.model';

export const enum KioskQuestionActionType {
  KIOSK_QUESTION_REQUEST_FAIL = 'QUESTION_REQUEST_FAIL',
  FETCH_KIOSK_QUESTION_LIST = 'FETCH_QUESTION_LIST',
  FETCH_KIOSK_QUESTION_LIST_SUCCESS = 'FETCH_QUESTION_LIST_SUCCESS',
  TOGGLE_KIOSK_QUESTION = 'TOGGLE_KIOSK_QUESTION',
  TOGGLE_KIOSK_QUESTION_SUCCESS = 'TOGGLE_KIOSK_QUESTION_SUCCESS',
  FETCH_KIOSK_QUESTION_SYMPTOM_LIST = 'FETCH_KIOSK_QUESTION_SYMPTOM_LIST',
  FETCH_KIOSK_QUESTION_SYMPTOM_LIST_SUCCESS = 'FETCH_KIOSK_QUESTION_SYMPTOM_LIST_SUCCESS',
  CREATE_KIOSK_QUESTION = 'CREATE_KIOSK_QUESTION',
  CREATE_KIOSK_QUESTION_SUCCESS = 'CREATE_KIOSK_QUESTION_SUCCESS',
  DELETE_KIOSK_QUESTION = 'DELETE_KIOSK_QUESTION',
  DELETE_KIOSK_QUESTION_SUCCESS = 'DELETE_KIOSK_QUESTION_SUCCESS',
  UPDATE_KIOSK_QUESTION = 'UPDATE_KIOSK_QUESTION',
  UPDATE_KIOSK_QUESTION_SUCCESS = 'UPDATE_KIOSK_QUESTION_SUCCESS',
  FETCH_KIOSK_QUESTION_OPTIONS = 'FETCH_KIOSK_QUESTION_OPTIONS',
  FETCH_KIOSK_QUESTION_OPTIONS_SUCCESS = 'FETCH_KIOSK_QUESTION_OPTIONS_SUCCESS',
  CHANGE_KIOSK_QUESTION_OPTION = 'CHANGE_KIOSK_QUESTION_OPTION',
  ADD_KIOSK_QUESTION_OPTION = 'ADD_KIOSK_QUESTION_OPTION',
  REMOVE_KIOSK_QUESTION_OPTION = 'REMOVE_KIOSK_QUESTION_OPTION',
  REMOVE_KIOSK_QUESTION_OPTION_SUCCESS = 'REMOVE_KIOSK_QUESTION_OPTION_SUCCESS',
  CHANGE_KIOSK_QUESTION_OPTION_ITEM_COUNT = 'CHANGE_KIOSK_QUESTION_OPTION_ITEM_COUNT',
  UPDATE_KIOSK_QUESTION_OPTIONS = 'UPDATE_KIOSK_QUESTION_OPTIONS',
  UPDATE_KIOSK_QUESTION_OPTIONS_SUCCESS = 'UPDATE_KIOSK_QUESTION_OPTIONS_SUCCESS',
  CHANGE_ORDER_KIOSK_QUESTION = 'CHANGE_ORDER_KIOSK_QUESTION',
  CHANGE_ORDER_KIOSK_QUESTION_SUCCESS = 'CHANGE_ORDER_KIOSK_QUESTION_SUCCESS',
  IMPORT_KIOSK_QUESTION_OPTIONS = 'IMPORT_KIOSK_QUESTION_OPTIONS',
  FETCH_ALL_QUESTION_OPTIONS = 'FETCH_ALL_QUESTION_OPTIONS',
  FETCH_ALL_QUESTION_OPTIONS_SUCCESS = 'FETCH_ALL_QUESTION_OPTIONS_SUCCESS',
}

export const KioskQuestionRequestFail = createAction(
  KioskQuestionActionType.KIOSK_QUESTION_REQUEST_FAIL,
  props<{ error: any }>()
);

export const KioskQuestionList = createAction(
  KioskQuestionActionType.FETCH_KIOSK_QUESTION_LIST,
  props<{ params: { unitKey: string } }>()
);

export const KioskQuestionListSuccess = createAction(
  KioskQuestionActionType.FETCH_KIOSK_QUESTION_LIST_SUCCESS,
  props<{ payload: QuestionnaireRes }>()
);

export const KioskQuestionToggle = createAction(
  KioskQuestionActionType.TOGGLE_KIOSK_QUESTION,
  props<{ params: { unitKey: string; useQuestion: boolean } }>()
);

export const KioskQuestionToggleSuccess = createAction(
  KioskQuestionActionType.TOGGLE_KIOSK_QUESTION_SUCCESS,
  props<{ payload: { useQuestion: boolean } }>()
);

export const KioskQuestionSymptomList = createAction(
  KioskQuestionActionType.FETCH_KIOSK_QUESTION_SYMPTOM_LIST,
  props<{ params: { unitKey: string } }>()
);

export const KioskQuestionSymptomListSuccess = createAction(
  KioskQuestionActionType.FETCH_KIOSK_QUESTION_SYMPTOM_LIST_SUCCESS,
  props<{ payload: QuestionSubjectResItem[] }>()
);

export const KioskQuestionCreate = createAction(
  KioskQuestionActionType.CREATE_KIOSK_QUESTION,
  props<{ params: QuestionnaireTargetCreateReq }>()
);

export const KioskQuestionCreateSuccess = createAction(
  KioskQuestionActionType.CREATE_KIOSK_QUESTION_SUCCESS,
  props<{ unitKey: string }>()
);

export const KioskQuestionDelete = createAction(
  KioskQuestionActionType.DELETE_KIOSK_QUESTION,
  props<{ params: { questionKey: string } }>()
);

export const KioskQuestionDeleteSuccess = createAction(
  KioskQuestionActionType.DELETE_KIOSK_QUESTION_SUCCESS,
  props<{ payload: { questionKey: string } }>()
);

export const KioskQuestionUpdate = createAction(
  KioskQuestionActionType.UPDATE_KIOSK_QUESTION,
  props<{ params: QuestionnaireTargetReq }>()
);

export const KioskQuestionUpdateSuccess = createAction(
  KioskQuestionActionType.UPDATE_KIOSK_QUESTION_SUCCESS,
  props<{ payload: QuestionnaireTargetReq }>()
);

export const KioskQuestionOptions = createAction(
  KioskQuestionActionType.FETCH_KIOSK_QUESTION_OPTIONS,
  props<{ params: QuestionnaireDetailParams }>()
);

export const KioskQuestionOptionsSuccess = createAction(
  KioskQuestionActionType.FETCH_KIOSK_QUESTION_OPTIONS_SUCCESS,
  props<{ payload: QuestionOptionRes }>()
);

export const KioskQuestionOptionChange = createAction(
  KioskQuestionActionType.CHANGE_KIOSK_QUESTION_OPTION,
  props<{
    id: string;
    changes: Partial<KioskQuestionOptionUIModel>;
  }>()
);

export const KioskQuestionOptionAdd = createAction(
  KioskQuestionActionType.ADD_KIOSK_QUESTION_OPTION
);

export const KioskQuestionOptionRemove = createAction(
  KioskQuestionActionType.REMOVE_KIOSK_QUESTION_OPTION,
  props<{ id: string }>()
);

export const KioskQuestionOptionRemoveSuccess = createAction(
  KioskQuestionActionType.REMOVE_KIOSK_QUESTION_OPTION_SUCCESS,
  props<{ id: string }>()
);

export const KioskQuestionOptionItemCountChange = createAction(
  KioskQuestionActionType.CHANGE_KIOSK_QUESTION_OPTION_ITEM_COUNT,
  props<{ id: string; optionCount: number }>()
);

export const KioskQuestionOptionUpdate = createAction(
  KioskQuestionActionType.UPDATE_KIOSK_QUESTION_OPTIONS,
  props<{ params: QuestionDetailUpdateParams }>()
);

export const KioskQuestionOptionUpdateSuccess = createAction(
  KioskQuestionActionType.UPDATE_KIOSK_QUESTION_OPTIONS_SUCCESS,
  props<{ unitKey: string }>()
);

export const KioskQuestionChangeOrder = createAction(
  KioskQuestionActionType.CHANGE_ORDER_KIOSK_QUESTION,
  props<{ params: QuestionnaireOrderUpdateParams }>()
);

export const KioskQuestionChangeOrderSuccess = createAction(
  KioskQuestionActionType.CHANGE_ORDER_KIOSK_QUESTION_SUCCESS,
  props<{ unitKey: string }>()
);

export const KioskQuestionImportOptions = createAction(
  KioskQuestionActionType.IMPORT_KIOSK_QUESTION_OPTIONS,
  props<{ options: QuestionDetailResItem[] }>()
);

export const KioskQuestionAllOptionList = createAction(
  KioskQuestionActionType.FETCH_ALL_QUESTION_OPTIONS
);

export const KioskQuestionAllOptionListSuccess = createAction(
  KioskQuestionActionType.FETCH_ALL_QUESTION_OPTIONS_SUCCESS,
  props<{ payload: QuestionnaireResItem[] }>()
);

export const KioskQuestionActions = {
  KioskQuestionRequestFail,
  KioskQuestionList,
  KioskQuestionListSuccess,
  KioskQuestionToggle,
  KioskQuestionToggleSuccess,
  KioskQuestionSymptomList,
  KioskQuestionSymptomListSuccess,
  KioskQuestionCreate,
  KioskQuestionCreateSuccess,
  KioskQuestionDelete,
  KioskQuestionDeleteSuccess,
  KioskQuestionUpdate,
  KioskQuestionUpdateSuccess,
  KioskQuestionOptions,
  KioskQuestionOptionsSuccess,
  KioskQuestionOptionChange,
  KioskQuestionOptionAdd,
  KioskQuestionOptionRemove,
  KioskQuestionOptionRemoveSuccess,
  KioskQuestionOptionItemCountChange,
  KioskQuestionOptionUpdate,
  KioskQuestionOptionUpdateSuccess,
  KioskQuestionChangeOrder,
  KioskQuestionChangeOrderSuccess,
  KioskQuestionImportOptions,
  KioskQuestionAllOptionList,
  KioskQuestionAllOptionListSuccess,
};
