import { Pipe, PipeTransform } from '@angular/core';
import { ChildScreeningResItem } from '../../models/child-screening.model';

const MAP_STATE_CLASS = {
  RESERVATION_CONFIRM: 'success',
  RESERVATION_RETRACT: 'rosy-pink',
  RESERVATION_CANCEL: 'rosy-pink',
  RESERVATION_AUTO_CANCEL: 'warning',
  RECEIVE: 'success',
  COMPLETE: 'info',
  PENDING: 'dark-gray',
  RESERVATION_RECEIPT: 'pink-swan'
};

@Pipe({
  name: 'resStateClass'
})
export class ResStateClassPipe implements PipeTransform {
  transform(item: ChildScreeningResItem): string {
    const state = item.state;
    const ret = MAP_STATE_CLASS[state];
    const sClassPrefix = 'bg-';

    if (!ret) {
      return sClassPrefix + 'default';
    }

    if (state === 'RESERVATION_CANCEL') {
      if (item.isAutoCancel) {
        return sClassPrefix + MAP_STATE_CLASS.RESERVATION_AUTO_CANCEL;
      }
    }

    return sClassPrefix + ret;
  }
}
