import { Subject } from 'rxjs/';
import { CareRoom } from '~models/care-room.model';
import { monitoringStatus } from '~models/hospital.model';
import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-re-wait-option-modal',
  template: `
    <div class="modal-header ha-modal-header">
      <div class="modal-title-wrap">
        <h5 class="modal-title">대기자 노출 옵션 설정</h5>
        <p class="modal-title-desc">
          똑닥에 노출되는 대기자 노출 방식을 선택해주세요.
        </p>
      </div>
    </div>

    <div class="modal-body">
      <!-- box -->
      <div class="ha-box-wrap">
        <div class="ha-box-item">
          <div class="title-area"><p>설정선택</p></div>
          <div class="setting-area">
            <div ngbDropdown>
              <button
                class="ha-dropdown-toggle form-control w-100"
                ngbDropdownToggle
              >
                {{ getStatusText(type) }}
              </button>
              <div ngbDropdownMenu class="w-100">
                <button
                  [ngClass]="{ active: subject.type === type }"
                  class="dropdown-item"
                  *ngFor="let subject of monitoring"
                  (click)="changeSelect(subject.type)"
                >
                  {{ subject.text }}
                </button>
              </div>
            </div>

            <p class="mt-2">{{ getStatusDescription(type) }}</p>
          </div>
        </div>
      </div>
      <!--// box -->

      <!-- box -->
      <div class="ha-box-wrap" *ngIf="isWaitTime(type)">
        <div class="ha-box-item">
          <div class="title-area"></div>
          <div class="setting-area">
            <div
              class="form-inline careroomList justify-content-between"
              *ngFor="let room of careRooms; let i = index"
              [ngClass]="{ 'mt-2': i > 0 }"
            >
              <span class="text-wrap">{{ room.title }}</span>
              <div>
                <input
                  type="number"
                  class="form-control"
                  [(ngModel)]="room.avgWaitTime"
                />
                <span>분</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--// box -->
    </div>
    <!--// modal-body -->

    <div class="modal-footer">
      <button
        type="button"
        class="ha-btn ha-btn-secondary"
        (click)="activeModal.close('cancel')"
      >
        닫기
      </button>
      <button
        type="button"
        class="ha-btn ha-btn-primary"
        (click)="updateOptions()"
      >
        설정 적용
      </button>
    </div>
  `,
  styles: [
    `
      .careroomList input[type='number'] {
        width: 46px;
        text-align: center;
        margin: 0 5px 0 10px;
      }

      .text-wrap {
        overflow-wrap: break-word;
        word-wrap: break-word;
        max-width: 180px;
      }

      @media (max-width: 576px) {
        .modal-body {
          padding-left: 24px;
          padding-right: 24px;
        }
      }
    `,
  ],
})
export class ReceptionWaitOptionModalComponent {
  monitoring = monitoringStatus;
  type = 'UNDER_FIVE';
  careRooms: CareRoom[];

  waitOptionSubject = new Subject<any>();

  changeSelect(type) {
    this.type = type;
  }

  isWaitTime(type: string) {
    return type ? type === this.monitoring[1].type : false;
  }

  updateOptions() {
    this.type === this.monitoring[1].type
      ? this.waitOptionSubject.next({
          type: this.type,
          careRooms: this.careRooms,
        })
      : this.waitOptionSubject.next({ type: this.type });
  }

  getStatusDescription(type: string) {
    return type
      ? this.monitoring.filter(v => v.type === type)[0].description
      : '설정되지 않았습니다.';
  }

  getStatusText(type: string) {
    return type
      ? this.monitoring.filter(v => v.type === type)[0].text
      : '설정 선택';
  }

  constructor(public activeModal: NgbActiveModal) {}
}
