import { createAction, props } from '@ngrx/store';
import {
  HealthScreeningModel,
  HealthScreeningItemsModel,
  UpdateQuestionnaireRequestModel,
  UpdateReservationRequestModel,
  UpdateHospitalInfoRequestModel,
} from '~models/health-screening.model';
import { ImageUrlInfo } from '~models/common.model';

const HEALTH_SCREENING = 'Health Screening' as const;

export const HealthScreeningActionType = {
  FETCH_HEALTH_SCREENING_INFO: `${[HEALTH_SCREENING]} Fetch Info`,
  FETCH_HEALTH_SCREENING_INFO_SUCCESS: `${[
    HEALTH_SCREENING,
  ]} Fetch Info Success`,

  FETCH_EXAMINATION_ITEMS: `${[HEALTH_SCREENING]} Fetch Examination Items`,
  FETCH_EXAMINATION_ITEMS_SUCCESS: `${[
    HEALTH_SCREENING,
  ]} Fetch Examination Items Success`,

  FETCH_LIFE_STYLE_ITEMS: `${[HEALTH_SCREENING]} Fetch Life Style Items`,
  FETCH_LIFE_STYLE_ITEMS_SUCCESS: `${[
    HEALTH_SCREENING,
  ]} Fetch Life Style Items Success`,

  POST_HEALTH_SCREENING_INFO: `${[HEALTH_SCREENING]} Update Info`,
  POST_HEALTH_SCREENING_INFO_SUCCESS: `${[HEALTH_SCREENING]} Update Info Fail`,

  UPDATE_EXAMINATION_ITEMS: `${[HEALTH_SCREENING]} Update Examination Items`,
  UPDATE_QUESTIONNAIRE_SETTING: `${[
    HEALTH_SCREENING,
  ]} Update Questionnaire Setting`,
  UPDATE_RESERVATION_SETTING: `${[
    HEALTH_SCREENING,
  ]} Update Reservation Setting`,
  UPDATE_HOSPITAL_INFO: `${[HEALTH_SCREENING]} Update Hospital Info`,

  USE_CHANGE: `${[HEALTH_SCREENING]} Use Change`,
  USE_CHANGE_SUCCESS: `${[HEALTH_SCREENING]} Use Change Success`,

  UPLOAD_HOSPITAL_LOGO: `${[HEALTH_SCREENING]} Upload Hospital Logo`,
  UPLOAD_HOSPITAL_LOGO_SUCCESS: `${[
    HEALTH_SCREENING,
  ]} Upload Hospital Logo Success`,

  DELETE_HOSPITAL_LOGO: `${[HEALTH_SCREENING]} Delete Hospital Logo`,
  DELETE_HOSPITAL_LOGO_SUCCESS: `${[
    HEALTH_SCREENING,
  ]} Delete Hospital Logo Success`,

  UPDATE_SUCCESS: `${[HEALTH_SCREENING]} Update Success`,
  ACTION_FAIL: `${[HEALTH_SCREENING]} Action Fail`,
};

// 검진 예약 설정 가져오기
export const FetchHealthScreeningInfo = createAction(
  HealthScreeningActionType.FETCH_HEALTH_SCREENING_INFO
);
export const FetchHealthScreeningInfoSuccess = createAction(
  HealthScreeningActionType.FETCH_HEALTH_SCREENING_INFO_SUCCESS,
  props<{ healthScreeningInfo: HealthScreeningModel }>()
);

// 검진 예약 설정 ON
export const PostHealthScreeningInfo = createAction(
  HealthScreeningActionType.POST_HEALTH_SCREENING_INFO
);
export const PostHealthScreeningInfoSuccess = createAction(
  HealthScreeningActionType.POST_HEALTH_SCREENING_INFO_SUCCESS
);

// 검진 문진 목록 가져오기
export const FetchExaminationItems = createAction(
  HealthScreeningActionType.FETCH_EXAMINATION_ITEMS
);
export const FetchExaminationItemsSuccess = createAction(
  HealthScreeningActionType.FETCH_EXAMINATION_ITEMS_SUCCESS,
  props<{ examinationItems: HealthScreeningItemsModel[] }>()
);

// 생활 습관 목록 가져오기
export const FetchLifeStyleItems = createAction(
  HealthScreeningActionType.FETCH_LIFE_STYLE_ITEMS
);
export const FetchLifeStyleItemsSuccess = createAction(
  HealthScreeningActionType.FETCH_LIFE_STYLE_ITEMS_SUCCESS,
  props<{ lifestyleItems: HealthScreeningItemsModel[] }>()
);

// 검진 항목 설정(검진 문진 목록) 업데이트
export const UpdateExaminationItems = createAction(
  HealthScreeningActionType.UPDATE_EXAMINATION_ITEMS,
  props<{ examinationCategories: string[] }>()
);
// 검진 문진표 발송 설정 업데이트
export const UpdateQuestionnaireSetting = createAction(
  HealthScreeningActionType.UPDATE_QUESTIONNAIRE_SETTING,
  props<{
    payload: UpdateQuestionnaireRequestModel;
  }>()
);
// 검진 예약 설정 업데이트
export const UpdateReservationSetting = createAction(
  HealthScreeningActionType.UPDATE_RESERVATION_SETTING,
  props<{
    payload: UpdateReservationRequestModel;
  }>()
);
// 검진 결과지 설정(병원 정보) 업데이트
export const UpdateHospitalInfo = createAction(
  HealthScreeningActionType.UPDATE_HOSPITAL_INFO,
  props<{
    payload: UpdateHospitalInfoRequestModel;
  }>()
);

export const HealthScreeningUseChange = createAction(
  HealthScreeningActionType.USE_CHANGE,
  props<{ useHealthScreening: boolean }>()
);

export const HealthScreeningUseChangeSuccess = createAction(
  HealthScreeningActionType.USE_CHANGE_SUCCESS,
  props<{ payload: { useHealthScreening: boolean } }>()
);

export const HealthScreeningUploadHospitalLogo = createAction(
  HealthScreeningActionType.UPLOAD_HOSPITAL_LOGO,
  props<{ file: File }>()
);

export const HealthScreeningUploadHospitalLogoSuccess = createAction(
  HealthScreeningActionType.UPLOAD_HOSPITAL_LOGO_SUCCESS,
  props<{ logo: ImageUrlInfo }>()
);

export const HealthScreeningDeleteHospitalLogo = createAction(
  HealthScreeningActionType.DELETE_HOSPITAL_LOGO
);
export const HealthScreeningDeleteHospitalLogoSuccess = createAction(
  HealthScreeningActionType.DELETE_HOSPITAL_LOGO_SUCCESS
);

export const HealthScreeningUpdateSuccess = createAction(
  HealthScreeningActionType.UPDATE_SUCCESS
);

export const HealthScreeningActionFail = createAction(
  HealthScreeningActionType.ACTION_FAIL,
  props<{ message: string }>()
);

export const HealthScreeningActions = {
  FetchHealthScreeningInfo,
  FetchHealthScreeningInfoSuccess,

  PostHealthScreeningInfo,
  PostHealthScreeningInfoSuccess,

  FetchExaminationItems,
  FetchExaminationItemsSuccess,

  FetchLifeStyleItems,
  FetchLifeStyleItemsSuccess,

  UpdateExaminationItems,
  UpdateQuestionnaireSetting,
  UpdateReservationSetting,
  UpdateHospitalInfo,

  HealthScreeningUseChange,
  HealthScreeningUseChangeSuccess,

  HealthScreeningUploadHospitalLogo,
  HealthScreeningUploadHospitalLogoSuccess,
  HealthScreeningDeleteHospitalLogo,
  HealthScreeningDeleteHospitalLogoSuccess,

  HealthScreeningUpdateSuccess,
  HealthScreeningActionFail,
};
