<div
  class="ham-overlay"
  [ngClass]="{ 'ham-show': isOpen() }"
  (click)="clickOverlay($event)"
>
  <div class="ham-inner-t">
    <div class="ham-inner-c">
      <div class="ham-section" (click)="$event.stopPropagation()">
        <h4 class="ham-head" [innerText]="title"></h4>
        <pre class="ham-body" [innerHTML]="desc | sanitizeHtml"></pre>
        <footer class="ham-footer" *ngIf="!isRetreatMode()">
          <button
            *ngIf="isConfirmMode()"
            type="button"
            class="ha-btn ha-btn-secondary"
            (click)="close($event, false)"
          >
            {{ cancelText }}
          </button>
          <button
            id="btnAccept"
            type="button"
            class="ha-btn ha-btn-primary"
            (click)="close($event, true)"
          >
            {{ okText }}
          </button>
        </footer>
        <footer class="ham-footer" *ngIf="isRetreatMode()">
          <button
            id="btnRetreat"
            type="button"
            class="ha-btn ha-btn-secondary"
            (click)="close($event, false)"
          >
            되돌아가기
          </button>
          <button
            id="btnElseWhere"
            type="button"
            class="ha-btn ha-btn-primary"
            (click)="close($event, true)"
          >
            {{ elseWhereText }}
          </button>
        </footer>
      </div>
    </div>
  </div>
</div>
