import { update as _update } from 'lodash';
import { HospitalSettingActions } from '~actions/products/hospital-setting.actions';
import { HospitalSettingModel } from '~models/hospital.model';
import { errorMessage } from '~shared/service/util';
import { createReducer, on } from '@ngrx/store';

export interface HosSettingState {
  loading: boolean;
  loaded: boolean;
  error: string | null;
  data: HospitalSettingModel;
}

const initialState: HosSettingState = {
  loaded: false,
  loading: false,
  error: null,
  data: {} as HospitalSettingModel,
};

export const reducer = createReducer(
  initialState,
  on(HospitalSettingActions.HosSettingLoad, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(HospitalSettingActions.HosSettingLoadSuccess, (state, action) => ({
    data: action.payload.setting,
    loading: false,
    loaded: true,
    error: null,
  })),
  on(HospitalSettingActions.HosSettingLoadFail, (state, action) => ({
    ...state,
    loading: false,
    error: errorMessage(action.payload, 'hosset.red43'),
    loaded: false,
  })),
  on(HospitalSettingActions.ExamInfantsSettingUpdateSuccess, state => ({
    ...state,
    loading: false,
    error: null,
    loaded: false,
  })),
  on(HospitalSettingActions.OverlapOptionUpdateSuccess, (state, action) => ({
    ...state,
    data: _update(
      state.data,
      'mobileEnvironment.reservation.allowDuplicates',
      () => action.payload
    ),
    loading: false,
    error: null,
    loaded: false,
  })),
  on(
    HospitalSettingActions.HosOptionUpdateSuccess,
    (state, { payload: { update } }) => {
      const settingUpdater = update as HospitalSettingModel;
      return {
        ...state,
        data: {
          ...state.data,
          ...settingUpdater,
        },
      };
    }
  ),
  on(
    HospitalSettingActions.InspectionSettingUpdateFail,
    HospitalSettingActions.ExamInfantsSettingUpdateFail,
    (state, action) => ({
      ...state,
      loading: false,
      error: errorMessage(action.payload),
      loaded: false,
    })
  )
);

export const getLoaded = (state: HosSettingState) => state.loaded;
export const getHosSetting = (state: HosSettingState) => state.data;
export const getError = (state: HosSettingState) => state.error;
export const getLoading = (state: HosSettingState) => state.loading;

export const getUseReceipt = (state: HosSettingState) => state.data?.useReceipt;
export const getUseReservation = (state: HosSettingState) =>
  state.data?.useReservation;
export const getUseExaminationInfants = (state: HosSettingState) =>
  state.data?.useExaminationInfants;
