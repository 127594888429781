import {
  KioskQuestionOptionUIModel,
  QuestionDetailItemModel,
  QuestionDetailOptionItem,
  QuestionDetailRes,
  QuestionDetailResItem,
  QuestionDetailUiItemModel,
  QuestionDetailUiModel,
  QuestionDetailUpdateParams,
  QuestionInputType,
  QuestionnaireTargetReq,
  QuestionOptionType,
  QuestionUseOptionType,
} from '~models/question.model';

const NO_EDITABLE_LIMIT = 5;
export const ITEMS_MAX_LENGTH = 15;

export function parseKioskQuestionOptionUIModel(
  item: QuestionDetailResItem
): KioskQuestionOptionUIModel {
  return {
    ...item,
    expanded: false,
    isDefault: false,
    isRequired: item.default ?? false,
    useChangeable: false,
    editable: true,
    isKiosk: true,
  };
}

export function questionDetailItemResToUiItem(
  item: QuestionDetailResItem,
  index: number
): QuestionDetailUiItemModel {
  return {
    userTitle: item.userTitle,
    emrTitle: item.emrTitle,
    use: item.use,
    useOptionType: item.useOptionType,
    optionCount: item.optionCount,
    options: item.options,
    // isDefault: index === 0,
    isDefault: false,
    // isRequired: index > 0 && index < NO_EDITABLE_LIMIT,
    isRequired: item.default ?? false,
    // byUser: index >= 5,
    useChangeable: index > 0,
    // editable: index >= NO_EDITABLE_LIMIT,
    editable: true,
    // expanded: index === 0
    expanded: false,
    default: item.default,
    healthInfoType: item.healthInfoType,
  };
}

export function questionDetailResToUiModel(
  res: QuestionDetailRes
): QuestionDetailUiModel {
  if (!res.items || res.items.length < NO_EDITABLE_LIMIT) {
    throw new Error('사전문진 데이터가 부족합니다.\n서비스 센터에 문의하세요.');
  }
  return {
    unitKey: res.unitKey,
    type: res.type,
    useMedicalQuestionnaire: res.useMedicalQuestionnaire,
    items: res.items.map(questionDetailItemResToUiItem),
  };
}

export function questionnaireDetailResToUiModel(res: QuestionDetailRes) {
  return {
    unitKey: res.unitKey,
    type: res.type,
    items: res.items.map(questionDetailItemResToUiItem),
  };
}

export function questionUiItemToItemMode(
  item: QuestionDetailUiItemModel
): QuestionDetailItemModel {
  const params: QuestionDetailItemModel = {
    userTitle: item.userTitle,
    emrTitle: item.emrTitle,
    use: item.use,
    useOptionType: item.useOptionType,
    optionCount: item.optionCount,
    options: item.options,
  };

  if (item.default) {
    params.default = item.default;
  }

  if (item.healthInfoType) {
    params.healthInfoType = item.healthInfoType;
  }

  return params;
}

function _validateOption(
  option: QuestionDetailOptionItem,
  parentIndex: number,
  index: number
) {
  let msg = '';

  if (!option.hintText) {
    msg = '상세답변 설명은 필수입니다.';
  } else if (
    option.optionType === QuestionOptionType.SELECT &&
    (!option.selectText || option.selectText.length === 0)
  ) {
    msg =
      '상세답변이 객관식일 경우 하나 이상의 객관식 답변을 입력하셔야 합니다.';
  }

  if (msg) {
    throw new Error(
      `모든 사전문진 문항에 대하여 옵션 설정을 완료해주세요.\n(${parentIndex +
        1}번 문항 - 상세 답변${index + 1})`
    );
  }

  return true;
}

function _validateKioskOption(
  option: QuestionDetailOptionItem,
  parentIndex: number,
  index: number
) {
  let msg = '';

  if (!option.selectText || option.selectText.length === 0) {
    msg =
      '상세답변이 객관식일 경우 하나 이상의 객관식 답변을 입력하셔야 합니다.';
  }

  if (msg) {
    throw new Error(
      `모든 사전문진 문항에 대하여 옵션 설정을 완료해주세요.\n(${parentIndex +
        1}번 문항 - 상세 답변${index + 1})`
    );
  }

  return true;
}

function _validateItem(item: QuestionDetailUiItemModel, index: number) {
  const useOption = item.useOptionType !== QuestionUseOptionType.ONLY_YES_OR_NO;
  let msg = '';

  if (!item.userTitle) {
    msg = '똑닥 App 항목';
  } else if (!item.emrTitle) {
    msg = '의사랑 항목';
  } else if (useOption && (!item.options || item.options.length < 1)) {
    msg = '상세 답변이 필요하나 그 내용이 누락되었습니다.';
  } else if (useOption) {
    item.options.forEach((opt, idx) => _validateOption(opt, index, idx));
  }

  if (msg) {
    throw new Error(
      `모든 사전문진 문항에 대하여 옵션 설정을 완료해주세요.\n(${index +
        1}번 문항 - ${msg})`
    );
  }
}

function _validateKioskItem(item: QuestionDetailUiItemModel, index: number) {
  const useOption = item.useOptionType !== QuestionUseOptionType.ONLY_YES_OR_NO;
  let msg = '';

  if (!item.userTitle) {
    msg = '똑닥 키오스크 항목';
  } else if (!item.emrTitle) {
    msg = '의사랑 항목';
  } else if (useOption && (!item.options || item.options.length < 1)) {
    msg = '상세 답변이 필요하나 그 내용이 누락되었습니다.';
  } else if (useOption) {
    item.options.forEach((opt, idx) => _validateKioskOption(opt, index, idx));
  }

  if (msg) {
    throw new Error(
      `모든 사전문진 문항에 대하여 옵션 설정을 완료해주세요.\n(${index +
        1}번 문항 - ${msg})`
    );
  }
}

export function questionValidate(data: QuestionDetailUiModel) {
  const items = data.items;
  let msg = '';

  if (!data.type) {
    msg = '사전문진 타입이 없습니다.';
  } else if (!items || items.length === 0) {
    msg = '사전문진 내용이 비어 있습니다.';
  } else {
    items.forEach(_validateItem);
  }

  if (msg) {
    throw new Error(msg);
  }

  return data;
}

export function kioskQuestionValidate(
  params: QuestionDetailUpdateParams
): QuestionDetailUpdateParams {
  const items = params.items;
  items.forEach(_validateKioskItem);
  return params;
}

export function questionUiModelToUpdateParams(
  data: QuestionDetailUiModel
): QuestionDetailUpdateParams {
  return {
    ...data,
    items: data.items.map(questionUiItemToItemMode),
  };
}

export function questionUiItemChange(
  items: QuestionDetailUiItemModel[],
  item: QuestionDetailUiItemModel,
  index: number
) {
  const aRet = [...items];

  aRet[index] = {
    ...item,
  };

  return aRet;
}

export function getQuestionOptionItem(): QuestionDetailOptionItem {
  return {
    optionType: QuestionOptionType.INPUT,
    hintText: '',
    selectText: [],
    inputType: QuestionInputType.ALL,
    optionUnit: '',
  };
}

export function getKioskQuestionOptionItem(): QuestionDetailOptionItem {
  return {
    optionType: QuestionOptionType.SELECT,
    hintText: '',
    selectText: [],
    inputType: QuestionInputType.ALL,
    optionUnit: '',
    isKiosk: true,
  };
}

export function createQuestionUiItem(): QuestionDetailUiItemModel {
  return {
    userTitle: '',
    emrTitle: '',
    use: true,
    useOptionType: QuestionUseOptionType.ONLY_YES_OR_NO,
    optionCount: 1,
    options: [getQuestionOptionItem()],
    isDefault: false,
    isRequired: false,
    useChangeable: true,
    editable: true,
    expanded: true,
  };
}

export function createKioskQuestionUIModel(
  _id: number
): KioskQuestionOptionUIModel {
  return {
    _id: `temp_${_id}`,
    userTitle: '',
    emrTitle: '',
    use: true,
    useOptionType: QuestionUseOptionType.ONLY_YES_OR_NO,
    optionCount: 1,
    options: [getKioskQuestionOptionItem()],
    isDefault: false,
    isRequired: false,
    useChangeable: true,
    editable: true,
    expanded: true,
    isKiosk: true,
  };
}

export function questionUiItemAdd(items: QuestionDetailUiItemModel[]) {
  if (items.length === ITEMS_MAX_LENGTH) {
    throw new Error(
      `더 이상 문항 추가가 불가합니다.\n(최대 ${ITEMS_MAX_LENGTH}개 까지 가능)`
    );
  }
  return [...items, createQuestionUiItem()];
}

export function questionUiItemRemove(
  items: QuestionDetailUiItemModel[],
  index: number
) {
  return items.filter((_, idx) => idx !== index);
}

function getOptionsList(count: number) {
  const aRet: QuestionDetailOptionItem[] = [];

  if (count === 0) {
    return aRet;
  }

  for (let i = 0; i < count; i++) {
    aRet.push(getQuestionOptionItem());
  }

  return aRet;
}

export function questionOptionCountChange(
  item: QuestionDetailUiItemModel,
  beforeCount: number,
  afterCount: number
) {
  if (afterCount > beforeCount) {
    return {
      ...item,
      options: [...item.options, ...getOptionsList(afterCount - beforeCount)],
    };
  } else if (afterCount < beforeCount) {
    return {
      ...item,
      options: [...item.options.slice(0, afterCount)],
    };
  }
}

export function questionOptionItemChange(
  item: QuestionDetailUiItemModel,
  optionItem: QuestionDetailOptionItem,
  optionIndex: number
) {
  return {
    ...item,
    option: item.options.map((option, idx) => {
      if (idx === optionIndex) {
        return optionItem;
      }
      return option;
    }),
  };
}

export function questionTargetValidate(params: QuestionnaireTargetReq) {
  if (!params.title) {
    throw new Error('사전문진표 타이틀을 입력해주세요.');
  }

  if (params.reReception) {
    if (!params.reReceptionPeriod) {
      throw new Error('재접수 기준 기간을 선택하세요');
    }
    if (params.reReceptionPeriod && params.reReceptionPeriod < 0) {
      throw new Error('알맞지않은 재접수 기준 기간입니다.');
    }
  }

  return params;
}
