export function loadOuterScript(url: string) {
  return new Promise<boolean>((resolve, reject) => {
    const script = document.createElement('script');

    try {
      script.src = url;
      script.type = 'text/javascript';
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        resolve(true);
      };
    } catch (error) {
      reject(false);
    }
  });
}
