<div class="wrap">
  <div class="container py-4">
    <div class="inner-wrap">
      <div class="inner-left">
        <app-circle-icon [icon]="icon"></app-circle-icon>
        <div class="text-wrap">
          <div class="title">{{ title }}</div>
          <span class="desc">{{ description }}</span>
        </div>
      </div>
      <div class="inner-right">
        <a
          *ngIf="buttonType === 'text'"
          class="btn btn-unsubscribe"
          (click)="useChange.emit()"
          >{{ buttonLabel }}</a
        >
        <button
          style="width: 200px"
          class="ha-btn ha-btn-primary"
          *ngIf="buttonType === 'button'"
          (click)="useChange.emit()"
        >
          {{ buttonLabel }}
        </button>
      </div>
    </div>
  </div>
</div>
