import { HashMap } from '~models/common.model';
import {
  CellInfo,
  EnableTime,
  HourItem,
  Schedule,
  ScheduleExistsItem,
  ScheduleItem,
  ScheduleItemGroup,
  SymptomItem,
  SymptomUnit,
} from '~models/reservation-timeline.model';
import {
  arrayToBoolMap,
  arrayToMap,
  copyArray,
  mapForEach,
  sortedNumberKeys,
} from '~shared/service/util';
import { ResTimelineScheduleState } from '~reducers/products/res-timeline-schedule.reducer';
import {
  clearOverlapEnableTimes,
  clearOverlapEnableTimesByNewList,
  createEnableTime,
  filterEnableTimeEquals,
  filterEnableTimesByOffData,
  findOverlapEnableTime,
  makeEnableTimes,
} from './res-timeline-schedule.enableTime';
import {
  DEFAULT_TIME_UNIT,
  makeDayMatrix,
  WEEK_NAME_LIST,
} from './res-timeline-schedule.util';
import { values as _values, keyBy as _keyBy } from 'lodash';

function createSchedule(day: number): Schedule {
  return {
    day,
    enableTime: [],
  };
}

export function sortSchedules(a: Schedule, b: Schedule): number {
  if (a.day < b.day) {
    return -1;
  }
  if (a.day > b.day) {
    return 1;
  }

  return 0;
}

function sortEnableTimes(a: EnableTime, b: EnableTime): number {
  if (a.startTime < b.startTime) {
    return -1;
  }
  if (a.startTime > b.startTime) {
    return 1;
  }

  return 0;
}

export function modifySchedules(
  state: ResTimelineScheduleState,
  cellInfo: CellInfo
): HashMap<Schedule> {
  try {
    const { selectedSymptomUnit, hourItems, timeUnit } = state;
    const { day } = cellInfo;
    const { symptom: symptomId, count: symptomCount } = selectedSymptomUnit;
    const schedules = state.schedules;
    const enableTimeNew = createEnableTime(
      cellInfo,
      hourItems,
      selectedSymptomUnit,
      timeUnit
    );

    if (day) {
      let schedule = schedules[day];
      let enableTimes: EnableTime[];
      let mClearResult: { hasEquals: boolean; enableTime: EnableTime[] };

      if (!schedule) {
        schedule = createSchedule(day);
        schedules[day] = schedule;
        // schedules.push(schedule);
      } else if (schedule.enableTime && schedule.enableTime.length > 0) {
        // TODO: EnableTime 이 이미 존재할 때 수행할거
        mClearResult = clearOverlapEnableTimes(
          schedule.enableTime.slice(),
          enableTimeNew
        );
        schedule.enableTime = mClearResult.enableTime;

        if (mClearResult.hasEquals) {
          return schedules;
        }
      }

      // 걸리적거리는 EnableTime 들을 모두 제거 하고 필요한 내용을 삽입.
      enableTimes = schedule.enableTime;
      enableTimes.push(enableTimeNew);
      schedule.enableTime = enableTimes.sort(sortEnableTimes);
    }

    return { ...schedules };
  } catch (e) {
    console.log('ERROR(modifySchedules):', e);
  }
}

export function modifySchedulesByCellInfoList(
  schedules: HashMap<Schedule>,
  cellMap: HashMap<CellInfo[]>,
  su: SymptomUnit,
  hourItems: HourItem[]
) {
  const TIME_UNIT = DEFAULT_TIME_UNIT;
  const mSchedules: HashMap<Schedule> = schedules;
  let hasModify = false;

  // schedules.forEach(schedule => {
  //   mSchedules[schedule.day] = schedule;
  // });

  Object.keys(cellMap).forEach(key => {
    const day = Number(key);
    const cellInfoList = cellMap[key];
    let schedule = mSchedules[key];
    // let mClearResult: { hasEquals: boolean; enableTime: EnableTime[]; };

    if (!schedule || !schedule.enableTime || schedule.enableTime.length === 0) {
      schedule = createSchedule(day);
      schedules[day] = schedule;
      hasModify = true;
      schedule.enableTime = cellInfoList.map(cellInfo =>
        createEnableTime(cellInfo, hourItems, su, TIME_UNIT)
      );
    } else if (schedule.enableTime && schedule.enableTime.length > 0) {
      const enableTimeResult: EnableTime[] = [];
      const enableTimeDelete: EnableTime[] = [];
      cellInfoList.forEach(cellInfo => {
        const et = createEnableTime(cellInfo, hourItems, su, TIME_UNIT);
        // 카운트가 있으면 없어지는 겁니다 ^^
        if (!!cellInfo.count) {
          enableTimeDelete.push(et);
        } else {
          enableTimeResult.push(et);
        }
      });

      let enableTimeFiltered = clearOverlapEnableTimesByNewList(
        schedule.enableTime.slice(),
        enableTimeResult
      );
      enableTimeFiltered = clearOverlapEnableTimesByNewList(
        enableTimeFiltered,
        enableTimeDelete
      );

      schedule.enableTime = enableTimeFiltered
        .concat(enableTimeResult)
        .sort(sortEnableTimes);
    }
  });

  if (hasModify) {
    return { ...schedules };
  }

  return schedules;
}

export function changeSchedules(
  state: ResTimelineScheduleState,
  cellInfo: CellInfo
): HashMap<Schedule> {
  const { selectedSymptomUnit, hourItems, timeUnit } = state;
  const { day, count } = cellInfo;
  const { symptom: symptomId, count: symptomCount } = selectedSymptomUnit;
  let schedules = state.schedules;
  const enableTimeNew = createEnableTime(
    cellInfo,
    hourItems,
    selectedSymptomUnit,
    timeUnit
  );
  if (day) {
    const existSchedule = schedules[day];
    const hasNoSchedule = !existSchedule;
    const schedule = hasNoSchedule ? createSchedule(day) : existSchedule;
    const mClearResult = clearOverlapEnableTimes(
      schedule.enableTime.slice(),
      enableTimeNew
    );
    const enableTimes = mClearResult.enableTime;
    const targets = enableTimes.filter(filterEnableTimeEquals(enableTimeNew));
    let target: EnableTime;
    const overlapTarget = findOverlapEnableTime(
      schedule.enableTime,
      enableTimeNew
    );

    if (overlapTarget !== null) {
      overlapTarget.count = count;

      return schedules;
    }

    if (!targets || targets.length === 0) {
      enableTimeNew.count = count;
      enableTimes.push(enableTimeNew);
      // schedule.enableTime = enableTimes.sort(sortEnableTimes);
    } else {
      target = targets[0];
      target.count = count;
    }

    if (hasNoSchedule) {
      schedules = { ...schedules, [day]: schedule };
      // schedules[day] = schedule;
      // schedules.push(schedule);
      // schedules = schedules.sort(sortSchedules);
    }

    schedule.enableTime = enableTimes.sort(sortEnableTimes);
  }

  return schedules;
}

/**
 * 입력된 진료항목만을 가진 스케줄외 나머지 스케줄 자료는 삭제 한다.
 * @param schedules 확인 할 스케줄 자료
 * @param unit 진료항목
 */
export function removeSchedulesBySymptomUnit(
  // state: ResTimelineScheduleState,
  schedules: HashMap<Schedule>,
  unit: SymptomUnit
) {
  // const schedules = state.schedules;
  // mapForEach(schedules, item => {
  //   item.enableTime = item.enableTime.filter(time => {
  //     return time.symptom !== unit.symptom;
  //   });
  // });

  // schedules.forEach((item, idx) => {
  //   item.enableTime = item.enableTime.filter(time => {
  //     return time.symptom !== unit.symptom;
  //   });
  // });

  return _keyBy(
    _values(schedules).map(item => {
      return {
        ...item,
        enableTime: [
          ...item.enableTime.filter(time => {
            return time.symptom !== unit.symptom;
          }),
        ],
      };
    }),
    'day'
  );
}

/**
 * 입력된 진료항목만을 가진 스케줄 외 나머지 스케주 자료는 삭제한다.
 *
 * 스케줄 데이터가 Array 일 경우에 쓰인다.
 * @param schedules 확인 할 스케줄 자료
 * @param unit 진료항목
 */
export function removeScheduleArrayBySymptomUnit(
  schedules: Schedule[],
  unit: SymptomUnit
) {
  schedules.forEach((item, idx) => {
    item.enableTime = item.enableTime.filter(time => {
      return time.symptom !== unit.symptom;
    });
  });

  return schedules;
}

export function removeSchedules(
  state: ResTimelineScheduleState,
  cellInfo: CellInfo
): HashMap<Schedule> {
  const { hourItems, timeUnit } = state;
  const { day } = cellInfo;
  const schedules = state.schedules;
  const enableTimeNew = createEnableTime(
    cellInfo,
    hourItems,
    {
      timeUnit,
      symptom: null,
      count: 1,
      colorNo: '',
    },
    timeUnit
  );
  if (day) {
    const schedule = schedules[day];
    let mClearResult;

    if (!schedule) {
      return schedules;
    } else if (schedule.enableTime && schedule.enableTime.length > 0) {
      // TODO: EnableTime 이 이미 존재할 때 수행할거
      mClearResult = clearOverlapEnableTimes(
        schedule.enableTime.slice(),
        enableTimeNew
      );
      schedule.enableTime = mClearResult.enableTime;

      if (mClearResult.hasEquals) {
        return schedules;
      }
    }
  }

  return { ...schedules };
}

export function applySymptomUnitToSchedulesByHourRange(
  state: ResTimelineScheduleState,
  unit: SymptomUnit
): HashMap<Schedule> {
  const {
    year,
    month,
    schedules,
    hourRange,
    week,
    hourItems,
    timeUnit,
    openTimeMap,
    holidayMap,
  } = state;
  const weekIndex = week - 1;

  const weekdays = hourRange.weekdays;
  const aaMatrix = makeDayMatrix(year, month);
  const aCurrDays = aaMatrix[weekIndex];
  const aEnableTime: EnableTime[] = makeEnableTimes(
    hourRange,
    hourItems,
    unit,
    timeUnit
  );

  // console.log('openTimeMap', openTimeMap);
  // console.log('holidayMap', holidayMap);

  const mSchedules = schedules;
  let isScheduleAdded = false;

  aCurrDays.forEach((day, idx) => {
    if (day < 1 || (weekdays && !weekdays[idx])) {
      return;
    }

    const weekName = WEEK_NAME_LIST[idx];
    let schedule = mSchedules[day];

    if (!schedule) {
      schedule = createSchedule(day);
      mSchedules[day] = schedule;
      // schedules.push(schedule);
      isScheduleAdded = true;
    }

    // console.log('before', aEnableTime.length);

    let enableTimes = schedule.enableTime;
    const enableTimesNew = copyArray(
      unit
        ? aEnableTime.filter(
            filterEnableTimesByOffData(openTimeMap[weekName], holidayMap[day])
          )
        : aEnableTime
    );

    // console.log('after', enableTimesNew.length);

    // console.log('holidayMap', holidayMap, holidayMap[day]);

    // console.log('aEnableTime.length', aEnableTime.length, enableTimesNew.length);

    enableTimesNew.forEach(item => {
      const mResult = clearOverlapEnableTimes(enableTimes, item);

      enableTimes = mResult.enableTime;

      // console.log('enableTimesNew->enableTimes', enableTimes);

      // SymptomUnit 이 비어 있다는 것은 지운다는 것을 의미 한다.
      if (!unit) {
        return;
      }
      enableTimes.push(item);
    });

    enableTimes = enableTimes.sort(sortEnableTimes);
    schedule.enableTime = enableTimes;

    // console.log('enableTimesNew', enableTimesNew);
  });

  // console.log('schedules', schedules);

  if (isScheduleAdded) {
    // return schedules.sort(sortSchedules);
    return { ...schedules };
  }

  return schedules;
}

function toScheduleItem(
  enableTime: EnableTime,
  symptomUnit: SymptomUnit,
  symptomItem: SymptomItem
): ScheduleItem {
  return {
    ...enableTime,
    ...symptomUnit,
    ...symptomItem,
  };
}

function toScheduleItems(
  enableTimes: EnableTime[],
  suMap: { [key: string]: SymptomUnit },
  siMap: { [key: string]: SymptomItem }
) {
  return enableTimes.map(et => {
    const id = et.symptom as string;
    return toScheduleItem(et, suMap[id], siMap[id]);
  });
}

export function toScheduleItemGroups(
  year: number,
  month: number,
  days: number[],
  schedules: HashMap<Schedule>,
  symptomUnits: SymptomUnit[],
  symptomMap: { [id: string]: SymptomItem }
): ScheduleItemGroup[] {
  const mSU = arrayToMap(symptomUnits, 'symptom');
  const mSI = symptomMap;
  const mDays = arrayToBoolMap(days);

  return sortedNumberKeys(schedules)
    .filter(day => !!mDays[day])
    .map(day => {
      const item: ScheduleItemGroup = {
        year,
        month,
        day,
        items: toScheduleItems(schedules[day].enableTime, mSU, mSI),
      };

      return item;
    });

  // days.forEach(day => {
  //   const schedule = schedules[day];

  //   if (!schedule) {
  //     return;
  //   }

  // });
  // const aRet = schedules
  //   .filter(s => !!mDays[s.day])
  //   .map(schedule => {
  //     const item: ScheduleItemGroup = {
  //       year,
  //       month,
  //       day: schedule.day,
  //       items: toScheduleItems(schedule.enableTime, mSU, mSI)
  //     };

  //     return item;
  //   });

  // return aRet;
}

export function toScheduleExistsMap(items: ScheduleExistsItem[]) {
  const mRet: { [month: string]: boolean } = {};

  if (!items || items.length === 0) {
    return mRet;
  }

  items.forEach(item => {
    const monthKey = item.monthKey;
    const sMonth = monthKey.replace(/^\d{4,4}-0?/, '');

    mRet[sMonth] = item.available;
  });

  return mRet;
}

/**
 * 스케줄 목록에서 연월을 이용하여 특정 주간의 스케줄을 가져온다.
 * @param schedules 스케줄 목록
 * @param year 년
 * @param month 월
 * @param week 주간
 */
export function getWeeklySchedules(
  schedules: Schedule[],
  year: number,
  month: number,
  week: number
) {
  if (!schedules || schedules.length === 0) {
    return [null, null, null, null, null, null, null];
  }
  const weekIndex = week - 1;
  const aWeeks = makeDayMatrix(year, month);
  const aWeekly = aWeeks[weekIndex];
  const mSchedules = arrayToMap(schedules, 'day');
  const aRet: (Schedule | null)[] = [];

  aWeekly.forEach(day => {
    if (day < 1) {
      aRet.push(null);
      return;
    }

    const schedule = mSchedules[day];

    if (!schedule || !schedule.enableTime || schedule.enableTime.length === 0) {
      aRet.push(null);
    } else {
      aRet.push(schedule);
    }
  });

  return aRet;
}
