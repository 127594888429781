import { Environment } from './environment.model';

export const environment: Environment = {
  production: true,
  name: 'production',
  test: false,
  api_url: '//api.hospital-admin.ddocdoc.kr',
  child_url: '//infant.ddocdoc.com',
  voluntary_url: '//api.ddocdoc.com',
  faq_url: '//cms.ddocdoc.kr',
  hospital_detail_url: 'https://hospital-info.ddocdoc.com/hospital/',
  gtm_id: 'GTM-5Q9WCD2',
  ga_id: 'G-YH6Y1897VK',
  ncloudId: 'zg6uzq24qw',
  special_symptom: '5e6b3a422335b50d4e16ca28',
  channelTalk_key: 'f2ba3411-dbbe-4e6d-8762-efa58f6356d1',
  ddocdoc_agent_version: '6.1.0.0',
  outpatient_clinic_version: '6.0.0.6',
  kiosk_func_active_ids: [],
  kiosk_menu_info_url:
    'https://cdn.ddocdoc.com/ddocdoc-self-desk/kiosk/admin/options.json',
};
