import { createAction, props } from '@ngrx/store';
import { KioskSustainInfo } from '~models/kiosk-sustain.model';

export const enum KioskSustainActionType {
  FETCH_KIOSK_SUSTAIN_INFOS = '[KIOSK SUSTAIN] Fetch Kiosk Sustain Infos',
  FETCH_KIOSK_SUSTAIN_INFOS_SUCCESS = '[KIOSK SUSTAIN] Fetch Kiosk Sustain Infos Success',
  FETCH_KIOSK_SUSTAIN_INFOS_FAIL = '[KIOSK SUSTAIN] Fetch Kiosk Sustain Infos Fail',
}

export const FetchKioskSustainInfos = createAction(
  KioskSustainActionType.FETCH_KIOSK_SUSTAIN_INFOS,
  props<{ ynum: string }>()
);

export const FetchKioskSustainInfosSuccess = createAction(
  KioskSustainActionType.FETCH_KIOSK_SUSTAIN_INFOS_SUCCESS,
  props<{ payload: KioskSustainInfo[] }>()
);

export const FetchKioskSustainInfosFail = createAction(
  KioskSustainActionType.FETCH_KIOSK_SUSTAIN_INFOS_FAIL,
  props<{ error: any }>()
);

export const KioskSustainActions = {
  FetchKioskSustainInfos,
  FetchKioskSustainInfosSuccess,
  FetchKioskSustainInfosFail,
};
