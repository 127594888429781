import { createAction, props } from '@ngrx/store';
import { HospitalScheduleUiModel } from '~models/hospital-crm.model';
import { HttpRes } from '~models/common.model';

export enum CrmDefaultActionType {
  REQ_FAIL = '[CrmDefault] Req Fail',
  DEFAULT_LOAD = '[CrmDefault] Default Load',
  DEFAULT_LOAD_SUCCESS = '[CrmDefault] Default Load Success',
  DEFAULT_LOAD_FAIL = '[CrmDefault] Default Load Fail',
  SET_ACTIVATE_ALL_SCHEDULE = '[CrmDefault] Set Activate All Schedule',
  SET_ACTIVATE_ALL_SCHEDULE_SUCCESS = '[CrmDefault] Set Activate All Schedule Success',
  SET_ACTIVATE_ALL_SCHEDULE_FAIL = '[CrmDefault] Set Activate All Schedule Fail',
  UPDATE_SCHEDULE = '[CrmDefault] Update Schedule',
  SAVE_SCHEDULE = '[CrmDefault] Save Schedule',
  SAVE_SCHEDULE_START = '[CrmDefault] Save Schedule Start',
  SAVE_SCHEDULE_SUCCESS = '[CrmDefault] Save Schedule Success',
  SAVE_SCHEDULE_FAIL = '[CrmDefault] Save Schedule Fail',
}

export const CrmDefaultReqFail = createAction(
  CrmDefaultActionType.REQ_FAIL,
  props<{ payload: any }>()
);

export const CrmDefaultLoad = createAction(
  CrmDefaultActionType.DEFAULT_LOAD,
  props<{ payload: 'DEFAULT' | 'HOSPITAL' }>()
);

export const CrmDefaultLoadSuccess = createAction(
  CrmDefaultActionType.DEFAULT_LOAD_SUCCESS,
  props<{ payload: HttpRes<HospitalScheduleUiModel> }>()
);

export const CrmDefaultLoadFail = createAction(
  CrmDefaultActionType.DEFAULT_LOAD_FAIL
);

export const CrmSetActivateSchedule = createAction(
  CrmDefaultActionType.SET_ACTIVATE_ALL_SCHEDULE
);

export const CrmSetActivateScheduleSuccess = createAction(
  CrmDefaultActionType.SET_ACTIVATE_ALL_SCHEDULE_SUCCESS,
  props<{ payload: HttpRes<HospitalScheduleUiModel> }>()
);

export const CrmSetActivateScheduleFail = createAction(
  CrmDefaultActionType.SET_ACTIVATE_ALL_SCHEDULE_FAIL
);

export const CrmDefaultUpdateSchedule = createAction(
  CrmDefaultActionType.UPDATE_SCHEDULE,
  props<{ schedule: HospitalScheduleUiModel }>()
);

export const CrmDefaultSaveSchedule = createAction(
  CrmDefaultActionType.SAVE_SCHEDULE,
  props<{ _id: string }>()
);

export const CrmDefaultSaveScheduleStart = createAction(
  CrmDefaultActionType.SAVE_SCHEDULE_START,
  props<{ _id: string }>()
);

export const CrmDefaultSaveScheduleSuccess = createAction(
  CrmDefaultActionType.SAVE_SCHEDULE_SUCCESS,
  props<{ _id: string }>()
);

export const CrmDefaultSaveScheduleFail = createAction(
  CrmDefaultActionType.SAVE_SCHEDULE_FAIL,
  props<{ _id: string }>()
);

export const CrmDefaultActions = {
  CrmDefaultReqFail,
  CrmDefaultLoad,
  CrmDefaultLoadSuccess,
  CrmDefaultLoadFail,
  CrmSetActivateSchedule,
  CrmSetActivateScheduleSuccess,
  CrmSetActivateScheduleFail,
  CrmDefaultUpdateSchedule,
  CrmDefaultSaveSchedule,
  CrmDefaultSaveScheduleStart,
  CrmDefaultSaveScheduleSuccess,
  CrmDefaultSaveScheduleFail,
};
