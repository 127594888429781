import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DateRangeFilterComponent } from './components/date-range-filter/date-range-filter.component';
import { KeywordFilterComponent } from './components/keyword-filter/keyword-filter.component';
import { SearchFilterPanelComponent } from './components/search-filter-panel/search-filter-panel.component';
import { SelectFilterComponent } from './components/select-filter/select-filter.component';
import { LabeledDateFilterComponent } from './components/labeled-date-filter/labeled-date-filter.component';
import { LabeledTextFilterComponent } from './components/labeled-text-filter/labeled-text-filter.component';
import { LabeledSelectFilterComponent } from './components/labeled-select-filter/labeled-select-filter.component';
import { TableHeaderSortComponent } from './components/table-header-sort/table-header-sort.component';

const COMPONENTS = [
  SearchFilterPanelComponent,
  SelectFilterComponent,
  KeywordFilterComponent,
  DateRangeFilterComponent,
  LabeledDateFilterComponent,
  LabeledTextFilterComponent,
  LabeledSelectFilterComponent,
  TableHeaderSortComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    NgbModule,
  ],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class SharedNewModule {}
