import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from '~core/api/shared.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-category-type',
  templateUrl: './category-type.component.html',
  styleUrls: ['./category-type.component.scss'],
})
export class CategoryTypeComponent implements OnInit {
  @Input() placeHolder = '대표진료과를 선택하세요';

  @Input() set selectedCategory(selectedItem: any) {
    this.selectedItem = selectedItem;
  }

  selectedItem: any;

  @Output() categoryTypeInfo = new EventEmitter();

  showEtc = false;
  categoryTypeList$: Observable<any>;

  constructor(private sharedService: SharedService) {
    this.categoryTypeList$ = sharedService
      .fetchSubjectList()
      .pipe(map(value => value.items));
  }

  ngOnInit(): void {}

  onItemClick(item: any): void {
    this.selectedItem = item;
    this.categoryTypeInfo.emit(item);
  }

  get selectedTitle(): any {
    if (this.selectedItem) {
      return this.selectedItem.name;
    }

    return this.placeHolder;
  }
}
