import { ResetPwInfo } from '~models/auth.model';
import { createAction, props } from '@ngrx/store';

export enum ResetPwActionTypes {
  INIT = '[Auth] Init',
  RESET_PW = '[Auth] Reset Pw',
  RESET_PW_COMPLETE = '[Auth] Reset Pw Complete',
  RESET_PW_FAILURE = '[Auth] Reset Pw Failure',
}

export const ResetPwInit = createAction(ResetPwActionTypes.INIT);

export const ResetPw = createAction(
  ResetPwActionTypes.RESET_PW,
  props<{
    payload: ResetPwInfo;
  }>()
);

export const ResetPwComplete = createAction(
  ResetPwActionTypes.RESET_PW_COMPLETE
);

export const ResetPwFailure = createAction(
  ResetPwActionTypes.RESET_PW_FAILURE,
  props<{
    payload: any;
  }>()
);

export const ResetPwActions = {
  ResetPwInit,
  ResetPw,
  ResetPwComplete,
  ResetPwFailure,
};
