import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { toPhoneFormat } from '~shared/utils/common';
import { ControlEvent } from '~shared/utils/control-event';

@Component({
  selector: 'app-labeled-text-filter',
  templateUrl: './labeled-text-filter.component.html',
  styleUrls: ['./labeled-text-filter.component.scss'],
})
export class LabeledTextFilterComponent implements OnInit, OnDestroy {
  @Input() label = '';
  @Input() placeholder = '';
  @Input() value = '';
  @Input() type = 'text';
  @Input() minLength = 0;
  @Input() maxLength = 524288;

  @Output() valueChange = new EventEmitter<string>();
  @Output() debouncedChange = new EventEmitter<string>();

  @ViewChild('input', { static: false }) inputElem: ElementRef<
    HTMLInputElement
  >;

  private ngDestroy = new Subject<void>();
  private sbjChange = new ControlEvent<string>(this.ngDestroy);

  constructor() {
    this.sbjChange.asObservable
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(value => {
        this.debouncedChange.emit(value);
      });
  }

  ngOnInit() {}

  handleChange(value: string) {
    const parsedValue = this.type === 'tel' ? toPhoneFormat(value) : value;
    this.inputElem.nativeElement.value = parsedValue;
    this.valueChange.emit(parsedValue);
    this.sbjChange.next(parsedValue);
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}
