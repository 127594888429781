import { createReducer, on } from '@ngrx/store';
import { ShortUrlActions } from '~actions/products/short-url.actions';

export interface ShortUrlState {
  shortUrl?: string;
  error?: any;
}

const initialState: ShortUrlState = {};

export const reducer = createReducer(
  initialState,
  on(ShortUrlActions.ShortUrlCreateSuccess, (state, action) => ({
    ...state,
    shortUrl: action.payload.shortUrl,
  })),
  on(ShortUrlActions.ShortUrlCreateFail, (state, action) => ({
    ...state,
    error: action.payload.error,
  }))
);
