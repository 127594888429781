import { CareRoom } from '~models/care-room.model';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import * as fromProduct from '~reducers/products';
// import * as careRoomActions from '~actions/products/care-room';

import { Subject } from 'rxjs/';
import { CareRoomActions } from '~actions/products/care-room.actions';

@Component({
  selector: 'app-room-select-modal',
  template: `
    <div class="modal-header ha-modal-header">
      <div class="modal-title-wrap">
        <h5 class="modal-title">다른 진료실 설정 불러오기</h5>
        <p class="modal-title-desc">선택한 진료실의 설정 내역을 가져옵니다.</p>
      </div>
    </div>
    <div class="modal-body full-size">
      <div class="list-group">
        <a
          (click)="selectedRoom(room.title, room.key)"
          style="cursor: pointer;"
          *ngFor="let room of careRooms$ | async"
          class="list-group-item list-group-item-action text-center"
        >
          {{ room.title }}
        </a>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="ha-btn ha-btn-primary"
        (click)="activeModal.close('cancel')"
      >
        취소
      </button>
    </div>
  `,
  styles: [
    `
      .modal-header {
      }

      .modal-header {
        border-bottom: none;
      }
    `,
  ],
})
export class RoomSelectModalComponent implements OnInit {
  careRooms$: Observable<CareRoom[]>;
  roomSubject = new Subject<CareRoom>();

  selectedRoom(title: string, key: string) {
    if (confirm(title + ' 의 시간을 적용하시겠습니까?')) {
      this.getCareRoom(key);
    }
  }

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<fromProduct.ProductState>
  ) {}

  getCareRoom(key: string) {
    this.store.dispatch(CareRoomActions.CareRoomSelect({ payload: { key } }));
    this.store.pipe(select(fromProduct.getSelectedCareRoom)).subscribe(data => {
      this.roomSubject.next(data);
    });
  }

  ngOnInit() {
    this.store.dispatch(CareRoomActions.FetchCareRoom());
    this.careRooms$ = this.store.pipe(select(fromProduct.getAllEntityCareRoom));
  }
}
