<div class="wrap">
  <!-- <div>
    <label class="ha-form-control ha-form-checkbox" for="chk_status_all">
      <input
        class="ha-form-control-input"
        type="checkbox"
        id="chk_status_all"
        [(ngModel)]="statesCheckedAll"
        (change)="onCheckChangeAll($event)"
      />
      <h4 class="chk-text ha-form-control-label"><b>전체 동의</b></h4>
    </label>
  </div> -->
  <span class="step-header">
    Step.1<br />
    <b>원장님 인증하기</b>
  </span>
  <div class="checklist-group">
    <div *ngFor="let item of terms; let idx = index">
      <label
        class="check-status ha-form-control ha-form-checkbox"
        for="chk_status_{{ idx }}"
      >
        <input
          class="ha-form-control-input"
          type="checkbox"
          id="chk_status_{{ idx }}"
          [checked]="statesChecked[idx]"
          (change)="onCheckedChange($event, idx)"
        />
        <span class="chk-text ha-form-control-label"
          >{{ item.label }} ({{ item.isNecessary ? '필수' : '선택' }})</span
        >
        <a
          *ngIf="item.href"
          class="terms-link"
          [href]="item.href"
          target="_blank"
          >전체보기 <i class="ha-icon-angle-thin-right icon-right"></i>
        </a>
      </label>
      <iframe class="terms-wrap" [src]="item.src | safeUrl"></iframe>
    </div>
  </div>
  <div class="form-group-wrap">
    <h4 class="form-group-title">요양기관 번호(필수)</h4>
    <div class="form-group">
      <input
        class="form-control"
        type="tel"
        placeholder="요양기관 번호 입력"
        maxlength="8"
        [(ngModel)]="ynum"
        (ngModelChange)="onChangeYnum($event)"
      />
      <button
        class="ha-btn sign-up-check"
        [ngClass]="{ active: isActiveYnumBtn }"
        [disabled]="!isActiveYnumBtn"
        (click)="onCheckYnum()"
      >
        가입 확인
      </button>
    </div>
  </div>
  <button
    class="ha-btn next"
    (click)="onClickNext()"
    [ngClass]="{ active: isActiveNext }"
    [disabled]="!isActiveNext"
  >
    다음
  </button>
</div>
