<label for="datePicker"> {{ label }} </label>
<input
  id="datePicker"
  type="text"
  class="form-control date-input"
  [autocomplete]="autocomplete"
  [placeholder]="placeholder"
  [value]="displayValue"
  bsDatepicker
  [bsConfig]="{ showWeekNumbers: false, dateInputFormat: 'YYYY/MM/DD' }"
  (bsValueChange)="handleBsValueChange($event)"
  [minDate]="minDate"
  [maxDate]="maxDate"
  (keydown)="handleKeyDown($event)"
/>
