import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    // loadChildren: './modules/admin/admin.module#AdminModule',
    loadChildren: () =>
      import('./modules/admin/admin.module').then(m => m.AdminModule),
  },
  // {
  //   path: '',
  //   loadChildren: './__legacy__/layout/layout.module#LayOutPageModule',
  // },
  {
    path: 'pdfArchives',
    // loadChildren:
    //   './modules/pdf-archives/pdf-archives.module#PdfArchivesModule',
    loadChildren: () =>
      import('./modules/pdf-archives/pdf-archives.module').then(
        m => m.PdfArchivesModule
      ),
  },
];

export const AppRoutes = RouterModule.forRoot(routes, {
  preloadingStrategy: PreloadAllModules,
});
