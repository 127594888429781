import { SharedService } from '~core/api/shared.service';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AgentTypeListItem, EmrTypeListItems } from '~models/auth.model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-emr-type',
  templateUrl: './emrType.component.html',
  styleUrls: ['./emrType.component.scss'],
})
export class EmrTypeComponent implements OnInit, OnDestroy {
  @Input() placeHolder =
    '병원에서 사용하는 EMR사 및 차트 프로그램을 선택하세요.';
  @Input() selectedAgentType = '';
  @Output() emrTypeInfo = new EventEmitter();
  @Output() emrTypeNameInfo = new EventEmitter();

  selectedItem?: AgentTypeListItem;
  emrTypeListSubscription?: Subscription;
  agentTypeList: AgentTypeListItem[] = [];

  constructor(private sharedService: SharedService) {}

  ngOnInit(): void {
    this.emrTypeListSubscription = this.sharedService
      .fetchEmrList()
      .pipe(
        map<EmrTypeListItems, any>(value => {
          return value.items;
        })
      )
      .subscribe(agentTypeList => {
        this.agentTypeList = agentTypeList;
        if (this.selectedAgentType) {
          this.checkSelectedAgentTypeName();
        }
      });
  }

  ngOnDestroy(): void {
    this.emrTypeListSubscription?.unsubscribe();
  }

  checkSelectedAgentTypeName(): void {
    const selectedAgent = this.findSelectedAgentByType(this.selectedAgentType);

    if (selectedAgent) {
      this.emrTypeNameInfo.emit(selectedAgent);
    }
  }

  findSelectedAgentByType(agentType: string): AgentTypeListItem | null {
    const selectedAgent = this.agentTypeList.find(
      i => i.agentType === agentType
    );
    if (!selectedAgent) {
      return null;
    }

    return selectedAgent;
  }

  onItemClick(item: AgentTypeListItem): void {
    this.selectedItem = item;
    this.emrTypeInfo.emit(item);
  }

  get selectedTitle(): string {
    if (this.selectedAgentType) {
      const selectedAgent = this.findSelectedAgentByType(
        this.selectedAgentType
      );
      if (selectedAgent) {
        return selectedAgent.agentTypeName;
      }
    }

    if (this.selectedItem) {
      return this.selectedItem.agentTypeName;
    }

    return this.placeHolder;
  }
}
