import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '~core/api/auth.service';
import { Api } from '~core/http-api';
import { TokenProvider } from '~core/token.provider';
import { AccountInfo } from '~models/auth.model';
import { errorMessage } from '~shared/service/util';
import { SimpleModalService } from '~shared/service/simple.modal.service';
import { AuthActions } from '~actions/auth/auth.action';
import { toSortedServiceTypeHospitalInfo } from '~store/services/hospital-info/hospital-info.parser';
import { AuthState } from '~reducers/auth/auth.reducer';
import { Store } from '@ngrx/store';
import { getGuideType } from '~reducers/auth';
import * as Cookies from 'js-cookie';

@Injectable()
export class AuthEffects {
  constructor(
    private store$: Store<AuthState>,
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private api: Api,
    private token: TokenProvider,
    private simpleModalService: SimpleModalService
  ) {}

  // Login Effects
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.Login),
      map(action => action.payload),
      exhaustMap(({ authLoginInfo: auth }) =>
        this.authService.accountLogin(auth).pipe(
          map(user => {
            return AuthActions.LoginSuccess({ payload: user });
          }),
          catchError(error => of(AuthActions.LoginFailure({ payload: error })))
        )
      )
    )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.LoginSuccess),
      map(action => action.payload),
      tap(user => {
        this.token.setByHours(user.hospitalToken, 4);
      }),
      map(loginSuccessInfo =>
        AuthActions.AccountFetch({
          payload: loginSuccessInfo,
        })
      ),
      catchError(error => of(AuthActions.LoginFailure({ payload: error })))
    )
  );

  loginComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.LoginComplete),
        concatLatestFrom(() => this.store$.select(getGuideType)),
        tap(([_, guideType]) => {
          Cookies.set('pop', 'open', { expires: 86400 });
          if (guideType === 'schedule') {
            this.router.navigate(['/ddocdoc-service/mobile-schedule/list']);
          }
        }),
        catchError(error => of(AuthActions.LoginFailure({ payload: error })))
        // tap(() => this.router.navigate(['/home']))
      ),
    {
      dispatch: false,
    }
  );

  loginRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.LoginRedirect),
        tap(() => {
          this.api.clearHospitalId();
          this.router.navigate(['/signin']);
        })
      ),
    {
      dispatch: false,
    }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.Logout),
        tap(() => {
          this.token.clear();
          this.api.clearHospitalId();
          this.router.navigate(['/signin']);
        })
      ),
    {
      dispatch: false,
    }
  );

  // Create Effects
  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.AccountCreate),
      map(action => action.payload),
      exhaustMap(createUserInfo =>
        this.authService.createUser(createUserInfo).pipe(
          map(() => AuthActions.AccountCreateComplete()),
          catchError(error => {
            return of(AuthActions.AccountCreateFailure({ payload: error }));
          })
        )
      )
    )
  );

  createFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.AccountCreateFailure),
        tap(({ payload: error }) => {
          this.simpleModalService.alert(errorMessage(error, 'auth.effects'));
        })
      ),
    {
      dispatch: false,
    }
  );

  createComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.AccountCreateComplete),
        tap(() => {
          alert('가입승인 요청이 완료되었습니다.');
          this.router.navigate(['/signin']);
        })
      ),
    {
      dispatch: false,
    }
  );

  // Account Update Effects
  updateAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.AccountUpdate),
      exhaustMap(({ payload: accountUpdateInfo }) => {
        return this.authService.updateAuthInfo(accountUpdateInfo).pipe(
          switchMap(() => this.authService.fetchUserInfo()),
          map(user => AuthActions.AccountUpdateComplete({ payload: user })),
          catchError(err => {
            const { error } = err.error;
            this.simpleModalService.error(err);
            return of(AuthActions.AccountUpdateFailure({ payload: error }));
          })
        );
      })
    )
  );

  updateAccountComplete = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.AccountUpdateComplete),
        tap(() => {
          this.simpleModalService.alert('계정 정보가 업데이트되었습니다.');
        })
      ),
    {
      dispatch: false,
    }
  );

  // Account Fetch Effects
  accountFetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.AccountFetch),
      exhaustMap(() =>
        this.authService.fetchUserInfo().pipe(
          map((data: AccountInfo) => {
            const payload = {
              ...data,
              ...(data.hospital && {
                hospital: toSortedServiceTypeHospitalInfo(
                  data.hospital,
                  data.authType ?? ''
                ),
              }),
            };
            return AuthActions.AccountFetchComplete({
              payload,
            });
          }),
          catchError(error => {
            return of(AuthActions.AccountFetchFailure({ payload: error }));
          })
        )
      )
    )
  );

  accountFetchComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.AccountFetchComplete),
      map(data => data.payload),
      // map(data => this.initPartnerHospital(data)),
      map(data => this.checkEmptyPartner(data)),
      map(() => AuthActions.LoginComplete())
    )
  );

  accountFetchFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.AccountFetchFailure),
        tap(err => {
          console.log(err);
          this.token.clear();
          alert('계정 정보를 불러 올 수 없습니다. 다시 로그인 해주세요.');
          this.router.navigate(['/signin']);
        })
      ),
    {
      dispatch: false,
    }
  );

  changeHospital$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.ChangeHospital),
      map(action => action.payload),
      map(data => AuthActions.ChangeHospitalSuccess({ payload: data }))
    )
  );

  changeHospitalSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.ChangeHospitalSuccess),
        tap(() => {
          alert('변경 되었습니다.');
          this.router.navigate(['/home']);
        })
      ),
    { dispatch: false }
  );

  checkPhoneCertification = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.PhoneCertificationCheck),
        exhaustMap(({ payload: { userPhone } }) =>
          this.authService.phoneCertificationCheck(userPhone).pipe(
            tap(() => {
              this.simpleModalService.alert('인증번호를 전송했습니다.');
            }),
            catchError(err => {
              this.simpleModalService.error(err);
              return EMPTY;
            })
          )
        )
      ),
    {
      dispatch: false,
    }
  );

  confirmPhoneCertification = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.PhoneCertificationConfirm),
      exhaustMap(({ payload: { userPhone, certNum } }) => {
        return this.authService
          .phoneCertificationConfirm(userPhone, certNum)
          .pipe(
            map(({ token }) => {
              return AuthActions.PhoneCertificationConfirmComplete({
                payload: { token },
              });
            }),
            tap(() => this.simpleModalService.alert('인증에 성공하였습니다.')),
            catchError(() => {
              this.simpleModalService.alert(
                '전화번호 인증에 실패하셨습니다. 다시 시도해주세요.'
              );
              return EMPTY;
            })
          );
      })
    )
  );

  // NOTE: 231006, 2018년도 데이터에는 있었던 것 같으나, 현재 데이터에는 없음.
  /*
  initPartnerHospital(data: AccountInfo): AccountInfo {
    if (data.partnerGroup) {
      const initHos = data.partnerGroup.hospitals.filter((hospital: any) => {
        return (
          hospital.mobileEnvironment.receipt.use ||
          hospital.mobileEnvironment.reservation.use
        );
      });
      if (initHos.length !== 0) {
        this.api.setHospitalId(Object.assign({}, initHos[0]));
        data.hospital = Object.assign({}, initHos[0]);
      }
    }
    return data;
  }
  */

  checkEmptyPartner(data: AccountInfo): AccountInfo {
    if (!data.hospital) {
      alert('똑닥 접수 및 예약 가능한 병원이 없습니다.');
      this.router.navigate(['/signin']);
    }
    return data;
  }
}
