import { AccountInfo } from '~models/auth.model';
import { Banner } from '~models/common.model';
import { AuthActions } from '~actions/auth/auth.action';
import {
  HomeActions,
  HomeEmergencyUpdateSuccess,
} from '~actions/products/home.actions';
import { createReducer, on } from '@ngrx/store';
import {
  ServiceUnsetInfo,
  ServiceUnsetInfoType,
} from '~core/api/guides.service';

export interface HomeState {
  accountInfo: AccountInfo;
  banners: Banner[];
  unsetService: ServiceUnsetInfoType;
  loading: boolean;
}

const initialState: HomeState = {
  accountInfo: {
    _id: '',
    hasAccount: false,
    accountState: 'NOT_SIGNED',
    hasAgent: false,
    useDdocdocService: false,
    agentType: '',
    setupId: '',
    hospital: {
      _id: '',
      title: '',
      serviceType: [],
      bCode: '',
      reservationVersion: '',
    },
    approvalStatus: '',
    active: false,
    accessedAt: '',
    updatedAt: '',
    createdAt: '',
    isEmrAuth: false,
    authType: '',
    showKioskMenu: false,
    showReviewMenu: false,
    userPhone: '',
    userName: '',
    // userId: '',
    // userEmail: '',
    // role: '',
  },
  banners: [],
  unsetService: ServiceUnsetInfo.NONE,
  loading: false,
};

export const reducer = createReducer(
  initialState,
  on(AuthActions.AccountFetchComplete, (state, action) => ({
    ...state,
    accountInfo: action.payload,
  })),
  on(HomeActions.HomeBanner, state => ({ ...state, loading: true })),
  on(HomeActions.HomeBannerSuccess, (state, action) => ({
    ...state,
    banners: action.payload,
    loading: false,
  })),
  on(HomeActions.HomeBannerFail, state => ({
    ...state,
    loading: false,
  })),
  on(HomeActions.HomeServiceGuideSuccess, (state, action) => {
    return {
      ...state,
      unsetService: action.payload,
    };
  }),
  on(HomeActions.HomeEmergencyUpdateSuccess, (state, action) => {
    return {
      ...state,
      accountInfo: {
        ...state.accountInfo,
        hospital: {
          ...state.accountInfo.hospital,
          useEmergency: action.payload,
        },
      },
    };
  })
);
