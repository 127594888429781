import {
  StatusCanceledSearchUiQuery,
  StatusSortTypeUiQuery,
} from '~models/status-canceled.model';

export function createStatusCanceledSearchUiQuery(initialQuery?: {
  [p: string]: any;
}): StatusCanceledSearchUiQuery {
  return {
    type: 'receipt',
    canceledState: '',
    keywordType: 'userName',
    keyword: '',
    dateType: '',
    occurredDate: {
      startDate: '',
      endDate: '',
    },
    birthDate: '',
    skip: 0,
    limit: 10,
    sort: createStatusSortTypeUiQuery(),
    ...initialQuery,
  };
}

export function createStatusSortTypeUiQuery(): StatusSortTypeUiQuery {
  return {
    sortState: 'NONE',
    sortType: '',
  };
}
