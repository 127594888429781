<div class="page-bg"></div>
<div class="wrap">
  <div class="guide-header pb-5 mb-5">
    <i class="icon icon-new-bi"></i>
    <!-- <img
      class="mb-4"
      src="/assets/img/bi_512.png"
      alt=""
      width="72"
      height="72"
      style="border-radius:10px; box-shadow: 2px 3px 15px 0px rgba(204,204,204,0.3);"
    /> -->
    <!-- <p class="text-muted">똑닥을 사용하고 계신 병원 관계자분은 가입정보를 입력하고 승인을 요청해주세요.</!-->
  </div>
  <h3 class="wrap-title">가입 정보를 입력 후<br />가입 승인 요청을 해주세요</h3>
  <form
    [formGroup]="registerForm"
    novalidate
    autocomplete="off"
    onsubmit="false"
  >
    <div class="form-group-wrap">
      <h4 class="form-group-title">기본 정보</h4>
      <div class="form-group">
        <!-- <label for="inputUserId">아이디</label> -->
        <input
          type="text"
          autocomplete="off"
          formControlName="userId"
          class="form-control"
          #inputId
          id="inputUserId"
          placeholder="아이디 (영문+숫자, 4~20자)"
        />
        <!-- <small class="form-text text-muted">영문, 숫자 4 ~ 12자리로 작성해주세요.</small> -->
        <small class="form-text text-danger" *ngIf="isDuplicatedId"
          >이미 가입된 아이디 입니다.</small
        >
        <small
          class="form-text text-danger"
          *ngIf="
            this.registerForm.controls['userId'].invalid &&
            this.registerForm.controls['userId'].touched
          "
        >
          4~20자의 영문, 숫자, 영문+숫자만 사용 가능합니다.</small
        >
      </div>
      <div class="form-group">
        <!-- <label for="inputPassword">비밀번호</label> -->
        <input
          type="password"
          autocomplete="new-password"
          formControlName="userPassword"
          class="form-control"
          id="inputPassword"
          placeholder="비밀번호 (영문+숫자+특수문자 1자 이상 포함, 8 ~ 16자)"
        />
        <!-- <small class="form-text text-muted"
          >영문, 숫자, 특수문자가 1자 이상 포함된 6 ~ 18자로 작성해주세요. <br />특수문자는 !@#$%^*+- 만
          허용됩니다.</small
        > -->
        <small
          class="form-text text-danger"
          *ngIf="
            registerForm.controls['userPassword'].invalid &&
            registerForm.controls['userPassword'].touched
          "
        >
          8~16자의 영문, 숫자, 특수문자를 포함하여 입력해주세요.</small
        >
      </div>
      <div class="form-group">
        <!-- <label for="inputPasswordConfirm">비밀번호 확인</label> -->
        <input
          type="password"
          formControlName="resetPassword"
          autocomplete="new-password"
          class="form-control"
          id="inputPasswordConfirm"
          placeholder="비밀번호 확인"
        />
        <small
          class="form-text text-danger"
          *ngIf="
            registerForm.controls['resetPassword'].invalid &&
            registerForm.controls['resetPassword'].touched
          "
          >비밀번호가 맞지 않습니다.</small
        >
      </div>
    </div>
    <div class="form-group-wrap">
      <h4 class="form-group-title">병원 정보 (ex. 연세정형외과)</h4>
      <div class="form-group">
        <div class="form-group">
          <app-signup-hospital-select
            formControlName="hospitalTitle"
            [placeholder]="'가입 병원 선택'"
            [isShowLabel]="false"
            (hospitalInfo)="getHospitalInfo($event)"
            (isValidate)="handleHospitalValid($event)"
            (hospitalTitleChange)="onChangeHospitalValue($event)"
          >
          </app-signup-hospital-select>
          <small
            class="form-text text-danger"
            *ngIf="registerForm.controls['hospital'].invalid"
          >
            가입 병원을 검색 하신 후 선택해주세요.</small
          >
          <small class="form-text text-danger" *ngIf="isHospitalValueEmpty">
            병원명을 입력후 선택해 주세요.</small
          >
          <small class="form-text text-danger" *ngIf="isHospitalInvalid">
            병원이 존재하지 않습니다. 고객센터로 문의 바랍니다.(TEL
            1899-6826)</small
          >
        </div>

        <div class="form-group">
          <!-- <label for="inputYnum">요양기관번호 (숫자 8자리)</label> -->
          <!-- <input
            type="password"
            autocomplete="off"
            formControlName="ynum"
            class="form-control"
            id="inputYnum"
            placeholder="요양기관 번호 (숫자, 8자)"
          />
          <small
            class="form-text text-danger"
            *ngIf="
              registerForm.controls['ynum'].invalid &&
              (registerForm.controls['ynum'].dirty || registerForm.controls['ynum'].touched)
            "
            >요양기관번호가 잘못되었습니다.</small
          > -->
        </div>

        <div class="form-group">
          <app-category-type
            [selectedCategory]="registerForm.controls['mainCategory'].value"
            (categoryTypeInfo)="getCategoryTypeInfo($event)"
            placeHolder="대표 진료과 선택"
            (click)="onClickMainCategory()"
          ></app-category-type>
          <small
            class="form-text text-danger"
            *ngIf="
              registerForm.controls['mainCategory'].invalid &&
              hasClickedMainCategory
            "
          >
            대표 진료과를 선택하세요.
          </small>
        </div>

        <div class="form-group">
          <input
            type="text"
            autocomplete="off"
            [disabled]="true"
            (click)="getAddressRoad()"
            formControlName="addressRoad"
            class="form-control"
            id="inputAddressRoad"
            placeholder="기본 주소"
            readonly
          />
          <small
            class="form-text text-danger"
            *ngIf="
              registerForm.controls['addressRoad'].invalid &&
              registerForm.controls['addressRoad'].touched
            "
          >
            기본주소를 입력해 주세요.</small
          >
        </div>

        <div class="form-group">
          <input
            type="text"
            autocomplete="off"
            formControlName="addressDetail"
            class="form-control"
            id="inputAddressDetail"
            placeholder="상세 주소"
          />
          <small
            class="form-text text-danger"
            *ngIf="
              registerForm.controls['addressDetail'].invalid &&
              registerForm.controls['addressDetail'].touched
            "
          >
            상세주소를 입력해 주세요.</small
          >
        </div>

        <div class="form-group">
          <!-- <label for="inputPhone">휴대폰 번호</label> -->
          <input
            appPhoneNumAutoHyphen
            maxlength="13"
            type="phone"
            formControlName="telReal"
            autocomplete="off"
            class="form-control"
            id="inputTel"
            placeholder="병원 전화번호"
          />

          <!-- <small class="form-text text-muted">연락 가능한 휴대전화번호를 입력하셔야 합니다.</small> -->
          <small
            class="form-text text-danger"
            *ngIf="
              registerForm.controls['telReal'].invalid &&
              registerForm.controls['telReal'].touched
            "
          >
            병원 전화 번호를 입력해 주세요.</small
          >
        </div>

        <div class="form-group">
          <!-- <label for="">EMR 및 차트 프로그램 선택 (EX. 유비케어 - 의사랑)</label> -->
          <app-emr-type
            placeHolder="EMR 및 차트 프로그램 선택"
            (emrTypeInfo)="getEmrTypeInfo($event)"
            (emrTypeNameInfo)="getEmrTypeNameInfo($event)"
            [selectedAgentType]="registerForm.controls['agentType'].value"
            (click)="onClickAgentType()"
          ></app-emr-type>
          <small
            class="form-text text-danger"
            *ngIf="
              registerForm.controls['agentType'].invalid && hasClickedAgentType
            "
          >
            EMR 및 차트 프로그램을 선택해 주세요.</small
          >
        </div>

        <div
          *ngIf="registerForm.controls['agentType'].value === 'ETC'"
          class="form-group"
        >
          <input
            type="text"
            autocomplete="off"
            formControlName="agentTypeName"
            class="form-control"
            id="inputAgentTypeName"
            placeholder="EMR 차트 프로그램 이름을 입력해주세요."
          />
          <small class="form-text text-danger" *ngIf="isETCAgentNameEmpty">
            기타 EMR 차트 프로그램 이름을 입력해주세요.</small
          >
        </div>

        <div *ngIf="isShowAgencyInput" class="form-group">
          <app-agency-type
            [selectedAgency]="{
              agency: registerForm.controls['agency'].value,
              emr: registerForm.controls['agentType'].value
            }"
            [agent]="registerForm.controls['agentType'].value"
            (agencyTypeInfo)="getAgencyTypeInfo($event)"
          ></app-agency-type>
        </div>
        <div *ngIf="isShowAgencyInput" class="form-group">
          <input
            type="text"
            autocomplete="off"
            formControlName="agencyManager"
            class="form-control"
            id="inputAgencyManager"
            placeholder="담당자명을 입력해주세요."
          />
        </div>
      </div>
    </div>

    <div class="form-group-wrap">
      <h4 class="form-group-title">대표(원장님) 정보</h4>
      <div class="form-group">
        <!-- <label for="inputPhone">휴대폰 번호</label> -->
        <input
          appCregnoAutoHyphen
          type="phone"
          formControlName="cRegNo"
          maxlength="12"
          autocomplete="off"
          class="form-control"
          id="inputcRegNo"
          placeholder="사업자 등록번호"
        />
        <!-- <small class="form-text text-muted">연락 가능한 휴대전화번호를 입력하셔야 합니다.</small> -->
        <small
          class="form-text text-danger"
          *ngIf="
            registerForm.controls['cRegNo'].invalid &&
            (registerForm.controls['cRegNo'].dirty ||
              registerForm.controls['cRegNo'].touched)
          "
        >
          정확한 사업자 번호를 입력해 주세요.</small
        >
      </div>
      <div class="form-group">
        <!-- <label for="inputUserName">담당자 이름</label> -->
        <input
          type="text"
          autocomplete="off"
          formControlName="userName"
          class="form-control"
          id="inputUserName"
          placeholder="대표자(원장님)"
        />
        <small
          class="form-text text-danger"
          *ngIf="
            registerForm.controls['userName'].invalid &&
            (registerForm.controls['userName'].dirty ||
              registerForm.controls['userName'].touched)
          "
          >정확한 이름을 입력해 주세요.</small
        >
      </div>
      <div class="form-group">
        <!-- <label for="inputPhone">휴대폰 번호</label> -->
        <div class="form-check-wrap">
          <input
            appPhoneNumAutoHyphen
            maxlength="13"
            type="phone"
            formControlName="userPhone"
            autocomplete="off"
            class="form-control with-check-button"
            id="inputPhone"
            placeholder="대표자 휴대폰 번호"
          />

          <button
            *ngIf="!isPhoneCertificationSend"
            type="button"
            class="ha-btn sign-up-check"
            [disabled]="
              (registerForm.controls['userPhone'].invalid &&
                (registerForm.controls['userPhone'].dirty ||
                  registerForm.controls['userPhone'].touched)) ||
              !registerForm.controls['userPhone'].value
            "
            (click)="onCheckPhoneNum()"
          >
            <span>인증하기</span>
          </button>
          <button
            *ngIf="isPhoneCertificationSend"
            type="button"
            class="ha-btn sign-up-check"
            [disabled]="
              registerForm.controls['userPhone'].invalid &&
              (registerForm.controls['userPhone'].dirty ||
                registerForm.controls['userPhone'].touched)
            "
            (click)="onResetCheckPhoneNum()"
          >
            <span>재인증</span>
          </button>
        </div>

        <!-- <small class="form-text text-muted">연락 가능한 휴대전화번호를 입력하셔야 합니다.</small> -->
        <small
          class="form-text text-danger"
          *ngIf="
            registerForm.controls['userPhone'].invalid &&
            registerForm.controls['userPhone'].touched
          "
        >
          정확한 휴대폰 번호를 입력해 주세요.</small
        >
      </div>
      <div *ngIf="isPhoneCertificationSend" class="form-group">
        <!-- <label for="inputPhone">휴대폰 번호</label> -->
        <div class="form-check-wrap">
          <label class="phone">
            <input
              type="phone"
              formControlName="certNum"
              autocomplete="off"
              class="form-control phone-input"
              id="inputCertNum"
              placeholder="인증번호"
            />
            <span
              *ngIf="
                isPhoneCertificationSend &&
                registerForm.controls['token'].invalid
              "
              class="phone-timer"
              >{{ resendSMSCountDown$ | async | transformToTime }}</span
            >
          </label>

          <button
            type="button"
            class="ha-btn sign-up-check"
            [disabled]="
              !registerForm.controls['certNum'].value ||
              !isPhoneCertificationSend ||
              registerForm.controls['token'].valid
            "
            (click)="onCheckCertification()"
          >
            <span>{{ phoneCertificatedButtonText }}</span>
          </button>
        </div>

        <small
          class="form-text text-danger"
          *ngIf="registerForm.controls['token'].invalid"
          >인증번호 확인이 되지 않았습니다.</small
        >
      </div>
      <div class="form-group">
        <!-- <label for="inputEmail">이메일</label> -->
        <input
          type="email"
          #inputEmail
          autocomplete="off"
          formControlName="userEmail"
          class="form-control"
          id="inputEmail"
          placeholder="대표자 이메일"
        />
        <!-- <small class="form-text text-muted">수신가능한 이메일 주소를 입력하셔야 합니다.</small> -->
        <small class="form-text text-danger" *ngIf="isDuplicatedEmail"
          >이미 가입된 이메일입니다.</small
        >
        <small
          class="form-text text-danger"
          *ngIf="
            registerForm.controls['userEmail'].invalid &&
            registerForm.controls['userEmail'].touched
          "
        >
          정확한 이메일 주소를 입력해 주세요.</small
        >
      </div>
    </div>

    <!-- <div class="form-group form-check mt-5">
      <label class="form-check-label agree-form-control" for="exampleCheck1">
        <i class="icon icon-check-on" *ngIf="registerForm.controls['agree'].value === true"></i>
        <i class="icon icon-check-none" *ngIf="!!registerForm.controls['agree'].value === false"></i>
        <input
          style="visibility: hidden;"
          type="checkbox"
          formControlName="agree"
          class="form-check-input"
          id="exampleCheck1"
          checked
        />
        <a href="//www.ddocdoc.com/termsofservice" target="blank">서비스 이용약관</a>&nbsp;및&nbsp;
        <a href="//www.ddocdoc.com/privacypolicy" target="blank">개인정보 처리방침</a>에 동의합니다.</label
      >
    </div> -->

    <div class="form-group form-check mt-3" *ngIf="KIMES_TEMPORARY === true">
      <input
        type="checkbox"
        formControlName="isKimes"
        class="form-check-input"
        id="isKimesAgreement"
        checked
      />
      <label for="isKimesAgreement">개인정보 수집 및 이용에 대한 동의</label>
      <div class="mt-2 mb-3 bg-pale-gray border px-3 py-2">
        <p>
          정보수집, 이용목적<br />
          - 수집하는 개인정보 항목 : 성명, 휴대전화번호, 병원명<br />
          - 수집 및 이용 목적 : 키오스크 추첨 경품행사 응모<br />
          - 보유 및 이용 기간 : 경품 당첨 고지(3월 29일 이후로 예정) 후
          2주까지<br />
          <b>본 약관은 유비케어 고객처에만 해당됩니다 </b>
        </p>
      </div>

      <input
        type="checkbox"
        formControlName="isKimesExtra"
        class="form-check-input"
        id="isKimesAgreement2"
        checked
      />
      <label for="isKimesAgreement2">개인정보 위탁에 대한 동의</label>
      <div class="mt-2 bg-pale-gray border px-3 py-2">
        <p>
          정보위탁 목적<br />
          - 위탁 대상 : 유비케어<br />
          - 위탁하는 개인정보 항목 : 당첨 처의 성명, 휴대전화번호, 병원명<br />
          - 위탁 목적 : 경품(키오스크)의 실물 발송을 위한 연락처 제공<br />
          - 위탁처의 보유 및 이용 기간 : 경품의 실제 발송된 후 7일까지<br />
          <b>본 약관은 유비케어 고객처에만 해당됩니다 </b>
        </p>
      </div>
    </div>

    <button
      type="submit"
      class="btn"
      [class.invalid]="isInvalidRegister"
      (click)="registerAccount()"
    >
      가입 승인 요청
    </button>

    <div class="description">
      모든 항목을 입력해야 가입을 승인요청할 수 있습니다.
    </div>
    <!-- <span class="text-muted d-inline ml-2">이미 계정을 생성하셨다면 <a routerLink="/signin">로그인</a> </span> -->
  </form>
</div>
