import { createAction, props } from '@ngrx/store';
import { HashMap } from '~models/common.model';
import { OfficeWeeklyDetailData } from '~models/care-room.model';
import { OpenTimeUpdateParams } from '~models/reservation-timeline.model';
import { ScheduleSettingBatchModel } from '~models/reservation-schedule.model';

export enum ResScheduleActionTypes {
  LOAD = '[Res Schedule] Load',
  LOAD_SUCCESS = '[Res Schedule] Success',
  LOAD_FAIL = '[Res Schedule] Fail',
  READ = '[Res Schedule] Read',
  READ_SUCCESS = '[Res Schedule] Read Success',
  READ_FAIL = '[Res Schedule] Read Fail',
  UPDATE = '[Res Schedule] Update',
  UPDATE_SUCCESS = '[Res Schedule] Update Success',
  UPDATE_FAIL = '[Res Schedule] Update Fail',

  EDIT = '[Res Schedule] Edit',
  // APPLY = '[Res Schedule] Apply',

  BATCH_EDIT = '[Res Schedule] Batch Edit',
  BATCH_APPLY = '[Res Schedule] Batch Apply',
}

export const ResScheduleLoad = createAction(
  ResScheduleActionTypes.LOAD,
  props<{ params: { unitKey: string }; successMessage?: string }>()
);

export const ResScheduleLoadSuccess = createAction(
  ResScheduleActionTypes.LOAD_SUCCESS,
  props<{ payload: HashMap<OfficeWeeklyDetailData> }>()
);

export const ResScheduleLoadFail = createAction(
  ResScheduleActionTypes.LOAD_FAIL,
  props<any>()
);

export const ResScheduleRead = createAction(
  ResScheduleActionTypes.READ,
  props<{ params: { unitKey: string } }>()
);

export const ResScheduleReadSuccess = createAction(
  ResScheduleActionTypes.READ_SUCCESS,
  props<{ payload: HashMap<OfficeWeeklyDetailData>; message: string }>()
);

export const ResScheduleReadFail = createAction(
  ResScheduleActionTypes.READ_FAIL,
  props<any>()
);

export const ResScheduleUpdate = createAction(
  ResScheduleActionTypes.UPDATE,
  props<{ params: OpenTimeUpdateParams }>()
);

export const ResScheduleUpdateSuccess = createAction(
  ResScheduleActionTypes.UPDATE_SUCCESS,
  props<{ message: string }>()
);

export const ResScheduleUpdateFail = createAction(
  ResScheduleActionTypes.UPDATE_FAIL,
  props<any>()
);

export const ResScheduleEdit = createAction(
  ResScheduleActionTypes.EDIT,
  props<{ payload: HashMap<OfficeWeeklyDetailData> }>()
);

export const ResScheduleBatchEdit = createAction(
  ResScheduleActionTypes.BATCH_EDIT,
  props<{ payload: ScheduleSettingBatchModel }>()
);

export const ResScheduleBatchApply = createAction(
  ResScheduleActionTypes.BATCH_APPLY,
  props<{ payload: HashMap<OfficeWeeklyDetailData> }>()
);

export const ResScheduleActions = {
  ResScheduleLoad,
  ResScheduleLoadSuccess,
  ResScheduleLoadFail,
  ResScheduleRead,
  ResScheduleReadSuccess,
  ResScheduleReadFail,
  ResScheduleUpdate,
  ResScheduleUpdateSuccess,
  ResScheduleUpdateFail,
  ResScheduleEdit,
  ResScheduleBatchEdit,
  ResScheduleBatchApply,
};
