import {
  PaymentActions,
  PaymentActionTypes as ActionType,
} from '~actions/products/payment.actions';
import {
  PaymentActivationInfoWithId,
  PaymentActivationStateType,
} from '~models/PaymentActivationInfoWithId';
import { createReducer, on } from '@ngrx/store';

export interface PaymentState extends PaymentActivationInfoWithId {
  pending: boolean;
}

function getInitState(): PaymentState {
  return {
    hospital: '',
    agentType: '',
    cRegNo: '',
    representative: '',
    category: '',
    ynum: '',
    agency: '',
    agencyManager: '',
    applyDate: '',
    confirmDate: '',
    rejectDate: '',
    denyReason: '',
    cancelDate: '',
    terminateDate: '',
    createdAt: '',
    updatedAt: '',
    use: false,
    van: '',
    catId: '',
    dataProvisionAgreements: [''],
    hopeUseDate: '',
    _id: '',
    state: 'NONE',
    active: false,
    pending: false,
  };
}

const initState = getInitState();

export const reducer = createReducer(
  initState,
  on(
    PaymentActions.ToggleChange,
    PaymentActions.PaymentDataLoad,
    (state, action) => ({
      ...state,
      pending: true,
    })
  ),
  on(PaymentActions.PaymentDataLoadSuccess, (state, action) => {
    if (action.payload.state === 'REJECT') {
      return {
        ...state,
        ...action.payload,
        state: 'APPLY' as PaymentActivationStateType,
        pending: false,
      };
    }
    return {
      ...state,
      ...action.payload,
      pending: false,
    };
  }),
  on(PaymentActions.ToggleChangeSuccess, (state, action) => ({
    ...state,
    use: !state.use,
    pending: false,
  })),
  on(PaymentActions.PaymentService, (state, action) => ({
    ...state,
    active: true,
    ok: true,
    pending: true,
  })),
  on(PaymentActions.PaymentServiceSuccess, (state, action) => ({
    ...state,
    active: true,
    ok: true,
    pending: false,
  })),
  on(PaymentActions.HandleLoadFailure, (state, action) => ({
    ...state,
    pending: false,
  }))
);
