<section class="ddocdoc-set">
  <div class="content-box">
    <div class="content-layout">
      <h2 class="content-layout--title">
        똑닥이 처음이신
        <br />
        원장님들을 위한 간편 똑닥 SET!
      </h2>

      <div class="set-list">
        <div class="set-item">
          <div class="set-item--icon-box ring">
            <img
              class="set-item--icon-box_icon__ring"
              src="assets/img/signin/ringIcon.png"
              alt="종 아이콘"
            />
          </div>
          <div class="set-item--content-box">
            <p class="set-item--content-box_sub-title">
              검사 진료 하시는 원장님들을 위한
            </p>
            <h4 class="set-item--content-box_title">검사결과 알림 SET</h4>
            <p class="set-item--content-box_content">
              더 이상 검사결과 도착을 전화나 문자로 일일이 안내할 필요가
              없습니다.
              <br class="br-desktop" />
              똑닥이 환자에게 카카오 알림톡으로 자동 안내하고, 똑닥 접수/예약을
              통해 검사결과 확인을 위한 재내원을 자연스럽게 유도합니다.
              <br />
              병원과 환자 모두 편해지는 똑닥 MD의 추천 PICK
            </p>
          </div>
          <div class="set-item--tag-box">
            <p class="set-item--tag-box_item">접수/예약</p>
            <p class="set-item--tag-box_item">검사결과 알림</p>
          </div>
        </div>
        <div class="set-item blue">
          <div class="set-item--icon-box">
            <img
              class="set-item--icon-box_icon__card"
              src="assets/img/signin/cardIcon.png"
              alt="카드 아이콘"
            />
          </div>
          <div class="set-item--content-box">
            <p class="set-item--content-box_sub-title">
              운영으로 고민하시는 원장님들을 위한
            </p>
            <h4 class="set-item--content-box_title">모바일결제 SET</h4>
            <p class="set-item--content-box_content">
              환자 정보 입력하고, 진료 기록하고, 결제 안내하고….
              <br />
              반복적인 단순 업무는 이제 그만! EMR과 똑닥 앱이 연동되어 병원과
              환자 모두 간편한 접수/예약부터 실물카드 없이 한번에 간편결제가
              가능한 모바일 결제까지. 실손보험 청구용 서류도 모바일로 발급되는
              모바일 결제 SET로 스마트하고 편리한 의원이 되어보세요.
            </p>
          </div>
          <div class="set-item--tag-box">
            <p class="set-item--tag-box_item">접수/예약</p>
            <p class="set-item--tag-box_item">모바일 결제</p>
            <p class="set-item--tag-box_item">모바일 서류발급</p>
          </div>
        </div>
      </div>

      <div class="more-info">
        <p class="more-info--content">똑닥에 대해 더 알고 싶으신가요?</p>

        <div class="more-info--btn-list">
          <a href="https://www.ddocdoc.com/ddocdoc_register" target="blank">
            <button class="more-info--btn-list_item white">
              <span> 서비스 상담 문의하기 </span>
              <i class="fas fa-angle-right"></i>
            </button>
          </a>
          <a href="/signup">
            <button class="more-info--btn-list_item black">
              <span> 무료로 시작하기 </span>
              <i class="fas fa-angle-right"></i>
            </button>
          </a>
        </div>
      </div>

      <hr class="hr-mobile" />

      <div class="call">
        <a class="call-number" href="tel:1899-6826">
          <i class="icon icon-call"></i>
          <span> 1899-6826 </span>
        </a>
        <p class="call-content">
          똑닥 고객 센터로 문의 주시면 친절하게 상담해드립니다.
        </p>
      </div>
      <div class="content--youtubeTrigger">
        <app-signin-youtube-trigger></app-signin-youtube-trigger>
      </div>
    </div>
  </div>
</section>
