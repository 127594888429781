<div style="position: relative">
  <label for="inputHospital" *ngIf="isShowLabel"
    >병원 선택 (EX.연세정형외과)</label
  >
  <input
    type="text"
    class="form-control"
    id="inputHospital"
    [placeholder]="this.placeholder"
    [ngModel]="hospitalTitle"
    (ngModelChange)="handleValueChange($event)"
    autocomplete="off"
    (blur)="onBlurInput()"
  />

  <!--
  <small class="form-text text-muted">가입 병원을 검색하신 후 선택해주세요.</small>
  <small style="display: inline-block;">선택된 병원 :</small>
  <small class="form-text text-primary" style="display: inline-block">
    <span [ngClass]="{ 'red-font': !isSelect }">{{ selectedHospitalSpan }}</span>
  </small>
  -->

  <div *ngIf="existsResult" class="list-group result-list result-list-empty">
    <a class="list-group-item list-group-item-action" (click)="hideResult()">
      <p class="m-0">검색 결과가 없습니다.</p>
    </a>
  </div>

  <div
    *ngIf="hasHospitals && hospitals.length !== 0 && isShowResultList"
    class="list-group result-list result-list-items"
  >
    <div
      class="list-group-item list-group-item-action"
      *ngFor="let item of hospitals"
      (click)="selectedHospital(item)"
    >
      <p class="m-0">{{ item.title }}</p>
      <small class="text-muted">{{ item.address }}</small>
    </div>
  </div>
</div>
