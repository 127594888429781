import { createAction, props } from '@ngrx/store';
import {
  KioskMainConfig,
  KioskMainConfigCommon,
  KioskMainSpecificConfig,
} from '~models/kiosk-main-config.model';

export const enum KioskMainConfigActionType {
  FETCH_KIOSK_MAIN_COMMON_CONFIG = 'FETCH_KIOSK_MAIN_COMMON_CONFIG',
  FETCH_KIOSK_MAIN_COMMON_CONFIG_SUCCESS = 'FETCH_KIOSK_MAIN_COMMON_CONFIG_SUCCESS',

  FETCH_KIOSK_MAIN_ALL_CONFIG_LIST = 'FETCH_KIOSK_MAIN_ALL_CONFIG_LIST',
  FETCH_KIOSK_MAIN_ALL_CONFIG_LIST_SUCCESS = 'FETCH_KIOSK_MAIN_ALL_CONFIG_LIST_SUCCESS',

  FETCH_KIOSK_MAIN_SPECIFIC_CONFIG_LIST = 'FETCH_KIOSK_MAIN_SPECIFIC_CONFIG_LIST',
  FETCH_KIOSK_MAIN_SPECIFIC_CONFIG_LIST_SUCCESS = 'FETCH_KIOSK_MAIN_SPECIFIC_CONFIG_LIST_SUCCESS',

  UPDATE_KIOSK_MAIN_CONFIG = 'UPDATE_KIOSK_MAIN_CONFIG',
  UPDATE_KIOSK_MAIN_CONFIG_SUCCESS = 'UPDATE_KIOSK_MAIN_CONFIG_SUCCESS',

  DELETE_KIOSK_MAIN_CONFIG = 'DELETE_KIOSK_MAIN_CONFIG',
  DELETE_KIOSK_MAIN_CONFIG_SUCCESS = 'DELETE_KIOSK_MAIN_CONFIG_SUCCESS',

  CREATE_KIOSK_MAIN_CONFIG = 'CREATE_KIOSK_MAIN_CONFIG',
  CREATE_KIOSK_MAIN_CONFIG_SUCCESS = 'CREATE_KIOSK_MAIN_CONFIG_SUCCESS',

  ERROR = 'ERROR',
}

export const KioskMainRequestFail = createAction(
  KioskMainConfigActionType.ERROR,
  props<{ error: any }>()
);

export const KioskMainCommonConfig = createAction(
  KioskMainConfigActionType.FETCH_KIOSK_MAIN_COMMON_CONFIG
);

export const KioskMainCommonConfigSuccess = createAction(
  KioskMainConfigActionType.FETCH_KIOSK_MAIN_COMMON_CONFIG_SUCCESS,
  props<{ payload: KioskMainConfigCommon }>()
);

export const KioskMainAllConfigList = createAction(
  KioskMainConfigActionType.FETCH_KIOSK_MAIN_ALL_CONFIG_LIST
);

export const KioskMainAllConfigListSuccess = createAction(
  KioskMainConfigActionType.FETCH_KIOSK_MAIN_ALL_CONFIG_LIST_SUCCESS,
  props<{ payload: KioskMainConfig[] }>()
);

export const KioskMainSpecificConfigList = createAction(
  KioskMainConfigActionType.FETCH_KIOSK_MAIN_SPECIFIC_CONFIG_LIST
);

export const KioskMainSpecificConfigListSuccess = createAction(
  KioskMainConfigActionType.FETCH_KIOSK_MAIN_SPECIFIC_CONFIG_LIST_SUCCESS,
  props<{ payload: KioskMainSpecificConfig[] }>()
);

export const KioskMainSpecificConfigUpdate = createAction(
  KioskMainConfigActionType.UPDATE_KIOSK_MAIN_CONFIG,
  props<{ id: string; changes: Partial<KioskMainSpecificConfig> }>()
);

export const KioskMainSpecificConfigUpdateSuccess = createAction(
  KioskMainConfigActionType.UPDATE_KIOSK_MAIN_CONFIG_SUCCESS,
  props<{ id: string; changes: Partial<KioskMainSpecificConfig> }>()
);

export const KioskMainConfigDelete = createAction(
  KioskMainConfigActionType.DELETE_KIOSK_MAIN_CONFIG,
  props<{ id: string }>()
);

export const KioskMainConfigDeleteSuccess = createAction(
  KioskMainConfigActionType.DELETE_KIOSK_MAIN_CONFIG_SUCCESS,
  props<{ id: string }>()
);

export const KioskMainConfigCreate = createAction(
  KioskMainConfigActionType.CREATE_KIOSK_MAIN_CONFIG,
  props<{ title: string }>()
);

export const KioskMainConfigCreateSuccess = createAction(
  KioskMainConfigActionType.CREATE_KIOSK_MAIN_CONFIG_SUCCESS,
  props<{ payload: KioskMainSpecificConfig }>()
);

export const KioskMainConfigActions = {
  KioskMainRequestFail,
  KioskMainCommonConfig,
  KioskMainCommonConfigSuccess,
  KioskMainAllConfigList,
  KioskMainAllConfigListSuccess,
  KioskMainSpecificConfigList,
  KioskMainSpecificConfigListSuccess,
  KioskMainSpecificConfigUpdate,
  KioskMainSpecificConfigUpdateSuccess,
  KioskMainConfigDelete,
  KioskMainConfigDeleteSuccess,
  KioskMainConfigCreate,
  KioskMainConfigCreateSuccess,
};
