import { CreateInfo } from '~models/auth.model';
import { Store } from '@ngrx/store';
import { Component } from '@angular/core';

import { AuthActions } from '~actions/auth/auth.action';
import * as fromAuth from '~reducers/auth';
import { SignupTermValues } from '../../../../components/signup-terms/signup-terms.component';

@Component({
  selector: 'app-sign-up-page',
  templateUrl: './signup-page.component.html',
})
export class SignUpPageComponent {
  ynum?: string;
  marketingAgreement = false;
  signUpStep = 1;
  // isGovUntactAgreed = false;
  isGCWellbeing = false;

  constructor(private store: Store<fromAuth.AuthInfoState>) {}

  onSubmit(submitData: CreateInfo): void {
    const data = {
      ...submitData,
      ynum: this.ynum,
      mainCategory: submitData.mainCategory._id,
      marketingAgreement: this.marketingAgreement,
      // isGovUntactAgreed: this.isGovUntactAgreed,
      isGCWellbeing: this.isGCWellbeing,
    };
    this.store.dispatch(AuthActions.AccountCreate({ payload: data }));
  }

  onTerms({ ynum, marketingAgreement }: SignupTermValues): void {
    this.ynum = ynum;
    this.marketingAgreement = marketingAgreement;
    this.signUpStep = 2;
    // this.isGovUntactAgreed = isGovUntactAgreed;
  }
}
