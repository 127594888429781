import { createReducer, on } from '@ngrx/store';
import { StatusResultNotifiedActions } from '~actions/products/status-result-notified.action';
import {
  ResultNotifiedItem,
  ResultNotifiedSearchUiQuery,
} from '~models/status-result-notified.model';
import { createStatusResultNotifiedSearchUiQuery } from '~store/services/status/result-notified.create';

export interface StatusResultNotifiedState {
  query: ResultNotifiedSearchUiQuery;
  items: ResultNotifiedItem[];
  totalCount: number;
  pending: boolean;
}

export function getInitState(): StatusResultNotifiedState {
  return {
    query: createStatusResultNotifiedSearchUiQuery(),
    items: [],
    totalCount: 0,
    pending: false,
  };
}

export const reducer = createReducer(
  getInitState(),
  on(
    StatusResultNotifiedActions.StatusResultNotifiedLoadList,
    (state, action) => ({
      ...state,
      query: action.payload,
      pending: true,
    })
  ),
  on(
    StatusResultNotifiedActions.StatusResultNotifiedLoadListSuccess,
    (state, action) => ({
      ...state,
      items: action.payload.items,
      totalCount: action.payload.totalCount,
      pending: false,
    })
  ),
  on(StatusResultNotifiedActions.StatusResultNotifiedFail, () => ({
    ...getInitState(),
  }))
);
