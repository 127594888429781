import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

const SORT_STATE = ['NONE', 'ASCENDING', 'DESCENDING'];

@Component({
  selector: 'app-table-header-sort',
  templateUrl: './table-header-sort.component.html',
  styleUrls: ['./table-header-sort.component.scss'],
})
export class TableHeaderSortComponent implements OnInit {
  _sortState: string;
  @Input() set sortState(sortState: string) {
    this._sortState = sortState;
  }

  get sortState() {
    return this._sortState;
  }

  @Output() changeSortState = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onSortButtonClick() {
    this.changeState();
  }

  changeState() {
    switch (this._sortState) {
      case 'NONE':
        this._sortState = 'ASCENDING';
        break;
      case 'ASCENDING':
        this._sortState = 'DESCENDING';
        break;
      case 'DESCENDING':
      default:
        this._sortState = 'NONE';
        break;
    }

    this.changeSortState.emit(this._sortState);
  }
}
