import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'apmFormat'
})
export class ApmFormat implements PipeTransform {
  // timeCheck(time) {
  //     if ((time === 0) || (time === 12)) {
  //         return '12';
  //     }
  //     return time < 10 ? '0' + time :
  //            time === 10 ? time : time === 11 ? time : time >= 12 && time < 22 ? '0' + (time - 12) : (time - 12) + '';
  // }

  transform(time: string): string {
    if (!time) {
      return '00:00';
    }
    return time;
    // const date = parseInt(time.substring(0, 2), 10);
    // const sTime = this.timeCheck(date) + time.substring(2, 5);

    // if (date < 12) {
    //     return sTime + ' AM';
    // }

    // return sTime + ' PM';
  }

  // transform(time: string): string {
  //     if (!time) {
  //         return '12:00 AM';
  //     }
  //     const date = parseInt(time.substring(0, 2), 10);
  //     const sTime = this.timeCheck(date) + time.substring(2, 5);

  //     if (date < 12) {
  //         return sTime + ' AM';
  //     }

  //     return sTime + ' PM';
  // }
}
