import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weekName'
})
export class WeekNamePipe implements PipeTransform {
  transform(value: number): string {
    const MAP_WEEK_NAME = {
      0: '일',
      1: '월',
      2: '화',
      3: '수',
      4: '목',
      5: '금',
      6: '토',
    };

    return MAP_WEEK_NAME[value];
  }
}
