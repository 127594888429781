import { isNil as _isNil, uniqBy as _uniqBy } from 'lodash';
import { HashMap } from '~models/common.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Schedule } from '~models/reservation-schedule-v2.model';

export function makeNumberArray(start: number, end: number, step: number = 1) {
  const aRet: number[] = [];

  for (let i = start; i <= end; i += step) {
    aRet.push(i);
  }

  return aRet;
}

export function copyArray<T>(arr: T[]): T[] {
  return arr.map(item => Object.assign({}, item));
}

export function findIndex<T>(
  arr: T[],
  callback: (value: T) => boolean
): number {
  const len = arr.length;

  let i = 0;
  while (i < len) {
    if (callback(arr[i])) {
      break;
    }
    i++;
  }
  return i;

  // for (let i = 0; i < len; i++) {
  //   if (callback(arr[i])) {
  //     return i;
  //   }
  // }
}

export function arrayToMap<T>(arr: T[], field: string): { [field: string]: T } {
  const mRet: { [field: string]: T } = {};

  arr.forEach((item: any) => {
    const temp = item[field];
    mRet[temp] = item;
  });

  return mRet;
}

export function arrayToBoolMap(arr: any[]): { [val: string]: boolean } {
  const mRet: { [field: string]: boolean } = {};

  arr.forEach(item => {
    mRet[item + ''] = true;
  });

  return mRet;
}

/**
 * 해시맵으로 사용되는 객체가 가진 key 를 순회하여 콜백을 수행한다.
 * @param map key 를 기준으로 순회 하고 싶은 자료
 * @param fn 순회 할 때 마다 수행 될 콜백 함수
 */
export function mapForEach<T>(
  map: HashMap<T>,
  fn: (item: T, key: string) => void
) {
  Object.keys(map).forEach(key => fn(map[key], key));

  return map;
}

/**
 * 해시맵으로 사용되는 객체가 가진 key 로 순회하며 콜백 내용을 기준으로 필터링 된 새로운 해시맵을 반환한다.
 * @param map 필터링 하고 싶은 객체
 * @param fn key 를 기준으로 순회하여 boolean 형으로 결과를 보내 줄 콜백 함수
 */
export function mapFilter<T>(
  map: HashMap<T>,
  fn: (item: T, key: string) => boolean
) {
  return Object.keys(map).reduce((acc, key) => {
    if (fn(map[key], key)) {
      acc[key] = map[key];
    }
    return acc;
  }, {} as HashMap<T>);
}

/**
 * 해시맵으로 사용되는 객체의 key 를 number 로 바꾼 뒤 그 내용을 정렬하여 반환 한다.
 *
 * 만약 객체 데이터가 비어있거나 의미없는 내용일 경우 빈 배열을 반환한다.
 * @param data 정렬된 숫자 key 로 받고 싶은 객체 데이터
 */
export function sortedNumberKeys(data: any) {
  try {
    return Object.keys(data)
      .map(key => Number(key))
      .sort((a, b) => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
  } catch (error) {
    return [];
  }
}

/**
 * 객체로 되어있는 배열을 프로퍼티 하나를 기준으로하여 정렬후 반환 한다.
 *
 * 만약 객체 데이터가 비어있거나 의미없는 내용일 경우 빈 배열을 반환한다.
 * @param data 소팅할 배열
 * @param key 비교할 프로퍼티 키
 */
export function sortByProperty<T>(data: T[], key: string): T[] {
  try {
    return data.sort((left: any, right: any) => {
      if (!_isNil(left[key]) && !_isNil(right[key])) {
        return left[key] > right[key] ? 1 : -1;
      }
      return 0;
    });
  } catch (err) {
    return [];
  }
}

/**
 * 해시맵으로 사용되는 객체의 key 를 문자열 배열로 반환한다.
 *
 * 만약 객체가 비어있거나 의미 없는 자료일 경우 빈 배열을 반환한다.
 * @param data 키 값을 배열로 가져오고 싶은 객체
 */
export function mapKeys(data: any) {
  if (!data) {
    return [];
  }
  try {
    return Object.keys(data);
  } catch (error) {
    return [];
  }
}

export function uniqueArray<T>(arrArg: T[]) {
  return arrArg.filter((elem, pos, arr) => {
    return arr.indexOf(elem) === pos;
  });
}

export function deepCopy<T>(val: T): T {
  return JSON.parse(JSON.stringify(val));
}

export function forEachPart<T>(
  arr: T[],
  startIndex: number,
  endIndex: number,
  callback: (item: T, index: number, arr: T[]) => void
) {
  if (startIndex <= endIndex) {
    for (let i = startIndex; i <= endIndex; i++) {
      callback(arr[i], i, arr);
    }
  } else if (startIndex > endIndex) {
    for (let i = endIndex; i <= startIndex; i++) {
      callback(arr[i], i, arr);
    }
  }
}

export function errorMessage(err: any, finalHint: string = 'no hint'): string {
  const commonMsg = '오류 발생: ';
  let solutionMsg = '';

  try {
    if (!err) {
      return commonMsg + finalHint;
    }

    if (err.message && !err.error) {
      return err.message;
    }

    if (err.error) {
      if (err.error.message) {
        return err.error.message;
      }
      if (err.error.error) {
        if (err.error.error.message) {
          return err.error.error.message;
        }
      }
    }
    solutionMsg = getSolutionMessage(finalHint, JSON.stringify(err));

    return commonMsg + finalHint + '\n' + solutionMsg;
  } catch (error) {
    return commonMsg + finalHint;
  }
}

const findErrorMessage = <U = any>(
  error: U,
  depth: number,
  maxDepth = 1
): string => {
  const safeObject = error || {};
  if (maxDepth < depth) {
    return '';
  }

  if ('message' in safeObject) {
    // tslint:disable-next-line: no-string-literal
    return safeObject['message'];
  }

  if ('error' in safeObject) {
    // tslint:disable-next-line: no-string-literal
    return findErrorMessage(safeObject['error'], depth + 1, maxDepth);
  }

  return '';
};

export const getErrorMessage = (
  { error = {} }: HttpErrorResponse,
  maxDepth = 1
): string => {
  return findErrorMessage(error, 0, maxDepth);
};

const MSG_SOLUTION_IE_URL_UTF8 =
  // tslint:disable-next-line: max-line-length
  '아래 내용으로 조치 바랍니다.\n* 인터넷옵션 > "고급"탭 > 목록에서 "국제*"를 찾음 > 아래 내용을 체크 하기\n[v] URL 경로를 UTF-8로 보내기\n[v] 인코딩된 주소에 대한 알림 표시줄 표시\n"확인" 후 인터넷창 끄고 재시작';

function getSolutionMessage(code: string, json: string) {
  const types = ['symptom59', 'careroom-92'];

  if (types.indexOf(code) >= 0) {
    return `${MSG_SOLUTION_IE_URL_UTF8}\n만약 위 내용으로도 해결되지 않는다면 다음 내용을 고객센터 담당자에게 보여주십시요.\n----------------\n${json}`;
  }

  return json;
}

/**
 * 특정 url 에 대하여 파일 다운로드를 수행한다.
 * @param url 파일이 존재하는 경로
 * @param byNewWindow 새창에서 수행하는지의 여부. 기본 false.
 */
export function fileDownload(url: string, byNewWindow = false) {
  if (byNewWindow) {
    window.open(url);
  } else {
    window.location.href = url;
  }
}

/**
 * 특정 url 에 대하여 새창을 열어서 수행한다.
 * @param url 새창에서 수행 될 경로.
 * @param width 새창 가로 크기. 기본 600
 * @param height 새창 세로 크기. 기본 800
 */
export function openWindow(url: string, width = 600, height = 800) {
  window.open(url, '_blank', `width=${width},height=${height}`);
}

/**
 * undeifined, null 체크 후 원본값 또는 대체할 값을 return 하는 함수
 * lazy evaluation 기법 적용, 성능 개선
 * @param source nil check 대상
 * @param otherLazy target이 undefined or null 의 경우 실행될 callback function 정의
 */
export function getOrElse<T>(source: T, otherLazy: () => T): T {
  return _isNil(source) ? otherLazy() : source;
}

/**
 * predicate 평가식 실행 후 true 면 source, false 면 otherLazy 실행
 * @param source
 * @param predicate
 * @param otherLazy
 */
export function getOrElsePredicate<T>(
  source: T,
  predicate: (source: T) => boolean,
  otherLazy: () => T
): T {
  return predicate(source) ? source : otherLazy();
}

/**
 * 객체형 파라미터를 하나의 문자열 쿼리 파라미터로 바꿔준다.
 * @param params 문자열 쿼리 파라미터로 바꿀 객체.
 */
export function serializeParams(params: any) {
  if (!params || typeof params !== 'object') {
    return '';
  }

  try {
    return Object.keys(params)
      .reduce((acc, key) => {
        acc.push(`${key}=${params[key]}`);

        return acc;
      }, [] as string[])
      .join('&');
  } catch (error) {
    return '';
  }
}

/**
 * url형식인지 체크 (http://, https:// 로 시작하는지 체크)
 */
export function checkUrl(url: string): boolean {
  const expUrl = /(http(s)?:\/\/)/;
  return expUrl.test(url);
}

export class SoundMeter {
  context: AudioContext;

  instant = 0.0;

  // slow = 0.0;
  // clip = 0.0;
  script: ScriptProcessorNode;

  mic?: MediaStreamAudioSourceNode;

  constructor(context: AudioContext) {
    this.context = context;
    const scriptNode = context.createScriptProcessor(2048, 1, 1);
    scriptNode.addEventListener('audioprocess', event => {
      let i;
      let sum = 0.0;
      // let clipCount = 0;
      const input = event.inputBuffer.getChannelData(0);
      for (i = 0; i < input.length; i += 1) {
        sum += input[i] * input[i];
        // if (Math.abs(input[i]) > 0.99) {
        // clipCount += 1;
        // }
      }
      this.instant = Math.sqrt(sum / input.length);
      // this.slow = 0.95 * this.slow + 0.05 * this.instant;
      // this.clip = clipCount / input.length;
    });
    this.script = scriptNode;
  }

  connectToSource(stream: MediaStream, callback?: () => void) {
    console.log('SoundMeter connecting');
    this.mic = this.context.createMediaStreamSource(stream);
    this.mic.connect(this.script);
    this.script.connect(this.context.destination);
    if (callback) {
      callback();
    }
  }

  stop() {
    console.log('SoundMeter stopping');
    if (this.mic) {
      this.mic.disconnect();
      this.script.disconnect();
    }
  }
}

/**
 * 예약 스케줄 업데이트 전 중복 제거
 * @param schedules
 */
export const filterDuplicateSchedules = (schedules: Schedule[]): Schedule[] => {
  return schedules.map(editorSchedule => {
    const { enableTime } = editorSchedule;
    return {
      ...editorSchedule,
      enableTime: _uniqBy(enableTime, time => {
        return `${time.startTime}/${time.endTime}`;
      }),
    };
  });
};
