import { CrmPushTargetUserActions } from '~store/actions/products/hospital-crm-push-target-user.actions';
import { PushTargetUser } from '~models/hospital-crm.model';
import { createReducer, on } from '@ngrx/store';

/**
 * 푸시 발송 대상 유저 Store
 * @name CrmPushTargetUserState
 * @author Hong-ki Min
 */
export interface CrmPushTargetUserState extends PushTargetUser {
  pending: boolean;
}

const initState: CrmPushTargetUserState = {
  items: [],
  totalCount: 0,
  pending: false,
};

export const reducer = createReducer(
  initState,
  on(CrmPushTargetUserActions.CrmPushTargetUserLoad, (state, action) => ({
    ...state,
    pending: true,
  })),
  on(CrmPushTargetUserActions.CrmPushTargetUserLoadSuccess, (state, action) => {
    return {
      ...state,
      ...action.payload,
      pending: false,
    };
  }),
  on(CrmPushTargetUserActions.CrmPushTargetUserReqFail, (state, action) => ({
    ...state,
    pending: false,
  }))
);
