import { createAction, props } from '@ngrx/store';
import { ExpenseCategorySearchResultUiModel } from '~models/expense-category.model';

export enum ExpenseCategorySearchActionType {
  EXPENSE_SEARCH_CATEGORY_LOAD = '[Expense Category Search] Search',
  EXPENSE_SEARCH_CATEGORY_LOAD_SUCCESS = '[Expense Category Search] Search Success',
  REQ_FAIL = '[Expense Category Search] Request Fail',
}

export const ExpenseCategorySearchLoadSuccess = createAction(
  ExpenseCategorySearchActionType.EXPENSE_SEARCH_CATEGORY_LOAD_SUCCESS,
  props<{ payload: ExpenseCategorySearchResultUiModel[] }>()
);

export const ExpenseCategorySearchLoad = createAction(
  ExpenseCategorySearchActionType.EXPENSE_SEARCH_CATEGORY_LOAD,
  props<{ payload: string }>()
);

export const ExpenseCategorySearchActions = {
  ExpenseCategorySearchLoadSuccess,
  ExpenseCategorySearchLoad,
};
