import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pending-spinner',
  template: `
    <div class="spinner" *ngIf="show">
      <div class="text-center">
        <i class="fas fa-spinner"></i>
        <p *ngIf="!!message">{{ message }}</p>
      </div>
    </div>
  `,
  styleUrls: ['./pending-spinner.component.scss']
})
export class PendingSpinnerComponent {
  @Input() show = false;
  @Input() message = '';
}
