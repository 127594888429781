import { ResetPwActions } from '~actions/auth/reset-pw.action';
import { errorMessage } from '~shared/service/util';
import { createReducer, on } from '@ngrx/store';

export interface ResetPwState {
  loading: boolean;
  showComplete: boolean;
  error: string | null;
}

const initialState: ResetPwState = {
  loading: false,
  showComplete: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(ResetPwActions.ResetPw, (state, action) => ({
    loading: true,
    showComplete: false,
    error: null,
  })),
  on(ResetPwActions.ResetPwInit, (state, action) => ({ ...initialState })),
  on(ResetPwActions.ResetPwComplete, (state, action) => ({
    loading: false,
    showComplete: true,
    error: null,
  })),
  on(ResetPwActions.ResetPwFailure, (state, action) => ({
    loading: false,
    showComplete: false,
    error: errorMessage(action.payload, 'reset-pw.33'),
  }))
);

export const getError = (state: ResetPwState) => state.error;
