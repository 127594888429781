import type { TossErrorKey } from './payments.model';

export const TelemedicineRequiredStatus = {
  NOT_SET: 'NOT_SET', // 미설정
  REJECTED: 'REJECTED', // 설정 실패
  NOT_USE: 'NOT_USE', // 설정 완료 (사용대기)
  APPROVED: 'APPROVED', // 설정 완료 (사용중)
} as const;

export interface TelemedicineRequiredSettingInfo {
  status: keyof typeof TelemedicineRequiredStatus;
  reason?: TossErrorKey;
}

export interface TelemedicineRequiredSettingFetchRes {
  payment: TelemedicineRequiredSettingInfo;
  paper: TelemedicineRequiredSettingInfo;
  emrVersion?: TelemedicineRequiredSettingInfo;
}

export interface TelemedicineRoomSetting {
  unitKey: string;
  phone?: string;
  usePaper?: boolean;
  useTelemedicine: boolean;
  usePhone?: boolean;
  useVideo?: boolean;
  careRoomName?: string;
}

export interface TelemedicineUseReq {
  use: boolean;
}

export interface TelemedicineBulkUpdateReq {
  units: TelemedicineRoomSetting[];
}

export interface TelemedicineRoomsSettingsFetchRes {
  use: boolean;
  units: TelemedicineRoomSetting[];
}
