import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-signin-header',
  templateUrl: './signin-header.component.html',
  styleUrls: ['./signin-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SigninHeaderComponent implements OnInit {
  @Output() login = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onLoginClick(): void {
    this.login.emit();
  }
}
