import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Directive({
  selector: '[appClickOutSide]',
})
export class ClickOutSideDirective {
  @Input() isTargetOpen: boolean;
  @Output() handleTargetOpen = new EventEmitter<boolean>();

  constructor(private ref: ElementRef<HTMLDivElement>) {}

  @HostListener('document:click', ['$event'])
  onClickModalOutSide(event: MouseEvent): void {
    const clickedTargetElemRef = new ElementRef<any>(event.target);

    if (
      this.isTargetOpen &&
      this.ref.nativeElement.contains(clickedTargetElemRef.nativeElement) ===
        false
    ) {
      this.handleTargetOpen.emit(false);
    }
  }
}
