import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CareRoom } from '~models/care-room.model';
import { CareRoomActions } from '~actions/products/care-room.actions';
import { createReducer, on } from '@ngrx/store';

export interface EmrCareRoomState extends EntityState<CareRoom> {
  loading: boolean;
  loadingUnitKey: string;
}

export const emrCareRoomAdapter: EntityAdapter<CareRoom> = createEntityAdapter<
  CareRoom
>({
  selectId: careRoom => careRoom.key,
});

const initialState: EmrCareRoomState = emrCareRoomAdapter.getInitialState({
  loading: false,
  loadingUnitKey: '',
});

export const reducer = createReducer(
  initialState,
  on(CareRoomActions.CareRoomEmrFetchAll, state => ({
    ...state,
    loading: true,
  })),
  on(
    CareRoomActions.CareRoomEmrFetchAllSuccess,
    (state, { payload: { units: careRooms } }) => {
      const keyEncodedCareRooms = careRooms.map(careRoom => {
        return {
          ...careRoom,
          key: encodeURIComponent(careRoom.key),
        };
      });
      return emrCareRoomAdapter.setAll(keyEncodedCareRooms, {
        ...state,
        loading: false,
      });
    }
  ),
  on(CareRoomActions.CareRoomEmrActive, (state, { payload: { unitKey } }) => {
    return {
      ...state,
      loading: true,
      loadingUnitKey: unitKey,
    };
  }),
  on(
    CareRoomActions.CareRoomEmrActiveSuccess,
    (state, { payload: { unitKey, active } }) =>
      emrCareRoomAdapter.updateOne(
        {
          id: unitKey,
          changes: { active },
        },
        { ...state, loading: false, loadingUnitKey: '' }
      )
  ),
  on(CareRoomActions.CareRoomUpdate, (state, action) =>
    emrCareRoomAdapter.updateOne(
      {
        id: action.payload.id,
        changes: action.payload.changes,
      },
      {
        ...state,
        loading: true,
      }
    )
  )
);
