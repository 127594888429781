import {
  CrmSymptomCategoryItemModel,
  CrmSymptomItemModel,
  HospitalScheduleCreateModel,
  HospitalScheduleFormModel,
} from '~models/hospital-crm.model';
import { CrmAddScheduleActions } from '~actions/products/hospital-crm-schedule-add.actions';
import { createReducer, on } from '@ngrx/store';

export interface HospitalCrmScheduleAddState {
  schedule: HospitalScheduleCreateModel | null;
  formData: HospitalScheduleFormModel | null;
  categories: CrmSymptomCategoryItemModel[];
  symptoms: CrmSymptomItemModel[];
}

const initState: HospitalCrmScheduleAddState = {
  schedule: null,
  formData: null,
  categories: [],
  symptoms: [],
};

export const reducer = createReducer(
  initState,
  on(CrmAddScheduleActions.CrmMakeScheduleDataSuccess, (state, action) => ({
    ...state,
    schedule: action.data,
  })),
  on(CrmAddScheduleActions.CrmSaveFormData, (state, action) => ({
    ...state,
    formData: action.formData,
  })),
  on(CrmAddScheduleActions.CrmResetScheduleData, (state, action) => ({
    ...state,
    formData: null,
    schedule: null,
  })),
  on(CrmAddScheduleActions.CrmCategoryLoadSuccess, (state, action) => ({
    ...state,
    categories: action.categories,
  })),
  on(CrmAddScheduleActions.CrmSymptomLoadSuccess, (state, action) => ({
    ...state,
    symptoms: action.symptoms,
  }))
);
