import { HashMap } from '~models/common.model';
import { OfficeWeeklyDetailData } from '~models/care-room.model';
import {
  Holiday,
  Schedule,
  ScheduleWeekCopyPayload,
  TimeCell,
  TimelineInfo,
  TimeRow,
} from '~models/reservation-timeline.model';
import { ResTimelineScheduleState } from '~reducers/products/res-timeline-schedule.reducer';
import { filterEnableTimesByOffData } from './res-timeline-schedule.enableTime';
import { toLinearCells } from './res-timeline-schedule.timelineinfo';
import {
  findWeekName,
  hasPreventCellWithStartEnd,
  makeDayMatrix,
} from './res-timeline-schedule.util';

export function copyScheduleByDays(
  state: ResTimelineScheduleState,
  args: {
    src: number;
    dist: number[];
  }
) {
  const {
    schedules,
    year,
    month,
    openTimeMap,
    // holidayMap,
  } = state;
  const { src, dist } = args;
  const mSchedules = schedules;
  const enableTime = mSchedules[src].enableTime;
  const mNew: HashMap<Schedule> = { ...schedules };

  dist.forEach(day => {
    const weekName = findWeekName(year, month, day);
    const fnFilter = filterEnableTimesByOffData(openTimeMap[weekName], null);
    let schedule = mSchedules[day];

    if (!schedule) {
      schedule = {
        day,
        enableTime: enableTime.filter(fnFilter),
      };
      mNew[day] = schedule;
    } else {
      schedule.enableTime = enableTime.filter(fnFilter);
    }
  });

  return mNew;
}

export function copyScheduleByWeeks(
  state: ResTimelineScheduleState,
  args: ScheduleWeekCopyPayload
) {
  const { schedules, holidayMap, openTimeMap, year, month } = state;
  const { src, dist } = args;
  const mSchedules = schedules;
  const mNew: HashMap<Schedule> = { ...schedules };
  // const aNew: Schedule[] = [].concat(schedules);

  dist.forEach((row, rowIndex) => {
    row.forEach((day, colIndex) => {
      const iSrcDay = src[colIndex];

      if (day < 0 || iSrcDay < 0) {
        return;
      }
      if (holidayMap[day]) {
        return;
      }

      let schedule = mSchedules[day];
      const srcSchedule = mSchedules[iSrcDay];
      const weekName = findWeekName(year, month, day);
      const fnFilter = filterEnableTimesByOffData(openTimeMap[weekName], null);

      const enableTime = srcSchedule ? srcSchedule.enableTime : [];

      if (!schedule) {
        schedule = {
          day,
          enableTime: enableTime.filter(fnFilter),
        };
        mNew[day] = schedule;
      } else {
        schedule.enableTime = enableTime.filter(fnFilter);
      }
    });
  });

  return mNew;

  // return aNew.sort(sortSchedules);
}

function getTimeRowsByDay(data: TimelineInfo[][], day: number): TimeRow[] {
  const firstWeekLastDay = data[0][6].day;
  const diffDay = day - firstWeekLastDay;
  let iWeekIndex = 0;
  let iColIndex = 0;

  if (diffDay === 0) {
    return data[0][6].timeRows;
  }

  if (diffDay < 0) {
    return data[0][6 + diffDay].timeRows;
  }

  if (diffDay > 0) {
    iWeekIndex = Math.floor((diffDay - 1) / 7) + 1;
    iColIndex = (diffDay - 1) % 7;

    // console.log('error', day, iWeekIndex, iColIndex, data);

    return data[iWeekIndex][iColIndex].timeRows;
  }

  return [];
}

function findTimeline(data: TimelineInfo[][]) {
  const maxRow = data.length;
  const maxCol = 7;
  let rowIndex = 0;
  let colIndex = 0;

  return (day: number) => {
    let info: TimelineInfo;

    for (let iRow = rowIndex; iRow < maxRow; iRow++) {
      for (let iCol = colIndex; iCol < maxCol; iCol++) {
        colIndex = 0;
        info = data[iRow][iCol];

        if (info.day === day) {
          rowIndex = iRow;
          colIndex = iCol;

          return info;
        }
      }
    }

    return null;
  };
}

/**
 * TimeRow 를 복사 한다.
 * 복사 방향은 a <-- b 이다.
 * 복사 하면 a 내용이 b와 동일 해 진다.
 * 복사 시 기본적으로 PreventReason 을 확인하여 진료외 시간은 회피 한다.
 * @param a 복사 받을 행 (destination)
 * @param b 복사 할 원본 행 (source)
 * @param allowHoliday 휴일인 셀 내용을 복사에 포함시킬지의 여부. 기본값 false.
 */
function copyTimeRows(
  a: TimeRow[],
  b: TimeRow[],
  allowHoliday: boolean = false
) {
  const linearCellsDest = toLinearCells(a);
  const linearCellsSrc = toLinearCells(b);

  const iLen = linearCellsDest.length;
  let cellSrc: TimeCell;
  let start = -1;
  let end = -1;

  for (let i = 0; i < iLen; ) {
    cellSrc = linearCellsSrc[i];

    if (!cellSrc) {
      break;
    }

    if (cellSrc.start) {
      start = cellSrc.start;
    }

    if (cellSrc.end) {
      end = cellSrc.end;
    }

    if (start < 0 || end < 0) {
      copyCell(linearCellsDest[i], cellSrc);
      i++;

      continue;
    }

    if (hasPreventCellWithStartEnd(start, end, linearCellsDest, allowHoliday)) {
      i += end - start + 1;

      continue;
    }

    for (let idx = start; idx <= end; idx++) {
      copyCell(linearCellsDest[idx], linearCellsSrc[idx]);
      i++;
    }
  }

  return a;

  // linearCellsDest.forEach((cellDest, index) => {
  //   const cellSrc = linearCellsSrc[index];

  //   if ()
  // });

  // return a;

  // return a.map((tr, r) => {

  //   tr.cells.forEach((cell, i) => {
  //     const c = b[r].cells[i];
  //     cell.color = c.color;
  //     cell.count = c.count;
  //     cell.end  = c.end;
  //     cell.start = c.start;
  //     cell.text = c.text;
  //   });

  //   return tr;
  // });
}

function copyCell(a: TimeCell, b: TimeCell) {
  a.color = b.color;
  a.count = b.count;
  a.end = b.end;
  a.start = b.start;
  a.text = b.text;

  return a;
}

function cleanTimeRows(timeRows: TimeRow[]) {
  return timeRows.map(timeRow => {
    timeRow.cells.forEach(cell => {
      cell.color = '';
      cell.count = null;
      cell.end = null;
      cell.start = null;
      cell.text = '';
    });

    return timeRow;
  });
}

export function copyTimelineByDays(
  state: ResTimelineScheduleState,
  args: {
    src: number;
    dist: number[];
  }
) {
  const { timelineInfoList } = state;
  const { src, dist } = args;
  const timerow = getTimeRowsByDay(timelineInfoList, src);
  const next = findTimeline(timelineInfoList);

  dist.forEach(day => {
    const info = next(day);
    if (info) {
      info.timeRows = copyTimeRows(info.timeRows, timerow, true);
    }
  });

  return timelineInfoList;
}

function getTimelineByWeek(
  data: TimelineInfo[][],
  week: number[]
): TimelineInfo[] {
  const secondWeekFirstDay = data[1][0].day;
  const diffDay = week[0] - secondWeekFirstDay;
  let iWeekIndex = 0;

  if (diffDay === 0) {
    return data[1];
  }

  if (diffDay < 0) {
    return data[0];
  }

  if (diffDay > 0) {
    iWeekIndex = Math.floor(diffDay / 7) + 1;

    return data[iWeekIndex];
  }

  return [];
}

export function copyTimelineByWeeks(
  state: ResTimelineScheduleState,
  args: ScheduleWeekCopyPayload
) {
  const { timelineInfoList } = state;
  const { src, dist } = args;
  const weekRaw = getTimelineByWeek(timelineInfoList, src);

  dist.forEach(weekdays => {
    const target = getTimelineByWeek(timelineInfoList, weekdays);

    weekdays.forEach((day, index) => {
      const weekRawData = weekRaw[index];
      const targetData = target[index];

      // 휴일은 패스 한다.
      if (targetData.holiday) {
        return;
      }

      if (day < 0) {
        cleanTimeRows(targetData.timeRows);
        return;
      }

      targetData.timeRows = copyTimeRows(
        targetData.timeRows,
        weekRawData.timeRows
      );
    });
  });

  return timelineInfoList;
}

/**
 * 주간 스케줄 내용을 지정된 년월의 캘린더에 맞추어 채워 넣는다.
 * @param schedules 채워 넣은 주간 스케줄
 * @param year 년
 * @param month 월
 * @param openTimeMap 진료시간 정보
 * @param holidayMap 휴일 정보
 */
export function copyWeeklySchedulesToFullMonth(
  schedules: Schedule[],
  year: number,
  month: number,
  openTimeMap: { [key: string]: OfficeWeeklyDetailData },
  holidayMap: { [day: string]: Holiday }
) {
  if (!schedules || schedules.length === 0) {
    return [];
  }
  const aWeeks = makeDayMatrix(year, month);
  const aRet: Schedule[] = [];

  aWeeks.forEach(aWeekly =>
    aWeekly.forEach((day, idx) => {
      if (day < 1) {
        return;
      }

      const schedule = schedules[idx];

      if (!schedule) {
        return;
      }

      const holiday = holidayMap[day];

      if (holiday) {
        return;
      }

      const weekName = findWeekName(year, month, day);
      const fnFilter = filterEnableTimesByOffData(openTimeMap[weekName], null);

      aRet.push({
        day,
        isDayOff: schedule.isDayOff,
        enableTime: schedule.enableTime.filter(fnFilter),
      });
    })
  );

  return aRet;
}
