import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';

import { FindIdActions } from '~actions/auth/find-id.action';

import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { AuthService } from '~core/api/auth.service';

@Injectable()
export class FindIdEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router
  ) {}

  findId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FindIdActions.FindId),
      map(action => action.payload),
      exhaustMap(findUserInfo =>
        this.authService.findId(findUserInfo).pipe(
          map(id => {
            alert(`ID는 ${id.userId} 입니다.`);
            return FindIdActions.FindIdComplete(id.userId);
          }),
          catchError(error =>
            of(FindIdActions.FindIdFailure({ payload: error }))
          )
        )
      )
    )
  );

  findIdComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FindIdActions.FindIdComplete),
        map(action => action.payload),
        tap(id => this.router.navigate(['signin/' + id]))
      ),
    {
      dispatch: false,
    }
  );
}
