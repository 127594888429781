import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  KioskMainConfigCommon,
  KioskMainSpecificConfig,
} from '~models/kiosk-main-config.model';
import { KioskMainConfigActions } from '~actions/products/kiosk-main-config.actions';

interface State {
  commonConfig: KioskMainConfigCommon;
}

export type KioskMainConfigState = State & EntityState<KioskMainSpecificConfig>;

export const kioskMainConfigAdapter = createEntityAdapter<
  KioskMainSpecificConfig
>({
  selectId: kioskMainConfig => kioskMainConfig._id,
});

const initialState = kioskMainConfigAdapter.getInitialState<State>({
  commonConfig: null,
});

export const kioskMainConfigReducer = createReducer(
  initialState,
  on(
    KioskMainConfigActions.KioskMainCommonConfigSuccess,
    (state, { payload }) => {
      return {
        ...state,
        defaultConfig: payload,
      };
    }
  ),
  on(
    KioskMainConfigActions.KioskMainSpecificConfigListSuccess,
    (state, { payload }) => {
      return kioskMainConfigAdapter.setAll(payload, {
        ...state,
      });
    }
  ),
  on(
    KioskMainConfigActions.KioskMainSpecificConfigUpdateSuccess,
    (state, { id, changes }) => {
      return kioskMainConfigAdapter.updateOne(
        {
          id,
          changes,
        },
        state
      );
    }
  ),
  on(KioskMainConfigActions.KioskMainConfigDeleteSuccess, (state, { id }) => {
    return kioskMainConfigAdapter.removeOne(id, state);
  }),
  on(
    KioskMainConfigActions.KioskMainConfigCreateSuccess,
    (state, { payload }) => {
      return kioskMainConfigAdapter.addOne(payload, state);
    }
  )
);
