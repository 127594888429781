import {
  CrmFileLists,
  CrmReqAgreement,
  CrmReqMessage,
  CrmResAgreement,
} from '~models/crm-service.model';
import { createAction, props } from '@ngrx/store';

export enum CrmActionType {
  CRM_AGREEMENT_LOAD = '[CrmService] Crm Service Agreement Load',
  CRM_AGREEMENT_LOAD_SUCCESS = '[CrmService] Crm Service Agreement Load Success',
  CRM_AGREEMENT_LOAD_FAIL = '[CrmService] Crm Service Agreement Load Fail',

  CRM_AGREEMENT_UPDATE = '[CrmService] Crm Service Agreement Update',
  CRM_AGREEMENT_UPDATE_SUCCESS = '[CrmService] Crm Service Agreement Update Success',
  CRM_AGREEMENT_UPDATE_FAIL = '[CrmService] Crm Service Agreement Update Fail',

  CRM_UPLOAD_FILES = '[CrmService] Crm Upload Files',
  CRM_UPLOAD_FILES_SUCCESS = '[CrmService] Crm Upload Files Success',
  CRM_UPLOAD_FILES_FAIL = '[CrmService] Crm Upload Files Fail',
  CRM_EXCEPT_FILES = '[CrmService] Crm Except Files',

  CRM_SMS_REQUEST = '[CrmService] Crm SMS Request',
  CRM_SMS_REQUEST_SUCCESS = '[CrmService] Crm SMS Request Success',
  CRM_SMS_REQUEST_FAIL = '[CrmService] Crm SMS Request Fail',

  CRM_CLEAR = '[CrmService] Crm Clear',
}

// 마케팅 대행 동의 설정 조회
export const CrmAgreementLoad = createAction(CrmActionType.CRM_AGREEMENT_LOAD);

export const CrmAgreementLoadSuccess = createAction(
  CrmActionType.CRM_AGREEMENT_LOAD_SUCCESS,
  props<{ payload: CrmResAgreement }>()
);

export const CrmAgreementLoadFail = createAction(
  CrmActionType.CRM_AGREEMENT_LOAD_FAIL,
  props<{ payload: any }>()
);

// 마케팅 대행 동의 설정 업데이트
export const CrmAgreementUpdate = createAction(
  CrmActionType.CRM_AGREEMENT_UPDATE,
  props<{ payload: CrmReqAgreement }>()
);

export const CrmAgreementUpdateSuccess = createAction(
  CrmActionType.CRM_AGREEMENT_UPDATE_SUCCESS
);

export const CrmAgreementUpdateFail = createAction(
  CrmActionType.CRM_AGREEMENT_UPDATE_FAIL,
  props<{ payload: any }>()
);

// 파일 업로드
export const CrmUploadFiles = createAction(
  CrmActionType.CRM_UPLOAD_FILES,
  props<{ payload: File }>()
);

export const CrmUploadFilesSuccess = createAction(
  CrmActionType.CRM_UPLOAD_FILES_SUCCESS,
  props<{ payload: CrmFileLists }>()
);

export const CrmUploadFilesFail = createAction(
  CrmActionType.CRM_UPLOAD_FILES_FAIL,
  props<{ payload: any }>()
);

// 파일 제외
export const CrmExceptFiles = createAction(
  CrmActionType.CRM_EXCEPT_FILES,
  props<{ payload: number }>()
);

// SMS 요청
export const CrmSMSRequest = createAction(
  CrmActionType.CRM_SMS_REQUEST,
  props<{ payload: CrmReqMessage }>()
);

export const CrmSMSRequestSuccess = createAction(
  CrmActionType.CRM_SMS_REQUEST_SUCCESS
);

export const CrmSMSRequestFail = createAction(
  CrmActionType.CRM_SMS_REQUEST_FAIL,
  props<{ payload: any }>()
);

export const CrmClear = createAction(CrmActionType.CRM_CLEAR);

export const CrmActions = {
  CrmAgreementLoad,
  CrmAgreementLoadSuccess,
  CrmAgreementLoadFail,
  CrmAgreementUpdate,
  CrmAgreementUpdateSuccess,
  CrmAgreementUpdateFail,
  CrmUploadFiles,
  CrmUploadFilesSuccess,
  CrmUploadFilesFail,
  CrmExceptFiles,
  CrmSMSRequest,
  CrmSMSRequestSuccess,
  CrmSMSRequestFail,
  CrmClear,
};
