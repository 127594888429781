<section class="benefit">
  <div class="content-box">
    <div class="content-layout">
      <h2 class="content-layout--title">
        똑닥과 함께,
        <br />
        무엇이 좋아지나요?
      </h2>

      <div class="benefit-list">
        <div class="benefit-item">
          <img
            class="benefit-item--icon"
            src="assets/img/signin/chartIcon.png"
            alt="차트 아이콘"
          />
          <h4 class="benefit-item--title">병원 업무 절감!</h4>
          <p class="benefit-item--content">
            똑닥은 국내최초로 전자차트와 연동된 모바일 서비스로
            <br />
            예약, 접수, 수납 업무로 바쁜 병원의 행정업무를 줄여줍니다.
          </p>
        </div>

        <hr class="vertical-line" />

        <div class="benefit-item">
          <img
            class="benefit-item--icon"
            src="assets/img/signin/addUserIcon.png"
            alt="유저 아이콘"
          />
          <h4 class="benefit-item--title">환자 재방문율 상승!</h4>
          <p class="benefit-item--content">
            똑닥을 사용하는 병원은 환자에게 더욱 집중할 수 있고
            <br />
            이를 경험한 환자는 우리 병원에 다시 방문하게 됩니다.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="content-box gray">
    <div class="content-layout below">
      <h3 class="benefit-sub-title">
        아직도 진료 외의 일로
        <br />
        바쁘신가요?
      </h3>

      <div class="benefit-process">
        <div class="process-item">
          <h4 class="process-item--title">환자 진료 전</h4>
          <div class="process-item--content">
            <p>병원 홍보</p>
            <hr />
            <p>
              전화 방문 접수
              <br />
              /예약 응대
            </p>
            <hr />
            <p>
              서면 •
              <br class="br-mobile" />
              구두문진
            </p>
            <hr />
            <p>환자 호출</p>
          </div>
        </div>

        <div class="process-item">
          <h4 class="process-item--title blue">환자 진료 후</h4>
          <div class="process-item--content">
            <p>
              수납 / 처방전 •
              <br />
              보험서류 발급
            </p>
            <hr />
            <p>
              검사 결과
              <br />
              도착 안내
            </p>
            <hr />
            <p>환자 관리</p>
          </div>
        </div>

        <div class="process-item">
          <h4 class="process-item--title green">병원 경영관리</h4>
          <div class="process-item--content">
            <p>
              병원 경영관리
            </p>
          </div>
        </div>
      </div>

      <h3 class="benefit-last-title">
        이제 똑닥에게 맡기고
        <br class="br-mobile" />
        진료만 보세요!
      </h3>
    </div>
  </div>
</section>
