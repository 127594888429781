<div class="tab-list" [ngClass]="themeClass">
  <button
    type="button"
    class="ha-btn tab-item"
    *ngFor="let item of items; index as idx"
    [ngClass]="{ active: tab === idx, fix: fixday === idx }"
    (click)="onTabSelect(idx)"
  >
    <span>{{ item }}</span>
  </button>
</div>
<div class="extra"></div>
