import { ChildScreeningResResult } from '~models/child-screening.model';
import { createReducer, on } from '@ngrx/store';
import { ChildActions } from '~actions/products/child.actions';

export interface ChildScreeningState {
  loading: boolean;
  loaded: boolean;
  error: string | null;
  data: ChildScreeningResResult | null;
  downloadPending: boolean;
}

const initialState: ChildScreeningState = {
  loaded: false,
  loading: false,
  error: null,
  data: null,
  downloadPending: false,
};

export const reducer = createReducer(
  initialState,
  on(ChildActions.ChildInfantListLoad, (state, action) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ChildActions.ChildInfantListSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    error: null,
    data: action.payload,
  })),
  on(ChildActions.ChildInfantListFail, (state, action) => ({
    ...state,
    loading: false,
    loaded: false,
    error: action.payload,
  })),
  on(ChildActions.ChildInfantSinglePdfDownload, (state, action) => ({
    ...state,
    downloadPending: true,
  })),
  on(ChildActions.ChildInfantSinglePdfDownloadPending, (state, action) => ({
    ...state,
    downloadPending: action.payload,
  }))
);

export const getLoading = (state: ChildScreeningState) => state.loading;
export const getLoaded = (state: ChildScreeningState) => state.loaded;
export const getError = (state: ChildScreeningState) => state.error;
export const getData = (state: ChildScreeningState) => state.data;
export const getDownloadPending = (state: ChildScreeningState) =>
  state.downloadPending;
