import * as cancelMsgActions from '~actions/products/cancel-message';
import { createReducer, on } from '@ngrx/store';
import { CancelMsgActions } from '~actions/products/cancel-message';

export interface CancelMsgState {
  loading: boolean;
  loaded: boolean;
  error: string | null;
  msg: string[] | null;
}

const initialState: CancelMsgState = {
  loaded: false,
  loading: false,
  error: null,
  msg: null,
};

export const reducer = createReducer(
  initialState,
  on(CancelMsgActions.CancelMsgLoad, (state, action) => ({
    ...state,
    loading: true,
    error: null,
    msg: null,
  })),
  on(CancelMsgActions.CancelMsgLoadSuccess, (state, action) => ({
    loading: false,
    error: null,
    loaded: true,
    msg: action.payload.msg,
  })),
  on(CancelMsgActions.CancelMsgLoadFail, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
    loaded: false,
  })),
  on(CancelMsgActions.CancelMsgUpdate, (state, action) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(CancelMsgActions.CancelMsgUpdateSuccess, (state, action) => ({
    ...state,
    msg: [...action.payload],
    loading: false,
    error: null,
  })),
  on(CancelMsgActions.CancelMsgUpdateFail, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
);

export const getCancelMsgError = (state: CancelMsgState) => state.error;
export const getCancelMsgLoaded = (state: CancelMsgState) => state.loaded;
export const getCancelMsgData = (state: CancelMsgState) => state.msg;
export const getCancelMsgLength = (state: CancelMsgState) => {
  return state.msg ? state.msg.length : 0;
};
