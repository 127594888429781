import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs/';
import { takeWhile } from 'rxjs/operators';
import { SharedService } from '~core/api/shared.service';
import { HospitalListItems } from '~models/auth.model';
import { errorMessage } from '../../service/util';

@Component({
  selector: 'app-hospital-select',
  templateUrl: './hospital-select.component.html',
  styleUrls: ['./hospital-select.component.scss'],
})
export class HospitalSelectComponent implements OnInit {
  isSearching = false;
  isSelect = false;
  search = true;

  hosTitle: string;
  selectedHospitalSpan = '병원을 선택 해주세요.';

  hospitalsItem$ = new Subject<string>();
  hospitalItems: HospitalListItems;
  errorMsg: string;

  @ViewChild('hospitalTitle') hospitalTitle;
  @Output() hospitalInfo = new EventEmitter();

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    this.sharedService
      .searchHospitalsByTitle(this.hospitalsItem$)
      .pipe(takeWhile(() => this.search))
      .subscribe(
        (item: any) => {
          return (this.hospitalItems = item);
        },
        err => {
          return (this.errorMsg = errorMessage(err, 'hospital-select'));
        }
      );
  }

  selectedHospital(item) {
    this.hospitalItems = null;
    this.hosTitle = item.title;
    this.selectedHospitalSpan = item.title;
    this.isSelect = true;
    this.hospitalInfo.emit(item);
  }

  get items(): any[] {
    try {
      const items = this.hospitalItems.items;

      if (items && items.length > 0) {
        return items;
      }
    } catch (error) {}

    return [];
  }

  get hasItems(): boolean {
    try {
      const items = this.hospitalItems.items;

      return items.length >= 0;
    } catch (error) {
      return false;
    }
  }

  handleKeyUp(event: any): void {
    this.hospitalsItem$.next(event.target.value.trim());
  }
}
