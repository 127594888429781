import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Api } from '~core/http-api';

const BASE_URL = 'api/v2/hospital-service-guides';

/**
 * @description SCHEDULES 진료시간 미설정
 * @description NOTE 병원 소개 미설정
 * @description PARKING 주차장 정보 미설정
 * @description IMAGES 병원 사진 미설정
 * @description DOCTOR 의사 정보 미설정
 * @description MEDICAL_BILL 진료비 정보 미설정
 * @description RECEIPT 접수 미설정 (비정상 1차)
 * @description RESERVATION 예약 미설정 (비정상 1차)
 * @description RESERVATION_SCHEDULE 이번달 예약 스케줄 미설정 (스케줄 체크)
 * @description AUTOMATIC_ISSUANCE_PAPER 서류 자동 발급 여부
 * @description PAYMENT 결제 미설정
 * @description NONE 미설정 정보 없음
 */
export const ServiceUnsetInfo = {
  SCHEDULES: 'SCHEDULES',
  NOTE: 'NOTE',
  PARKING: 'PARKING',
  IMAGES: 'IMAGES',
  DOCTOR: 'DOCTOR',
  MEDICAL_BILL: 'MEDICAL_BILL',
  RECEIPT: 'RECEIPT',
  RESERVATION: 'RESERVATION',
  RESERVATION_SCHEDULE: 'RESERVATION_SCHEDULE',
  AUTOMATIC_ISSUANCE_PAPER: 'AUTOMATIC_ISSUANCE_PAPER',
  PAYMENT: 'PAYMENT',
  NONE: 'NONE',
} as const;

export type ServiceUnsetInfoType = keyof typeof ServiceUnsetInfo;

export interface HospitalServiceUnsetInfo {
  unsetInfo: ServiceUnsetInfoType;
}

@Injectable({
  providedIn: 'root',
})
export class GuidesService {
  constructor(private http: HttpClient, private api: Api) {}

  fetchServiceGuides(): Observable<HospitalServiceUnsetInfo> {
    return this.api.get(BASE_URL);
  }
}
