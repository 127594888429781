import {
  QuestionnaireTargetUiModel,
  QuestionnaireUnitInfo,
} from '~models/question.model';

export function createTargetUiModel(): QuestionnaireTargetUiModel {
  return {
    /**
     * 진료실 키
     */
    unitKey: '',
    /**
     * 접수/예약 여부
     */
    type: '',
    /**
     * 사전문진 질문지 순서
     */
    order: 0,
    /**
     * 시작 허용 연령
     */
    startAge: 0,
    /**
     * 끝 허용 연령
     */
    endAge: 100,
    /**
     * 질문지 제목
     */
    title: '',
    /**
     * 허용 진료 항목 사용 여부
     * 사용시 symptomCategoryId 입력
     * 미사용 시 전체 진료 항목 사용
     */
    useSymptomCategory: false,
    /**
     * 허용 진료 항목 아이디
     */
    symptomCategoryId: '',
    /**
     * 재접수 여부
     */
    reReception: false,
    /**
     * 재접수 판단 기간 (이후)
     */
    reReceptionPeriod: 90,
    /**
     * 처음 접수 여부
     */
    firstReception: false,
    /**
     * (UI전용) 사전문진 추가 옵션 선택 값
     */
    additionalOption: '',
  };
}

export function createQuestionnaireUnitInfo(): QuestionnaireUnitInfo {
  return {
    title: '',
    subject: '',
    useMedicalQuestionnaire: false,
  };
}
