import { createAction, props } from '@ngrx/store';
import { KioskMenuActiveInfo } from '~models/kiosk-menu-active.model';

export const enum KioskMenuActiveActionType {
  FETCH_KIOSK_MENU_ACTIVE_INFO = 'FETCH_KIOSK_MENU_ACTIVE_INFO',
  FETCH_KIOSK_MENU_ACTIVE_INFO_SUCCESS = 'FETCH_KIOSK_MENU_ACTIVE_INFO_SUCCESS',

  ERROR = 'ERROR',
}

export const KioskMenuActiveReqFail = createAction(
  KioskMenuActiveActionType.ERROR,
  props<{ error: any }>()
);

export const KioskMenuActive = createAction(
  KioskMenuActiveActionType.FETCH_KIOSK_MENU_ACTIVE_INFO
);

export const KioskMenuActiveSuccess = createAction(
  KioskMenuActiveActionType.FETCH_KIOSK_MENU_ACTIVE_INFO_SUCCESS,
  props<{ res: KioskMenuActiveInfo }>()
);

export const KioskMenuActiveActions = {
  KioskMenuActiveReqFail,
  KioskMenuActive,
  KioskMenuActiveSuccess,
};
