import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs/';
import { takeUntil } from 'rxjs/operators';
import { SharedService } from '~core/api/shared.service';
import { SignUpHospitalInfo, SignupHospitalList } from '~models/auth.model';
import { errorMessage } from '~shared/service/util';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-signup-hospital-select',
  templateUrl: './signup-hospital-select.component.html',
  styleUrls: ['./signup-hospital-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SignupHospitalSelectComponent),
    },
  ],
})
export class SignupHospitalSelectComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input() hospitalTitle: string;
  @Input() placeholder = '가입 병원을 선택하세요.';
  @Input() isShowLabel = true;

  @Output() hospitalInfo = new EventEmitter<SignUpHospitalInfo>();
  @Output() isValidate = new EventEmitter<boolean>();
  @Output() hospitalTitleChange = new EventEmitter<string>();

  // isSelect = false;
  // selectedHospitalSpan = '병원을 선택 해주세요.';
  isShowResultList = false;

  hospitalSearchEvent = new Subject<string>();
  hospitalList: SignupHospitalList;
  errorMsg: string;

  onChange: (value: string) => void;
  onTouched: () => void;

  /* Life cycle */
  onDestroy = new Subject<void>();

  constructor(private sharedService: SharedService) {}

  ngOnInit(): void {
    this.sharedService
      .searchHospitalsByTitle(this.hospitalSearchEvent)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(
        (list: SignupHospitalList) => {
          this.isShowResultList = true;
          this.hospitalList = list;
          this.isValidate.emit(list.items.length !== 0);
        },
        err => {
          return (this.errorMsg = errorMessage(err, 'hospital-select'));
        }
      );
  }

  hideResult(): void {
    this.isShowResultList = false;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    this.hospitalTitle = value;
  }

  selectedHospital(hospital: SignUpHospitalInfo): void {
    this.isShowResultList = false;
    // this.selectedHospitalSpan = hospital.title;
    // this.isSelect = true;
    this.hospitalInfo.emit(hospital);
    this.isValidate.emit(true);
  }

  onBlurInput(): void {
    if (this.existsResult) {
      this.hideResult();
    }
  }

  get hospitals(): SignUpHospitalInfo[] {
    try {
      const hospital = this.hospitalList.items;
      if (hospital && hospital.length > 0) {
        return hospital;
      }
    } catch (error) {}

    return [];
  }

  get hasHospitals(): boolean {
    try {
      const hospitals = this.hospitalList.items;
      return hospitals.length >= 0;
    } catch (error) {
      return false;
    }
  }

  get existsResult(): boolean {
    return (
      this.hasHospitals && this.hospitals.length === 0 && this.isShowResultList
    );
  }

  handleValueChange(value: string): void {
    this.hospitalTitleChange.emit(value);
    this.isValidate.emit(false);
    this.hospitalSearchEvent.next(value.trim());
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
