import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ClipboardModule } from 'ngx-clipboard';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { LottieModule } from 'ngx-lottie';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import player from 'lottie-web';

import { AuthComponentsModule } from './components/auth.components.module';
import { SharedModule } from '~shared/shared.module';

import { AuthService } from '~core/api/auth.service';
import { SharedService } from '~core/api/shared.service';
import { FaqService } from '~core/api/faq.service';
import { KakaoService } from '~shared/service/kakao.service';

import { AuthEffects } from '~effects/auth/auth.effects';
import { FindIdEffects } from '~effects/auth/find-id.effects';
import { FindPwEffects } from '~effects/auth/find-pw.effects';
import { ResetPwEffects } from '~effects/auth/reset-pw.effects';
import { FaqEffects } from '~effects/products/faq.effects';
import { ServiceVideoEffects } from '~effects/auth/service-video.effects';

import { reducers } from '~reducers/auth';

import { SigninContentsSliderComponent } from './pages/signin/components/signin-contents-slider/signin-contents-slider.component';
import { SigninContentsComponent } from './pages/signin/components/signin-contents/signin-contents.component';
import { SigninModalComponent } from './pages/signin/components/signin-modal/signin-modal.component';
import { FindPasswordPageComponent } from './pages/find-pw/containers/find-password-page/find-password-page.component';
import { FindIdPageComponent } from './pages/find-id/containers/find-id-page/find-id-page.component';
import { ResetPasswordPageComponent } from './pages/reset-pw/containers/reset-password-page/reset-password-page.component';
import { SignInPageComponent } from './pages/signin/containers/signin-page/signin-page.component';
import { SignUpPageComponent } from './pages/signup/containers/signup-page/signup-page.component';
import { SigninHeaderComponent } from './pages/signin/components/signin-header/signin-header.component';
import { SignupTermsComponent } from './components/signup-terms/signup-terms.component';
import { BannerFaqSectionComponent } from './components/faq/banner-faq-section.component';
import { SigninBenefitComponent } from './pages/signin/components/signin-benefit/signin-benefit.component';
import { SigninHappyWayComponent } from './pages/signin/components/signin-happy-way/signin-happy-way.component';
import { SigninDdocdocSetComponent } from './pages/signin/components/signin-ddocdoc-set/signin-ddocdoc-set.component';
import { SigninScrollContentsComponent } from './pages/signin/components/singin-scroll-contents/signin-scroll-contents.component';
import { SigninCarouselComponent } from './pages/signin/components/signin-carousel/signin-carousel.component';
import { SigninYoutubeTriggerComponent } from '~root/app/modules/auth/pages/signin/components/signin-youtube-trigger/signin-youtube-trigger.component';

export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    SharedModule,
    AuthComponentsModule,
    ClipboardModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forChild([
      { path: '', redirectTo: 'signin', pathMatch: 'full' },
      { path: 'apply', component: SignInPageComponent },
      { path: 'signin', component: SignInPageComponent },
      { path: 'signin/:id', component: SignInPageComponent },
      { path: 'find-id', component: FindIdPageComponent },
      { path: 'find-pw', component: FindPasswordPageComponent },
      { path: 'reset-pw/:token', component: ResetPasswordPageComponent },
      { path: 'signup', component: SignUpPageComponent },
    ]),
    StoreModule.forFeature('auth', reducers),
    EffectsModule.forFeature([
      AuthEffects,
      FindIdEffects,
      FindPwEffects,
      ResetPwEffects,
      ServiceVideoEffects,
      FaqEffects,
    ]),
    LottieModule.forRoot({ player: playerFactory }),
    CarouselModule.forRoot(),
  ],
  declarations: [
    SignInPageComponent,
    FindIdPageComponent,
    FindPasswordPageComponent,
    ResetPasswordPageComponent,
    SignUpPageComponent,
    SigninContentsComponent,
    SigninContentsSliderComponent,
    SigninModalComponent,
    SigninHeaderComponent,
    SignupTermsComponent,
    BannerFaqSectionComponent,
    SigninBenefitComponent,
    SigninHappyWayComponent,
    SigninDdocdocSetComponent,
    SigninCarouselComponent,
    SigninScrollContentsComponent,
    SigninYoutubeTriggerComponent,
  ],
  providers: [AuthService, KakaoService, FaqService, SharedService],
  exports: [
    SigninContentsSliderComponent,
    BannerFaqSectionComponent,
    SigninContentsComponent,
    SigninHeaderComponent,
  ],
})
export class AuthModule {}
