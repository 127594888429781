import { Action, createAction, props } from '@ngrx/store';
import { errorMessage } from '~shared/service/util';

export abstract class ErrorAction implements Action {
  public type: string;
  /**
   * 에러 메시지 내용이 담긴 항목.
   */
  public message: string;

  protected constructor(error: any) {
    this.type = '';
    let msg = '';

    // console.log('error action', error);

    try {
      msg = errorMessage(error);
      // msg = error.error.error.message;
    } catch (error) {
      msg = '서버 호출에 실패했습니다.';
    }

    // this.payload = msg;
    this.message = msg;
  }
}

export const createErrorAction = (type: string, error: any) => {
  const errorAction = createAction(type, props<{ payload: any }>());
  let message = '';
  try {
    message = errorMessage(error);
  } catch (e) {
    message = '서버 호출에 실패했습니다.';
  }
  return errorAction({ payload: message });
};
