<label class="toggle-switch" [ngClass]="{ disapply: value === undefined }">
  <input
    type="checkbox"
    class="chk-hidden"
    [disabled]="disabled"
    [checked]="value"
    (change)="onChange($event)"
  />
  <span class="inner clearfix">
    <span class="btn-toggle btn-off">{{ off }}</span>
    <span class="btn-toggle btn-on">
      <span>{{ on }}</span>
    </span>
  </span>
</label>
