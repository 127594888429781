<div
  #modalBody
  class="ham-overlay modal-body"
  [ngClass]="{ 'ham-show': open }"
  (click)="clickOverlay($event)"
>
  <div class="ham-inner-t">
    <div class="ham-inner-r">
      <div class="ham-inner-c" [ngClass]="'plc-' + placement">
        <div
          class="d-inline-block m-auto"
          [ngClass]="{ responsible: responsible }"
          (click)="clickModal($event)"
        >
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
