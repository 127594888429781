import { PopupActions } from '~actions/products/popup.actions';
import { PopupInfo } from '~models/popup.model';
import { createReducer, on } from '@ngrx/store';

export interface PopupState {
  loading: boolean;
  loaded: boolean;
  popupInfoItems: PopupInfo[];
}

const initialState: PopupState = {
  loaded: false,
  loading: false,
  popupInfoItems: [],
};

export const reducer = createReducer(
  initialState,
  on(PopupActions.PopupLoad, state => ({
    ...state,
    loaded: false,
    loading: true,
  })),
  on(PopupActions.PopupLoadSuccess, (state, { payload }) => {
    const { popupInfoItems } = payload;

    return {
      ...state,
      loaded: true,
      loading: false,
      popupInfoItems: popupInfoItems.map(popupInfo => {
        return {
          ...popupInfo,
          isInnerLink: popupInfo.link && popupInfo.link.indexOf('ha:') > -1,
        };
      }),
    };
  })
);

export const getLoading = (state: PopupState) => state.loading;
export const getLoaded = (state: PopupState) => state.loaded;
export const getPopupInfoItems = (state: PopupState) => state.popupInfoItems;
