import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { UploadResponse } from '~models/common.model';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class Api {
  public API_URL = environment.api_url;
  private selectHospital: any;

  constructor(private http: HttpClient) {}

  setHospitalId(data: any) {
    this.selectHospital = Object.assign({}, data);
  }

  getHospitalId() {
    return this.selectHospital;
  }

  clearHospitalId() {
    this.selectHospital = undefined;
  }

  getEndPoint(endPoint: string) {
    return this.selectHospital
      ? endPoint + '?hospitalId=' + this.selectHospital._id
      : endPoint;
  }

  get<T = any>(endPoint: string, params?: any, reqOpts?: any) {
    const requestOpts = (() => {
      let httpParams = new HttpParams({
        fromObject: {
          _timestamp: moment()
            .valueOf()
            .toString(),
        },
      });
      if (params) {
        for (const key in params) {
          if (params.hasOwnProperty(key)) {
            const element = params[key];
            httpParams = httpParams.set(key, element);
          }
        }
      }
      return Object.assign({ params: httpParams }, reqOpts);
    })();

    return (this.http.get(
      this.API_URL + '/' + this.getEndPoint(endPoint),
      Object.assign({}, requestOpts)
    ) as any) as Observable<T>;
  }

  post<T = any>(endPoint: string, body: any, reqOpts?: any) {
    return (this.http.post(
      this.API_URL + '/' + this.getEndPoint(endPoint),
      body,
      Object.assign({}, reqOpts)
    ) as any) as Observable<T>;
  }

  put<T = any>(endPoint: string, body: any, reqOpts?: any) {
    return (this.http.put(
      this.API_URL + '/' + this.getEndPoint(endPoint),
      body,
      Object.assign({}, reqOpts)
    ) as any) as Observable<T>;
  }

  delete<T = any>(endPoint: string, reqOpts?: any) {
    return (this.http.delete(
      this.API_URL + '/' + this.getEndPoint(endPoint),
      Object.assign({}, reqOpts)
    ) as any) as Observable<T>;
  }

  patch<T = any>(endPoint: string, body: any, reqOpts?: any) {
    return (this.http.patch(
      this.API_URL + '/' + this.getEndPoint(endPoint),
      body,
      Object.assign({}, reqOpts)
    ) as any) as Observable<T>;
  }

  upload<T>(
    method: string,
    url: string,
    params: { [key: string]: Blob }
  ): Observable<UploadResponse<T>> {
    let req: HttpRequest<any>;
    const formData = new FormData();

    Object.keys(params).forEach(key => formData.append(key, params[key]));
    // const headers = {};
    // headers['Content-Type'] = 'application/x-www-form-urlencoded';
    // headers['Content-Type'] = 'multipart/form-data';
    // headers[]

    req = new HttpRequest(method, `${this.API_URL}/${url}`, formData, {
      // headers: new HttpHeaders(headers),
      reportProgress: true,
    });

    return this.http.request(req).pipe(
      switchMap((event: HttpEvent<HttpEventType.UploadProgress | any>) => {
        return new Observable<UploadResponse<T>>(subscriber => {
          if (event.type === HttpEventType.UploadProgress) {
            subscriber.next({
              progress: Math.round((event.loaded / (event.total ?? 100)) * 100),
            });
          } else if (event instanceof HttpResponse) {
            subscriber.next({
              progress: 100,
              data: event.body,
            });
            subscriber.complete();
          }
        });
      })
    );
  }

  postUpload<T>(
    url: string,
    params: { [key: string]: Blob }
  ): Observable<UploadResponse<T>> {
    return this.upload('POST', url, params);
  }

  putUpload<T>(
    url: string,
    params: { [key: string]: Blob }
  ): Observable<UploadResponse<T>> {
    return this.upload('PUT', url, params);
  }
}
