import { ImageSize, ImageUrlInfo, Omit, PositionLatLng } from './common.model';
import {
  AlimTalkSendEnum,
  InspectionSetting,
  ResultSendEnum,
} from './hospital-setting.model';

export interface HospitalScheduleImageUploadResModel {
  result: {
    imageUrl: string;
    size: ImageSize;
  };
}

export interface HospitalNotice {
  _id?: string;
  content?: string;
  contentType?: string;
  hospital?: string;
  title?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface HospitalNoticeList {
  items: HospitalNotice[];
  totalCount: number;
  skip?: number;
  limit?: number;
  sort?: string;
}

export interface HospitalNoticeSearchParams {
  pageNum: number;
  searchKey: string;
  searchValue: string;
}

export interface HospitalNoticeQueryParam {
  title?: string;
  content?: string;
  titleOrContent?: string;
  skip?: number;
  limit?: number;
}

export interface OperatingTimeModel {
  schedule: {
    monday: OperatingInnerTime;
    tuesday: OperatingInnerTime;
    wednesday: OperatingInnerTime;
    thursday: OperatingInnerTime;
    friday: OperatingInnerTime;
    saturday: OperatingInnerTime;
    sunday: OperatingInnerTime;
    holiday: OperatingInnerTime;
    lunch: OperatingInnerTime;
    dinner: OperatingInnerTime;
  };
  scheduleMessage?: string;
}

export interface TimeRange {
  startTime?: {
    hour: any;
    minute: any;
  };
  endTime?: {
    hour: any;
    minute: any;
  };
}

export interface OperatingInnerTime {
  startTime: string | null;
  endTime: string | null;
  night?: false;
}

export interface PickerDate {
  title?: string;
  startTime?: any;
  endTime?: any;
  type?: string;
  rest?: boolean;
}

// ! deprecated 항목 : reservation 의 timeUnit, useTodayBooking, maxTerm
export interface HospitalSettingModel {
  useReceipt?: boolean;
  useReservation?: boolean;
  useReceiptSchedule: boolean;
  useExaminationInfants: boolean;
  useSelfDesk: boolean;
  useKiosk: boolean;
  mobileEnvironment?: {
    receipt?: {
      use: boolean;
      useSchedule: boolean;
      lastReceive?: {
        receipt?: number;
        lunch?: number;
        dinner?: number;
      };
      waitCountOption?: string;
      disableArriveTime?: boolean;
      autoCancel?: {
        use?: boolean;
        delayTime?: number;
      };
      inspection: InspectionSetting;
      // 실제 대기 순번 노출 옵션 사용 여부
      useDDWaiting?: boolean;
    };
    reservation?: {
      usePenalty: boolean;
      allowDuplicates: boolean;
      useCRM: boolean;
      version: string;
      availableAfterMinutes: number;
      maxTerm?: number;
      timeUnit?: number;
      useTodayBooking?: boolean;
      inspection: InspectionSetting;
    };
    examinationInfants?: {
      use?: boolean;
      hideCanceled?: boolean;
      useNewbornExam?: boolean;
    };
  };
}

export interface HospitalSettingUpdateModel {
  useReceipt?: boolean;
  useReservation?: boolean;
  useReceiptSchedule?: boolean;
  useExaminationInfants?: boolean;
  useSelfDesk?: boolean;
  useKiosk?: boolean;
  mobileEnvironment?: {
    receipt?: {
      use?: boolean;
      useSchedule?: boolean;
      lastReceive?: {
        receipt?: number;
        lunch?: number;
        dinner?: number;
      };
      waitCountOption?: 'UNDER_FIVE' | 'WAIT_TIME' | 'WAIT_COUNT' | 'NONE';
      disableArriveTime?: boolean;
      // 실제 대기 순번 노출 옵션 사용 여부
      useDDWaiting?: boolean;
      autoCancel?: {
        use?: boolean;
        delayTime?: number;
      };
    };
    reservation?: {
      usePenalty?: boolean;
      allowDuplicates?: boolean;
      useCRM?: boolean;
      version?: string;
      availableAfterMinutes?: number;
      maxTerm?: number;
      timeUnit?: number;
      useTodayBooking?: boolean;
    };
    examinationInfants?: {
      use?: boolean;
      hideCanceled?: boolean;
      useNewbornExam?: boolean;
    };
  };
}

export interface MsgData {
  msg: string;
  index: number;
}

export interface AllStopCareRoom {
  receiptPause?: boolean;
  receiptPauseMessage?: string;
  receiptPauseEndDate?: string;
  kioskPause?: boolean;
  kioskPauseMessage?: string;
}

export interface UpdateAccount {
  userName?: string;
  userPassword?: string;
  userPhone?: string;
  userEmail?: string;
}

// export interface WaitTimeSetting {
//   _id?: string;
//   ynum?: string;
//   memo?: string;
//   confirmEducation?: boolean;
//   increase?: boolean;
//   useSumHold?: boolean;
//   useSMS?: boolean;
//   waitingsTime?: {
//     autoTime: string;
//     manualTime: string;
//     autoYn: string;
//     active: boolean;
//   };
//   firstNotify?: {
//     active: boolean;
//     limit: number;
//   };
//   secondNotify?: {
//     active: boolean;
//     limit: number;
//     targetWaitings: number;
//   };
//   notice?: {
//     startTime: string;
//     endTime: string;
//     text: string;
//     active: boolean;
//   };
//   waitingsUse?: boolean;

//   /**
//    * 환자호출 메시지 모음.
//    */
//   callMessage: {
//     /**
//      * 환자호출 - 안내 데스크 메시지
//      */
//     desk: string;
//     /**
//      * 환자호출 - 진료실 메시지
//      */
//     unit: string;
//   };

//   callMessageUse?: {
//     desk: boolean;
//     unit: boolean;
//   };

//   excludeSend?: {
//     active: boolean;
//     age: number;
//   };

//   stopReason?: WaitStopReasonModel;
// }

// export interface WaitStopReasonModel {
//   name: string;
//   etc: string;
// }

// export class WaitTimeUpdate implements WaitTimeSetting {
//   increase: boolean;
//   useSMS: boolean;
//   waitingsTime: {
//     autoTime: string;
//     manualTime: string;
//     autoYn: string;
//     active: boolean;
//   };
//   notice: {
//     startTime: string;
//     endTime: string;
//     text: string;
//     active: boolean;
//   };
//   firstNotify: {
//     active: boolean;
//     limit: number;
//   };
//   secondNotify: {
//     active: boolean;
//     limit: number;
//     targetWaitings: number;
//   };
//   waitingsUse: boolean;
//   useSumHold: boolean;
//   callMessage: {
//     desk: string;
//     unit: string;
//   };
//   requestType = 'HOSPITAL_ADMIN';
//   excludeSend: {
//     active: boolean;
//     age: number;
//   };
//   stopReason: {
//     name: string;
//     etc: string;
//   };

//   constructor(data: WaitTimeSetting) {
//     this.increase = data.increase;
//     this.useSMS = data.useSMS;
//     this.waitingsTime = data.waitingsTime;
//     this.notice = data.notice;
//     this.firstNotify = data.firstNotify;
//     this.secondNotify = data.secondNotify;
//     this.waitingsUse = data.waitingsUse;
//     this.useSumHold = data.useSumHold;
//     this.callMessage = data.callMessage;
//     this.excludeSend = data.excludeSend;
//     this.stopReason = data.stopReason;

//     if (data.callMessage) {
//       this.callMessage = {
//         desk: data.callMessageUse.desk ? data.callMessage.desk : '',
//         unit: data.callMessageUse.unit ? data.callMessage.unit : ''
//       };
//     } else {
//       this.callMessage = {
//         desk: '',
//         unit: ''
//       };
//     }
//   }
// }

export interface PeaktimeLimitResQuery {
  peaktimeSettings: PeaktimeSettings[];
  hospitalId?: string;
}

export interface UnitPeaktimeLimitResQuery {
  key: string;
  usePeakTime: boolean;
  peaktimeSettings: UnitPeaktimeItem;
}

export interface UnitPeaktimeLimitConfirmResQuery {
  key: string;
  usePeakTime: boolean;
  peaktimeSettings: UnitPeaktimeItem;
}

export interface PeaktimeLimitResResult {
  items: PeaktimeLimitResItem[];
  totalCount: number;
}

export interface PeaktimeLimitResItem {
  _id: string;
  hospital: string;
  key: string;
  peaktimeSettings: PeaktimeSettings[];
  usePeakTime: boolean;
}

export interface UnitPeaktimeLimitResItem {
  _id: string;
  hospital: string;
  key: string;
  usePeakTime: boolean;
  active: boolean;
  createdAt: string;
  peaktimeSettings: UnitPeaktimeItem;
}

export interface UnitPeaktimeItem {
  holiday: PeaktimeSettings[];
  sunday: PeaktimeSettings[];
  saturday: PeaktimeSettings[];
  friday: PeaktimeSettings[];
  thursday: PeaktimeSettings[];
  wednesday: PeaktimeSettings[];
  tuesday: PeaktimeSettings[];
  monday: PeaktimeSettings[];
}

export interface PeaktimeSettings {
  startTime: string;
  endTime: string;
  limit: number;
}

export interface PauseCareRoom {
  receiptPause?: boolean;
  receiptPauseEndDate?: string;
  receiptPauseMessage?: string;
  kioskPause?: boolean;
  kioskPauseMessage?: string;
  hospitalId?: string;
}

export const monitoringStatus = [
  {
    type: 'UNDER_FIVE',
    text: '기본설정',
    description: `· 5명 이하: '30분 이내 진료가능'으로 표시 <br/>· 6명 이상: 대기자 수 노출`,
  },
  {
    type: 'WAIT_TIME',
    text: '예상 대기시간',
    description:
      '원장님별 평균 진료시간과 대기자 수를<br/>계산하여 예상 대기시간 노출',
  },
  {
    type: 'WAIT_COUNT',
    text: '전체 대기자 수 노출',
    description: "'대기자 수 N명'으로 표시",
  },
  {
    type: 'NONE',
    text: '비노출',
    description: '대기자를 노출하지 않음',
  },
];

interface InspectionStatus<T> {
  text: string;
  value: T;
}

export const inspectionAlimtalkStatus: InspectionStatus<AlimTalkSendEnum>[] = [
  {
    text: '병원 검사결과 도착 즉시 (당일)',
    value: AlimTalkSendEnum.THE_DAY,
  },
  {
    text: '병원 검사결과 도착 다음 날 (1일 후)',
    value: AlimTalkSendEnum.AFTER_ONE_DAY,
  },
  {
    text: '병원 검사결과 도착 다다음 날 (2일 후)',
    value: AlimTalkSendEnum.AFTER_TWO_DAYS,
  },
];

export const inspectionResultStatus: InspectionStatus<ResultSendEnum>[] = [
  {
    text: '상담진료 완료 시 자동 전송',
    value: ResultSendEnum.AFTER_CONSULTANT,
  },
  {
    text: '검사결과 도착 시 함께 전송',
    value: ResultSendEnum.AFTER_RESULT_ARRIVED,
  },
];

/**
 * 병원 조회 Response
 *
 * https://boostbros.atlassian.net/wiki/spaces/BACKEND/pages/1086521433/API+-
 */
export interface HospitalInfoResModel {
  /**
   * 고유 Id
   */
  _id?: string;
  /**
   * 병원명
   */
  title?: string;
  /**
   * 지번 주소
   */
  address?: string;
  /**
   * 도로명 주소
   */
  roadAddress: string | null;
  /**
   * 좌표
   */
  position?: PositionLatLng;
  /**
   * 위치
   */
  location?: {
    coordinates: number[];
    type?: string;
  };
  /**
   * 실제 전화번호
   */
  telReal: string;
  /**
   * 대표 진료 과목
   */
  mainDepartment: HospitalInfoCategoryModel;
  /**
   * 홈페이지
   */
  homepage?: string;
  /**
   * 한줄소개
   */
  introduction?: string;
  /**
   * 병원설명
   */
  note?: string;
  /**
   * 병원 사진
   */
  images?: ImageUrlInfo[];
  /**
   * 병원 스케줄 이미지
   */
  scheduleImage?: ImageUrlInfo;
  /**
   * 시설정보
   */
  facility?: HospitalFacilityInfo;
  /**
   * 의료장비 정보
   */
  medicalEquipmentInfos?: HospitalMedicalEquipmentInfo[];
  /**
   * 주차 정보
   */
  parking?: HospitalParkingInfo;
  /**
   * 카테고리
   */
  categories?: HospitalInfoCategoryModel[];
  /**
   * 진료질환
   */
  careTags?: HospitalInfoCareTagModel[];
  /**
   * ui only 상세주소
   */
  addressDetail?: string;
  /**
   * 병드민 생성일
   */
  createdAt?: string;
  /**
   * 병원 서술형 리뷰 노출 여부
   */
  useHospitalReview: boolean;
}

export interface HospitalInfoReqModel
  extends Omit<
    HospitalInfoResModel,
    'careTags' | 'categories' | 'mainDepartment'
  > {
  /**
   * 진료질환 (수정 요청 보낼때는 id 배열만)
   */
  careTags?: string[];
  /**
   * 진료질환 (수정 요청 보낼때는 id 배열만)
   */
  categories?: string[];
  /**
   * 대표 진료질환
   */
  mainDepartment: string;
}

/**
 * 병원정보 시설 정보
 *
 * https://boostbros.atlassian.net/wiki/spaces/BACKEND/pages/981991454/-+2
 */
export interface HospitalFacilityInfo {
  /**
   * 중환자실 병상 수
   */
  intensiveCareBeds?: number;
  /**
   * 일반 입원실 일반 병상 수
   */
  generalBeds?: number;
  /**
   * 일반 입원실 상급 병상 수
   */
  seniorBeds?: number;
  /**
   * 신생아 중환자실 병상 수
   */
  neonatalIntensiveCareBeds?: number;
  /**
   * 분만실 병상 수
   */
  deliveryRooms?: number;
  /**
   * 수술실 병상 수
   */
  operationgRooms?: number;
  /**
   * 응급실 병상 수
   */
  emergencyRoomBeds?: number;
  /**
   * 물리치료실 병상 수
   */
  physicalTherapyRooms?: number;
}

/**
 * 병원정보 보유 장비 정보
 *
 * https://boostbros.atlassian.net/wiki/spaces/BACKEND/pages/981991454/-+2\
 */
export interface HospitalMedicalEquipmentInfo {
  categoryCode: string;
  categoryName: string;
  medicalEquipmentCategory: string;
  total?: number;
}

/**
 * 병원정보 주차 정보
 */
export interface HospitalParkingInfo {
  isAvailable?: boolean;
  parkingSpaces?: number;
  parkingFree?: string;
  isFree?: boolean;
  notice?: string;
}

/**
 * 병원정보 진료항목 모델
 */
export interface HospitalInfoCareTagModel {
  _id: string;
  active?: boolean;
  diseaseCategoryName?: string;
  diseaseGeneralNames?: string[];
}

/**
 * 병원정보 카테고리 모델
 */
export interface HospitalInfoCategoryModel {
  _id: string;
  fullName?: string;
  name?: string;
}

/**
 * 병원정보 UI 모델
 *
 * 기본적으로 HospitalInfoResModel에서 extend한다
 * Depth가 들어가는 필드의 경우 Ui모델에서 재정의 해서 처리 하도록한다.
 */
export interface HospitalInfoUiModel extends HospitalInfoResModel {
  /**
   * 주차정보 - 주차장 운영여부
   * parking > isAvailable
   */
  isAvailable?: boolean;
  /**
   * 주차정보 - 주차가능대수
   * parking > parkingSpaces
   */
  parkingSpaces?: number;
  /**
   * 주차정보 - 주차 비용 부담
   * parking > isFree
   */
  isFree?: boolean;
  /**
   * 주차정보 - 주차 안내 사항
   * parking > notice
   */
  notice?: string;
  /**
   * 중환자실 병상 수
   */
  intensiveCareBeds?: number;
  /**
   * 일반 입원실 일반 병상 수
   */
  generalBeds?: number;
  /**
   * 일반 입원실 상급 병상 수
   */
  seniorBeds?: number;
  /**
   * 신생아 중환자실 병상 수
   */
  neonatalIntensiveCareBeds?: number;
  /**
   * 분만실 병상 수
   */
  deliveryRooms?: number;
  /**
   * 수술실 병상 수
   */
  operationgRooms?: number;
  /**
   * 응급실 병상 수
   */
  emergencyRoomBeds?: number;
  /**
   * 물리치료실 병상 수
   */
  physicalTherapyRooms?: number;
  /**
   * 회원가입여부
   */
  isMember?: boolean;
}

/**
 * 병원정보 input 페이지 ui 모델
 */
export interface HospitalInfoPageUiModel {
  /**
   * 섹션명
   */
  section: string;
  /**
   * option 리스트
   */
  items: HospitalInfoInputOption[];
}

/**
 * 병원정보 input
 */
export interface HospitalInfoInputOption {
  /**
   * option 타이틀
   *
   * showType이 table 일시 table의 header에서 보여지게된다
   */
  title?: string;
  /**
   * option item 리스트
   */
  optionItems: HospitalInfoInputOptionItem[];
  /**
   * option 표시 형식 (table: 테이블, raw: 테이블 미적용 (ex 병원사진))
   */
  showType: 'table' | 'raw';
}

/**
 * 병원정보 input 옵션 모델
 */
export interface HospitalInfoInputOptionItem {
  /**
   * 필드 키
   */
  key: string;
  /**
   * 필드 명
   */
  title: string;
  /**
   * 필드 타입 (ex: text, number, object, tel)
   */
  fieldType: string;
  /**
   * 필수 여부
   */
  isNecessary?: boolean;
  /**
   * input 태그 타입 속성
   */
  type?: string;
  /**
   * placeholder
   */
  placeholder?: string;
  /**
   * (text) 최대 글자수
   */
  maxlength?: number;
  /**
   * (number) 최소 값
   */
  min?: number;
  /**
   * (number) 최대 값
   */
  max?: number;
  /**
   * (text, number) 접두사
   */
  prefix?: string;
  /**
   * (text, number) 접미사
   */
  suffix?: string;
  /**
   * (boolean) 라벨 [true, false]
   */
  booleanLabel?: string[];
}
