import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '~core/api/auth.service';
import { EmrAccountCreateInfo } from '~models/auth.model';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { SimpleModalService } from '~shared/service/simple.modal.service';

/**
 * 기회원 동의
 */
@Component({
  selector: 'app-account-create-from-emr-modal',
  templateUrl: './account-create-from-emr-modal.component.html',
  styleUrls: ['./account-create-from-emr-modal.component.scss'],
})
export class AccountCreateFromEmrModalComponent implements OnInit {
  step = 1;
  ynum = null;
  marketingAgreement = false;

  constructor(
    public activeModal: NgbActiveModal,
    private service: AuthService,
    private simpleModalService: SimpleModalService
  ) {}

  ngOnInit() {}

  onTerms({ ynum, marketingAgreement }) {
    this.ynum = ynum;
    this.marketingAgreement = marketingAgreement;
    this.onNextStep(3);
  }

  onSubmit(submitData: EmrAccountCreateInfo) {
    const data = {
      ...submitData,
      ynum: this.ynum,
      marketingAgreement: this.marketingAgreement,
    };
    this.createAccount(data);
    // this.store.dispatch(new authActions.AccountCreate(data));
  }

  onNextStep(step: number) {
    this.step = step;
  }

  createAccount(data: EmrAccountCreateInfo) {
    of(data)
      .pipe(
        exhaustMap(param => {
          return this.service.createAccountFromEmr(param).pipe(
            map(payload => of({ result: payload })),
            catchError(err => of({ result: null, err }))
          );
        })
      )
      .subscribe((payload: { result: any; err: any }) => {
        if (payload.err) {
          this.simpleModalService.alert(payload.err.error.error.message);
        } else {
          this.onNextStep(4);
        }
      });
  }
}
