import { WizardModel } from '~models/wizard.model';
import { createReducer, on } from '@ngrx/store';
import { WizardActions } from '~actions/products/wizard.actions';

export interface WizardState {
  type: string;
  intro: boolean;
  use: boolean;
  list: WizardModel[];
  // current: WizardModel;
  index: number;
  loading: boolean;
  message: string;
}

function getInitState(): WizardState {
  return {
    type: '',
    intro: false,
    use: false,
    list: [],
    // current: null,
    index: -1,
    loading: false,
    message: '',
  };
}

const initState = getInitState();

export const reducer = createReducer(
  initState,
  on(WizardActions.WizardListLoad, (state, action) => ({
    ...state,
    intro: false,
    loading: true,
    message: '',
    index: -1,
  })),
  on(WizardActions.WizardListLoadSuccess, (state, action) => ({
    ...state,
    ...action.payload,
    intro: true,
    loading: false,
    use: true,
    index: 0,
  })),
  on(WizardActions.WizardIntroEnd, (state, action) => ({
    ...state,
    intro: false,
  })),
  on(WizardActions.WizardSetUse, (state, action) => {
    const { use } = action.payload;
    return {
      ...state,
      ...action.payload,
      list: use ? state.list : [],
      index: use ? 0 : -1,
      intro: use,
    };
  }),
  on(WizardActions.WizardGotoApply, (state, action) => {
    const index = state.use ? action.index ?? -1 : -1;
    return {
      ...state,
      index,
    };
  }),
  on(WizardActions.WizardListLoadFail, (state, action) => ({
    ...state,
    loading: false,
    message: action.message,
  }))
);
