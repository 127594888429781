import { HospitalInfoUiModel } from '~models/hospital.model';
import { HospitalInfoActions } from '~actions/products/hospital-info.actions';
import { createHospitalInfoUiModel } from '../../services/hospital-info/hospital-info.create';
import { createReducer, on } from '@ngrx/store';

export interface HospitalInfoState {
  info: HospitalInfoUiModel;
}

const initialState: HospitalInfoState = {
  info: createHospitalInfoUiModel(),
};

export const reducer = createReducer(
  initialState,
  on(HospitalInfoActions.HospitalInfoLoadSuccess, (state, action) => ({
    ...state,
    info: action.payload,
  })),
  on(HospitalInfoActions.HospitalInfoChange, (state, action) => ({
    ...state,
    info: { ...state.info, ...action.payload },
  }))
);
