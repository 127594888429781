import { Component, EventEmitter, Input, Output } from '@angular/core';
import { makeNumberArray } from '../../service/util';

let instSeq = 0;

function initWeekdaysChecked() {
  return [false, false, false, false, false, false, false];
}

@Component({
  selector: 'app-weekday-selector',
  templateUrl: './weekday-selector.component.html',
  styleUrls: ['./weekday-selector.component.scss'],
})
export class WeekdaySelectorComponent {
  weekdaysChecked: boolean[] = initWeekdaysChecked();

  @Input() set weekdays(weekdays: boolean[]) {
    if (!weekdays || weekdays.length !== 7) {
      this.weekdaysChecked = initWeekdaysChecked();
    }
    this.weekdaysChecked = this.weekdaysChecked.map(
      (checked, idx) => !!weekdays[idx]
    );
  }

  nums = makeNumberArray(0, 6);
  instKey = instSeq++;

  @Output() weekDaySelectEventSelect = new EventEmitter<boolean[]>();

  constructor() {}

  onCheckedChange(event: any, index: number) {
    event.stopPropagation();
    const value = event.target.checked as boolean;
    this.weekdaysChecked[index] = value;
    this.weekDaySelectEventSelect.emit(this.weekdaysChecked);
  }
}
