import { createAction, props } from '@ngrx/store';
import {
  DayOfWeekMap,
  EachMonthTag,
  HasScheduleMonthInfo,
  HolidaysInfo,
  OccupiedTimetableRange,
  OperateTime,
  ReservationScheduleQuery,
  ReservationScheduleV2ReqModel,
  ReservationScheduleV2ResModel,
  SaveActionTypeEnum,
  Schedule,
  ScheduleExistsReqModel,
  SchedulerDate,
  SymptomCategoryResModel,
} from '~models/reservation-schedule-v2.model';
import { DateRange, SchedulerTags } from '~models/common.model';

const RESERVATION_SCHEDULE = 'Reservation Schedule v2' as const;

export const ReservationScheduleTypes = {
  FETCH_RESERVATION_SCHEDULE: `[${RESERVATION_SCHEDULE}] Fetch Reservation Schedule`,
  FETCH_RESERVATION_SCHEDULE_SUCCESS: `[${RESERVATION_SCHEDULE}] Fetch Reservation Schedule Success`,

  FETCH_SYMPTOM_CATEGORY_LIST: `[${RESERVATION_SCHEDULE}] Fetch Symptom Category List`,
  FETCH_SYMPTOM_CATEGORY_LIST_SUCCESS: `[${RESERVATION_SCHEDULE}] Fetch Symptom Category List Success`,

  FETCH_OPERATETIME: `[${RESERVATION_SCHEDULE}] Fetch Operate Time`,
  FETCH_OPERATETIME_SUCCESS: `[${RESERVATION_SCHEDULE}] Fetch Operate Time Success`,

  FETCH_HOLIDAYS: `[${RESERVATION_SCHEDULE}] Fetch Holidays`,
  FETCH_HOLIDAYS_SUCCESS: `[${RESERVATION_SCHEDULE}] Fetch Holidays Success`,

  UPDATE_TIME: `[${RESERVATION_SCHEDULE}] Update Time`,
  UPDATE_TIME_SUCCESS: `[${RESERVATION_SCHEDULE}] Update Time Success`,

  UPDATE_RESERVATION_SCHEDULE: `[${RESERVATION_SCHEDULE}] Update Reservation Schedule`,
  UPDATE_RESERVATION_SCHEDULE_SUCCESS: `[${RESERVATION_SCHEDULE}] Update Reservation Schedule Success`,
  UPDATE_RESERVATION_SCHEDULE_FAIL: `[${RESERVATION_SCHEDULE}] Update Reservation Schedule Fail`,

  FETCH_EXISTS_SCHEDULE_MONTH: `[${RESERVATION_SCHEDULE}] Fetch Exists Schedule Month`,
  FETCH_EXISTS_SCHEDULE_MONTH_SUCCESS: `[${RESERVATION_SCHEDULE}] Fetch Exists Schedule Month Success`,

  RESERVATION_SCHEDULE_FAIL: `[${RESERVATION_SCHEDULE}] Reservation Schedule Fail`,

  TIMETABLE_ITEM_POPUP_TOGGLE: `[${RESERVATION_SCHEDULE}] Timetable Item Toggle`,

  UPDATE_MONTHS_TAG: `[${RESERVATION_SCHEDULE}] Update Months Tag`,
  UPDATE_MONTHS_TAG_SUCCESS: `[${RESERVATION_SCHEDULE}] Update Months Tag Success`,

  RESET_IS_FROM_V1: `[${RESERVATION_SCHEDULE}] Reset IsFromV1`,
  RESET_IS_FROM_V1_SUCCESS: `[${RESERVATION_SCHEDULE}] Reset IsFromV1 Success`,

  CALCULATE_OCCUPIED_TIMETABLE_RANGE: `[${RESERVATION_SCHEDULE}] Calculate Occupied timetable range`,

  SET_HAS_MODIFIED: `[${RESERVATION_SCHEDULE}] Set Has Modified`,

  SET_DRAG_AND_RESIZE_ITEM_KEY: `[${RESERVATION_SCHEDULE}] Set Drag and Resize Item Key`,

  SET_SAVE_ACTION_TYPE: `[${RESERVATION_SCHEDULE}] Set Save Action Type`,
} as const;

// 예약 3.0 스케줄 조회
export const FetchReservationSchedule = createAction(
  ReservationScheduleTypes.FETCH_RESERVATION_SCHEDULE,
  props<{ payload: ReservationScheduleQuery }>()
);

export const FetchReservationScheduleSuccess = createAction(
  ReservationScheduleTypes.FETCH_RESERVATION_SCHEDULE_SUCCESS,
  props<{ payload: ReservationScheduleV2ResModel }>()
);

// 증상 카테고리 목록 조회
export const FetchCategoryList = createAction(
  ReservationScheduleTypes.FETCH_SYMPTOM_CATEGORY_LIST
);

export const FetchCategoryListSuccess = createAction(
  ReservationScheduleTypes.FETCH_SYMPTOM_CATEGORY_LIST_SUCCESS,
  props<{ payload: SymptomCategoryResModel }>()
);

// 진료실 영업시간 조회
export const FetchOperateTime = createAction(
  ReservationScheduleTypes.FETCH_OPERATETIME,
  props<{ params: { unitKey: string } }>()
);

export const FetchOperateTimeSuccess = createAction(
  ReservationScheduleTypes.FETCH_OPERATETIME_SUCCESS,
  props<{
    payload: { operateTime: OperateTime };
  }>()
);

// 공휴일 조회
export const FetchHolidays = createAction(
  ReservationScheduleTypes.FETCH_HOLIDAYS,
  props<{ params: DateRange }>()
);

export const FetchHolidaysSuccess = createAction(
  ReservationScheduleTypes.FETCH_HOLIDAYS,
  props<{ payload: HolidaysInfo }>()
);

/**
 * 날짜/시간 변경
 */
export const UpdateTime = createAction(
  ReservationScheduleTypes.UPDATE_TIME,
  props<{
    payload: SchedulerDate;
  }>()
);

export const UpdateTimeSuccess = createAction(
  ReservationScheduleTypes.UPDATE_TIME_SUCCESS,
  props<{
    payload: {
      currentDate: string;
      year: number;
      month: number;
      firstDayOfWeek: string;
      week: number;
      totalWeek: number;
    };
  }>()
);

/**
 * 예약 3.0 스케줄 생성/수정
 */
export const UpdateReservationSchedule = createAction(
  ReservationScheduleTypes.UPDATE_RESERVATION_SCHEDULE,
  props<{
    payload: ReservationScheduleV2ReqModel;
    saveActionType: SaveActionTypeEnum;
    // routerPath?: string | string[];
  }>()
);
export const UpdateReservationScheduleSuccess = createAction(
  ReservationScheduleTypes.UPDATE_RESERVATION_SCHEDULE_SUCCESS,
  props<{
    payload: ReservationScheduleQuery;
    saveActionType: SaveActionTypeEnum;
    editorSchedules: Schedule[];
  }>()
);

export const UpdateReservationScheduleFail = createAction(
  ReservationScheduleTypes.UPDATE_RESERVATION_SCHEDULE_FAIL,
  props<{ error: any }>()
);

/**
 * 예약 스케줄러 관련 에러 액션
 */
export const ReservationScheduleFail = createAction(
  ReservationScheduleTypes.RESERVATION_SCHEDULE_FAIL,
  props<{ message: string }>()
);

/**
 * edit popup이 노출된 스케줄의 key값
 */
export const TimetableItemPopupToggle = createAction(
  ReservationScheduleTypes.TIMETABLE_ITEM_POPUP_TOGGLE,
  props<{ key: string }>()
);

export const CalculateOccupiedTimetableRange = createAction(
  ReservationScheduleTypes.CALCULATE_OCCUPIED_TIMETABLE_RANGE,
  props<DayOfWeekMap<OccupiedTimetableRange[]>>()
);

/*
 * 스케줄 있는 달 조회
 */
export const FetchExistsScheduleMonth = createAction(
  ReservationScheduleTypes.FETCH_EXISTS_SCHEDULE_MONTH,
  props<{
    params: ScheduleExistsReqModel;
  }>()
);

export const FetchExistsScheduleMonthSuccess = createAction(
  ReservationScheduleTypes.FETCH_EXISTS_SCHEDULE_MONTH_SUCCESS,
  props<{
    payload: HasScheduleMonthInfo;
    usedTags: SchedulerTags[];
    monthsTag: EachMonthTag;
  }>()
);

/**
 * 구 스케줄러 이동할 달의 태그를 V2로 변경
 */
export const UpdateMonthsTag = createAction(
  ReservationScheduleTypes.UPDATE_MONTHS_TAG,
  props<{
    payload: { roomKey: string; year: number; month: number };
  }>()
);
export const UpdateMonthsTagSuccess = createAction(
  ReservationScheduleTypes.UPDATE_MONTHS_TAG_SUCCESS,
  props<{
    isFromV1: boolean;
    route: {
      roomKey: string;
      year: number;
      month: number;
    };
  }>()
);

export const ResetIsFromV1 = createAction(
  ReservationScheduleTypes.RESET_IS_FROM_V1,
  props<{
    isFromV1: boolean;
  }>()
);

/**
 * 타임테이블, 진료항목 수정 체크
 */
export const SetHasModified = createAction(
  ReservationScheduleTypes.SET_HAS_MODIFIED,
  props<{
    payload: boolean;
  }>()
);

/**
 *
 */
export const SetSaveActionType = createAction(
  ReservationScheduleTypes.SET_SAVE_ACTION_TYPE,
  props<{ payload: SaveActionTypeEnum }>()
);

export const SetDragAndResizeEventItem = createAction(
  ReservationScheduleTypes.SET_DRAG_AND_RESIZE_ITEM_KEY,
  props<{ key: string }>()
);

export const ReservationScheduleActions = {
  FetchReservationSchedule,
  FetchReservationScheduleSuccess,

  FetchCategoryList,
  FetchCategoryListSuccess,

  FetchOperateTime,
  FetchOperateTimeSuccess,

  FetchHolidays,
  FetchHolidaysSuccess,

  UpdateTime,
  UpdateTimeSuccess,

  UpdateReservationSchedule,
  UpdateReservationScheduleSuccess,
  UpdateReservationScheduleFail,

  FetchExistsScheduleMonth,
  FetchExistsScheduleMonthSuccess,

  ReservationScheduleFail,

  TimetableItemPopupToggle,

  UpdateMonthsTag,
  UpdateMonthsTagSuccess,

  ResetIsFromV1,

  CalculateOccupiedTimetableRange,

  SetHasModified,

  SetDragAndResizeEventItem,

  SetSaveActionType,
};
