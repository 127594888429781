import { createAction, props } from '@ngrx/store';
import {
  CrmMessageTemplateRes,
  HospitalScheduleCrmTypeEnum,
} from '~models/hospital-crm.model';

export enum CrmMessageTemplateActionType {
  REQ_FAIL = '[CrmMessageTemplate] Req Fail',
  LIST_LOAD = '[CrmMessageTemplate] Push Message Template Load',
  LIST_LOAD_SUCCESS = '[CrmMessageTemplate] Push Message Template Load Success',
}

export const CrmMessageTemplateReqFail = createAction(
  CrmMessageTemplateActionType.REQ_FAIL,
  props<any>()
);

export const CrmMessageTemplateLoad = createAction(
  CrmMessageTemplateActionType.LIST_LOAD,
  props<{ payload: HospitalScheduleCrmTypeEnum }>()
);

export const CrmMessageTemplateLoadSuccess = createAction(
  CrmMessageTemplateActionType.LIST_LOAD_SUCCESS,
  props<{ payload: CrmMessageTemplateRes }>()
);

export const CrmMessageTemplateActions = {
  CrmMessageTemplateReqFail,
  CrmMessageTemplateLoad,
  CrmMessageTemplateLoadSuccess,
};
