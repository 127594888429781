<div class="period-dropdown">
  <div ngbDropdown class="period-dropdown-value">
    <div>
      <button
        class="ha-dropdown-toggle form-control"
        ngbDropdownToggle
        [disabled]="isDisabled"
      >
        {{ value }}
      </button>
    </div>
    <div ngbDropdownMenu class="dropdown-items">
      <div class="scroll-wrap">
        <button
          class="dropdown-item"
          *ngFor="let period of dropdownOptions"
          (click)="handleChange(period)"
        >
          {{ period }}
        </button>
      </div>
    </div>
  </div>
  <p *ngIf="suffix">{{ suffix }}</p>
</div>
