import { PaymentActivationInfoWithId } from '~models/PaymentActivationInfoWithId';
import { createAction, props } from '@ngrx/store';

export enum PaymentActionTypes {
  ACTIVATED_PAYMENT_DATA_LOAD = '[payment] Activated Payment Data Load',
  ACTIVATE_PAYMENT_SERVICE = '[payment] Activated Payment Service',
  ACTIVATE_PAYMENT_SERVICE_SUCCESS = '[payment] Activated Payment Service Success',
  HANDLING_TOGGLE_CHANGE = '[payment] Cancelled Payment Activation',
  HANDLING_TOGGLE_CHANGE_SUCCESS = '[payment] Cancelled Payment Activation Success',
  ACTIVATION_LOAD_SUCCESS = '[payment] Payment Activation Success',
  LOAD_FAILURE = '[payment] Payment Load Failure',
}

// 결제서비스 연동 시 병원 기본 데이터 관리
export const PaymentDataLoad = createAction(
  PaymentActionTypes.ACTIVATED_PAYMENT_DATA_LOAD
);

export const PaymentDataLoadSuccess = createAction(
  PaymentActionTypes.ACTIVATION_LOAD_SUCCESS,
  props<{ payload: PaymentActivationInfoWithId }>()
);

export const PaymentService = createAction(
  PaymentActionTypes.ACTIVATE_PAYMENT_SERVICE,
  props<{ activationState: boolean }>()
);

export const PaymentServiceSuccess = createAction(
  PaymentActionTypes.ACTIVATE_PAYMENT_SERVICE_SUCCESS
);

export const ToggleChange = createAction(
  PaymentActionTypes.HANDLING_TOGGLE_CHANGE,
  props<{ isActive: boolean }>()
);

export const ToggleChangeSuccess = createAction(
  PaymentActionTypes.HANDLING_TOGGLE_CHANGE_SUCCESS
);

export const HandleLoadFailure = createAction(
  PaymentActionTypes.LOAD_FAILURE,
  props<{ error: Error }>()
);

export const PaymentActions = {
  PaymentDataLoad,
  PaymentDataLoadSuccess,
  HandleLoadFailure,
  PaymentService,
  ToggleChange,
  PaymentServiceSuccess,
  ToggleChangeSuccess,
};
