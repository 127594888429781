import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-signin-contents',
  templateUrl: './signin-contents.component.html',
  styleUrls: [
    './signin-contents.common.scss',
    './signin-contents.component.scss',
    './signin-contents.header.scss',
  ],
})
export class SigninContentsComponent implements OnInit {
  @ViewChild('videoContent', {
    static: true,
  })
  videoContent: ElementRef<HTMLVideoElement>;

  @Output()
  login = new EventEmitter();

  options: AnimationOptions = {
    path: 'https://assets5.lottiefiles.com/private_files/lf30_uqhusfrx.json',
  };

  ngOnInit(): void {
    this.videoContent.nativeElement.autoplay = true;
    this.videoContent.nativeElement.muted = true;
    this.videoContent.nativeElement.loop = false;
    this.videoContent.nativeElement.playsInline = true;
  }

  onLoginClick(): void {
    this.login.emit();
  }
}
