import {
  HospitalNotice,
  HospitalNoticeList,
  HospitalNoticeSearchParams,
} from '~models/hospital.model';
import { HospitalNoticeActions } from '~actions/products/hospital-notice.actions';
import { createReducer, on } from '@ngrx/store';

export interface HospitalNoticeState {
  error: any;
  searchParams: HospitalNoticeSearchParams;
  list?: HospitalNoticeList; // HttpRes<HospitalNotice>;
  detail?: HospitalNotice;
}

const initialState: HospitalNoticeState = {
  error: null,
  searchParams: { pageNum: 1, searchKey: 'title', searchValue: '' },
};

export const reducer = createReducer(
  initialState,
  on(HospitalNoticeActions.HospitalNoticeLoad, (state, action) => ({
    ...state,
    searchParams: action.payload,
  })),
  on(HospitalNoticeActions.HospitalNoticeLoadSuccess, (state, action) => ({
    ...state,
    list: action.payload,
  })),
  on(
    HospitalNoticeActions.HospitalNoticeDetailLoadSuccess,
    (state, action) => ({
      ...state,
      detail: action.payload,
    })
  ),
  on(
    HospitalNoticeActions.HospitalNoticeLoadFail,
    HospitalNoticeActions.HospitalNoticeDetailLoadFail,
    HospitalNoticeActions.HospitalNoticeCreateFail,
    HospitalNoticeActions.HospitalNoticeUpdateFail,
    HospitalNoticeActions.HospitalNoticeDeleteFail,
    (state, action) => ({
      ...state,
      error: action.payload,
    })
  ),
  on(HospitalNoticeActions.HospitalNoticeDetailReset, (state, action) => ({
    ...state,
    detail: {
      title: '',
      content: '',
      hospital: '',
    },
  })),
  on(
    HospitalNoticeActions.HospitalNoticeSearchParamsReset,
    (state, action) => ({
      ...state,
      searchParams: { pageNum: 1, searchKey: 'title', searchValue: '' },
    })
  ),
  on(HospitalNoticeActions.HospitalNoticeClear, (state, action) => ({
    ...state,
    detail: undefined,
  }))
);

export const getHospitalNoticeSearchParams = (state: HospitalNoticeState) =>
  state.searchParams;
export const getHospitalNoticeList = (state: HospitalNoticeState) => state.list;
export const getHospitalNoticeDetail = (state: HospitalNoticeState) =>
  state.detail;
export const getError = (state: HospitalNoticeState) => state.error;
