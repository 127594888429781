import { createAction, props } from '@ngrx/store';
import {
  HospitalCrmReqModel,
  HospitalScheduleInitListModel,
  HospitalScheduleListModel,
  HospitalScheduleUiModel,
} from '~models/hospital-crm.model';

export enum CrmScheduleActionType {
  REQ_FAIL = '[CrmHospital] Req Fail',
  SCHEDULE_INIT = '[CrmHospital] Schedule Init',
  SCHEDULE_INIT_SUCCESS = '[CrmHospital] Schedule Init Success',
  SCHEDULE_INIT_FAIL = '[CrmHospital] Schedule Init Fail',
  SCHEDULE_LOAD = '[CrmHospital] Schedule Load',
  SCHEDULE_LOAD_SUCCESS = '[CrmHospital] Schedule Load Succ',
  SCHEDULE_LOAD_FAIL = '[CrmHospital] Schedule Load Fail',
  UPDATE_SCHEDULE = '[CrmHospital] Update Schedule',
  EDIT_SCHEDULE = '[CrmHospital] EDIT Schedule',
  EDIT_SCHEDULE_START = '[CrmHospital] EDIT Schedule Start',
  EDIT_SCHEDULE_SUCCESS = '[CrmHospital] EDIT Schedule Success',
  EDIT_SCHEDULE_FAIL = '[CrmHospital] EDIT Schedule Fail',
  DELETE_SCHEDULE = '[CrmHospital] Delete Schedule',
}

export const CrmScheduleReqFail = createAction(
  CrmScheduleActionType.REQ_FAIL,
  props<any>()
);

export const CrmScheduleInit = createAction(
  CrmScheduleActionType.SCHEDULE_INIT
);

export const CrmScheduleInitFail = createAction(
  CrmScheduleActionType.SCHEDULE_INIT_FAIL
);

export const CrmScheduleInitSuccess = createAction(
  CrmScheduleActionType.SCHEDULE_INIT_SUCCESS,
  props<{ payload: HospitalScheduleInitListModel }>()
);

export const CrmScheduleLoad = createAction(
  CrmScheduleActionType.SCHEDULE_LOAD,
  props<{ payload: HospitalCrmReqModel }>()
);

export const CrmScheduleLoadFail = createAction(
  CrmScheduleActionType.SCHEDULE_LOAD_FAIL
);

export const CrmScheduleLoadSuccess = createAction(
  CrmScheduleActionType.SCHEDULE_LOAD_SUCCESS,
  props<{ payload: HospitalScheduleListModel }>()
);

export const CrmUpdateSchedule = createAction(
  CrmScheduleActionType.UPDATE_SCHEDULE,
  props<{ schedule: HospitalScheduleUiModel }>()
);

export const CrmEditSchedule = createAction(
  CrmScheduleActionType.EDIT_SCHEDULE,
  props<{ _id: string }>()
);

export const CrmEditScheduleStart = createAction(
  CrmScheduleActionType.EDIT_SCHEDULE_START,
  props<{ _id: string }>()
);

export const CrmEditScheduleSuccess = createAction(
  CrmScheduleActionType.EDIT_SCHEDULE_SUCCESS,
  props<{ _id: string }>()
);

export const CrmEditScheduleFail = createAction(
  CrmScheduleActionType.EDIT_SCHEDULE_FAIL,
  props<{ _id: string }>()
);

export const CrmDeleteSchedule = createAction(
  CrmScheduleActionType.DELETE_SCHEDULE,
  props<{ _id: string }>()
);

export const CrmScheduleActions = {
  CrmScheduleReqFail,
  CrmScheduleLoad,
  CrmScheduleLoadFail,
  CrmScheduleLoadSuccess,
  CrmUpdateSchedule,
  CrmEditSchedule,
  CrmEditScheduleStart,
  CrmEditScheduleSuccess,
  CrmEditScheduleFail,
  CrmDeleteSchedule,
  CrmScheduleInit,
  CrmScheduleInitFail,
  CrmScheduleInitSuccess,
};
