import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Subject } from 'rxjs/';

@Component({
  selector: 'app-modal-body',
  templateUrl: './modal-body.component.html',
  styleUrls: ['./modal-body.component.scss'],
})
export class ModalBodyComponent {
  protected _open: boolean;
  @Input() set open(val: boolean) {
    this.preventScroll(val);
    try {
      this.modalBody.nativeElement.scrollTo(0, 0);
    } catch (error) {}
    this._open = val;
  }

  get open(): boolean {
    return this._open;
  }

  private _placement = 'middle';
  @Input() set placement(val: string) {
    if (!val) {
      return;
    }
    this._placement = val;
  }

  get placement(): string {
    return this._placement;
  }

  private _responsible: boolean;
  @Input() set responsible(val: boolean) {
    this._responsible = val;
  }

  get responsible(): boolean {
    return this._responsible;
  }

  private _closeByOverlay = true;
  @Input() set closeByOverlay(val: boolean) {
    this._closeByOverlay = val;
  }

  get closeByOverlay(): boolean {
    return this._closeByOverlay;
  }

  private _confirmWhenClose = false;
  @Input() set confirmWhenClose(val: boolean) {
    this._confirmWhenClose = val;
  }

  get confirmWhenClose(): boolean {
    return this._confirmWhenClose;
  }

  closing$ = new Subject<(result: any) => void>();
  closed$ = new Subject<any>();

  @ViewChild('modalBody', { static: true }) modalBody: ElementRef<
    HTMLDivElement
  >;

  isOpen() {
    return this.open === true;
  }

  private _close(result: any) {
    this._open = false;
    this.preventScroll(false);
    this.closed$.next(result);
  }

  preventScroll(prevent: boolean) {
    // document.body.style.overflowY = prevent ? 'hidden' : null;
  }

  close(val: any = false) {
    if (this.confirmWhenClose && !val) {
      this.preventScroll(false);
      this.closing$.next((result: any) => this._close(result));

      return;
    }
    this._close(val);
  }

  clickOverlay(event) {
    if (event) {
      event.stopPropagation();
    }
    if (!this.closeByOverlay) {
      return;
    }
    this._close(false);
  }

  clickModal(event) {
    event.stopPropagation();
  }
}
