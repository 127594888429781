import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { SimpleModalService } from '~shared/service/simple.modal.service';
import * as fromProduct from '~reducers/products';

@Component({
  selector: 'app-import-confirm-modal',
  template: `
    <div class="modal-header ha-modal-header">
      <div class="modal-title-wrap">
        <h5 class="modal-title">진료시간 설정 가져오기</h5>
        <p class="modal-title-desc">선택한 진료실의 설정 내역을 가져옵니다.</p>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="ha-btn ha-btn-secondary"
        (click)="activeModal.close('cancel')"
      >
        취소
      </button>
      <button type="button" class="ha-btn ha-btn-primary" (click)="emitTime()">
        가져오기
      </button>
    </div>
  `,
})
export class ImportOperatingTimeModalComponent implements OnInit {
  timeDateChange = new Subject<any>();

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<fromProduct.ProductState>,
    private p: SimpleModalService
  ) {}

  ngOnInit() {}

  emitTime() {
    this.activeModal.close('ok');
  }
}
