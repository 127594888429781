import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PLATFORM_TYPE } from '../../config';

export type SNS_TYPE = 'facebook' | 'kakaotalk';
export type SHARE_ACTION_TYPE = 'shareKakaoTalk' | 'shareFacebook';

export interface SnsShareInfo {
  title?: string;
  link: string;
  imageURL?: string;
  desc?: string;
  imageWidth?: number;
  imageHeight?: number;
}

export class SnsShareInfo implements SnsShareInfo {
  constructor(info?: SnsShareInfo) {
    this.imageURL = 'https://';
    Object.assign(this, info);
  }
}

@Injectable()
export class SnsShareService {
  platform: PLATFORM_TYPE;
  isMobile: boolean;

  constructor(route: ActivatedRoute) {
    const platform: PLATFORM_TYPE = (() => {
      const queryParamMap = route.snapshot.queryParamMap;
      const utmTypeStr = queryParamMap.get('utm_source') || '';
      switch (utmTypeStr) {
        case 'ddocdoc_aos':
          return 'android';
        case 'ddocdoc_ios':
          return 'ios';
        default:
          return 'web';
      }
    })();

    this.platform = platform;
    this.isMobile = platform !== 'web';
  }

  private _sendToIos = (action: SHARE_ACTION_TYPE, data: SnsShareInfo) => {
    try {
      window.webkit.messageHandlers.Ddocdoc.postMessage({ action, data });
    } catch (e) {
      console.log(e);
    }
    // tslint:disable-next-line: semicolon
  };

  private _sendToAndroid = (action: SHARE_ACTION_TYPE, data: SnsShareInfo) => {
    try {
      if (data) {
        const parsedDataStr = JSON.stringify(data);
        // 예전 안드로이드 인터페이스
        // 안드로이드 업데이트가 잘 이루어지면 삭제 예정
        if (window.Ddocdoc) {
          window.Ddocdoc[action](parsedDataStr);
        }

        // 개선한 안드로이드 인터페이스
        if (window.CpaWebToApp) {
          window.CpaWebToApp[action](parsedDataStr);
        }
      } else {
        // 예전 안드로이드 인터페이스
        // 안드로이드 업데이트가 잘 이루어지면 삭제 예정
        if (window.Ddocdoc) {
          window.Ddocdoc[action]();
        }

        // 개선한 안드로이드 인터페이스
        if (window.CpaWebToApp) {
          window.CpaWebToApp[action]();
        }
      }
    } catch (e) {
      console.log(e);
    }
    // tslint:disable-next-line: semicolon
  };

  private _sendToApp = (
    platform: PLATFORM_TYPE,
    action: SHARE_ACTION_TYPE,
    data: SnsShareInfo
  ) => {
    const initializedSnsShareInfo = new SnsShareInfo(data);
    if (platform === 'ios') {
      this._sendToIos(action, initializedSnsShareInfo);
    } else {
      this._sendToAndroid(action, initializedSnsShareInfo);
    }
    // tslint:disable-next-line: semicolon
  };

  shareSnsByPlatform = (
    type: SNS_TYPE,
    data: SnsShareInfo,
    ifDesktop: () => void
  ) => {
    if (this.isMobile) {
      switch (type) {
        case 'kakaotalk':
          this._sendToApp(this.platform, 'shareKakaoTalk', data);
          return;
        case 'facebook':
          this._sendToApp(this.platform, 'shareFacebook', data);
          return;
      }
    } else {
      ifDesktop();
    }
    // tslint:disable-next-line: semicolon
  };
}
