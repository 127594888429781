import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { HOSPITAL_ADMIN_PATH } from '~root/app/config';

type ImgNoticeModalButtonThemeType = 'def' | 'primary' | 'secondary' | 'spec';

@Component({
  selector: 'app-img-notice-modal',
  templateUrl: './img-notice-modal.component.html',
  styleUrls: ['./img-notice-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImgNoticeModalComponent implements OnInit {
  @Input() imgUrl = '';
  @Input() imgDesc = '';
  @Input() okText = '확인';
  @Input() cancelable = true;
  @Input() size: 'small' | 'middle' | 'large' = 'middle';
  @Input() cancelText = '닫기';
  @Input() theme: ImgNoticeModalButtonThemeType = 'primary';
  @Input() link = '';

  @Output() handleOk = new EventEmitter();
  // @Output() handleCancel = new EventEmitter();

  constructor(public activeModal: NgbActiveModal, private router: Router) {}

  ngOnInit(): void {}

  confirm(): void {
    this.activeModal.close(true);
    if (this.link) {
      const isInHospitalAdmin = this.link.indexOf(HOSPITAL_ADMIN_PATH) > -1;
      if (isInHospitalAdmin) {
        const [_, adminPath] = this.link.split(HOSPITAL_ADMIN_PATH);
        this.router.navigate([adminPath]);
      } else {
        window.open(this.link, '', '_blank');
      }
    }
    if (this.handleOk) {
      this.handleOk.emit();
    }
  }

  cancel(): void {
    this.activeModal.close(false);
    // this.activeModal.dismiss();
    // if (this.handleCancel) {
    //   this.handleCancel.emit();
    // }
  }

  get themeClass(): string {
    return `ha-btn-${this.theme}`;
  }

  get sizeClass(): string {
    return `ha-btn-${this.size}`;
  }
}
