import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

type ButtonType = 'text' | 'button';

@Component({
  selector: 'app-cancel-subscription',
  templateUrl: './cancel-subscription.component.html',
  styleUrls: ['./cancel-subscription.component.scss'],
})
export class CancelSubscriptionComponent implements OnInit {
  @Input() icon = '';
  @Input() title = '';
  @Input() description = '';
  @Input() buttonType: ButtonType = 'text';
  @Input() buttonLabel = '';

  @Output() useChange = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}
}
