<ng-template #TMP_DISPLAY>
  <span class="dr-label">
    {{ bsValue[0] | date: 'yy-MM-dd' }} ~ {{ bsValue[1] | date: 'yy-MM-dd' }}
  </span>
</ng-template>

<button
  type="button"
  class="button-area"
  #drp="bsDaterangepicker"
  bsDaterangepicker
  [attr.aria-expanded]="drp.isOpen"
  [bsConfig]="bsConfig"
  [minDate]="minDate"
  [maxDate]="maxDate"
  [placement]="placement"
  [(bsValue)]="bsValue"
  (bsValueChange)="handleBsValueChange($event)"
>
  <ng-container *ngIf="!bsValue[0]; else TMP_DISPLAY">
    <span class="dr-label"> {{ placeholder }} </span>
  </ng-container>
  <span class="ha-btn icon-button">
    <i class="icon icon-ic-calendar"></i>
  </span>
</button>

<!-- <ng-template #TMP_MODAL_TRIGGER>
  <button class="ha-btn icon-button" type="button" (click)="handleModalTrigger()">
    <i class="icon icon-ic-calendar"></i>
  </button>
</ng-template>

<ng-container *ngIf="!useModal; else TMP_MODAL_TRIGGER">

</ng-container> -->
