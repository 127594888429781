import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objNgFor'
})
export class ObjectNgFor implements PipeTransform {
  transform(value: any, args: any[] = [null]): any {
      if (value) {
        return Object.keys(value);
      }
      return [];
  }
}
