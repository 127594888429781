<div class="page-bg"></div>
<div class="wrap">
  <div class="find-header mb-5">
    <img
      class="mb-4"
      src="/assets/img/find-id-password.png"
      width="100"
      height="37"
      (click)="back()"
      style="cursor: pointer;"
    />
    <hr />
  </div>
  <div class="title-section"><span>아이디 찾기</span></div>
  <form [formGroup]="findIdForm">
    <span class="form-title">가입 시 입력한 기본 정보를 입력해주세요.</span>
    <div class="form-group">
      <input
        type="text"
        autocomplete="off"
        formControlName="name"
        class="form-control-input"
        id="inputUserName"
        placeholder="계정 담당자 이름"
      />
    </div>
    <div class="form-group" style="margin-bottom: 53px;">
      <input
        type="email"
        autocomplete="off"
        formControlName="email"
        class="form-control-input"
        id="inputEmail"
        placeholder="수신 가능한 이메일"
      />
    </div>
    <div class="form-group">
      <div class="form-title">병원 정보 (ex, 연세정형외과)</div>
      <app-hospital-select (hospitalInfo)="getHospitalInfo($event)">
      </app-hospital-select>
    </div>
    <div class="form-group mb-5">
      <input
        appNumberOnly
        maxlength="8"
        type="password"
        autocomplete="off"
        formControlName="ynum"
        class="form-control-input"
        id="inputYoNum"
        placeholder="요양기관번호 (숫자, 8자)"
      />
    </div>
    <button
      [ngClass]="{ 'btn-disabled': findIdForm.invalid }"
      type="submit"
      [disabled]="findIdForm.invalid"
      (click)="findId()"
      class="btn btn-lg submit-btn"
    >
      아이디 찾기
    </button>
    <p style="margin-top: 16px; color: red;">{{ error }}</p>
  </form>
</div>
