import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { koLocale } from 'ngx-bootstrap/locale';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  filter,
  map,
  pluck,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { fromEvent, Observable, of, Subject, Subscription } from 'rxjs';
import * as moment from 'moment';
import { isEmpty as _isEmpty } from 'lodash';

import {
  PaymentsSetting,
  PaymentsSettingType,
  PaymentsSettingTypeMapper,
  TOSS_SUB_MALL_ERROR_CODES,
  TossErrorKey,
} from '~models/payments.model';
import { ProductState } from '~reducers/products';
import { PaymentsActions } from '~actions/products/payments.actions';
import {
  getPaymentsBankCodes,
  getPaymentsDefaultAvailableDate,
  getPaymentsRegisterErrorKey,
  getPaymentsRegisterLoaded,
  getPaymentsUpdateActivationDateLoaded,
  getPaymentsUpdateSettingLoaded,
} from '~selectors/payments.selectors';
import {
  TelemedicineRequiredSettingInfo,
  TelemedicineRequiredStatus,
} from '~models/telemedicine.model';
import { getTelemedicineRequiredSetting } from '~selectors/telemedicine.selectors';
import { SimpleModalService } from '~shared/service/simple.modal.service';

export interface PaymentsSettingModalInstanceProps {
  active: boolean;
  paymentsSettingStatus: TelemedicineRequiredSettingInfo['status'];
  hospitalId: string;
  hospitalName: string;
  type: PaymentsSetting['type'];
  businessNumber: string;
  bankCode: string;
  holderName: string;
  accountNumber: string;
  activationDate: string;
  rejectedReason: string;
  isCompletedPayments: boolean;
}

interface FormValue {
  type: PaymentsSettingType;
  bankCode: string;
  holderName: string;
  accountNumber: string;
  activationDate: Date;
}

@Component({
  selector: 'app-payments-setting-modal',
  templateUrl: './payments-setting-modal.component.html',
  styleUrls: ['./payments-setting-modal.component.scss'],
})
export class PaymentsSettingModalComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('datePickerRef') datePickerRef: ElementRef<HTMLDivElement>;
  @ViewChild('datePickerBtnRef')
  datePickerBtnRef: ElementRef<HTMLButtonElement>;

  NOW = moment();
  BANK_CODES: string[];
  modalTitle = '';
  noticeInfo = '';
  submitBtnText = '';

  PaymentsSettingTypeMapper = PaymentsSettingTypeMapper;

  /* INPUTS */
  active: boolean;
  paymentsSettingStatus: TelemedicineRequiredSettingInfo['status'];
  hospitalId: string;
  hospitalName: string;
  type: PaymentsSetting['type'];
  businessNumber: string;
  accountNumber: string;
  holderName: string;
  activationDate: string;
  bankCode: string;
  rejectedReason: TossErrorKey;
  isCompletedPayments: boolean;
  /*  */

  paymentsSettingForm: FormGroup;
  activationDateFormControl: FormControl;
  bankCodeFormControl: FormControl;

  bsConfig: Partial<BsDatepickerConfig> = {
    showWeekNumbers: false,
    dateInputFormat: 'yyyy-mm-dd',
  };

  hasAccountError$: Observable<boolean>;
  registerErrorKey$: Observable<TossErrorKey>;
  clickedDatePickerText: Subscription = new Subscription();

  submitEvent = new Subject<void>();
  exceptNotSetUpdateEvent = new Subject<void>();

  onDestroy = new Subject<void>();

  constructor(
    public activeModal: NgbActiveModal,
    private localeService: BsLocaleService,
    private formBuilder: FormBuilder,
    private store$: Store<ProductState>,
    private simpleModalService: SimpleModalService
  ) {
    defineLocale('ko', koLocale);
    this.localeService.use('ko');

    this.store$
      .pipe(select(getPaymentsBankCodes), takeUntil(this.onDestroy))
      .subscribe(bankCodes => {
        this.BANK_CODES = bankCodes;
      });

    this.store$
      .pipe(select(getPaymentsDefaultAvailableDate), takeUntil(this.onDestroy))
      .subscribe(defaultAvailableDate => {
        this.bsConfig = {
          ...this.bsConfig,
          minDate: defaultAvailableDate.clone().toDate(),
        };
      });
  }

  ngAfterViewInit() {
    this.clickedDatePickerText = of(this.datePickerRef)
      .pipe(
        filter(datePickerElem => !!datePickerElem),
        switchMap(datePickerElem =>
          fromEvent(datePickerElem.nativeElement, 'click')
        ),
        takeUntil(this.onDestroy)
      )
      .subscribe(() => {
        this.datePickerBtnRef.nativeElement.click();
      });
  }

  ngOnInit(): void {
    this.modalTitle = this.isCompletedPayments
      ? '정산받을 계좌정보 수정'
      : '똑닥 간편결제 설정';
    this.noticeInfo = this.isCompletedPayments
      ? '매일 오후 11시~오전 2시 사이에는 계좌 정보를 수정할 수 없습니다.'
      : '똑닥 간편결제 정산 금액은 매월 10일에 입력하신 계좌로 정산됩니다.';
    this.submitBtnText = this.isCompletedPayments
      ? '수정'
      : '동의 후 똑닥 간편결제 사용';

    const paymentsSettingStatusInfo$ = this.store$.pipe(
      select(getTelemedicineRequiredSetting),
      pluck('payment')
    );

    this.registerErrorKey$ = paymentsSettingStatusInfo$.pipe(
      switchMap(info =>
        this.store$.select(getPaymentsRegisterErrorKey).pipe(
          map(registerErrorKey => {
            return info.reason || this.rejectedReason || registerErrorKey;
          })
        )
      )
    );

    this.hasAccountError$ = this.registerErrorKey$.pipe(
      map(
        registerErrorKey =>
          registerErrorKey === TOSS_SUB_MALL_ERROR_CODES.INVALID_BANK ||
          registerErrorKey ===
            TOSS_SUB_MALL_ERROR_CODES.INVALID_ACCOUNT_NUMBER ||
          registerErrorKey ===
            TOSS_SUB_MALL_ERROR_CODES.ACCOUNT_OWNER_CHECK_FAILED
      )
    );

    this.bankCodeFormControl = new FormControl(this.bankCode, [
      Validators.required,
    ]);

    this.activationDateFormControl = new FormControl(
      moment(this.activationDate).toDate(),
      [Validators.required]
    );

    this.paymentsSettingForm = this.formBuilder.group({
      type: [
        { value: this.type, disabled: this.active === false },
        Validators.required,
      ],
      bankCode: this.bankCodeFormControl,
      holderName: [this.holderName, Validators.required],
      accountNumber: [this.accountNumber, Validators.required],
      activationDate: this.activationDateFormControl,
    });

    this.submitEvent
      .pipe(
        switchMap(() => {
          const { accountNumber, holderName }: FormValue =
            this.paymentsSettingForm.value;

          const confirmContent =
            '<div class="account">' +
            `<p class="account--info">예금주명 : ${holderName}<br/>계좌번호 : ${accountNumber}</p>` +
            '<p class="account--notice">진료비가 정산 될 계좌이므로 정확한 확인이 필요합니다.</p>' +
            '<p>비브로스는 계좌번호 오기입으로 인한 책임을 지지 않습니다.<p>' +
            '</div>';

          return this.simpleModalService.confirm(
            confirmContent,
            '알림',
            '네, 맞아요',
            '다시 입력하기'
          );
        }),
        filter(confirmed => confirmed),
        takeUntil(this.onDestroy)
      )
      .subscribe(() => {
        const {
          activationDate: formActivationDate,
          bankCode,
          accountNumber,
          holderName,
          type,
        }: FormValue = this.paymentsSettingForm.value;

        if (this.paymentsSettingStatus === TelemedicineRequiredStatus.NOT_SET) {
          this.store$.dispatch(
            PaymentsActions.PaymentsRegister({
              req: {
                hospitalId: this.hospitalId,
                activationDate: moment(formActivationDate).format(),
                type,
                account: {
                  accountNumber,
                  bank: bankCode,
                  holderName,
                },
              },
            })
          );
        } else {
          this.store$.dispatch(
            PaymentsActions.PaymentsSettingUpdate({
              req: {
                account: {
                  accountNumber,
                  bank: bankCode,
                  holderName,
                },
              },
            })
          );
        }
      });

    const registerLoaded$ = this.store$.pipe(select(getPaymentsRegisterLoaded));
    const settingLoaded$ = this.store$.pipe(
      select(getPaymentsUpdateSettingLoaded)
    );
    const activationDateLoaded$ = this.store$.pipe(
      select(getPaymentsUpdateActivationDateLoaded)
    );
    const isEmptyErrorKey$ = this.registerErrorKey$.pipe(
      map(errorKey => _isEmpty(errorKey)),
      filter(isEmptyErrorKey => isEmptyErrorKey)
    );

    registerLoaded$
      .pipe(
        filter(loaded => loaded),
        switchMap(() => isEmptyErrorKey$),
        takeUntil(this.onDestroy)
      )
      .subscribe(() => {
        this.handleCancel();
      });

    settingLoaded$
      .pipe(
        filter(settingLoaded => settingLoaded),
        switchMap(() => isEmptyErrorKey$),
        takeUntil(this.onDestroy)
      )
      .subscribe(() => {
        if (!this.isCompletedPayments) {
          const { activationDate: formActivationDate }: FormValue =
            this.paymentsSettingForm.value;
          const originActivationDate = moment(this.activationDate).startOf(
            'day'
          );
          const newActivationDate = moment(formActivationDate).startOf('day');

          if (!originActivationDate.isSame(newActivationDate)) {
            this.store$.dispatch(
              PaymentsActions.PaymentsActivationDateUpdate({
                req: {
                  activationDate: moment(formActivationDate)
                    .set({
                      hour: 0,
                      minutes: 0,
                      second: 0,
                    })
                    .format(),
                },
              })
            );
          } else {
            this.store$.dispatch(
              PaymentsActions.PaymentsActivationDateUpdateSuccess({
                updater: {
                  activationDate: this.activationDate,
                },
              })
            );
          }
          this.exceptNotSetUpdateEvent.next();
        }
        this.handleCancel();
      });

    const noError$ = activationDateLoaded$.pipe(
      filter(activationDateLoaded => activationDateLoaded),
      switchMap(() => isEmptyErrorKey$)
    );

    this.exceptNotSetUpdateEvent
      .pipe(withLatestFrom(noError$), takeUntil(this.onDestroy))
      .subscribe(() => {
        this.store$.dispatch(
          PaymentsActions.PaymentsUseUpdate({ req: { use: true } })
        );
        this.handleCancel();
      });
  }

  get formattedDate(): string {
    const selectedDate = moment(this.activationDateFormControl.value);
    const isToday = selectedDate.isSame(this.NOW, 'day');
    const parsed = selectedDate.clone().locale('ko').format('YY/MM/DD (dd)');

    return isToday ? '오늘' : parsed;
  }

  get isNotAllowedTime(): boolean {
    const startTime = moment([23, 0, 0], 'HH:mm:ss').clone();
    const endTime = moment([2, 0, 0], 'HH:mm:ss').add(1, 'days').clone();

    return moment().clone().isBetween(startTime, endTime);
  }

  handleCancel(): void {
    this.store$.dispatch(PaymentsActions.PaymentsErrorKeyReset());
    this.activeModal.close(false);
  }

  handleActivationDateSelector(date: Date): void {
    this.activationDateFormControl.patchValue(date);
  }

  handleBankSelector(bank: string): void {
    this.bankCodeFormControl.patchValue(bank);
  }

  ngOnDestroy() {
    this.clickedDatePickerText.unsubscribe();

    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
