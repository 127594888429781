import { CrmStatisticsActions } from '~actions/products/hospital-crm-statistics.actions';
import { CrmStatisticsUiModel } from '~models/hospital-crm.model';
import { createReducer, on } from '@ngrx/store';

/**
 * @name CrmStatisticsState
 * @author Hong-ki Min
 * @description CrmStatistics + { pending }
 */
export interface CrmStatisticsState extends CrmStatisticsUiModel {
  pending: boolean;
}

const initState: CrmStatisticsState = {
  items: [],
  totalCount: 0,
  benefitAmount: 0,
  pending: false,
};

export const reducer = createReducer(
  initState,
  on(CrmStatisticsActions.CrmStatisticsLoad, (state, action) => ({
    ...state,
    pending: true,
  })),
  on(CrmStatisticsActions.CrmStatisticsLoadSuccess, (state, action) => {
    const {
      items,
      totalCount,
      skip,
      limit,
      sort,
      benefitAmount,
    } = action.payload;
    return {
      ...state,
      items,
      totalCount,
      skip,
      limit,
      sort,
      benefitAmount,
      pending: false,
    };
  }),
  on(CrmStatisticsActions.CrmStatisticsReqFail, (state, action) => ({
    ...state,
    pending: true,
  }))
);
