<span
  class="tooltip-wrapper"
  *ngIf="!!tooltip"
  [ngbTooltip]="tooltip"
  [placement]="tooltipPlacement"
  [tooltipClass]="tooltipClass"
  [autoClose]="autoClose"
  container="body"
>
  <i class="icon tooltip-icon" [ngClass]="iconClass"></i>
</span>
