import { createAction, props } from '@ngrx/store';
import { MobilePaperModel } from '~models/mobile-paper.model';

export const enum MobilePaperActionTypes {
  ERROR = '[Mobile Paper] Error',

  PAPER_USE_LOAD = '[Mobile Paper] Paper Use Load',
  PAPER_USE_LOAD_SUCCESS = '[Mobile Paper] Paper Use Load Success',

  PAPER_USE_UPDATE = '[Mobile Paper] Paper Use Update',
  PAPER_USE_UPDATE_SUCCESS = '[Mobile Paper] Paper Use Update Success',
  PAPER_USE_UPDATE_LAZY = '[Mobile Paper] Paper Use Update Lazy',
}

export const MobilePaperError = createAction(
  MobilePaperActionTypes.ERROR,
  props<{ error: any }>()
);

export const MobilePaperUseLoad = createAction(
  MobilePaperActionTypes.PAPER_USE_LOAD
);

export const MobilePaperUseLoadSuccess = createAction(
  MobilePaperActionTypes.PAPER_USE_LOAD_SUCCESS,
  props<{ payload: MobilePaperModel }>()
);

export const MobilePaperUseUpdate = createAction(
  MobilePaperActionTypes.PAPER_USE_UPDATE,
  props<{ use: boolean }>()
);

export const MobilePaperUseUpdateSuccess = createAction(
  MobilePaperActionTypes.PAPER_USE_UPDATE_SUCCESS,
  props<{ use: boolean }>()
);

export const MobilePaperUseUpdateLazy = createAction(
  MobilePaperActionTypes.PAPER_USE_UPDATE_LAZY,
  props<{ useLazy: boolean }>()
);

export const MobilePaperActions = {
  MobilePaperError,
  MobilePaperUseLoad,
  MobilePaperUseLoadSuccess,
  MobilePaperUseUpdate,
  MobilePaperUseUpdateSuccess,
  MobilePaperUseUpdateLazy,
};
