import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-copy-tab-list',
  templateUrl: './copy-tab-list.component.html',
  styleUrls: ['./copy-tab-list.component.scss']
})
export class CopyTabListComponent implements OnInit {
  @Input() theme: string;
  @Input() items: string[];
  @Input() optionalTitles: string[];

  private _fixday = 0;
  @Input() set fixday(val: number) {
    this._fixday = val;
  }
  get fixday(): number {
    return this._fixday;
  }

  private _tab = 0;
  @Input() set tab(val: number) {
    this._tab = val;
  }
  get tab(): number {
    return this._tab;
  }

  @Output() tabSelect = new EventEmitter<number>();

  ngOnInit(): void {}

  get themeClass() {
    if (this.theme) {
      return this.theme;
    }
    return this.theme;
  }

  onTabSelect(index: number) {
    if (index === this._fixday) {
      return false;
    }
    this._tab = index;
    this.tabSelect.emit(index);
  }
}
