import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ClipboardModule } from 'ngx-clipboard';
import { FaqService } from '~core/api/faq.service';
import { SharedService } from '~core/api/shared.service';
import { ImportOperatingTimeModalComponent } from './components/modals/re-import-confirm-modal.component';
import { ReceptionOptionModalComponent } from './components/modals/re-option-modal.component';
import { ReceptionPauseFormModalComponent } from './components/modals/re-pause.modal.component';
import { ReceptionPeaktimeModalComponent } from './components/modals/re-peaktime-copy.modal.component';
import { RoomSelectModalComponent } from './components/modals/re-room-select-modal.component';
import { ReceptionWaitOptionModalComponent } from './components/modals/re-wait-option-modal.component';
import { AccordionBoardComponent } from './components/accordion-board/accordion-board.component';
import { AccordionTableComponent } from './components/accordion-table/accordion-table.component';
import { PageFooterComponent } from './components/apply/page-footer/page-footer.component';
import { ApplyBannerSetComponent } from './components/banners/apply-banner-set.component';
import { BannerFaqComponent } from './components/banners/faq/banner-faq.component';
import { GraphBannerComponent } from './components/banners/graph-banner/graph-banner.component';
import { BannerReceptionComponent } from './components/banners/reception/banner-reception.component';
import { BannerReservationComponent } from './components/banners/reservation/banner-reservation.component';
import { BannerReviewsComponent } from './components/banners/reviews/reviews.component';
import { BannerSocialComponent } from './components/banners/social/social.component';
import { BannerWaitingBoardComponent } from './components/banners/waitingboard/banner-waiting-board.component';
import { BannerWinningsComponent } from './components/banners/winnings/winnings.component';
import { CardTimeTableComponent } from './components/card-time-table/card-time-table.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { AdminLoadingComponent } from './components/common/app-loading.component';
import { ContainerFooterComponent } from './components/container-footer/container-footer.component';
import { ContainerHeaderComponent } from './components/container-header/container-header.component';
import { ContainerRoomTitleHeaderComponent } from './components/container-room-title-header/container-room-title-header.component';
import { ContentSliderComponent } from './components/content-slider/content-slider.component';
import { CopyTabListComponent } from './components/copy-tab-list/copy-tab-list.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { HospitalSelectComponent } from './components/hospital-select/hospital-select.component';
import { HtmlContentLoaderComponent } from './components/html-content-loader/html-content-loader.component';
import { LabelCheckboxComponent } from './components/label-checkbox/label-checkbox.component';
import { LimitedInputTextComponent } from './components/limited-input-text/limited-input-text.component';
import { LimitedInputNumberComponent } from './components/limited-input-number/limited-input-number.component';
import { LimitedTextareaComponent } from './components/limited-textarea/limited-textarea.component';
import { ModalBodyComponent } from './components/modal-body/modal-body.component';
import { PageGuideComponent } from './components/page-guide/page-guide.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PendingSpinnerComponent } from './components/pending-spinner/pending-spinner.component';
import { RadioComponent } from './components/radio/radio.component';
import { ScrollSpyComponent } from './components/scroll-spy/scroll-spy.component';
import { ServiceVideoPopupComponent } from './components/service-video/popup/service-video-popup.component';
import { ServiceVideoComponent } from './components/service-video/service-video.component';
import { SignupHospitalSelectComponent } from './components/signup/signup-hospital-select.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TabListComponent } from './components/tab-list/tab-list.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { UsePanelComponent } from './components/use-panel/use-panel.component';
import { UseTogglePanelComponent } from './components/use-toggle-panel/use-toggle-panel.component';
import { WeekdaySelectorComponent } from './components/weekday-selector/weekday-selector.component';
import { FileDropDirective } from './directives/file.drop.directive';
import { SliderItemDirective } from './directives/slider-item.directive';
import { YnumAutoHyphenDirective } from './directives/ynum-auto-hyphen.directive';
import { PhoneNumAutoHyphenDirective } from './directives/phone-num-auto-hyphen.directive';
import { IconCalendarComponent } from './icons/icon-calendar.component';
import { IconInfoComponent } from './icons/icon-info.component';
import { ApmFormat } from './pipe/apm-format.pipe';
import { DayTransForm } from './pipe/dayTrans.pipe';
import { DualTimeFormatPipe } from './pipe/dual-time-format.pipe';
import { MomentFormatPipe } from './pipe/moment-format.pipe';
import { Nl2br } from './pipe/nl2br.pipe';
import { ObjectNgFor } from './pipe/obj-ngfor.pipe';
import { PhoneHyphen } from './pipe/phoneFormat.pipe';
import { ResPaperNamePipe } from './pipe/prescriptionPrintingPaper.pipe';
import { ResCellColorPipe } from './pipe/resCellColor.pipe';
import { ResScheduleColorPipe } from './pipe/resScheduleColor.pipe';
import { ResStateClassPipe } from './pipe/resStateClass.pipe';
import { ResStateClassListPipe } from './pipe/resStateClassList.pipe';
import { ResStateNamePipe } from './pipe/resStateName.pipe';
import { ResStateNameListPipe } from './pipe/resStateNameList.pipe';
import { TimeRangeFormatPipe } from './pipe/timeRangeFormat.pipe';
import { TruncateCharactersPipe } from './pipe/truncateCharacter.pipe';
import { WeekNamePipe } from './pipe/weekName.pipe';
import { SafeUrlPipe } from './pipe/safe-url.pipe';
import { CookieService } from './service/cookie.service';
import { ImageService } from './service/image.service';
import { KakaoService } from './service/kakao.service';
import { SnsShareService } from './service/sns-share.service';
import { StorageService } from './service/storage.service';
import { ValidatorService } from './service/validator.service';
import { InputTextComponent } from './components/input-text/input-text.component';
import { InputNumberComponent } from './components/input-number/input-number.component';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { CregnoAutoHyphenDirective } from './directives/cregno-auto-hyphen.directive';
import { AccountCreateFromEmrModalComponent } from './components/account-create-from-emr-modal/account-create-from-emr-modal.component';
import { AccountCreateTermsComponent } from './components/account-create-terms/account-create-terms.component';
import { AccountCreateFormsComponent } from './components/account-create-forms/account-create-forms.component';
import { SpeechTooltipComponent } from './components/speech-tooltip/speech-tooltip.component';
import { CardWrapperComponent } from './components/card-wrapper/card-wrapper.component';
import { CardBodyComponent } from './components/card-body/card-body.component';
import { CardHeaderComponent } from './components/card-header/card-header.component';
import { CardGuideComponent } from './components/card-guide/card-guide.component';
import { LabelListedSelectComponent } from './components/label-listed-select/label-listed-select.component';
import { ListedSelectComponent } from './components/listed-select/listed-select.component';
import { BoldTextPipe } from './pipe/bold-text.pipe';
import { EmphasisColorTextPipe } from './pipe/emphasis-color-text.pipe';
import { CareRoomNameService } from '~shared/service/careroom-name.service';
import { CancelSubscriptionComponent } from './components/cancel-subscription/cancel-subscription.component';
import { CircleIconComponent } from '~shared/components/circle-icon/circle-icon.component';
import { SanitizeHtmlPipe } from './pipe/sanitize-html.pipe';
import { ServiceUsePanelComponent } from '~shared/components/service-use-panel/service-use-panel.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { ChannelService } from './service/channeltalk.service';
import { IconTooltipComponent } from '~shared/components/icon-tooltip/icon-tooltip.component';
import { ClickOutSideDirective } from './directives/click-outside-of-target.directive';
import { CommonFooterComponent } from './components/common-footer/common-footer.component';
import { NumberCommaPipe } from './pipe/number-comma.pipe';
import { DecodeUnitKeyPipe } from './pipe/decode-unitKey.pipe';
import { UserGuideButtonComponent } from './components/user-guide-button/user-guide-button.component';
import { NumberRangeDropdownComponent } from '~shared/components/number-range-dropdown/number-range-dropdown.component';
import { DayOfWeekKorPipe } from './pipe/day-of-week-kor.pipe';
import { ImgNoticeModalComponent } from './components/img-notice-modal/img-notice-modal.component';
import { AgreementModalComponent } from './components/agreement-modal/agreement-modal.component';
import { AlimtalkAgreementModalComponent } from './components/alimtalk-agreement-modal/alimtalk-agreement-modal.component';
import { CoronaFaceTreatmentModalComponent } from './components/corona-face-treatment-modal/corona-face-treatment-modal.component';
import { ShortUrlUsageTipModalComponent } from './components/short-url-usage-tip-modal/short-url-usage-tip-modal.component';
import { DataCyDirective } from './directives/data-cy.directive';
import { TimeDropdownSelectorComponent } from '~shared/components/time-dropdown-selector/time-dropdown-selector.component';
import { AutoHyphenPhoneNumberPipe } from './pipe/auto-hyphen-phone-number.pipe';
import { BankNamePipe } from './pipe/bank-name.pipe';
import { TossErrorPipe } from './pipe/toss-error.pipe';
import { PaymentsSettingModalComponent } from '~shared/components/payments-setting-modal/payments-setting-modal.component';

const components = [
  DayTransForm,
  ApmFormat,
  ObjectNgFor,
  Nl2br,

  TruncateCharactersPipe,
  MomentFormatPipe,
  WeekNamePipe,
  ResStateNameListPipe,
  ResStateNamePipe,
  ResStateClassPipe,
  ResStateClassListPipe,
  ResScheduleColorPipe,
  ResCellColorPipe,
  ResPaperNamePipe,
  HospitalSelectComponent,
  ResStateNamePipe,
  ResStateClassPipe,
  TimeRangeFormatPipe,
  DualTimeFormatPipe,
  SafeUrlPipe,
  BoldTextPipe,
  EmphasisColorTextPipe,
  BankNamePipe,
  TossErrorPipe,

  HospitalSelectComponent,
  PhoneHyphen,
  ContainerHeaderComponent,
  ContainerRoomTitleHeaderComponent,
  ContainerFooterComponent,
  CommonFooterComponent,
  CardTimeTableComponent,
  AdminLoadingComponent,
  AccordionTableComponent,
  DropdownComponent,
  PageGuideComponent,
  IconInfoComponent,
  IconCalendarComponent,
  SpinnerComponent,
  PageFooterComponent,
  HtmlContentLoaderComponent,
  ContentSliderComponent,
  // ContentSliderChildComponent,
  PaginationComponent,
  AccordionBoardComponent,
  TabListComponent,
  TimeDropdownSelectorComponent,
  CopyTabListComponent,
  ServiceVideoComponent,
  ServiceVideoPopupComponent,
  BannerWaitingBoardComponent,
  BannerReservationComponent,
  BannerReceptionComponent,
  BannerFaqComponent,
  ApplyBannerSetComponent,
  ScrollSpyComponent,
  GraphBannerComponent,
  BannerReviewsComponent,
  BannerSocialComponent,
  BannerWinningsComponent,
  ToggleSwitchComponent,
  LimitedTextareaComponent,
  LimitedInputNumberComponent,

  ModalBodyComponent,
  LabelCheckboxComponent,
  WeekdaySelectorComponent,

  SliderItemDirective,
  FileDropDirective,
  YnumAutoHyphenDirective,
  PhoneNumAutoHyphenDirective,
  NumberOnlyDirective,
  CregnoAutoHyphenDirective,
  ClickOutSideDirective,
  DataCyDirective,

  ContainerRoomTitleHeaderComponent,

  PendingSpinnerComponent,
  CheckboxComponent,
  UseTogglePanelComponent,
  LimitedInputTextComponent,
  InputTextComponent,
  InputNumberComponent,
  RadioComponent,
  TimePickerComponent,
  UsePanelComponent,

  ImportOperatingTimeModalComponent,
  ReceptionPauseFormModalComponent,
  ReceptionOptionModalComponent,
  ReceptionWaitOptionModalComponent,
  RoomSelectModalComponent,
  ReceptionPeaktimeModalComponent,

  SignupHospitalSelectComponent,

  AccountCreateFromEmrModalComponent,
  AccountCreateTermsComponent,
  AccountCreateFormsComponent,

  SpeechTooltipComponent,

  // card component
  CardWrapperComponent,
  CardBodyComponent,
  CardHeaderComponent,
  CardGuideComponent,

  // listed Select component
  LabelListedSelectComponent,
  ListedSelectComponent,

  CancelSubscriptionComponent,
  CircleIconComponent,

  ServiceUsePanelComponent,

  IconTooltipComponent,

  UserGuideButtonComponent,

  NumberRangeDropdownComponent,
  ImgNoticeModalComponent,
  AgreementModalComponent,
  AlimtalkAgreementModalComponent,
  CoronaFaceTreatmentModalComponent,
  ShortUrlUsageTipModalComponent,
  PaymentsSettingModalComponent,
];

export const ReModals = {
  importOperatingTime: ImportOperatingTimeModalComponent,
  pauseForm: ReceptionPauseFormModalComponent,
  option: ReceptionOptionModalComponent,
  waitOptions: ReceptionWaitOptionModalComponent,
  roomSelect: RoomSelectModalComponent,
  PeaktimeCopy: ReceptionPeaktimeModalComponent,
  AccountCreate: AccountCreateFromEmrModalComponent,
};

const entry = [
  ImportOperatingTimeModalComponent,
  ReceptionPauseFormModalComponent,
  ReceptionOptionModalComponent,
  ReceptionWaitOptionModalComponent,
  RoomSelectModalComponent,
  ReceptionPeaktimeModalComponent,
  RadioComponent,
  TimePickerComponent,
  UsePanelComponent,
  AccountCreateFromEmrModalComponent,
];

@NgModule({
  imports: [
    NgbModule,
    RouterModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    ClipboardModule,
    BsDatepickerModule.forRoot(),
  ],
  declarations: [
    ...components,
    SanitizeHtmlPipe,
    PageTitleComponent,
    NumberCommaPipe,
    DecodeUnitKeyPipe,
    DayOfWeekKorPipe,
    AutoHyphenPhoneNumberPipe,
  ],
  entryComponents: entry,
  exports: [
    ...components,
    NgbModule,
    RouterModule,
    BsDatepickerModule,
    SanitizeHtmlPipe,
    PageTitleComponent,
    NumberCommaPipe,
    DecodeUnitKeyPipe,
    DayOfWeekKorPipe,
    AutoHyphenPhoneNumberPipe,
    BankNamePipe,
    TossErrorPipe,
  ],
  providers: [
    CookieService,
    SharedService,
    StorageService,
    SnsShareService,
    FaqService,
    KakaoService,
    ImageService,
    ValidatorService,
    CareRoomNameService,
    ChannelService,
  ],
})
export class SharedModule {}
