<div class="scroll-contents-body" [ngClass]="items.order">
  <div *ngFor="let content of items.contents" class="scroll-item">
    <img
      class="scroll-item--img"
      [src]="content.scrollImgUrl"
      alt="스크롤 이미지"
    />
    <h3 class="scroll-item--title">{{ content.title }}</h3>
    <p class="scroll-item--explain">{{ content.explain }}</p>
  </div>
</div>
