<div ngbDropdown class="">
  <button
    type="button"
    class="form-control category-selector"
    ngbDropdownToggle
  >
    {{ selectedTitle }}
  </button>
  <div class="dropdown-menu" ngbDropdownMenu>
    <button
      type="button"
      class="dropdown-item"
      *ngFor="let item of agencyTypeList$ | async"
      (click)="onItemClick(item)"
    >
      {{ item.emr + ' - ' + item.agency }}
    </button>
  </div>
</div>
