import {
  CellInfo,
  EnableTime,
  Holiday,
  HourItem,
  HourRange,
  SymptomUnit,
  TimeRange,
} from '~models/reservation-timeline.model';
import { formatHourMinutes } from './res-timeline-schedule.util';
import { OfficeWeeklyDetailData } from '~models/care-room.model';
import { isEqual as _isEqual } from 'lodash';

export function createEnableTime(
  cellInfo: CellInfo,
  hourItems: HourItem[],
  symptomUnit: SymptomUnit,
  timeUnit: number,
  autoAdjust = true
): EnableTime {
  return createEnableTimeWithRowCol(
    cellInfo.row,
    cellInfo.col,
    hourItems,
    symptomUnit,
    timeUnit,
    autoAdjust
  );
}

export function createEnableTimeWithRowCol(
  row: number,
  col: number,
  hourItems: HourItem[],
  symptomUnit: SymptomUnit,
  timeUnit: number,
  autoAdjust = true
): EnableTime {
  const hourItem = hourItems[row];

  if (!hourItem) {
    console.log(
      'createEnableTimeWithRowCol',
      row,
      col,
      hourItems,
      symptomUnit,
      timeUnit
    );

    throw new Error('아싸 오류 ^^');
  }

  const iHourStart = hourItems[row].raw;
  let iHourEnd = iHourStart;
  const iMinStart = col * timeUnit;
  let iMinEnd = iMinStart + symptomUnit.timeUnit;

  if (iMinEnd >= 60) {
    iHourEnd += 1;
    iMinEnd -= 60;
  }

  if (iHourEnd > 24) {
    iHourEnd -= 24;
  }

  return {
    startTime: formatHourMinutes(iHourStart, iMinStart),
    endTime: formatHourMinutes(iHourEnd, iMinEnd),
    count: symptomUnit.count,
    symptom: symptomUnit.symptom,
  };
}

export const filterEnableTimeEquals = (enableTimeNew: EnableTime) => (
  item: EnableTime
) => {
  return (
    item.startTime === enableTimeNew.startTime &&
    item.endTime === enableTimeNew.endTime &&
    item.symptom === enableTimeNew.symptom
  );
};

function timeToNumber(time: string, before: number = 0): number {
  if (before === 0) {
    return parseInt(time.replace(':', ''), 10);
  }
  const timeBefore = before;
  let timeAfter = timeToNumber(time);

  if (timeBefore >= 2400 && timeAfter < 200) {
    timeAfter += 2400;
  }

  return timeAfter;
}

const filterNotOverlap = (enableTimeNew: EnableTime) => (item: EnableTime) => {
  const { startTime: startTimeOld, endTime: endTimeOld } = item;
  const { startTime: startTimeNew, endTime: endTimeNew } = enableTimeNew;

  const iOldStart = timeToNumber(startTimeOld);
  const iOldEnd = timeToNumber(endTimeOld, iOldStart);
  const iNewStart = timeToNumber(startTimeNew);
  const iNewEnd = timeToNumber(endTimeNew, iNewStart);
  const cond1 = iOldEnd > iNewStart && iOldStart <= iNewStart;
  const cond2 = iOldStart < iNewEnd && iOldEnd >= iNewEnd;
  const cond3 = iOldStart <= iNewStart && iOldEnd >= iNewEnd;
  const cond4 = iOldStart >= iNewStart && iOldEnd <= iNewEnd;

  // console.log('filterNotOverlap', `${item.startTime}~${item.endTime} <> ${enableTimeNew.startTime}~${enableTimeNew.endTime}`);
  // console.log('filterNotOverlap', cond1, cond2, cond3, cond4);

  return !(cond1 || cond2 || cond3 || cond4);
};

export function clearOverlapEnableTimesByNewList(
  enableTimes: EnableTime[],
  newList: EnableTime[]
) {
  if (
    !enableTimes ||
    enableTimes.length === 0 ||
    !newList ||
    newList.length === 0
  ) {
    return enableTimes || [];
  }
  // const newFirstStart = newList[0].startTime;
  // const newLastEnd = newList[newList.length - 1].endTime;
  let iNew = 0;

  return enableTimes.filter(et => {
    if (newList.length <= iNew) {
      return true;
    }
    const net = newList[iNew];
    const bRet = filterNotOverlap(net)(et);

    if (!bRet) {
      iNew++;
    }

    return bRet;
  });

  // 오버랩되는 대상을 먼저 뽑는다.
  // const oldET = enableTimes.filter(et => et.startTime >= newFirstStart || et.endTime <= newLastEnd);
  // const mNewList: HashMap<boolean> = {};
  // const fnGetKey = (item: EnableTime) => `${item.startTime}_${item.endTime}`;

  // newList.forEach(item => mNewList[fnGetKey(item)] = true);

  // if (
  //   !enableTimes ||
  //   enableTimes.length === 0 ||
  //   !newList ||
  //   newList.length === 0
  // ) {
  //   return enableTimes || [];
  // }

  // return enableTimes.filter(et => !mNewList[fnGetKey(et)]);
}

export function clearOverlapEnableTimes(
  enableTimes: EnableTime[],
  enableTimeNew: EnableTime
) {
  // const hasEquals =
  //   enableTimes.filter(filterEnableTimeEquals(enableTimeNew)).length > 0;
  const hasEquals =
    enableTimes.filter(item => _isEqual(item, enableTimeNew)).length > 0;
  const enableTime = enableTimes.filter(filterNotOverlap(enableTimeNew));

  return {
    hasEquals,
    enableTime,
  };
}

const filterOverlap = (enableTimeNew: EnableTime) => (item: EnableTime) => {
  const { startTime: startTimeOld, endTime: endTimeOld } = item;
  const { startTime: startTimeNew, endTime: endTimeNew } = enableTimeNew;

  const iOldStart = timeToNumber(startTimeOld);
  const iOldEnd = timeToNumber(endTimeOld, iOldStart);
  const iNewStart = timeToNumber(startTimeNew);
  const iNewEnd = timeToNumber(endTimeNew, iNewStart);
  const cond1 = iOldEnd > iNewStart && iOldStart <= iNewStart;
  const cond2 = iOldStart < iNewEnd && iOldEnd >= iNewEnd;
  const cond3 = iOldStart <= iNewStart && iOldEnd >= iNewEnd;
  const cond4 = iOldStart >= iNewStart && iOldEnd <= iNewEnd;

  // console.log('filterOverlap', `${item.startTime}~${item.endTime} <> ${enableTimeNew.startTime}~${enableTimeNew.endTime}`);
  // console.log('filterOverlap', cond1, cond2, cond3, cond4);

  return cond1 || cond2 || cond3 || cond4;
};

export function findOverlapEnableTime(
  enableTimes: EnableTime[],
  enableTimeNew: EnableTime
): EnableTime | null {
  const filtered = enableTimes.filter(filterOverlap(enableTimeNew));

  // console.log('enableTimes', enableTimes);
  // console.log('filtered', filtered);

  if (filtered.length > 0) {
    return filtered[0];
  }
  return null;
}

export function makeEnableTimes(
  hourRange: HourRange,
  hourItems: HourItem[],
  unit: SymptomUnit,
  // preventRange: {[key: string]: HourMinRange[]},
  timeUnit: number
): EnableTime[] {
  const aStart = hourRange.start.split(':');
  const aEnd = hourRange.end.split(':');
  let iStartHour = parseInt(aStart[0], 10);
  const iStartMin = parseInt(aStart[1], 10);
  let iEndHour = parseInt(aEnd[0], 10);
  const iEndMin = parseInt(aEnd[1], 10);
  // const iEndTime = iStartMin + (unit ? unit.timeUnit : timeUnit);
  const sectionCount = unit ? Math.floor(unit.timeUnit / timeUnit) : 1;
  const symptomId = unit ? unit.symptom : null;
  const count = unit ? unit.count : 0;
  const iAbleFirstHour = hourItems[0].raw;
  const iAbleLastHour = hourItems[hourItems.length - 1].raw;

  if (iAbleFirstHour > iStartHour) {
    iStartHour = iAbleFirstHour;
  }
  if (iAbleLastHour < iEndHour) {
    iEndHour = iAbleLastHour;
  }

  return makeEnableTimesInner(
    iStartHour,
    iStartMin,
    iEndHour,
    iEndMin,
    symptomId,
    count,
    sectionCount,
    timeUnit
  );
}

function makeEnableTimesInner(
  startHour: number,
  startMin: number,
  endHour: number,
  endMin: number,
  symptomId: string | null,
  count: number,
  sectionCount: number,
  timeUnit: number
): EnableTime[] {
  const aRet: EnableTime[] = [];
  const sectionUnit = sectionCount * timeUnit;
  // let endMin = endMinRaw;
  let iStartHour = 0;
  let iStartMin = 0;
  let iEndHour = 0;
  let iEndMin = 0;

  // if (endMin <= startMin) {
  //   endMin += 60;
  // }

  const startTime = startHour * 60 + startMin;
  const endTime = endHour * 60 + endMin;

  for (let iTime = startTime; iTime < endTime; iTime += sectionUnit) {
    iStartHour = Math.floor(iTime / 60);
    iStartMin = iTime % 60;
    iEndHour = iStartHour;
    iEndMin = iStartMin + sectionUnit;

    if (iTime + sectionUnit > endTime) {
      break;
    }

    if (iStartMin >= 60) {
      iStartHour += 1;
      iStartMin -= 60;
    }

    if (iEndMin >= 60) {
      iEndHour += 1;
      iEndMin -= 60;
    }

    aRet.push({
      startTime: formatHourMinutes(iStartHour, iStartMin),
      endTime: formatHourMinutes(iEndHour, iEndMin),
      symptom: symptomId ?? '',
      count,
    });
  }

  // for (let iHour = startHour; iHour <= endHour; iHour++) {
  //   for (let iMin = startMin; (iMin <= endMin); iMin += sectionUnit) {
  //     iStartHour = iHour;
  //     iStartMin = iMin;
  //     iEndHour = iHour;
  //     iEndMin = iMin + sectionUnit;

  //     if ((iStartMin >= 60)) {
  //       iStartHour += 1;
  //       iStartMin -= 60;
  //     }

  //     if (iEndMin >= 60) {
  //       iEndHour += 1;
  //       iEndMin -= 60;
  //     }

  //     aRet.push({
  //       startTime: formatHourMinutes(iStartHour, iStartMin),
  //       endTime: formatHourMinutes(iEndHour, iEndMin),
  //       symptom: symptomId,
  //       count,
  //     });
  //   }
  // }

  return aRet;
}

/**
 * target을 대상으로 source 가 얼마나 포함되거나 걸쳐져 있는지 확인한다.
 * - 0 = 같을 때
 * - 1 = target 의 좌측을 넘어섬
 * - 2 = target 의 우측을 넘어섬
 * - -1 = target 의 좌측을 포함
 * - -2 = target 의 우측을 포함
 * - -3 = target 안에 포함됨
 * - -4 = target 을 포함 함.
 * - 999 = target의 값이 일부 혹은 전부 비었음
 * - -999 = 알 수 없음
 * @param target 목표
 * @param source 맞춰 볼 값
 */
function compareTimeRange(target: TimeRange, source: TimeRange) {
  if (!target || !target.startTime || !target.endTime) {
    return 999;
  }
  const { startTime: tgtStart, endTime: tgtEnd } = target;
  const { startTime: srcStart, endTime: srcEnd } = source;

  // 같을 때
  if (tgtStart === srcStart && tgtEnd === srcEnd) {
    // console.log(0, tgtStart, tgtEnd, srcStart, srcEnd);
    return 0;
  }

  // 타깃의 좌측을 넘어설 때
  if (srcStart < tgtStart && srcEnd <= tgtStart) {
    // console.log(1, tgtStart, tgtEnd, srcStart, srcEnd);
    return 1;
  }

  // 타깃의 우측을 넘어설 때
  if (tgtEnd <= srcStart && tgtEnd < srcEnd) {
    // console.log(3, tgtStart, tgtEnd, srcStart, srcEnd);
    return 2;
  }

  // 타깃 안에 포함 될 때
  if (tgtStart <= srcStart && tgtEnd >= srcEnd) {
    // console.log(-3, tgtStart, tgtEnd, srcStart, srcEnd);
    return -3;
  }

  // 타깃을 포함 할 때
  if (tgtStart >= srcStart && tgtEnd <= srcEnd) {
    // console.log(-4, tgtStart, tgtEnd, srcStart, srcEnd);
    return -4;
  }

  // 타깃의 좌측을 포함 할 때
  if (tgtStart >= srcStart && tgtEnd >= srcEnd && srcEnd < tgtEnd) {
    // console.log(-1, tgtStart, tgtEnd, srcStart, srcEnd);
    return -1;
  }

  // 타깃의 우측을 포함 할 때
  if (tgtStart <= srcStart && tgtEnd <= srcEnd && tgtStart < srcStart) {
    // console.log(-2, tgtStart, tgtEnd, srcStart, srcEnd);
    return -2;
  }

  // console.log(target, source);

  // 알 수 없는 결과
  return -999;
}

export const filterEnableTimesByOffData = (
  openTime: OfficeWeeklyDetailData,
  holiday: Holiday | null
) => (enableTime: EnableTime) => {
  // 휴일이면 무조건 막고 본다.
  if (!!holiday) {
    // console.log('filter: holiday');
    return false;
  }

  // 운영일이 아니면 막는다.
  // if (openTime.isDayOff) {
  //   // console.log('filter: isDayOff');
  //   return false;
  // }

  let iCompareResult = compareTimeRange(openTime, enableTime);

  iCompareResult = Math.abs(iCompareResult);

  // 스케줄이 운영 시간 바깥에 있거나 걸쳐 있다면 막는다.
  if (iCompareResult === 1 || iCompareResult === 2) {
    // console.log('filter: openTime', openTime.startTime, openTime.endTime, 'enableTime', enableTime.startTime, enableTime.endTime);
    return false;
  }

  // 점심 시간이 있다면 확인하고 막는다.
  if (
    openTime.hasLunchBreak &&
    // Math.abs(compareTimeRange(openTime.lunch, enableTime)) !== 999) {
    compareTimeRange(openTime.lunch, enableTime) <= 0
  ) {
    // console.log('filter: lunch', openTime.lunch, enableTime);
    return false;
  }

  // 저녁 시간이 있다면 확인하고 막는다.
  if (
    openTime.hasDinnerBreak &&
    // Math.abs(compareTimeRange(openTime.dinner, enableTime)) !== 999) {
    compareTimeRange(openTime.dinner, enableTime) <= 0
  ) {
    // console.log('filter: dinner', openTime.dinner, enableTime);
    return false;
  }

  // if (openTime.hasLunchBreak &&
  //   enableTime.startTime >= '13:00' &&
  //   enableTime.endTime < '14:00') {
  //   console.log(openTime.lunch, enableTime);
  // }

  return true;
};
