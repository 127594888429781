<div class="modal-overlay" *ngIf="isShow">
  <div class="inner">
    <div class="ha-modal">
      <!-- <a routerLink="/signin" class="ha-btn ha-btn-close" (click)="close()">
        <i class="icon icon-x-xl"></i>
        <b class="sr-only">닫기</b>
      </a> -->
      <button type="button" class="ha-btn ha-btn-close" (click)="close()">
        <i class="icon icon-x-xl"></i>
        <b class="sr-only">닫기</b>
      </button>
      <form
        class="form-signin"
        [formGroup]="loginForm"
        novalidate
        (ngSubmit)="onSubmit()"
      >
        <div class="logo-wrap">
          <i class="icon icon-ddocdoc-bi"></i>
        </div>

        <!-- <label for="inputUserId" class="label">아이디</label> -->
        <div class="input-wrap">
          <input
            #inputUserId
            type="text"
            id="inputUserId"
            class="input-text"
            placeholder="아이디"
            formControlName="userId"
          />
          <div
            data-cy="user-id-error-message"
            class="error-msg"
            *ngIf="errorMsg?.type === 'ID'"
          >
            {{ errorMsg?.msg }}
          </div>
        </div>

        <!-- <label for="inputPassword" class="label">비밀번호</label> -->
        <div class="input-wrap">
          <input
            #inputUserPassword
            type="password"
            autocomplete="false"
            id="inputPassword"
            class="input-text"
            placeholder="비밀번호"
            formControlName="userPassword"
          />
          <div
            data-cy="user-password-error-message"
            class="error-msg"
            *ngIf="errorMsg?.type === 'PWD'"
          >
            {{ errorMsg?.msg }}
          </div>
        </div>

        <button
          data-cy="login-button"
          class="ha-btn ha-btn-login"
          [class.active]="loginForm.invalid === false"
          type="submit"
        >
          로그인
        </button>

        <button class="ha-btn ha-btn-signup" type="button" routerLink="/signup">
          회원가입
        </button>

        <div class="ha-modal-footer">
          <div class="footer-row">
            <label for="chk_saveId" class="text-left label-checkbox">
              <input
                id="chk_saveId"
                type="checkbox"
                [checked]="isSaveId"
                (change)="onChangeSaveId($event)"
              />
              <i class="icon icon-check-on" *ngIf="isSaveId"></i>
              <i class="icon icon-check-none" *ngIf="!isSaveId"></i>
              <span>아이디 저장</span>
            </label>
            <div class="text-right footer-right">
              <a routerLink="/find-id" class="footer-link">아이디 찾기</a>
              <span class="separator">|</span>
              <a routerLink="/find-pw" class="footer-link">비밀번호 찾기</a>
            </div>
          </div>
        </div>
        <!-- <p style="color: #dc3545;" class="mt-3">{{ error }}</p> -->
      </form>
    </div>
  </div>
</div>
