import { createReducer, on } from '@ngrx/store';
import { HospitalReviewModels } from '~models/hospital-review.model';
import { HospitalReviewActions } from '~actions/products/hospital-review.actions';

export interface HospitalReviewState {
  /* 고객의 소리 통계 */
  isStatisticsLoading: boolean;
  statistics: HospitalReviewModels.StatisticsUiModel;

  /* 고객의 소리 의견 리스트 */
  isCommentListLoading: boolean;
  commentList: HospitalReviewModels.CommentListUiModel;

  error: string;
}

const initialState: HospitalReviewState = {
  isStatisticsLoading: false,
  isCommentListLoading: false,
  statistics: {
    goodDetail: {
      satisfaction: 'GOOD',
      count: 0,
      keywords: [],
      percent: 0,
    },
    badDetail: {
      satisfaction: 'BAD',
      count: 0,
      keywords: [],
      percent: 0,
    },
  },
  commentList: {
    items: [],
    totalCount: 0,
    skip: 0,
    limit: 10,
  },
  error: '',
};

export const hospitalReviewReducer = createReducer(
  initialState,
  on(HospitalReviewActions.HospitalStatisticsLoad, state => ({
    ...state,
    isStatisticsLoading: true,
    error: '',
  })),
  on(HospitalReviewActions.HospitalCommentListLoad, state => ({
    ...state,
    isCommentListLoading: true,
    error: '',
  })),
  on(HospitalReviewActions.HospitalStatisticsSuccess, (state, action) => ({
    ...state,
    statistics: action.payload,
    isStatisticsLoading: false,
  })),
  on(HospitalReviewActions.HospitalCommentListSuccess, (state, action) => ({
    ...state,
    commentList: action.payload,
    isCommentListLoading: false,
  })),
  on(
    HospitalReviewActions.HospitalStatisticsFail,
    HospitalReviewActions.HospitalCommentListFail,
    (state, action) => ({
      ...state,
      error: action.message,
      isStatisticsLoading: false,
      isCommentListLoading: false,
    })
  )
);
