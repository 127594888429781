import { createAction, props } from '@ngrx/store';
import {
  PdfArchivesListParams,
  PdfArchivesResItem,
} from '~models/pdf-archives.model';
import { ListDataRes } from '~models/common';

export const PdfArchivesActionTypes = {
  INIT: '[PDF ARCH] INIT',
  LIST_LOAD: '[PDF ARCH] LIST LOAD',
  LIST_SUCCESS: '[PDF ARCH] LIST SUCCESS',
  // LIST_FAIL = '[PDF ARCH] LIST FAIL',
  PENDING: '[PDF ARCH] PENDING',
  DOWNLOAD: '[PDF ARCH] DOWNLOAD',
} as const;

export const PdfArchivesInit = createAction(PdfArchivesActionTypes.INIT);

export const PdfArchivesListLoad = createAction(
  PdfArchivesActionTypes.LIST_LOAD,
  props<{ payload: PdfArchivesListParams }>()
);

export const PdfArchivesListSuccess = createAction(
  PdfArchivesActionTypes.LIST_SUCCESS,
  props<{ payload: ListDataRes<PdfArchivesResItem> }>()
);

export const PdfArchivesDownload = createAction(
  PdfArchivesActionTypes.DOWNLOAD,
  props<{ payload: string }>()
);

export const PdfArchivesPendingStart = createAction(
  PdfArchivesActionTypes.PENDING,
  props<{ payload: true }>()
);

export const PdfArchivesPendingEnd = createAction(
  PdfArchivesActionTypes.PENDING,
  props<{ payload: false }>()
);

export const PdfArchivesActions = {
  PdfArchivesInit,
  PdfArchivesListLoad,
  PdfArchivesListSuccess,
  PdfArchivesDownload,
  PdfArchivesPendingStart,
  PdfArchivesPendingEnd,
};
