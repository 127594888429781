import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'resStateNameList'
})
export class ResStateNameListPipe implements PipeTransform {
  transform(value: number): string {
    const MAP_STATE_NAME = {
      0: '완료',
      1: '자동 취소 / 병원 취소',
      2: '사용자 철회',
      3: '예약 확정',
      4: '내원',
      5: '진료 보류',
      6: '가접수'
    };

    return MAP_STATE_NAME[value];
  }
}
