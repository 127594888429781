import { EmrAccountCreateInfo } from '~models/auth.model';
import {
  AccountInfo,
  AccountUpdateInfo,
  AuthLoginInfo,
  CreateInfo,
  FindUserInfo,
  LoginSuccessInfo,
  ResetPwInfo,
} from '~models/auth.model';
import { Observable } from 'rxjs';
import { Api } from './../http-api';
import { Injectable } from '@angular/core';

// let seq = 0;

@Injectable()
export class AuthService {
  constructor(private api: Api) {}

  /**
   * 계정 로그인
   * @param {AuthLoginInfo} data
   * @memberOf AuthService
   */
  accountLogin(data: AuthLoginInfo): Observable<LoginSuccessInfo> {
    const url = 'api/v1/login';
    return this.api.post(url, data);
  }

  /**
   * 계정 정보 불러오기 : Token
   * @memberOf AuthService
   */
  fetchUserInfo(): Observable<AccountInfo> {
    // if (seq++ > 5) {
    //   throw new Error('아니 뭘 이렇게 자꾸 호출햇!');
    // }
    const url = 'api/v1/account/me';
    return this.api.get(url);
  }

  /**
   * DEPRECATED
   * (구) 계정 생성
   * @param {CreateInfo} data
   * @memberOf AuthService
   */
  // createUser(data: CreateInfo): Observable<any> {
  //   const url = 'api/v1/account';
  //   return this.api.post(url, data);
  // }

  /**
   * 계정생성
   * @param {CreateInfo} data
   * @memberOf AuthService
   */
  createUser(data: CreateInfo): Observable<any> {
    const url = 'api/v2/accounts';
    return this.api.post(url, data);
  }

  /**
   * 계정 ID 찾기
   * @param {FindUserInfo} data
   * @memberOf AuthService
   */
  findId(data: FindUserInfo): Observable<any> {
    const url = 'api/v1/account/findLoginId';
    return this.api.post(url, data);
  }

  /**
   * 계정 비밀번호 찾기
   * @param {FindUserInfo} data
   * @memberOf AuthService
   */
  findPw(data: FindUserInfo): Observable<any> {
    const url = 'api/v1/account/findPassword';
    return this.api.post(url, data);
  }

  /**
   * 패스워드 초기화
   * @param {ResetPwInfo} data
   * @memberOf AuthService
   */
  resetPw(data: ResetPwInfo): Observable<any> {
    const url = 'api/v1/account/resetPassword';
    return this.api.post(url, data);
  }

  /**
   * 아이디 중복 검사
   * @param {string} userId
   * @memberOf AuthService
   */
  existId(userId: string): Observable<any> {
    const url = `api/v1/account/check/id/${userId}`;
    return this.api.get(url);
  }

  /**
   * 이메일 중복 검사
   * @param {string} userEmail
   * @memberOf AuthService
   */
  existEmail(userEmail: string): Observable<any> {
    const url = `api/v1/account/check/email/${userEmail}`;
    return this.api.get(url);
  }

  /**
   * 계정 업데이트
   * @param {AccountUpdateInfo} data
   * @memberOf AuthService
   */
  updateAuthInfo(data: AccountUpdateInfo): Observable<any> {
    const url = 'api/v2/accounts';
    return this.api.put(url, data);
  }

  /**
   * 계정 리스트 불러오기: token
   * @memberOf AuthService
   */
  patchAccountList(): Observable<any> {
    const url = 'api/v1/account/list';
    return this.api.get(url);
  }

  /**
   * 가입된 요양기관번호 확인
   * @param {string} ynum
   * https://boostbros.atlassian.net/wiki/spaces/BACKEND/pages/1093501009/-
   */
  checkYnum(ynum: string): Observable<any> {
    const url = 'api/v2/accounts/check/ynum';
    return this.api.get(url, { ynum });
  }

  /**
   * 전화번호 인증
   * https://boostbros.atlassian.net/wiki/spaces/BACKEND/pages/1080459334/-
   */
  phoneCertificationCheck(userPhone: string): Observable<any> {
    const url = 'api/v2/accounts/phone-certification';
    return this.api.post(url, { userPhone });
  }

  /**
   * 전화번호 인증 체크
   * https://boostbros.atlassian.net/wiki/spaces/BACKEND/pages/1080328272/-
   */
  phoneCertificationConfirm(
    userPhone: string,
    certNum: string
  ): Observable<{ token: string }> {
    const url = 'api/v2/accounts/phone-certification/check';
    return this.api.post(url, { userPhone, certNum });
  }

  /**
   * 기회원 동의
   */
  createAccountFromEmr(createInfo: EmrAccountCreateInfo) {
    const url = 'api/v2/accounts/_emr';
    return this.api.post(url, { ...createInfo });
  }

  /**
   * 병원어드민 회원용 추가동의여부 수정
   */
  updateAgreements({ thirdPartyAgreement }: { thirdPartyAgreement: boolean }) {
    const url = 'api/v2/accounts/me/agreements';
    return this.api.put(url, { thirdPartyAgreement });
  }

  /**
   * 기존 비밀번호 일치 불일치 확인
   */
  loadExistUserPassword(password: string): Observable<{ isCorrect: boolean }> {
    const url = 'api/v2/accounts/password/check';
    return this.api.post(url, { password });
  }
}
