import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { HashMap } from '~models/common.model';
import { CareRoomActions } from '~actions/products/care-room.actions';
import * as fromProduct from '~reducers/products';
import {
  QuestionCheckUseSymptomReq,
  QuestionCheckUseSymptomRes,
} from '~models/question.model';
import { Api } from '~core/http-api';

@Injectable({
  providedIn: 'root',
})
export class CareRoomNameService {
  keyToTitle: HashMap<string> = null;
  titleToKey: HashMap<string> = null;
  subjectMap: HashMap<string> = null;

  private sbjKeyToTitle = new Subject<HashMap<string>>();
  private sbjTitleToKey = new Subject<HashMap<string>>();
  private sbjSubjectMap = new Subject<HashMap<string>>();

  constructor(
    private store: Store<fromProduct.ProductState>,
    private api: Api
  ) {
    this.store
      .pipe(
        select(fromProduct.getAllEntityCareRoom),
        map(list => {
          if (!list || list.length === 0) {
            this.store.dispatch(CareRoomActions.FetchCareRoom());
          }

          return list;
        }),
        filter(list => list && list.length > 0)
      )
      .subscribe(list => {
        const mKeyToTitle = {};
        const mTitleToKey = {};
        const mSubjectMap = {};

        list.forEach(room => {
          mKeyToTitle[room.key] = room.title;
          mTitleToKey[room.title] = room.key;
          mSubjectMap[room.key] = room.subject;
        });

        this.keyToTitle = mKeyToTitle;
        this.titleToKey = mTitleToKey;
        this.subjectMap = mSubjectMap;

        this.sbjKeyToTitle.next(mKeyToTitle);
        this.sbjTitleToKey.next(mTitleToKey);
        this.sbjSubjectMap.next(mSubjectMap);
      });
  }

  /**
   * 실제 병원에서 쓰이는 진료실명을 가져온다.
   * @param key 가져올 때 쓰이는 진료실키 (unitKey)
   */
  getTitle(key: string) {
    if (this.keyToTitle) {
      return of(this.keyToTitle[key]);
    }
    return this.sbjKeyToTitle.pipe(map(data => data[key]));
  }

  /**
   * 똑닥에서 쓰이는 진료실키를 진료실명을 통하여 가져온다.
   * @param title 병원에서 쓰이는 진료실명
   */
  getKey(title: string) {
    if (this.titleToKey) {
      return of(this.titleToKey[title]);
    }
    return this.sbjTitleToKey.pipe(map(data => data[title]));
  }

  /**
   * 현재 진료실의 진료과목명을 가져온다.
   * @param key 가져올 때 쓰이는 진료실키 (unitKey)
   */
  getSubject(key: string) {
    if (this.subjectMap) {
      return of(this.subjectMap[key]);
    }
    return this.sbjSubjectMap.pipe(map(data => data[key]));
  }

  /**
   * 진료실 별 진료항목 사용여부 (접수/예약 진료항목 설정에서 유효성 체크용으로 쓰임)
   */
  fetchCheckUseSymptom(
    params: QuestionCheckUseSymptomReq
  ): Observable<QuestionCheckUseSymptomRes> {
    return this.api.get(
      `api/v1/reception-symptom/type/${params.type}/unitKey/${params.unitKey}/check-use-symptom`,
      {
        symptomCategoryId: params.symptomCategoryId,
      }
    );
  }
}
