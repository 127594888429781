<div ngbDropdown class="select-filter">
  <button type="button" class="form-control dropdown-button" ngbDropdownToggle>
    {{ selectedText }}
  </button>
  <div ngbDropdownMenu>
    <button
      type="button"
      class="dropdown-item"
      *ngFor="let option of selectedButtonOptions"
      (click)="handleChange(option)"
    >
      {{ option.text }}
    </button>
  </div>
</div>
