import { Observable } from 'rxjs';
import { AccordionTableContent } from '~shared/components/accordion-table/accordion-table.component';

export const FAQ_CATEGORY = [
  '병원어드민',
  '접수서비스',
  '예약서비스',
  '영유아 검진',
  '대기현황판',
  '똑닥이란',
  '똑닥 App',
];

export type MainCategoryStr = '공지사항' | 'FAQ';

export type FaqCategoryStr =
  | '대기현황판'
  | '똑닥 App'
  | '똑닥이란'
  | '병원어드민'
  | '영유아 검진'
  | '예약서비스'
  | '접수서비스';

export namespace FAQS {
  export const ABOUT_DD = '똑닥이란';
  export const DD_APP = '똑닥 App';
  export const RESERVATION_SERVICE = '예약서비스';
  export const RECEPTION_SERVICE = '접수서비스';
  export const WAITING_BOARD = '대기현황판';
  export const HOSPITAL_ADMIN = '병원어드민';
  export const CHILD_SCREENING = '영유아 검진';

  export const CATEGORIES: FaqCategoryStr[] = [
    ABOUT_DD,
    DD_APP,
    RESERVATION_SERVICE,
    RECEPTION_SERVICE,
    WAITING_BOARD,
    HOSPITAL_ADMIN,
    CHILD_SCREENING,
  ];
}

export interface FaqAccordionTableContent {
  title: FaqCategoryStr;
  faqsObs: Observable<AccordionTableContent[]>;
}

export interface Faq {
  _id: string | number;
  category: Category;
  title: string;
  content: string;
  emrTypes?: string[];
  isFixed: boolean;
  topNoti?: boolean;
  createdAt: string;
}

export interface FaqReqParams {
  skip: number;
  limit: number;
  category: string;
}

export interface Category {
  id: string;
  name: string;
  display?: boolean;
  order: number;
}

export interface BannerFaq {
  title: string;
  content: string;
  categoryName?: string;
}

export interface BannerFaqCategory {
  name: string;
  id: string;
}

export interface BannerFaqJson {
  categories: BannerFaqCategory[];
  faqs: { [key: string]: BannerFaq[] };
}

export interface FaqQueryParams {
  category?: string;
  limit?: number;
  skip?: number;
  isFixed?: boolean;
  keywords?: FaqQueryKeyword;
}

export interface FaqQueryKeyword {
  content?: string;
  title?: string;
}

export const NOTICE_LIMIT = 11;
export const FAQ_LIMIT = 10;
export const ALL_KEYWORD_TYPE = 'all';

export const TABS = {
  notice: {
    id: 'notice',
    title: '공지사항',
    isShowTableHeader: true,
  },
  faq: {
    id: 'faq',
    title: '자주 묻는 질문',
    isShowTableHeader: false,
  },
};

export const KEYWORD_OPTIONS = [
  {
    value: 'title',
    label: '제목',
  },
  {
    value: 'content',
    label: '내용',
  },
  {
    value: ALL_KEYWORD_TYPE,
    label: '제목+내용',
  },
];

export const INITIAL_SEARCH_PARAMS = {
  searchType: KEYWORD_OPTIONS[0].value,
  keyword: '',
};

export const TOTAL_CATEGORY = {
  id: 'TOTAL',
  name: '전체',
  display: true,
  order: -1,
};
