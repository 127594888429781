import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nl2br'
})
export class Nl2br implements PipeTransform {
  transform(value: string) {
    return value ? value.replace(/\n/g, '<br/>') : value;
  }
}
