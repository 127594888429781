import { createAction, props } from '@ngrx/store';
import { ShortUrlReqModel, ShortUrlResModel } from '~models/short-url.model';

export const ShortUrlActionTypes = {
  SHORT_URL_CREATE: '[SHORT_URL] ShortUrl Create',
  SHORT_URL_CREATE_SUCCESS: '[SHORT_URL] ShortUrl Create Success',
  SHORT_URL_CREATE_FAIL: '[SHORT_URL] ShortUrl Create Fail',
} as const;

export const ShortUrlCreate = createAction(
  ShortUrlActionTypes.SHORT_URL_CREATE,
  props<{ payload: ShortUrlReqModel }>()
);

export const ShortUrlCreateSuccess = createAction(
  ShortUrlActionTypes.SHORT_URL_CREATE_SUCCESS,
  props<{ payload: ShortUrlResModel }>()
);

export const ShortUrlCreateFail = createAction(
  ShortUrlActionTypes.SHORT_URL_CREATE_FAIL,
  props<{ payload: any }>()
);

export const ShortUrlActions = {
  ShortUrlCreate,
  ShortUrlCreateSuccess,
  ShortUrlCreateFail,
};
