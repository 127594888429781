import { TreatmentInfoUiModel } from '~models/treatment-room.model';
import { TreatmentInfoActions } from '~actions/products/treatment-info.actions';
import { createReducer, on } from '@ngrx/store';

export interface TreatmentInfoState {
  treatmentRooms: TreatmentInfoUiModel[];
}

const initialState: TreatmentInfoState = {
  treatmentRooms: [],
};

export const reducer = createReducer(
  initialState,
  on(
    TreatmentInfoActions.TreatmentListUpdateSuccess,
    TreatmentInfoActions.TreatmentListLoadSuccess,
    (state, action) => ({ ...state, treatmentRooms: action.payload })
  ),
  on(TreatmentInfoActions.TreatmentChange, (state, action) => {
    const updateTreatmentRooms = [...state.treatmentRooms];
    updateTreatmentRooms[action.index] = action.payload;
    return { ...state, treatmentRooms: updateTreatmentRooms };
  })
);
