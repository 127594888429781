import {
  HospitalNotice,
  HospitalNoticeList,
  HospitalNoticeSearchParams,
} from '~models/hospital.model';
import { createAction, props } from '@ngrx/store';

export enum HospitalNoticeActionTypes {
  HOSPITAL_NOTICE_LOAD = '[HospitalNotice] Hospital Notice Load',
  HOSPITAL_NOTICE_LOAD_SUCCESS = '[HospitalNotice] Hospital Notice Load success',
  HOSPITAL_NOTICE_LOAD_FAIL = '[HospitalNotice] Hospital Notice Load fail',

  HOSPITAL_NOTICE_PARAMS_RESET = '[HospitalNotice] Hospital Notice Search Params Reset',

  HOSPITAL_NOTICE_DETAIL_LOAD = '[HospitalNotice] Hospital Notice detail load',
  HOSPITAL_NOTICE_DETAIL_LOAD_SUCCESS = '[HospitalNotice] Hospital Notice detail load success',
  HOSPITAL_NOTICE_DETAIL_LOAD_FAIL = '[HospitalNotice] Hospital Notice detail load fail',
  HOSPITAL_NOTICE_DETAIL_RESET = '[HospitalNotice] Hospital Notice detail reset',

  HOSPITAL_NOTICE_CREATE = '[HospitalNotice] Hospital Notice create',
  HOSPITAL_NOTICE_CREATE_SUCCESS = '[HospitalNotice] Hospital Notice create success',
  HOSPITAL_NOTICE_CREATE_FAIL = '[HospitalNotice] Hospital Notice create fail',

  HOSPITAL_NOTICE_UPDATE = '[HospitalNotice] Hospital Notice update',
  HOSPITAL_NOTICE_UPDATE_SUCCESS = '[HospitalNotice] Hospital Notice update success',
  HOSPITAL_NOTICE_UPDATE_FAIL = '[HospitalNotice] Hospital Notice update fail',

  HOSPITAL_NOTICE_DELETE = '[HospitalNotice] Hospital Notice delete',
  HOSPITAL_NOTICE_DELETE_SUCCESS = '[HospitalNotice] Hospital Notice delete success',
  HOSPITAL_NOTICE_DELETE_FAIL = '[HospitalNotice] Hospital Notice delete fail',

  HOSPITAL_NOTICE_CLEAR = '[HospitalNotice] Hospital Notice clear',
}

// 병원 공지사항 생성
export const HospitalNoticeCreate = createAction(
  HospitalNoticeActionTypes.HOSPITAL_NOTICE_CREATE,
  props<{ payload: HospitalNotice }>()
);

export const HospitalNoticeCreateSuccess = createAction(
  HospitalNoticeActionTypes.HOSPITAL_NOTICE_CREATE_SUCCESS,
  props<{ payload: HospitalNotice }>()
);

export const HospitalNoticeCreateFail = createAction(
  HospitalNoticeActionTypes.HOSPITAL_NOTICE_CREATE_FAIL,
  props<{ payload: any }>()
);

// 병원 공지사항 수정
export const HospitalNoticeUpdate = createAction(
  HospitalNoticeActionTypes.HOSPITAL_NOTICE_UPDATE,
  props<{ payload: HospitalNotice }>()
);

export const HospitalNoticeUpdateSuccess = createAction(
  HospitalNoticeActionTypes.HOSPITAL_NOTICE_UPDATE_SUCCESS
);

export const HospitalNoticeUpdateFail = createAction(
  HospitalNoticeActionTypes.HOSPITAL_NOTICE_UPDATE_FAIL,
  props<{ payload: any }>()
);

// 병원 공지사항 리스트 조회
export const HospitalNoticeLoad = createAction(
  HospitalNoticeActionTypes.HOSPITAL_NOTICE_LOAD,
  props<{ payload: HospitalNoticeSearchParams }>()
);

export const HospitalNoticeLoadSuccess = createAction(
  HospitalNoticeActionTypes.HOSPITAL_NOTICE_LOAD_SUCCESS,
  props<{ payload: HospitalNoticeList }>()
);

export const HospitalNoticeLoadFail = createAction(
  HospitalNoticeActionTypes.HOSPITAL_NOTICE_LOAD_FAIL,
  props<{ payload: any }>()
);

// 병원 공지사항 상세 불러오기
export const HospitalNoticeDetailLoad = createAction(
  HospitalNoticeActionTypes.HOSPITAL_NOTICE_DETAIL_LOAD,
  props<{ payload: { hospitalId: string; noticeId: string } }>()
);

export const HospitalNoticeDetailLoadSuccess = createAction(
  HospitalNoticeActionTypes.HOSPITAL_NOTICE_DETAIL_LOAD_SUCCESS,
  props<{ payload: HospitalNotice }>()
);

export const HospitalNoticeDetailLoadFail = createAction(
  HospitalNoticeActionTypes.HOSPITAL_NOTICE_DETAIL_LOAD_FAIL,
  props<{ payload: any }>()
);

export const HospitalNoticeDetailReset = createAction(
  HospitalNoticeActionTypes.HOSPITAL_NOTICE_DETAIL_RESET
);

// 병원 공지사항 삭제
export const HospitalNoticeDelete = createAction(
  HospitalNoticeActionTypes.HOSPITAL_NOTICE_DELETE,
  props<{ payload: { hospitalNotice: HospitalNotice; searchParams: any } }>()
);

export const HospitalNoticeDeleteSuccess = createAction(
  HospitalNoticeActionTypes.HOSPITAL_NOTICE_DELETE_SUCCESS
);

export const HospitalNoticeDeleteFail = createAction(
  HospitalNoticeActionTypes.HOSPITAL_NOTICE_DELETE_FAIL,
  props<{ payload: any }>()
);

// 병원 공지사항 검색 리셋
export const HospitalNoticeSearchParamsReset = createAction(
  HospitalNoticeActionTypes.HOSPITAL_NOTICE_PARAMS_RESET,
  props<{ payload: HospitalNoticeSearchParams }>()
);

export const HospitalNoticeClear = createAction(
  HospitalNoticeActionTypes.HOSPITAL_NOTICE_CLEAR
);

export const HospitalNoticeActions = {
  HospitalNoticeLoad,
  HospitalNoticeLoadSuccess,
  HospitalNoticeLoadFail,
  HospitalNoticeDetailLoad,
  HospitalNoticeDetailLoadSuccess,
  HospitalNoticeDetailLoadFail,
  HospitalNoticeDetailReset,
  HospitalNoticeCreate,
  HospitalNoticeCreateSuccess,
  HospitalNoticeCreateFail,
  HospitalNoticeUpdate,
  HospitalNoticeUpdateSuccess,
  HospitalNoticeUpdateFail,
  HospitalNoticeDelete,
  HospitalNoticeDeleteSuccess,
  HospitalNoticeDeleteFail,
  HospitalNoticeSearchParamsReset,
  HospitalNoticeClear,
};
