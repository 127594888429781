import { createAction, props } from '@ngrx/store';
import {
  PushTargetUser,
  PushTargetUserReqParams,
  UpdateExcludePushUserReqParams,
} from '~models/hospital-crm.model';

export enum CrmPushTargetUserActionType {
  REQ_FAIL = '[CrmPushTargetUser] Req Fail',
  DEFAULT_LOAD = '[CrmPushTargetUser] Default Push Target User Load',
  DEFAULT_LOAD_SUCCESS = '[CrmPushTargetUser] Default Push Target User Load Success',
  UPDATE = '[CrmPushTargetUser] Update Target User',
  UPDATE_SUCCESS = '[CrmPushTargetUser] Update Target User Success',
}

export const CrmPushTargetUserReqFail = createAction(
  CrmPushTargetUserActionType.REQ_FAIL,
  props<any>()
);

export const CrmPushTargetUserLoad = createAction(
  CrmPushTargetUserActionType.DEFAULT_LOAD,
  props<{ payload: PushTargetUserReqParams }>()
);

export const CrmPushTargetUserLoadSuccess = createAction(
  CrmPushTargetUserActionType.DEFAULT_LOAD_SUCCESS,
  props<{ payload: PushTargetUser }>()
);

export const CrmPushTargetUserUpdate = createAction(
  CrmPushTargetUserActionType.UPDATE,
  props<{
    payload: {
      user: UpdateExcludePushUserReqParams;
      searchParams?: PushTargetUserReqParams;
    };
  }>()
);

export const CrmPushTargetUserUpdateSuccess = createAction(
  CrmPushTargetUserActionType.UPDATE_SUCCESS,
  props<{ payload: PushTargetUserReqParams }>()
);

export const CrmPushTargetUserActions = {
  CrmPushTargetUserReqFail,
  CrmPushTargetUserLoad,
  CrmPushTargetUserLoadSuccess,
  CrmPushTargetUserUpdate,
  CrmPushTargetUserUpdateSuccess,
};
