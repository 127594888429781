import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tab-list',
  templateUrl: './tab-list.component.html',
  styleUrls: ['./tab-list.component.scss', './theme/index.scss'],
})
export class TabListComponent implements OnInit {
  @Input() theme: string;
  @Input() items: string[];
  @Input() autoChange = true;

  private _tab = 0;
  @Input() set tab(val: number) {
    this._tab = val;
  }

  get tab(): number {
    return this._tab;
  }

  @Output() tabSelect = new EventEmitter<number>();

  ngOnInit(): void {}

  onTabSelect(index: number) {
    if (this.autoChange) {
      this._tab = index;
    }
    this.tabSelect.emit(index);
  }
}
