import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs/';
import { errorMessage } from './util';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';

const DEFAULT_TITLE = '알림';
const DEFAULT_OK_TEXT = '확인';
const DEFAULT_CANCEL_TEXT = '취소';

export interface SimpleModalModel {
  mode?: string;
  title?: string;
  desc?: string;
  elseWhere?: RetreatElseWhere;
  okText?: string;
  cancelText?: string;
  open: boolean;
  isOverlayPassTrue?: boolean;
}

export interface RetreatElseWhere {
  text: string;
  route: string;
}

function getInitData(): SimpleModalModel {
  return {
    mode: 'alert',
    title: DEFAULT_TITLE,
    desc: '',
    okText: DEFAULT_OK_TEXT,
    cancelText: DEFAULT_CANCEL_TEXT,
    open: false,
    isOverlayPassTrue: true,
  };
}

@Injectable({
  providedIn: 'root',
})
export class SimpleModalService {
  private _modal = new BehaviorSubject<SimpleModalModel>(getInitData());
  private _result = new Subject<boolean>();

  constructor(private location: Location, private router: Router) {}

  get modal() {
    return this._modal.asObservable();
  }

  get result() {
    return this._result.asObservable();
  }

  open(
    mode,
    desc,
    title,
    elseWhere?: RetreatElseWhere,
    okText = DEFAULT_OK_TEXT,
    cancelText = DEFAULT_CANCEL_TEXT,
    isOverlayPassTrue = true
  ): Observable<boolean> {
    const args = {
      mode,
      desc,
      title,
      okText,
      cancelText,
      open: true,
      elseWhere,
      isOverlayPassTrue,
    } as SimpleModalModel;

    this._modal.next(args);

    // return new Promise<boolean>(resolve => {
    //   const sub$ = this._result.subscribe(val => {
    //     resolve(val);
    //     sub$.unsubscribe();
    //   });
    // });

    // return new Promise<boolean>(resolve => {
    //   const sub$ = this._result.subscribe(val => {
    //     // resolve(val);
    //     sub$.unsubscribe();
    //   });
    // });

    // const sub$ = this._result.subscribe(val => {
    //   resolve(val);
    //   sub$.unsubscribe();
    // });
    return this._result.pipe(take(1));
  }

  close(val: boolean, elseWhere?: RetreatElseWhere) {
    this._result.next(val);
    this._modal.next(getInitData());

    if (elseWhere) {
      if (val) {
        this.router.navigate([elseWhere.route]);
      } else {
        this.location.back();
      }
      return;
    }
  }

  alert(
    desc: string,
    title: string = DEFAULT_TITLE,
    isOverlayPassTrue = true
  ): Observable<boolean> {
    return this.open(
      'alert',
      desc,
      title,
      undefined,
      undefined,
      undefined,
      isOverlayPassTrue
    );
  }

  confirm(
    desc: string,
    title = DEFAULT_TITLE,
    okText = DEFAULT_OK_TEXT,
    cancelText = DEFAULT_CANCEL_TEXT,
    isOverlayPassTrue = false
  ): Observable<boolean> {
    return this.open(
      'confirm',
      desc,
      title,
      undefined,
      okText,
      cancelText,
      isOverlayPassTrue
    );
  }

  retreat(
    desc: string,
    title: string = DEFAULT_TITLE,
    elseWhere?: RetreatElseWhere,
    isOverlayPassTrue = false
  ): Observable<boolean> {
    return this.open(
      'retreat',
      desc,
      title,
      elseWhere,
      undefined,
      undefined,
      isOverlayPassTrue
    );
  }

  error(error: any): Observable<boolean> {
    return this.alert(
      errorMessage(error, '알 수 없는 오류가 발생 하였습니다.')
    );
  }
}
