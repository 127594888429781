import { createAction, props } from '@ngrx/store';
import {
  TelemedicineBulkUpdateReq,
  TelemedicineRequiredSettingFetchRes,
  TelemedicineRoomSetting,
  TelemedicineRoomsSettingsFetchRes,
  TelemedicineUseReq,
} from '~models/telemedicine.model';

export const enum TelemedicineActionTypes {
  REQUEST_FAIL = '[Telemedicine] REQUEST FAIL',
  FETCH_REQUIRED_SETTINGS = '[Telemedicine] FETCH_REQUIRED_SETTINGS',
  FETCH_REQUIRED_SETTINGS_SUCCESS = '[Telemedicine] FETCH_REQUIRED_SETTINGS_SUCCESS',
  UPDATE_USE = '[Telemedicine] UPDATE_USE',
  UPDATE_USE_SUCCESS = '[Telemedicine] UPDATE_USE_SUCCESS',
  UPDATE_BULK_ROOM_SETTINGS = '[Telemedicine] UPDATE_BULK_ROOM_SETTINGS',
  UPDATE_BULK_ROOM_SETTINGS_SUCCESS = '[Telemedicine] UPDATE_BULK_ROOM_SETTINGS_SUCCESS',
  FETCH_ROOMS_SETTINGS = '[Telemedicine] FETCH_ROOMS_SETTINGS',
  FETCH_ROOMS_SETTINGS_SUCCESS = '[Telemedicine] FETCH_ROOMS_SETTINGS_SUCCESS',
}

export const TelemedicineRequestFail = createAction(
  TelemedicineActionTypes.REQUEST_FAIL,
  props<{ error: any }>()
);
export const TelemedicineRequiredSettingFetch = createAction(
  TelemedicineActionTypes.FETCH_REQUIRED_SETTINGS
);

export const TelemedicineRequiredSettingSuccess = createAction(
  TelemedicineActionTypes.FETCH_REQUIRED_SETTINGS_SUCCESS,
  props<{ res: Partial<TelemedicineRequiredSettingFetchRes> }>()
);

export const TelemedicineUpdateUse = createAction(
  TelemedicineActionTypes.UPDATE_USE,
  props<{ req: TelemedicineUseReq }>()
);

export const TelemedicineUpdateUseSuccess = createAction(
  TelemedicineActionTypes.UPDATE_USE_SUCCESS,
  props<{ useTelemedicine: boolean }>()
);

export const TelemedicineUpdateBulkRoomSettings = createAction(
  TelemedicineActionTypes.UPDATE_BULK_ROOM_SETTINGS,
  props<{ req: TelemedicineBulkUpdateReq }>()
);

export const TelemedicineUpdateBulkRoomSettingsSuccess = createAction(
  TelemedicineActionTypes.UPDATE_BULK_ROOM_SETTINGS_SUCCESS,
  props<{
    updates: {
      id: string;
      changes: TelemedicineRoomSetting;
    }[];
  }>()
);

export const TelemedicineRoomsSettingsFetch = createAction(
  TelemedicineActionTypes.FETCH_ROOMS_SETTINGS
);

export const TelemedicineRoomsSettingsFetchSuccess = createAction(
  TelemedicineActionTypes.FETCH_ROOMS_SETTINGS_SUCCESS,
  props<{ res: TelemedicineRoomsSettingsFetchRes }>()
);

export const TelemedicineActions = {
  TelemedicineRequestFail,
  TelemedicineRequiredSetting: TelemedicineRequiredSettingFetch,
  TelemedicineRequiredSettingSuccess,
  TelemedicineUpdateUse,
  TelemedicineUpdateUseSuccess,
  TelemedicineUpdateBulkRoomSettings,
  TelemedicineUpdateBulkRoomSettingsSuccess,
  TelemedicineRoomsSettingsFetch,
  TelemedicineRoomsSettingsFetchSuccess,
};
