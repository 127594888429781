<div class="page-bg"></div>
<div class="wrap">
  <a class="find-header mb-5" routerLink="/">
    <img
      class="mb-4"
      src="/assets/img/find-id-password.png"
      width="100"
      height="37"
    />
    <hr />
  </a>
  <div class="title-section"><span>비밀번호 재설정</span></div>
  <form [formGroup]="findPwForm" (submit)="onSubmit($event)">
    <span class="form-title">가입 시 입력한 기본 정보를 입력해주세요.</span>
    <div class="form-group">
      <input
        type="text"
        autocomplete="off"
        formControlName="name"
        class="form-control-input"
        id="inputUserName"
        placeholder="계정 담당자 이름"
      />
    </div>
    <div class="form-group" style="margin-bottom: 53px;">
      <input
        type="email"
        autocomplete="off"
        formControlName="email"
        class="form-control-input"
        id="inputEmail"
        placeholder="수신 가능한 이메일"
      />
    </div>
    <div class="form-group">
      <div class="form-title">병원 정보 (ex, 연세정형외과)</div>
      <app-hospital-select
        (hospitalInfo)="getHospitalInfo($event)"
      ></app-hospital-select>
    </div>
    <div class="form-group mb-5">
      <input
        type="password"
        autocomplete="new-password"
        formControlName="ynum"
        class="form-control-input"
        id="inputYoNum"
        placeholder="요양기관번호 (숫자, 8자)"
      />
    </div>
    <button
      [ngClass]="{ 'btn-disabled': findPwForm.invalid }"
      type="submit"
      [disabled]="findPwForm.invalid"
      class="btn btn-lg submit-btn"
    >
      다음
    </button>
    <p style="margin-top: 16px; color: red;">{{ error }}</p>
  </form>
  <!-- <div class="guide-header mb-5">
    <img
      class="mb-4"
      src="/assets/img/bi_512.png"
      alt=""
      width="72"
      height="72"
      style="border-radius:10px; box-shadow: 2px 3px 15px 0px rgba(204,204,204,0.3);"
    />
    <h3>비밀번호를 잊으셨나요?</h3>
    <p class="text-muted">아래 가입정보를 입력하시고<br />비밀번호 초기화 버튼을 선택해주세요.</p>
  </div> -->
  <!-- <form [formGroup]="findPwForm" novalidate (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="inputUserId">담당자 이름</label>
      <input
        type="text"
        autocomplete="name"
        formControlName="name"
        class="form-control"
        id="inputUserId"
        placeholder="담당자 이름을 입력하세요."
      />
    </div>
    <div class="form-group">
      <label for="inputEmail">이메일</label>
      <input
        type="email"
        autocomplete="email"
        formControlName="email"
        class="form-control"
        id="inputEmail"
        placeholder="이메일을 입력하세요."
      />
      <small class="form-text text-muted">계정 생성시 입력한 이메일을 입력해주세요.</small>
    </div>
    <div class="form-group">
      <app-hospital-select (hospitalInfo)="getHospitalInfo($event)"></app-hospital-select>
    </div>
    <div class="form-group mb-5">
      <label for="inputYoNum">요양기관번호</label>
      <input
        type="text"
        formControlName="ynum"
        class="form-control"
        id="inputYoNum"
        placeholder="요양기관번호를 입력하세요."
      />
      <small class="form-text text-muted">병원의 요양기관 번호를 입력하세요.</small>
    </div>
    <button type="submit" [disabled]="findPwForm.invalid" class="btn btn-lg btn-primary">비밀번호 초기화</button>
    <p style="margin-top: 16px; color: red;">{{ error }}</p>
  </form> -->
</div>
