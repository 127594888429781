<div class="ham-overlay" [ngClass]="{ 'ham-show': isOpen() }" (click)="clickOverlay($event)">
  <div class="ham-inner-t">
    <div class="ham-inner-c">
      <div class="ham-section" (click)="$event.stopPropagation()">
        <button *ngIf="showClose" type="button" class="btn-close" (click)="close($event, false)">
          <img src="/assets/img/btn-delete.png" />
        </button>
        <pre class="ham-body" [innerHTML]="desc"></pre>
        <footer class="ham-footer" *ngIf="!isRetreatMode()">
          <button *ngIf="isConfirmMode()" type="button" class="ha-btn btn-second" (click)="close($event, false)">
            {{ negativeText }}
          </button>
          <button
            *ngIf="isConfirmMode()"
            id="btnCommonAccept"
            type="button"
            class="ha-btn btn-color-special"
            (click)="close($event, true)"
          >
            {{ positiveText }}
          </button>
          <button
            *ngIf="!isConfirmMode()"
            id="btnCommonAccept"
            type="button"
            class="ha-btn btn-color-special"
            (click)="close($event, true)"
          >
            {{ positiveText }}
          </button>
        </footer>
        <footer class="ham-footer" *ngIf="isRetreatMode()">
          <button id="btnCommonRetreat" type="button" class="ha-btn ha-btn-secondary" (click)="close($event, false)">
            되돌아가기
          </button>
          <button id="btnCommonElseWhere" type="button" class="ha-btn ha-btn-primary" (click)="close($event, true)">
            {{ elseWhereText }}
          </button>
        </footer>
      </div>
    </div>
  </div>
</div>
