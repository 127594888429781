import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StorageService, TypeStorage } from '~shared/service/storage.service';
import { includes as _includes } from 'lodash';

const USER_ID_KEY = 'ha_userId';
type ErrorType = 'ID' | 'PWD';

@Component({
  selector: 'app-signin-modal',
  templateUrl: './signin-modal.component.html',
  styleUrls: ['./signin-modal.component.scss'],
})
export class SigninModalComponent implements OnInit {
  @Input() loading = false;

  errorMsg?: { type: ErrorType; msg: string | null };

  @Input() set error(error: string | null) {
    this.errorMsg = {
      type: _includes(error, '아이디') ? 'ID' : 'PWD',
      msg: error,
    };
  }

  @Output() submitted = new EventEmitter();

  loginForm: FormGroup = this.fb.group({
    userId: ['', Validators.required],
    userPassword: ['', Validators.required],
  });

  isShow = false;

  storage: TypeStorage<string>;
  userId = '';
  isSaveId = false;

  @ViewChild('inputUserId') inputUserId?: ElementRef;
  @ViewChild('inputUserPassword') inputUserPassword?: ElementRef;

  constructor(private fb: FormBuilder, private storageService: StorageService) {
    this.storage = this.storageService.local<string>(USER_ID_KEY);
  }

  ngOnInit(): void {
    // this.init();
  }

  init(): boolean {
    const userId = this.storage.get();
    let bRet = false;

    if (userId) {
      this.isSaveId = true;
      this.userId = userId;
      bRet = true;
    } else {
      this.isSaveId = false;
      this.userId = '';
    }

    this.loginForm.setValue({
      userId: this.userId,
      userPassword: '',
    });

    return bRet;
  }

  close(): void {
    this.isShow = false;
  }

  open(): void {
    const hasUserId = this.init();
    this.isShow = true;

    setTimeout(() => {
      let elem;

      if (hasUserId) {
        elem = this.inputUserPassword?.nativeElement;
      } else {
        elem = this.inputUserId?.nativeElement;
      }

      elem.focus();
    }, 100);
  }

  // onOverlayClick(event) {
  //   event.stopPropagation();
  //   event.preventDefault();

  //   this.close();
  // }

  onSubmit(): void {
    const sendValue = this.loginForm.value;

    this.saveId(sendValue.userId);
    this.submitted.emit(sendValue);
  }

  onChangeSaveId(event: any): void {
    this.isSaveId = event.target.checked;
    this.saveId(this.loginForm.value.userId);
  }

  saveId(userId: string): void {
    if (this.isSaveId) {
      this.userId = userId;
      this.storage.set(userId);
    } else {
      this.userId = '';
      this.storage.remove();
    }
  }
}
