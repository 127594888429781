import { createAction, props } from '@ngrx/store';
import { HealthScreeningQuestionnaireModels } from '~models/health-screening-questionnaire.model';

export const enum HealthScreeningQuestionnaireActionType {
  HEALTH_SCREENING_QUESTIONNAIRE_REQUEST_FAIL = `[Health Screening Questionnaire] Request Fail`,

  FETCH_HEALTH_SCREENING_QUESTIONNAIRE_TARGETS = '[Health Screening Questionnaire] Fetch Targets',
  FETCH_HEALTH_SCREENING_QUESTIONNAIRE_TARGETS_SUCCESS = '[Health Screening Questionnaire] Fetch Targets Success',

  UPDATE_HEALTH_SCREENING_QUESTIONNAIRE_TARGETS_ORDER = '[Health Screening Questionnaire] Update Order',
  UPDATE_HEALTH_SCREENING_QUESTIONNAIRE_TARGETS_ORDER_SUCCESS = '[Health Screening Questionnaire] Update Order Success',

  CREATE_HEALTH_SCREENING_QUESTIONNAIRE_TARGET = '[Health Screening Questionnaire] Create Target',
  CREATE_HEALTH_SCREENING_QUESTIONNAIRE_TARGET_SUCCESS = '[Health Screening Questionnaire] Create Target Success',

  UPDATE_HEALTH_SCREENING_QUESTIONNAIRE_TARGET = '[Health Screening Questionnaire] Update Target',
  UPDATE_HEALTH_SCREENING_QUESTIONNAIRE_TARGET_SUCCESS = '[Health Screening Questionnaire] Update Target Success',

  FETCH_HEALTH_SCREENING_QUESTIONNAIRE_TARGET_DETAIL = '[Health Screening Questionnaire] Fetch Target Detail',
  FETCH_HEALTH_SCREENING_QUESTIONNAIRE_TARGET_DETAIL_SUCCESS = '[Health Screening Questionnaire] Fetch Target Detail Success',

  UPDATE_HEALTH_SCREENING_QUESTIONNAIRE_QUESTIONS = '[Health Screening Questionnaire] Update Questions',
  UPDATE_HEALTH_SCREENING_QUESTIONNAIRE_QUESTIONS_SUCCESS = '[Health Screening Questionnaire] Update Questions Success',

  DELETE_HEALTH_SCREENING_QUESTIONNAIRE_TARGET = '[Health Screening Questionnaire] Delete Target',
  DELETE_HEALTH_SCREENING_QUESTIONNAIRE_TARGET_SUCCESS = '[Health Screening Questionnaire] Delete Target Success',

  UPDATE_HEALTH_SCREENING_QUESTIONNAIRE_USE = '[Health Screening Questionnaire] Update Use',
  UPDATE_HEALTH_SCREENING_QUESTIONNAIRE_USE_SUCCESS = '[Health Screening Questionnaire] Update Use Success',
}

const RequestFail = createAction(
  HealthScreeningQuestionnaireActionType.HEALTH_SCREENING_QUESTIONNAIRE_REQUEST_FAIL,
  props<{ error: any }>()
);

const FetchTargets = createAction(
  HealthScreeningQuestionnaireActionType.FETCH_HEALTH_SCREENING_QUESTIONNAIRE_TARGETS,
  props<{ req: HealthScreeningQuestionnaireModels.FetchTargetsReq }>()
);

const FetchTargetsSuccess = createAction(
  HealthScreeningQuestionnaireActionType.FETCH_HEALTH_SCREENING_QUESTIONNAIRE_TARGETS_SUCCESS,
  props<{ res: HealthScreeningQuestionnaireModels.FetchTargetsRes }>()
);

const UpdateTargetsOrder = createAction(
  HealthScreeningQuestionnaireActionType.UPDATE_HEALTH_SCREENING_QUESTIONNAIRE_TARGETS_ORDER,
  props<{
    req: HealthScreeningQuestionnaireModels.UpdateTargetsOrderReq;
  }>()
);

const UpdateTargetsOrderSuccess = createAction(
  HealthScreeningQuestionnaireActionType.UPDATE_HEALTH_SCREENING_QUESTIONNAIRE_TARGETS_ORDER_SUCCESS
);

const CreateTarget = createAction(
  HealthScreeningQuestionnaireActionType.CREATE_HEALTH_SCREENING_QUESTIONNAIRE_TARGET,
  props<{
    payload: HealthScreeningQuestionnaireModels.CreateOrUpdateTargetPayload;
  }>()
);

const CreateTargetSuccess = createAction(
  HealthScreeningQuestionnaireActionType.CREATE_HEALTH_SCREENING_QUESTIONNAIRE_TARGET_SUCCESS,
  props<{
    res: HealthScreeningQuestionnaireModels.CreateTargetRes;
  }>()
);

const UpdateTarget = createAction(
  HealthScreeningQuestionnaireActionType.UPDATE_HEALTH_SCREENING_QUESTIONNAIRE_TARGET,
  props<{ req: HealthScreeningQuestionnaireModels.UpdateTargetReq }>()
);

const UpdateTargetSuccess = createAction(
  HealthScreeningQuestionnaireActionType.UPDATE_HEALTH_SCREENING_QUESTIONNAIRE_TARGET_SUCCESS,
  props<{
    res: HealthScreeningQuestionnaireModels.UpdateTargetRes;
  }>()
);

const FetchTargetDetail = createAction(
  HealthScreeningQuestionnaireActionType.FETCH_HEALTH_SCREENING_QUESTIONNAIRE_TARGET_DETAIL,
  props<{ req: HealthScreeningQuestionnaireModels.FetchTargetDetailReq }>()
);

const FetchTargetDetailSuccess = createAction(
  HealthScreeningQuestionnaireActionType.FETCH_HEALTH_SCREENING_QUESTIONNAIRE_TARGET_DETAIL_SUCCESS,
  props<{ res: HealthScreeningQuestionnaireModels.FetchTargetDetailRes }>()
);

const UpdateTargetQuestions = createAction(
  HealthScreeningQuestionnaireActionType.UPDATE_HEALTH_SCREENING_QUESTIONNAIRE_QUESTIONS,
  props<{
    req: HealthScreeningQuestionnaireModels.UpdateTargetQuestionsReq;
  }>()
);

const UpdateTargetQuestionsSuccess = createAction(
  HealthScreeningQuestionnaireActionType.UPDATE_HEALTH_SCREENING_QUESTIONNAIRE_QUESTIONS_SUCCESS,
  props<{
    res: HealthScreeningQuestionnaireModels.UpdateTargetQuestionsRes;
  }>()
);

const DeleteTarget = createAction(
  HealthScreeningQuestionnaireActionType.DELETE_HEALTH_SCREENING_QUESTIONNAIRE_TARGET,
  props<{ req: HealthScreeningQuestionnaireModels.DeleteTargetReq }>()
);

const DeleteTargetSuccess = createAction(
  HealthScreeningQuestionnaireActionType.DELETE_HEALTH_SCREENING_QUESTIONNAIRE_TARGET_SUCCESS,
  props<{ questionnaireId: string }>()
);

const UpdateUse = createAction(
  HealthScreeningQuestionnaireActionType.UPDATE_HEALTH_SCREENING_QUESTIONNAIRE_USE,
  props<{ payload: HealthScreeningQuestionnaireModels.UpdateUseReq }>()
);

const UpdateUseSuccess = createAction(
  HealthScreeningQuestionnaireActionType.UPDATE_HEALTH_SCREENING_QUESTIONNAIRE_USE_SUCCESS,
  props<{ useAdditionalQuestionnaire: boolean }>()
);

export const HealthScreeningQuestionnaireActions = {
  RequestFail,
  FetchTargets,
  FetchTargetsSuccess,

  UpdateTargetsOrder,
  UpdateTargetsOrderSuccess,

  CreateTarget,
  CreateTargetSuccess,

  UpdateTarget,
  UpdateTargetSuccess,

  FetchTargetDetail,
  FetchTargetDetailSuccess,

  UpdateTargetQuestions,
  UpdateTargetQuestionsSuccess,

  DeleteTarget,
  DeleteTargetSuccess,

  UpdateUse,
  UpdateUseSuccess,
};
