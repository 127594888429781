<div class="gnb">
  <div class="voluntary_header">
    <div class="content-layout">
      <div class="content-wrap">
        <div class="inner">
          <a class="logo" href="https://hospital.ddocdoc.com">
            <i class="icon icon-ddocdoc-bi"></i>
            <i class="icon icon-ddocdoc-bi-mobile"></i>
          </a>
          <div class="right-btn-group">
            <button
              type="button"
              class="btn-rounded-outline white"
              data-cy="sign-in-modal-button"
              (click)="login.emit()"
            >
              병원관리자 로그인
            </button>
            <button
              type="button"
              routerLink="/signup"
              class="btn-rounded-outline black"
            >
              무료로 시작하기
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
