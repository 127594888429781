<app-signin-header (login)="onLoginClick()"></app-signin-header>
<section class="banner main">
  <div class="video-box">
    <video #videoContent class="video-box--video">
      <source
        src="https://cdn.ddocdoc.com/web_assets/ddocdoc-hospital-admin/assets/videos/bannerVideo.mp4"
      />
    </video>
  </div>
  <div class="content-layout">
    <div class="content-wrap">
      <div class="title-section">
        <p class="title">
          병원이 행복해지는<br />
          편리한 방법을 제공합니다.
        </p>
      </div>
    </div>

    <ng-lottie class="scroll-down" [options]="options"></ng-lottie>
  </div>
</section>

<app-signin-benefit></app-signin-benefit>

<app-signin-happy-way></app-signin-happy-way>

<app-signin-ddocdoc-set></app-signin-ddocdoc-set>

<app-page-footer></app-page-footer>
