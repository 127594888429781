import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-reset-password',
  templateUrl: './resetPassword.component.html',
  styleUrls: ['./resetPassword.component.scss'],
})
export class ResetPasswordComponent {
  @Input() error: string | null = null;
  @Input() token: string | null = null;

  @Output() submitted = new EventEmitter<string>();

  resetPasswordForm: FormGroup;
  validatePassword = '^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^*+-]).{6,18}$';

  get password(): AbstractControl | null {
    return this.resetPasswordForm.get('password');
  }

  get resetPassword(): AbstractControl | null {
    return this.resetPasswordForm.get('resetPassword');
  }

  matchingPassword(
    passwordKey: string,
    passwordConfirm: string
  ): (group: FormGroup) => void {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey];
      const passwordConfirmInput = group.controls[passwordConfirm];
      if (passwordInput.value !== passwordConfirmInput.value) {
        return passwordConfirmInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmInput.setErrors(null);
      }
    };
  }

  constructor(private fb: FormBuilder) {
    this.resetPasswordForm = this.fb.group(
      {
        password: [
          null,
          Validators.compose([
            Validators.required,
            Validators.pattern(this.validatePassword),
          ]),
        ],
        resetPassword: [null, Validators.required],
      },
      { validator: this.matchingPassword('password', 'resetPassword') }
    );
  }

  onSubmit(e: any): void {
    e.preventDefault();
    if (this.resetPasswordForm.valid) {
      this.submitted.emit(this.resetPasswordForm.controls.password.value);
    }
  }
}
