import { createReducer, on } from '@ngrx/store';
import { MobilePaperActions } from '~actions/products/mobile-paper.actions';

export interface MobilePaperState {
  use: boolean;
  useLazy: boolean;
  isAgreed: boolean;
  agreeDate: string;
  emrVersion: string;
}

const initialState: MobilePaperState = {
  use: false,
  useLazy: false,
  isAgreed: false,
  agreeDate: undefined,
  emrVersion: undefined,
};

export const mobilePaperReducer = createReducer(
  initialState,
  on(MobilePaperActions.MobilePaperUseLoadSuccess, (state, action) => {
    const {
      payload: { use },
    } = action;
    return {
      ...state,
      ...action.payload,
      useLazy: use,
    };
  }),
  on(MobilePaperActions.MobilePaperUseUpdateSuccess, (state, { use }) => {
    return {
      ...state,
      use,
      useLazy: use,
    };
  }),
  on(MobilePaperActions.MobilePaperUseUpdateLazy, (state, { useLazy }) => {
    return {
      ...state,
      useLazy,
    };
  })
);
