import { EmrTypeEnum } from './crm-service.model';

export enum SideMenuTypeEnum {
  home = 'home',
  basic = 'basic',
  reception = 'reception',
  reservation = 'reservation',
  hospital_crm = 'hospital-crm',
  telemedicine = 'telemedicine',
  payment = 'payment',
  status = 'status',
  child_screening = 'child_screening',
  waitings = 'waitings',
  wait_manage = 'wait-manage',
  unmanned = 'unmanned',
  user_service = 'user-service',
  kakao_plus = 'kakao-plus',
}

export type SideMenuTypes =
  typeof SideMenuTypeEnum[keyof typeof SideMenuTypeEnum];

export interface SideMenu {
  type: SideMenuTypes;
  typeName: string;
  noChild?: boolean;
  hidden?: boolean;
  menuItems?: SideMenuItem[];
  /**
   * 신규메뉴 여부 이 값이 true이면 new 아이콘이 노출된다.
   */
  isShowNewBadge?: boolean;
  /**
   * 특정 ERM 전용 여부 WhiteList
   */
  allowedAgents?: string[];
  /**
   * 특정 ERM 전용 여부 blackList
   */
  blockedAgents?: string[];
  /**
   * 관리시 (똑닥어드민에서 접속시)에만 활성화되는 메뉴 여부
   */
  forManagement?: boolean;
  isAdminOnly?: boolean;
  popoverHTML?: string;
}

export interface HeaderMenuItem {
  type?: string;
  menuName: string;
  icon?: string;
  link: string;
  href?: string; // href가 있으면 link는 x
  hidden?: boolean;
}

export interface SideMenuItem {
  menuName?: string;
  link?: string;
  btnName?: string;
  subTitle?: string;
  parentTitle?: string;
  noChild?: boolean;
  hidden?: boolean;
  new?: boolean;
  file?: string;
  video?: { code: string; desc: string }[];
  show?: boolean;
  hospital?: string;
  hospitalDirector?: string;
  /**
   * 특정 ERM 전용 여부
   */
  emrType?: string;
  /**
   * 접수/예약/무인접수/키오스크 사용 여부
   *
   * true : 해당 서비스 하나라도 사용하면 노출
   * false: 서비스 하나도 사용하지않을시 노출
   * null, undefined: 서비스 사용 여부 상관 없이 노출 (Default)
   */
  useDdocdocService?: boolean;
  popoverHTML?: string;
}

const CDN_DOMAIN_MANUAL = '//cdn.ddocdoc.com/manual/';
export const USE_UNTACT_AGENT_SET = new Set<string>([
  EmrTypeEnum.UBCARE,
  EmrTypeEnum.BIT,
  EmrTypeEnum.POINT,
  EmrTypeEnum.NEO,
  EmrTypeEnum.MEDICAL,
  EmrTypeEnum.BYUNGCOM,
  EmrTypeEnum.FORDOCTOR,
  EmrTypeEnum.DASOM,
  // EmrTypeEnum.CLICKSOFT,
]);

// TODO: 병원어드민 사용법용으로 따로 만듬(QA-4445)
// 개편 구조에서는 메뉴얼 구성 안맞고, 메뉴얼 디자인 자체도 예전버전이어서 메뉴얼 화면 제작업 해야 함
// 일단 QA-4445 이슈 해결을 위해 임시 조치
export const tempManualList: SideMenu[] = [
  {
    type: SideMenuTypeEnum.home,
    typeName: '홈',
    hidden: true,
    noChild: true,
    menuItems: [
      {
        menuName: '홈',
        link: '/home',
      },
    ],
  },
  // agents of use untact service
  {
    type: SideMenuTypeEnum.basic,
    typeName: '기본 설정',
    isShowNewBadge: true,
    isAdminOnly: true,
    popoverHTML: `
      <p>언택트 시대! 안전한 진료를 위한</p>
      <b>전화상담진료 서비스를 이용해보세요!</b>
    `,
    allowedAgents: Array.from(USE_UNTACT_AGENT_SET),
    menuItems: [
      {
        menuName: '진료시간 정보',
        link: '/hospital-info/schedules',
        btnName: '설정하기',
        file: CDN_DOMAIN_MANUAL + 'basic-ot.png',
      },
      {
        menuName: '진료실 정보',
        link: '/basic/care',
        file: CDN_DOMAIN_MANUAL + 'basic-care.png',
        useDdocdocService: true,
        new: true,
        popoverHTML: `
          <p>언택트 시대! 안전한 진료를 위한</p>
          <b>전화상담진료 서비스를 이용해보세요!</b>
        `,
      },
      {
        menuName: '진료실 정보',
        parentTitle: '기본 설정',
        link: '/basic/treatment',
        useDdocdocService: false,
      },
      {
        menuName: '병원 공지사항',
        link: '/hospital-info/notices',
        btnName: '등록하기',
      },
      {
        menuName: '병원 정보',
        link: '/basic/hospital',
        file: CDN_DOMAIN_MANUAL + 'basic-care.png',
        new: true,
      },
      {
        menuName: '의사 정보',
        link: '/basic/doctor',
        file: CDN_DOMAIN_MANUAL + 'basic-care.png',
        new: true,
      },
      {
        menuName: '진료비 정보',
        link: '/basic/expense',
        file: CDN_DOMAIN_MANUAL + 'basic-care.png',
        new: true,
      },
    ],
  },
  {
    type: SideMenuTypeEnum.reception,
    typeName: '똑닥 접수 설정',
    menuItems: [
      {
        menuName: '접수시간 설정',
        link: '/reception/rt',
        file: CDN_DOMAIN_MANUAL + 'reception-rt.png',
      },
      {
        menuName: '접수 진료항목 설정',
        link: '/reception/select',
        file: CDN_DOMAIN_MANUAL + 'reception-select.png',
      },
      {
        menuName: '접수 옵션 설정',
        link: '/reception/options',
        file: CDN_DOMAIN_MANUAL + 'reception-options.png',
      },
      {
        menuName: '접수 취소 메시지 설정',
        link: '/reception/cancel-msg',
        file: CDN_DOMAIN_MANUAL + 'reception-cancel-msg.png',
      },
      {
        menuName: '접수 일시정지',
        link: '/reception/pause',
        file: CDN_DOMAIN_MANUAL + 'reception-pause.png',
      },
      {
        menuName: '접수 사전문진 설정',
        link: '/reception/question',
        emrType: 'UBCARE',
      },
    ],
  },
  {
    type: SideMenuTypeEnum.reservation,
    typeName: '모바일 예약 설정',
    menuItems: [
      {
        menuName: '예약 스케줄러 설정',
        link: '/reservation/schedule',
        file: '/assets/manual/timeline-table-detail.html',
      },
      {
        menuName: '예약 진료항목 설정',
        link: '/reservation/select',
      },
      {
        menuName: '예약 옵션 설정',
        link: '/reservation/basic',
        hidden: true,
        subTitle:
          '똑닥 모바일 예약 관련 세부 설정입니다. 해당 설정은 진료실에 공통적으로 적용됩니다.',
      },
      {
        menuName: '예약취소 메시지 설정',
        link: '/reservation/cancel-msg',
        file: CDN_DOMAIN_MANUAL + 'reservation-cancel-msg.png',
      },
      {
        menuName: '예약 사전문진 설정',
        link: '/reservation/question',
        emrType: 'UBCARE',
      },
      // {
      //   menuName: '진료실별 설정',
      //   link: '/reservation/care',
      //   file: CDN_DOMAIN_MANUAL + 'reservation-care.png',
      // },
    ],
  },
  {
    type: SideMenuTypeEnum.hospital_crm,
    typeName: '모바일 환자 관리',
    isShowNewBadge: true,
    menuItems: [
      {
        menuName: '똑닥 기본 푸시 설정',
        link: '/hospital-crm/schedule/default',
      },
      {
        menuName: '병원 상세 푸시 설정',
        link: '/hospital-crm/schedule/hospital',
      },
      {
        menuName: '푸시 발송 결과 내역',
        link: '/hospital-crm/statistics',
      },
      {
        menuName: '푸시 발송 제외 내역',
        link: '/hospital-crm/exclude-list',
      },
    ],
    allowedAgents: ['UBCARE'],
  },

  {
    type: SideMenuTypeEnum.telemedicine,
    typeName: '똑닥 비대면 진료',
    noChild: true,
    forManagement: true,
    menuItems: [
      {
        menuName: '똑닥 비대면 진료 설정',
        link: '/telemedicine',
        emrType: 'UBCARE',
      },
    ],
    allowedAgents: ['UBCARE'],
  },
  {
    type: SideMenuTypeEnum.payment,
    typeName: '똑닥 간편결제 설정',
    noChild: true,
    forManagement: true,
    menuItems: [
      {
        menuName: '똑닥 간편결제 설정',
        link: '/mobile-payment',
        emrType: 'UBCARE',
      },
    ],
    allowedAgents: ['UBCARE'],
  },
  {
    type: SideMenuTypeEnum.status,
    typeName: '접수/예약 현황',
    menuItems: [
      {
        menuName: '접수/예약취소 현황',
        link: '/status/canceled',
      },
      {
        menuName: '접수/예약 페널티 현황',
        link: '/status/penalty',
      },
    ],
  },
  {
    type: SideMenuTypeEnum.child_screening,
    typeName: '영유아 검진 문진표',
    noChild: true,
    menuItems: [
      {
        menuName: '영유아 검진 문진표 리스트',
        link: '/child/download',
        file: CDN_DOMAIN_MANUAL + 'child-download.png',
        video: [
          { code: 'dz89Q22UNeM', desc: '영유아 검진 소개' },
          { code: 'Gu0mc_UEBr8', desc: '영유아 검진 사전준비' },
          { code: 'xOWj9UPOSLY', desc: '영유아 검진 문진표 등록방법' },
          { code: 'jwarDP3OrIU', desc: '영유아 검진 오류 해결방법' },
        ],
      },
    ],
  },
  {
    type: SideMenuTypeEnum.waitings,
    typeName: '모바일 대기현황판 설정',
    noChild: true,
    menuItems: [
      {
        menuName: '모바일 대기현황판 설정',
        link: '/waitings/setup',
        // btnName: '설정 저장하기',
        // subTitle: '설정 변경 후 설정 저장하기 버튼을 클릭하셔야 변경된 데이터가 반영됩니다.',
        file: CDN_DOMAIN_MANUAL + 'wait-manage.png',
        video: [
          { code: 'LOYxuA8DK44', desc: '대기현황판 신청방법' },
          { code: 'ReufN7McwN4', desc: '대기현황판 설정하기' },
        ],
      },
    ],
  },
  {
    type: SideMenuTypeEnum.unmanned,
    typeName: '무인접수 설정',
    // hidden: true,
    // noChild: true,
    menuItems: [
      {
        menuName: '키오스크 기본 설정',
        link: '/unmanned/kiosk/manage',
      },
      {
        menuName: '키오스크 접수시간 설정',
        link: '/unmanned/kiosk/ut',
      },
      {
        menuName: '키오스크 진료항목 설정',
        link: '/unmanned/kiosk/select',
      },
      {
        menuName: '키오스크 일시정지 설정',
        link: '/unmanned/kiosk/pause',
      },
      {
        menuName: '태블릿 기본 설정',
        link: '/unmanned/tablet/basic',
      },
      {
        menuName: '태블릿 접수시간 설정',
        link: '/unmanned/tablet/ut',
      },
      {
        menuName: '태블릿 진료항목 설정',
        link: '/unmanned/tablet/select',
      },
      // {ㄹㄹㄹㄹㄹ
      //   menuName: '무인접수 설정', // 공지사항
      //   link: '/unmanned/notice-set',
      // }
    ],
  },
  // {
  //   type: 'kiosk',
  //   typeName: '키오스크 설정',
  //   menuItems: [
  //     {
  //       menuName: '키오스크 설정',
  //       link: '/kiosk/manage'
  //       // subTitle: '설정 변경 후 업데이트 버튼을 클릭 시, 서비스에 반영됩니다.',
  //       // btnName: '업데이트'
  //     },
  //     {
  //       menuName: '키오스크 진료항목 설정',
  //       link: '/kiosk/select'
  //     }
  //   ]
  // },
  // {
  //   type: 'kakao-plus',
  //   typeName: '플러스친구 설정',
  //   hidden: true,
  //   menuItems: [
  //     {
  //       menuName: '프로필 설정',
  //       link: '/kakao/profile',
  //       btnName: '업데이트'
  //     }
  //   ]
  // },
  {
    type: SideMenuTypeEnum.user_service,
    typeName: '서비스 요청 현황',
    noChild: true,
    menuItems: [
      {
        menuName: '서비스 요청 내역',
        link: '/user-service/list',
      },
    ],
  },
];

export const basicSideMenu: SideMenu[] = [
  {
    type: SideMenuTypeEnum.home,
    typeName: '홈',
    hidden: true,
    noChild: true,
    menuItems: [
      {
        menuName: '홈',
        link: '/home',
      },
    ],
  },
  // agents of use untact service
  {
    type: SideMenuTypeEnum.basic,
    typeName: '기본 설정',
    isShowNewBadge: true,
    isAdminOnly: true,
    popoverHTML: `
      <p>언택트 시대! 안전한 진료를 위한</p>
      <b>전화상담진료 서비스를 이용해보세요!</b>
    `,
    allowedAgents: Array.from(USE_UNTACT_AGENT_SET),
    menuItems: [
      {
        menuName: '진료시간 정보',
        link: '/hospital-info/schedules',
        btnName: '설정하기',
        file: CDN_DOMAIN_MANUAL + 'basic-ot.png',
      },
      {
        menuName: '진료실 정보',
        link: '/basic/care',
        file: CDN_DOMAIN_MANUAL + 'basic-care.png',
        useDdocdocService: true,
        new: true,
        popoverHTML: `
          <p>언택트 시대! 안전한 진료를 위한</p>
          <b>전화상담진료 서비스를 이용해보세요!</b>
        `,
      },
      {
        menuName: '진료실 정보',
        parentTitle: '기본 설정',
        link: '/basic/treatment',
        useDdocdocService: false,
      },
      {
        menuName: '병원 공지사항',
        link: '/hospital-info/notices',
        btnName: '등록하기',
      },
      {
        menuName: '병원 정보',
        link: '/basic/hospital',
        file: CDN_DOMAIN_MANUAL + 'basic-care.png',
        new: true,
      },
      {
        menuName: '의사 정보',
        link: '/basic/doctor',
        file: CDN_DOMAIN_MANUAL + 'basic-care.png',
        new: true,
      },
      {
        menuName: '진료비 정보',
        link: '/basic/expense',
        file: CDN_DOMAIN_MANUAL + 'basic-care.png',
        new: true,
      },
    ],
  },
  // agents of not use untact service
  {
    type: SideMenuTypeEnum.basic,
    typeName: '기본 설정',
    isShowNewBadge: true,
    isAdminOnly: true,
    blockedAgents: Array.from(USE_UNTACT_AGENT_SET),
    menuItems: [
      {
        menuName: '진료시간 정보',
        link: '/hospital-info/schedules',
        btnName: '설정하기',
        file: CDN_DOMAIN_MANUAL + 'basic-ot.png',
      },
      {
        menuName: '진료실 정보',
        link: '/basic/care',
        file: CDN_DOMAIN_MANUAL + 'basic-care.png',
        useDdocdocService: true,
      },
      {
        menuName: '진료실 정보',
        parentTitle: '기본 설정',
        link: '/basic/treatment',
        useDdocdocService: false,
      },
      {
        menuName: '병원 공지사항',
        link: '/basic/notice',
        btnName: '등록하기',
      },
      {
        menuName: '병원 정보',
        link: '/basic/hospital',
        file: CDN_DOMAIN_MANUAL + 'basic-care.png',
        new: true,
      },
      {
        menuName: '의사 정보',
        link: '/basic/doctor',
        file: CDN_DOMAIN_MANUAL + 'basic-care.png',
        new: true,
      },
      {
        menuName: '진료비 정보',
        link: '/basic/expense',
        file: CDN_DOMAIN_MANUAL + 'basic-care.png',
        new: true,
      },
    ],
  },
  // from ddocdoc admin
  {
    type: SideMenuTypeEnum.basic,
    typeName: '기본 설정',
    isShowNewBadge: true,
    forManagement: true,
    isAdminOnly: false,
    popoverHTML: `
      <p>언택트 시대! 안전한 진료를 위한</p>
      <b>전화상담진료 서비스를 이용해보세요!</b>
    `,
    menuItems: [
      {
        menuName: '진료시간 정보',
        link: '/hospital-info/schedules',
        btnName: '설정하기',
        file: CDN_DOMAIN_MANUAL + 'basic-ot.png',
      },
      {
        menuName: '진료실 정보',
        link: '/basic/care',
        file: CDN_DOMAIN_MANUAL + 'basic-care.png',
        useDdocdocService: true,
        new: true,
        popoverHTML: `
          <p>언택트 시대! 안전한 진료를 위한</p>
          <b>전화상담진료 서비스를 이용해보세요!</b>
        `,
      },
      {
        menuName: '진료실 정보',
        parentTitle: '기본 설정',
        link: '/basic/treatment',
        useDdocdocService: false,
      },
      {
        menuName: '병원 공지사항',
        link: '/basic/notice',
        btnName: '등록하기',
      },
      {
        menuName: '병원 정보',
        link: '/basic/hospital',
        file: CDN_DOMAIN_MANUAL + 'basic-care.png',
        new: true,
      },
      {
        menuName: '의사 정보',
        link: '/basic/doctor',
        file: CDN_DOMAIN_MANUAL + 'basic-care.png',
        new: true,
      },
      {
        menuName: '진료비 정보',
        link: '/basic/expense',
        file: CDN_DOMAIN_MANUAL + 'basic-care.png',
        new: true,
      },
    ],
  },
  {
    type: SideMenuTypeEnum.reception,
    typeName: '똑닥 접수 설정',
    menuItems: [
      {
        menuName: '접수시간 설정',
        link: '/reception/rt',
        file: CDN_DOMAIN_MANUAL + 'reception-rt.png',
      },
      {
        menuName: '접수 진료항목 설정',
        link: '/reception/select',
        file: CDN_DOMAIN_MANUAL + 'reception-select.png',
      },
      {
        menuName: '접수 옵션 설정',
        link: '/reception/options',
        file: CDN_DOMAIN_MANUAL + 'reception-options.png',
      },
      {
        menuName: '접수 취소 메시지 설정',
        link: '/reception/cancel-msg',
        file: CDN_DOMAIN_MANUAL + 'reception-cancel-msg.png',
      },
      {
        menuName: '접수 일시정지',
        link: '/reception/pause',
        file: CDN_DOMAIN_MANUAL + 'reception-pause.png',
      },
      {
        menuName: '접수 사전문진 설정',
        link: '/reception/question',
        emrType: 'UBCARE',
      },
    ],
  },
  {
    type: SideMenuTypeEnum.reservation,
    typeName: '모바일 예약 설정',
    menuItems: [
      {
        menuName: '예약 스케줄러 설정',
        link: '/reservation/schedule',
        file: '/assets/manual/timeline-table-detail.html',
      },
      {
        menuName: '예약 진료항목 설정',
        link: '/reservation/select',
      },
      {
        menuName: '예약 옵션 설정',
        link: '/reservation/basic',
        hidden: true,
        subTitle:
          '똑닥 모바일 예약 관련 세부 설정입니다. 해당 설정은 진료실에 공통적으로 적용됩니다.',
      },
      {
        menuName: '예약취소 메시지 설정',
        link: '/reservation/cancel-msg',
        file: CDN_DOMAIN_MANUAL + 'reservation-cancel-msg.png',
      },
      {
        menuName: '예약 사전문진 설정',
        link: '/reservation/question',
        emrType: 'UBCARE',
      },
      // {
      //   menuName: '진료실별 설정',
      //   link: '/reservation/care',
      //   file: CDN_DOMAIN_MANUAL + 'reservation-care.png',
      // },
    ],
  },
  {
    type: SideMenuTypeEnum.hospital_crm,
    typeName: '모바일 환자 관리',
    isShowNewBadge: true,
    menuItems: [
      {
        menuName: '똑닥 기본 푸시 설정',
        link: '/hospital-crm/schedule/default',
      },
      {
        menuName: '병원 상세 푸시 설정',
        link: '/hospital-crm/schedule/hospital',
      },
      {
        menuName: '푸시 발송 결과 내역',
        link: '/hospital-crm/statistics',
      },
      {
        menuName: '푸시 발송 제외 내역',
        link: '/hospital-crm/exclude-list',
      },
    ],
    allowedAgents: ['UBCARE'],
  },
  {
    type: SideMenuTypeEnum.telemedicine,
    typeName: '똑닥 비대면 진료 설정',
    noChild: true,
    forManagement: true,
    menuItems: [
      {
        menuName: '똑닥 비대면 진료 설정',
        link: '/telemedicine',
        emrType: 'UBCARE',
      },
    ],
    allowedAgents: ['UBCARE'],
  },
  {
    type: SideMenuTypeEnum.payment,
    typeName: '똑닥 간편결제 설정',
    noChild: true,
    forManagement: true,
    menuItems: [
      {
        menuName: '똑닥 간편결제 설정',
        link: '/mobile-payment',
        emrType: 'UBCARE',
      },
    ],
    allowedAgents: ['UBCARE'],
  },
  {
    type: SideMenuTypeEnum.status,
    typeName: '접수/예약 현황',
    menuItems: [
      {
        menuName: '접수/예약취소 현황',
        link: '/status/canceled',
      },
      {
        menuName: '접수/예약 페널티 현황',
        link: '/status/penalty',
      },
    ],
  },
  {
    type: SideMenuTypeEnum.child_screening,
    typeName: '영유아 검진 문진표',
    noChild: true,
    menuItems: [
      {
        menuName: '영유아 검진 문진표 리스트',
        link: '/child/download',
        file: CDN_DOMAIN_MANUAL + 'child-download.png',
        video: [
          { code: 'dz89Q22UNeM', desc: '영유아 검진 소개' },
          { code: 'Gu0mc_UEBr8', desc: '영유아 검진 사전준비' },
          { code: 'xOWj9UPOSLY', desc: '영유아 검진 문진표 등록방법' },
          { code: 'jwarDP3OrIU', desc: '영유아 검진 오류 해결방법' },
        ],
      },
    ],
  },
  {
    type: SideMenuTypeEnum.waitings,
    typeName: '모바일 대기현황판 설정',
    noChild: true,
    menuItems: [
      {
        menuName: '모바일 대기현황판 설정',
        link: '/waitings/setup',
        // btnName: '설정 저장하기',
        // subTitle: '설정 변경 후 설정 저장하기 버튼을 클릭하셔야 변경된 데이터가 반영됩니다.',
        file: CDN_DOMAIN_MANUAL + 'wait-manage.png',
        video: [
          { code: 'LOYxuA8DK44', desc: '대기현황판 신청방법' },
          { code: 'ReufN7McwN4', desc: '대기현황판 설정하기' },
        ],
      },
    ],
  },
  {
    type: SideMenuTypeEnum.unmanned,
    typeName: '무인접수 설정',
    // hidden: true,
    // noChild: true,
    menuItems: [
      {
        menuName: '키오스크 기본 설정',
        link: '/unmanned/kiosk/manage',
      },
      {
        menuName: '키오스크 접수시간 설정',
        link: '/unmanned/kiosk/ut',
      },
      {
        menuName: '키오스크 진료항목 설정',
        link: '/unmanned/kiosk/select',
      },
      {
        menuName: '키오스크 일시정지 설정',
        link: '/unmanned/kiosk/pause',
      },
      {
        menuName: '태블릿 기본 설정',
        link: '/unmanned/tablet/basic',
      },
      {
        menuName: '태블릿 접수시간 설정',
        link: '/unmanned/tablet/ut',
      },
      {
        menuName: '태블릿 진료항목 설정',
        link: '/unmanned/tablet/select',
      },
      // {ㄹㄹㄹㄹㄹ
      //   menuName: '무인접수 설정', // 공지사항
      //   link: '/unmanned/notice-set',
      // }
    ],
  },
  // {
  //   type: 'kiosk',
  //   typeName: '키오스크 설정',
  //   menuItems: [
  //     {
  //       menuName: '키오스크 설정',
  //       link: '/kiosk/manage'
  //       // subTitle: '설정 변경 후 업데이트 버튼을 클릭 시, 서비스에 반영됩니다.',
  //       // btnName: '업데이트'
  //     },
  //     {
  //       menuName: '키오스크 진료항목 설정',
  //       link: '/kiosk/select'
  //     }
  //   ]
  // },
  // {
  //   type: 'kakao-plus',
  //   typeName: '플러스친구 설정',
  //   hidden: true,
  //   menuItems: [
  //     {
  //       menuName: '프로필 설정',
  //       link: '/kakao/profile',
  //       btnName: '업데이트'
  //     }
  //   ]
  // },
  {
    type: SideMenuTypeEnum.user_service,
    typeName: '서비스 요청 현황',
    noChild: true,
    menuItems: [
      {
        menuName: '서비스 요청 내역',
        link: '/user-service/list',
      },
    ],
  },
];

export const headerMenu: HeaderMenuItem[] = [
  {
    type: 'isConfirmationTarget',
    menuName: '영업교육 확인서',
    icon: 'icon-sales-edu',
    link: '/',
    href: 'https://docs.google.com/forms/d/e/1FAIpQLSdiz7lOn8takERYwI28XlnxnErcMR5CwSSlVmBCPAyQzZjlXA/viewform',
    hidden: false,
  },
  {
    type: 'isCrmSmsTarget',
    menuName: '문자발송 신청서',
    icon: 'icon-sms',
    link: '/crm',
  },
];

export const SIDE_MENU_NAMES: { [key: string]: string } = {
  reception: 'RECEIPT',
  reservation: 'RESERVATION',
  'wait-manage': 'WAITINGS',
  'child-screening': 'EXAMINATION_INFANTS',
  // unmanned: 'SELF_DESK',
  // kiosk: 'KIOSK'
};
